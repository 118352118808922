import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "../src/assets/css/venjos.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Dashboard from "./components/dashboard/Dashboard";
import NoPage from "./components/pages/NoPage";
import Login from "./components/login/Login";
import Logout from "./components/login/logout";
// import LoadPlanRoute from "./components/loadsummary/plantRouteComponent";
// import LoadPlanScreen from "./components/loadsummary/loadPlanSummaryComponent";
// import LoadPlanSummary from "./components/loadsummary/loadPlanSummaryGrid";
import Dealers from "./components/dealers/dealerComponent";
import DealerParts from "./components/dealers/dealerpartsComponent";
import SummaryDashboard from "./components/dashboard/summaryDashboard";
// import ClusterRoute from "./components/loadsummary/clusterrouteComponent";
// import VehicleDetails from "./components/loadsummary/vehicledetailgrid";
// import BinPacking  from './components/loadsummary/binPacking'
// import Packing from "./components/loadsummary/packingComponent";
// import StackingPlanSummary from "./components/loadsummary/stackingplantsummary";
// import VehicleInfo from "./components/loadsummary/vehicleinformation";
// import InputPlan from "./components/loadsummary/inputplandata";
// import Packing3D from "./components/loadsummary/3dpackingComponent";
// import PreviousPacking3D from "./components/loadsummary/previous3dstacking";
// import PreviousPlans from "./components/loadsummary/previousstackingplansummary";
import Configurations from "./components/optirun-admin/configurations";
import OptiRunAdmin from "./components/optirun-admin/optirunComponent";
import PlantDataPanel from "./components/plantDataPanel.js";
import PickupDropLocations from "./components/optirun-admin/pickupdroplocationsComponent";
import VehiclesData from "./components/optirun-admin/vehiclesMasterData";
// import VehicleAvailability from './components/optirun-admin/vehicleAvailability'
// import TransporterDetails from './components/optirun-admin/transporterDetails'
import MaterialConfigurations from "./components/optirun-admin/materialConfigurations";
import RouteConfigurations from "./components/optirun-admin/routeConfigurations";
import AutoClustering from "./components/optirun-admin/autoclustering";
import TransporterShareOfBusiness from "./components/optirun-admin/transportershareofbusiness";
// import FreightConfigurations from './components/optirun-admin/freightConfigurations'
import LoadProcessRun from "./components/optirun-admin/loadprocessrun";
import PlanNewDispatch from "./components/optirun-admin/plannewdispatch.js";
import DipatchPlanHistory from "./components/optirun-admin/dispatchplanhistory";
import ViewDispatchPlanDetails from "./components/optirun-admin/viewdispatchplandetails";
import DispatchPlanSummary from "./components/optirun-admin/dispatchplansummaryDashboard";

import VehicleCostConfigurations from "./components/optirun-admin/vehicleCostComponent";
// import ZoneMapping from './components/optirun-admin/zonemapping'

import PlantWarehouseLocations from "./components/stacking-plan/plantOrWarehouseLocations";
import StackingVehicleDetails from "./components/stacking-plan/stackingVehicleDetails";
import MaterialDetails from "./components/stacking-plan/stackingMaterialDetails";
import ForecastInputData from "./components/forecast/forecastInputdata";
import ForecastOutputParams from "./components/forecast/forecastoutputparams";
import ForecastOutputData from "./components/forecast/forecastOutputdata";
import ForecastCustomers from "./components/forecast/forecastCustomers";
import ForecastMaterials from "./components/forecast/forecastMaterials";
import ForecastPreviousPlan from "./components/forecast/forecast-previousplan";
import ForecastSuppliers from "./components/forecast/forecastSuppliers";
import VehicleAvailabilityDetails from "./components/optirun-admin/vehicleAvailabilityDetails";
import TransporterConfiguration from "./components/optirun-admin/transporterConfigurations";
import ZoneConfiguration from "./components/optirun-admin/zoneConfiguration";

import ManageTruckConsignments from "./components/tracking/truckswithconsignmentsComponent";
import TrucksGPSData from "./components/tracking/trucksgpsdataComponent";
import OverspeedComponent from "./components/tracking/overspeedComponent";
import NightDrivingReportData from "./components/tracking/nightdrivingReportComponent";
import StoppageReportData from "./components/tracking/stoppagereportComponent";
import TransitDelayReportData from "./components/tracking/transitdelayreportComponent";
import NoGPSReports from "./components/tracking/nogpsreports";
import SNDUnloadingDashboard from "./components/tracking/unloadingdashboard";
import KPIDashboard from "./kpi_dashboard";
import KpiPowerBi from "./components/optirun-admin/kpis_powerbi";
import ConfigurationsNew from "./components/optirun-admin/configurations/configurationsNew.js";
import ChangePassword from "./components/header/changePs.js";
import Questionnaire from "./components/questionnarie/questionnarie.js";
import PlantWiseConfigurations from "./components/optirun-admin/plantWiseConfig.js";
import ConsignmentData from "./components/tracking/consignmentsdataComponent.js";
import LoadingAnalysisData from "./components/tracking/loadingAnalysisData.js";
import AverageTransitTime from "./components/optirun-admin/averageTransitTime.js";

import VehiclesForecastData from "./components/vehicle-forecast/forecastVehicleConfig.js";
import ForecastOrderProcessRun from "./components/vehicle-forecast/forecastOrderPlanDispatch.js";
import VehicleForecastRunPlan from "./components/vehicle-forecast/forecastvehiclerunplan.js";
import ViewForecastDispatchPlanDetails from "./components/vehicle-forecast/viewForecastDispatchPlanDetails";
import KpiTable from "./components/vehicle-forecast/kpisummarytable.js";
import ForecastVehicleTransxHistory from "./components/vehicle-forecast/forecastVehicleTransxDetails.js";
import OBDAnalysis from "./components/vehicle-forecast/OBDAnalysis.js";
import VehicleStatusDashboard from "./components/optirun-admin/vehicleDashboard/statusofvehicles.js";
import EnmUserLogin from './components/login/enmuserlogin.js'
import ConfigsNewModel from "./components/optirun-admin/configurations/configuransnewone.js";
import PlanManagementDashboard from "./components/planEditDashboard/planManagementDashboard.js"
import ConsolidatedRunPlan from "./components/optirun-admin/consolidatedRunPlan.js";
import DeviationReports from "./components/DeviationReports/DeviationReports.js";
import OverallDashboard from "./components/dashboard/OverallDashboard.js"
import VechicleCheckList from "./components/manage/vehicleCheckList.js";
// import { element } from "three/examples/jsm/nodes/shadernode/ShaderNode.js";
import Requirements from "./components/manage/requirements.js";
import AssignRequirementToVehicle from "./components/manage/assignrequirementvehicle.js";
import ProtectedRoute from "../src/components/protectedRoute.js";
import Unauthorizedpage from "./components/unAuthorized.js";
import DailyPlansDashboard from "./components/optirun-admin/planhistorydetails/dailyplansdashboard.js";

import AddGeoFence from "./components/optirun-admin/geofence/addgeofence.js";
import GeofenceList from "./components/optirun-admin/geofence/geofencelist.js";
import EditGeoFence from "./components/optirun-admin/geofence/editgeofence.js";
import CompareActualData from "./components/optirun-admin/compareData/compareFoActualData.js";
import ActualDataFO from "./components/optirun-admin/compareData/actualdatalist.js";
import GPSQuality from "./components/dashboard/gpsQuality.js";
import RecommendedForecast from "./components/vehicle-forecast/recommandedforecast.js";
import RouteDetails from "./components/optirun-admin/routeDetailes.js";

export default function Root() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/enmuserlogin/:token" element={<EnmUserLogin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/reset" element={<ChangePassword />} />
        {/* <Route path={"/packing3d/:rowid"} element={<Packing3D />} /> */}
        {/* <Route
          path={"/previouspacking3d/:rowid"}
          element={<PreviousPacking3D />}
        /> */}
        {/* <Route path={"/packing/:rowid"} element={<Packing />} /> */}

        <Route path="/" element={<App />}>
          <Route path="/questionnarie" element={<Questionnaire />} />

          {/* <Route path={"/requirements"} element={ <ProtectedRoute element={Requirements} />} />
          <Route path="/assign_requirement_to_vehicle" element={ <ProtectedRoute element={AssignRequirementToVehicle} />} /> */}
          <Route path={"/requirements"} element={<Requirements />} />
          <Route path="/assign_requirement_to_vehicle" element={ <AssignRequirementToVehicle />}/>
          {/* <Route path="/" element={<Dashboard />} /> */}
          <Route path="/" element={<DipatchPlanHistory />} />
          <Route path="/dashboard" element={<DipatchPlanHistory />} />
          {/* <Route path="/stackplan" element={<StackingPlanSummary />} /> */}
          {/* <Route path={"/vehicleinfo"} element={<VehicleInfo />} /> */}

          {/* <Route path={"/vehicle_checklist"} element={<ProtectedRoute element={VechicleCheckList} />} /> */}
          <Route path={"/vehicle_checklist"} element={<VechicleCheckList />} />
          <Route path="/unauthorized" element={<Unauthorizedpage />} />
          {/* <Route path={"/inputplan"} element={<InputPlan />} /> */}
          {/* <Route path={"/previousplans"} element={<PreviousPlans />} /> */}
          {/* <Route path={"/binpacking"} element={<BinPacking />} /> */}
          {/* <Route path={"/loadplan"} element={<LoadPlanRoute />} /> */}
          <Route path={"/suppliers"} element={<Dealers />} />
          <Route path={"/partsdetails"} element={<DealerParts />} />
          <Route path={"/summarydashboard"} element={<SummaryDashboard />} />
          {/* <Route path={"/loadplanroute"} element={<LoadPlanScreen />} />
          <Route path={"/loadplansummary"} element={<LoadPlanSummary />} />
          <Route path={"/clusterplan"} element={<ClusterRoute />} /> */}
          {/* <Route path={"/vehicledetails"} element={<VehicleDetails />} /> */}
          {/* <Route path={"/packing3dnew"} element={<Packing3D />} /> */}
          <Route path={"/gpsquality"} element = {<GPSQuality />} />
          <Route path={"/configurations"} element={<ConfigurationsNew />} />
          <Route
            path={"/configurations?dispatch_planning"}
            element={<ConfigurationsNew />}
          />
          <Route
            path={"/configurations?stacking"}
            element={<ConfigurationsNew />}
          />
          <Route path={"/optirunadmin"} element={<OptiRunAdmin />} />
          {/* Dispatch Plan Summary */}
          <Route path={"/pickup_and_drop"} element={<PickupDropLocations />} />
          <Route path={"/vehicles_data"} element={<VehiclesData />} />
          <Route path={"/editplan_dashboard"} element={<PlanManagementDashboard />} />

          {/* <Route path={"/vehicle_availability"} element={<VehicleAvailability />} /> */}
          <Route
            path={"/vehicle_availability"}
            element={<VehicleAvailabilityDetails />}
          />
          {/* <Route path={"/transporter_details"} element={<TransporterDetails />} /> */}
          <Route
            path={"/transporter_details"}
            element={<TransporterConfiguration />}
          />
          <Route 
            path={"/unscheduledstoppages"} 
            element={<PlantDataPanel />} 
          />
          <Route
            path={"/materialconfigurations"}
            element={<MaterialConfigurations />}
          />
          <Route
            path={"/routeconfigurations"}
            element={<RouteConfigurations />}
          />
          <Route path={"/autoclustering"} element={<AutoClustering />} />
          <Route
            path={"/transportershareofbusiness"}
            element={<TransporterShareOfBusiness />}
          />
          <Route
            path={"/freightconfigurations"}
            element={<VehicleCostConfigurations />}
          />
          <Route path={"/loadprocessrun"} element={<LoadProcessRun />} />
          <Route path={"/plannewdispatch"} element={<PlanNewDispatch />} />
          <Route
            path={"/dipatchplanhistory"}
            element={<DipatchPlanHistory />}
          />
          <Route
            path={"/viewdispatchplandetails"}
            element={<ViewDispatchPlanDetails />}
          />
          <Route
            path={"/viewVehicleForecastplandetails"}
            element={<ViewForecastDispatchPlanDetails />}
          />
          <Route
            path={"/kpicomparison"}
            element={<KpiTable />}
          />
          <Route
            path={"/freightconfigurations"}
            element={<VehicleCostConfigurations />}
          />
          {/* <Route path={"/zonemapping"} element={<ZoneMapping />} /> */}
          {/* <Route path={"/zonemapping"} element={<ZoneConfiguration />} /> */}
          <Route
            path={"/dispatchplansummary"}
            element={<DispatchPlanSummary />}
          />

          {/* <Route path={"/vehiclecostconfigurations"} element={<VehicleCostConfigurations />} /> */}

          {/* STACKING PLAN */}
          <Route
            path={"/plantOrWarehouse"}
            element={<PlantWarehouseLocations />}
          />
          <Route
            path={"/stacking_vehicles"}
            element={<StackingVehicleDetails />}
          />
          <Route
            path={"/stackingmaterial_details"}
            element={<MaterialDetails />}
          />
          <Route path={"/forecast-input"} element={<ForecastInputData />} />
          <Route
            path={"/forecast-predict"}
            element={<ForecastOutputParams />}
          />
          <Route path={"/forecast-customers"} element={<ForecastCustomers />} />
          <Route path={"/forecast-materials"} element={<ForecastMaterials />} />
          <Route path={"/forecast-output"} element={<ForecastOutputData />} />
          <Route
            path={"/forecast-prev-output"}
            element={<ForecastOutputData />}
          />
          <Route
            path={"/forecast-previous"}
            element={<ForecastPreviousPlan />}
          />
          <Route path={"/forecast-suppliers"} element={<ForecastSuppliers />} />

          {/* Tracking */}
          <Route path={"/trips"} element={<ManageTruckConsignments />} />
          <Route path={"/consignments"} element={<ConsignmentData />} />
          <Route path={"/trucksgps"} element={<TrucksGPSData />} />
          <Route path={"/overspeed"} element={<OverspeedComponent />} />
          <Route
            path={"/nightdrivingreportdata"}
            element={<NightDrivingReportData />}
          />
          <Route path={"/stoppagereport"} element={<StoppageReportData />} />
          <Route
            path={"/transitdelayreport"}
            element={<TransitDelayReportData />}
          />
          <Route path={"/nogpsreport"} element={<NoGPSReports />} />
          <Route
            path={"/unloadingdashboard"}
            element={<SNDUnloadingDashboard />}
          />

          {/* {KPIDASHBOARD} */}

          <Route path={"/kpidashboard"} element={<KPIDashboard />} />
          <Route path={"/kpi_bi_dashboard"} element={<KpiPowerBi />} />
          

          {/* {newConfig} */}
          <Route path={"/configurationsnew"} element={<ConfigurationsNew />} />
          <Route path={"/plantwconfigurations"} element={<PlantWiseConfigurations />} />

          <Route path ={"/analyticsdashboard"} element={<LoadingAnalysisData/>} />
          <Route path ={"/averagetransittime"} element={<AverageTransitTime/>} />

          <Route path={"/addGeoFence"} element={<AddGeoFence/>}/>
          <Route path={"/editgeofence"} element={<EditGeoFence/>}/>
          <Route path={"/geofenceList"} element={<GeofenceList/>}/>
          <Route path={"/compareactual"} element={<CompareActualData/>}/>
          <Route path={"/actualdatafo"} element={<ActualDataFO/>}/>
          {/* {Vehicle Forecast} */}
          <Route path={"/vehicleForecastConfig"} element={<VehiclesForecastData />} />
          <Route path={"/VehicleForecastOrderDetails"} element={<ForecastOrderProcessRun />} />
          {/* <Route path={"/VehicleForecastRunPlan"} element={<VehicleForecastRunPlan />} /> */}
          <Route path={"/vehicleforecastplanhistory"} element={<ForecastVehicleTransxHistory/>}/>
          <Route path={"/loadanalysis"} element={<OBDAnalysis/>}/>
          <Route path={"/recommendedvehicles"} element={<RecommendedForecast/>}/>
          <Route path={"/vehicleStatusDashboard"} element={<VehicleStatusDashboard/>}/>
          <Route path={"/configsnewmodel"} element={<ConfigsNewModel/>}/>
          <Route path={"/consolidatedRun"} element={<ConsolidatedRunPlan/>}/>
          <Route path={"/deviationreports"} element={<DeviationReports/>}/>

          <Route path={"/dispatchplandetails"} element={<OverallDashboard />} />

          <Route path={"/dailyplans"} element={<DailyPlansDashboard />} />
          <Route path={"/route_master"} element={<RouteDetails/>}/>
          <Route path={"/route_master?outbound"} element={<RouteDetails/>}/>
          <Route path="*" element={<NoPage />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

//const rootElement = document.getElementById('root')
//ReactDOM.render(<Root />, rootElement)
