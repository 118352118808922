import React from "react";

const ConfigButton = (props) => {
  return (
    <div>
      <button className="btn btn-danger grid-btn" style={{ border: "0px" }}>
        <i className="fa fa-cogs f13"></i>{" "}
      </button>
    </div>
  );
};

export default ConfigButton;
