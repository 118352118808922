import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      please_select_scenario: "Please Select Scenario to Compare Plans",
      "Number Of Trips": "Number Of Trips",
  "Total": "Total",
  "IN416": "IN416",
  "IN436": "IN436",
  "Quantity": "Quantity",
  "Weight": "Weight",
  "Spot": "Spot",
  "Local": "Local",
  "Upcountry": "Upcountry",
  "Volume": "Volume",
  "Spot": "Spot",
  "2,177": "2,177",
  "1,268": "1,268",
  "909": "909",
  "0": "0",
  "Planned Truck Load": "Planned Truck Load",
  "Quantity (cases)": "Quantity (cases)",
  "Weight (Kgs)": "Weight (Kgs)",
  "Volume (CMT)": "Volume (CMT)",
  "1,81,970": "1,81,970",
  "25,25,554": "25,25,554",
  "8,249": "8,249",
  "Unplanned Load": "Unplanned Load",
  "26,324": "26,324",
  "3,74,549": "3,74,549",
  "1,234": "1,234",
  "Average Distance Travelled Per Vehicle (KM)": "Average Distance Travelled Per Vehicle (KM)",
  "35,426": "35,426",
  "37,624": "37,624",
  "28,111": "28,111",
  "Operational Cost (Rs.)": "Operational Cost (Rs.)",
  "1,62,57,137": "1,62,57,137",
  "1,31,64,341": "1,31,64,341",
  "30,92,797": "30,92,797",
  "Average Vehicle Fill Rate": "Average Vehicle Fill Rate",
  "60 %": "60 %",
  "42 %": "42 %",
  "79 %": "79 %",
  "Unplanned Load Proportion": "Unplanned Load Proportion",
  "12 %": "12 %",
  "Average Vehicle Utilization As Per SOB": "Average Vehicle Utilization As Per SOB",
  "104 %": "104 %",
  "94 %": "94 %",
  "Ontime Dispatches": "Ontime Dispatches",
  "Local": "Local",
  "frefe": "frefe",
  "94 %": "94 %",
  "92 %": "92 %",
  "Ontime Deliveries": "Ontime Deliveries",
  "97 %": "97 %",
  "94 %": "94 %",
      plant: "Plant",
      avg_kms_travelled: "Avg Kms Travelled",
      "driver_break_time_to": "Driver Break Time To",
"max_seating_capacity": "Max Seating Capacity",
"min_seating_capacity": "Min Seating Capacity",
"vehicle_seating_capacity": "Vehicle Seating Capacity",
"vehicle_capacity_cmt": "Vehicle Capacity (Cubic Meter)",
      "change_password": "Change Password",
  "new_password": "New Password",
  "confirm_new_password": "Confirm New Password",
  "old_password": "Old Password",
  "enter_email": "Enter your email",
  "enter_new_password": "Enter your new password",
  "confirm_new_password_entry": "Confirm your new password",
      percent_unplanned: "Percent Unplanned",
      customer: "Customer",
      standard_transit_time_days: "Standard Transit Time (Days)",
    drop_to_time: "Drop To Time",
    drop_from_time: "Drop From Time",
    pickup_to_time: "Pickup To Time",
    pickup_from_time: "Pickup From Time",
    distance_from_plant_miles: "Distance From Plant (Miles)",
    vehicle_next_available_date: "Vehicle Next Available Date",
    max_distance_per_plan_miles: "Max Distance Per Plan (Miles)",
    remaining_monthly_distance_miles: "Remaining Monthly Distance (Miles)",
    assign_quality_checks: "Assign Quality Checks",
    add_fixed_vehicle_cost: "Add Fixed Vehicle Cost",
      total_cost: "Total Cost",
      total_vehicle_fill_rate: "Total Vehicle Fill Rate",
      percent_km_run_sob: "Percent KM Run SOB",
      percent_ontime_dispatch: "Percent Ontime Dispatch",
      upcountry: "Upcountry",
      percent_spot_vehicles_trips: "Percent Spot Vehicles Trips",
      round_trip_distance: "Round Trip Distance",
      total_trip_distance: "Total Trip Distance",
      vehicle_type: "Vehicle Type",
      plant_code: "Plant Code",
      vehicle_no: "Vehicle No",
      monthly_sob_km: "Monthly SOB (Km)",
      distance_travelled_km: "Distance Travelled (Km)",
      pickup_location_source: "Pickup Location (Source)",
      drop_location_code_destination: "Drop Location Code (Destination)",
      drop_location_name_destination: "Drop Location Name (Destination)",
      order_number: "Order Number",
      invoicing_date: "Invoicing Date",
      quantity: "Quantity",
      weight_kgs: "Weight (Kgs)",
      demand_volume_m3: "Demand Volume (M3)",
      dealer_available_from: "Dealer Available From",
      dealer_available_to: "Dealer Available To",
      delivery_date: "Delivery Date",
      trip_no: "Trip No",
      planned_day: "Planned Day",
      drop_sequence: "Drop Sequence",
      destination_location_city: "Destination Location City",
      destination_location_state: "Destination Location State",
      volume_m3: "Volume (M3)",
      distance_to_destination_kms: "Distance To Destination (Kms)",
      expected_delivery_date: "Expected Delivery Date",
      time_to_destination: "Time To Destination",
      transit_time_hhmm: "Transit Time (HH:MM)",
      loading_time_hhmm: "Loading Time (HH:MM)",
      unloading_time_hhmm: "Unloading Time (HH:MM)",
      vehicles_recommended: "Vehicles Recommended",
    start_month: "Start Month",
    end_month: "End Month",
    get_data: "Get Data",
    select_chart_type: "Select Chart Type",
    fleet_analysis: "Fleet Analysis",
    system_recommended: "System Recommended",
    total_load: "Total Load",
    serviceability: "Serviceability",
    in416: "IN416",
    in436: "IN436",
    user_recommended: "User Recommended",
    threshold: "Threshold",
    number_of_trips: "Number Of Trips",
    total: "Total",
    spot: "Spot",
    planned_truck_load: "Planned Truck Load",
    quantity_cases: "Quantity (cases)",
    weight_kgs: "Weight (Kgs)",
    volume_cmt: "Volume (CMT)",
    unplanned_load: "Unplanned Load",
    average_distance_travelled_per_vehicle_km: "Average Distance Travelled Per Vehicle (KM)",
    operational_cost_rs: "Operational Cost (Rs.)",
    average_vehicle_fill_rate: "Average Vehicle Fill Rate",
    unplanned_load_proportion: "Unplanned Load Proportion",
    average_vehicle_utilization_as_per_sob: "Average Vehicle Utilization As Per SOB",
    ontime_dispatches: "Ontime Dispatches",
    local: "Local",
    ontime_delivery : "Onntime Delivery ",
    upcountry: "Upcountry",
    ontime_deliveries: "Ontime Deliveries",
    bar: "Bar",
    "Day-2": "Day-2",
  "Day-3": "Day-3",
  "Day-4": "Day-4",
  "Day-5": "Day-5",
  "Day-6": "Day-6",
  "Distance / Time From Pickup Location": "Distance / Time From Pickup Location",
  "Distance / Time From Previous Drop Point": "Distance / Time From Previous Drop Point",
  "Location City": "Location City",
  "Planned Day": "Planned Day",
  "Quantity": "Quantity",
  "Demand Weight": "Demand Weight",
  "Demand Volume": "Demand Volume",
  "Location": "Location",
  "Weight": "Weight",
  "View Onward Route": "View Onward Route",
  "View Return Route": "View Return Route",
    load: "Load",
    month: "Month",
    percentage: "%",
    "Pickup Locations (Drop)*": "Pickup Locations (Drop)*",
    duplicate_obds_light_red: "Duplicate OBDs are shown in light Red Colour",
    new_obds_green: "Newly Added OBDs are shown in Green",
    pickup_locations_drop: "Pickup Locations (Drop)",
    "pickup_locations_drop*": "Pickup Locations (Drop)*",





      // words for configurations screens
"Consider Transporter SOB": "Consider Transporter SOB",
  "Pickup Locations (Drop)*": "Pickup Locations (Drop)*",

  "enable_disable": "Enable / Disable",
  "note_highlighted_rows_red": "Note : 1. Highlighted Rows in red lack GPS data or integration, mark Vehicle Availability manually.",
  "highlighted_rows_green": "2. Highlighted Rows in Green are Considered for Planning",
  "enable_reconciliation": "Enable Reconciliation",
  "enable_dc_week_off_planning": "Enable DC Week Off planning",
  "Consider Dropping Distant Smaller Loads": "Consider Dropping Distant Smaller Loads",
  "Consider Realtime Vehicle Available Times": "Consider Realtime Vehicle Available Times",
  "Plant Wise Vehicle Type Configuration*": "Plant Wise Vehicle Type Configuration*",
  "Consignees / Dealers Locations (Drop)*": "Consignees / Dealers Locations (Drop)*",
  "Pickup Locations (Pickup)*": "Pickup Locations (Pickup)*",
  "loading....": "Loading....",
  "select_cluster": "Select Cluster",
  "Overall Cluster Wise Vehicles": "Overall Cluster Wise Vehicles",
  "Discard": "Discard",
  "obd": "OBD", // English OBD for consistency
  "Dispatch Planning is in progress..!!!": "Dispatch Planning is in progress..!!!",
  "Estimated Time": "Estimated Time",
  "GPS": "GPS",
  "Spot Vehicles To Be Planned": "Spot Vehicles To Be Planned",
  "No Of Spot Vehicles": "No Of Spot Vehicles",
  "Availability DateTime": "Availability DateTime",
  "Notify Email Users After Process (comma seperated)": "Notify Email Users After Process (comma seperated)",
  "Max Time Limit(Hrs)": "Max Time Limit(Hrs)",
  "vfr": "vfr",
  "Disclaimer": "Disclaimer : Dealer available window for OT customers will be considered from input file, GT customer timings will be considered from pickup/drop locations.",
  "To Check for Blank Data": "To Check for Blank Data 1. Click Find & Select. 2. Click to Go to Special. 3. Choose Blanks. 4. Click OK and then all the blank rows/cells will be highlighted. 5. Choose the Delete under Cells section on the Home Tab. 6. Click Delete Sheet Rows.",
  "Add New OBD": "Add New OBD",
  "All drop locations are invalid, Please check..!!": "All drop locations are invalid, Please check..!!",
  "Please upload file having extensions .csv or .xlsx only.": "Please upload file having extensions .csv or .xlsx only.",
  "Dispatch Load Volume Summary (CFT)": "Dispatch Load Volume Summary (CFT)",
  "Total Loads Volume (CFT)": "Total Loads Volume (CFT)",
  "Planned Loads Volume (CFT)": "Planned Loads Volume (CFT)",
  "Unplanned Loads Volume (CFT)": "Unplanned Loads Volume (CFT)",
  "Dropped Loads Volume (CFT)": "Dropped Loads Volume (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "Dispatch Load Weight Summary (Pounds)",
  "Total Loads Weight (Pounds)": "Total Loads Weight (Pounds)",
  "Planned Loads Weight (Pounds)": "Planned Loads Weight (Pounds)",
  "Unplanned Loads Weight (Pounds)": "Unplanned Loads Weight (Pounds)",
  "Dropped Loads Weight (Pounds)": "Dropped Loads Weight (Pounds)",
  "Dispatch Load Quantity Summary": "Dispatch Load Quantity Summary",
  "Total Quantity": "Total Quantity",
  "Planned Quantity": "Planned Quantity",
  "Unplanned Quantity": "Unplanned Quantity",
  "Dropped Quantity": "Dropped Quantity",
  "Dispatch Load Quantity Summary": "Dispatch Load Quantity Summary",
  "Dispatch Load Volume Summary (CFT)": "Dispatch Load Volume Summary (CFT)",
  "Total Loads Volume (CFT)": "Total Loads Volume (CFT)",
  "Planned Loads Volume (CFT)": "Planned Loads Volume (CFT)",
  "Unplanned Loads Volume (CFT)": "Unplanned Loads Volume (CFT)",
  "Dropped Loads Volume (CFT)": "Dropped Loads Volume (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "Dispatch Load Weight Summary (Pounds)",
  "Total Loads Weight (Pounds)": "Total Loads Weight (Pounds)",
  "Planned Loads Weight (Pounds)": "Planned Loads Weight (Pounds)",
  "Unplanned Loads Weight (Pounds)": "Unplanned Loads Weight (Pounds)",
  "Dropped Loads Weight (Pounds)": "Dropped Loads Weight (Pounds)",
  "Dispatch Load Quantity Summary": "Dispatch Load Quantity Summary",
  "Total Quantity": "Total Quantity",
  "Planned Quantity": "Planned Quantity",
  "Unplanned Quantity": "Unplanned Quantity",
  "Dropped Quantity": "Dropped Quantity",
  "Dispatch Load Weight Summary (Pounds)": "Dispatch Load Weight Summary (Pounds)",
  "Dispatch Load Volume Summary (CFT)": "Dispatch Load Volume Summary (CFT)",
  "Dropped Data": "Dropped Data",
"ontime_delivery": "Onntime Delivery",
"dealer_Available_start_time_should_be_less_than_end_time": "Dealer Available start time should be less than end time",
"Removed": "Removed",
"Unedited": "Unedited",
"Edited": "Edited",
  "Optimization Type*": "Optimization Type*",
  "Plant Wise Planning History*": "Plant Wise Planning History*",
  "Plant Wise Plan Summary*": "Plant Wise Plan Summary*",
  "Plant Wise New Plan Creation*": "Plant Wise New Plan Creation*",
  "Suppliers / Vendor Locations (Pickup)*": "Suppliers / Vendor Locations (Pickup)*",
  "Plant Wise Vehicle Type Configuration*": "Plant Wise Vehicle Type Configuration*",
  "Consignees / Dealers Locations (Drop)*": "Consignees / Dealers Locations (Drop)*",
  "Pickup Locations (Pickup)*": "Pickup Locations (Pickup)*",
  "Plant Wise Vehicle Type Configuration*": "Plant Wise Vehicle Type Configuration*",
  "Suppliers / Vendor Locations (Pickup)*": "Suppliers / Vendor Locations (Pickup)*",
  "Plant Wise Planning History*": "Plant Wise Planning History*",
  "Plant Wise Plan Summary*": "Plant Wise Plan Summary*",
  "Plant Wise New Plan Creation*": "Plant Wise New Plan Creation*",
      "Enable/Disable Dispatch Planning Module":
        "Enable/Disable Dispatch Planning Module",
      Inbound: "Inbound",
      Outbound: "Outbound",
      "Suppliers / Vendor Locations (Pickup)":
        "Suppliers / Vendor Locations (Pickup)",
      "Operational Hours": "Operational Hours",
      "Pickup Locations (Drop)": "Pickup Locations (Drop)",
      "Plant Wise Vehicle Type Configuration":
        "Plant Wise Vehicle Type Configuration",
      "Max No. Of Vehicle Types": "Max No. Of Vehicle Types",
      "Vehicle Load Capacity Min / Max Configuration Preferences":
        "Vehicle Load Capacity Min / Max Configuration Preferences",
      "Vehicle Load Weight Min / Max Preferences":
        "Vehicle Load Weight Min / Max Preferences",
      "Distance preferences": "Distance preferences",
      "Vehicle Priorities": "Vehicle Priorities",
      "Vehicle Operational Times": "Vehicle Operational Times",
      "Vehicle Availability": "Vehicle Availability",
      "Plant Wise Freight Configuration": "Plant Wise Freight Configuration",
      "Plant Wise Transporter Configuration":
        "Plant Wise Transporter Configuration",
      "Route Preferences": "Route Preferences",
      "Plant Wise New Plan Creation": "Plant Wise New Plan Creation",
      "Plant Wise Plan Summary": "Plant Wise Plan Summary",
      "Plant Wise Planning History": "Plant Wise Planning History",
      "No of Vehicles Types": "No of Vehicles Types",
      "Generic Time Windows": "Generic Time Windows",
      "Individual Vehicle Time Windows": "Individual Vehicle Time Windows",
      "Availability By Vehicle Type": "Availability By Vehicle Type",
      "Availability By Individual Vehicle":
        "Availability By Individual Vehicle",
      "Monthly Minimum Planning Distance Preferences":
        "Monthly Minimum Planning Distance Preferences",
      "GPS Based Availability": "GPS Based Availability",
      "Truck Location Visibility": "Truck Location Visibility",
      "Plant Vicinity Circle Radius": "Plant Vicinity Circle Radius",
      "Fixed Vehicle Costs": "Fixed Vehicle Costs",
      "Variable Costs": "Variable Costs",
      "Vehicle Type Level Variable Costs": "Vehicle Type Level Variable Costs",
      "City Level Vehicle Type Costs": "City Level Vehicle Type Costs",
      "Per CFT Cost": "Per CFT Cost",
      "Per Mile Cost": "Per Mile Cost",
      "Zone Level Cost": "Zone Level Cost",
      "Transporter Share of Business": "Transporter Share of Business",
      "Sustainability Integration": "Sustainability Integration",
      "Optimization Type": "Optimization Type",
      "Cost Optimization": "Cost Optimization",
      "Weight based optimization": "Weight based optimization",
      "Distance based optimization": "Distance based optimization",
      "Time based optimization": "Time based optimization",
      "Volume based optimization": "Volume based optimization",
      "Prioritize Local Loads": "Prioritize Local Loads",
      "Radius Miles": "Radius Miles",
      "Number of Loading Docks": "Number of Loading Docks",
      "City Based Entry Restriction": "City Based Entry Restriction",
      "Unloading time per case/max unloading time":
        "Unloading time per case/max unloading time",
      "Consider Vehicle Break Times (Night Driving)":
        "Consider Vehicle Break Times (Night Driving)",
      "Consider Holiday Restrictions": "Consider Holiday Restrictions",
      "Vehicle Restriction": "Vehicle Restriction",
      "Plan Spot Vehicles": "Plan Spot Vehicles",
      "Max No of Customers Per Vehicle": "Max No of Customers Per Vehicle",
      "Use Dynamic Planning Window": "Use Dynamic Planning Window",
      "Number of Days to Use Planning Window":
        "Number of Days to Use Planning Window",
        "Number of kms Travelled": "Number of kms Travelled",
"Cost": "Cost",
"Vehicle Fill Rate": "Vehicle Fill Rate",
"Vehicle KM Run %": "Vehicle KM Run %",
      "Use Volume for Vehicle Fill Rate (VFR)":
        "Use Volume for Vehicle Fill Rate (VFR)",
      "Use Weight for Vehicle Fill Rate (VFR)":
        "Use Weight for Vehicle Fill Rate (VFR)",
      "Min VFR Per Vehicle Type": "Min VFR Per Vehicle Type",
      "Min VFR Per Spot Vehicle": "Min VFR Per Spot Vehicle",
      "Prioritize Same Dealers First in a Vehicle":
        "Prioritize Same Dealers First in a Vehicle",
      "Prioritize Multi City Dealers First in a Vehicle":
        "Prioritize Multi City Dealers First in a Vehicle",
      "Dispatch within Days": "Dispatch within Days",
      "Allow Unplanned Loads": "Allow Unplanned Loads",
      "Minimize Number of Vehicles Used": "Minimize Number of Vehicles Used",
      "Dealer Buffer Time": "Dealer Buffer Time",
      "Buffer Time(Mins)": "Buffer Time(Mins)",
      "Month End Planning Start Day": "Month End Planning Start Day",
      "Day of Every Month": "Day of Every Month",
      "Max Local No of Customers Per Vehicle":
        "Max Local No of Customers Per Vehicle",
      "Max Upcountry No of Customers Per Vehicle":
        "Max Upcountry No of Customers Per Vehicle",
      "Planning Type": "Planning Type",
      "Local Prioritization": "Local Prioritization",
      "Local Prioritization and Virtual Upcountry clubbing":
        "Local Prioritization and Virtual Upcountry clubbing",
      "General Plan (Local and Upcountry clubbing)":
        "General Plan (Local and Upcountry clubbing)",
      "Telescopic Deviation": "Telescopic Deviation",
      "MOQ for Planning": "MOQ for Planning",
      Reconciliation: "Reconciliation",
      "DC Week Off planning": "DC Week Off planning",
      "Save Inbound Configurations": "Save Inbound Configurations",
      "Save Outbound Configurations": "Save Outbound Configurations",
      SAVE: "SAVE",
      "Enable Reconciliation": "Enable Reconciliation",
      "Enable DC Week Off planning": "Enable DC Week Off planning",
      "Deviation Alert": "Deviation Alert",
      "Overload Warning": "Overload Warning",
      "Underload Alert": "Underload Alert",
      "Fuel Efficiency Report": "Fuel Efficiency Report",
      "Vehicle Downtime": "Vehicle Downtime",
      "Speed Violation Alert": "Speed Violation Alert",
      "Trip Delay Notification": "Trip Delay Notification",
      "Idle Time Monitoring": "Idle Time Monitoring",
      "Driver Performance": "Driver Performance",
      "Realtime GPS Tracking": "Realtime GPS Tracking",
      "Fleet Utilization": "Fleet Utilization",
      "Trip Efficiency": "Trip Efficiency",
      "Dynamic Route Adjustment": "Dynamic Route Adjustment",
      "Load Distribution Optimization": "Load Distribution Optimization",
      "Transporter Preferences": "Transporter Preferences",
      "Trip Schedule": "Trip Schedule",
      "Driver Assignment": "Driver Assignment",
      "Optimize Vehicle Utilization": "Optimize Vehicle Utilization",
      "Time Based Planning": "Time Based Planning",
      "Plant Wise Route Planning": "Plant Wise Route Planning",
      "Vehicle Rotation Configuration": "Vehicle Rotation Configuration",
      "Inbound Vehicle Preferences": "Inbound Vehicle Preferences",
      "Outbound Vehicle Preferences": "Outbound Vehicle Preferences",
      "Vehicle Fill Rate Optimization": "Vehicle Fill Rate Optimization",
      "Dynamic Load Allocation": "Dynamic Load Allocation",
      "Start Date": "Start Date",
      "Run Plan": "Run Plan",
      "Completion Date": "Completion Date",
      "Transporter Name": "Transporter Name",
      "Driver Allocation": "Driver Allocation",
      "Max Load Capacity": "Max Load Capacity",
      "Estimated Delivery Time": "Estimated Delivery Time",
      "Use Real-Time Traffic Data": "Use Real-Time Traffic Data",
      "Driver Break Times": "Driver Break Times",
      "Max Vehicle Load Percentage": "Max Vehicle Load Percentage",
      "Telescopic Deviation Settings": "Telescopic Deviation Settings",
      "Deviation Type": "Deviation Type",
      "Spot Vehicle Usage": "Spot Vehicle Usage",
      "Real-time Load Monitoring": "Real-time Load Monitoring",
      "Driver Allocation Time": "Driver Allocation Time",
      "Use Traffic Data": "Use Traffic Data",
      "Optimize Route": "Optimize Route",
      "Optimize Fuel Efficiency": "Optimize Fuel Efficiency",
      "Daily Vehicle Plan": "Daily Vehicle Plan",
      "Transporter Allocation": "Transporter Allocation",
      "Enable Real-time Tracking": "Enable Real-time Tracking",
      "Vehicle Rotation Plan": "Vehicle Rotation Plan",
      "Route Optimization Preferences": "Route Optimization Preferences",
      "Consider Weather Conditions": "Consider Weather Conditions",
      "Max Loading Time": "Max Loading Time",
      "Min Load Capacity": "Min Load Capacity",
      "Driver Scheduling": "Driver Scheduling",
      "Vehicle Capacity Monitoring": "Vehicle Capacity Monitoring",
      "Dynamic Load Balancing": "Dynamic Load Balancing",
      "Last Mile Optimization": "Last Mile Optimization",
      "Unloading Time Management": "Unloading Time Management",
      "Daily Trip Plan": "Daily Trip Plan",
      "Delivery Window Optimization": "Delivery Window Optimization",
      "Night Driving Restrictions": "Night Driving Restrictions",
      "Weekend Planning": "Weekend Planning",

      run_new_plan: "Run New Plan",
      new_plan_details: "New Plan Details",
      order: "Order",
      vehicle_configs: "Vehicle Configs",
      order_information: "Order Information",
      plan_configs: "Plan Configs",
      vehicle_availability: "Vehicle Availability",
      vehicle_type: "Vehicle Type",
      transporter_details: "Transporter Details",
      vehicle_name: "Vehicle Name",
      vehicle_min_weight: "Vehicle Min Weight (Pounds)",
      vehicle_max_weight: "Vehicle Max Weight (Pounds)",
      vehicle_min_volume: "Vehicle Min Volume (CFT)",
      vehicle_max_volume: "Vehicle Max Volume (CFT)",
      max_distance_per_trip: "Max Distance per Trip (Miles)",
      vehicle_priority: "Vehicle Priority",
      enable_reconciliation: "Enable Reconciliation",
      enable_dc_week_off_planning: "Enable DC Week Off planning",
      order_number: "Order Number",
      invoicing_date: "Invoicing Date",
      quantity: "Quantity",
      demand_cft: "Demand (CFT)",
      weight_pounds: "Weight (Pounds)",
      dealer_available_from: "Dealer Available From",
      dealer_available_to: "Dealer Available To",
      delivery_date: "Delivery Date",
      general_settings: "General Settings",
      prioritize_local_loads: "Prioritize Local Loads",
      consider_dropping_distant_smaller_loads:
        "Consider Dropping Distant Smaller Loads",
      plan_spot_vehicles: "Plan Spot Vehicles",
      city_based_entry_restriction: "City Based Entry Restriction",
      consider_vehicle_break_times:
        "Consider Vehicle Break Times (Night Driving)",
      consider_holiday_restrictions: "Consider Holiday Restrictions",
      consider_realtime_vehicle_available_times:
        "Consider Realtime Vehicle Available Times",
      consider_transporter_sob: "Consider Transporter SOB",
      planning_optimization_settings: "Planning / Optimization Settings",
      use_dynamic_planning_window: "Use Dynamic Planning Window",
      number_of_days_to_use_planning_window:
        "Number of Days to Use Planning Window",
      optimization_type: "Optimization Type",
      cost_optimization: "Cost Optimization",
      weight_based_optimization: "Weight based optimization",
      distance_based_optimization: "Distance based optimization",
      time_based_optimization: "Time based optimization",
      volume_based_optimization: "Volume based optimization",
      vehicle_fill_rate_vfr_settings: "Vehicle Fill Rate (VFR) Settings",
      use_volume_for_vfr: "Use Volume for Vehicle Fill Rate (VFR)",
      use_weight_for_vfr: "Use Weight for Vehicle Fill Rate (VFR)",
      min_vfr_per_vehicle_type: "Min VFR Per Vehicle Type",
      min_vfr_per_spot_vehicle: "Min VFR Per Spot Vehicle",
      moq_for_planning: "MOQ for Planning",
      load_planning_settings: "Load Planning Settings",
      number_of_loading_docks: "Number of Loading Docks",
      max_local_no_of_customers_per_vehicle:
        "Max Local No of Customers Per Vehicle",
      max_upcountry_no_of_customers_per_vehicle:
        "Max Upcountry No of Customers Per Vehicle",
      prioritize_same_dealers_first:
        "Prioritize Same Dealers First in a Vehicle",
      prioritize_multi_city_dealers_first:
        "Prioritize Multi City Dealers First in a Vehicle",
      note_highlighted_rows_red:
        "Highlighted Rows in red lack GPS data or integration, mark Vehicle Availability manually.",
      note_highlighted_rows_green:
        "Highlighted Rows in Green are Considered for Planning",
      vehicle_number: "Vehicle Number",
      transit_type: "Transit Type",
      monthly_minimum_distance: "Monthly Minimum Distance",
      remaining_distance: "Remaining Distance",
      vehicle_available_date: "Vehicle Available Date (YYYY-MM-DD HH:mm)",
      charlie_hub: "Charlie Hub",
      enable_recommendation: "Enable Recommendation",
      moq_for_planning: "MOQ for Planning",
      planned_day: "Planned Day",
      drop_sequence: "Drop Sequence",
      drop_location_code_destination: "Drop Location Code (Destination)",
      drop_location_name_destination: "Drop Location Name (Destination)",
      destination_location_city: "Destination Location City",
      weight_kgs: "Weight (Kgs)",
      volume_m3: "Volume (M3)",
      distance_to_destination_kms: "Distance To Destination (Kms)",
      expected_delivery_date: "Expected Delivery Date",
      time_to_destination: "Time To Destination",
      transit_time_hh_mm: "Transit Time (HH:MM)",
      unloading_time_hh_mm: "Unloading Time (HH:MM)",
      transit_type: "Transit Type",
      plan_id: "Plan ID",
      generated_on: "Generated On",
      vehicle_availability_from: "Vehicle Availability (from)",
      vehicle_availability_to: "Vehicle Availability (to)",
      break_time_from: "Break Time (from)",
      break_time_to: "Break Time (to)",
      min_volume_m3: "Min Volume (M3)",
      max_volume_m3: "Max Volume (M3)",
      vehicle_width_mm: "Vehicle Width (mm)",
      vehicle_height_mm: "Vehicle Height (mm)",
      vehicle_depth_mm: "Vehicle Depth (mm)",
      material_code: "Material Code",
      material_name: "Material Name",
      material_type: "Material Type",
      material_weight: "Material Weight",
      material_dimensions_depth: "Material Dimensions Depth",
      material_dimensions_width: "Material Dimensions Width",
      material_dimensions_height: "Material Dimensions Height",
      state: "State",
      city: "City",
      fixed_vehicle_cost: "Fixed Vehicle Cost",
      driver_cost: "Driver Cost",
      maintenance_cost: "Maintenance Cost",
      per_cmt_cost: "Per CMT Cost",
      per_km_cost: "Per KM Cost",
      date: "Date",
      transporter_name: "Transporter Name",
      no_of_vehicles: "No. of Vehicles",
      driver_break_time_from: "Driver Break Time (from)",
      vehicle_max_weight_kgs: "Vehicle Max Weight (Kgs)",
      vehicle_min_volume_m3: "Vehicle Min Volume (M3)",
      vehicle_max_volume_m3: "Vehicle Max Volume (M3)",
      max_distance_kms: "Max Distance (Kms)",
      monthly_min_planning_distance_kms: "Monthly Min Planning Distance (Kms)",
      details: "Details",
      trip_no: "Trip No",
      vehicle_no: "Vehicle No",
      no_of_orders: "No Of Orders",
      no_of_customers: "No Of Customers",
      total_quantity: "Total Quantity",
      vehicle_utilization_percent: "Vehicle Utilization (%)",
      total_planned_weight_kgs: "Total Planned Weight (Kgs)",
      onward_trip_distance_kms: "Onward Trip Distance (Kms)",
      round_trip_distance_kms: "Round Trip Distance (Kms)",
      total_planned_volume_m3: "Total Planned Volume (M3)",
      max_dispatch_time_hh_mm: "Max Dispatch Time (HH:MM)",
      arrival_at_first_dealer_start: "Arrival At First Dealer (Start)",
      departure_at_last_dealer_end: "Departure At Last Dealer (End)",
      return_to_plant_hh_mm: "Return to Plant (HH:MM)",
      total_planned_trip_time_hrs: "Total Planned Trip Time (Hrs)",
      total_round_trip_time_hrs: "Total Round Trip Time (Hrs)",
      total_transit_time_hh_mm: "Total Transit Time (HH:MM)",
      total_loading_time_hh_mm: "Total Loading Time (HH:MM)",
      total_unloading_time_hh_mm: "Total Unloading Time (HH:MM)",
      drop_location_code: "Drop Location Code",
      drop_location_name: "Drop Location Name",
      drop_location_city: "Drop Location City",
      demand_volume_m3: "Demand Volume (M3)",
      vehicle_min_weight_kgs: "Vehicle Min Weight (Kgs)",
      no_of_deliveries: "No Of Deliveries",
      min_load_per_delivery: "Min Load Per Delivery",
      status: "Status",
      processing: "Processing",
      please_contact_admin: "Please Contact Admin",
      solver_complete: "SOLVER COMPLETE",
      solver_reco_processed: "SOLVER RECO PROCESSED",
      solver_sob_done: "SOLVER SOB DONE",
      solver_invalid_input_dealer_location:
        "SOLVER INVALID INPUT DEALER LOCATION",
      solver_invalid_input_materials: "SOLVER INVALID INPUT MATERIALS",
      solver_invalid_input_dealer_location_and_materials:
        "SOLVER INVALID INPUT DEALER LOCATION AND MATERIALS",
      solver_input_data_not_valid: "SOLVER INPUT DATA NOT VALID",
      solver_fix_dropped_dealers: "SOLVER FIX DROPPED DEALERS",
      solver_vehicle_details_missing: "SOLVER VEHICLE DETAILS MISSING",
      solver_input_file_data_error: "SOLVER INPUT FILE DATA ERROR",
      solver_invalid_file_template: "SOLVER INVALID FILE TEMPLATE",
      process_timed_out: "PROCESS TIMED OUT",
      solver_sob_fail: "SOLVER SOB FAIL",
      solver_reco_failed: "SOLVER RECO FAILED",
      solver_no_tool_config: "SOLVER NO TOOL CONFIG",
      solver_invalid_tool_config: "SOLVER INVALID TOOL CONFIG",
      transit_time_with_multiple_city_clubing:
        "Transit Time With Multiple City Clubbing",
      rdd_preference: "RDD Preference",
      transit_time_with_single_city_clubing:
        "Transit Time With Single City Clubbing",
        compare: "Compare",
      no_of_trucks_planned: "No. of Trucks Planned",
      view_details: "View Details",
      plan_run_by: "Plan Run By",
      compare_plans: "Compare Plans",
      scenario_name: "Scenario Name",
      pick_up_location_source: "Pick Up Location (Source)",
      pickup_location_source: "Pickup Location (Source)",
      drop_location_destination: "Drop Location (Destination)",
      demand_m3: "Demand (M3)",

      location_radius: "Location Radius (KMS)",
      volume_cmt: "Volume (CMT)",
      obd_details_in_radius: "OBD Details With In Radius",
      planning_configurations: "Planning Configurations",
      planning_scenarios: "Planning Scenarios",
      what_if_demand_fluctuation: "What-If : Demand Fluctuation",
      increase_decrease_demand_by: "Increase/Decrease demand by",
      what_if_customer_priority_changes: "What-If : Customer Priority Changes",
      prioritize_upcountry: "Prioritize Upcountry",
      prioritize_local: "Prioritize Local",
      what_if_service_level_target_changes:
        "What-If : Service Level Target Changes",
      set_service_level_target_to: "Set Service Level Target to",
      what_if_vehicle_fill_rate_changes: "What-If : Vehicle Fill Rate Changes",
      set_vehicle_fill_rate_to: "Set Vehicle Fill Rate to",
      what_if_customer_specific_delivery_time:
        "What-If : Customer Specific Delivery Time",
      increase_decrease_delivery_time_window_by:
        "Increase/Decrease Delivery Time Window by",
      scenario_description: "Scenario Description",
      vehicle_fill_rate_settings: "Vehicle Fill Rate (VFR) Settings",
      file_name: "File Name",
      total_weight_kgs: "Total Weight (Kgs)",
      total_demand_volume_m3: "Total Demand Volume (M3)",
      processing_plan_please_wait: "Processing Plan. Please wait.....",
      forecast_input_information: "Forecast Input Information",
      uploading_data_for_plant: "Uploading Data For Plant",
      vehicle_type_configuration: "Vehicle Type Configuration",
      min_volume_cft: "Min Volume (CFT)",
      max_volume_cft: "Max Volume (CFT)",
      cost_per_km: "Cost Per Km",
      note_duplicate_scenario: "Note: Duplicate Scenario",
      select_unique_scenarios_to_compare:
        "Please Select Unique Scenario's to Compare Plans",
      scenario_selection_limit_exceeded: "Scenario's Selection Limit Exceeded",
      note_scenario_selection_limit:
        "Note: You can select up to Three Scenario's",
      select_scenario_to_compare: "Please Select Scenario to Compare Plans",
      forecast_scenarios: "Forecast Scenarios",
      select_planning_duration: "Select Planning Duration",
      notify_email_users_after_process:
        "Notify Email Users After Process (comma separated)",
      optimize_by_time_details: "Optimize by Time Details",
      max_time_limit_hrs: "Max Time Limit (Hrs)",
      spot_vehicles_to_be_planned: "Spot Vehicles To Be Planned",
      auto_select: "Auto Select",

      planned_day: "Planned Day",
      drop_sequence: "Drop Sequence",
      drop_location_code_destination: "Drop Location Code (Destination)",
      drop_location_name_destination: "Drop Location Name (Destination)",
      destination_location_city: "Destination Location City",
      dealer_type: "Dealer Type",
      customer_type: "Customer Type",
      week_off: "Week Off",
      order_number: "Order Number",
      invoicing_date: "Invoicing Date",
      quantity: "Quantity",
      weight_pounds: "Weight (Pounds)",
      volume_cft: "Volume (CFT)",
      distance_to_destination_miles: "Distance To Destination (Miles)",
      expected_delivery_date: "Expected Delivery Date",
      time_to_destination: "Time To Destination",
      transit_time_hh_mm: "Transit Time (HH:MM)",
      unloading_time_hh_mm: "Unloading Time (HH:MM)",
      email: "Email",
      details: "Details",
      view: "View",
      assign_vehicle: "Assign Vehicle",
      revoke_assignment: "Revoke Assignment",
      edit_plan: "Edit Plan",
      delete: "Delete",
      plan_edited: "Plan Edited",
      yes: "Yes",
      no: "No",
      trip_no: "Trip No",
      vehicle_no: "Vehicle No",
      vehicle_type: "Vehicle Type",
      cost: "Cost ($)",
      transit_type: "Transit Type",
      transporter_name: "Transporter Name",
      no_of_orders: "No Of Orders",
      no_of_customers: "No Of Customers",
      total_quantity: "Total Quantity",
      vehicle_utilization_percent: "Vehicle Utilization (%)",
      vehicle_max_weight: "Vehicle Max Weight (Pounds)",
      total_planned_weight: "Total Planned Weight (Pounds)",
      onward_trip_distance_miles: "Onward Trip Distance (Miles)",
      round_trip_distance_miles: "Round Trip Distance (Miles)",
      vehicle_max_volume_cft: "Vehicle Max Volume (CFT)",
      total_planned_volume_cft: "Total Planned Volume (CFT)",
      max_dispatch_time: "Max Dispatch Time (HH:MM)",
      arrival_at_first_dealer: "Arrival At First Dealer (Start)",
      departure_at_last_dealer: "Departure At Last Dealer (End)",
      return_to_plant: "Return to Plant (HH:MM)",
      total_planned_trip_time: "Total Planned Trip Time(Hrs)",
      total_round_trip_time: "Total Round Trip Time(Hrs)",
      total_transit_time: "Total Transit Time (HH:MM)",
      total_loading_time: "Total Loading Time (HH:MM)",
      total_unloading_time: "Total Unloading Time (HH:MM)",
      remark: "Remark",
      "n/a": "N/A",
      revoke_again_to_edit: "Revoke Again To Edit",
      drop_location_code: "Drop Location Code",
      drop_location_name: "Drop Location Name",
      drop_location_city: "Drop Location City",
      distance_from_plant: "Distance from Plant",
      demand_volume_cft: "Demand Volume (CFT)",
      delivery_date: "Delivery Date",
      actions: "Actions",
      pickup_location_source: "Pickup Location (Source)",
      dealer_available_from: "Dealer Available From",
      dealer_available_to: "Dealer Available To",
      loading_time_hh_mm: "Loading Time (HH:MM)",
      dropped_reason: "Dropped Reason",
      dealer_location_data_not_in_system: "Dealer Location Data Not In System",
      delivery_date_less_than_current_date:
        "Delivery Date is less than Current Date",
      kdd_customer: "KDD Customer",
      insufficient_load_for_planning: "Insufficient Load for Planning(< MOQ)",
      warehouse_name: "Warehouse Name",
      destination_city: "Destination City",
      destination_state: "Destination State",
      sales_order_no: "Sales Order No",
      no_of_tires: "No. of Tires",
      order_date: "Order Date",
      vehicle_name: "Vehicle Name",
      vehicle_priority: "Vehicle Priority",
      vehicle_availability_from: "Vehicle Availability (from)",
      vehicle_availability_to: "Vehicle Availability (to)",
      break_time_from: "Break Time (from)",
      break_time_to: "Break Time (to)",
      min_volume_cft: "Min Volume (CFT)",
      max_volume_cft: "Max Volume (CFT)",
      vehicle_dimensions_width_mm: "Vehicle Width (mm)",
      vehicle_dimensions_height_mm: "Vehicle Height (mm)",
      vehicle_dimensions_depth_mm: "Vehicle Depth (mm)",
      vehicle_min_weight: "Vehicle Min Weight (Pounds)",
      max_no_of_nodes: "No Of Deliveries",
      min_load_per_delivery: "Min Load Per Delivery",
      max_distance: "Max Distance (Miles)",
      material_code: "Material Code",
      material_name: "Material Name",
      material_type: "Material Type",
      material_weight: "Material Weight",
      material_dimensions_depth: "Material Dimensions Depth",
      material_dimensions_width: "Material Dimensions Width",
      material_dimensions_height: "Material Dimensions Height",
      form_vehicle_types: "Vehicle Types",
      dealer_form_state: "State",
      dealer_form_city: "City",
      freight_fixed_vehicle_cost: "Fixed Vehicle Cost",
      freight_driver_cost: "Driver Cost",
      freight_maintenance_cost: "Maintenance Cost",
      freight_per_cmt_cost: "Per CMT Cost",
      freight_per_km_cost: "Per KM Cost",
      vehicle_date: "Date",
      vehicle_number: "Vehicle Number",
      plant_code: "Plant Code",
      plant_name: "Plant Name",
      available_time: "Available Time",
      gps_status: "GPS Status",
      last_known_address: "Last Known Address",
      last_gps_time: "Last GPS Time",
      vehicle_location: "Vehicle Location",
      assigned_to_ot: "Assigned To OT",
      vehicle_number: "Vehicle Number",
      vehicle_type: "Vehicle Type",
      transporter_code: "Transporter Code",
      transporter_name: "Transporter Name",
      max_distance_per_plan: "Max Distance Per Plan",
      monthly_minimum_distance: "Monthly Minimum Distance",
      remaining_distance: "Remaining Distance",
      vehicle_available_date_yyyy_mm_dd_hh_mm:
        "Vehicle Available Date (YYYY-MM-DD HH:mm)",
      no_of_vehicles: "No Of Vehicles",
      vehicle_type: "Vehicle Type",
      vehicle_name: "Vehicle Name",
      vehicle_min_weight_kgs: "Vehicle Min Weight (Kgs)",
      vehicle_max_weight_kgs: "Vehicle Max Weight (Kgs)",
      vehicle_min_volume_m3: "Vehicle Min Volume (M3)",
      vehicle_max_volume_m3: "Vehicle Max Volume (M3)",
      max_distance_per_trip_kms: "Max Distance per Trip (Kms)",
      monthly_min_planning_distance_kms: "Monthly Min Planning Distance (Kms)",
      vehicle_priority: "Vehicle Priority",
      dealer: "Dealer",
      demand: "Demand",
      remarks: "Remarks",

      run_new_plan: "Run New Plan",
      min_vfr: "Min VFR",
      order_information: "Order Information",
      vehicle_type_configurations: "Vehicle Type Configurations",
      vehicle_routes: "Vehicle Routes",
      total_customers: "Total Customers",
      planned: "Planned",
      unplanned: "Unplanned",
      total_round_trip_distance_miles: "Total Round Trip Distance (Miles)",
      planning: "Planning",
      vfr: "VFR",
      ontime_dispatch: "Ontime Dispatch",
      avg_tat: "Avg TAT",
      view_locations: "View Locations",
      view_routes: "View Routes",
      additional_vehicles: "Additional Vehicles",
      regular_vehicles: "Regular Vehicles",
      enter_vehicle_no: "Enter Vehicle No",
      vehicle_has_active_trip_shipment_id:
        "Vehicle has an Active Trip Shipment ID",
      do_you_wish_to: "Do you wish to",
      force_close_assign: "Force Close & Assign",
      force_close_reason: "Force Close Reason",
      gps_issue: "GPS Issue",
      trip_closed: "Trip Closed",
      load_cancelled: "Load Cancelled",
      new_trip_allocated: "New Trip Allocated",
      driver_changed: "Driver Changed",
      are_you_sure_you_want_to_update: "Are you sure you want to update",
      edit_plan_for_same: "Edit Plan for the same",
      update_vehicle_plan: "Update Vehicle Plan",
      max_dispatch_time: "Max Dispatch Time",
      new_plan: "New Plan",
      reason_for_plan_change: "Reason for plan change",
      enter_the_reason: "Enter the Reason",
      reason_for_plan_deletion: "Reason for plan Deletion",
      are_you_sure_delete_plan: "Are you sure, You want to Delete Plan",
      update_order_details: "Update Order Details",
      demand_volume: "Demand Volume",
      dealer_availability_start_time: "Dealer Availability Start Time",
      dealer_availability_end_time: "Dealer Availability End Time",
      download: "Download",
      dealerTimeError:
        "Dealer Available start time should be less than end time",
      more: "More",
      less: "Less",
      qualityChecks: "Quality Checks",
      currentMonthPlanDistance: "Current Month Plan Distance",
      email: "E Mail",
      viewPlan: "View Plan",
      configurations: "Configurations",
      blank_rows_columns_not_allowed: "Blank rows/columns are not allowed.",
      import_existing_vehicle_details: "Import Existing Vehicle Details",
      uploading_data_for_plant: "Uploading Data For the Plant",
      check_and_remove_blank_data:
        "To check and remove blank data, use Find and Select → Go to Special → Blanks, then delete the highlighted rows.",
      add_vehicle_type: "Add Vehicle Type",
      vehicle_operational_hours: "Vehicle Operational Hours (HH:MM)",
      data_saved_successfully: "Data Saved Successfully",
      plant_wise_configurations: "Plant-wise Configurations",
      save_plant_wise_configurations: "Save Plant-wise Configurations",
      vehicle_break_window: "Vehicle Break Window (HH:MM)",
      total_vehicles: "Total Vehicles",
      zone_configurations: "Zone Configurations",
      pickup_point: "Pickup Point",
      actions: "Actions",
      edit: "Edit",
      add_transporter_details: "+ Add Transporter Details",
      cost_per_mile: "Cost Per Mile",
      freight_details: "Freight Details",
      add_zone: "+ Add Zone",
      fixed_vehicle_cost_title: "Fixed Vehicle Cost ($)",
      variable_vehicle_cost_title: "Variable Vehicle Cost",
      city_wise_cost: "City-wise Vehicle Cost",
      zone_wise_cost: "Zone-wise Vehicle Cost",
      vehicle_cost_configurations: "Vehicle Cost Configurations",
      freight_cost_label: "Freight Cost",
      validity_to_label: "Valid To",
      validity_from_label: "Valid From",
      actions_label: "Actions",
      edit_button: "Edit",
      delete_button: "Delete",
      save_button: "Save",
      cancel_button: "Cancel",
      select_vehicle_type: "Select Vehicle Type",
      select_transporter_name: "Select Transporter Name",
      zone: "Zone",
      state: "State",
      driver_cost: "Driver Cost ($)",
      maintenance_cost: "Maintenance Cost ($)",
      fixed_vehicle_cost_label: "Fixed Vehicle Cost ($)",
      enable_disable_dispatch_planning_module:
        "Enable/Disable Dispatch Planning Module",
      inbound: "Inbound",
      suppliers_vendor_locations_pickup:
        "Suppliers / Vendor Locations (Pickup)",
      operational_hours: "Operational Hours",
      pickup_locations_drop: "Pickup Locations (Drop)",

      plant_wise_vehicle_type_configuration:
        "Plant-wise Vehicle Type Configuration",
      max_no_of_vehicle_types: "Max No. of Vehicle Types",
      vehicle_load_capacity_min_max_configuration_preferences:
        "Min/Max Vehicle Load Capacity Configuration Preferences",
      vehicle_load_weight_min_max_preferences:
        "Min/Max Vehicle Load Weight Preferences",
      distance_preferences: "Distance Preferences",
      vehicle_priorities: "Vehicle Priorities",
      vehicle_operational_times: "Vehicle Operational Times",
      vehicle_availability: "Vehicle Availability",
      generic_time_windows: "Generic Time Windows",
      individual_vehicle_time_windows: "Individual Vehicle Time Windows",
      availability_by_vehicle_type: "Availability by Vehicle Type",
      availability_by_individual_vehicle: "Availability by Individual Vehicle",
      monthly_minimum_planning_distance_preferences:
        "Monthly Minimum Planning Distance Preferences",
      gps_based_availability: "GPS Based Availability",
      truck_location_visibility: "Truck Location Visibility",
      plant_vicinity_circle_radius: "Plant Vicinity Circle Radius",
      plant_wise_freight_configuration: "Plant-wise Freight Configuration",
      plant_wise_transporter_configuration:
        "Plant-wise Transporter Configuration",
      transporter_share_of_business: "Transporter Share of Business",
      route_preferences: "Route Preferences",
      plant_wise_new_plan_creation: "Plant-wise New Plan Creation",
      plant_wise_plan_summary: "Plant-wise Plan Summary",
      plant_wise_planning_history: "Plant-wise Planning History",
      sustainability_integration: "Sustainability Integration",
      save_inbound_configurations: "Save Inbound Configurations",
      save_outbound_configurations: "Save Outbound Configurations",
      outbound: "Outbound",
      consignees_dealers_locations_drop:
        "Consignees / Dealers Locations (Drop)",
      select_vehicle_type: "Select Vehicle Type",
      select_transporter_name: "Select Transporter Name",
      zone: "Zone",
      state: "State",
      driver_cost: "Driver Cost ($)",
      maintenance_cost: "Maintenance Cost ($)",
      fixed_vehicle_cost_label: "Fixed Vehicle Cost ($)",
      delete: "Delete",
      state: "State",
      city: "City",
      zone: "Zone",
      delta_warehouse: "Delta Warehouse",
      zones: "Zones",
      zone_mapping: "Zone Mapping",
      city_not_mapped_to_state: "This city is not mapped to any state",
      actions: "Actions",
      add_zones: "+ Add Zones",
      transporter_master: "Transporter Master",
      transporter_configurations: "Transporter Configurations",
      transporter_operations: "Transporter Operations",
      transporter_code: "Transporter Code",
      transporter_name: "Transporter Name",
      invalid_orders_not_shown: "Invalid orders are not being shown",
    note: "Note",
      actions: "Actions",
      edit: "Edit",
      delete: "Delete",
      zone_configuration: "Zone Configuration",
      vehicle_checklist_for_transportation_requirements:
        "Vehicle Checklist for Transportation Requirements",
      transporters_configuration: "Transporters Configuration",
      consolidated_route_plan_screen: "Consolidated Route Plan Screen",
      plant_code: "Plant Code",
      select_plant_to_check_vehicle_availability:
        "Select plant to check vehicle availability",
      vehicle_availability: "Vehicle Availability",
      note_trucks_near_plant_vicinity:
        "Note: Trucks near the plant at a distance of ",
      facility_name: "Facility Name",
      vehicle_number: "Vehicle Number",
      transporter_code: "Transporter Code",
      vehicle_status_dashboard: "Vehicle Status Dashboard",
      dc: "DC",
      vehicles: "Vehicles",
      total_trucks: "Total Trucks",
      active: "Active",
      cost_details: "Cost Details",
      fixed_vehicle_cost: "Fixed Vehicle Cost",
      variable_vehicle_cost: "Variable Vehicle Cost",
      city_wise_vehicle_cost: "City-Wise Vehicle Cost",
      zone_wise_vehicle_cost: "Zone-Wise Vehicle Cost",
      pickup_point: "Pickup Point",
      vehicle_type: "Vehicle Type",
      transporter_name: "Transporter Name",
      cost_per_mile_below_sob: "Cost Per Mile (Below SOB)",
      cost_per_mile_after_sob: "Cost Per Mile (After SOB)",
      validity_from: "Validity From",
      validity_to: "Validity To",
      add_vehicle_cost: "+ Add Vehicle Cost",
      freight_details: "Freight Details",
      select_transporter: "Select Transporter",
      select_zone: "Select Zone",
      select_state: "Select State",
      city: "City",
      cost: "Cost ($)",
      save: "Save",
      cancel: "Cancel",
      actions: "Actions",
      validity_from_label: "Validity From",
      validity_to_label: "Validity To",
      get_data: "Get Data",
      state: "State",
      zone: "Zone",
      fixed_vehicle_cost_label: "Fixed Vehicle Cost ($)",
      driver_cost: "Driver Cost ($)",
      maintenance_cost: "Maintenance Cost ($)",
      city: "City",
      zone: "Zone",
      vehicle_cost: "Vehicle Cost",
      freight_cost: "Freight Cost",
      inactive: "Inactive",
      inside_dc: "Inside DC",
      in_transit_to_customer: "In Transit to Customer",
      near_customer: "Near Customer",
      return_to_dc: "Return to DC",
      empty_outside_dc: "Empty Outside DC",
      zone_configurations: "Zone Configurations",
      transporters_configurations: "Transporters Configurations",
      truck_no: "Truck No",
      facility_name: "Facility Name",
      planned_distance_miles: "Planned Distance (Miles)",
      restriction_hours: "Restriction Hours",
      save_geofence: "Save Geofence",
      no_data: "No Data",
      redirecting_to_vehicle_checklist: "Redirecting To Vehicle Checklist",
      zones: "Zones",
      actions: "Actions",
      edit: "Edit",
      delete: "Delete",
      add_zone_mapping: "Add Zone Mapping",
      current_status: "Current Status",
      next_availability: "Next Availability",
      dc_location: "DC Location",
      add_route_restriction: "Add Route Restriction",
      location_code: "Location Code",
      location_name: "Location Name",
      restricted_area: "Restricted Area",
      vehicle_list: "Vehicle List",
      from_time: "From Time",
      to_time: "To Time",
      actions: "Actions",
      edit: "Edit",
      customer_location: "Customer Location",
      truck_no: "Truck No",
      facility_name: "Facility Name",
      current_status: "Current Status",
      inside_dc: "Inside DC",
      in_transit_to_customer: "In Transit to Customer",
      near_customer: "Near Customer",
      return_to_dc: "Return to DC",
      empty_outside_dc: "Empty Outside DC",
      unplanned: "Unplanned",
      next_availability_date_at_dc: "Next Availability Date at DC",
      transporter_name: "Transporter Name",
      gps_status: "GPS Status",
      inactive: "Inactive",
      active: "Active",
      na: "NA",
      actions: "Actions",
      date_and_time: "Date and Time",
      security: "Security",
      quality: "Quality",
      assessed_by: "Assessed By",
      quality_conclusion: "Quality Conclusion",
      quality_comments: "Quality Comments",
      quality_approved: "Quality Approved",
      quality_date_of_assessment: "Quality Date of Assessment",
      facility_name: "Facility Name",
      vehicle_number: "Vehicle Number",
      vehicle_type: "Vehicle Type",
      transporter_code: "Transporter Code",
      transporter_name: "Transporter Name",
      last_packet_time: "Last Packet Time",
      vehicle_checklist_for_transportation:
        "Vehicle Checklist for Transportation",
      assessed_by: "Assessed By",
      security_conclusion: "Security Conclusion",
      security_comments: "Security Comments",
      security_approved: "Security Approved",
      security_date_of_assessment: "Security Date of Assessment",
      view: "View",
      location_code: "Location Code",
      vehicle_no: "Vehicle Number",
      stage: "Stage",
      quality_rejected: "Quality Rejected",
      requirements_set: "Requirements Set",
      security_rejected: "Security Rejected",
      security_accepted: "Security Accepted",
      assessment_date: "Assessment Date",
      requirement: "Requirement",
      satisfied: "Satisfied",
      yes: "Yes",
      no: "No",
      requirement_set_date: "Requirement Set Date",
      temperature: "Temperature",
      vehicle_operational_from: "Vehicle Operational From",
      vehicle_operational_to: "Vehicle Operational To",
      break_time_from: "Break Time From",
      break_time_to: "Break Time To",
      transporter_name: "Transporter Name",
      last_packet_time: "Last Packet Time",
      temperature: "Temperature",
      view_vehicles: "View Vehicles",
      transporter_name: "Transporter Name",
      max_distance_per_plan_miles: "Max Distance per Plan (Miles)",
      remaining_monthly_distance_miles: "Remaining Monthly Distance (Miles)",
      vehicle_next_available_date: "Vehicle Next Available Date",
      owned: "Owned",
      contract: "Contract",
      high: "High",
      medium: "Medium",
      low: "Low",
      market: "Market",
      vehicles_for_today: "Vehicles for Today",
      bulk_upload_master_vehicles: "Bulk Upload Master Vehicles",
      add_master_vehicle: "Add Master Vehicle",
      transporter_name: "Transporter Name",
      transit_type: "Transit Type",
      actions: "Actions",
      facility_name: "Facility Name",
      vehicle_type: "Vehicle Type",
      vehicle_number: "Vehicle Number",
      vehicle_priority: "Vehicle Priority",
      max_distance_per_plan_miles: "Max Distance per Plan (Miles)",
      max_weight_pounds: "Max Weight (Pounds)",
      max_volume_cft: "Max Volume (CFT)",
      monthly_min_planning_distance_miles:
        "Monthly Min Planning Distance (Miles)",
      remaining_monthly_planning_distance_miles:
        "Remaining Monthly Planning Distance (Miles)",
      next_available_date_yyyy_mm_dd_hh_mm:
        "Next Available Date (YYYY-MM-DD HH:MM)",
      vehicle_break_down: "Vehicle Break Down",
      drop_location_code: "Drop Location Code",
      arrival_at_first_dealer: "Arrival at First Dealer",
      departure_at_last_dealer: "Departure at Last Dealer",
      average_payload: "Average Payload (Tons)",
      mileage: "Mileage (Miles/Gallon)",
      fuel_type: "Fuel Type",
      temperature_controlled: "Temperature Controlled",
      load_characteristics: "Load Characteristics",
      glec_vehicle_type: "GLEC Vehicle Type",
      return_to_plant: "Return to Plant",
      summary_dashboard: "Summary Dashboard",
      dispatch_plan_details: "Dispatch Plan Details",
      invalid_orders_note: "Note: Invalid orders are not shown",
      pickup_point: "Pickup Point",
      vehicle_type_configuration: "Vehicle Type Configuration",
      vehicle_type: "Vehicle Type",
      vehicle_type_name: "Vehicle Type Name",
      min_volume: "Min Volume (CFT)",
      max_volume: "Max Volume (CFT)",
      vehicle_priority: "Vehicle Priority",
      min_weight: "Min Weight (Pounds)",
      max_weight: "Max Weight (Pounds)",
      max_distance_trip: "Max Distance per Trip (Miles)",
      monthly_min_planning_distance: "Monthly Min Planning Distance (Miles)",
      cancel: "Cancel",
      save: "Save",
      facility_name: "Facility Name",
      vehicle_no: "Vehicle No.",
      address: "Address",
      last_packet_time: "Last Packet Time",
      latitude: "Latitude",
      longitude: "Longitude",
      service_provider: "Service Provider",
      temperature: "Temperature",
      on_trip: "On Trip",
      requirement: "Requirement",
      requirement_type: "Requirement Type",
      security: "Security",
      assessed_by: "Assessed By",
      security_conclusion: "Security Conclusion",
      security_comments: "Security Comments",
      assessment_start_date: "Assessment Start Date",
      assessment_end_date: "Assessment End Date",
      get_data: "Get Data",
      add_requirements: "Add Requirements",
      add_vehicle_checklist: "Add Vehicle Checklist",
      actions: "Actions",
      edit: "Edit",
      delete: "Delete",
      vehicle_configurations: "Vehicle Configurations",
      vehicle_availability_configurations:
        "Vehicle Availability Configurations",
      vehicle_location: "Vehicle Location",
      vehicle_master: "Vehicle Master",
      vehicle_availability: "Vehicle Availability",
      vehicle_details: "Vehicle Details",
      monthly_min_distance: "Monthly Minimum Distance (Miles)",
      max_weight_per_trip: "Max Weight per Trip (Pounds)",
      max_distance_per_trip: "Max Distance per Trip (Miles)",
      planned_data: "Planned Data",
      vfr_percentage: "VFR Percentage",
      cool_chain_adherence: "Cool Chain Adherence",
      transit_time: "Transit Time",
      loading_time: "Loading Time",
      unloading_time: "Unloading Time",
      planned_summary: "Planned Summary",
      invalid_drop_data: "Invalid Drop Data",
      vehicle_utilization_km: "Vehicle Utilization (KM)",
      distance_to_destination: "Distance to Destination",
      expected_delivery_date: "Expected Delivery Date",
      trip_no: "Trip Number",
      total_quantity: "Total Quantity",
      demand_volume_cft: "Demand Volume (CFT)",
      weight_pounds: "Weight (Pounds)",
      quantity: "Quantity",
      dropped_reason: "Dropped Reason",
      delivery_date: "Delivery Date",
      drop_location_city: "Drop Location City",
      planned_day: "Planned Day",
      vehicle_no: "Vehicle Number",
      drop_sequence: "Drop Sequence",
      invoicing_date: "Invoicing Date",
      pickup_location_source: "Pickup Location (Source)",
      destination_location_city: "Destination Location City",
      destination_location_state: "Destination Location State",
      distance_to_destination_miles: "Distance to Destination (Miles)",
      time_to_destination: "Time to Destination",
      dealer_available_from: "Dealer Available From",
      dealer_available_to: "Dealer Available To",
      total_planned_weight: "Total Planned Weight",
      total_planned_volume: "Total Planned Volume",
      arrival_at_first_dealer_start: "Arrival at First Dealer (Start)",
      departure_at_last_dealer_end: "Departure at Last Dealer (End)",
      return_to_plant_hh_mm: "Return to Plant (HH:MM)",
      total_planned_trip_time_hrs: "Total Planned Trip Time (Hrs)",
      total_round_trip_time_hrs: "Total Round Trip Time (Hrs)",
      total_loading_time_hh_mm: "Total Loading Time (HH:MM)",
      total_unloading_time_hh_mm: "Total Unloading Time (HH:MM)",
      vehicle_utilization_percentage: "Vehicle Utilization (%)",
      view_order_information: "View Order Information",
      vehicle_configuration: "Vehicle Configuration",
      summary: "Summary",
      planned_summary: "Planned Summary",
      planned_vehicles: "Planned Vehicles",
      unplanned_loads: "Unplanned Loads",
      invalid_drop_data: "Invalid Drop Data",
      set_vehicle_priority: "Set Vehicle Priority",
      forecast_order_plan: "Forecast Order Plan",
      volume_cft: "Volume (CFT)",
      dealer_available_time_error: "Start time must be less than end time",
      ok: "Ok",
      get_data: "Get Data",
      plan_history: "Plan History",
      upload_file: "Upload File",
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
      plant_name: "Plant Name",
      total_trips: "Total Trips",
      trips_edited: "Trips Edited",
      vfr_percentage: "VFR Percentage",
      total_transit_time_hh_mm: "Total Transit Time (HH)",
      drop_location_name_destination: "Drop Location Name (Destination)",
      unloading_time_hh_mm: "Unloading Time (HH:MM)",
      edited_transaction_ids: "Edited Transaction IDs",
      vehicle_type: "Vehicle Type",
      vehicle_name: "Vehicle Name",
      weight_pounds: "Weight (Pounds)",
      "Please Select Scenario to Compare Plans": "Please Select Scenario to Compare Plans",
"Percentage": "Percentage",
"Forecast Scenario": "Forecast Scenario",
      plant_code: "Plant Code",
      plant_name: "Plant Name",
      total_trips: "Total Trips",
      trips_edited: "Trips Edited",
      last_30_days_trips_modification_details:
        "Last 30 Days Trips Modification Details",
      last_7_days_trips_modification_details:
        "Last 7 Days Trips Modification Details",
      planning_progress_past_7_weeks: "Planning Progress Past 7 Weeks",
      edited_transaction_ids: "Edited Transaction IDs",
      trips_removed: "Trips Removed",
      removed_transaction_ids: "Removed Transaction IDs",
      drop_location_city: "Drop Location City",
      drop_location_name_destination: "Drop location name (Destination)",
      note_blank_rows_columns_not_allowed:
        "Note: Blank rows/columns are not allowed",
      check_blank_data_instructions:
        "To check for blank data: 1. Click Find & Select. 2. Click Go to Special. 3. Choose Blanks. 4. Click OK and all blank rows/cells will be highlighted. 5. Choose Delete in the Cells section on the Home tab. 6. Click Delete Sheet Rows.",
      disclaimer_dealer_window_ot_customers:
        "Disclaimer: The available window for OT customers will be considered from the input file, the timings for GT customers will be considered from the pickup/drop locations",
      quantity: "Quantity",
      specifications: "Specifications",
      please_select_plan_id_to_compare_plans:
        "Please select the plan ID to compare plans",
      vehicle_forecast_summary_dashboard: "Vehicle forecast summary dashboard",
      pickup_point: "Pickup point",
      view_details: "View details",
      compare_plans: "Compare plans",
      pickup_drop_points: "Pickup/Drop points",
      edit: "Edit",
      pickup: "PICKUP",
      drop: "DROP",
      both: "BOTH",
      pickup_location_code: "Pickup location code",
      dealer_type: "Dealer type",
      select: "Select",
      local: "Local",
      upcountry: "Upcountry",
      vehicle_restriction_list: "Vehicle restriction list",
      week_off: "Week off",
      csp_day: "CSP day",
      is_customer_kdd: "Is customer KDD?",
      start_month: "Start month",
      end_month: "End month",
      plan_id: "Plan ID",
      fecha_creada: "Created date",
      no_of_trucks_planned: "Number of trucks planned",
      total_planned_volume_cft: "Total planned volume (CFT)",
      total_planned_weight_pounds: "Total planned weight (Pounds)",
      plan_run_by: "Plan run by",
      planned_vehicles: "Planned vehicles",
      trip_no: "Trip number",
      total_planned_customers: "Total planned customers",
      total_round_trip_distance: "Total round trip distance",
      planning_percentage: "Planning percentage",
      max_dispatch_time: "Max dispatch time (HH:MM)",
      total_loading_time: "Total loading time (HH:MM)",
      total_unloading_time: "Total unloading time (HH:MM)",
      planned_summary: "Planned summary",
      invalid_orders_not_shown: "Note: Invalid orders are not shown",
      no_of_customers: "Number of customers",
      no_of_orders: "Number of orders",
      total_demand_volume: "Total demand volume (CFT)",
      planned_summary: "Planned summary",
      trips_removed: "Trips removed",
      removed_transaction_ids: "Removed transaction IDs",
      vehicle_forecast_configurations: "Vehicle forecast configurations",
      pickup_point: "Pickup point",
      vehicle_type: "Vehicle type",
      vehicle_name: "Vehicle name",
      cost_per_miles: "Cost per mile",
      driver_break_time: "Driver break time",
      view: "View",
      please_select_no_of_vehicles: "Please select the number of vehicles",
      no_of_vehicles: "Number of vehicles",
      obd_analysis: "OBD analysis",
      pickup_point: "Pickup point",
      select_month: "Select month",
      location_radius_miles: "Location radius (miles)",
      submit: "Submit",
      total_obd_details: "Total OBD details",
      valid_dealers: "Valid dealers",
      invalid_dealers: "Invalid dealers",
      volume: "Volume",
      help: "Help",
      change_password: "Change password",
      logout: "Logout",
      weight_pounds: "Weight (pounds)",
      quantity: "Quantity",
      obd_details_within_radius: "OBD details within radius",
      dealers: "Dealers",
      dealers_percent: "Dealers percentage",
      volume_percent: "Volume percentage",
      weight_pounds_percent: "Weight percentage (pounds)",
      quantity_percent: "Quantity percentage",
      cost_per_miles: "Cost per mile ($)",
      driver_break_time_from: "Driver break time (from)",
      monthly_min_planning_distance_miles:
        "Monthly minimum planning distance (miles)",
      vehicle_type_count: "Vehicle type count",
      driver_break_time_per_day: "Driver break time per day (HH:MM)",
      cost_per_miles_2: "Cost per mile ($)",
      set_vehicle_priority: "Set vehicle priority",
      vehicle_type_name: "Vehicle type name",
      min_volume_cft: "Minimum volume (CFT)",
      max_volume_cft: "Maximum volume (CFT)",
      max_distance_per_trip_miles: "Max distance per trip (miles)",
      select_plan_id: "Select plan ID",
      drop_details: "Drop details",
      drop_location_code: "Drop Location Code",
      vehicle_utilization: "Vehicle Utilization",
      onward_trip_distance_miles: "Onward Trip Distance (Miles)",
      round_trip_miles: "Round Trip (Miles)",
      max_dispatch_time_hh_mm: "Max Dispatch Time (HH:MM)",
      arrival_at_first_dealer_start: "Arrival at First Dealer (Start)",
      departure_at_last_dealer_end: "Departure at Last Dealer (End)",
      return_to_plant_hh_mm: "Return to Plant (HH:MM)",
      vehicle_number: "Vehicle Number",
      vehicle_availability_from: "Vehicle Availability (From)",
      vehicle_availability_to: "Vehicle Availability (To)",
      break_time_from: "Break Time (From)",
      break_time_to: "Break Time (To)",
      no_of_vehicles: "Number of Vehicles",
      driver_break_time_from: "Driver Break Time (From)",
      max_distance_miles: "Max Distance (Miles)",
      monthly_min_planning_distance_miles:
        "Monthly Minimum Planning Distance (Miles)",
      total_planned_trip_time_hrs: "Total Planned Trip Time (Hrs)",
      total_round_trip_time_hrs: "Total Round Trip Time (Hrs)",
      drop_location_code: "Drop Location Code",
      drop_location_name: "Drop Location Name",
      drop_location_city: "Drop Location City",
      order_number: "Order Number",
      delivery_date: "Delivery Date (M/D/YYYY)",
      back: "Back",
      drop_location_name: "Drop Location Name",
      pickup_location: "Pickup Location",
      dealer_available_from: "Dealer Available From",
      dealer_available_to: "Dealer Available To",
      total_planned_weight: "Total Planned Weight",
      total_planned_volume: "Total Planned Volume",
      arrival_at_first_dealer: "Arrival at First Dealer",
      departure_at_last_dealer: "Departure at Last Dealer",
      return_to_plant: "Return to Plant",
      summary_dashboard: "Summary Dashboard",
      dispatch_plan_details: "Dispatch Plan Details",
      invalid_orders_note: "Note: Invalid orders are not being displayed",
      planned_data: "Planned Data",
      vfr_percentage: "VFR Percentage",
      cool_chain_adherence: "Cool Chain Adherence",
      transit_time: "Transit Time",
      loading_time: "Loading Time",
      unloading_time: "Unloading Time",
      planned_summary: "Planned Summary",
      invalid_drop_data: "Invalid Drop Data",
      vehicle_utilization_km: "Vehicle Utilization (KM)",
      distance_to_destination: "Distance to Destination",
      expected_delivery_date: "Expected Delivery Date",
      planned_vehicles: "Planned Vehicles",
      trip_no: "Trip Number",
      total_quantity: "Total Quantity",
      dealer_available_to: "Dealer Available To",
      drop_location_code_destination: "Drop Location Code (Destination)",
      order_number: "Order Number",
      demand_volume_cft: "Demand Volume (CFT)",
      weight_pounds: "Weight (Pounds)",
      quantity: "Quantity",
      dropped_reason: "Dropped Reason",
      delivery_date: "Delivery Date",
      drop_location_name: "Drop Location Name",
      drop_location_city: "Drop Location City",
      trip_no: "Trip Number",
      planned_day: "Planned Day",
      vehicle_no: "Vehicle Number",
      vehicle_type: "Vehicle Type",
      drop_sequence: "Drop Sequence",
      invoicing_date: "Invoicing Date",
      pickup_location_source: "Pickup Location (Source)",
      destination_location_city: "Destination Location City",
      destination_location_state: "Destination Location State",
      distance_to_destination_miles: "Distance to Destination (Miles)",
      expected_delivery_date: "Expected Delivery Date",
      time_to_destination: "Time to Destination",
      transit_time_hh_mm: "Transit Time (HH:MM)",
      loading_time_hh_mm: "Loading Time (HH:MM)",
      unloading_time_hh_mm: "Unloading Time (HH:MM)",
      total_planned_customers: "Total Planned Customers",
      total_round_trip_distance_km: "Total Round Trip Distance (KM)",
      planning_percentage: "Planning Percentage",
      cool_chain_adherence_percentage: "Cool Chain Adherence Percentage",
      vfr_percentage: "VFR Percentage",
      total_transit_time_hh_mm: "Total Transit Time (HH:MM)",
      round_trip_miles: "Round Trip (Miles)",
      total_planned_weight_pounds: "Total Planned Weight (Pounds)",
      total_planned_volume_cft: "Total Planned Volume (CFT)",
      arrival_at_first_dealer_start: "Arrival at First Dealer (Start)",
      departure_at_last_dealer_end: "Departure at Last Dealer (End)",
      return_to_plant_hh_mm: "Return to Plant (HH:MM)",
      total_planned_trip_time_hrs: "Total Planned Trip Time (Hrs)",
      total_round_trip_time_hrs: "Total Round Trip Time (Hrs)",
      total_loading_time_hh_mm: "Total Loading Time (HH:MM)",
      total_unloading_time_hh_mm: "Total Unloading Time (HH:MM)",
      vehicle_utilization_percentage: "Vehicle Utilization (%)",
      view_order_information: "View Order Information",
      vehicle_configuration: "Vehicle Configuration",
      summary: "Summary",
      planned_summary: "Planned Summary",
      planned_vehicles: "Planned Vehicles",
      unplanned_loads: "Unplanned Loads",
      invalid_drop_data: "Invalid Drop Data",
      vehicle_utilization_km: "Vehicle Utilization (KM)",
      planned_data: "Planned Data",
      invalid_orders_note: "Note: Invalid orders are not being displayed",
      set_vehicle_priority: "Set Vehicle Priority",
      vehicle_min_weight_pounds: "Vehicle Minimum Weight (Pounds)",
      vehicle_max_weight_pounds: "Vehicle Maximum Weight (Pounds)",
      vehicle_min_volume_cft: "Vehicle Minimum Volume (CFT)",
      vehicle_max_volume_cft: "Vehicle Maximum Volume (CFT)",
      max_distance_miles: "Maximum Distance (Miles)",
      vehicle_priority: "Vehicle Priority",
      actions: "Actions",
      forecast_order_plan: "Forecast Order Plan",
      drop_details: "Drop Details",
      month: "Month",
      year: "Year",
      total_quantity: "Total Quantity",
      total_weight_pounds: "Total Weight (Pounds)",
      total_demand_volume_cft: "Total Demand Volume (CFT)",
      user: "User",
      uploaded_date: "Uploaded Date",
      view_order_details: "View Order Details",
      pick_up_location_code: "Pickup Location Code",
      quantity: "Quantity",
      drop_location: "Drop Location",
      drop_location_name: "Drop Location Name",
      weight: "Weight",
      volume: "Volume",
      delivery_date: "Delivery Date",
      invoice_date: "Invoice Date",
      order_no: "Order No",
      available_start_time: "Available Start Time",
      available_end_time: "Available End Time",
      upload_input_file: "Upload Input File",
      download_sample_template: "Download Sample Template",
      select_month: "Select Month",
      upload_file: "Upload File",
      save: "Save",
      cancel: "Cancel",
      note_blank_rows_columns_not_allowed:
        "Note: Blank rows/columns are not allowed",
      no_of_vehicles: "Number of Vehicles",
      run_plan: "Run Plan",
      max_distance_per_plan_miles: "Max Distance Per Plan (Miles)",
    add_city_wise_cost: "Add City Wise Cost",
    add_zone_wise_cost: "Add Zone Wise Cost",
    scenarios: "Scenarios",
      vehicle_types: "Vehicle Types",
      upload_file: "Upload File",
      pickup_point: "Pickup Point",
      consolidated_route_plan_screen: "Consolidated Route Plan Screen",
      start_date: "Start Date",
      end_date: "End Date",
      get_data: "Get Data",
      recent_plans: "Recent Plans",
      plan_history: "Plan History",
      view_input: "View Input",
      consolidation_id: "Consolidation ID",
      created_date: "Created Date",
      run_by: "Run By",
      no_of_executions: "Number of Executions",
      execution_status: "Execution Status",
      download_output: "Download Output",
      upload_input_file: "Upload Input File",
      choose_file: "Choose File",
      discard_obd: "Discard OBD",
      upload_obd_details: "Upload OBD Details",
      upload_file: "Upload File",
      submit: "Submit",
      download_template: "Download Template",
      bulk_upload_locations: "Bulk Upload Locations",
      add_pickup_drop_points: "+Add Pickup/Drop Points",
      details: "Details",
      date: "Date",
      total_trips: "Total Trips",
      trips_edited: "Trips Edited",
      trips_removed: "Trips Removed",
      trips_edit_percentage: "Trips Edit Percentage",
      plans_overview_dashboard: "Plans Overview Dashboard",
      pickup_point: "Pickup Point",
      location_type: "Location Type",
      location_code: "Location Code",
      location_name: "Location Name",
      location_city: "Location City",
      zone: "Zone",
      customer_type: "Customer Type",
      location_latitude: "Location Latitude",
      location_longitude: "Location Longitude",
      standard_transit_time: "Standard Transit Time (Days)",
      distance_from_plant: "Distance From Plant (Miles)",
      delta_warehouse: "Delta Warehouse",
      home: "Home",
      run_new_plan: "Run New Plan",
      stacking: "Stacking",
      production_planning: "Production Planning",
      forecasting: "Forecasting",
      resource_scheduling: "Resource Scheduling",
      general: "General",
      pickup_drop_locations: "Pickup/Drop Locations",
      vehicle_configurations: "Vehicle Configurations",
      vehicle_availability: "Vehicle Availability",
      zone_mapping: "Zone Mapping",
      transporters: "Transporters",
      material_configurations: "Material Configurations",
      freight_configurations: "Freight Configurations",
      route_configurations: "Route Configurations",
      auto_clustering: "Auto Clustering",
      transporter_share_of_business: "Transporter Share of Business",
      dispatch_plan_summary: "Dispatch Plan Summary",
      kpi_dashboard: "KPI Dashboard",
      planning_tools: "Planning Tools",
      forecast_tools: "Forecast Tools",
      suppliers: "Suppliers",
      customers: "Customers",
      materials: "Materials",
      input_data: "Input Data",
      forecast: "Forecast",
      previous_plan: "Previous Plan",
      trips: "Trips",
      gps_quality: "GPS Quality",
      truck_gps_data: "Truck GPS Data",
      unscheduled_stoppages: "Unscheduled Stoppages",
      deviation_reports: "Deviation Reports",
      fo_data: "FO Data",
      vehicle_checklist: "Vehicle Checklist",
      vehicle_status: "Vehicle Status",
      route_restrictions: "Route Restrictions",
      consolidated_run_plan: "Consolidated Run Plan",
      plans_overview: "Plans Overview",
      input_plan_summary: "Input Plan Summary",
      load_plan_summary: "Load Plan Summary",
      plant_wise_configurations: "Plant-wise Configurations",
      vehicle_configuration: "Vehicle Configuration",
      monthly_data: "Monthly Data",
      forecast_plan_history: "Forecast Plan History",
      obd_analysis: "OBD Analysis",
      actions: "Actions",
      pickup_location_source: "Pickup Location (Source)",
      drop_location_destination: "Drop Location (Destination)",
      order_number: "Order Number",
      invoicing_date: "Invoicing Date (M/D/YYYY)",
      quantity: "Quantity",
      dealer_available_time_from: "Dealer Available Time (From 00:00)",
      dealer_available_time_to: "Dealer Available Time (To 23:59)",
      volume_cft: "Volume (CFT)",
      weight_pounds: "Weight (Pounds)",
      delivery_date: "Delivery Date (M/D/YYYY)",
      no_rows_to_show: "No Rows to Show",
      rows: "Rows",
      total_rows: "Total Rows",
      add_dispatch: "Add Dispatch",
      run_new_plan_by_file: "Run New Plan by File",
      submit_run_plan: "Submit and Run Plan",
      delete: "Delete",
      upload_input_file: "Upload Input File",
      upload_file: "Upload File",
      download_sample_template: "Download Sample Template",
      save: "SAVE",
      cancel: "CANCEL",
      dealer_available_time_error: "Start Time must be less than End Time",
      ok: "Ok",
      summary_dashboard: "Summary Dashboard",
      pickup_point: "Pickup Point",
      date_created: "Date Created",
      get_data: "Get Data",
      plan_details: "Plan Details",
      plan_history: "Plan History",
      drop_details: "Drop Details",
      generate_plan: "Generate Plan",
      order_id: "Order ID",
      no_of_runs: "Number of Runs",
      created_on: "Created On",
      plan_run_by: "Plan Run By",
      discard_obd: "Discard OBD",
      upload_obd_details: "Upload OBD Details",
      download_template: "Download Template",
      choose_file: "Choose File",
      submit: "Submit",
      start_date: "Start Date",
      end_date: "End Date",
      get_data: "Get Data",
      upload_input_file: "Upload Input File",
      discard_obd: "Discard OBD",
      recent_plans: "Recent Plans",
      plan_history: "Plan History",
      view_input: "View Input",
      consolidation_id: "Consolidation ID",
      created_date: "Created Date",
      run_by: "Run By",
      no_of_executions: "Number of Executions",
      execution_status: "Execution Status",
      download_output: "Download Output",
      upload_obd_details: "Upload OBD Details",
      download_template: "Download Template",
      choose_file: "Choose File",
      submit: "Submit",
      simulation_time: "Simulation Time",
      enable_dc_week_off_planning: "Enable DC Week Off Planning",
      run_plan: "Run Plan",
      cancel: "Cancel",
      plan_details: "Plan Details",
      pickup_point: "Pickup Point",
      date_created: "Date Created",
      order_id: "Order ID",
      no_of_runs: "Number of Runs",
      fix_and_reupload: "Fix and Reupload",
      club_previous_order: "Club Previous Order",
      ignore: "Ignore",
      dealer_code: "Dealer Code",
      remarks: "Remarks",
      run_new_plan: "Run New Plan",
      consolidated_route_plan_screen: "Consolidated Route Plan Screen",
      plant_code: "Plant Code",
      plant_name: "Plant Name",
      total_trips: "Total Trips",
      trips_edited: "Trips Edited",
      edited_transaction_ids: "Edited Transaction IDs",
      vehicle_type: "Vehicle Type",
      vehicle_name: "Vehicle Name",
      weight_pounds: "Weight (Pounds)",
      drop_location_city: "Drop Location City",
      quantity: "Quantity",
      planned_vehicles: "Planned Vehicles",
      trip_no: "Trip Number",
      total_planned_customers: "Total Planned Customers",
      total_round_trip_distance: "Total Round Trip Distance",
      planning_percentage: "Planning Percentage",
      max_dispatch_time: "Max Dispatch Time (HH:MM)",
      total_loading_time: "Total Loading Time (HH:MM)",
      total_unloading_time: "Total Unloading Time (HH:MM)",
      planned_summary: "Planned Summary",
      invalid_orders_not_shown: "Note: Invalid Orders Not Shown",
      no_of_customers: "Number of Customers",
      no_of_orders: "Number of Orders",
      total_demand_volume: "Total Demand Volume (CFT)",
      planned_summary: "Planned Summary",
      trips_removed: "Trips Removed",
      removed_transaction_ids: "Removed Transaction IDs",
      vehicle_forecast_configurations: "Vehicle Forecast Configurations",
      pickup_point: "Pickup Point",
      vehicle_type: "Vehicle Type",
      vehicle_name: "Vehicle Name",
      cost_per_miles: "Cost per Mile",
      driver_break_time: "Driver Break Time",
      view: "View",
      please_select_no_of_vehicles: "Please Select Number of Vehicles",
      no_of_vehicles: "Number of Vehicles",
      cost_per_miles: "Cost per Miles ($)",
      driver_break_time_from: "Driver Break Time (From)",
      monthly_min_planning_distance_miles:
        "Monthly Minimum Planning Distance (Miles)",
      vehicle_type_count: "Vehicle Type Count",
      driver_break_time_per_day: "Driver Break Time per Day (HH:MM)",
      cost_per_miles_2: "Cost per Miles ($)",
      set_vehicle_priority: "Set Vehicle Priority",
      vehicle_type_name: "Vehicle Type Name",
      min_volume_cft: "Minimum Volume (CFT)",
      max_volume_cft: "Maximum Volume (CFT)",
      max_distance_per_trip_miles: "Max Distance per Trip (Miles)",
      select_plan_id: "Select Plan ID",
      drop_details: "Drop Details",
      drop_location_code: "Drop Location Code",
      drop_location_name: "Drop Location Name",
      pickup_location: "Pickup Location",
      dealer_available_from: "Dealer Available From",
      dealer_available_to: "Dealer Available To",
      total_planned_weight: "Total Planned Weight",
      total_planned_volume: "Total Planned Volume",
      arrival_at_first_dealer: "Arrival at First Dealer",
      departure_at_last_dealer: "Departure at Last Dealer",
      return_to_plant: "Return to Plant",
      summary_dashboard: "Summary Dashboard",
      dispatch_plan_details: "Dispatch Plan Details",
      invalid_orders_note: "Note: Invalid Orders Not Shown",
      planned_data: "Planned Data",
      vfr_percentage: "VFR Percentage",
      cool_chain_adherence: "Cool Chain Adherence",
      transit_time: "Transit Time",
      loading_time: "Loading Time",
      unloading_time: "Unloading Time",
      planned_summary: "Planned Summary",
      invalid_drop_data: "Invalid Drop Data",
      vehicle_utilization_km: "Vehicle Utilization (KM)",
      distance_to_destination: "Distance to Destination",
      expected_delivery_date: "Expected Delivery Date",
      planned_vehicles: "Planned Vehicles",
      trip_no: "Trip Number",
      total_quantity: "Total Quantity",
      dealer_available_to: "Dealer Available To",
      drop_location_code_destination: "Drop Location Code (Destination)",
      order_number: "Order Number",
      demand_volume_cft: "Demand Volume (CFT)",
      weight_pounds: "Weight (Pounds)",
      quantity: "Quantity",
      dropped_reason: "Dropped reason",
      delivery_date: "Delivery date",
      drop_location_name: "Drop location name",
      drop_location_city: "Drop location city",
      trip_no: "Trip number",
      planned_day: "Planned day",
      vehicle_no: "Vehicle number",
      vehicle_type: "Vehicle type",
      drop_sequence: "Drop sequence",
      invoicing_date: "Invoicing date",
      pickup_location_source: "Pickup location (source)",
      destination_location_city: "Destination location city",
      destination_location_state: "Destination location state",
      distance_to_destination_miles: "Distance to destination (miles)",
      expected_delivery_date: "Expected delivery date",
      time_to_destination: "Time to destination",
      invalid_material: "Invalid material",
      drop_location_code_destination: "Drop location code (destination)",
      demand_volume_cft: "Demand volume (CFT)",
      dropped_reason: "Dropped reason",
      dealer_location_data_not_in_system: "Dealer location data not in system",
      delivery_date_less_than_current:
        "Delivery date is less than current date",
      missing_dealer_information: "Missing dealer information",
      transit_time_hh_mm: "Transit time (HH:MM)",
      loading_time_hh_mm: "Loading time (HH:MM)",
      unloading_time_hh_mm: "Unloading time (HH:MM)",
      actions: "Actions",
      expected_delivery_date: "Expected delivery date",
      max_dispatch_time: "Max dispatch time (HH:MM)",
      unplanned_loads: "Unplanned loads",
      monthly_data: "Monthly data",
      forecast_plan_history: "Forecast plan history",
      obd_analysis: "OBD analysis",
      dealer_available_time_error: "Start time must be less than end time",
      forecast_summary: "Forecast Summary",
    pickup_locations_drop: "Pickup Locations (Drop)",
    vehicle_availability_configurations: "Vehicle Availability Configurations",
    "Please Select File": "Please Select File",
"Processing Plan. Please wait.....": "Processing Plan. Please wait.....",
"File is processing": "File is processing",
"Enter Input Data To Continue": "Enter Input Data To Continue",
"No Data": "No Data",
"please_select_date": "Please select date",
"data_uploaded": "Data uploaded",
"Please Select No of Vehicles": "Please Select No of Vehicles",
"Please Input Valid Data": "Please Input Valid Data",
"route_type": "Route Type"    
},
  
  },
  ar: {
    translation: {
      vehicles_recommended: "المركبات الموصى بها",
      "Number Of Trips": "عدد الرحلات",
  "Total": "إجمالي",
  "Quantity": "الكمية",
  "Weight": "الوزن",
  "Spot": "الموقع",
  "Day-2": "اليوم-2",
  "Day-3": "اليوم-3",
  "Day-4": "اليوم-4",
  "Day-5": "اليوم-5",
  "Day-6": "اليوم-6",
  "Distance / Time From Pickup Location": "المسافة / الوقت من موقع الالتقاط",
  "Distance / Time From Previous Drop Point": "المسافة / الوقت من نقطة التسليم السابقة",
  "Location City": "مدينة الموقع",
  "Planned Day": "اليوم المخطط",
  "Quantity": "الكمية",
  "Demand Weight": "وزن الطلب",
  "Demand Volume": "حجم الطلب",
  "Location": "الموقع",
  "Weight": "الوزن",
  "View Onward Route": "عرض المسار المتجه",
  "View Return Route": "عرض مسار العودة",
  "Local": "محلي",
  "Upcountry": "الريف",
  "Volume": "الحجم",
  "IN416": "IN416",
  "IN436": "IN436",
  "Spot": "النقطة",
  "2,177": "2,177",
  "1,268": "1,268",
  "909": "909",
  "0": "0",
  "Planned Truck Load": "الحمولة المخططة للشاحنة",
  "Quantity (cases)": "الكمية (صناديق)",
  "Weight (Kgs)": "الوزن (كجم)",
  "Volume (CMT)": "الحجم (CMT)",
  "1,81,970": "1,81,970",
  "25,25,554": "25,25,554",
  "8,249": "8,249",
  "Unplanned Load": "حمولة غير مخططة",
  "26,324": "26,324",
  "3,74,549": "3,74,549",
  "1,234": "1,234",
  "Average Distance Travelled Per Vehicle (KM)": "متوسط المسافة المقطوعة لكل مركبة (كم)",
  "35,426": "35,426",
  "37,624": "37,624",
  "28,111": "28,111",
  "Operational Cost (Rs.)": "التكلفة التشغيلية (Rs.)",
  "1,62,57,137": "1,62,57,137",
  "1,31,64,341": "1,31,64,341",
  "30,92,797": "30,92,797",
  "Average Vehicle Fill Rate": "معدل ملء المركبة المتوسط",
  "60 %": "60 %",
  "42 %": "42 %",
  "79 %": "79 %",
  "Unplanned Load Proportion": "نسبة الحمولة غير المخططة",
  "12 %": "12 %",
  "Average Vehicle Utilization As Per SOB": "متوسط استخدام المركبات وفقًا لـ SOB",
  "104 %": "104 %",
  "94 %": "94 %",
  "Ontime Dispatches": "الشحنات في الوقت المحدد",
  "Local": "محلي",
  "frefe": "frefe",
  "94 %": "94 %",
  "92 %": "92 %",
  "Ontime Deliveries": "التسليم في الوقت المحدد",
  "97 %": "97 %",
  "94 %": "94 %",
      "Please Select Scenario to Compare Plans": "يرجى اختيار السيناريو لمقارنة الخطط",
"Percentage": "النسبة المئوية",
"Forecast Scenario": "سيناريو التنبؤ",
      "Number of kms Travelled": "عدد الكيلومترات المقطوعة",
"Cost": "التكلفة",
"Vehicle Fill Rate": "معدل تعبئة المركبة",
"Vehicle KM Run %": "نسبة الكيلومترات المقطوعة للمركبة %",
      "Disclaimer": "إخلاء المسؤولية: سيتم اعتبار نافذة التاجر المتاحة لعملاء OT من ملف الإدخال، بينما سيتم اعتبار توقيتات عملاء GT من مواقع الاستلام/التوصيل.",
  "To Check for Blank Data": "للتحقق من البيانات الفارغة: 1. انقر فوق 'بحث & تحديد'. 2. انقر فوق 'الانتقال إلى خاص'. 3. اختر 'فراغات'. 4. انقر فوق 'موافق' وسيتم تمييز جميع الصفوف/الخلايا الفارغة. 5. اختر 'حذف' ضمن قسم الخلايا في علامة التبويب 'الرئيسية'. 6. انقر فوق 'حذف صفوف الورقة'.",
      "loading....": "جارٍ التحميل....",
  "select_cluster": "اختر مجموعة",
  "Please Select File": "الرجاء اختيار الملف",
"Processing Plan. Please wait.....": "جارٍ معالجة الخطة. الرجاء الانتظار.....",
"File is processing": "يتم معالجة الملف",
"Enter Input Data To Continue": "أدخل البيانات للمتابعة",
"No Data": "لا توجد بيانات",
"please_select_date": "الرجاء اختيار التاريخ",
"data_uploaded": "تم تحميل البيانات",
"Please Select No of Vehicles": "الرجاء اختيار عدد المركبات",
"Please Input Valid Data": "الرجاء إدخال بيانات صالحة",
  "change_password": "تغيير كلمة المرور",
  "new_password": "كلمة المرور الجديدة",
  "confirm_new_password": "تأكيد كلمة المرور الجديدة",
  "old_password": "كلمة المرور القديمة",
  "enter_email": "أدخل بريدك الإلكتروني",
  "enter_new_password": "أدخل كلمة المرور الجديدة الخاصة بك",
  "confirm_new_password_entry": "أكد كلمة المرور الجديدة الخاصة بك",
  "Overall Cluster Wise Vehicles": "المركبات حسب المجموعة الشاملة",
  "Discard": "تجاهل",
  "obd": "أو بي دي",
  "Dispatch Planning is in progress..!!!": "تخطيط الإرسال قيد التقدم..!!!",
  "Estimated Time": "الوقت المقدر",
  "GPS": "نظام تحديد المواقع",
  "Spot Vehicles To Be Planned": "المركبات الفورية التي يجب تخطيطها",
  "No Of Spot Vehicles": "عدد المركبات الفورية",
  "Availability DateTime": "تاريخ ووقت التوفر",
  "Notify Email Users After Process (comma seperated)": "إخطار مستخدمي البريد الإلكتروني بعد العملية (مفصولة بفاصلة)",
  "Max Time Limit(Hrs)": "الحد الأقصى للوقت (ساعات)",
  "vfr": "VFR",
  "Disclaimer": "إخلاء المسؤولية: سيتم اعتبار نافذة التوفر للوكلاء لعملاء OT من ملف الإدخال، وسيتم اعتبار توقيت عملاء GT من مواقع الالتقاط/التسليم.","Add New OBD": "إضافة OBD جديد",
  "All drop locations are invalid, Please check..!!": "جميع مواقع التسليم غير صالحة، يرجى التحقق..!!",
  "Please upload file having extensions .csv or .xlsx only.": "يرجى تحميل ملف بامتدادات .csv أو .xlsx فقط.",
  "Dispatch Load Volume Summary (CFT)": "ملخص حجم الحمولة المرسلة (CFT)",
  "Total Loads Volume (CFT)": "إجمالي حجم الحمولة (CFT)",
  "Planned Loads Volume (CFT)": "الحجم المخطط للحمولة (CFT)",
  "Unplanned Loads Volume (CFT)": "الحجم غير المخطط للحمولة (CFT)",
  "Dropped Loads Volume (CFT)": "حجم الحمولة المتروكة (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "ملخص وزن الحمولة المرسلة (رطل)",
  "Total Loads Weight (Pounds)": "إجمالي وزن الحمولة (رطل)",
  "Planned Loads Weight (Pounds)": "وزن الحمولة المخطط (رطل)",
  "Unplanned Loads Weight (Pounds)": "وزن الحمولة غير المخطط (رطل)",
  "Dropped Loads Weight (Pounds)": "وزن الحمولة المتروكة (رطل)",
  "Dispatch Load Quantity Summary": "ملخص كمية الحمولة المرسلة",
  "Total Quantity": "إجمالي الكمية",
  "Planned Quantity": "الكمية المخطط لها",
  "Unplanned Quantity": "الكمية غير المخطط لها",
  "Dropped Quantity": "الكمية المتروكة",
  "Dispatch Load Volume Summary (CFT)": "ملخص حجم الحمولة المرسلة (CFT)",
  "Total Loads Volume (CFT)": "إجمالي حجم الحمولة (CFT)",
  "Planned Loads Volume (CFT)": "الحجم المخطط للحمولة (CFT)",
  "Unplanned Loads Volume (CFT)": "الحجم غير المخطط للحمولة (CFT)",
  "Dropped Loads Volume (CFT)": "حجم الحمولة المتروكة (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "ملخص وزن الحمولة المرسلة (رطل)",
  "Total Loads Weight (Pounds)": "إجمالي وزن الحمولة (رطل)",
  "Planned Loads Weight (Pounds)": "وزن الحمولة المخطط (رطل)",
  "Unplanned Loads Weight (Pounds)": "وزن الحمولة غير المخطط (رطل)",
  "Dropped Loads Weight (Pounds)": "وزن الحمولة المتروكة (رطل)",
  "Dispatch Load Quantity Summary": "ملخص كمية الحمولة المرسلة",
  "Total Quantity": "إجمالي الكمية",
  "Planned Quantity": "الكمية المخطط لها",
  "Unplanned Quantity": "الكمية غير المخطط لها",
  "Dropped Quantity": "الكمية المتروكة",
  "Dispatch Load Weight Summary (Pounds)": "ملخص وزن الحمولة المرسلة (رطل)",
  "Dispatch Load Volume Summary (CFT)": "ملخص حجم الحمولة المرسلة (CFT)",
  "Dropped Data": "البيانات المتروكة",
  "ontime_delivery": "التسليم في الوقت المحدد",
  "dealer_Available_start_time_should_be_less_than_end_time": "يجب أن يكون وقت بدء توفر الوكيل أقل من وقت الانتهاء",
  "Removed": "تمت الإزالة",
  "Unedited": "غير محرر",
  "Edited": "محرر",
      obd: "أو بي دي",
      vehicle_availability_configurations: "تكوينات توافر المركبات",
      max_distance_per_plan_miles: "أقصى مسافة لكل خطة (أميال)",
    add_city_wise_cost: "إضافة تكلفة حسب المدينة",
    add_zone_wise_cost: "إضافة تكلفة حسب المنطقة",
    scenarios: "سيناريوهات",
      plant: "مصنع",
      customer: "العميل",
      percent_unplanned: "نسبة غير المخطط لها",
      standard_transit_time_days: "الوقت القياسي للنقل (أيام)",
    drop_to_time: "وقت التسليم",
    drop_from_time: "وقت الانطلاق",
    pickup_to_time: "وقت الاستلام",
    pickup_from_time: "وقت بدء الاستلام",
    distance_from_plant_miles: "المسافة من المصنع (أميال)",
    vehicle_next_available_date: "تاريخ التوفر التالي للمركبة",
    max_distance_per_plan_miles: "أقصى مسافة لكل خطة (أميال)",
    remaining_monthly_distance_miles: "المسافة الشهرية المتبقية (أميال)",
    assign_quality_checks: "تخصيص فحوصات الجودة",
    add_fixed_vehicle_cost: "إضافة تكلفة ثابتة للمركبة",
      "Pickup Locations (Drop)*": "مواقع الاستلام (التوصيل)*",
      "pickup_locations_drop*": "مواقع الاستلام (التوصيل)*",
      please_select_scenario: "يرجى اختيار السيناريو لمقارنة الخطط",
      invalid_orders_not_shown: "الطلبات غير الصالحة لا يتم عرضها",
      duplicate_obds_light_red: "تُعرض OBD المكررة باللون الأحمر الفاتح",
    new_obds_green: "تُعرض OBD المضافة حديثًا باللون الأخضر",
    pickup_locations_drop: "مواقع الاستلام (التوصيل)",
    note: "ملاحظة",
    avg_kms_travelled: "متوسط الكيلومترات المقطوعة",
    total_cost: "التكلفة الإجمالية",
    total_vehicle_fill_rate: "معدل ملء المركبات الإجمالي",
    percent_km_run_sob: "نسبة الكيلومترات المقطوعة SOB",
    percent_ontime_dispatch: "نسبة الشحن في الوقت المحدد",
    upcountry: "الداخلية",
    percent_spot_vehicles_trips: "نسبة رحلات المركبات الفورية",
    round_trip_distance: "مسافة الرحلة ذهابًا وإيابًا",
    total_trip_distance: "إجمالي مسافة الرحلة",
    vehicle_type: "نوع المركبة",
    plant_code: "رمز المصنع",
    vehicle_no: "رقم المركبة",
    monthly_sob_km: "SOB الشهري (كم)",
    distance_travelled_km: "المسافة المقطوعة (كم)",
    pickup_location_source: "موقع الاستلام (المصدر)",
    drop_location_code_destination: "رمز موقع التسليم (الوجهة)",
    drop_location_name_destination: "اسم موقع التسليم (الوجهة)",
    order_number: "رقم الطلب",
    invoicing_date: "تاريخ الفاتورة",
    quantity: "الكمية",
    weight_kgs: "الوزن (كجم)",
    demand_volume_m3: "الحجم المطلوب (م³)",
    dealer_available_from: "التاجر متاح من",
    dealer_available_to: "التاجر متاح حتى",
    delivery_date: "تاريخ التسليم",
    trip_no: "رقم الرحلة",
    planned_day: "اليوم المخطط",
    drop_sequence: "ترتيب التسليم",
    destination_location_city: "مدينة الموقع الوجهة",
    destination_location_state: "ولاية الموقع الوجهة",
    volume_m3: "الحجم (م³)",
    distance_to_destination_kms: "المسافة إلى الوجهة (كم)",
    expected_delivery_date: "تاريخ التسليم المتوقع",
    time_to_destination: "الوقت إلى الوجهة",
    transit_time_hhmm: "وقت العبور (ساعة:دقيقة)",
    loading_time_hhmm: "وقت التحميل (ساعة:دقيقة)",
    unloading_time_hhmm: "وقت التفريغ (ساعة:دقيقة)",
    start_month: "شهر البدء",
    end_month: "شهر النهاية",
    get_data: "احصل على البيانات",
    select_chart_type: "اختر نوع الرسم البياني",
    fleet_analysis: "تحليل الأسطول",
    system_recommended: "النظام الموصى به",
    total_load: "إجمالي الحمولة",
    serviceability: "قابلية التشغيل",
    in416: "IN416",
    in436: "IN436",
    user_recommended: "المستخدم الموصى به",
    threshold: "العتبة",
    number_of_trips: "عدد الرحلات",
    total: "الإجمالي",
    spot: "سبوت",
    planned_truck_load: "الحمولة المخططة للشاحنة",
    quantity_cases: "الكمية (الصناديق)",
    weight_kgs: "الوزن (كجم)",
    volume_cmt: "الحجم (CMT)",
    unplanned_load: "الحمولة غير المخططة",
    average_distance_travelled_per_vehicle_km: "متوسط المسافة المقطوعة لكل مركبة (كم)",
    operational_cost_rs: "التكلفة التشغيلية (روبية)",
    average_vehicle_fill_rate: "معدل ملء المركبات المتوسط",
    unplanned_load_proportion: "نسبة الحمولة غير المخططة",
    average_vehicle_utilization_as_per_sob: "متوسط استخدام المركبة وفق SOB",
    ontime_dispatches: "العمليات المرسلة في الوقت المحدد",
    local: "محلي",
    upcountry: "البلدات",
    ontime_deliveries: "التسليم في الوقت المحدد",
    bar: "شريط",
    load: "حمولة",
    month: "شهر",
    percentage: "%",






      // words for configurations screens

      "Consider Transporter SOB": "النظر في نسبة الناقل SOB",
      forecast_summary: "ملخص التوقعات",
      compare: "مقارنة",
    pickup_locations_drop: "مواقع الاستلام (التوصيل)",
      "note_highlighted_rows_red": "ملاحظة: الصفوف المظللة باللون الأحمر تفتقر إلى بيانات GPS أو التكامل، قم بتحديد توفر المركبة يدويًا.",
  "highlighted_rows_green": "2. الصفوف المظللة باللون الأخضر تعتبر للتخطيط",
  "enable_reconciliation": "تمكين التسوية",
  "enable_dc_week_off_planning": "تمكين تخطيط عطلة أسبوع مراكز البيانات",
      enable_disable: "تمكين / تعطيل",
      "Consider Dropping Distant Smaller Loads":
        "النظر في إسقاط الحمولات الصغيرة البعيدة",
      "Consider Realtime Vehicle Available Times":
        "النظر في أوقات توفر المركبة في الوقت الفعلي",
      "Plant Wise Vehicle Type Configuration*": "تكوين نوع المركبة حسب المصنع*",
      "Consignees / Dealers Locations (Drop)*":
        "مواقع المستلمين / التجار (التسليم)*",
      "Pickup Locations (Pickup)*": "مواقع الاستلام (استلام)*",
      "Optimization Type*": "نوع التحسين*",
      "Plant Wise Planning History*": "تاريخ التخطيط حسب المصنع*",
      "Plant Wise Plan Summary*": "ملخص الخطة حسب المصنع*",
      "Plant Wise New Plan Creation*": "إنشاء خطة جديدة حسب المصنع*",
      "Suppliers / Vendor Locations (Pickup)*":
        "مواقع الموردين / البائعين (استلام)*",
      "Plant Wise Vehicle Type Configuration*": "تكوين نوع المركبة حسب المصنع*",
      "Consignees / Dealers Locations (Drop)*":
        "مواقع المستلمين / التجار (التسليم)*",
      "Pickup Locations (Pickup)*": "مواقع الاستلام (استلام)*",
      "Plant Wise Vehicle Type Configuration*": "تكوين نوع المركبة حسب المصنع*",
      "Suppliers / Vendor Locations (Pickup)*":
        "مواقع الموردين / البائعين (استلام)*",
      "Plant Wise Planning History*": "تاريخ التخطيط حسب المصنع*",
      "Plant Wise Plan Summary*": "ملخص الخطة حسب المصنع*",
      "Plant Wise New Plan Creation*": "إنشاء خطة جديدة حسب المصنع*",
      "Enable/Disable Dispatch Planning Module":
        "تمكين/تعطيل وحدة تخطيط الإرسال",
      Inbound: "وارد",
      Outbound: "صادر",
      "Suppliers / Vendor Locations (Pickup)":
        "الموردون / مواقع البائع (استلام)",
      "Operational Hours": "ساعات التشغيل",
      "Pickup Locations (Drop)": "مواقع الاستلام (تسليم)",
      "Plant Wise Vehicle Type Configuration": "تكوين نوع المركبة حسب المصنع",
      "Max No. Of Vehicle Types": "الحد الأقصى لعدد أنواع المركبات",
      "Vehicle Load Capacity Min / Max Configuration Preferences":
        "تفضيلات تكوين سعة حمولة المركبة الدنيا / القصوى",
      "Vehicle Load Weight Min / Max Preferences":
        "تفضيلات وزن حمولة المركبة الدنيا / القصوى",
      "Distance preferences": "تفضيلات المسافة",
      "Vehicle Priorities": "أولويات المركبة",
      "Vehicle Operational Times": "أوقات تشغيل المركبة",
      "Vehicle Availability": "توفر المركبة",
      "Plant Wise Freight Configuration": "تكوين الشحن حسب المصنع",
      "Plant Wise Transporter Configuration": "تكوين الناقل حسب المصنع",
      "Route Preferences": "تفضيلات المسار",
      "Plant Wise New Plan Creation": "إنشاء خطة جديدة حسب المصنع",
      "Plant Wise Plan Summary": "ملخص الخطة حسب المصنع",
      "Plant Wise Planning History": "تاريخ التخطيط حسب المصنع",
      "No of Vehicles Types": "عدد أنواع المركبات",
      "Generic Time Windows": "نوافذ الوقت العامة",
      "Individual Vehicle Time Windows": "نوافذ الوقت لكل مركبة على حدة",
      "Availability By Vehicle Type": "التوفر حسب نوع المركبة",
      "Availability By Individual Vehicle": "التوفر حسب المركبة الفردية",
      "Monthly Minimum Planning Distance Preferences":
        "تفضيلات الحد الأدنى للمسافة التخطيطية الشهرية",
      "GPS Based Availability": "التوفر بناءً على نظام تحديد المواقع GPS",
      "Truck Location Visibility": "رؤية موقع الشاحنة",
      "Plant Vicinity Circle Radius": "نصف قطر دائرة جوار المصنع",
      "Fixed Vehicle Costs": "تكاليف المركبة الثابتة",
      "Variable Costs": "التكاليف المتغيرة",
      "Vehicle Type Level Variable Costs":
        "تكاليف متغيرة على مستوى نوع المركبة",
      "City Level Vehicle Type Costs": "تكاليف نوع المركبة على مستوى المدينة",
      "Per CFT Cost": "التكلفة لكل قدم مكعب",
      "Per Mile Cost": "التكلفة لكل ميل",
      "Zone Level Cost": "التكلفة على مستوى المنطقة",
      "Transporter Share of Business": "نسبة مشاركة الناقل في العمل",
      "Sustainability Integration": "دمج الاستدامة",
      "Optimization Type": "نوع التحسين",
      "Cost Optimization": "تحسين التكلفة",
      "Weight based optimization": "تحسين يعتمد على الوزن",
      "Distance based optimization": "تحسين يعتمد على المسافة",
      "Time based optimization": "تحسين يعتمد على الوقت",
      "Volume based optimization": "تحسين يعتمد على الحجم",
      "Prioritize Local Loads": "إعطاء الأولوية للحمولات المحلية",
      "Radius Miles": "نصف قطر الأميال",
      "Number of Loading Docks": "عدد أرصفة التحميل",
      "City Based Entry Restriction": "تقييد الدخول حسب المدينة",
      "Unloading time per case/max unloading time":
        "وقت التفريغ لكل حالة / الحد الأقصى لوقت التفريغ",
      "Consider Vehicle Break Times (Night Driving)":
        "اعتبار فترات استراحة المركبة (القيادة الليلية)",
      "Consider Holiday Restrictions": "اعتبار القيود في العطلات",
      "Vehicle Restriction": "تقييد المركبة",
      "Plan Spot Vehicles": "تخطيط مركبات النقاط",
      "Max No of Customers Per Vehicle": "الحد الأقصى لعدد العملاء لكل مركبة",
      "Use Dynamic Planning Window": "استخدام نافذة التخطيط الديناميكية",
      "Number of Days to Use Planning Window":
        "عدد الأيام لاستخدام نافذة التخطيط",
      "Use Volume for Vehicle Fill Rate (VFR)":
        "استخدام الحجم لمعدل ملء المركبة (VFR)",
      "Use Weight for Vehicle Fill Rate (VFR)":
        "استخدام الوزن لمعدل ملء المركبة (VFR)",
      "Min VFR Per Vehicle Type": "أدنى معدل ملء المركبة لكل نوع",
      "Min VFR Per Spot Vehicle": "أدنى معدل ملء المركبة لكل مركبة نقطة",
      "Prioritize Same Dealers First in a Vehicle":
        "إعطاء الأولوية لنفس التجار أولاً في مركبة",
      "Prioritize Multi City Dealers First in a Vehicle":
        "إعطاء الأولوية لتجار المدن المتعددة أولاً في مركبة",
      "Dispatch within Days": "الإرسال خلال أيام",
      "Allow Unplanned Loads": "السماح بالحمولات غير المخططة",
      "Minimize Number of Vehicles Used": "تقليل عدد المركبات المستخدمة",
      "Dealer Buffer Time": "وقت تخزين مؤقت للتاجر",
      "Buffer Time(Mins)": "وقت التخزين المؤقت (بالدقائق)",
      "Month End Planning Start Day": "يوم بدء التخطيط لنهاية الشهر",
      "Day of Every Month": "يوم كل شهر",
      "Max Local No of Customers Per Vehicle":
        "الحد الأقصى لعدد العملاء المحليين لكل مركبة",
      "Max Upcountry No of Customers Per Vehicle":
        "الحد الأقصى لعدد العملاء في المناطق النائية لكل مركبة",
      "Planning Type": "نوع التخطيط",
      "Local Prioritization": "إعطاء الأولوية للمحليين",
      "Local Prioritization and Virtual Upcountry clubbing":
        "إعطاء الأولوية للمحليين ودمج افتراضي للمناطق النائية",
      "General Plan (Local and Upcountry clubbing)":
        "خطة عامة (دمج المحليين والمناطق النائية)",
      "Telescopic Deviation": "الانحراف التلسكوبي",
      "MOQ for Planning": "الحد الأدنى لكمية التخطيط",
      Reconciliation: "التسوية",
      "DC Week Off planning": "تخطيط أسبوع الإيقاف لمراكز البيانات",
      "Save Inbound Configurations": "حفظ تكوينات الوارد",
      "Save Outbound Configurations": "حفظ تكوينات الصادر",
      SAVE: "حفظ",
      "Enable Reconciliation": "تمكين التسوية",
      "Enable DC Week Off planning":
        "تمكين تخطيط أسبوع الإيقاف لمراكز البيانات",
      "Deviation Alert": "تنبيه الانحراف",
      "Overload Warning": "تحذير التحميل الزائد",
      "Underload Alert": "تنبيه التحميل الناقص",
      "Fuel Efficiency Report": "تقرير كفاءة الوقود",
      "Vehicle Downtime": "توقف المركبة",
      "Speed Violation Alert": "تنبيه انتهاك السرعة",
      "Trip Delay Notification": "إشعار تأخير الرحلة",
      "Idle Time Monitoring": "مراقبة وقت التوقف",
      "Driver Performance": "أداء السائق",
      "Realtime GPS Tracking": "تتبع GPS في الوقت الفعلي",
      "Fleet Utilization": "استخدام الأسطول",
      "Trip Efficiency": "كفاءة الرحلة",
      "Dynamic Route Adjustment": "تعديل المسار الديناميكي",
      "Load Distribution Optimization": "تحسين توزيع الحمولة",
      "Transporter Preferences": "تفضيلات الناقل",
      "Trip Schedule": "جدول الرحلة",
      "Driver Assignment": "تخصيص السائق",
      "Optimize Vehicle Utilization": "تحسين استخدام المركبة",
      "Time Based Planning": "التخطيط على أساس الوقت",
      "Plant Wise Route Planning": "تخطيط المسار حسب المصنع",
      "Vehicle Rotation Configuration": "تكوين دوران المركبة",
      "Inbound Vehicle Preferences": "تفضيلات مركبة الوارد",
      "Outbound Vehicle Preferences": "تفضيلات مركبة الصادر",
      "Vehicle Fill Rate Optimization": "تحسين معدل ملء المركبة",
      "Dynamic Load Allocation": "توزيع الحمولة الديناميكي",
      "Start Date": "تاريخ البدء",
      "Run Plan": "خطة التشغيل",
      "Completion Date": "تاريخ الانتهاء",
      "Transporter Name": "اسم الناقل",
      "Driver Allocation": "تخصيص السائق",
      "Max Load Capacity": "الحد الأقصى لسعة الحمولة",
      "Estimated Delivery Time": "الوقت المقدر للتسليم",
      "Use Real-Time Traffic Data": "استخدام بيانات المرور في الوقت الفعلي",
      "Driver Break Times": "أوقات استراحة السائق",
      "Max Vehicle Load Percentage": "الحد الأقصى لنسبة حمولة المركبة",
      "Telescopic Deviation Settings": "إعدادات الانحراف التلسكوبي",
      "Deviation Type": "نوع الانحراف",
      "Spot Vehicle Usage": "استخدام مركبات النقاط",
      "Real-time Load Monitoring": "مراقبة الحمولة في الوقت الفعلي",
      "Driver Allocation Time": "وقت تخصيص السائق",
      "Use Traffic Data": "استخدام بيانات المرور",
      "Optimize Route": "تحسين المسار",
      "Optimize Fuel Efficiency": "تحسين كفاءة الوقود",
      "Daily Vehicle Plan": "خطة المركبة اليومية",
      "Transporter Allocation": "تخصيص الناقل",
      "Enable Real-time Tracking": "تمكين التتبع في الوقت الفعلي",
      "Vehicle Rotation Plan": "خطة دوران المركبة",
      "Route Optimization Preferences": "تفضيلات تحسين المسار",
      "Consider Weather Conditions": "مراعاة ظروف الطقس",
      "Max Loading Time": "الحد الأقصى لوقت التحميل",
      "Min Load Capacity": "الحد الأدنى لسعة الحمولة",
      "Driver Scheduling": "جدولة السائق",
      "Vehicle Capacity Monitoring": "مراقبة سعة المركبة",
      "Dynamic Load Balancing": "موازنة الحمولة الديناميكية",
      "Last Mile Optimization": "تحسين الميل الأخير",
      "Unloading Time Management": "إدارة وقت التفريغ",
      "Daily Trip Plan": "خطة الرحلة اليومية",
      "Delivery Window Optimization": "تحسين نافذة التسليم",
      "Night Driving Restrictions": "قيود القيادة الليلية",
      "Weekend Planning": "تخطيط عطلة نهاية الأسبوع",

      run_new_plan: "تشغيل خطة جديدة",
      planned_day: "اليوم المخطط",
      drop_sequence: "تسلسل الإسقاط",
      planned_distance_miles: "المسافة المخطط لها (أميال)",
      transporter_details: "تفاصيل الناقل",
      restriction_hours: "ساعات التقييد",
      save_geofence: "حفظ السياج الجغرافي",
      no_data: "لا توجد بيانات",
      redirecting_to_vehicle_checklist: "إعادة التوجيه إلى قائمة فحص المركبات",
      drop_location_code_destination: "رمز موقع الإسقاط (الوجهة)",
      drop_location_name_destination: "اسم موقع الإسقاط (الوجهة)",
      destination_location_city: "مدينة الموقع الوجهة",
      dealer_type: "نوع الوكيل",
      customer_type: "نوع العميل",
      week_off: "إجازة أسبوعية",
      order_number: "رقم الطلب",
      invoicing_date: "تاريخ الفاتورة",
      quantity: "الكمية",
      weight_pounds: "الوزن (رطل)",
      volume_cft: "الحجم (CFT)",
      distance_to_destination_miles: "المسافة إلى الوجهة (ميل)",
      expected_delivery_date: "تاريخ التسليم المتوقع",
      time_to_destination: "الوقت إلى الوجهة",
      transit_time_hh_mm: "وقت النقل (HH:MM)",
      unloading_time_hh_mm: "وقت التفريغ (HH:MM)",
      email: "البريد الإلكتروني",
      details: "تفاصيل",
      view: "عرض",
      assign_vehicle: "تعيين المركبة",
      revoke_assignment: "إلغاء التعيين",
      edit_plan: "تحرير الخطة",
      delete: "حذف",
      plan_edited: "تم تحرير الخطة",
      yes: "نعم",
      no: "لا",
      trip_no: "رقم الرحلة",
      vehicle_no: "رقم المركبة",
      vehicle_type: "نوع المركبة",
      cost: "التكلفة ($)",
      transit_type: "نوع النقل",
      transporter_name: "اسم الناقل",
      no_of_orders: "عدد الطلبات",
      no_of_customers: "عدد العملاء",
      total_quantity: "إجمالي الكمية",
      vehicle_utilization_percent: "استخدام المركبة (%)",
      vehicle_max_weight: "الوزن الأقصى للمركبة (رطل)",
      total_planned_weight: "الوزن المخطط الإجمالي (رطل)",
      onward_trip_distance_miles: "مسافة الرحلة الأمامية (ميل)",
      round_trip_distance_miles: "مسافة الرحلة ذهابًا وإيابًا (ميل)",
      vehicle_max_volume_cft: "الحجم الأقصى للمركبة (CFT)",
      total_planned_volume_cft: "الحجم المخطط الإجمالي (CFT)",
      max_dispatch_time: "الوقت الأقصى للإرسال (HH:MM)",
      arrival_at_first_dealer: "الوصول إلى أول وكيل (البداية)",
      departure_at_last_dealer: "المغادرة من آخر وكيل (النهاية)",
      return_to_plant: "العودة إلى المصنع (HH:MM)",
      total_planned_trip_time: "الوقت المخطط الإجمالي للرحلة (ساعات)",
      total_round_trip_time: "الوقت الإجمالي للرحلة ذهابًا وإيابًا (ساعات)",
      total_transit_time: "وقت النقل الإجمالي (HH:MM)",
      total_loading_time: "وقت التحميل الإجمالي (HH:MM)",
      total_unloading_time: "وقت التفريغ الإجمالي (HH:MM)",
      remark: "ملاحظة",
      "n/a": "غير متوفر",
      revoke_again_to_edit: "إلغاء التعيين للتحرير مرة أخرى",
      drop_location_code: "رمز موقع الإسقاط",
      drop_location_name: "اسم موقع الإسقاط",
      drop_location_city: "مدينة موقع الإسقاط",
      distance_from_plant: "المسافة من المصنع",
      demand_volume_cft: "حجم الطلب (CFT)",
      delivery_date: "تاريخ التسليم",
      actions: "إجراءات",
      pickup_location_source: "موقع الالتقاط (المصدر)",
      dealer_available_from: "الوكيل متاح من",
      dealer_available_to: "الوكيل متاح إلى",
      loading_time_hh_mm: "وقت التحميل (HH:MM)",
      dropped_reason: "سبب الإسقاط",
      dealer_location_data_not_in_system:
        "بيانات موقع الوكيل غير موجودة في النظام",
      delivery_date_less_than_current_date:
        "تاريخ التسليم أقل من التاريخ الحالي",
      kdd_customer: "عميل KDD",
      insufficient_load_for_planning: "حمولة غير كافية للتخطيط (<MOQ)",
      warehouse_name: "اسم المستودع",
      destination_city: "مدينة الوجهة",
      destination_state: "ولاية الوجهة",
      sales_order_no: "رقم طلب المبيعات",
      no_of_tires: "عدد الإطارات",
      order_date: "تاريخ الطلب",
      vehicle_name: "اسم المركبة",
      vehicle_priority: "أولوية المركبة",
      vehicle_availability_from: "توفر المركبة (من)",
      vehicle_availability_to: "توفر المركبة (إلى)",
      break_time_from: "وقت الاستراحة (من)",
      break_time_to: "وقت الاستراحة (إلى)",
      min_volume_cft: "الحجم الأدنى (CFT)",
      max_volume_cft: "الحجم الأقصى (CFT)",
      vehicle_dimensions_width_mm: "عرض المركبة (مم)",
      vehicle_dimensions_height_mm: "ارتفاع المركبة (مم)",
      vehicle_dimensions_depth_mm: "عمق المركبة (مم)",
      vehicle_min_weight: "الوزن الأدنى للمركبة (رطل)",
      max_no_of_nodes: "عدد عمليات التسليم",
      min_load_per_delivery: "الحمولة الأدنى لكل عملية تسليم",
      max_distance: "المسافة القصوى (ميل)",
      material_code: "رمز المادة",
      material_name: "اسم المادة",
      material_type: "نوع المادة",
      material_weight: "وزن المادة",
      material_dimensions_depth: "عمق المادة",
      material_dimensions_width: "عرض المادة",
      material_dimensions_height: "ارتفاع المادة",
      form_vehicle_types: "أنواع المركبات",
      dealer_form_state: "الولاية",
      dealer_form_city: "المدينة",
      freight_fixed_vehicle_cost: "تكلفة المركبة الثابتة",
      freight_driver_cost: "تكلفة السائق",
      freight_maintenance_cost: "تكلفة الصيانة",
      freight_per_cmt_cost: "التكلفة لكل CMT",
      freight_per_km_cost: "التكلفة لكل كيلومتر",
      vehicle_date: "التاريخ",
      vehicle_number: "رقم المركبة",
      plant_code: "رمز المصنع",
      plant_name: "اسم المصنع",
      available_time: "الوقت المتاح",
      gps_status: "حالة GPS",
      last_known_address: "آخر عنوان معروف",
      last_gps_time: "آخر وقت GPS",
      vehicle_location: "موقع المركبة",
      assigned_to_ot: "مخصص لـ OT",
      min_vfr: "أدنى VFR",
      order_information: "معلومات الطلب",
      vehicle_type_configurations: "تكوينات نوع السيارة",
      run_new_plan: "تشغيل خطة جديدة",
      new_plan_details: "تفاصيل الخطة الجديدة",
      order: "طلب",
      vehicle_configs: "إعدادات المركبة",
      order_information: "معلومات الطلب",
      plan_configs: "إعدادات الخطة",
      vehicle_availability: "توفر المركبة",
      vehicle_type: "نوع المركبة",
      vehicle_name: "اسم المركبة",
      vehicle_min_weight: "الوزن الأدنى للمركبة (رطل)",
      vehicle_max_weight: "الوزن الأقصى للمركبة (رطل)",
      vehicle_min_volume: "الحجم الأدنى للمركبة (قدم مكعب)",
      vehicle_max_volume: "الحجم الأقصى للمركبة (قدم مكعب)",
      max_distance_per_trip: "أقصى مسافة لكل رحلة (ميل)",
      vehicle_priority: "أولوية المركبة",
      enable_reconciliation: "تمكين التسوية",
      enable_dc_week_off_planning:
        "تمكين تخطيط العطلات الأسبوعية لمركز التوزيع",
      order_number: "رقم الطلب",
      invoicing_date: "تاريخ الفاتورة",
      quantity: "الكمية",
      demand_cft: "الطلب (قدم مكعب)",
      weight_pounds: "الوزن (رطل)",
      dealer_available_from: "الموزع متاح من",
      dealer_available_to: "الموزع متاح حتى",
      delivery_date: "تاريخ التسليم",
      general_settings: "الإعدادات العامة",
      prioritize_local_loads: "إعطاء الأولوية للشحنات المحلية",
      consider_dropping_distant_smaller_loads:
        "النظر في إسقاط الشحنات الأصغر البعيدة",
      plan_spot_vehicles: "تخطيط المركبات المؤقتة",
      city_based_entry_restriction: "قيود الدخول المعتمدة على المدينة",
      consider_vehicle_break_times:
        "النظر في فترات استراحة المركبة (القيادة الليلية)",
      consider_holiday_restrictions: "النظر في قيود العطلات",
      consider_realtime_vehicle_available_times:
        "النظر في أوقات توفر المركبة في الوقت الفعلي",
      consider_transporter_sob: "النظر في مركز التوزيع للناقل",
      planning_optimization_settings: "إعدادات تحسين التخطيط",
      use_dynamic_planning_window: "استخدام نافذة التخطيط الديناميكية",
      number_of_days_to_use_planning_window:
        "عدد الأيام لاستخدام نافذة التخطيط",
      optimization_type: "نوع التحسين",
      cost_optimization: "تحسين التكلفة",
      weight_based_optimization: "تحسين معتمد على الوزن",
      distance_based_optimization: "تحسين معتمد على المسافة",
      time_based_optimization: "تحسين معتمد على الوقت",
      volume_based_optimization: "تحسين معتمد على الحجم",
      vehicle_fill_rate_vfr_settings: "إعدادات معدل امتلاء المركبة",
      use_volume_for_vfr: "استخدام الحجم لمعدل امتلاء المركبة",
      use_weight_for_vfr: "استخدام الوزن لمعدل امتلاء المركبة",
      min_vfr_per_vehicle_type: "الحد الأدنى لمعدل امتلاء المركبة لكل نوع",
      min_vfr_per_spot_vehicle: "الحد الأدنى لمعدل امتلاء المركبة المؤقتة",
      moq_for_planning: "الحد الأدنى للتخطيط",
      load_planning_settings: "إعدادات تخطيط التحميل",
      number_of_loading_docks: "عدد الأرصفة التحميل",
      max_local_no_of_customers_per_vehicle:
        "الحد الأقصى لعدد العملاء المحليين لكل مركبة",
      max_upcountry_no_of_customers_per_vehicle:
        "الحد الأقصى لعدد العملاء خارج المدينة لكل مركبة",
      prioritize_same_dealers_first:
        "إعطاء الأولوية لنفس الموزعين في المركبة أولاً",
      prioritize_multi_city_dealers_first:
        "إعطاء الأولوية للموزعين المتعددين في المدن أولاً",
      note_highlighted_rows_red:
        "الصفوف المظللة بالأحمر تفتقر إلى بيانات GPS أو التكامل، حدد توفر المركبة يدوياً.",
      note_highlighted_rows_green: "الصفوف المظللة بالأخضر تم اعتبارها للتخطيط",
      vehicle_number: "رقم المركبة",
      transit_type: "نوع العبور",
      monthly_minimum_distance: "المسافة الشهرية الدنيا",
      remaining_distance: "المسافة المتبقية",
      vehicle_available_date: "تاريخ توفر المركبة (YYYY-MM-DD HH:mm)",
      charlie_hub: "محور تشارلي",
      enable_recommendation: "تمكين التوصية",
      planned_day: "اليوم المخطط",
      drop_sequence: "تسلسل التسليم",
      drop_location_code_destination: "رمز موقع التسليم (الوجهة)",
      drop_location_name_destination: "اسم موقع التسليم (الوجهة)",
      destination_location_city: "مدينة موقع التسليم",
      weight_kgs: "الوزن (كجم)",
      volume_m3: "الحجم (متر مكعب)",
      distance_to_destination_kms: "المسافة إلى الوجهة (كم)",
      expected_delivery_date: "تاريخ التسليم المتوقع",
      time_to_destination: "الوقت إلى الوجهة",
      transit_time_hh_mm: "وقت العبور (ساعات:دقائق)",
      unloading_time_hh_mm: "وقت التفريغ (ساعات:دقائق)",
      plan_id: "معرف الخطة",
      generated_on: "تم الإنشاء في",
      vehicle_availability_from: "توفر المركبة من",
      vehicle_availability_to: "توفر المركبة إلى",
      break_time_from: "وقت الاستراحة من",
      break_time_to: "وقت الاستراحة إلى",
      min_volume_m3: "الحد الأدنى للحجم (متر مكعب)",
      max_volume_m3: "الحد الأقصى للحجم (متر مكعب)",
      vehicle_width_mm: "عرض المركبة (مم)",
      vehicle_height_mm: "ارتفاع المركبة (مم)",
      vehicle_depth_mm: "عمق المركبة (مم)",
      material_code: "رمز المادة",
      material_name: "اسم المادة",
      material_type: "نوع المادة",
      material_weight: "وزن المادة",
      material_dimensions_depth: "أبعاد المادة - العمق",
      material_dimensions_width: "أبعاد المادة - العرض",
      material_dimensions_height: "أبعاد المادة - الارتفاع",
      state: "الولاية",
      city: "المدينة",
      fixed_vehicle_cost: "التكلفة الثابتة للمركبة",
      driver_cost: "تكلفة السائق",
      maintenance_cost: "تكلفة الصيانة",
      per_cmt_cost: "التكلفة لكل قدم مكعب",
      per_km_cost: "التكلفة لكل كم",
      date: "التاريخ",
      transporter_name: "اسم الناقل",
      no_of_vehicles: "عدد المركبات",
      driver_break_time_from: "وقت استراحة السائق من",
      vehicle_max_weight_kgs: "الوزن الأقصى للمركبة (كجم)",
      vehicle_min_volume_m3: "الحجم الأدنى للمركبة (متر مكعب)",
      vehicle_max_volume_m3: "الحجم الأقصى للمركبة (متر مكعب)",
      max_distance_kms: "أقصى مسافة (كم)",
      monthly_min_planning_distance_kms: "المسافة الشهرية الدنيا للتخطيط (كم)",
      details: "التفاصيل",
      trip_no: "رقم الرحلة",
      vehicle_no: "رقم المركبة",
      no_of_orders: "عدد الطلبات",
      no_of_customers: "عدد العملاء",
      total_quantity: "الكمية الإجمالية",
      vehicle_utilization_percent: "نسبة استخدام المركبة",
      total_planned_weight_kgs: "إجمالي الوزن المخطط (كجم)",
      onward_trip_distance_kms: "مسافة الرحلة القادمة (كم)",
      round_trip_distance_kms: "مسافة الرحلة الدائرية (كم)",
      total_planned_volume_m3: "إجمالي الحجم المخطط (متر مكعب)",
      max_dispatch_time_hh_mm: "أقصى وقت للإرسال (ساعات:دقائق)",
      arrival_at_first_dealer_start: "الوصول إلى أول موزع (البداية)",
      departure_at_last_dealer_end: "المغادرة من آخر موزع (النهاية)",
      return_to_plant_hh_mm: "العودة إلى المصنع (ساعات:دقائق)",
      total_planned_trip_time_hrs: "إجمالي وقت الرحلة المخطط (ساعات)",
      total_round_trip_time_hrs: "إجمالي وقت الرحلة الدائرية (ساعات)",
      total_transit_time_hh_mm: "إجمالي وقت العبور (ساعات:دقائق)",
      total_loading_time_hh_mm: "إجمالي وقت التحميل (ساعات:دقائق)",
      total_unloading_time_hh_mm: "إجمالي وقت التفريغ (ساعات:دقائق)",
      drop_location_code: "رمز موقع التسليم",
      drop_location_name: "اسم موقع التسليم",
      drop_location_city: "مدينة موقع التسليم",
      demand_volume_m3: "حجم الطلب (متر مكعب)",
      vehicle_min_weight_kgs: "الوزن الأدنى للمركبة (كجم)",
      no_of_deliveries: "عدد التسليمات",
      min_load_per_delivery: "الحد الأدنى للحمل لكل تسليم",
      status: "الحالة",
      processing: "جاري المعالجة",
      please_contact_admin: "يرجى الاتصال بالمسؤول",
      solver_complete: "المعالج مكتمل",
      solver_reco_processed: "تمت معالجة التوصية",
      solver_sob_done: "تم إنجاز SOB",
      solver_invalid_input_dealer_location: "موقع الموزع المدخل غير صالح",
      solver_invalid_input_materials: "المواد المدخلة غير صالحة",
      solver_invalid_input_dealer_location_and_materials:
        "موقع الموزع والمواد المدخلة غير صالحة",
      solver_input_data_not_valid: "بيانات المدخلات غير صالحة",
      solver_fix_dropped_dealers: "إصلاح الموزعين المسقطين",
      solver_vehicle_details_missing: "تفاصيل المركبة مفقودة",
      solver_input_file_data_error: "خطأ في بيانات ملف الإدخال",
      solver_invalid_file_template: "قالب الملف غير صالح",
      process_timed_out: "انتهت مدة العملية",
      solver_sob_fail: "فشل SOB",
      solver_reco_failed: "فشل التوصية",
      solver_no_tool_config: "لا يوجد تكوين للأداة",
      solver_invalid_tool_config: "تكوين الأداة غير صالح",
      transit_time_with_multiple_city_clubing:
        "وقت العبور مع دمج المدن المتعددة",
      rdd_preference: "تفضيل RDD",
      transit_time_with_single_city_clubing: "وقت العبور مع دمج مدينة واحدة",
      no_of_trucks_planned: "عدد الشاحنات المخطط لها",
      view_details: "عرض التفاصيل",
      plan_run_by: "الخطة تم تشغيلها بواسطة",
      compare_plans: "مقارنة الخطط",
      scenario_name: "اسم السيناريو",
      pick_up_location_source: "موقع الالتقاط (المصدر)",
      pickup_location_source: "موقع الالتقاط (المصدر)",
      drop_location_destination: "موقع التسليم (الوجهة)",
      demand_m3: "الطلب (متر مكعب)",
      location_radius: "نصف القطر (كم)",
      volume_cmt: "الحجم (قدم مكعب)",
      obd_details_in_radius: "تفاصيل OBD في النطاق",
      planning_configurations: "تكوينات التخطيط",
      planning_scenarios: "سيناريوهات التخطيط",
      what_if_demand_fluctuation: "ماذا لو: تقلب الطلب",
      increase_decrease_demand_by: "زيادة/تقليل الطلب بنسبة",
      what_if_customer_priority_changes: "ماذا لو: تغييرات أولوية العميل",
      prioritize_upcountry: "إعطاء الأولوية للمناطق الريفية",
      prioritize_local: "إعطاء الأولوية للمناطق المحلية",
      what_if_service_level_target_changes: "ماذا لو: تغييرات هدف مستوى الخدمة",
      set_service_level_target_to: "تحديد هدف مستوى الخدمة إلى",
      what_if_vehicle_fill_rate_changes: "ماذا لو: تغييرات معدل ملء المركبة",
      set_vehicle_fill_rate_to: "تحديد معدل ملء المركبة إلى",
      what_if_customer_specific_delivery_time: "ماذا لو: وقت تسليم محدد للعميل",
      increase_decrease_delivery_time_window_by:
        "زيادة/تقليل نافذة وقت التسليم بنسبة",
      scenario_description: "وصف السيناريو",
      vehicle_fill_rate_settings: "إعدادات معدل ملء المركبة",
      file_name: "اسم الملف",
      total_weight_kgs: "الوزن الإجمالي (كجم)",
      total_demand_volume_m3: "الحجم الإجمالي للطلب (متر مكعب)",
      processing_plan_please_wait: "جاري معالجة الخطة. يرجى الانتظار...",
      forecast_input_information: "معلومات المدخلات التنبؤية",
      uploading_data_for_plant: "جاري تحميل البيانات للمصنع",
      vehicle_type_configuration: "تكوين نوع المركبة",
      min_volume_cft: "الحجم الأدنى (قدم مكعب)",
      max_volume_cft: "الحجم الأقصى (قدم مكعب)",
      cost_per_km: "التكلفة لكل كم",
      note_duplicate_scenario: "ملاحظة: سيناريو مكرر",
      select_unique_scenarios_to_compare:
        "يرجى اختيار سيناريوهات فريدة لمقارنة الخطط",
      scenario_selection_limit_exceeded: "تم تجاوز حد اختيار السيناريوهات",
      note_scenario_selection_limit:
        "ملاحظة: يمكنك اختيار ما يصل إلى ثلاثة سيناريوهات",
      select_scenario_to_compare: "يرجى اختيار السيناريو لمقارنة الخطط",
      forecast_scenarios: "سيناريوهات التنبؤ",
      select_planning_duration: "اختر مدة التخطيط",
      notify_email_users_after_process:
        "إخطار مستخدمي البريد الإلكتروني بعد العملية (مفصولة بفاصلة)",
      optimize_by_time_details: "تحسين بالتفاصيل الزمنية",
      max_time_limit_hrs: "الحد الأقصى للوقت (ساعات)",
      spot_vehicles_to_be_planned: "المركبات المؤقتة للتخطيط",
      auto_select: "الاختيار التلقائي",
      vehicle_routes: "طرق السيارة",
      total_customers: "إجمالي العملاء",
      planned: "مخطط",
      unplanned: "غير مخطط",
      total_round_trip_distance_miles:
        "إجمالي مسافة الرحلة ذهابًا وإيابًا (أميال)",
      planning: "تخطيط",
      vfr: "VFR",
      ontime_dispatch: "إرسال في الوقت المحدد",
      avg_tat: "متوسط TAT",
      view_locations: "عرض المواقع",
      view_routes: "عرض الطرق",
      additional_vehicles: "مركبات إضافية",
      regular_vehicles: "مركبات منتظمة",
      enter_vehicle_no: "أدخل رقم السيارة",
      vehicle_has_active_trip_shipment_id: "السيارة لها رقم شحنة رحلة نشطة",
      do_you_wish_to: "هل ترغب في",
      force_close_assign: "إغلاق القوة والتعيين",
      force_close_reason: "سبب الإغلاق القسري",
      gps_issue: "مشكلة GPS",
      trip_closed: "الرحلة مغلقة",
      load_cancelled: "تم إلغاء التحميل",
      new_trip_allocated: "تم تخصيص رحلة جديدة",
      driver_changed: "تم تغيير السائق",
      are_you_sure_you_want_to_update: "هل أنت متأكد أنك تريد التحديث",
      edit_plan_for_same: "تحرير الخطة لنفسه",
      update_vehicle_plan: "تحديث خطة السيارة",
      max_dispatch_time: "أقصى وقت للإرسال",
      new_plan: "خطة جديدة",
      reason_for_plan_change: "سبب تغيير الخطة",
      enter_the_reason: "أدخل السبب",
      reason_for_plan_deletion: "سبب حذف الخطة",
      are_you_sure_delete_plan: "هل أنت متأكد أنك تريد حذف الخطة",
      update_order_details: "تحديث تفاصيل الطلب",
      demand_volume: "حجم الطلب",
      dealer_availability_start_time: "وقت بدء توفر الوكيل",
      dealer_availability_end_time: "وقت انتهاء توفر الوكيل",
      configurations: "التكوينات",
      download: "تحميل",
      dealerTimeError:
        "يجب أن يكون وقت البدء المتاح للتاجر أقل من وقت الانتهاء",
      more: "المزيد",
      less: "أقل",
      qualityChecks: "فحوصات الجودة",
      currentMonthPlanDistance: "مسافة خطة الشهر الحالي",
      email: "البريد الإلكتروني",
      viewPlan: "عرض الخطة",
      blank_rows_columns_not_allowed: "الصفوف/الأعمدة الفارغة غير مسموح بها.",
      import_existing_vehicle_details: "استيراد تفاصيل المركبة الحالية",
      uploading_data_for_plant: "تحميل البيانات للمصنع",
      check_and_remove_blank_data:
        "للتحقق من البيانات الفارغة وإزالتها، استخدم بحث وتحديد → الانتقال إلى الخاص → فارغ، ثم احذف الصفوف المظللة.",
      add_vehicle_type: "إضافة نوع المركبة",
      vehicle_operational_hours: "ساعات تشغيل المركبة (HH:MM)",
      data_saved_successfully: "تم حفظ البيانات بنجاح",
      plant_wise_configurations: "تكوينات المصنع",
      save_plant_wise_configurations: "حفظ تكوينات المصنع",
      vehicle_break_window: "نافذة استراحة المركبة (HH:MM)",
      total_vehicles: "إجمالي المركبات",
      zone_configurations: "تكوينات المناطق",
      pickup_point: "نقطة الالتقاط",
      actions: "الإجراءات",
      edit: "تحرير",
      add_transporter_details: "+ إضافة تفاصيل الناقل",
      cost_per_mile: "التكلفة لكل ميل",
      freight_details: "تفاصيل الشحن",
      add_zone: "+ إضافة منطقة",
      fixed_vehicle_cost_title: "تكلفة المركبة الثابتة ($)",
      variable_vehicle_cost_title: "تكلفة المركبة المتغيرة",
      city_wise_cost: "تكلفة المركبة حسب المدينة",
      zone_wise_cost: "تكلفة المركبة حسب المنطقة",
      vehicle_cost_configurations: "تكوينات تكلفة المركبة",
      freight_cost_label: "تكلفة الشحن",
      validity_to_label: "صالح حتى",
      validity_from_label: "صالح من",
      actions_label: "الإجراءات",
      edit_button: "تحرير",
      delete_button: "حذف",
      save_button: "حفظ",
      cancel_button: "إلغاء",
      select_vehicle_type: "اختيار نوع المركبة",
      select_transporter_name: "اختيار اسم الناقل",
      zone: "المنطقة",
      state: "الولاية",
      driver_cost: "تكلفة السائق ($)",
      maintenance_cost: "تكلفة الصيانة ($)",
      fixed_vehicle_cost_label: "تكلفة المركبة الثابتة ($)",
      enable_disable_dispatch_planning_module: "تمكين/تعطيل وحدة تخطيط التوزيع",
      inbound: "الوارد",
      suppliers_vendor_locations_pickup: "مواقع الموردين/البائعين (الالتقاط)",
      operational_hours: "ساعات التشغيل",
      pickup_locations_drop: "مواقع الالتقاط (التسليم)",
      plant_wise_vehicle_type_configuration: "تكوين نوع المركبة حسب المصنع",
      max_no_of_vehicle_types: "الحد الأقصى لأنواع المركبات",
      vehicle_load_capacity_min_max_configuration_preferences:
        "تفضيلات التكوين للسعة التحميلية للمركبة الدنيا/العليا",
      vehicle_load_weight_min_max_preferences:
        "تفضيلات الوزن التحميلي للمركبة الدنيا/العليا",
      distance_preferences: "تفضيلات المسافة",
      vehicle_priorities: "أولويات المركبة",
      vehicle_operational_times: "أوقات تشغيل المركبة",
      vehicle_availability: "توفر المركبة",
      generic_time_windows: "نوافذ الوقت العامة",
      individual_vehicle_time_windows: "نوافذ الوقت للمركبة الفردية",
      availability_by_vehicle_type: "التوفر حسب نوع المركبة",
      availability_by_individual_vehicle: "التوفر حسب المركبة الفردية",
      monthly_minimum_planning_distance_preferences:
        "تفضيلات الحد الأدنى للمسافة المخططة الشهرية",
      gps_based_availability: "التوفر بناءً على نظام تحديد المواقع",
      truck_location_visibility: "رؤية موقع الشاحنة",
      plant_vicinity_circle_radius: "نصف قطر دائرة جوار المصنع",
      plant_wise_freight_configuration: "تكوين الشحن حسب المصنع",
      plant_wise_transporter_configuration: "تكوين الناقل حسب المصنع",
      transporter_share_of_business: "حصة الناقل من الأعمال",
      route_preferences: "تفضيلات الطريق",
      plant_wise_new_plan_creation: "إنشاء خطة جديدة حسب المصنع",
      plant_wise_plan_summary: "ملخص الخطة حسب المصنع",
      plant_wise_planning_history: "تاريخ التخطيط حسب المصنع",
      sustainability_integration: "تكامل الاستدامة",
      save_inbound_configurations: "حفظ تكوينات الوارد",
      save_outbound_configurations: "حفظ تكوينات الصادر",
      outbound: "الصادر",
      consignees_dealers_locations_drop: "مواقع المستلمين/الوكلاء (التسليم)",
      vehicles: "المركبات",
      total_trucks: "إجمالي الشاحنات",
      active: "نشط",
      cost_details: "تفاصيل التكلفة",
      fixed_vehicle_cost: "تكلفة المركبة الثابتة",
      variable_vehicle_cost: "تكلفة المركبة المتغيرة",
      city_wise_vehicle_cost: "تكلفة المركبة حسب المدينة",
      zone_wise_vehicle_cost: "تكلفة المركبة حسب المنطقة",
      // pickup_point: "نقطة الالتقاط",
      vehicle_type: "نوع المركبة",
      transporter_name: "اسم الناقل",
      cost_per_mile_below_sob: "التكلفة لكل ميل (أقل من SOB)",
      cost_per_mile_after_sob: "التكلفة لكل ميل (بعد SOB)",
      validity_from: "ساري من",
      validity_to: "ساري حتى",
      add_vehicle_cost: "+ إضافة تكلفة المركبة",
      freight_details: "تفاصيل الشحن",
      select_transporter: "اختر الناقل",
      select_zone: "اختر المنطقة",
      select_state: "اختر الولاية",
      city: "المدينة",
      cost: "التكلفة ($)",
      save: "حفظ",
      cancel: "إلغاء",
      actions: "الإجراءات",
      validity_from_label: "ساري من",
      validity_to_label: "ساري حتى",
      get_data: "احصل على البيانات",
      state: "الولاية",
      zone: "المنطقة",
      fixed_vehicle_cost_label: "تكلفة المركبة الثابتة ($)",
      driver_cost: "تكلفة السائق ($)",
      maintenance_cost: "تكلفة الصيانة ($)",
      city: "المدينة",
      zone: "المنطقة",
      vehicle_cost: "تكلفة المركبة",
      freight_cost: "تفاصيل الشحن",
      inactive: "غير نشط",
      inside_dc: "داخل DC",
      in_transit_to_customer: "في النقل إلى العميل",
      near_customer: "بالقرب من العميل",
      return_to_dc: "العودة إلى DC",
      empty_outside_dc: "فارغ خارج DC",
      zone_configurations: "تهيئة المناطق",
      transporters_configurations: "تهيئة الناقلين",
      truck_no: "رقم الشاحنة",
      facility_name: "اسم المنشأة",
      zones: "المناطق",
      actions: "الإجراءات",
      edit: "تعديل",
      delete: "حذف",
      add_zone_mapping: "إضافة تخطيط المنطقة",
      current_status: "الحالة الحالية",
      next_availability: "التوفر القادم",
      dc_location: "موقع DC",
      add_route_restriction: "إضافة قيود الطريق",
      location_code: "رمز الموقع",
      location_name: "اسم الموقع",
      restricted_area: "المنطقة المقيدة",
      vehicle_list: "قائمة المركبات",
      from_time: "من الوقت",
      to_time: "إلى الوقت",
      customer_location: "موقع العميل",
      truck_no: "رقم الشاحنة",
      facility_name: "اسم المنشأة",
      current_status: "الحالة الحالية",
      inside_dc: "داخل DC",
      in_transit_to_customer: "في النقل إلى العميل",
      near_customer: "بالقرب من العميل",
      return_to_dc: "العودة إلى DC",
      empty_outside_dc: "فارغ خارج DC",
      unplanned: "غير مخطط",
      next_availability_date_at_dc: "تاريخ التوفر القادم في DC",
      transporter_name: "اسم الناقل",
      gps_status: "حالة GPS",
      inactive: "غير نشط",
      active: "نشط",
      na: "غير متوفر",
      actions: "الإجراءات",
      date_and_time: "التاريخ والوقت",
      security: "الأمن",
      quality: "الجودة",
      assessed_by: "تم التقييم بواسطة",
      quality_conclusion: "استنتاج الجودة",
      quality_comments: "تعليقات الجودة",
      quality_approved: "موافقة الجودة",
      quality_date_of_assessment: "تاريخ تقييم الجودة",
      zone_mapping: "تخطيط المناطق",
      transporters: "النقل",
      material_configurations: "تهيئة المواد",
      freight_configurations: "تهيئة الشحن",
      route_configurations: "تهيئة الطرق",
      auto_clustering: "التجميع التلقائي",
      transporter_share_of_business: "حصة النقل من الأعمال",
      dispatch_plan_summary: "ملخص خطة الشحن",
      kpi_dashboard: "لوحة مؤشرات الأداء الرئيسية",
      planning_tools: "أدوات التخطيط",
      forecast_tools: "أدوات التنبؤ",
      suppliers: "الموردين",
      customers: "العملاء",
      materials: "المواد",
      input_data: "بيانات الإدخال",
      forecast: "التوقعات",
      previous_plan: "الخطة السابقة",
      trips: "الرحلات",
      gps_quality: "جودة GPS",
      truck_gps_data: "بيانات GPS للشاحنة",
      unscheduled_stoppages: "التوقفات غير المجدولة",
      deviation_reports: "تقارير الانحراف",
      fo_data: "بيانات FO",
      vehicle_checklist: "قائمة فحص المركبات",
      vehicle_status: "حالة المركبة",
      route_restrictions: "قيود الطريق",
      consolidated_run_plan: "خطة التشغيل الموحدة",
      plans_overview: "نظرة عامة على الخطط",
      input_plan_summary: "ملخص خطة الإدخال",
      load_plan_summary: "ملخص خطة التحميل",
      plant_wise_configurations: "تهيئة حسب المصنع",
      vehicle_configuration: "تهيئة المركبة",
      monthly_data: "البيانات الشهرية",
      forecast_plan_history: "تاريخ خطة التنبؤ",
      obd_analysis: "تحليل OBD",
      actions: "الإجراءات",
      pickup_location_source: "موقع الالتقاط (المصدر)",
      drop_location_destination: "موقع التسليم (الوجهة)",
      order_number: "رقم الطلب",
      invoicing_date: "تاريخ الفاتورة (M/D/YYYY)",
      quantity: "الكمية",
      dealer_available_time_from: "وقت توفر الوكيل (من 00:00)",
      dealer_available_time_to: "وقت توفر الوكيل (حتى 23:59)",
      volume_cft: "الحجم (CFT)",
      weight_pounds: "الوزن (رطل)",
      delivery_date: "تاريخ التسليم (M/D/YYYY)",
      no_rows_to_show: "لا توجد صفوف لعرضها",
      rows: "الصفوف",
      total_rows: "إجمالي الصفوف",
      add_dispatch: "إضافة شحنة",
      run_new_plan_by_file: "تشغيل خطة جديدة بواسطة ملف",
      submit_run_plan: "إرسال وتشغيل الخطة",
      delete: "حذف",
      upload_input_file: "تحميل ملف الإدخال",
      upload_file: "تحميل ملف",
      download_sample_template: "تنزيل قالب العينة",
      save: "حفظ",
      cancel: "إلغاء",
      dealer_available_time_error: "يجب أن يكون وقت البدء أقل من وقت الانتهاء",
      ok: "موافق",
      summary_dashboard: "لوحة الملخص",
      // pickup_point: "نقطة الالتقاط",
      date_created: "تاريخ الإنشاء",
      get_data: "الحصول على البيانات",
      plan_details: "تفاصيل الخطة",
      plan_history: "تاريخ الخطة",
      drop_details: "تفاصيل التسليم",
      generate_plan: "إنشاء خطة",
      order_id: "معرّف الطلب",
      no_of_runs: "عدد التشغيلات",
      created_on: "تم الإنشاء في",
      plan_run_by: "تم تشغيل الخطة بواسطة",
      discard_obd: "تجاهل OBD",
      upload_obd_details: "تحميل تفاصيل OBD",
      download_template: "تنزيل القالب",
      choose_file: "اختر ملف",
      submit: "إرسال",
      start_date: "تاريخ البدء",
      end_date: "تاريخ الانتهاء",
      get_data: "الحصول على البيانات",
      upload_input_file: "تحميل ملف الإدخال",
      discard_obd: "تجاهل OBD",
      recent_plans: "الخطط الأخيرة",
      plan_history: "تاريخ الخطة",
      view_input: "عرض الإدخال",
      consolidation_id: "معرّف التوحيد",
      created_date: "تاريخ الإنشاء",
      run_by: "تم التشغيل بواسطة",
      no_of_executions: "عدد التشغيلات",
      execution_status: "حالة التنفيذ",
      download_output: "تنزيل المخرجات",
      upload_obd_details: "تحميل تفاصيل OBD",
      download_template: "تنزيل القالب",
      choose_file: "اختر ملف",
      submit: "إرسال",
      simulation_time: "وقت المحاكاة",
      enable_dc_week_off_planning: "تمكين التخطيط لعطلة أسبوع DC",
      run_plan: "تنفيذ الخطة",
      cancel: "إلغاء",
      plan_details: "تفاصيل الخطة",
      // pickup_point: "نقطة الاستلام",
      date_created: "تاريخ الإنشاء",
      order_id: "معرّف الطلب",
      no_of_runs: "عدد مرات التنفيذ",
      fix_and_reupload: "الإصلاح وإعادة التحميل",
      club_previous_order: "دمج الطلب السابق",
      ignore: "تجاهل",
      dealer_code: "رمز الوكيل",
      remarks: "ملاحظات",
      run_new_plan: "تنفيذ خطة جديدة",
      consolidated_route_plan_screen: "شاشة خطة الطريق الموحدة",
      plant_code: "رمز المصنع",
      plant_name: "اسم المصنع",
      total_trips: "إجمالي الرحلات",
      trips_edited: "الرحلات المعدلة",
      edited_transaction_ids: "معرّفات المعاملات المعدلة",
      vehicle_type: "نوع السيارة",
      vehicle_name: "اسم السيارة",
      weight_pounds: "الوزن (بالرطل)",
      drop_location_city: "مدينة موقع التسليم",
      quantity: "الكمية",
      planned_vehicles: "المركبات المخطط لها",
      trip_no: "رقم الرحلة",
      total_planned_customers: "إجمالي العملاء المخطط لهم",
      total_round_trip_distance: "إجمالي مسافة الرحلة ذهابًا وإيابًا",
      planning_percentage: "نسبة التخطيط",
      max_dispatch_time: "أقصى وقت للتوزيع (ساعة:دقيقة)",
      total_loading_time: "إجمالي وقت التحميل (ساعة:دقيقة)",
      total_unloading_time: "إجمالي وقت التفريغ (ساعة:دقيقة)",
      planned_summary: "الملخص المخطط",
      invalid_orders_not_shown: "ملاحظة: الطلبات غير الصالحة غير معروضة",
      no_of_customers: "عدد العملاء",
      no_of_orders: "عدد الطلبات",
      total_demand_volume: "إجمالي حجم الطلب (CFT)",
      planned_summary: "الملخص المخطط",
      trips_removed: "الرحلات المحذوفة",
      removed_transaction_ids: "معرّفات المعاملات المحذوفة",
      vehicle_forecast_configurations: "إعدادات توقعات المركبات",
      // pickup_point: "نقطة الاستلام",
      vehicle_type: "نوع السيارة",
      vehicle_name: "اسم السيارة",
      cost_per_miles: "التكلفة لكل ميل",
      driver_break_time: "وقت استراحة السائق",
      view: "عرض",
      please_select_no_of_vehicles: "يرجى اختيار عدد المركبات",
      no_of_vehicles: "عدد المركبات",
      cost_per_miles: "التكلفة لكل ميل ($)",
      driver_break_time_from: "وقت استراحة السائق (من)",
      monthly_min_planning_distance_miles:
        "المسافة الدنيا للتخطيط الشهري (ميل)",
      vehicle_type_count: "عدد أنواع المركبات",
      driver_break_time_per_day: "وقت استراحة السائق اليومي (ساعة:دقيقة)",
      cost_per_miles_2: "التكلفة لكل ميل ($)",
      set_vehicle_priority: "تحديد أولوية السيارة",
      vehicle_type_name: "اسم نوع السيارة",
      min_volume_cft: "أدنى حجم (CFT)",
      max_volume_cft: "أقصى حجم (CFT)",
      max_distance_per_trip_miles: "أقصى مسافة لكل رحلة (ميل)",
      select_plan_id: "اختيار معرّف الخطة",
      drop_details: "تفاصيل التسليم",
      drop_location_code: "رمز موقع التسليم",
      drop_location_name: "اسم موقع التسليم",
      pickup_location: "موقع الاستلام",
      dealer_available_from: "الوكيل متاح من",
      dealer_available_to: "الوكيل متاح إلى",
      total_planned_weight: "إجمالي الوزن المخطط له",
      total_planned_volume: "إجمالي الحجم المخطط له",
      arrival_at_first_dealer: "الوصول إلى الوكيل الأول",
      departure_at_last_dealer: "مغادرة الوكيل الأخير",
      return_to_plant: "العودة إلى المصنع",
      summary_dashboard: "لوحة الملخص",
      dispatch_plan_details: "تفاصيل خطة التوزيع",
      invalid_orders_note: "ملاحظة: الطلبات غير الصالحة غير معروضة",
      planned_data: "البيانات المخططة",
      vfr_percentage: "نسبة VFR",
      cool_chain_adherence: "الالتزام بسلسلة التبريد",
      transit_time: "وقت النقل",
      loading_time: "وقت التحميل",
      unloading_time: "وقت التفريغ",
      planned_summary: "الملخص المخطط",
      invalid_drop_data: "بيانات التسليم غير الصالحة",
      vehicle_utilization_km: "استخدام المركبات (كم)",
      distance_to_destination: "المسافة إلى الوجهة",
      expected_delivery_date: "تاريخ التسليم المتوقع",
      planned_vehicles: "المركبات المخطط لها",
      trip_no: "رقم الرحلة",
      total_quantity: "إجمالي الكمية",
      dealer_available_to: "الوكيل متاح إلى",
      drop_location_code_destination: "رمز موقع التسليم (الوجهة)",
      order_number: "رقم الطلب",
      demand_volume_cft: "حجم الطلب (CFT)",
      weight_pounds: "الوزن (بالرطل)",
      drop_location_name_destination: "اسم موقع التسليم (الوجهة)",
      note_blank_rows_columns_not_allowed:
        "ملاحظة: لا يُسمح بالصفوف/الأعمدة الفارغة",
      check_blank_data_instructions:
        "للتحقق من البيانات الفارغة: 1. انقر فوق البحث والتحديد. 2. انقر فوق الانتقال إلى خاص. 3. اختر الخلايا الفارغة. 4. انقر فوق موافق وسيتم تمييز جميع الصفوف/الخلايا الفارغة. 5. اختر حذف من قسم الخلايا في علامة التبويب الرئيسية. 6. انقر فوق حذف صفوف الورقة.",
      disclaimer_dealer_window_ot_customers:
        "إخلاء المسؤولية: سيتم النظر في نافذة العملاء المتاحة من ملف الإدخال، سيتم اعتبار توقيتات العملاء GT من مواقع الاستلام/التسليم",
      quantity: "الكمية",
      specifications: "المواصفات",
      please_select_plan_id_to_compare_plans:
        "الرجاء تحديد معرف الخطة لمقارنة الخطط",
      vehicle_forecast_summary_dashboard: "لوحة ملخص توقعات المركبات",
      // pickup_point: "نقطة الاستلام",
      view_details: "عرض التفاصيل",
      compare_plans: "مقارنة الخطط",
      pickup_drop_points: "نقاط الاستلام/التسليم",
      edit: "تحرير",
      pickup: "استلام",
      drop: "تسليم",
      both: "كلاهما",
      pickup_location_code: "رمز موقع الاستلام",
      dealer_type: "نوع الوكيل",
      select: "تحديد",
      local: "محلي",
      upcountry: "الداخلية",
      vehicle_restriction_list: "قائمة تقييد المركبات",
      week_off: "عطلة أسبوعية",
      csp_day: "يوم CSP",
      is_customer_kdd: "هل العميل KDD؟",
      start_month: "شهر البدء",
      end_month: "شهر الانتهاء",
      plan_id: "معرف الخطة",
      fecha_creada: "تاريخ الإنشاء",
      no_of_trucks_planned: "عدد الشاحنات المخطط لها",
      total_planned_volume_cft: "إجمالي الحجم المخطط (CFT)",
      total_planned_weight_pounds: "إجمالي الوزن المخطط (رطل)",
      plan_run_by: "تم تنفيذ الخطة بواسطة",
      planned_vehicles: "المركبات المخطط لها",
      trip_no: "رقم الرحلة",
      total_planned_customers: "إجمالي العملاء المخطط لهم",
      total_round_trip_distance: "إجمالي مسافة الرحلة ذهابًا وإيابًا",
      planning_percentage: "نسبة التخطيط",
      max_dispatch_time: "الحد الأقصى لوقت الإرسال (HH:MM)",
      total_loading_time: "إجمالي وقت التحميل (HH:MM)",
      total_unloading_time: "إجمالي وقت التفريغ (HH:MM)",
      planned_summary: "ملخص الخطة",
      invalid_orders_not_shown: "ملاحظة: الطلبات غير الصالحة غير معروضة",
      no_of_customers: "عدد العملاء",
      no_of_orders: "عدد الطلبات",
      total_demand_volume: "إجمالي حجم الطلب (CFT)",
      planned_summary: "ملخص الخطة",
      trips_removed: "تمت إزالة الرحلات",
      removed_transaction_ids: "معرفات المعاملات التي تمت إزالتها",
      vehicle_forecast_configurations: "تكوينات توقعات المركبات",
      // pickup_point: "نقطة الاستلام",
      vehicle_type: "نوع المركبة",
      vehicle_name: "اسم المركبة",
      cost_per_miles: "التكلفة لكل ميل",
      driver_break_time: "وقت استراحة السائق",
      view: "عرض",
      please_select_no_of_vehicles: "الرجاء تحديد عدد المركبات",
      no_of_vehicles: "عدد المركبات",
      obd_analysis: "تحليل OBD",
      // pickup_point: "نقطة الاستلام",
      select_month: "حدد الشهر",
      location_radius_miles: "نصف قطر الموقع (أميال)",
      submit: "إرسال",
      total_obd_details: "إجمالي تفاصيل OBD",
      valid_dealers: "الوكلاء الصالحون",
      invalid_dealers: "الوكلاء غير الصالحين",
      volume: "الحجم",
      help: "مساعدة",
      change_password: "تغيير كلمة المرور",
      logout: "تسجيل الخروج",
      weight_pounds: "الوزن (رطل)",
      quantity: "الكمية",
      obd_details_within_radius: "تفاصيل OBD ضمن النطاق",
      dealers: "الوكلاء",
      dealers_percent: "نسبة الوكلاء",
      volume_percent: "نسبة الحجم",
      weight_pounds_percent: "نسبة الوزن (رطل)",
      quantity_percent: "نسبة الكمية",
      cost_per_miles: "التكلفة لكل ميل ($)",
      driver_break_time_from: "وقت استراحة السائق (من)",
      monthly_min_planning_distance_miles:
        "الحد الأدنى للمسافة المخططة الشهرية (أميال)",
      vehicle_type_count: "عدد أنواع المركبات",
      driver_break_time_per_day: "وقت استراحة السائق لكل يوم (HH:MM)",
      cost_per_miles_2: "التكلفة لكل ميل ($)",
      set_vehicle_priority: "تحديد أولوية المركبة",
      vehicle_type_name: "اسم نوع المركبة",
      min_volume_cft: "الحد الأدنى للحجم (CFT)",
      max_volume_cft: "الحد الأقصى للحجم (CFT)",
      max_distance_per_trip_miles: "الحد الأقصى للمسافة لكل رحلة (أميال)",
      select_plan_id: "حدد معرف الخطة",
      drop_details: "تفاصيل التسليم",
      select_vehicle_type: "اختيار نوع المركبة",
      select_transporter_name: "اختيار اسم الناقل",
      zone: "المنطقة",
      state: "الولاية",
      driver_cost: "تكلفة السائق ($)",
      maintenance_cost: "تكلفة الصيانة ($)",
      fixed_vehicle_cost_label: "تكلفة المركبة الثابتة ($)",
      delete: "حذف",
      state: "الولاية",
      city: "المدينة",
      zone: "المنطقة",
      delta_warehouse: "مستودع دلتا",
      zones: "المناطق",
      zone_mapping: "تخطيط المناطق",
      city_not_mapped_to_state: "هذه المدينة غير مخصصة لأي ولاية",
      actions: "الإجراءات",
      add_zones: "+ إضافة مناطق",
      transporter_master: "ماستر الناقل",
      transporter_configurations: "تكوينات الناقل",
      transporter_operations: "عمليات الناقل",
      transporter_code: "رمز الناقل",
      transporter_name: "اسم الناقل",
      actions: "الإجراءات",
      edit: "تحرير",
      delete: "حذف",
      zone_configuration: "تكوين المنطقة",
      vehicle_checklist_for_transportation_requirements:
        "قائمة التحقق للمركبات لمتطلبات النقل",
      transporters_configuration: "تكوينات الناقلين",
      consolidated_route_plan_screen: "شاشة خطة الطريق الموحدة",
      plant_code: "رمز المصنع",
      select_plant_to_check_vehicle_availability:
        "اختر المصنع للتحقق من توفر المركبة",
      vehicle_availability: "توفر المركبات",
      note_trucks_near_plant_vicinity:
        "ملاحظة: الشاحنات بالقرب من المصنع على مسافة ",
      facility_name: "اسم المنشأة",
      vehicle_number: "رقم المركبة",
      transporter_code: "رمز الناقل",
      vehicle_status_dashboard: "لوحة تحكم حالة المركبات",
      dc: "مركز التوزيع",
      drop_location_code: "رمز موقع التسليم",
      vehicle_utilization: "استخدام المركبة",
      onward_trip_distance_miles: "مسافة الرحلة ذهاباً (ميل)",
      round_trip_miles: "رحلة ذهاب وإياب (ميل)",
      max_dispatch_time_hh_mm: "أقصى وقت للإرسال (HH:MM)",
      arrival_at_first_dealer_start: "الوصول إلى أول وكيل (بداية)",
      departure_at_last_dealer_end: "المغادرة من آخر وكيل (نهاية)",
      return_to_plant_hh_mm: "العودة إلى المصنع (HH:MM)",
      vehicle_number: "رقم المركبة",
      vehicle_availability_from: "توفر المركبة من",
      vehicle_availability_to: "توفر المركبة إلى",
      break_time_from: "وقت الاستراحة من",
      break_time_to: "وقت الاستراحة إلى",
      no_of_vehicles: "عدد المركبات",
      driver_break_time_from: "وقت استراحة السائق من",
      max_distance_miles: "أقصى مسافة (ميل)",
      monthly_min_planning_distance_miles:
        "الحد الأدنى للمسافة المخططة الشهرية (ميل)",
      total_planned_trip_time_hrs: "إجمالي وقت الرحلة المخطط (ساعات)",
      total_round_trip_time_hrs: "إجمالي وقت الرحلة ذهابًا وإيابًا (ساعات)",
      drop_location_code: "رمز موقع التسليم",
      drop_location_name: "اسم موقع التسليم",
      drop_location_city: "مدينة موقع التسليم",
      order_number: "رقم الطلب",
      delivery_date: "تاريخ التسليم (M/D/YYYY)",
      back: "رجوع",
      pickup_location: "موقع الالتقاط",
      dealer_available_from: "الوكيل متاح من",
      dealer_available_to: "الوكيل متاح إلى",
      total_planned_weight: "إجمالي الوزن المخطط",
      total_planned_volume: "إجمالي الحجم المخطط",
      arrival_at_first_dealer: "الوصول إلى أول وكيل",
      departure_at_last_dealer: "المغادرة من آخر وكيل",
      return_to_plant: "العودة إلى المصنع",
      summary_dashboard: "لوحة تحكم الملخص",
      dispatch_plan_details: "تفاصيل خطة التوزيع",
      invalid_orders_note: "ملاحظة: الطلبات غير الصالحة غير معروضة",
      planned_data: "البيانات المخططة",
      vfr_percentage: "النسبة المئوية لـ VFR",
      cool_chain_adherence: "الامتثال لسلسلة التبريد",
      transit_time: "وقت النقل",
      loading_time: "وقت التحميل",
      unloading_time: "وقت التفريغ",
      planned_summary: "الملخص المخطط",
      invalid_drop_data: "بيانات التسليم غير صالحة",
      vehicle_utilization_km: "استخدام المركبة (كم)",
      distance_to_destination: "المسافة إلى الوجهة",
      expected_delivery_date: "تاريخ التسليم المتوقع",
      planned_vehicles: "المركبات المخططة",
      trip_no: "رقم الرحلة",
      total_quantity: "إجمالي الكمية",
      dealer_available_to: "الوكيل متاح إلى",
      drop_location_code_destination: "رمز موقع التسليم (الوجهة)",
      order_number: "رقم الطلب",
      demand_volume_cft: "حجم الطلب (CFT)",
      weight_pounds: "الوزن (رطل)",
      quantity: "الكمية",
      dropped_reason: "سبب الحذف",
      delivery_date: "تاريخ التسليم",
      drop_location_name: "اسم موقع التسليم",
      drop_location_city: "مدينة موقع التسليم",
      trip_no: "رقم الرحلة",
      planned_day: "اليوم المخطط",
      vehicle_no: "رقم المركبة",
      vehicle_type: "نوع المركبة",
      drop_sequence: "تسلسل التسليم",
      invoicing_date: "تاريخ الفاتورة",
      pickup_location_source: "موقع الالتقاط (المصدر)",
      destination_location_city: "مدينة الوجهة",
      destination_location_state: "ولاية الوجهة",
      distance_to_destination_miles: "المسافة إلى الوجهة (ميل)",
      expected_delivery_date: "تاريخ التسليم المتوقع",
      time_to_destination: "الوقت إلى الوجهة",
      transit_time_hh_mm: "وقت النقل (HH:MM)",
      loading_time_hh_mm: "وقت التحميل (HH:MM)",
      unloading_time_hh_mm: "وقت التفريغ (HH:MM)",
      total_planned_customers: "إجمالي العملاء المخطط لهم",
      total_round_trip_distance_km: "إجمالي المسافة ذهابًا وإيابًا (كم)",
      planning_percentage: "نسبة التخطيط",
      cool_chain_adherence_percentage: "النسبة المئوية للامتثال لسلسلة التبريد",
      total_transit_time_hh_mm: "إجمالي وقت النقل (HH:MM)",
      total_planned_weight_pounds: "إجمالي الوزن المخطط (رطل)",
      total_planned_volume_cft: "إجمالي الحجم المخطط (CFT)",
      arrival_at_first_dealer_start: "الوصول إلى أول وكيل (بداية)",
      departure_at_last_dealer_end: "المغادرة من آخر وكيل (نهاية)",
      return_to_plant_hh_mm: "العودة إلى المصنع (HH:MM)",
      total_planned_trip_time_hrs: "إجمالي وقت الرحلة المخطط (ساعات)",
      total_round_trip_time_hrs: "إجمالي وقت الرحلة ذهابًا وإيابًا (ساعات)",
      total_loading_time_hh_mm: "إجمالي وقت التحميل (HH:MM)",
      total_unloading_time_hh_mm: "إجمالي وقت التفريغ (HH:MM)",
      vehicle_utilization_percentage: "نسبة استخدام المركبة (%)",
      view_order_information: "عرض معلومات الطلب",
      vehicle_configuration: "تكوين المركبة",
      summary: "الملخص",
      facility_name: "اسم المنشأة",
      vehicle_number: "رقم المركبة",
      vehicle_type: "نوع المركبة",
      transporter_code: "رمز الناقل",
      transporter_name: "اسم الناقل",
      last_packet_time: "وقت آخر حزمة",
      vehicle_checklist_for_transportation: "قائمة فحص المركبة للنقل",
      assessed_by: "تم التقييم بواسطة",
      security_conclusion: "استنتاج الأمن",
      security_comments: "تعليقات الأمن",
      planned_summary: "ملخص المخطط",
      planned_vehicles: "المركبات المخططة",
      unplanned_loads: "الأحمال غير المخططة",
      invalid_drop_data: "بيانات التسليم غير الصالحة",
      vehicle_utilization_km: "استخدام المركبة (كم)",
      planned_data: "البيانات المخططة",
      invalid_orders_note: "ملاحظة: لا يتم عرض الطلبات غير الصالحة",
      set_vehicle_priority: "تعيين أولوية المركبة",
      vehicle_min_weight_pounds: "الوزن الأدنى للمركبة (رطل)",
      vehicle_max_weight_pounds: "الوزن الأقصى للمركبة (رطل)",
      vehicle_min_volume_cft: "الحجم الأدنى للمركبة (CFT)",
      vehicle_max_volume_cft: "الحجم الأقصى للمركبة (CFT)",
      max_distance_miles: "أقصى مسافة (ميل)",
      vehicle_priority: "أولوية المركبة",
      forecast_order_plan: "خطة الطلب التنبؤية",
      drop_details: "تفاصيل التسليم",
      month: "الشهر",
      year: "السنة",
      total_quantity: "الكمية الإجمالية",
      total_weight_pounds: "الوزن الإجمالي (رطل)",
      total_demand_volume_cft: "إجمالي حجم الطلب (CFT)",
      user: "المستخدم",
      uploaded_date: "تاريخ التحميل",
      view_order_details: "عرض تفاصيل الطلب",
      pick_up_location_code: "رمز موقع الاستلام",
      quantity: "الكمية",
      drop_location: "موقع التسليم",
      drop_location_name: "اسم موقع التسليم",
      weight: "الوزن",
      volume: "الحجم",
      delivery_date: "تاريخ التسليم",
      invoice_date: "تاريخ الفاتورة",
      order_no: "رقم الطلب",
      available_start_time: "وقت البدء المتاح",
      available_end_time: "وقت الانتهاء المتاح",
      upload_input_file: "تحميل ملف الإدخال",
      download_sample_template: "تحميل قالب العينة",
      select_month: "اختر الشهر",
      upload_file: "تحميل ملف",
      save: "حفظ",
      cancel: "إلغاء",
      note_blank_rows_columns_not_allowed:
        "ملاحظة: لا يُسمح بالصفوف/الأعمدة الفارغة",
      no_of_vehicles: "عدد المركبات",
      run_plan: "تنفيذ الخطة",
      vehicle_types: "أنواع المركبات",
      consolidated_route_plan_screen: "شاشة خطة الطريق الموحدة",
      start_date: "تاريخ البدء",
      end_date: "تاريخ الانتهاء",
      get_data: "احصل على البيانات",
      recent_plans: "الخطط الأخيرة",
      plan_history: "تاريخ الخطة",
      view_input: "عرض الإدخال",
      consolidation_id: "رقم الدمج",
      created_date: "تاريخ الإنشاء",
      run_by: "تم التنفيذ بواسطة",
      no_of_executions: "عدد عمليات التنفيذ",
      execution_status: "حالة التنفيذ",
      download_output: "تحميل النتائج",
      choose_file: "اختر الملف",
      discard_obd: "تجاهل OBD",
      upload_obd_details: "تحميل تفاصيل OBD",
      submit: "إرسال",
      download_template: "تحميل القالب",
      bulk_upload_locations: "تحميل المواقع بالجملة",
      add_pickup_drop_points: "+إضافة نقاط الاستلام/التسليم",
      details: "التفاصيل",
      date: "التاريخ",
      total_trips: "إجمالي الرحلات",
      trips_edited: "الرحلات المعدلة",
      trips_removed: "الرحلات المحذوفة",
      trips_edit_percentage: "نسبة تعديل الرحلات",
      plans_overview_dashboard: "لوحة عرض الخطط",
      // pickup_point: "نقطة الاستلام",
      location_type: "نوع الموقع",
      location_code: "رمز الموقع",
      location_name: "اسم الموقع",
      location_city: "مدينة الموقع",
      zone: "المنطقة",
      customer_type: "نوع العميل",
      location_latitude: "خط عرض الموقع",
      location_longitude: "خط طول الموقع",
      standard_transit_time: "وقت العبور القياسي (أيام)",
      distance_from_plant: "المسافة من المصنع (أميال)",
      delta_warehouse: "مستودع دلتا",
      home: "الصفحة الرئيسية",
      run_new_plan: "تنفيذ خطة جديدة",
      stacking: "التكديس",
      production_planning: "تخطيط الإنتاج",
      forecasting: "التنبؤ",
      resource_scheduling: "جدولة الموارد",
      general: "عام",
      pickup_drop_locations: "مواقع الاستلام/التسليم",
      vehicle_configurations: "تهيئة المركبات",
      vehicle_availability: "توفر المركبات",
      security_approved: "تمت الموافقة على الأمان",
      security_date_of_assessment: "تاريخ تقييم الأمان",
      view: "عرض",
      location_code: "رمز الموقع",
      vehicle_no: "رقم المركبة",
      stage: "المرحلة",
      quality_rejected: "تم رفض الجودة",
      requirements_set: "تم تعيين المتطلبات",
      security_rejected: "تم رفض الأمان",
      security_accepted: "تم قبول الأمان",
      assessment_date: "تاريخ التقييم",
      requirement: "المتطلبات",
      satisfied: "راض",
      yes: "نعم",
      no: "لا",
      requirement_set_date: "تاريخ تعيين المتطلبات",
      temperature: "درجة الحرارة",
      vehicle_operational_from: "تشغيل المركبة من",
      vehicle_operational_to: "تشغيل المركبة حتى",
      break_time_from: "وقت الاستراحة من",
      break_time_to: "وقت الاستراحة إلى",
      transporter_name: "اسم الناقل",
      last_packet_time: "آخر وقت للحزمة",
      temperature: "درجة الحرارة",
      view_vehicles: "عرض المركبات",
      transporter_name: "اسم الناقل",
      max_distance_per_plan_miles: "أقصى مسافة لكل خطة (أميال)",
      remaining_monthly_distance_miles: "المسافة الشهرية المتبقية (أميال)",
      vehicle_next_available_date: "تاريخ توفر المركبة التالي",
      owned: "مملوك",
      contract: "عقد",
      high: "عالي",
      medium: "متوسط",
      low: "منخفض",
      market: "السوق",
      vehicles_for_today: "المركبات اليوم",
      bulk_upload_master_vehicles: "تحميل المركبات الرئيسية بشكل جماعي",
      add_master_vehicle: "إضافة مركبة رئيسية",
      transporter_name: "اسم الناقل",
      transit_type: "نوع النقل",
      actions: "الإجراءات",
      facility_name: "اسم المنشأة",
      vehicle_type: "نوع المركبة",
      vehicle_number: "رقم المركبة",
      vehicle_priority: "أولوية المركبة",
      max_distance_per_plan_miles: "أقصى مسافة لكل خطة (أميال)",
      max_weight_pounds: "أقصى وزن (رطل)",
      max_volume_cft: "أقصى حجم (قدم مكعب)",
      monthly_min_planning_distance_miles: "أدنى مسافة تخطيط شهرية (أميال)",
      remaining_monthly_planning_distance_miles:
        "المسافة الشهرية المتبقية للتخطيط (أميال)",
      next_available_date_yyyy_mm_dd_hh_mm:
        "تاريخ التوفر التالي (YYYY-MM-DD HH:MM)",
      vehicle_break_down: "تعطل المركبة",
      drop_location_code: "رمز موقع التسليم",
      arrival_at_first_dealer: "الوصول إلى أول موزع",
      departure_at_last_dealer: "المغادرة من آخر موزع",
      average_payload: "الحمل المتوسط (أطنان)",
      mileage: "عدد الأميال (أميال/جالون)",
      fuel_type: "نوع الوقود",
      temperature_controlled: "درجة الحرارة مضبوطة",
      load_characteristics: "خصائص الحمولة",
      glec_vehicle_type: "نوع مركبة GLEC",
      return_to_plant: "العودة إلى المصنع",
      summary_dashboard: "لوحة الملخص",
      dispatch_plan_details: "تفاصيل خطة الشحن",
      invalid_orders_note: "ملاحظة: الطلبات غير الصالحة غير معروضة",
      // pickup_point: "نقطة الالتقاط",
      vehicle_type_configuration: "تهيئة نوع المركبة",
      vehicle_type: "نوع المركبة",
      vehicle_type_name: "اسم نوع المركبة",
      min_volume: "الحد الأدنى للحجم (قدم مكعب)",
      max_volume: "الحد الأقصى للحجم (قدم مكعب)",
      vehicle_priority: "أولوية المركبة",
      min_weight: "الحد الأدنى للوزن (رطل)",
      max_weight: "الحد الأقصى للوزن (رطل)",
      max_distance_trip: "أقصى مسافة لكل رحلة (أميال)",
      monthly_min_planning_distance: "أدنى مسافة تخطيط شهرية",
      cancel: "إلغاء",
      save: "حفظ",
      facility_name: "اسم المنشأة",
      vehicle_no: "رقم المركبة",
      address: "العنوان",
      last_packet_time: "آخر وقت للحزمة",
      latitude: "خط العرض",
      longitude: "خط الطول",
      service_provider: "مقدم الخدمة",
      temperature: "درجة الحرارة",
      on_trip: "في الرحلة",
      requirement: "المتطلبات",
      requirement_type: "نوع المتطلبات",
      security: "الأمان",
      assessed_by: "تم التقييم بواسطة",
      security_conclusion: "استنتاج الأمان",
      security_comments: "تعليقات الأمان",
      assessment_start_date: "تاريخ بدء التقييم",
      assessment_end_date: "تاريخ انتهاء التقييم",
      get_data: "الحصول على البيانات",
      add_requirements: "إضافة المتطلبات",
      add_vehicle_checklist: "إضافة قائمة فحص المركبة",
      actions: "الإجراءات",
      edit: "تحرير",
      delete: "حذف",
      vehicle_configurations: "تكوينات المركبات",
      vehicle_availability_configurations: "تكوينات توافر المركبات",
      vehicle_location: "موقع المركبة",
      vehicle_master: "بيانات المركبة الأساسية",
      vehicle_availability: "توافر المركبة",
      vehicle_details: "تفاصيل المركبة",
      monthly_min_distance: "المسافة الشهرية الدنيا (ميل)",
      max_weight_per_trip: "الوزن الأقصى لكل رحلة (بالرطل)",
      max_distance_per_trip: "المسافة القصوى لكل رحلة (ميل)",
      planned_data: "البيانات المخططة",
      vfr_percentage: "نسبة VFR",
      cool_chain_adherence: "الالتزام بسلسلة التبريد",
      transit_time: "وقت النقل",
      loading_time: "وقت التحميل",
      unloading_time: "وقت التفريغ",
      planned_summary: "الملخص المخطط",
      invalid_drop_data: "بيانات التسليم غير الصالحة",
      vehicle_utilization_km: "استخدام المركبات (كم)",
      distance_to_destination: "المسافة إلى الوجهة",
      expected_delivery_date: "تاريخ التسليم المتوقع",
      trip_no: "رقم الرحلة",
      total_quantity: "إجمالي الكمية",
      demand_volume_cft: "حجم الطلب (CFT)",
      weight_pounds: "الوزن (بالرطل)",
      quantity: "الكمية",
      dropped_reason: "سبب الإلغاء",
      delivery_date: "تاريخ التسليم",
      drop_location_city: "مدينة موقع التسليم",
      planned_day: "اليوم المخطط",
      vehicle_no: "رقم المركبة",
      drop_sequence: "ترتيب التسليم",
      invoicing_date: "تاريخ الفاتورة",
      pickup_location_source: "موقع الاستلام (المصدر)",
      destination_location_city: "مدينة الوجهة",
      destination_location_state: "ولاية الوجهة",
      distance_to_destination_miles: "المسافة إلى الوجهة (ميل)",
      time_to_destination: "الوقت إلى الوجهة",
      dealer_available_from: "الوكيل متاح من",
      dealer_available_to: "الوكيل متاح إلى",
      total_planned_weight: "إجمالي الوزن المخطط",
      total_planned_volume: "إجمالي الحجم المخطط",
      arrival_at_first_dealer_start: "الوصول إلى الوكيل الأول (البداية)",
      departure_at_last_dealer_end: "المغادرة من الوكيل الأخير (النهاية)",
      return_to_plant_hh_mm: "العودة إلى المصنع (ساعة:دقيقة)",
      total_planned_trip_time_hrs: "إجمالي وقت الرحلة المخطط (ساعات)",
      total_round_trip_time_hrs: "إجمالي وقت الرحلة ذهابًا وإيابًا (ساعات)",
      total_loading_time_hh_mm: "إجمالي وقت التحميل (ساعة:دقيقة)",
      total_unloading_time_hh_mm: "إجمالي وقت التفريغ (ساعة:دقيقة)",
      vehicle_utilization_percentage: "نسبة استخدام المركبة",
      view_order_information: "عرض معلومات الطلب",
      vehicle_configuration: "تكوين المركبة",
      summary: "الملخص",
      planned_summary: "الملخص المخطط",
      planned_vehicles: "المركبات المخطط لها",
      unplanned_loads: "الأحمال غير المخطط لها",
      invalid_drop_data: "بيانات التسليم غير الصالحة",
      set_vehicle_priority: "تحديد أولوية المركبة",
      forecast_order_plan: "خطة الطلب المتوقعة",
      volume_cft: "الحجم (CFT)",
      dealer_available_time_error: "يجب أن يكون وقت البدء أقل من وقت الانتهاء",
      ok: "موافق",
      get_data: "الحصول على البيانات",
      plan_history: "تاريخ الخطة",
      upload_file: "تحميل الملف",
      save: "حفظ",
      cancel: "إلغاء",
      delete: "حذف",
      plant_name: "اسم المصنع",
      total_trips: "إجمالي الرحلات",
      trips_edited: "الرحلات المعدلة",
      vfr_percentage: "نسبة VFR",
      total_transit_time_hh_mm: "إجمالي وقت النقل (ساعة)",
      drop_location_name_destination: "اسم موقع التسليم (الوجهة)",
      unloading_time_hh_mm: "وقت التفريغ (ساعة:دقيقة)",
      edited_transaction_ids: "معرّفات المعاملات المعدلة",
      vehicle_type: "نوع السيارة",
      vehicle_name: "اسم السيارة",
      weight_pounds: "الوزن (بالرطل)",
      plant_code: "رمز المصنع",
      plant_name: "اسم المصنع",
      total_trips: "إجمالي الرحلات",
      trips_edited: "الرحلات المعدلة",
      last_30_days_trips_modification_details:
        "تفاصيل تعديل الرحلات لآخر 30 يومًا",
      last_7_days_trips_modification_details:
        "تفاصيل تعديل الرحلات لآخر 7 أيام",
      planning_progress_past_7_weeks: "تقدم التخطيط في الأسابيع السبع الماضية",
      edited_transaction_ids: "معرّفات المعاملات المعدلة",
      trips_removed: "الرحلات المحذوفة",
      removed_transaction_ids: "معرّفات المعاملات المحذوفة",
      drop_location_city: "مدينة موقع التسليم",
      quantity: "الكمية",
      dropped_reason: "سبب الإسقاط",
      delivery_date: "تاريخ التسليم",
      drop_location_name: "اسم موقع التسليم",
      drop_location_city: "مدينة موقع التسليم",
      trip_no: "رقم الرحلة",
      planned_day: "اليوم المخطط",
      vehicle_no: "رقم المركبة",
      vehicle_type: "نوع المركبة",
      drop_sequence: "ترتيب التسليم",
      invoicing_date: "تاريخ الفاتورة",
      pickup_location_source: "موقع الاستلام (المصدر)",
      destination_location_city: "مدينة الوجهة",
      destination_location_state: "ولاية الوجهة",
      distance_to_destination_miles: "المسافة إلى الوجهة (أميال)",
      expected_delivery_date: "تاريخ التسليم المتوقع",
      time_to_destination: "الوقت إلى الوجهة",
      invalid_material: "مواد غير صالحة",
      drop_location_code_destination: "رمز موقع التسليم (الوجهة)",
      demand_volume_cft: "حجم الطلب (CFT)",
      dropped_reason: "سبب الإسقاط",
      dealer_location_data_not_in_system:
        "بيانات موقع الوكيل غير موجودة في النظام",
      delivery_date_less_than_current: "تاريخ التسليم أقل من التاريخ الحالي",
      missing_dealer_information: "معلومات الوكيل مفقودة",
      transit_time_hh_mm: "وقت العبور (HH:MM)",
      loading_time_hh_mm: "وقت التحميل (HH:MM)",
      unloading_time_hh_mm: "وقت التفريغ (HH:MM)",
      actions: "الإجراءات",
      expected_delivery_date: "تاريخ التسليم المتوقع",
      max_dispatch_time: "الحد الأقصى لوقت الإرسال (HH:MM)",
      unplanned_loads: "التحميلات غير المخططة",
      monthly_data: "البيانات الشهرية",
      forecast_plan_history: "تاريخ خطة التوقعات",
      obd_analysis: "تحليل OBD",
      dealer_available_time_error: "يجب أن يكون وقت البدء أقل من وقت الانتهاء",
      drop_location_name: "اسم موقع التسليم",
      drop_location_city: "مدينة موقع التسليم",
      order_number: "رقم الطلب",
      delivery_date: "تاريخ التسليم (M/D/YYYY)",
      back: "رجوع",
      arrival_at_first_dealer_start: "الوصول إلى أول وكيل (بداية)",
      departure_at_last_dealer_end: "المغادرة من آخر وكيل (نهاية)",
      return_to_plant_hh_mm: "العودة إلى المصنع (HH:MM)",
      total_planned_trip_time_hrs: "إجمالي وقت الرحلة المخطط (ساعات)",
      total_round_trip_time_hrs: "إجمالي وقت الرحلة ذهابًا وإيابًا (ساعات)",
      dealer_available_to: "الوكيل متاح إلى",
      upload_input_file: "تحميل ملف الإدخال",
      upload_file: "تحميل الملف",
      bulk_upload_master_vehicles: "تحميل المركبات الرئيسية بشكل جماعي",
      add_master_vehicle: "إضافة مركبة رئيسية",
      dealer_available_time_error: "يجب أن يكون وقت البدء أقل من وقت الانتهاء",
      plant_wise_configurations: "تهيئة حسب المصنع",
      monthly_min_planning_distance: "أدنى مسافة تخطيط شهرية",
      // pickup_point: "نقطة الالتقاط",
      fix_and_reupload: "الإصلاح وإعادة التحميل",
      club_previous_order: "دمج الطلب السابق",
      ignore: "تجاهل",
      dealer_code: "رمز الوكيل",
      remarks: "ملاحظات",
      run_new_plan: "تنفيذ خطة جديدة",
      consolidated_route_plan_screen: "شاشة خطة الطريق الموحدة",
      actions: "الإجراءات",
      "driver_break_time_to": "وقت استراحة السائق إلى",
"max_seating_capacity": "الحد الأقصى لسعة الجلوس",
"min_seating_capacity": "الحد الأدنى لسعة الجلوس",
"vehicle_seating_capacity": "سعة جلوس المركبة",
"vehicle_capacity_cmt": "سعة المركبة بالمتر المكعب",
 "route_type": "نوع الطريق"
  },
  },
  te: {
    translation: {
       "route_type": "రూట్ రకం",
      vehicles_recommended: "సిఫార్సు చేయబడిన వాహనాలు",
      "Number Of Trips": "ప్రయాణాల సంఖ్య",
  "Total": "మొత్తం",
  "Quantity": "పరిమాణం",
  "Day-2": "రోజు-2",
  "Day-3": "రోజు-3",
  "Day-4": "రోజు-4",
  "Day-5": "రోజు-5",
  "Day-6": "రోజు-6",
  "Distance / Time From Pickup Location": "పికప్ స్థానం నుండి దూరం / సమయం",
  "Distance / Time From Previous Drop Point": "మునుపటి డ్రాప్ పాయింట్ నుండి దూరం / సమయం",
  "Location City": "స్థానం నగరం",
  "Planned Day": "ప్రణాళిక చేయబడిన రోజు",
  "Quantity": "పరిమాణం",
  "Demand Weight": "డిమాండ్ బరువు",
  "Demand Volume": "డిమాండ్ వాల్యూమ్",
  "Location": "స్థానం",
  "Weight": "బరువు",
  "View Onward Route": "ముందు మార్గాన్ని వీక్షించండి",
  "View Return Route": "తిరిగి మార్గాన్ని వీక్షించండి",
  "Weight": "బరువు",
  "Spot": "స్పాట్",
  "Local": "లోకల్",
  "Upcountry": "ఉపకంట్రీ",
  "Volume": "పరిమాణం",
  "IN416": "IN416",
  "IN436": "IN436",
  "Spot": "స్పాట్",
  "2,177": "2,177",
  "1,268": "1,268",
  "909": "909",
  "0": "0",
  "Planned Truck Load": "ప్రణాళిక ట్రక్ లోడ్",
  "Quantity (cases)": "పరిమాణం (కేసులు)",
  "Weight (Kgs)": "బరువు (కిలోలు)",
  "Volume (CMT)": "వాల్యూమ్ (CMT)",
  "1,81,970": "1,81,970",
  "25,25,554": "25,25,554",
  "8,249": "8,249",
  "Unplanned Load": "పణ్యాలు ఏదైనా ప్రణాళిక లేకుండా",
  "26,324": "26,324",
  "3,74,549": "3,74,549",
  "1,234": "1,234",
  "Average Distance Travelled Per Vehicle (KM)": "వాహనం చొప్పున ప్రయాణించిన సగటు దూరం (KM)",
  "35,426": "35,426",
  "37,624": "37,624",
  "28,111": "28,111",
  "Operational Cost (Rs.)": "పనితీరు ఖర్చు (Rs.)",
  "1,62,57,137": "1,62,57,137",
  "1,31,64,341": "1,31,64,341",
  "30,92,797": "30,92,797",
  "Average Vehicle Fill Rate": "సగటు వాహన నింపకం రేటు",
  "60 %": "60 %",
  "42 %": "42 %",
  "79 %": "79 %",
  "Unplanned Load Proportion": "ప్రణాళికలేని లోడ్ సంతులనం",
  "12 %": "12 %",
  "Average Vehicle Utilization As Per SOB": "SOB ప్రకారం సగటు వాహన వినియోగం",
  "104 %": "104 %",
  "94 %": "94 %",
  "Ontime Dispatches": "పన్ను పంపిణీలు",
  "Local": "స్థానిక",
  "frefe": "ఫ్రెఫె",
  "94 %": "94 %",
  "92 %": "92 %",
  "Ontime Deliveries": "సమయంతో డెలివరీలు",
  "97 %": "97 %",
  "94 %": "94 %",
      "Please Select Scenario to Compare Plans": "ప్రణాళికలను పోల్చడానికి దయచేసి పరిస్థితిని ఎంచుకోండి",
"Percentage": "శాతం",
"Forecast Scenario": "అనుమాన పరిస్థితే",
      "Number of kms Travelled": "ప్రయాణించిన కిలోమీటర్ల సంఖ్య",
"Cost": "ఖర్చు",
"Vehicle Fill Rate": "వాహనం నింపిన రేటు",
"Vehicle KM Run %": "వాహనం కిలోమీటర్ పరుగులు %",
      "driver_break_time_to": "డ్రైవర్ విరామ సమయం వరకు",
      "Please Select File": "దయచేసి ఫైల్ ఎంచుకోండి",
"Processing Plan. Please wait.....": "ప్రాసెసింగ్ ప్లాన్. దయచేసి వేచి ఉండండి.....",
"File is processing": "ఫైల్ ప్రాసెస్ అవుతోంది",
"Enter Input Data To Continue": "కొనసాగడానికి ఇన్పుట్ డేటా నమోదు చేయండి",
"No Data": "డేటా లేదు",
"please_select_date": "దయచేసి తేదీని ఎంచుకోండి",
"data_uploaded": "డేటా అప్‌లోడ్ అయింది",
"Please Select No of Vehicles": "దయచేసి వాహనాల సంఖ్యను ఎంచుకోండి",
"Please Input Valid Data": "దయచేసి సరైన డేటా నమోదు చేయండి",
"max_seating_capacity": "గరిష్ట సీటింగ్ సామర్థ్యం",
"min_seating_capacity": "కనిష్ట సీటింగ్ సామర్థ్యం",
"vehicle_seating_capacity": "వాహనం సీటింగ్ సామర్థ్యం",
"vehicle_capacity_cmt": "వాహనం సామర్థ్యం (సి.ఎం.టి)",
      "loading....": "లోడ్ అవుతోంది....",
      "change_password": "పాస్‌వర్డ్ మార్చండి",
      "new_password": "కొత్త పాస్‌వర్డ్",
      "confirm_new_password": "కొత్త పాస్‌వర్డ్‌ని నిర్ధారించండి",
      "old_password": "పాత పాస్‌వర్డ్",
      "enter_email": "మీ ఇమెయిల్ నమోదు చేయండి",
      "enter_new_password": "మీ కొత్త పాస్‌వర్డ్ నమోదు చేయండి",
      "confirm_new_password_entry": "మీ కొత్త పాస్‌వర్డ్‌ని నిర్ధారించండి",
  "select_cluster": "క్లస్టర్ ఎంపిక చేయండి",
  "Overall Cluster Wise Vehicles": "మొత్తం క్లస్టర్ వారీగా వాహనాలు",
  "Discard": "తిప్పివేయి",
  "obd": "OBD",
  "Dispatch Planning is in progress..!!!": "డిస్పాచ్ ప్లానింగ్ కొనసాగుతోంది..!!!",
  "Estimated Time": "అంచనా సమయం",
  "GPS": "GPS",
  "Spot Vehicles To Be Planned": "ప్లాన్ చేయవలసిన స్పాట్ వాహనాలు",
  "No Of Spot Vehicles": "స్పాట్ వాహనాల సంఖ్య",
  "Availability DateTime": "అందుబాటులో ఉన్న తేదీ & సమయం",
  "Notify Email Users After Process (comma seperated)": "ప్రాసెస్ తరువాత ఇమెయిల్ యూజర్లకు నోటిఫికేషన్ ఇవ్వండి (కామా వేరుగా)",
  "Max Time Limit(Hrs)": "గరిష్ట సమయ పరిమితి (గంటలు)",
  "vfr": "VFR",
  "Disclaimer": "విమర్శనాత్మక సమాచారం: OT కస్టమర్లకు డీలర్ అందుబాటులో విండో ఇన్‌పుట్ ఫైల్ నుండి పరిగణించబడుతుంది, GT కస్టమర్ సమయాలు పికప్/డ్రాప్ లొకేషన్ల నుండి పరిగణించబడతాయి.",
  "To Check for Blank Data": "ఖాళీ డేటాను తనిఖీ చేయడానికి: 1. 'ఫైండ్ & సెలెక్ట్' పై క్లిక్ చేయండి. 2. 'స్పెషల్ కు వెళ్ళండి' పై క్లిక్ చేయండి. 3. 'బ్లాంక్స్' ఎంచుకోండి. 4. 'ఓకే' పై క్లిక్ చేసి అన్ని ఖాళీ ర్యాక్స్/సెల్స్ హైలైట్ చేయబడతాయి. 5. హోమ్ ట్యాబ్ లో సెల్స్ విభాగంలో 'డిలీట్' ఎంచుకోండి. 6. 'షీట్ ర్యాక్స్ డిలీట్' పై క్లిక్ చేయండి.",
  "Add New OBD": "కొత్త OBD జోడించండి",
  "All drop locations are invalid, Please check..!!": "అన్ని డ్రాప్ లొకేషన్లు చెల్లవు, దయచేసి తనిఖీ చేయండి..!!",
  "Please upload file having extensions .csv or .xlsx only.": "దయచేసి .csv లేదా .xlsx పొడిగింపులతో మాత్రమే ఫైల్ అప్‌లోడ్ చేయండి.",
  "Dispatch Load Volume Summary (CFT)": "డిస్పాచ్ లోడ్ వాల్యూం సారాంశం (CFT)",
  "Total Loads Volume (CFT)": "మొత్తం లోడ్ల వాల్యూం (CFT)",
  "Planned Loads Volume (CFT)": "ప్లాన్ చేయబడిన లోడ్ల వాల్యూం (CFT)",
  "Unplanned Loads Volume (CFT)": "అప్లాన్డ్ లోడ్ల వాల్యూం (CFT)",
  "Dropped Loads Volume (CFT)": "డ్రాప్ చేయబడిన లోడ్ల వాల్యూం (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "డిస్పాచ్ లోడ్ వెయిట్ సారాంశం (పౌండ్స్)",
  "Total Loads Weight (Pounds)": "మొత్తం లోడ్ల వెయిట్ (పౌండ్స్)",
  "Planned Loads Weight (Pounds)": "ప్లాన్ చేయబడిన లోడ్ల వెయిట్ (పౌండ్స్)",
  "Unplanned Loads Weight (Pounds)": "అప్లాన్డ్ లోడ్ల వెయిట్ (పౌండ్స్)",
  "Dropped Loads Weight (Pounds)": "డ్రాప్ చేయబడిన లోడ్ల వెయిట్ (పౌండ్స్)",
  "Dispatch Load Quantity Summary": "డిస్పాచ్ లోడ్ పరిమాణ సారాంశం",
  "Total Quantity": "మొత్తం పరిమాణం",
  "Planned Quantity": "ప్లాన్ చేయబడిన పరిమాణం",
  "Unplanned Quantity": "అప్లాన్డ్ పరిమాణం",
  "Dropped Quantity": "డ్రాప్ చేయబడిన పరిమాణం",
  "Dispatch Load Volume Summary (CFT)": "డిస్పాచ్ లోడ్ వాల్యూం సారాంశం (CFT)",
  "Total Loads Volume (CFT)": "మొత్తం లోడ్ల వాల్యూం (CFT)",
  "Planned Loads Volume (CFT)": "ప్లాన్ చేయబడిన లోడ్ల వాల్యూం (CFT)",
  "Unplanned Loads Volume (CFT)": "అప్లాన్డ్ లోడ్ల వాల్యూం (CFT)",
  "Dropped Loads Volume (CFT)": "డ్రాప్ చేయబడిన లోడ్ల వాల్యూం (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "డిస్పాచ్ లోడ్ వెయిట్ సారాంశం (పౌండ్స్)",
  "Total Loads Weight (Pounds)": "మొత్తం లోడ్ల వెయిట్ (పౌండ్స్)",
  "Planned Loads Weight (Pounds)": "ప్లాన్ చేయబడిన లోడ్ల వెయిట్ (పౌండ్స్)",
  "Unplanned Loads Weight (Pounds)": "అప్లాన్డ్ లోడ్ల వెయిట్ (పౌండ్స్)",
  "Dropped Loads Weight (Pounds)": "డ్రాప్ చేయబడిన లోడ్ల వెయిట్ (పౌండ్స్)",
  "Dispatch Load Quantity Summary": "డిస్పాచ్ లోడ్ పరిమాణ సారాంశం",
  "Total Quantity": "మొత్తం పరిమాణం",
  "Planned Quantity": "ప్లాన్ చేయబడిన పరిమాణం",
  "Unplanned Quantity": "అప్లాన్డ్ పరిమాణం",
  "Dropped Quantity": "డ్రాప్ చేయబడిన పరిమాణం",
  "Dispatch Load Weight Summary (Pounds)": "డిస్పాచ్ లోడ్ వెయిట్ సారాంశం (పౌండ్స్)",
  "Dispatch Load Volume Summary (CFT)": "డిస్పాచ్ లోడ్ వాల్యూం సారాంశం (CFT)",
  "Dropped Data": "డ్రాప్ చేయబడిన డేటా",
  "ontime_delivery": "సమయానికి డెలివరీ",
  "dealer_Available_start_time_should_be_less_than_end_time": "డీలర్ అందుబాటులో ఉన్న ప్రారంభ సమయం ముగింపు సమయానికి తక్కువగా ఉండాలి",
  "Removed": "తొలగించబడింది",
  "Unedited": "సంపాదించని",
  "Edited": "సంపాదించిన",
      vehicle_availability_configurations: "వాహన లభ్యత కాంక్ష",
      max_distance_per_plan_miles: "ప్రతి ప్లాన్‌కు గరిష్ఠ దూరం (మైళ్ళు)",
    add_city_wise_cost: "నగరవారీ ఖర్చు జోడించండి",
    add_zone_wise_cost: "జోన్ వారీ ఖర్చు జోడించండి",
    scenarios: "సన్నివేశాలు",
      plant: "ప్లాంట్",
      standard_transit_time_days: "ప్రామాణిక రవాణా సమయం (రోజులు)",
    drop_to_time: "డ్రాప్ సమయం",
    drop_from_time: "డ్రాప్ ప్రారంభ సమయం",
    pickup_to_time: "పికప్ సమయం",
    pickup_from_time: "పికప్ ప్రారంభ సమయం",
    distance_from_plant_miles: "ప్లాంట్ నుండి దూరం (మైళ్ళు)",
    vehicle_next_available_date: "వాహనం తదుపరి అందుబాటులో ఉన్న తేదీ",
    max_distance_per_plan_miles: "ప్రతి ప్లాన్‌కు గరిష్ఠ దూరం (మైళ్ళు)",
    remaining_monthly_distance_miles: "మిగిలిన నెలవారీ దూరం (మైళ్ళు)",
    assign_quality_checks: "నాణ్యత తనిఖీలు కేటాయించండి",
    add_fixed_vehicle_cost: "స్థిర వాహన ఖర్చును జోడించండి",
      customer: "వినియోగదారుడు",
      percent_unplanned: "నిర్వహించని శాతం",
       "Pickup Locations (Drop)*": "పికప్ లొకేషన్స్ (డ్రాప్)*",
      "pickup_locations_drop*": "పికప్ లొకేషన్స్ (డ్రాప్)*",
      duplicate_obds_light_red: "నకిలీ OBDలు లైట్ రెడ్ కలర్‌లో చూపబడతాయి",
    new_obds_green: "కొత్తగా చేర్చిన OBDలు గ్రీన్‌లో చూపబడతాయి",
    pickup_locations_drop: "పికప్ లొకేషన్స్ (డ్రాప్)",
    start_month: "ప్రారంభ నెల",
    invalid_orders_not_shown: "చెల్లని ఆర్డర్లు చూపబడడం లేదు",
    note: "గమనిక",
    end_month: "ముగింపు నెల",
    get_data: "డేటాను పొందండి",
    please_select_scenario: "ప్లాన్‌లను పోల్చడానికి దయచేసి సన్నివేశాన్ని ఎంచుకోండి",
    avg_kms_travelled: "సగటు కిలోమీటర్లు ప్రయాణించారు",
    total_cost: "మొత్తం ఖర్చు",
    total_vehicle_fill_rate: "మొత్తం వాహన నింపిన రేటు",
    percent_km_run_sob: "SOB కోసం కిలోమీటర్లు నడిపిన శాతం",
    percent_ontime_dispatch: "సమయానికి డిస్పాచ్ శాతం",
    upcountry: "పల్లెటూరు",
    percent_spot_vehicles_trips: "స్పాట్ వాహన ప్రయాణాల శాతం",
    round_trip_distance: "రౌండ్ ట్రిప్ దూరం",
    total_trip_distance: "మొత్తం ప్రయాణ దూరం",
    vehicle_type: "వాహన రకం",
    plant_code: "ప్లాంట్ కోడ్",
    vehicle_no: "వాహన సంఖ్య",
    monthly_sob_km: "నెలవారీ SOB (Km)",
    distance_travelled_km: "ప్రయాణించిన దూరం (Km)",
    pickup_location_source: "పికప్ లొకేషన్ (మూలం)",
    drop_location_code_destination: "డ్రాప్ లొకేషన్ కోడ్ (గమ్యం)",
    drop_location_name_destination: "డ్రాప్ లొకేషన్ పేరు (గమ్యం)",
    order_number: "ఆర్డర్ సంఖ్య",
    invoicing_date: "బిలింగ్ తేదీ",
    quantity: "మొత్తం",
    weight_kgs: "బరువు (Kg)",
    demand_volume_m3: "డిమాండ్ వాల్యూమ్ (M3)",
    dealer_available_from: "డీలర్ నుండి అందుబాటులో",
    dealer_available_to: "డీలర్ అందుబాటులో ఉంటుంది",
    delivery_date: "వితరణ తేదీ",
    trip_no: "యాత్ర సంఖ్య",
    planned_day: "ప్లాన్ చేయబడిన రోజు",
    drop_sequence: "డ్రాప్ క్రమం",
    destination_location_city: "గమ్యం స్థాన నగరం",
    destination_location_state: "గమ్యం స్థాన రాష్ట్రం",
    volume_m3: "వాల్యూమ్ (M3)",
    distance_to_destination_kms: "గమ్యం వరకు దూరం (Km)",
    expected_delivery_date: "అంచనా వితరణ తేదీ",
    time_to_destination: "గమ్యం వరకు సమయం",
    transit_time_hhmm: "ట్రాన్సిట్ సమయం (HH:MM)",
    loading_time_hhmm: "లోడింగ్ సమయం (HH:MM)",
    unloading_time_hhmm: "అన్‌లోడింగ్ సమయం (HH:MM)",
    select_chart_type: "చార్ట్ రకాన్ని ఎంచుకోండి",
    fleet_analysis: "వాహనాల విశ్లేషణ",
    system_recommended: "సిస్టమ్ సిఫార్సు",
    total_load: "మొత్తం లోడ్",
    serviceability: "సేవ సామర్ధ్యం",
    in416: "IN416",
    in436: "IN436",
    user_recommended: "వినియోగదారుని సిఫార్సు",
    threshold: "తీవ్రత",
    number_of_trips: "యాత్రల సంఖ్య",
    total: "మొత్తం",
    spot: "స్పాట్",
    planned_truck_load: "ప్రణాళిక కలిగిన ట్రక్ లోడ్",
    quantity_cases: "మొత్తం (కేసులు)",
    weight_kgs: "బరువు (కిలోలు)",
    volume_cmt: "పరిమాణం (CMT)",
    unplanned_load: "పట్టుబట్టని లోడ్",
    average_distance_travelled_per_vehicle_km: "ప్రతి వాహనానికి సగటు ప్రయాణం (KM)",
    operational_cost_rs: "ఆపరేషనల్ ఖర్చు (Rs)",
    average_vehicle_fill_rate: "సగటు వాహనం నింపే రేటు",
    unplanned_load_proportion: "ప్రణాళిక చేయని లోడ్ కొలత",
    average_vehicle_utilization_as_per_sob: "SOB ప్రకారం సగటు వాహనం వినియోగం",
    ontime_dispatches: "సమయానికి పంపించబడినవి",
    local: "స్థానిక",
    upcountry: "గ్రామీణ",
    ontime_deliveries: "సమయానికి పంపిణీ",
    bar: "బార్",
    load: "లోడ్",
    month: "నెల",
    percentage: "%",






      //words for configurations screen

      "Consider Transporter SOB": "ట్రాన్స్‌పోర్టర్ SOB ని పరిగణించండి",
      forecast_summary: "అనుకూలత సారాంశం",
      compare: "తులన చెయ్యి",
    pickup_locations_drop: "పికప్ లొకేషన్స్ (డ్రాప్)",
      "note_highlighted_rows_red": "గమనిక: ఎరుపులో హైలైట్ చేయబడిన వరుసల్లో GPS డేటా లేదా సమగ్రీకరణ లేదు, వాహన లభ్యతను మానవీయంగా గుర్తించండి.",
  "highlighted_rows_green": "2. పచ్చ రంగులో హైలైట్ చేయబడిన వరుసలు ప్రణాళిక కోసం పరిగణించబడతాయి",
  "enable_reconciliation": "పునఃసమాధానం ప్రారంభించండి",
  "enable_dc_week_off_planning": "DC వారం విరామ ప్రణాళిక ప్రారంభించండి",
      enable_disable: "ప్రవేశపెట్టు / నిలిపివేయి",
      "Consider Dropping Distant Smaller Loads":
        "దూరంలోని చిన్న లోడ్లను వదులుతామని పరిగణించండి",
      "Consider Realtime Vehicle Available Times":
        "రియల్‌టైమ్ వాహన అందుబాటు సమయాలను పరిగణించండి",
      "Plant Wise Vehicle Type Configuration*":
        "చెట్టు వారీగా వాహన రకం కాన్ఫిగరేషన్*",
      "Consignees / Dealers Locations (Drop)*":
        "కన్సిగ్నీలు / డీలర్ లొకేషన్లు (డ్రాప్)*",
      "Pickup Locations (Pickup)*": "పికప్ లొకేషన్లు (పికప్)*",
      "Optimization Type*": "ఆప్టిమైజేషన్ రకం*",
      "Plant Wise Planning History*": "ప్లాంట్ వారీగా ప్లానింగ్ చరిత్ర*",
      "Plant Wise Plan Summary*": "ప్లాంట్ వారీగా ప్రణాళిక సంగ్రహం*",
      "Plant Wise New Plan Creation*": "ప్లాంట్ వారీగా కొత్త ప్రణాళిక సృష్టి*",
      "Suppliers / Vendor Locations (Pickup)*":
        "సరఫరాదారులు / విక్రేత లొకేషన్లు (పికప్)*",
      "Plant Wise Vehicle Type Configuration*":
        "ప్లాంట్ వారీగా వాహన రకం కాన్ఫిగరేషన్*",
      "Consignees / Dealers Locations (Drop)*":
        "కన్సిగ్నీలు / డీలర్ లొకేషన్లు (డ్రాప్)*",
      "Pickup Locations (Pickup)*": "పికప్ లొకేషన్లు (పికప్)*",
      "Plant Wise Vehicle Type Configuration*":
        "ప్లాంట్ వారీగా వాహన రకం కాన్ఫిగరేషన్*",
      "Suppliers / Vendor Locations (Pickup)*":
        "సరఫరాదారులు / విక్రేత లొకేషన్లు (పికప్)*",
      "Plant Wise Planning History*": "ప్లాంట్ వారీగా ప్రణాళిక చరిత్ర*",
      "Plant Wise Plan Summary*": "ప్లాంట్ వారీగా ప్రణాళిక సంగ్రహం*",
      "Plant Wise New Plan Creation*": "ప్లాంట్ వారీగా కొత్త ప్రణాళిక సృష్టి*",
      "Enable/Disable Dispatch Planning Module":
        "డిస్పాచ్ ప్లానింగ్ మాడ్యూల్ ను ప్రారంభించండి / నిలిపివేయి",
      Inbound: "ఇన్‌బౌండ్",
      Outbound: "అవుట్‌బౌండ్",
      "Suppliers / Vendor Locations (Pickup)":
        "సరఫరాదారులు / విక్రేత లొకేషన్లు (పికప్)",
      "Operational Hours": "ఆపరేషనల్ గంటలు",
      "Pickup Locations (Drop)": "పికప్ లొకేషన్లు (డ్రాప్)",
      "Plant Wise Vehicle Type Configuration":
        "ప్లాంట్ వారీగా వాహన రకం కాన్ఫిగరేషన్",
      "Max No. Of Vehicle Types": "గరిష్ట వాహన రకాలు",
      "Vehicle Load Capacity Min / Max Configuration Preferences":
        "వాహన లోడ్ సామర్థ్యం కనీసం / గరిష్టం కాన్ఫిగరేషన్ ప్రాధాన్యతలు",
      "Vehicle Load Weight Min / Max Preferences":
        "వాహన లోడ్ బరువు కనీసం / గరిష్టం ప్రాధాన్యతలు",
      "Distance preferences": "దూరం ప్రాధాన్యతలు",
      "Vehicle Priorities": "వాహన ప్రాధాన్యతలు",
      "Vehicle Operational Times": "వాహన ఆపరేషనల్ సమయాలు",
      "Vehicle Availability": "వాహన అందుబాటులో ఉంది",
      "Plant Wise Freight Configuration": "ప్లాంట్ వారీగా సరుకు కాన్ఫిగరేషన్",
      "Plant Wise Transporter Configuration":
        "ప్లాంట్ వారీగా ట్రాన్స్‌పోర్టర్ కాన్ఫిగరేషన్",
      "Route Preferences": "మార్గం ప్రాధాన్యతలు",
      "Plant Wise New Plan Creation": "ప్లాంట్ వారీగా కొత్త ప్రణాళిక సృష్టి",
      "Plant Wise Plan Summary": "ప్లాంట్ వారీగా ప్రణాళిక సంగ్రహం",
      "Plant Wise Planning History": "ప్లాంట్ వారీగా ప్రణాళిక చరిత్ర",
      "No of Vehicles Types": "వాహన రకాల సంఖ్య",
      "Generic Time Windows": "సాధారణ సమయ విండోస్",
      "Individual Vehicle Time Windows": "విభిన్న వాహన సమయ విండోస్",
      "Availability By Vehicle Type": "వాహన రకం ద్వారా అందుబాటులో ఉంది",
      "Availability By Individual Vehicle": "వ్యక్తిగత వాహన అందుబాటులో ఉంది",
      "Monthly Minimum Planning Distance Preferences":
        "నెలవారీ కనీస ప్రణాళిక దూరం ప్రాధాన్యతలు",
      "GPS Based Availability": "GPS ఆధారంగా అందుబాటులో ఉంది",
      "Truck Location Visibility": "ట్రక్ లొకేషన్ కనిపించకపోవడం",
      "Plant Vicinity Circle Radius": "ప్లాంట్ పరిసర సర్కిల్ వ్యాసం",
      "Fixed Vehicle Costs": "స్థిర వాహన వ్యయాలు",
      "Variable Costs": "వేరియబుల్ వ్యయాలు",
      "Vehicle Type Level Variable Costs": "వాహన రకం స్థాయి వేరియబుల్ ఖర్చులు",
      "City Level Vehicle Type Costs": "నగర స్థాయి వాహన రకం ఖర్చులు",
      "Per CFT Cost": "CFT కు ఒక వ్యయం",
      "Per Mile Cost": "మైల్ కు ఒక ఖర్చు",
      "Zone Level Cost": "జోన్ స్థాయి ఖర్చు",
      "Transporter Share of Business": "ట్రాన్స్‌పోర్టర్ షేర్ ఆఫ్ బిజినెస్",
      "Sustainability Integration": "సస్టైనబిలిటీ సమీకరణం",
      "Optimization Type": "ఆప్టిమైజేషన్ రకం",
      "Cost Optimization": "ధర ఆప్టిమైజేషన్",
      "Weight based optimization": "బరువు ఆధారిత ఆప్టిమైజేషన్",
      "Distance based optimization": "దూరం ఆధారిత ఆప్టిమైజేషన్",
      "Time based optimization": "సమయం ఆధారిత ఆప్టిమైజేషన్",
      "Volume based optimization": "వాల్యూమ్ ఆధారిత ఆప్టిమైజేషన్",
      "Prioritize Local Loads": "స్థానిక లోడ్లకు ప్రాధాన్యత ఇవ్వండి",
      "Radius Miles": "రేడియస్ మైల్స్",
      "Number of Loading Docks": "లోడింగ్ డాక్స్ సంఖ్య",
      "City Based Entry Restriction": "నగరం ఆధారిత ప్రవేశ పరిమితి",
      "Unloading time per case/max unloading time":
        "కేసుకు లారింగ్ సమయం / గరిష్ట లారింగ్ సమయం",
      "Consider Vehicle Break Times (Night Driving)":
        "వాహన విరామ సమయాలను పరిగణించండి (రాత్రి డ్రైవింగ్)",
      "Consider Holiday Restrictions": "అవకాశ పరిమితులను పరిగణించండి",
      "Vehicle Restriction": "వాహన పరిమితి",
      "Plan Spot Vehicles": "స్పాట్ వాహనాలను ప్రణాళిక చేయండి",
      "Max No of Customers Per Vehicle": "వాహనం పై గరిష్ట కస్టమర్ సంఖ్య",
      "Use Dynamic Planning Window": "డైనమిక్ ప్లానింగ్ విండోను ఉపయోగించండి",
      "Number of Days to Use Planning Window":
        "ప్లానింగ్ విండోను ఉపయోగించడానికి రోజులు",
      "Use Volume for Vehicle Fill Rate (VFR)":
        "వాహన ఫిల్ రేట్ (VFR) కోసం వాల్యూమ్ ఉపయోగించండి",
      "Use Weight for Vehicle Fill Rate (VFR)":
        "వాహన ఫిల్ రేట్ (VFR) కోసం బరువు ఉపయోగించండి",
      "Min VFR Per Vehicle Type": "వాహన రకం కనీస VFR",
      "Min VFR Per Spot Vehicle": "స్పాట్ వాహనానికి కనీస VFR",
      "Prioritize Same Dealers First in a Vehicle":
        "ఒక వాహనంలో ముందుగా అదే డీలర్లకు ప్రాధాన్యత ఇవ్వండి",
      "Prioritize Multi City Dealers First in a Vehicle":
        "ఒక వాహనంలో ముందుగా మల్టీ సిటీ డీలర్లకు ప్రాధాన్యత ఇవ్వండి",
      "Dispatch within Days": "రోజుల్లో డిస్పాచ్",
      "Allow Unplanned Loads": "ప్లాన్లో లేని లోడ్లను అనుమతించండి",
      "Minimize Number of Vehicles Used":
        "ఉపయోగించిన వాహనాల సంఖ్యను తగ్గించండి",
      "Dealer Buffer Time": "డీలర్ బఫర్ సమయం",
      "Buffer Time(Mins)": "బఫర్ సమయం (నిమిషాలు)",
      "Month End Planning Start Day": "మాసాంతం ప్రణాళిక ప్రారంభ రోజు",
      "Day of Every Month": "ప్రతి నెల రోజులు",
      "Max Local No of Customers Per Vehicle":
        "ఒక వాహనంలో గరిష్ట స్థానిక కస్టమర్ల సంఖ్య",
      "Max Upcountry No of Customers Per Vehicle":
        "ఒక వాహనంలో గరిష్ట ఉపగ్రామ కస్టమర్ల సంఖ్య",
      "Planning Type": "ప్లానింగ్ రకం",
      "Local Prioritization": "స్థానిక ప్రాధాన్యత",
      "Local Prioritization and Virtual Upcountry clubbing":
        "స్థానిక ప్రాధాన్యత మరియు వర్చువల్ ఉపగ్రామ కలయిక",
      "General Plan (Local and Upcountry clubbing)":
        "సాధారణ ప్రణాళిక (స్థానిక మరియు ఉపగ్రామ కలయిక)",
      "Telescopic Deviation": "టెలిస్కోపిక్ విరామం",
      "MOQ for Planning": "ప్లానింగ్ కోసం కనీస ఆర్డర్ పరిమాణం",
      Reconciliation: "పునఃపరిశీలన",
      "DC Week Off planning": "DC వారాంతం ప్రణాళిక",
      "Save Inbound Configurations": "ఇన్బౌండ్ కాన్ఫిగరేషన్లను సేవ్ చేయండి",
      "Save Outbound Configurations": "అవుట్బౌండ్ కాన్ఫిగరేషన్లను సేవ్ చేయండి",
      SAVE: "సేవ్",
      "Enable Reconciliation": "పునఃపరిశీలన ప్రారంభించండి",
      "Enable DC Week Off planning": "DC వారాంతం ప్రణాళిక ప్రారంభించండి",
      "Deviation Alert": "విరామం హెచ్చరిక",
      "Overload Warning": "ఓవర్‌లోడ్ హెచ్చరిక",
      "Underload Alert": "అండర్‌లోడ్ హెచ్చరిక",
      "Fuel Efficiency Report": "ఇంధన సామర్థ్య నివేదిక",
      "Vehicle Downtime": "వాహన డౌన్‌టైమ్",
      "Speed Violation Alert": "వేగం ఉల్లంఘన హెచ్చరిక",
      "Trip Delay Notification": "ప్రయాణం ఆలస్యం నోటిఫికేషన్",
      "Idle Time Monitoring": "అడిల్ సమయ పర్యవేక్షణ",
      "Driver Performance": "డ్రైవర్ పనితీరు",
      "Realtime GPS Tracking": "రియల్‌టైమ్ GPS ట్రాకింగ్",
      "Fleet Utilization": "ఫ్లీట్ వినియోగం",
      "Trip Efficiency": "ప్రయాణ సామర్థ్యం",
      "Dynamic Route Adjustment": "డైనమిక్ రూట్ సర్దుబాటు",
      "Load Distribution Optimization": "లోడ్ పంపిణీ ఆప్టిమైజేషన్",
      "Transporter Preferences": "ట్రాన్స్‌పోర్టర్ ప్రాధాన్యతలు",
      "Trip Schedule": "ప్రయాణ షెడ్యూల్",
      "Driver Assignment": "డ్రైవర్ అసైన్‌మెంట్",
      "Optimize Vehicle Utilization": "వాహన వినియోగాన్ని ఆప్టిమైజ్ చేయండి",
      "Time Based Planning": "సమయం ఆధారిత ప్లానింగ్",
      "Plant Wise Route Planning": "చెట్టు వారీగా రూట్ ప్రణాళిక",
      "Vehicle Rotation Configuration": "వాహన రొటేషన్ కాన్ఫిగరేషన్",
      "Inbound Vehicle Preferences": "ఇన్‌బౌండ్ వాహన ప్రాధాన్యతలు",
      "Outbound Vehicle Preferences": "అవుట్‌బౌండ్ వాహన ప్రాధాన్యతలు",
      "Vehicle Fill Rate Optimization": "వాహన ఫిల్ రేట్ ఆప్టిమైజేషన్",
      "Dynamic Load Allocation": "డైనమిక్ లోడ్ కేటాయింపు",
      "Start Date": "ప్రారంభ తేదీ",
      "Run Plan": "రన్ ప్లాన్",
      "Completion Date": "పూర్తి తేదీ",
      "Transporter Name": "ట్రాన్స్‌పోర్టర్ పేరు",
      "Driver Allocation": "డ్రైవర్ కేటాయింపు",
      "Max Load Capacity": "గరిష్ట లోడ్ సామర్థ్యం",
      "Estimated Delivery Time": "అంచనా డెలివరీ సమయం",
      "Use Real-Time Traffic Data": "రియల్‌టైమ్ ట్రాఫిక్ డేటాను ఉపయోగించండి",
      "Driver Break Times": "డ్రైవర్ బ్రేక్ సమయాలు",
      "Max Vehicle Load Percentage": "గరిష్ట వాహన లోడ్ శాతం",
      "Telescopic Deviation Settings": "టెలిస్కోపిక్ విరామం సెట్టింగ్స్",
      "Deviation Type": "విరామం రకం",
      "Spot Vehicle Usage": "స్పాట్ వాహనం వినియోగం",
      "Real-time Load Monitoring": "రియల్‌టైమ్ లోడ్ పర్యవేక్షణ",
      "Driver Allocation Time": "డ్రైవర్ కేటాయింపు సమయం",
      "Use Traffic Data": "ట్రాఫిక్ డేటాను ఉపయోగించండి",
      "Optimize Route": "రూట్‌ను ఆప్టిమైజ్ చేయండి",
      "Optimize Fuel Efficiency": "ఇంధన సామర్థ్యాన్ని ఆప్టిమైజ్ చేయండి",
      "Daily Vehicle Plan": "రోజువారీ వాహన ప్రణాళిక",
      "Transporter Allocation": "ట్రాన్స్‌పోర్టర్ కేటాయింపు",
      "Enable Real-time Tracking": "రియల్‌టైమ్ ట్రాకింగ్‌ను ప్రారంభించండి",
      "Vehicle Rotation Plan": "వాహన రొటేషన్ ప్రణాళిక",
      "Route Optimization Preferences": "రూట్ ఆప్టిమైజేషన్ ప్రాధాన్యతలు",
      "Consider Weather Conditions": "వాతావరణ పరిస్థితులను పరిగణించండి",
      "Max Loading Time": "గరిష్ట లోడింగ్ సమయం",
      "Min Load Capacity": "కనీస లోడ్ సామర్థ్యం",
      "Driver Scheduling": "డ్రైవర్ షెడ్యూల్",
      "Vehicle Capacity Monitoring": "వాహన సామర్థ్య పర్యవేక్షణ",
      "Dynamic Load Balancing": "డైనమిక్ లోడ్ బ్యాలెన్సింగ్",
      "Last Mile Optimization": "చివరి మైల్ ఆప్టిమైజేషన్",
      "Unloading Time Management": "లారింగ్ సమయ నిర్వహణ",
      "Daily Trip Plan": "రోజువారీ ప్రయాణ ప్రణాళిక",
      "Delivery Window Optimization": "డెలివరీ విండో ఆప్టిమైజేషన్",
      "Night Driving Restrictions": "రాత్రి డ్రైవింగ్ పరిమితులు",
      "Weekend Planning": "వీకెండ్ ప్రణాళిక",

      run_new_plan: "కొత్త ప్రణాళికను అమలు చేయండి",
      planned_day: "ప్లాన్ చేసిన రోజు",
      planned_distance_miles: "నిర్దేశిత దూరం (మైళ్ళు)",
      transporter_details: "రవాణాదారు వివరాలు",
      restriction_hours: "పరిమితి గంటలు",
      save_geofence: "జియోఫెన్స్‌ను సేవ్ చేయండి",
      no_data: "డేటా లేదు",
      redirecting_to_vehicle_checklist: "వాహన చెక్‌లిస్ట్‌కు దారిమళ్లిస్తోంది",
      drop_sequence: "డ్రాప్ సీక్వెన్స్",
      drop_location_code_destination: "డ్రాప్ లొకేషన్ కోడ్ (గమ్యం)",
      drop_location_name_destination: "డ్రాప్ లొకేషన్ పేరు (గమ్యం)",
      run_new_plan: "కొత్త ప్రణాళిక అమలు చేయండి",
      new_plan_details: "కొత్త ప్రణాళిక వివరాలు",
      order: "ఆర్డర్",
      vehicle_configs: "వాహన కాంఫిగరేషన్లు",
      order_information: "ఆర్డర్ సమాచారం",
      plan_configs: "ప్రణాళిక కాంఫిగరేషన్లు",
      vehicle_availability: "వాహన లభ్యత",
      vehicle_type: "వాహన రకం",
      vehicle_name: "వాహన పేరు",
      vehicle_min_weight: "వాహన కనీస బరువు (పౌండ్లు)",
      vehicle_max_weight: "వాహన గరిష్ఠ బరువు (పౌండ్లు)",
      vehicle_min_volume: "వాహన కనీస పరిమాణం (క్యూబిక్ అడుగులు)",
      vehicle_max_volume: "వాహన గరిష్ఠ పరిమాణం (క్యూబిక్ అడుగులు)",
      max_distance_per_trip: "ప్రయాణానికి గరిష్ఠ దూరం (మైళ్ళు)",
      vehicle_priority: "వాహన ప్రాధాన్యత",
      enable_reconciliation: "పునర్మిళనను ప్రారంభించండి",
      enable_dc_week_off_planning: "డీసీ వారాంతం ప్రణాళికను ప్రారంభించండి",
      order_number: "ఆర్డర్ సంఖ్య",
      invoicing_date: "బిలింగ్ తేదీ",
      quantity: "పరిమాణం",
      demand_cft: "డిమాండ్ (క్యూబిక్ అడుగులు)",
      weight_pounds: "బరువు (పౌండ్లు)",
      dealer_available_from: "డీలర్ నుండి లభ్యం",
      dealer_available_to: "డీలర్ వరకు లభ్యం",
      delivery_date: "వాటా తేదీ",
      general_settings: "సాధారణ సెట్టింగులు",
      prioritize_local_loads: "ప్రాదాన్యతా స్థానిక లోడ్లు",
      consider_dropping_distant_smaller_loads:
        "దూరపు చిన్న లోడ్లను వదిలివేయడం పరిగణించండి",
      plan_spot_vehicles: "వాహనాలను ప్రణాళిక చేయండి",
      city_based_entry_restriction: "నగర ప్రాతిపదికపై ప్రవేశ పరిమితి",
      consider_vehicle_break_times:
        "వాహన విరామ సమయాలను పరిగణించండి (రాత్రి డ్రైవింగ్)",
      consider_holiday_restrictions: "సెలవు పరిమితులను పరిగణించండి",
      consider_realtime_vehicle_available_times:
        "రియల్ టైమ్ వాహన లభ్యత సమయాలను పరిగణించండి",
      consider_transporter_sob: "ట్రాన్స్‌పోర్టర్ SOB పరిగణించండి",
      planning_optimization_settings: "ప్రణాళిక ఆప్టిమైజేషన్ సెట్టింగులు",
      use_dynamic_planning_window: "డైనమిక్ ప్రణాళిక విండోను ఉపయోగించండి",
      number_of_days_to_use_planning_window:
        "ప్రణాళిక విండో ఉపయోగించడానికి రోజుల సంఖ్య",
      optimization_type: "ఆప్టిమైజేషన్ రకం",
      cost_optimization: "ఖర్చు ఆప్టిమైజేషన్",
      weight_based_optimization: "బరువు ఆధారిత ఆప్టిమైజేషన్",
      distance_based_optimization: "దూరం ఆధారిత ఆప్టిమైజేషన్",
      time_based_optimization: "సమయం ఆధారిత ఆప్టిమైజేషన్",
      volume_based_optimization: "పరిమాణం ఆధారిత ఆప్టిమైజేషన్",
      vehicle_fill_rate_vfr_settings: "వాహన పూర్ణ రేటు (VFR) సెట్టింగులు",
      use_volume_for_vfr: "వాహన పూర్ణ రేటుకు పరిమాణం ఉపయోగించండి",
      use_weight_for_vfr: "వాహన పూర్ణ రేటుకు బరువు ఉపయోగించండి",
      min_vfr_per_vehicle_type: "వాహన రకం కోసం కనీస VFR",
      min_vfr_per_spot_vehicle: "ప్రతి స్పాట్ వాహనానికి కనీస VFR",
      moq_for_planning: "ప్రణాళిక కోసం MOQ",
      load_planning_settings: "లోడ్ ప్రణాళిక సెట్టింగులు",
      number_of_loading_docks: "లోడ్ డాక్స్ సంఖ్య",
      max_local_no_of_customers_per_vehicle:
        "ఒక్కో వాహనానికి గరిష్ఠ స్థానిక కస్టమర్‌ల సంఖ్య",
      max_upcountry_no_of_customers_per_vehicle:
        "ఒక్కో వాహనానికి గరిష్ఠ గ్రామీణ కస్టమర్‌ల సంఖ్య",
      prioritize_same_dealers_first:
        "వాహనంలో మొదట ఒకే డీలర్లకు ప్రాధాన్యత ఇవ్వండి",
      prioritize_multi_city_dealers_first:
        "వాహనంలో మల్టీ సిటీ డీలర్లకు ప్రాధాన్యత ఇవ్వండి",
      note_highlighted_rows_red:
        "ఎరుపులో హైలైట్ చేయబడిన వరుసలు GPS డేటా లేదా ఇంటిగ్రేషన్ లేకుండా ఉంటాయి, వాహన లభ్యతను మాన్యువల్‌గా గుర్తించండి.",
      note_highlighted_rows_green:
        "పచ్చగా హైలైట్ చేయబడిన వరుసలు ప్రణాళిక కోసం పరిగణించబడతాయి",
      vehicle_number: "వాహన సంఖ్య",
      transit_type: "రవాణా రకం",
      monthly_minimum_distance: "మాసం కనీస దూరం",
      remaining_distance: "మిగిలిన దూరం",
      vehicle_available_date: "వాహన లభ్యత తేదీ (YYYY-MM-DD HH:mm)",
      charlie_hub: "చార్లీ హబ్",
      enable_recommendation: "సిఫారసును ప్రారంభించండి",
      planned_day: "ప్రణాళిక రోజు",
      drop_sequence: "డ్రాప్ సీక్వెన్స్",
      drop_location_code_destination: "డ్రాప్ లొకేషన్ కోడ్ (గమ్యం)",
      drop_location_name_destination: "డ్రాప్ లొకేషన్ పేరు (గమ్యం)",
      destination_location_city: "గమ్యం నగరం",
      weight_kgs: "బరువు (కిలోలు)",
      volume_m3: "పరిమాణం (CMT)",
      distance_to_destination_kms: "గమ్యానికి దూరం (కిలోమీటర్లు)",
      expected_delivery_date: "అంచనా డెలివరీ తేదీ",
      time_to_destination: "గమ్యానికి సమయం",
      transit_time_hh_mm: "రవాణా సమయం (HH:MM)",
      unloading_time_hh_mm: "అన్‌లోడింగ్ సమయం (HH:MM)",
      plan_id: "ప్రణాళిక ID",
      generated_on: "తెచ్చిన తేదీ",
      vehicle_availability_from: "వాహన లభ్యత (నుండి)",
      vehicle_availability_to: "వాహన లభ్యత (వరకు)",
      break_time_from: "విరామ సమయం (నుండి)",
      break_time_to: "విరామ సమయం (వరకు)",
      min_volume_m3: "కనీస పరిమాణం (క్యూబిక్ మీటర్లు)",
      max_volume_m3: "గరిష్ఠ పరిమాణం (క్యూబిక్ మీటర్లు)",
      vehicle_width_mm: "వాహన వెడల్పు (మిల్లీమీటర్లు)",
      vehicle_height_mm: "వాహన ఎత్తు (మిల్లీమీటర్లు)",
      vehicle_depth_mm: "వాహన లోతు (మిల్లీమీటర్లు)",
      material_code: "పదార్థ కోడ్",
      material_name: "పదార్థ పేరు",
      material_type: "పదార్థ రకం",
      material_weight: "పదార్థ బరువు",
      material_dimensions_depth: "పదార్థ పరిమాణం - లోతు",
      material_dimensions_width: "పదార్థ పరిమాణం - వెడల్పు",
      material_dimensions_height: "పదార్థ పరిమాణం - ఎత్తు",
      state: "రాష్ట్రం",
      city: "నగరం",
      fixed_vehicle_cost: "స్థిర వాహన వ్యయం",
      driver_cost: "డ్రైవర్ వ్యయం",
      maintenance_cost: "నిర్వహణ ఖర్చు",
      per_cmt_cost: "ప్రతి క్యూబిక్ అడుగుకు ఖర్చు",
      per_km_cost: "ప్రతి కిలోమీటరుకు ఖర్చు",
      date: "తేదీ",
      transporter_name: "రవాణాదారు పేరు",
      no_of_vehicles: "వాహనాల సంఖ్య",
      driver_break_time_from: "డ్రైవర్ విరామ సమయం (నుండి)",
      vehicle_max_weight_kgs: "వాహన గరిష్ఠ బరువు (కిలోలు)",
      vehicle_min_volume_m3: "వాహన కనీస పరిమాణం (క్యూబిక్ మీటర్లు)",
      vehicle_max_volume_m3: "వాహన గరిష్ఠ పరిమాణం (క్యూబిక్ మీటర్లు)",
      max_distance_kms: "గరిష్ఠ దూరం (కిలోమీటర్లు)",
      monthly_min_planning_distance_kms:
        "మాసం కనీస ప్రణాళిక దూరం (కిలోమీటర్లు)",
      details: "వివరాలు",
      trip_no: "ప్రయాణ సంఖ్య",
      vehicle_no: "వాహన సంఖ్య",
      no_of_orders: "ఆర్డర్ల సంఖ్య",
      no_of_customers: "కస్టమర్ల సంఖ్య",
      total_quantity: "మొత్తం పరిమాణం",
      vehicle_utilization_percent: "వాహన వినియోగం (%)",
      total_planned_weight_kgs: "మొత్తం ప్రణాళిక బరువు (కిలోలు)",
      onward_trip_distance_kms: "ముందుకు వెళ్లే దూరం (కిలోమీటర్లు)",
      round_trip_distance_kms: "రౌండ్ ట్రిప్ దూరం (కిలోమీటర్లు)",
      total_planned_volume_m3: "మొత్తం ప్రణాళిక పరిమాణం (క్యూబిక్ మీటర్లు)",
      max_dispatch_time_hh_mm: "గరిష్ఠ పంపిణీ సమయం (HH:MM)",
      arrival_at_first_dealer_start: "మొదటి డీలర్ వద్ద చేరిక (ప్రారంభం)",
      departure_at_last_dealer_end: "చివరి డీలర్ వద్ద ప్రయాణం (ముగింపు)",
      return_to_plant_hh_mm: "ఫ్యాక్టరీకి తిరిగి సమయం (HH:MM)",
      total_planned_trip_time_hrs: "మొత్తం ప్రణాళిక ప్రయాణ సమయం (గంటలు)",
      total_round_trip_time_hrs: "మొత్తం రౌండ్ ట్రిప్ సమయం (గంటలు)",
      total_transit_time_hh_mm: "మొత్తం రవాణా సమయం (HH:MM)",
      total_loading_time_hh_mm: "మొత్తం లోడింగ్ సమయం (HH:MM)",
      total_unloading_time_hh_mm: "మొత్తం అన్‌లోడింగ్ సమయం (HH:MM)",
      drop_location_code: "డ్రాప్ లొకేషన్ కోడ్",
      drop_location_name: "డ్రాప్ లొకేషన్ పేరు",
      drop_location_city: "డ్రాప్ లొకేషన్ నగరం",
      demand_volume_m3: "డిమాండ్ పరిమాణం (క్యూబిక్ మీటర్లు)",
      vehicle_min_weight_kgs: "వాహన కనీస బరువు (కిలోలు)",
      no_of_deliveries: "డెలివరీల సంఖ్య",
      min_load_per_delivery: "ఒక్కో డెలివరీకి కనీస లోడ్",
      status: "స్థితి",
      processing: "ప్రాసెసింగ్",
      please_contact_admin: "దయచేసి అడ్మిన్‌ను సంప్రదించండి",
      solver_complete: "పరిష్కారం పూర్తయింది",
      solver_reco_processed: "సిఫారసు ప్రక్రియ పూర్తి",
      solver_sob_done: "SOB పూర్తయింది",
      solver_invalid_input_dealer_location: "తప్పుడు డీలర్ లొకేషన్ ఇన్‌పుట్",
      solver_invalid_input_materials: "తప్పుడు పదార్థాలు ఇన్‌పుట్",
      solver_invalid_input_dealer_location_and_materials:
        "తప్పుడు డీలర్ లొకేషన్ మరియు పదార్థాలు ఇన్‌పుట్",
      solver_input_data_not_valid: "ఇన్‌పుట్ డేటా చెల్లదు",
      solver_fix_dropped_dealers: "వదిలిపెట్టిన డీలర్లను సవరించండి",
      solver_vehicle_details_missing: "వాహన వివరాలు గల్లంతయ్యాయి",
      solver_input_file_data_error: "ఇన్‌పుట్ ఫైల్ డేటా లోపం",
      solver_invalid_file_template: "చెల్లని ఫైల్ టెంప్లేట్",
      process_timed_out: "ప్రాసెస్ టైమ్ అవుట్ అయ్యింది",
      solver_sob_fail: "SOB విఫలమైంది",
      solver_reco_failed: "సిఫారసు విఫలమైంది",
      solver_no_tool_config: "టూల్ కాంఫిగరేషన్ లేదు",
      solver_invalid_tool_config: "చెల్లని టూల్ కాంఫిగరేషన్",
      transit_time_with_multiple_city_clubing:
        "అన్ని నగరాల్లో కలిపిన రవాణా సమయం",
      rdd_preference: "RDD ప్రాధాన్యత",
      transit_time_with_single_city_clubing: "ఒకే నగరంలో కలిపిన రవాణా సమయం",
      no_of_trucks_planned: "ప్రణాళికలో ఉన్న ట్రక్కుల సంఖ్య",
      view_details: "వివరాలను చూడండి",
      plan_run_by: "ప్రణాళిక నడిపినది",
      compare_plans: "ప్రణాళికలను పోల్చండి",
      scenario_name: "సీన్ పేరు",
      pick_up_location_source: "పిక్-అప్ లొకేషన్ (మూలం)",
      pickup_location_source: "పిక్-అప్ లొకేషన్ (మూలం)",
      drop_location_destination: "డ్రాప్ లొకేషన్ (గమ్యం)",
      demand_m3: "డిమాండ్ (మ³)",
      location_radius: "లొకేషన్ వ్యాసార్థం (కిమీ)",
      volume_cmt: "పరిమాణం (CMT)",
      obd_details_in_radius: "వ్యతిరేక OBD వివరాలు",
      planning_configurations: "ప్రణాళిక కాంఫిగరేషన్లు",
      planning_scenarios: "ప్రణాళిక పరిస్థితులు",
      what_if_demand_fluctuation: "ఎవిధంగా ఉంటుందంటే: డిమాండ్ మార్పు",
      increase_decrease_demand_by: "డిమాండ్‌ను పెంచండి/తగ్గించండి",
      what_if_customer_priority_changes:
        "ఎవిధంగా ఉంటుందంటే: కస్టమర్ ప్రాధాన్యత మార్పు",
      prioritize_upcountry: "గ్రామీణ ప్రాంతానికి ప్రాధాన్యత ఇవ్వండి",
      prioritize_local: "స్థానిక ప్రాధాన్యత ఇవ్వండి",
      what_if_service_level_target_changes:
        "ఎవిధంగా ఉంటుందంటే: సేవా స్థాయి లక్ష్యం మార్పు",
      set_service_level_target_to: "సేవా స్థాయి లక్ష్యాన్ని సెట్ చేయండి",
      what_if_vehicle_fill_rate_changes:
        "ఎవిధంగా ఉంటుందంటే: వాహన పూర్ణ రేటు మార్పు",
      set_vehicle_fill_rate_to: "వాహన పూర్ణ రేటును సెట్ చేయండి",
      what_if_customer_specific_delivery_time:
        "ఎవిధంగా ఉంటుందంటే: కస్టమర్‌కు నిర్దిష్ట డెలివరీ సమయం",
      increase_decrease_delivery_time_window_by:
        "డెలివరీ సమయం విండోను పెంచండి/తగ్గించండి",
      scenario_description: "సీన్ వివరణ",
      vehicle_fill_rate_settings: "వాహన పూర్ణ రేటు సెట్టింగులు",
      file_name: "ఫైల్ పేరు",
      total_weight_kgs: "మొత్తం బరువు (కిలోలు)",
      total_demand_volume_m3: "మొత్తం డిమాండ్ పరిమాణం (మ³)",
      processing_plan_please_wait:
        "ప్రాసెసింగ్ ప్రణాళిక. దయచేసి వేచి ఉండండి...",
      forecast_input_information: "అంచనా ఇన్‌పుట్ సమాచారం",
      uploading_data_for_plant: "ప్లాంట్ కోసం డేటాను అప్‌లోడ్ చేస్తోంది",
      vehicle_type_configuration: "వాహన రకం కాంఫిగరేషన్",
      min_volume_cft: "కనీస పరిమాణం (క్యూబిక్ అడుగులు)",
      max_volume_cft: "గరిష్ఠ పరిమాణం (క్యూబిక్ అడుగులు)",
      cost_per_km: "ప్రతి కిలోమీటరుకు ఖర్చు",
      note_duplicate_scenario: "గమనిక: డూప్లికేట్ సీన్",
      select_unique_scenarios_to_compare:
        "ప్రణాళికలను పోల్చడానికి ప్రత్యేక సీన్లను ఎంచుకోండి",
      scenario_selection_limit_exceeded: "సీన్ ఎంపిక పరిమితిని మించిపోయింది",
      note_scenario_selection_limit:
        "గమనిక: మీరు గరిష్ఠంగా మూడు సీన్లను ఎంచుకోగలరు",
      select_scenario_to_compare: "ప్రణాళికలను పోల్చడానికి సీన్ ఎంచుకోండి",
      forecast_scenarios: "అంచనా సీన్లు",
      select_planning_duration: "ప్రణాళిక వ్యవధిని ఎంచుకోండి",
      notify_email_users_after_process:
        "ప్రాసెస్ తర్వాత ఇమెయిల్ వినియోగదారులకు తెలియజేయండి (కామా వేర్పరచి)",
      optimize_by_time_details: "సమయం వివరాల ద్వారా ఆప్టిమైజ్ చేయండి",
      max_time_limit_hrs: "గరిష్ఠ సమయం పరిమితి (గంటలు)",
      spot_vehicles_to_be_planned: "ప్రణాళిక చేయవలసిన స్పాట్ వాహనాలు",
      auto_select: "ఆటో సెలెక్ట్",
      destination_location_city: "గమ్యం నగరం",
      dealer_type: "డీలర్ రకం",
      customer_type: "కస్టమర్ రకం",
      week_off: "వారంలో సెలవు",
      order_number: "ఆర్డర్ నంబర్",
      invoicing_date: "ఇన్వాయ్సింగ్ తేదీ",
      quantity: "పరిమాణం",
      weight_pounds: "బరువు (పౌండ్లు)",
      volume_cft: "వాల్యూమ్ (CFT)",
      distance_to_destination_miles: "గమ్యానికి దూరం (మైళ్ళు)",
      expected_delivery_date: "ఎదురుచూపే డెలివరీ తేదీ",
      time_to_destination: "గమ్యానికి సమయం",
      transit_time_hh_mm: "ట్రాన్సిట్ సమయం (HH:MM)",
      unloading_time_hh_mm: "అన్‌లోడింగ్ సమయం (HH:MM)",
      email: "ఇమెయిల్",
      details: "వివరాలు",
      view: "చూడండి",
      assign_vehicle: "వాహనాన్ని కేటాయించండి",
      revoke_assignment: "కేటాయింపును రద్దు చేయండి",
      edit_plan: "ప్లాన్ సవరించండి",
      delete: "తొలగించు",
      plan_edited: "ప్లాన్ సవరించబడింది",
      yes: "అవును",
      no: "కాదు",
      trip_no: "ట్రిప్ నం",
      vehicle_no: "వాహన నం",
      vehicle_type: "వాహన రకం",
      cost: "ఖర్చు ($)",
      transit_type: "ట్రాన్సిట్ రకం",
      transporter_name: "ట్రాన్స్‌పోర్టర్ పేరు",
      no_of_orders: "ఆర్డర్ల సంఖ్య",
      no_of_customers: "కస్టమర్ల సంఖ్య",
      total_quantity: "మొత్తం పరిమాణం",
      vehicle_utilization_percent: "వాహన వినియోగం (%)",
      vehicle_max_weight: "వాహన గరిష్ట బరువు (పౌండ్లు)",
      total_planned_weight: "మొత్తం ప్లాన్ చేసిన బరువు (పౌండ్లు)",
      onward_trip_distance_miles: "వెళ్లే ట్రిప్ దూరం (మైళ్ళు)",
      round_trip_distance_miles: "రౌండ్ ట్రిప్ దూరం (మైళ్ళు)",
      vehicle_max_volume_cft: "వాహన గరిష్ట వాల్యూమ్ (CFT)",
      total_planned_volume_cft: "మొత్తం ప్లాన్ చేసిన వాల్యూమ్ (CFT)",
      max_dispatch_time: "గరిష్ట డిస్పాచ్ సమయం (HH:MM)",
      arrival_at_first_dealer: "మొదటి డీలర్ వద్ద చేరడం (ప్రారంభం)",
      departure_at_last_dealer: "చివరి డీలర్ వద్ద నుంచి బయలుదేరడం (ముగింపు)",
      return_to_plant: "ప్లాంట్‌కు తిరిగి రాబడటం (HH:MM)",
      total_planned_trip_time: "మొత్తం ప్లాన్ చేసిన ట్రిప్ సమయం (గంటలు)",
      total_round_trip_time: "మొత్తం రౌండ్ ట్రిప్ సమయం (గంటలు)",
      total_transit_time: "మొత్తం ట్రాన్సిట్ సమయం (HH:MM)",
      total_loading_time: "మొత్తం లోడింగ్ సమయం (HH:MM)",
      total_unloading_time: "మొత్తం అన్‌లోడింగ్ సమయం (HH:MM)",
      remark: "విమర్శ",
      "n/a": "లభ్యం లేదు",
      revoke_again_to_edit: "సవరించడానికి మళ్లీ రద్దు చేయండి",
      drop_location_code: "డ్రాప్ లొకేషన్ కోడ్",
      drop_location_name: "డ్రాప్ లొకేషన్ పేరు",
      drop_location_city: "డ్రాప్ లొకేషన్ నగరం",
      distance_from_plant: "ప్లాంట్ నుండి దూరం",
      demand_volume_cft: "డిమాండ్ వాల్యూమ్ (CFT)",
      delivery_date: "డెలివరీ తేదీ",
      actions: "చర్యలు",
      pickup_location_source: "పికప్ లొకేషన్ (మూలం)",
      dealer_available_from: "డీలర్ అందుబాటులో ఉన్నారు నుండి",
      dealer_available_to: "డీలర్ అందుబాటులో ఉన్నారు వరకు",
      loading_time_hh_mm: "లోడింగ్ సమయం (HH:MM)",
      dropped_reason: "డ్రాప్ చేయబడిన కారణం",
      dealer_location_data_not_in_system: "డీలర్ లొకేషన్ డేటా సిస్టమ్‌లో లేదు",
      delivery_date_less_than_current_date:
        "డెలివరీ తేదీ ప్రస్తుత తేదీ కంటే తక్కువగా ఉంది",
      kdd_customer: "KDD కస్టమర్",
      insufficient_load_for_planning: "ప్లానింగ్ కోసం తక్కువ లోడ్ (<MOQ)",
      warehouse_name: "గిడ్డంగి పేరు",
      destination_city: "గమ్యం నగరం",
      destination_state: "గమ్యం రాష్ట్రం",
      sales_order_no: "సేల్స్ ఆర్డర్ నం",
      no_of_tires: "టైర్లు సంఖ్య",
      order_date: "ఆర్డర్ తేదీ",
      vehicle_name: "వాహన పేరు",
      vehicle_priority: "వాహన ప్రాధాన్యత",
      vehicle_availability_from: "వాహనం అందుబాటులో ఉంది (నుండి)",
      vehicle_availability_to: "వాహనం అందుబాటులో ఉంది (వరకు)",
      break_time_from: "బ్రేక్ సమయం (నుండి)",
      break_time_to: "బ్రేక్ సమయం (వరకు)",
      min_volume_cft: "కనిష్ట వాల్యూమ్ (CFT)",
      max_volume_cft: "గరిష్ట వాల్యూమ్ (CFT)",
      vehicle_dimensions_width_mm: "వాహనం వెడల్పు (mm)",
      vehicle_dimensions_height_mm: "వాహనం ఎత్తు (mm)",
      vehicle_dimensions_depth_mm: "వాహనం లోతు (mm)",
      vehicle_min_weight: "వాహనం కనిష్ట బరువు (పౌండ్లు)",
      max_no_of_nodes: "డెలివరీల సంఖ్య",
      min_load_per_delivery: "ప్రతి డెలివరీ కనిష్ట లోడ్",
      max_distance: "గరిష్ట దూరం (మైళ్ళు)",
      material_code: "మెటీరియల్ కోడ్",
      material_name: "మెటీరియల్ పేరు",
      material_type: "మెటీరియల్ రకం",
      material_weight: "మెటీరియల్ బరువు",
      material_dimensions_depth: "మెటీరియల్ లోతు",
      material_dimensions_width: "మెటీరియల్ వెడల్పు",
      material_dimensions_height: "మెటీరియల్ ఎత్తు",
      form_vehicle_types: "వాహన రకాలు",
      dealer_form_state: "రాష్ట్రం",
      dealer_form_city: "నగరం",
      freight_fixed_vehicle_cost: "స్థిర వాహన ఖర్చు",
      freight_driver_cost: "డ్రైవర్ ఖర్చు",
      freight_maintenance_cost: "నిర్వహణ ఖర్చు",
      freight_per_cmt_cost: "ప్రతి CMT ఖర్చు",
      freight_per_km_cost: "ప్రతి కిలోమీటరు ఖర్చు",
      vehicle_date: "తేదీ",
      vehicle_number: "వాహన నం",
      plant_code: "ప్లాంట్ కోడ్",
      plant_name: "ప్లాంట్ పేరు",
      available_time: "అందుబాటులో ఉన్న సమయం",
      gps_status: "GPS స్థితి",
      last_known_address: "చివరి తెలిసిన చిరునామా",
      last_gps_time: "చివరి GPS సమయం",
      vehicle_location: "వాహన స్థానం",
      assigned_to_ot: "OT కు కేటాయించబడింది",
      min_vfr: "కనిష్ట VFR",
      order_information: "ఆర్డర్ సమాచారం",
      vehicle_type_configurations: "వాహన రకం కాన్ఫిగరేషన్లు",
      vehicle_routes: "వాహన మార్గాలు",
      total_customers: "మొత్తం కస్టమర్లు",
      planned: "ప్లాన్ చేసినవి",
      unplanned: "ప్లాన్ కానివి",
      total_round_trip_distance_miles: "మొత్తం రౌండ్ ట్రిప్ దూరం (మైళ్ళు)",
      planning: "ప్రణాళిక",
      vfr: "VFR",
      ontime_dispatch: "సమయానికి పంపిణీ",
      avg_tat: "సగటు TAT",
      view_locations: "ప్రదేశాలు చూడండి",
      view_routes: "మార్గాలు చూడండి",
      additional_vehicles: "అదనపు వాహనాలు",
      regular_vehicles: "సాధారణ వాహనాలు",
      enter_vehicle_no: "వాహన నంబర్ నమోదు చేయండి",
      vehicle_has_active_trip_shipment_id:
        "వాహనానికి క్రియాశీల ట్రిప్ షిప్మెంట్ ID ఉంది",
      do_you_wish_to: "మీరు చేయాలనుకుంటున్నారా",
      force_close_assign: "ఫోర్స్ క్లోజ్ & అసైన్ చేయండి",
      force_close_reason: "ఫోర్స్ క్లోజ్ కారణం",
      gps_issue: "GPS సమస్య",
      trip_closed: "యాత్ర ముగిసింది",
      load_cancelled: "లోడ్ రద్దు చేయబడింది",
      new_trip_allocated: "కొత్త ట్రిప్ కేటాయించబడింది",
      driver_changed: "డ్రైవర్ మార్చబడింది",
      are_you_sure_you_want_to_update: "మీరు నవీకరించాలనుకుంటున్నారా",
      edit_plan_for_same: "ఇటువంటి కోసం ప్రణాళిక సవరించు",
      update_vehicle_plan: "వాహన ప్రణాళికను నవీకరించండి",
      max_dispatch_time: "గరిష్ట పంపిణీ సమయం",
      new_plan: "కొత్త ప్రణాళిక",
      reason_for_plan_change: "ప్రణాళిక మార్పు కోసం కారణం",
      enter_the_reason: "కారణం నమోదు చేయండి",
      reason_for_plan_deletion: "ప్రణాళిక తొలగింపు కోసం కారణం",
      are_you_sure_delete_plan:
        "మీరు ఖచ్చితంగా ప్రణాళిక తొలగించాలనుకుంటున్నారా",
      update_order_details: "ఆర్డర్ వివరాలను నవీకరించండి",
      demand_volume: "డిమాండ్ వాల్యూమ్",
      dealer_availability_start_time: "డీలర్ అందుబాటు ప్రారంభ సమయం",
      dealer_availability_end_time: "డీలర్ అందుబాటు ముగింపు సమయం",
      download: "డౌన్లోడ్",
      dealerTimeError:
        "డీలర్ అందుబాటు ప్రారంభ సమయం ముగింపు సమయానికి తక్కువగా ఉండాలి",
      more: "మరింత",
      less: "తక్కువ",
      qualityChecks: "నాణ్యత తనిఖీలు",
      currentMonthPlanDistance: "ప్రస్తుత నెల ప్రణాళిక దూరం",
      email: "ఈ మెయిల్",
      viewPlan: "ప్రణాళిక చూడండి",
      configurations: "కాన్ఫిగరేషన్స్",
      import_existing_vehicle_details: "ఉన్న వాహన వివరాలను దిగుమతి చేయండి",
      blank_rows_columns_not_allowed: "ఖాళీ వరుసలు/కాలమ్లు అనుమతించబడవు.",
      uploading_data_for_plant: "సంస్కరణ కోసం డేటా అప్లోడ్ చేయడం",
      check_and_remove_blank_data:
        "ఖాళీ డేటాను తనిఖీ చేసి తొలగించడానికి, శోధించు మరియు ఎంచుకో → ప్రత్యేకానికి వెళ్లు → ఖాళీలు, మరియు హైలైట్ చేయబడిన వరుసలను తొలగించండి.",
      add_vehicle_type: "వాహన రకం జోడించండి",
      vehicle_operational_hours: "వాహన కార్యాచరణ సమయాలు (HH:MM)",
      data_saved_successfully: "డేటా విజయవంతంగా సేవ్ చేయబడింది",
      plant_wise_configurations: "ప్లాంట్ వారీగా కాన్ఫిగరేషన్లు",
      save_plant_wise_configurations:
        "ప్లాంట్ వారీగా కాన్ఫిగరేషన్లను సేవ్ చేయండి",
      vehicle_break_window: "వాహన విరామం విండో (HH:MM)",
      total_vehicles: "మొత్తం వాహనాలు",
      zone_configurations: "మండల కాన్ఫిగరేషన్లు",
      pickup_point: "పికప్ పాయింట్",
      actions: "చర్యలు",
      edit: "మార్చు",
      add_transporter_details: "+ రవాణాదారు వివరాలు జోడించండి",
      cost_per_mile: "మైలు ప్రకారం ఖర్చు",
      freight_details: "రవాణా వివరాలు",
      add_zone: "+ మండలం జోడించండి",
      fixed_vehicle_cost_title: "స్థిర వాహన ఖర్చు ($)",
      variable_vehicle_cost_title: "మార్పిడి వాహన ఖర్చు",
      city_wise_cost: "నగరం వారీగా వాహన ఖర్చు",
      zone_wise_cost: "మండలం వారీగా వాహన ఖర్చు",
      vehicle_cost_configurations: "వాహన ఖర్చు కాన్ఫిగరేషన్లు",
      freight_cost_label: "రవాణా ఖర్చు",
      validity_to_label: "చెల్లుబాటు ఉన్న తేదీ వరకు",
      validity_from_label: "చెల్లుబాటు ఉన్న తేదీ నుండి",
      actions_label: "చర్యలు",
      edit_button: "మార్చు",
      delete_button: "తొలగించు",
      save_button: "సేవ్ చేయండి",
      cancel_button: "రద్దు చేయండి",
      select_vehicle_type: "వాహన రకాన్ని ఎంచుకోండి",
      select_transporter_name: "రవాణాదారు పేరు ఎంచుకోండి",
      zone: "మండలం",
      state: "రాష్ట్రం",
      driver_cost: "డ్రైవర్ ఖర్చు ($)",
      maintenance_cost: "నిర్వహణ ఖర్చు ($)",
      fixed_vehicle_cost_label: "స్థిర వాహన ఖర్చు ($)",
      cost_per_mile: "మైలు ప్రకారం ఖర్చు",
      freight_details: "రవాణా వివరాలు",
      add_zone: "+ మండలం జోడించండి",
      fixed_vehicle_cost_title: "స్థిర వాహన ఖర్చు ($)",
      variable_vehicle_cost_title: "మార్పిడి వాహన ఖర్చు",
      city_wise_cost: "నగరం వారీగా వాహన ఖర్చు",
      zone_wise_cost: "మండలం వారీగా వాహన ఖర్చు",
      vehicle_cost_configurations: "వాహన ఖర్చు కాన్ఫిగరేషన్లు",
      freight_cost_label: "రవాణా ఖర్చు",
      validity_to_label: "చెల్లుబాటు ఉన్న తేదీ వరకు",
      validity_from_label: "చెల్లుబాటు ఉన్న తేదీ నుండి",
      actions_label: "చర్యలు",
      edit_button: "మార్చు",
      delete_button: "తొలగించు",
      save_button: "సేవ్ చేయండి",
      cancel_button: "రద్దు చేయండి",
      enable_disable_dispatch_planning_module:
        "డిస్పాచింగ్ ప్రణాళిక మాడ్యూల్‌ని ప్రారంభించండి/డిసేబుల్ చేయండి",
      inbound: "ఇన్‌బౌండ్",
      suppliers_vendor_locations_pickup: "సరఫరాదారులు/విక్రేత స్థలాలు (పికప్)",
      operational_hours: "ఆపరేషనల్ గంటలు",
      pickup_locations_drop: "పికప్ లొకేషన్లు (డ్రాప్)",
      plant_wise_vehicle_type_configuration:
        "ప్లాంట్ వారీగా వాహన రకం కాన్ఫిగరేషన్",
      max_no_of_vehicle_types: "గరిష్ట వాహన రకాల సంఖ్య",
      vehicle_load_capacity_min_max_configuration_preferences:
        "మినిమం/మాక్సిమం వాహన లోడ్ సామర్థ్య కాన్ఫిగరేషన్ ప్రాధాన్యతలు",
      vehicle_load_weight_min_max_preferences:
        "వాహన లోడ్ బరువు మినిమం/మాక్సిమం ప్రాధాన్యతలు",
      distance_preferences: "దూరం ప్రాధాన్యతలు",
      vehicle_priorities: "వాహన ప్రాధాన్యతలు",
      vehicle_operational_times: "వాహన కార్యకాల సమయాలు",
      vehicle_availability: "వాహన లభ్యత",
      generic_time_windows: "జనరల్ టైమ్ విండోలు",
      individual_vehicle_time_windows: "ఇండివిడ్యువల్ వాహన టైమ్ విండోలు",
      availability_by_vehicle_type: "వాహన రకం ప్రకారం లభ్యత",
      availability_by_individual_vehicle: "ఇండివిడ్యువల్ వాహనాల ప్రకారం లభ్యత",
      monthly_minimum_planning_distance_preferences:
        "నెలవారీ కనిష్ట ప్రణాళిక దూరం ప్రాధాన్యతలు",
      gps_based_availability: "GPS ఆధారిత లభ్యత",
      truck_location_visibility: "ట్రక్ స్థానం చూడగలిగేలా చేయండి",
      plant_vicinity_circle_radius: "ప్లాంట్ పరిసర ప్రాంతం చుట్టుకొలత",
      plant_wise_freight_configuration: "ప్లాంట్ వారీగా రవాణా కాన్ఫిగరేషన్",
      plant_wise_transporter_configuration:
        "ప్లాంట్ వారీగా రవాణాదారు కాన్ఫిగరేషన్",
      transporter_share_of_business: "రవాణాదారు వ్యాపారంలో వాటా",
      route_preferences: "మార్గం ప్రాధాన్యతలు",
      plant_wise_new_plan_creation: "ప్లాంట్ వారీగా కొత్త ప్రణాళిక సృష్టి",
      plant_wise_plan_summary: "ప్లాంట్ వారీగా ప్రణాళిక సారాంశం",
      plant_wise_planning_history: "ప్లాంట్ వారీగా ప్రణాళిక చరిత్ర",
      sustainability_integration: "సస్టైనబిలిటీ ఇంటిగ్రేషన్",
      save_inbound_configurations: "ఇన్‌బౌండ్ కాన్ఫిగరేషన్లను సేవ్ చేయండి",
      save_outbound_configurations: "అవుట్‌బౌండ్ కాన్ఫిగరేషన్లను సేవ్ చేయండి",
      outbound: "అవుట్‌బౌండ్",
      consignees_dealers_locations_drop: "సంఖ్యాలుగల / డీలర్ల స్థలాలు (డ్రాప్)",
      select_vehicle_type: "వాహన రకాన్ని ఎంచుకోండి",
      from_time: "ప్రారంభ సమయం",
      to_time: "ముగింపు సమయం",
      actions: "కార్యచర్యలు",
      edit: "మార్చు",
      customer_location: "గ్రాహకుని స్థానం",
      truck_no: "ట్రక్ సంఖ్య",
      facility_name: "సదుపాయం పేరు",
      current_status: "ప్రస్తుత స్థితి",
      inside_dc: "డీసీ లోపల",
      in_transit_to_customer: "కస్టమర్ కు ట్రాన్సిట్ లో",
      near_customer: "కస్టమర్ కు సమీపంలో",
      return_to_dc: "డీసీకి తిరిగి",
      empty_outside_dc: "డీసీ వెలుపల ఖాళీగా",
      unplanned: "పథకం లేదు",
      next_availability_date_at_dc: "డీసీలో తదుపరి లభ్యమైన తేదీ",
      transporter_name: "రవాణాదారు పేరు",
      gps_status: "జీపీఎస్ స్థితి",
      inactive: "క్రియారహితం",
      active: "క్రియాశీల",
      na: "లభ్యం లేదు",
      date_and_time: "తేదీ మరియు సమయం",
      security: "భద్రత",
      quality: "నాణ్యత",
      assessed_by: "మూల్యాంకనం చేసినవారు",
      quality_conclusion: "నాణ్యత తేలిక",
      quality_comments: "నాణ్యత వ్యాఖ్యలు",
      quality_approved: "నాణ్యత ఆమోదం పొందింది",
      quality_date_of_assessment: "నాణ్యత యొక్క మూల్యాంకన తేదీ",
      vehicle_number: "వాహన సంఖ్య",
      vehicle_type: "వాహన రకం",
      transporter_code: "రవాణాదారు కోడ్",
      last_packet_time: "చివరి ప్యాకెట్ సమయం",
      vehicle_checklist_for_transportation: "రవాణా కోసం వాహన చెక్లిస్ట్",
      security_conclusion: "భద్రత తేలిక",
      security_comments: "భద్రత వ్యాఖ్యలు",
      security_approved: "భద్రత ఆమోదం పొందింది",
      security_date_of_assessment: "భద్రత యొక్క మూల్యాంకన తేదీ",
      view: "చూడండి",
      location_code: "స్థానం కోడ్",
      vehicle_no: "వాహన సంఖ్య",
      stage: "దశ",
      quality_rejected: "నాణ్యత తిరస్కరించబడింది",
      requirements_set: "అవసరాలు ఏర్పాటు చేయబడ్డాయి",
      security_rejected: "భద్రత తిరస్కరించబడింది",
      security_accepted: "భద్రత ఆమోదించబడింది",
      assessment_date: "మూల్యాంకన తేదీ",
      requirement: "అవసరం",
      satisfied: "సంతృప్తి చెందింది",
      yes: "అవును",
      no: "కాదు",
      requirement_set_date: "అవసరం ఏర్పాటు చేయబడిన తేదీ",
      temperature: "ఉష్ణోగ్రత",
      vehicle_operational_from: "వాహనం ఆపరేషనల్ (నుంచి)",
      vehicle_operational_to: "వాహనం ఆపరేషనల్ (వరకు)",
      break_time_from: "విరామ సమయం (నుంచి)",
      break_time_to: "విరామ సమయం (వరకు)",
      view_vehicles: "వాహనాలను చూడండి",
      max_distance_per_plan_miles: "ప్రతి ప్లాన్ కు గరిష్ట దూరం (మైళ్లలో)",
      remaining_monthly_distance_miles: "మిగిలిన నెలవారీ దూరం (మైళ్లలో)",
      vehicle_next_available_date: "వాహనం తదుపరి లభ్యమైన తేదీ",
      owned: "సొంతం",
      contract: "ఒప్పందం",
      high: "అధిక",
      medium: "మధ్యస్థ",
      low: "తక్కువ",
      market: "మార్కెట్",
      vehicles_for_today: "ఈరోజు వాహనాలు",
      bulk_upload_master_vehicles: "మాస్టర్ వాహనాల బల్క్ అప్‌లోడ్",
      add_master_vehicle: "మాస్టర్ వాహనం జోడించు",
      transit_type: "ట్రాన్సిట్ రకం",
      vehicle_priority: "వాహనం ప్రాధాన్యత",
      max_weight_pounds: "గరిష్ట బరువు (పౌండ్లలో)",
      max_volume_cft: "గరిష్ట వాల్యూమ్ (CFT)",
      monthly_min_planning_distance_miles:
        "నెలవారీ కనీస ప్రణాళిక దూరం (మైళ్లలో)",
      remaining_monthly_planning_distance_miles:
        "మిగిలిన నెలవారీ ప్రణాళిక దూరం (మైళ్లలో)",
      next_available_date_yyyy_mm_dd_hh_mm:
        "తదుపరి లభ్యమైన తేదీ (YYYY-MM-DD HH:mm)",
      vehicle_break_down: "వాహనం విరగడ",
      drop_location_code: "వదిలే స్థానం కోడ్",
      arrival_at_first_dealer: "మొదటి డీలర్ వద్ద చేరడం",
      departure_at_last_dealer: "చివరి డీలర్ వద్ద బయలుదేరడం",
      average_payload: "సగటు లోడ్ (టన్నులలో)",
      mileage: "మైలేజ్ (మైళ్ల/గ్యాలన్)",
      fuel_type: "ఇంధనం రకం",
      temperature_controlled: "ఉష్ణోగ్రత నియంత్రణ",
      load_characteristics: "లోడ్ లక్షణాలు",
      glec_vehicle_type: "GLEC వాహన రకం",
      return_to_plant: "ప్లాంట్‌కు తిరిగి",
      summary_dashboard: "సారాంశ డాష్‌బోర్డ్",
      dispatch_plan_details: "డిస్పాచ్ ప్లాన్ వివరాలు",
      invalid_orders_note: "గమనిక: చెల్లని ఆర్డర్లు చూపబడడం లేదు",
      pickup_point: "పికప్ పాయింట్",
      vehicle_type_configuration: "వాహన రకం కాన్ఫిగరేషన్",
      vehicle_type: "వాహన రకం",
      vehicle_type_name: "వాహన రకం పేరు",
      min_volume: "కనిష్ట పరిమాణం (CFT)",
      max_volume: "గరిష్ట పరిమాణం (CFT)",
      vehicle_priority: "వాహన ప్రాధాన్యత",
      min_weight: "కనిష్ట బరువు (పౌండ్లు)",
      max_weight: "గరిష్ట బరువు (పౌండ్లు)",
      max_distance_trip: "గరిష్ట ప్రయాణ దూరం (మైళ్ళు)",
      monthly_min_planning_distance: "నెలవారీ కనిష్ట ప్లానింగ్ దూరం (మైళ్ళు)",
      cancel: "రద్దు",
      save: "సేవ్ చేయండి",
      facility_name: "సౌకర్యం పేరు",
      vehicle_no: "వాహన సంఖ్య",
      address: "చిరునామా",
      last_packet_time: "చివరి ప్యాకెట్ సమయం",
      latitude: "అక్షాంశం",
      longitude: "రేఖాంశం",
      service_provider: "సర్వీస్ ప్రొవైడర్",
      temperature: "ఉష్ణోగ్రత",
      on_trip: "ప్రయాణంలో",
      requirement: "అవసరం",
      requirement_type: "అవసరం రకం",
      security: "భద్రత",
      assessed_by: "విశ్లేషించినవారు",
      security_conclusion: "భద్రతా తుది నిర్ణయం",
      security_comments: "భద్రతా వ్యాఖ్యలు",
      assessment_start_date: "విశ్లేషణ ప్రారంభ తేదీ",
      assessment_end_date: "విశ్లేషణ ముగింపు తేదీ",
      get_data: "డేటా పొందండి",
      add_requirements: "అవసరాలు చేర్చండి",
      add_vehicle_checklist: "వాహన తనిఖీ జాబితాను చేర్చండి",
      actions: "చర్యలు",
      edit: "సవరించు",
      delete: "తొలగించు",
      vehicle_configurations: "వాహన కాన్ఫిగరేషన్లు",
      vehicle_availability_configurations: "వాహన లభ్యత కాన్ఫిగరేషన్లు",
      vehicle_location: "వాహన స్థానం",
      vehicle_master: "వాహన మాస్టర్",
      vehicle_availability: "వాహన లభ్యత",
      vehicle_details: "వాహన వివరాలు",
      monthly_min_distance: "నెలవారీ కనిష్ట దూరం (మైళ్ళు)",
      max_weight_per_trip: "ప్రతి ప్రయాణానికి గరిష్ట బరువు (పౌండ్లు)",
      max_distance_per_trip: "ప్రతి ప్రయాణానికి గరిష్ట దూరం (మైళ్ళు)",
      planned_data: "ప్లాన్ చేయబడిన డేటా",
      vfr_percentage: "VFR శాతం",
      cool_chain_adherence: "కూల్ చైన్ అనుసరణ",
      transit_time: "ట్రాన్సిట్ సమయం",
      loading_time: "లోడింగ్ సమయం",
      unloading_time: "అన్‌లోడింగ్ సమయం",
      planned_summary: "ప్లాన్ చేసిన సారాంశం",
      invalid_drop_data: "చెల్లని డ్రాప్ డేటా",
      vehicle_utilization_km: "వాహన వినియోగం (KM)",
      distance_to_destination: "గమ్యం దూరం",
      expected_delivery_date: "అంచనా డెలివరీ తేదీ",
      trip_no: "ప్రయాణ సంఖ్య",
      total_quantity: "మొత్తం పరిమాణం",
      demand_volume_cft: "డిమాండ్ పరిమాణం (CFT)",
      weight_pounds: "బరువు (పౌండ్లు)",
      quantity: "పరిమాణం",
      dropped_reason: "డ్రాప్ చేసిన కారణం",
      delivery_date: "డెలివరీ తేదీ",
      drop_location_city: "డ్రాప్ స్థలం నగరం",
      planned_day: "ప్లాన్ చేయబడిన రోజు",
      vehicle_no: "వాహన సంఖ్య",
      drop_sequence: "డ్రాప్ క్రమం",
      invoicing_date: "ఇన్వాయ్సింగ్ తేదీ",
      pickup_location_source: "పికప్ స్థలం మూలం",
      destination_location_city: "గమ్యం స్థలం నగరం",
      destination_location_state: "గమ్యం స్థలం రాష్ట్రం",
      distance_to_destination_miles: "గమ్యం దూరం (మైళ్ళు)",
      time_to_destination: "గమ్యం సమయం",
      dealer_available_from: "డీలర్ అందుబాటులో ఉన్న సమయం నుండి",
      dealer_available_to: "డీలర్ అందుబాటులో ఉన్న సమయం వరకు",
      total_planned_weight: "మొత్తం ప్లాన్ చేసిన బరువు",
      total_planned_volume: "మొత్తం ప్లాన్ చేసిన పరిమాణం",
      arrival_at_first_dealer_start: "మొదటి డీలర్ వద్ద రాక (ప్రారంభం)",
      departure_at_last_dealer_end: "చివరి డీలర్ వద్ద ప్రస్థానం (ముగింపు)",
      return_to_plant_hh_mm: "ప్లాంట్‌కు తిరిగి (HH:MM)",
      total_planned_trip_time_hrs: "మొత్తం ప్లాన్ చేసిన ప్రయాణ సమయం (గంటలు)",
      total_round_trip_time_hrs: "మొత్తం రౌండ్ ట్రిప్ సమయం (గంటలు)",
      total_loading_time_hh_mm: "మొత్తం లోడింగ్ సమయం (HH:MM)",
      total_unloading_time_hh_mm: "మొత్తం అన్‌లోడింగ్ సమయం (HH:MM)",
      vehicle_utilization_percentage: "వాహన వినియోగ శాతం (%)",
      view_order_information: "ఆర్డర్ సమాచారాన్ని చూడండి",
      vehicle_configuration: "వాహన కాన్ఫిగరేషన్",
      summary: "సారాంశం",
      planned_summary: "ప్లాన్ చేసిన సారాంశం",
      planned_vehicles: "ప్లాన్ చేసిన వాహనాలు",
      unplanned_loads: "యోజనాబద్ధం కాని లోడ్లు",
      invalid_drop_data: "చెల్లని డెలివరీ డేటా",
      set_vehicle_priority: "వాహన ప్రాధాన్యతను స్థాపించండి",
      forecast_order_plan: "అంచనా ఆర్డర్ ప్లాన్",
      volume_cft: "పరిమాణం (CFT)",
      dealer_available_time_error:
        "ప్రారంభ సమయం ముగింపు సమయం కంటే తక్కువగా ఉండాలి",
      ok: "సరే",
      get_data: "డేటాను పొందండి",
      plan_history: "ప్లాన్ చరిత్ర",
      upload_file: "ఫైల్‌ను అప్‌లోడ్ చేయండి",
      save: "సేవ్ చేయండి",
      cancel: "రద్దు చేయండి",
      delete: "తొలగించు",
      plant_name: "ప్లాంట్ పేరు",
      total_trips: "మొత్తం ప్రయాణాలు",
      trips_edited: "సవరణ చేయబడిన ప్రయాణాలు",
      vfr_percentage: "VFR శాతం",
      total_transit_time_hh_mm: "మొత్తం పారవ్రుత సమయం (HH)",
      drop_location_name_destination: "డెలివరీ ప్రదేశం పేరు (గమ్యం)",
      unloading_time_hh_mm: "అన్‌లోడ్ సమయం (HH:MM)",
      edited_transaction_ids: "సవరించబడిన లావాదేవీ IDs",
      vehicle_type: "వాహన రకం",
      vehicle_name: "వాహన పేరు",
      weight_pounds: "బరువు (పౌండ్లు)",
      plant_code: "ప్లాంట్ కోడ్",
      plant_name: "ప్లాంట్ పేరు",
      total_trips: "మొత్తం ప్రయాణాలు",
      trips_edited: "సవరణ చేయబడిన ప్రయాణాలు",
      last_30_days_trips_modification_details:
        "గత 30 రోజులలో ప్రయాణాల సవరణ వివరాలు",
      last_7_days_trips_modification_details:
        "గత 7 రోజులలో ప్రయాణాల సవరణ వివరాలు",
      planning_progress_past_7_weeks: "గత 7 వారాలలో ప్రణాళిక పురోగతి",
      edited_transaction_ids: "సవరించబడిన లావాదేవీ IDs",
      trips_removed: "తొలగించబడిన ప్రయాణాలు",
      removed_transaction_ids: "తొలగించబడిన లావాదేవీ IDs",
      drop_location_city: "డెలివరీ ప్రదేశం నగరం",
      drop_location_name_destination: "డెలివరీ ప్రదేశం పేరు (గమ్యం)",
      note_blank_rows_columns_not_allowed:
        "గమనిక: ఖాళీ వరుసలు/కాలములు అనుమతించబడవు",
      check_blank_data_instructions:
        "ఖాళీ డేటా తనిఖీ చేయడానికి: 1. వెతకండి మరియు ఎంచుకోండి క్లిక్ చేయండి. 2. స్పెషల్‌కు వెళ్ళండి క్లిక్ చేయండి. 3. ఖాళీలు ఎంచుకోండి. 4. సరే క్లిక్ చేయండి, అన్ని ఖాళీ వరుసలు/సెల్‌లు హైలైట్ అవుతాయి. 5. హోమ్ ట్యాబ్‌లో సెల్‌ల విభాగంలో డిలీట్ ఎంచుకోండి. 6. షీట్ వరుసలను తొలగించండి క్లిక్ చేయండి.",
      disclaimer_dealer_window_ot_customers:
        "నిరాకరణ: OT వినియోగదారుల కోసం అందుబాటులో ఉన్న విండో ఇన్‌పుట్ ఫైల్ నుండి పరిగణనలోకి తీసుకోబడుతుంది, GT వినియోగదారుల షెడ్యూల్‌లు పికప్/డెలివరీ ప్రదేశాల నుండి పరిగణించబడతాయి",
      quantity: "పరిమాణం",
      specifications: "విశేషణలు",
      please_select_plan_id_to_compare_plans:
        "దయచేసి ప్లాన్‌లను సరిపోల్చడానికి ప్లాన్ IDను ఎంచుకోండి",
      vehicle_forecast_summary_dashboard: "వాహన అంచనా సారాంశం డాష్‌బోర్డు",
      pickup_point: "పికప్ పాయింట్",
      view_details: "వివరాలు వీక్షించండి",
      compare_plans: "ప్లాన్‌లను పోల్చండి",
      pickup_drop_points: "పికప్/డెలివరీ పాయింట్లు",
      edit: "సవరించు",
      pickup: "పికప్",
      drop: "డ్రాప్",
      both: "రెండు",
      pickup_location_code: "పికప్ ప్రదేశం కోడ్",
      dealer_type: "డీలర్ రకం",
      select: "ఎంచుకోండి",
      local: "లోకల్",
      upcountry: "పల్లె",
      vehicle_restriction_list: "వాహన పరిమితి జాబితా",
      week_off: "వారాంతం",
      csp_day: "CSP రోజు",
      is_customer_kdd: "కస్టమర్ KDDనా?",
      start_month: "ప్రారంభ నెల",
      end_month: "ముగింపు నెల",
      plan_id: "ప్లాన్ ID",
      fecha_creada: "తేదీ సృష్టించబడింది",
      no_of_trucks_planned: "ప్లాన్ చేసిన ట్రక్కుల సంఖ్య",
      total_planned_volume_cft: "మొత్తం ప్లాన్ చేసిన పరిమాణం (CFT)",
      total_planned_weight_pounds: "మొత్తం ప్లాన్ చేసిన బరువు (పౌండ్లు)",
      plan_run_by: "ప్లాన్ నడిపినవారు",
      planned_vehicles: "ప్లాన్ చేసిన వాహనాలు",
      trip_no: "ప్రయాణ సంఖ్య",
      total_planned_customers: "మొత్తం ప్లాన్ చేసిన వినియోగదారులు",
      total_round_trip_distance: "మొత్తం రౌండ్ ట్రిప్ దూరం",
      planning_percentage: "ప్లానింగ్ శాతం",
      max_dispatch_time: "గరిష్ట డిస్పాచ్ సమయం (HH:MM)",
      total_loading_time: "మొత్తం లోడింగ్ సమయం (HH:MM)",
      total_unloading_time: "మొత్తం అన్‌లోడింగ్ సమయం (HH:MM)",
      planned_summary: "ప్లాన్ చేసిన సారాంశం",
      invalid_orders_not_shown: "గమనిక: చెల్లని ఆర్డర్‌లు చూపించబడవు",
      no_of_customers: "వినియోగదారుల సంఖ్య",
      no_of_orders: "ఆర్డర్‌ల సంఖ్య",
      total_demand_volume: "మొత్తం డిమాండ్ పరిమాణం (CFT)",
      planned_summary: "ప్లాన్ చేసిన సారాంశం",
      trips_removed: "తొలగించబడిన ప్రయాణాలు",
      removed_transaction_ids: "తొలగించబడిన లావాదేవీ IDs",
      vehicle_forecast_configurations: "వాహన అంచనా కాన్ఫిగరేషన్లు",
      pickup_point: "పికప్ పాయింట్",
      vehicle_type: "వాహన రకం",
      vehicle_name: "వాహన పేరు",
      select_transporter_name: "రవాణాదారుని పేరు ఎంచుకోండి",
      zone: "జోన్",
      state: "రాష్ట్రం",
      driver_cost: "డ్రైవర్ ఖర్చు ($)",
      maintenance_cost: "నిర్వహణ ఖర్చు ($)",
      fixed_vehicle_cost_label: "వాహన స్థిర ఖర్చు ($)",
      delete: "తొలగించు",
      city: "నగరం",
      zone: "జోన్",
      delta_warehouse: "డెల్టా గిడ్డంగి",
      zones: "జోన్లు",
      zone_mapping: "జోన్ మాపింగ్",
      city_not_mapped_to_state: "ఈ నగరం ఏ రాష్ట్రానికి ఎంచుకోబడలేదు",
      actions: "చర్యలు",
      add_zones: "+ జోన్లను జోడించు",
      transporter_master: "రవాణాదారుల మాస్టర్",
      transporter_configurations: "రవాణాదారుల అమరికలు",
      transporter_operations: "రవాణాదారుల ఆపరేషన్స్",
      transporter_code: "రవాణాదారు కోడ్",
      transporter_name: "రవాణాదారు పేరు",
      actions: "చర్యలు",
      edit: "సవరించు",
      delete: "తొలగించు",
      zone_configuration: "జోన్ కాన్ఫిగరేషన్",
      vehicle_checklist_for_transportation_requirements:
        "రవాణా అవసరాలకు వాహన జాబితా",
      transporters_configuration: "రవాణాదారుల అమరికలు",
      consolidated_route_plan_screen: "సంయుక్త మార్గ ప్రణాళిక స్క్రీన్",
      plant_code: "ప్లాంట్ కోడ్",
      select_plant_to_check_vehicle_availability:
        "వాహన లభ్యతను తనిఖీ చేయడానికి ప్లాంట్ ఎంచుకోండి",
      vehicle_availability: "వాహన లభ్యత",
      note_trucks_near_plant_vicinity: "గమనిక: ప్లాంట్ సమీపంలో ఉన్న ట్రక్కులు ",
      facility_name: "సౌకర్యం పేరు",
      vehicle_number: "వాహనం సంఖ్య",
      transporter_code: "రవాణాదారు కోడ్",
      vehicle_status_dashboard: "వాహన స్థితి డాష్‌బోర్డ్",
      dc: "డీసీ",
      vehicles: "వాహనాలు",
      total_trucks: "మొత్తం ట్రక్కులు",
      active: "సక్రియంగా",
      cost_details: "ఖర్చు వివరాలు",
      fixed_vehicle_cost: "స్థిర వాహన ఖర్చు",
      variable_vehicle_cost: "వేరియబుల్ వాహన ఖర్చు",
      city_wise_vehicle_cost: "నగరానుసారం వాహన ఖర్చు",
      zone_wise_vehicle_cost: "జోన్ వారీ వాహన ఖర్చు",
      pickup_point: "పికప్ పాయింట్",
      vehicle_type: "వాహన రకం",
      transporter_name: "రవాణాదారు పేరు",
      cost_per_mile_below_sob: "సోబ్ కింద ప్రతి మైలు ఖర్చు",
      cost_per_mile_after_sob: "సోబ్ తరువాత ప్రతి మైలు ఖర్చు",
      validity_from: "చెల్లుబాటు నుండి",
      validity_to: "చెల్లుబాటు వరకు",
      add_vehicle_cost: "+ వాహన ఖర్చును జోడించు",
      freight_details: "సరుకు వివరాలు",
      select_transporter: "రవాణాదారుని ఎంచుకోండి",
      select_zone: "జోన్ ఎంచుకోండి",
      select_state: "రాష్ట్రం ఎంచుకోండి",
      city: "నగరం",
      cost: "ఖర్చు ($)",
      save: "సేవ్",
      cancel: "రద్దు చేయండి",
      actions: "చర్యలు",
      validity_from_label: "చెల్లుబాటు నుండి",
      validity_to_label: "చెల్లుబాటు వరకు",
      get_data: "డేటా పొందండి",
      fixed_vehicle_cost_label: "వాహన స్థిర ఖర్చు ($)",
      driver_cost: "డ్రైవర్ ఖర్చు ($)",
      maintenance_cost: "నిర్వహణ ఖర్చు ($)",
      city: "నగరం",
      zone: "జోన్",
      vehicle_cost: "వాహన ఖర్చు",
      freight_cost: "సరుకు ఖర్చు",
      inactive: "క్రియాశీలం లేనిది",
      inside_dc: "డీసీ లోపల",
      in_transit_to_customer: "వినియోగదారుని కి రవాణా లో",
      near_customer: "వినియోగదారుని సమీపంలో",
      return_to_dc: "డీసీ కి తిరిగి",
      empty_outside_dc: "డీసీ వెలుపల ఖాళీగా",
      zone_configurations: "జోన్ అమరికలు",
      transporters_configurations: "రవాణాదారుల అమరికలు",
      truck_no: "ట్రక్ సంఖ్య",
      facility_name: "సౌకర్యం పేరు",
      zones: "జోన్లు",
      actions: "చర్యలు",
      edit: "సవరించు",
      delete: "తొలగించు",
      add_zone_mapping: "జోన్ మ్యాపింగ్ జోడించు",
      current_status: "ప్రస్తుత స్థితి",
      next_availability: "తదుపరి లభ్యత",
      dc_location: "డీసీ లొకేషన్",
      add_route_restriction: "మార్గ నిరోధన జోడించు",
      location_code: "లొకేషన్ కోడ్",
      location_name: "లొకేషన్ పేరు",
      restricted_area: "నిరోధిత ప్రాంతం",
      vehicle_list: "వాహన జాబితా",
      cost_per_miles: "మైలు ప్రకారం ఖర్చు ($)",
      driver_break_time: "డ్రైవర్ విరామ సమయం",
      view: "చూడండి",
      please_select_no_of_vehicles: "దయచేసి వాహనాల సంఖ్యను ఎంచుకోండి",
      no_of_vehicles: "వాహనాల సంఖ్య",
      obd_analysis: "OBD విశ్లేషణ",
      pickup_point: "పికప్ పాయింట్",
      select_month: "నెలను ఎంచుకోండి",
      location_radius_miles: "స్థానం వ్యాసార్థం (మైళ్లు)",
      submit: "సమర్పించండి",
      total_obd_details: "మొత్తం OBD వివరాలు",
      valid_dealers: "చెల్లుబాటు అయ్యే డీలర్లు",
      invalid_dealers: "చెల్లుబాటు కాని డీలర్లు",
      volume: "వాల్యూమ్",
      help: "సహాయం",
      change_password: "పాస్వర్డ్ మార్చండి",
      logout: "లాగ్అవుట్",
      weight_pounds: "బరువు (పౌండ్లు)",
      quantity: "మొత్తం",
      obd_details_within_radius: "వ్యాసార్థంలో OBD వివరాలు",
      dealers: "డీలర్లు",
      dealers_percent: "డీలర్ల శాతం",
      volume_percent: "వాల్యూమ్ శాతం",
      weight_pounds_percent: "బరువు శాతం (పౌండ్లు)",
      quantity_percent: "మొత్తం శాతం",
      cost_per_miles: "మైలు ప్రకారం ఖర్చు ($)",
      driver_break_time_from: "డ్రైవర్ విరామ సమయం (నుంచి)",
      monthly_min_planning_distance_miles:
        "నెలవారీ కనిష్ట ప్రణాళిక దూరం (మైళ్లు)",
      vehicle_type_count: "వాహన రకాల సంఖ్య",
      driver_break_time_per_day: "రోజుకు డ్రైవర్ విరామ సమయం (HH:MM)",
      cost_per_miles_2: "మైలు ప్రకారం ఖర్చు ($)",
      set_vehicle_priority: "వాహన ప్రాధాన్యతను సెట్ చేయండి",
      vehicle_type_name: "వాహన రకం పేరు",
      min_volume_cft: "కనిష్ట వాల్యూమ్ (CFT)",
      max_volume_cft: "గరిష్ట వాల్యూమ్ (CFT)",
      max_distance_per_trip_miles: "ప్రతి ట్రిప్ గరిష్ట దూరం (మైళ్లు)",
      select_plan_id: "ప్లాన్ ID ఎంచుకోండి",
      drop_details: "డ్రాప్ వివరాలు",
      drop_location_code: "డ్రాప్ స్థలం కోడ్",
      vehicle_utilization: "వాహన వినియోగం",
      onward_trip_distance_miles: "ముందుకు ప్రయాణ దూరం (మైళ్లు)",
      round_trip_miles: "వెన్నిరుగు ప్రయాణం (మైళ్లు)",
      max_dispatch_time_hh_mm: "గరిష్ట డిస్పాచింగ్ సమయం (HH:MM)",
      arrival_at_first_dealer_start: "మొదటి డీలర్ వద్ద చేరుట (ప్రారంభం)",
      departure_at_last_dealer_end: "చివరి డీలర్ వద్ద నిష్క్రమణ (ముగింపు)",
      return_to_plant_hh_mm: "ప్లాంట్ కు తిరిగి (HH:MM)",
      vehicle_number: "వాహన సంఖ్య",
      vehicle_availability_from: "వాహన లభ్యత (నుంచి)",
      vehicle_availability_to: "వాహన లభ్యత (వరకు)",
      break_time_from: "విరామ సమయం (నుంచి)",
      break_time_to: "విరామ సమయం (వరకు)",
      no_of_vehicles: "వాహనాల సంఖ్య",
      driver_break_time_from: "డ్రైవర్ విరామ సమయం (నుంచి)",
      max_distance_miles: "గరిష్ట దూరం (మైళ్లు)",
      monthly_min_planning_distance_miles:
        "నెలవారీ కనిష్ట ప్రణాళిక దూరం (మైళ్లు)",
      total_planned_trip_time_hrs: "మొత్తం ప్రణాళిక చేసిన ప్రయాణ సమయం (గంటలు)",
      total_round_trip_time_hrs: "మొత్తం వెన్నిరుగు ప్రయాణ సమయం (గంటలు)",
      drop_location_code: "డ్రాప్ స్థలం కోడ్",
      drop_location_name: "డ్రాప్ స్థలం పేరు",
      drop_location_city: "డ్రాప్ స్థలం నగరం",
      order_number: "ఆర్డర్ సంఖ్య",
      delivery_date: "డెలివరీ తేదీ (M/D/YYYY)",
      back: "వెనక్కి",
      drop_location_name: "డ్రాప్ స్థలం పేరు",
      pickup_location: "పికప్ స్థలం",
      dealer_available_from: "డీలర్ లభ్యత (నుంచి)",
      dealer_available_to: "డీలర్ లభ్యత (వరకు)",
      total_planned_weight: "మొత్తం ప్రణాళిక చేసిన బరువు",
      total_planned_volume: "మొత్తం ప్రణాళిక చేసిన వాల్యూమ్",
      arrival_at_first_dealer: "మొదటి డీలర్ వద్ద చేరుట",
      departure_at_last_dealer: "చివరి డీలర్ వద్ద నిష్క్రమణ",
      return_to_plant: "ప్లాంట్ కు తిరిగి",
      summary_dashboard: "సారాంశ డాష్‌బోర్డ్",
      dispatch_plan_details: "డిస్పాచింగ్ ప్రణాళిక వివరాలు",
      invalid_orders_note: "గమనిక: చెల్లుబాటు కాని ఆర్డర్‌లు చూపబడడం లేదు",
      planned_data: "ప్రణాళిక చేసిన డేటా",
      vfr_percentage: "VFR శాతం",
      cool_chain_adherence: "కూల్ చైన్ అనుసరణ",
      transit_time: "ట్రాన్సిట్ సమయం",
      loading_time: "లోడింగ్ సమయం",
      unloading_time: "అన్‌లోడింగ్ సమయం",
      planned_summary: "ప్రణాళిక చేసిన సారాంశం",
      invalid_drop_data: "చెల్లుబాటు కాని డ్రాప్ డేటా",
      vehicle_utilization_km: "వాహన వినియోగం (KM)",
      distance_to_destination: "గమ్యం దూరం",
      expected_delivery_date: "అంచనా డెలివరీ తేదీ",
      planned_vehicles: "ప్రణాళిక చేసిన వాహనాలు",
      trip_no: "ప్రయాణ సంఖ్య",
      total_quantity: "మొత్తం పరిమాణం",
      dealer_available_to: "డీలర్ లభ్యత (వరకు)",
      drop_location_code_destination: "డ్రాప్ స్థలం కోడ్ (గమ్యం)",
      order_number: "ఆర్డర్ సంఖ్య",
      demand_volume_cft: "డిమాండ్ వాల్యూమ్ (CFT)",
      weight_pounds: "బరువు (పౌండ్లు)",
      quantity: "మొత్తం",
      dropped_reason: "వెదజల్లిన కారణం",
      delivery_date: "డెలివరీ తేదీ",
      drop_location_name: "డ్రాప్ లొకేషన్ పేరు",
      drop_location_city: "డ్రాప్ లొకేషన్ నగరం",
      trip_no: "యాత్ర సంఖ్య",
      planned_day: "ప్రణాళిక చేసిన రోజు",
      vehicle_no: "వాహనం సంఖ్య",
      vehicle_type: "వాహనం రకం",
      drop_sequence: "డ్రాప్ క్రమం",
      invoicing_date: "ఇన్వాయిసింగ్ తేదీ",
      pickup_location_source: "పికప్ స్థానం (మూలం)",
      destination_location_city: "గమ్యం లొకేషన్ నగరం",
      destination_location_state: "గమ్యం లొకేషన్ రాష్ట్రం",
      distance_to_destination_miles: "గమ్యానికి దూరం (మైళ్ళు)",
      expected_delivery_date: "అంచనా డెలివరీ తేదీ",
      time_to_destination: "గమ్యానికి సమయం",
      transit_time_hh_mm: "ట్రాన్సిట్ సమయం (HH:MM)",
      loading_time_hh_mm: "లోడింగ్ సమయం (HH:MM)",
      unloading_time_hh_mm: "అన్‌లోడింగ్ సమయం (HH:MM)",
      total_planned_customers: "మొత్తం ప్రణాళిక చేసిన కస్టమర్లు",
      total_round_trip_distance_km: "మొత్తం రౌండ్ ట్రిప్ దూరం (KM)",
      planning_percentage: "ప్రణాళిక శాతం",
      cool_chain_adherence_percentage: "కూల్ చైన్ అనుసరణ శాతం",
      vfr_percentage: "VFR శాతం",
      total_transit_time_hh_mm: "మొత్తం ట్రాన్సిట్ సమయం (HH:MM)",
      round_trip_miles: "రౌండ్ ట్రిప్ (మైళ్ళు)",
      total_planned_weight_pounds: "మొత్తం ప్రణాళిక చేసిన బరువు (పౌండ్లు)",
      total_planned_volume_cft: "మొత్తం ప్రణాళిక చేసిన వాల్యూమ్ (CFT)",
      arrival_at_first_dealer_start: "మొదటి డీలర్ వద్ద చేరడం (ప్రారంభం)",
      departure_at_last_dealer_end: "చివరి డీలర్ వద్ద బయలుదేరడం (ముగింపు)",
      return_to_plant_hh_mm: "ప్లాంట్ కి తిరిగి (HH:MM)",
      total_planned_trip_time_hrs: "మొత్తం ప్రణాళిక చేసిన యాత్ర సమయం (గంటలు)",
      total_round_trip_time_hrs: "మొత్తం రౌండ్ ట్రిప్ సమయం (గంటలు)",
      total_loading_time_hh_mm: "మొత్తం లోడింగ్ సమయం (HH:MM)",
      total_unloading_time_hh_mm: "మొత్తం అన్‌లోడింగ్ సమయం (HH:MM)",
      vehicle_utilization_percentage: "వాహన వినియోగ శాతం (%)",
      view_order_information: "ఆర్డర్ సమాచారం చూడండి",
      vehicle_configuration: "వాహన కాన్ఫిగరేషన్",
      summary: "సారాంశం",
      planned_summary: "ప్రణాళిక చేసిన సారాంశం",
      planned_vehicles: "ప్రణాళిక చేసిన వాహనాలు",
      unplanned_loads: "ప్రణాళిక లేని లోడ్లు",
      invalid_drop_data: "చెల్లని డ్రాప్ డేటా",
      vehicle_utilization_km: "వాహన వినియోగం (KM)",
      planned_data: "ప్రణాళిక చేసిన డేటా",
      invalid_orders_note: "గమనిక: చెల్లని ఆర్డర్లు చూపబడటం లేదు",
      set_vehicle_priority: "వాహన ప్రాధాన్యతను సెట్ చేయండి",
      vehicle_min_weight_pounds: "వాహన కనిష్ట బరువు (పౌండ్లు)",
      vehicle_max_weight_pounds: "వాహన గరిష్ట బరువు (పౌండ్లు)",
      vehicle_min_volume_cft: "వాహన కనిష్ట వాల్యూమ్ (CFT)",
      vehicle_max_volume_cft: "వాహన గరిష్ట వాల్యూమ్ (CFT)",
      max_distance_miles: "గరిష్ట దూరం (మైళ్ళు)",
      vehicle_priority: "వాహన ప్రాధాన్యత",
      actions: "చర్యలు",
      forecast_order_plan: "ఆర్డర్ ప్లాన్ అనుమానం",
      drop_details: "డ్రాప్ వివరాలు",
      month: "నెల",
      year: "సంవత్సరం",
      total_quantity: "మొత్తం పరిమాణం",
      total_weight_pounds: "మొత్తం బరువు (పౌండ్లు)",
      total_demand_volume_cft: "మొత్తం డిమాండ్ వాల్యూమ్ (CFT)",
      user: "వినియోగదారు",
      uploaded_date: "అప్‌లోడ్ చేసిన తేదీ",
      view_order_details: "ఆర్డర్ వివరాలు చూడండి",
      pick_up_location_code: "పికప్ లొకేషన్ కోడ్",
      quantity: "పరిమాణం",
      drop_location: "డ్రాప్ లొకేషన్",
      drop_location_name: "డ్రాప్ లొకేషన్ పేరు",
      weight: "బరువు",
      volume: "వాల్యూమ్",
      delivery_date: "డెలివరీ తేదీ",
      invoice_date: "ఇన్వాయిస్ తేదీ",
      order_no: "ఆర్డర్ సంఖ్య",
      available_start_time: "అందుబాటులో ప్రారంభ సమయం",
      available_end_time: "అందుబాటులో ముగింపు సమయం",
      upload_input_file: "ఇన్‌పుట్ ఫైల్‌ను అప్‌లోడ్ చేయండి",
      download_sample_template: "నమూనా టెంప్లేట్‌ను డౌన్‌లోడ్ చేయండి",
      select_month: "నెలను ఎంచుకోండి",
      upload_file: "ఫైల్ అప్‌లోడ్ చేయండి",
      save: "సేవ్",
      cancel: "రద్దు చేయండి",
      note_blank_rows_columns_not_allowed:
        "గమనిక: ఖాళీ వరుసలు/కాలమ్స్ అనుమతించబడవు",
      no_of_vehicles: "వాహనాల సంఖ్య",
      run_plan: "ప్లాన్ అమలు చేయండి",
      vehicle_types: "వాహన రకాల",
      upload_file: "ఫైల్ అప్‌లోడ్ చేయండి",
      pickup_point: "పికప్ పాయింట్",
      consolidated_route_plan_screen: "సంయుక్త మార్గ ప్రణాళిక స్క్రీన్",
      start_date: "ప్రారంభ తేదీ",
      end_date: "ముగింపు తేదీ",
      get_data: "డేటా పొందండి",
      recent_plans: "సమీప ప్రణాళికలు",
      plan_history: "ప్లాన్ చరిత్ర",
      view_input: "ఇన్‌పుట్ చూడండి",
      consolidation_id: "కన్సాలిడేషన్ ID",
      created_date: "తేదీ సృష్టించబడింది",
      run_by: "ద్వారా అమలుచేయబడింది",
      no_of_executions: "అమలు చేసిన సంఖ్య",
      execution_status: "నిర్వహణ స్థితి",
      download_output: "ఫలితాన్ని డౌన్‌లోడ్ చేయండి",
      upload_input_file: "ఇన్పుట్ ఫైల్‌ని అప్‌లోడ్ చేయండి",
      choose_file: "ఫైల్‌ను ఎంచుకోండి",
      discard_obd: "OBD ను విస్మరించండి",
      upload_obd_details: "OBD వివరాలను అప్‌లోడ్ చేయండి",
      upload_file: "ఫైల్‌ని అప్‌లోడ్ చేయండి",
      submit: "సమర్పించు",
      download_template: "టెంప్లేట్‌ని డౌన్‌లోడ్ చేయండి",
      bulk_upload_locations: "స్థానాలను భారీగా అప్‌లోడ్ చేయండి",
      add_pickup_drop_points: "+ఎత్తుకురావడం/డ్రాప్ పాయింట్లను జోడించండి",
      details: "వివరాలు",
      date: "తేదీ",
      total_trips: "మొత్తం ప్రయాణాలు",
      trips_edited: "మార్చబడిన ప్రయాణాలు",
      trips_removed: "తొలగించిన ప్రయాణాలు",
      trips_edit_percentage: "మార్చబడిన ప్రయాణాల శాతం",
      plans_overview_dashboard: "ప్లాన్‌ల అవలోకనం డాష్‌బోర్డ్",
      pickup_point: "పిక్-అప్ పాయింట్",
      location_type: "స్థాన రకం",
      location_code: "స్థాన కోడ్",
      location_name: "స్థాన పేరు",
      location_city: "స్థాన నగరం",
      zone: "జోన్",
      customer_type: "కస్టమర్ రకం",
      location_latitude: "స్థాన లాటిట్యూడ్",
      location_longitude: "స్థాన లాంగిట్యూడ్",
      standard_transit_time: "ప్రామాణిక ట్రాన్సిట్ సమయం (రోజులు)",
      distance_from_plant: "ప్లాంట్ నుండి దూరం (మైళ్లలో)",
      delta_warehouse: "డెల్టా గిడ్డంగి",
      home: "హోమ్",
      run_new_plan: "కొత్త ప్లాన్‌ను అమలు చేయండి",
      stacking: "స్టాకింగ్",
      production_planning: "ఉత్పత్తి ప్రణాళిక",
      forecasting: "పూర్వానుమానం",
      resource_scheduling: "వనరుల షెడ్యూలింగ్",
      general: "సాధారణ",
      pickup_drop_locations: "పిక్-అప్/డ్రాప్ స్థానాలు",
      vehicle_configurations: "వాహన కాన్ఫిగరేషన్లు",
      vehicle_availability: "వాహన లభ్యత",
      zone_mapping: "జోన్ మ్యాపింగ్",
      transporters: "రవాణాదారులు",
      material_configurations: "పదార్థాల కాన్ఫిగరేషన్లు",
      freight_configurations: "రవాణా కాన్ఫిగరేషన్లు",
      route_configurations: "మార్గం కాన్ఫిగరేషన్లు",
      auto_clustering: "ఆటో క్లస్టరింగ్",
      transporter_share_of_business: "రవాణాదారుల వ్యాపార భాగస్వామ్యం",
      dispatch_plan_summary: "డిస్పాచ్ ప్లాన్ సారాంశం",
      kpi_dashboard: "KPI డాష్‌బోర్డ్",
      planning_tools: "ప్లానింగ్ సాధనాలు",
      forecast_tools: "పూర్వానుమాన సాధనాలు",
      suppliers: "సరఫరాదారులు",
      customers: "కస్టమర్లు",
      materials: "పదార్థాలు",
      input_data: "ఇన్పుట్ డేటా",
      forecast: "పూర్వానుమానం",
      previous_plan: "మునుపటి ప్లాన్",
      trips: "ప్రయాణాలు",
      gps_quality: "GPS నాణ్యత",
      truck_gps_data: "ట్రక్ GPS డేటా",
      unscheduled_stoppages: "షెడ్యూల్ చేయని నిలిపివేతలు",
      deviation_reports: "విచలనం నివేదికలు",
      fo_data: "FO డేటా",
      vehicle_checklist: "వాహన చెక్లిస్ట్",
      vehicle_status: "వాహన స్థితి",
      route_restrictions: "మార్గ పరిమితులు",
      consolidated_run_plan: "కన్సాలిడేటెడ్ రన్ ప్లాన్",
      plans_overview: "ప్లాన్‌ల అవలోకనం",
      input_plan_summary: "ఇన్పుట్ ప్లాన్ సారాంశం",
      load_plan_summary: "లోడ్ ప్లాన్ సారాంశం",
      plant_wise_configurations: "ప్లాంట్ వారీగా కాన్ఫిగరేషన్లు",
      vehicle_configuration: "వాహన కాన్ఫిగరేషన్",
      monthly_data: "నెలవారీ డేటా",
      forecast_plan_history: "పూర్వానుమానం ప్లాన్ చరిత్ర",
      obd_analysis: "OBD విశ్లేషణ",
      pickup_location_source: "పిక్-అప్ స్థానము (మూలం)",
      drop_location_destination: "డ్రాప్ స్థానము (గమ్యం)",
      order_number: "ఆర్డర్ నంబర్",
      invoicing_date: "బిల్లింగ్ తేదీ (M/D/AAAA)",
      quantity: "పరిమాణం",
      dealer_available_time_from: "డీలర్ అందుబాటులో సమయం (00:00 నుండి)",
      dealer_available_time_to: "డీలర్ అందుబాటులో సమయం (23:59 వరకు)",
      volume_cft: "వాల్యూమ్ (CFT)",
      weight_pounds: "బరువు (పౌండ్లలో)",
      delivery_date: "డెలివరీ తేదీ (M/D/AAAA)",
      no_rows_to_show: "చూపించడానికి ఎటువంటి పంక్తులు లేవు",
      rows: "పంక్తులు",
      total_rows: "మొత్తం పంక్తులు",
      add_dispatch: "డిస్పాచ్ జోడించండి",
      run_new_plan_by_file: "ఫైల్ ద్వారా కొత్త ప్లాన్ అమలు చేయండి",
      submit_run_plan: "ప్లాన్‌ను సమర్పించండి మరియు అమలు చేయండి",
      delete: "తొలగించు",
      download_sample_template: "సాంపిల్ టెంప్లేట్‌ని డౌన్‌లోడ్ చేయండి",
      save: "సేవ్",
      cancel: "రద్దు చేయండి",
      dealer_available_time_error:
        "ప్రారంభ సమయం ముగింపు సమయానికి తక్కువగా ఉండాలి",
      ok: "అలాగే",
      summary_dashboard: "సారాంశ డాష్‌బోర్డ్",
      pickup_point: "పికప్ పాయింట్",
      date_created: "సృష్టించిన తేదీ",
      get_data: "డేటా పొందండి",
      plan_details: "ప్లాన్ వివరాలు",
      plan_history: "ప్లాన్ చరిత్ర",
      drop_details: "డ్రాప్ వివరాలు",
      generate_plan: "ప్లాన్ సృష్టించండి",
      order_id: "ఆర్డర్ ID",
      no_of_runs: "ఎక్కువ పరిమాణం",
      created_on: "సృష్టించబడినది",
      plan_run_by: "ప్లాన్ అమలు చేసినవారు",
      discard_obd: "OBDను విస్మరించు",
      upload_obd_details: "OBD వివరాలను అప్‌లోడ్ చేయండి",
      download_template: "టెంప్లేట్‌ను డౌన్‌లోడ్ చేయండి",
      choose_file: "ఫైల్‌ను ఎంచుకోండి",
      submit: "సబ్మిట్ చేయండి",
      start_date: "ప్రారంభ తేదీ",
      end_date: "ముగింపు తేదీ",
      get_data: "డేటా పొందండి",
      upload_input_file: "ఇన్పుట్ ఫైల్ అప్‌లోడ్ చేయండి",
      discard_obd: "OBDను విస్మరించు",
      recent_plans: "ఇటీవల చేసిన ప్లాన్స్",
      plan_history: "ప్లాన్ చరిత్ర",
      view_input: "ఇన్పుట్ చూడండి",
      consolidation_id: "కన్సాలిడేషన్ ID",
      created_date: "సృష్టించిన తేదీ",
      run_by: "చేయబడినది",
      no_of_executions: "ఎన్నికలు",
      execution_status: "అమలు స్థితి",
      download_output: "అవుట్‌పుట్ డౌన్‌లోడ్ చేయండి",
      upload_obd_details: "OBD వివరాలను అప్‌లోడ్ చేయండి",
      download_template: "టెంప్లేట్‌ను డౌన్‌లోడ్ చేయండి",
      choose_file: "ఫైల్ ఎంచుకోండి",
      submit: "సబ్మిట్ చేయండి",
      simulation_time: "సిమ్యులేషన్ సమయం",
      enable_dc_week_off_planning: "DC వారపు సెలవు ప్లానింగ్ ప్రారంభించండి",
      run_plan: "ప్లాన్ అమలు చేయండి",
      cancel: "రద్దు",
      plan_details: "ప్లాన్ వివరాలు",
      pickup_point: "పికప్ పాయింట్",
      date_created: "సృష్టించిన తేదీ",
      order_id: "ఆర్డర్ ID",
      no_of_runs: "ఎన్నికలు",
      fix_and_reupload: "దిద్దండి మరియు మళ్లీ అప్‌లోడ్ చేయండి",
      club_previous_order: "మునుపటి ఆర్డర్‌ను క్లబ్ చేయండి",
      ignore: "విస్మరించు",
      dealer_code: "డీలర్ కోడ్",
      remarks: "వ్యాఖ్యలు",
      run_new_plan: "కొత్త ప్లాన్ అమలు చేయండి",
      consolidated_route_plan_screen: "కన్సాలిడేట్ రూట్ ప్లాన్ స్క్రీన్",
      plant_code: "ప్లాంట్ కోడ్",
      plant_name: "ప్లాంట్ పేరు",
      total_trips: "మొత్తం ప్రయాణాలు",
      trips_edited: "సవరించిన ప్రయాణాలు",
      edited_transaction_ids: "సవరించిన ట్రాన్సాక్షన్ IDలు",
      vehicle_type: "వాహన రకం",
      vehicle_name: "వాహన పేరు",
      weight_pounds: "బరువు (పౌండ్లు)",
      drop_location_city: "డ్రాప్ లొకేషన్ నగరం",
      quantity: "పరిమాణం",
      planned_vehicles: "ప్లాన్ చేసిన వాహనాలు",
      trip_no: "ప్రయాణ సంఖ్య",
      total_planned_customers: "మొత్తం ప్లాన్ చేసిన కస్టమర్లు",
      total_round_trip_distance: "మొత్తం రౌండ్ ట్రిప్ దూరం",
      planning_percentage: "ప్లానింగ్ శాతం",
      max_dispatch_time: "గరిష్ట డిస్పాచ్ సమయం (HH:MM)",
      total_loading_time: "మొత్తం లోడింగ్ సమయం (HH:MM)",
      total_unloading_time: "మొత్తం అన్‌లోడింగ్ సమయం (HH:MM)",
      planned_summary: "ప్లాన్ చేసిన సారాంశం",
      invalid_orders_not_shown: "గమనిక: చెల్లని ఆర్డర్లు చూపబడడం లేదు",
      no_of_customers: "కస్టమర్ల సంఖ్య",
      no_of_orders: "ఆర్డర్ల సంఖ్య",
      total_demand_volume: "మొత్తం డిమాండ్ వాల్యూం (CFT)",
      planned_summary: "ప్లాన్ చేసిన సారాంశం",
      trips_removed: "తొలగించిన ప్రయాణాలు",
      removed_transaction_ids: "తొలగించిన ట్రాన్సాక్షన్ IDలు",
      vehicle_forecast_configurations: "వాహన ఫోర్కాస్ట్ కాన్ఫిగరేషన్లు",
      pickup_point: "పికప్ పాయింట్",
      vehicle_type: "వాహన రకం",
      vehicle_name: "వాహన పేరు",
      cost_per_miles: "మైలు కి ఖర్చు ($)",
      driver_break_time: "డ్రైవర్ బ్రేక్ సమయం",
      view: "చూడండి",
      please_select_no_of_vehicles: "దయచేసి వాహనాల సంఖ్యను ఎంచుకోండి",
      no_of_vehicles: "వాహనాల సంఖ్య",
      cost_per_miles: "మైళ్లకు ఖర్చు ($)",
      driver_break_time_from: "డ్రైవర్ బ్రేక్ సమయం (తదుపరి)",
      monthly_min_planning_distance_miles:
        "నెలవారీ కనిష్ట ప్లానింగ్ దూరం (మైళ్ళు)",
      vehicle_type_count: "వాహన రకాల సంఖ్య",
      driver_break_time_per_day: "రోజుకి డ్రైవర్ బ్రేక్ సమయం (HH:MM)",
      cost_per_miles_2: "మైళ్లకు ఖర్చు ($)",
      set_vehicle_priority: "వాహన ప్రాధాన్యతను స్థాపించండి",
      vehicle_type_name: "వాహన రకం పేరు",
      min_volume_cft: "కనిష్ట పరిమాణం (CFT)",
      max_volume_cft: "గరిష్ట పరిమాణం (CFT)",
      max_distance_per_trip_miles: "ప్రతి ప్రయాణానికి గరిష్ట దూరం (మైల్స్)",
      select_plan_id: "ప్లాన్ IDని ఎంచుకోండి",
      drop_details: "డెలివరీ వివరాలు",
      drop_location_code: "డెలివరీ ప్రదేశం కోడ్",
      drop_location_name: "డెలివరీ ప్రదేశం పేరు",
      pickup_location: "పికప్ ప్రదేశం",
      dealer_available_from: "డీలర్ అందుబాటులో ఉన్నది నుండి",
      dealer_available_to: "డీలర్ అందుబాటులో ఉన్నది వరకు",
      total_planned_weight: "మొత్తం ప్లాన్ చేసిన బరువు",
      total_planned_volume: "మొత్తం ప్లాన్ చేసిన పరిమాణం",
      arrival_at_first_dealer: "మొదటి డీలర్ వద్ద చేరిక",
      departure_at_last_dealer: "చివరి డీలర్ వద్ద నుండి బయలుదేరు",
      return_to_plant: "ప్లాంట్‌కు తిరిగి వెళ్లండి",
      summary_dashboard: "సారాంశం డాష్‌బోర్డు",
      dispatch_plan_details: "డిస్పాచ్ ప్లాన్ వివరాలు",
      invalid_orders_note: "గమనిక: చెల్లని ఆర్డర్‌లు చూపబడడం లేదు",
      planned_data: "ప్లాన్ చేసిన డేటా",
      vfr_percentage: "VFR శాతం",
      cool_chain_adherence: "కూల్ చైన్ కట్టుబాటు",
      transit_time: "పారవ్రుత సమయం",
      loading_time: "లోడింగ్ సమయం",
      unloading_time: "అన్‌లోడ్ సమయం",
      planned_summary: "ప్లాన్ చేసిన సారాంశం",
      invalid_drop_data: "చెల్లని డెలివరీ డేటా",
      vehicle_utilization_km: "వాహన వినియోగం (KM)",
      distance_to_destination: "గమ్యం వరకు దూరం",
      expected_delivery_date: "అంచనా డెలివరీ తేదీ",
      planned_vehicles: "ప్లాన్ చేసిన వాహనాలు",
      trip_no: "ప్రయాణ సంఖ్య",
      total_quantity: "మొత్తం పరిమాణం",
      dealer_available_to: "డీలర్ అందుబాటులో ఉన్నది వరకు",
      drop_location_code_destination: "డెలివరీ ప్రదేశం కోడ్ (గమ్యం)",
      order_number: "ఆర్డర్ సంఖ్య",
      demand_volume_cft: "డిమాండ్ పరిమాణం (CFT)",
      weight_pounds: "బరువు (పౌండ్లు)",
      quantity: "పరిమాణం",
      dropped_reason: "తొలగించబడిన కారణం",
      delivery_date: "డెలివరీ తేదీ",
      drop_location_name: "డెలివరీ ప్రదేశం పేరు",
      drop_location_city: "డెలివరీ ప్రదేశం నగరం",
      trip_no: "ప్రయాణ సంఖ్య",
      planned_day: "ప్లాన్ చేసిన రోజు",
      vehicle_no: "వాహన సంఖ్య",
      vehicle_type: "వాహన రకం",
      drop_sequence: "డెలివరీ క్రమం",
      invoicing_date: "ఇన్వాయిస్ తేదీ",
      pickup_location_source: "పికప్ ప్రదేశం (మూలం)",
      destination_location_city: "గమ్యం నగరం",
      destination_location_state: "గమ్యం రాష్ట్రం",
      distance_to_destination_miles: "గమ్యం వరకు దూరం (మైల్స్)",
      expected_delivery_date: "అంచనా డెలివరీ తేదీ",
      time_to_destination: "గమ్యం వరకు సమయం",
      invalid_material: "చెల్లని పదార్థం",
      drop_location_code_destination: "డెలివరీ ప్రదేశం కోడ్ (గమ్యం)",
      demand_volume_cft: "డిమాండ్ పరిమాణం (CFT)",
      dropped_reason: "తొలగించబడిన కారణం",
      dealer_location_data_not_in_system: "డీలర్ ప్రదేశం డేటా సిస్టమ్‌లో లేదు",
      delivery_date_less_than_current: "డెలివరీ తేదీ ప్రస్తుత తేదీ కంటే తక్కువ",
      missing_dealer_information: "డీలర్ సమాచారం లేదు",
      transit_time_hh_mm: "పారవ్రుత సమయం (HH:MM)",
      loading_time_hh_mm: "లోడింగ్ సమయం (HH:MM)",
      unloading_time_hh_mm: "అన్‌లోడ్ సమయం (HH:MM)",
      actions: "చర్యలు",
      expected_delivery_date: "అంచనా డెలివరీ తేదీ",
      max_dispatch_time: "గరిష్ట డిస్పాచ్ సమయం (HH:MM)",
      unplanned_loads: "యోజనాబద్ధం కాని లోడ్లు",
      monthly_data: "నెలవారీ డేటా",
      forecast_plan_history: "అంచనా ప్లాన్ చరిత్ర",
      obd_analysis: "OBD విశ్లేషణ",
      dealer_available_time_error:
        "ప్రారంభ సమయం ముగింపు సమయం కంటే తక్కువగా ఉండాలి",
    },
  },
  hi: {
    translation: {
      vehicles_recommended: "अनुशंसित वाहन",
      "Quantity": "मात्रा",
      "Day-2": "दिन-2",
  "Day-3": "दिन-3",
  "Day-4": "दिन-4",
  "Day-5": "दिन-5",
  "Day-6": "दिन-6",
  "Distance / Time From Pickup Location": "पिकअप स्थान से दूरी / समय",
  "Distance / Time From Previous Drop Point": "पिछले ड्रॉप पॉइंट से दूरी / समय",
  "Location City": "स्थान शहर",
  "Planned Day": "योजना बनाई गई दिन",
  "Quantity": "मात्रा",
  "Demand Weight": "मांग वजन",
  "Demand Volume": "मांग मात्रा",
  "Location": "स्थान",
  "Weight": "वजन",
  "View Onward Route": "आगे का मार्ग देखें",
  "View Return Route": "वापसी का मार्ग देखें",
  "Weight": "वज़न",
  "Spot": "स्पॉट",
  "Local": "स्थानीय",
  "Upcountry": "ग्रामीण",
  "Volume": "आयतन",
      "Please Select Scenario to Compare Plans": "योजनाओं की तुलना के लिए कृपया परिदृश्य चुनें",
"Percentage": "प्रतिशत",
"Number Of Trips": "यात्राओं की संख्या",
  "Total": "कुल",
  "IN416": "IN416",
  "IN436": "IN436",
  "Spot": "स्पॉट",
  "2,177": "2,177",
  "1,268": "1,268",
  "909": "909",
  "0": "0",
  "Planned Truck Load": "योजनाबद्ध ट्रक लोड",
  "Quantity (cases)": "मात्रा (मामले)",
  "Weight (Kgs)": "वजन (किग्रा)",
  "Volume (CMT)": "आयतन (सीएमटी)",
  "1,81,970": "1,81,970",
  "25,25,554": "25,25,554",
  "8,249": "8,249",
  "Unplanned Load": "अनियोजित लोड",
  "26,324": "26,324",
  "3,74,549": "3,74,549",
  "1,234": "1,234",
  "Average Distance Travelled Per Vehicle (KM)": "प्रति वाहन यात्रा की गई औसत दूरी (किमी)",
  "35,426": "35,426",
  "37,624": "37,624",
  "28,111": "28,111",
  "Operational Cost (Rs.)": "संचालन लागत (रु)",
  "1,62,57,137": "1,62,57,137",
  "1,31,64,341": "1,31,64,341",
  "30,92,797": "30,92,797",
  "Average Vehicle Fill Rate": "औसत वाहन भरण दर",
  "60 %": "60 %",
  "42 %": "42 %",
  "79 %": "79 %",
  "Unplanned Load Proportion": "अनियोजित लोड अनुपात",
  "12 %": "12 %",
  "Average Vehicle Utilization As Per SOB": "SOB के अनुसार औसत वाहन उपयोग",
  "104 %": "104 %",
  "94 %": "94 %",
  "Ontime Dispatches": "समय पर प्रेषण",
  "Local": "स्थानीय",
  "frefe": "फ्रेफे",
  "94 %": "94 %",
  "92 %": "92 %",
  "Ontime Deliveries": "समय पर डिलीवरी",
  "97 %": "97 %",
  "94 %": "94 %",
  "forecast_scenarios" :"पूर्वानुमान परिदृश्य",
"Forecast Scenario": "पूर्वानुमान परिदृश्य",
      "total_planned_volume_m3": "कुल नियोजित मात्रा (M3)",
"total_planned_weight": "कुल नियोजित वजन",
"total_planned_weight_kgs": "कुल नियोजित वजन (किलोग्राम)",
"planning_scenarios": "योजना परिदृश्य",
      "scenario_name": "परिदृश्य नाम",
      "vehicle_max_weight_kgs": "वाहन का अधिकतम वजन (किलोग्राम)",
"vehicle_min_volume_m3": "वाहन का न्यूनतम आयतन (M3)",
"vehicle_max_volume_m3": "वाहन का अधिकतम आयतन (M3)",
"max_distance_kms": "अधिकतम दूरी (किमी)",
"monthly_min_planning_distance_kms": "मासिक न्यूनतम योजना दूरी (किमी)",
"generated_on": "पर उत्पन्न",
      "Number of kms Travelled": "यात्रा की गई किलोमीटर संख्या",
"Cost": "लागत",
"Vehicle Fill Rate": "वाहन भरने की दर",
"Vehicle KM Run %": "वाहन किलोमीटर रन %",
      "forecast_input_information": "पूर्वानुमान इनपुट जानकारी",
      "what_if_demand_fluctuation": "क्या होगा यदि : मांग में उतार-चढ़ाव",
"increase_decrease_demand_by": "मांग को बढ़ाएं/घटाएं",
"what_if_customer_priority_changes": "क्या होगा यदि : ग्राहक प्राथमिकता में परिवर्तन",
"prioritize_upcountry": "अपकंट्री को प्राथमिकता दें",
"prioritize_local": "स्थानीय को प्राथमिकता दें",
"what_if_service_level_target_changes": "क्या होगा यदि : सेवा स्तर लक्ष्य में परिवर्तन",
"set_service_level_target_to": "सेवा स्तर लक्ष्य को सेट करें",
"what_if_vehicle_fill_rate_changes": "क्या होगा यदि : वाहन भरने की दर में परिवर्तन",
"set_vehicle_fill_rate_to": "वाहन भरने की दर को सेट करें",
"what_if_customer_specific_delivery_time": "क्या होगा यदि : ग्राहक विशिष्ट डिलीवरी समय",
"increase_decrease_delivery_time_window_by": "डिलीवरी समय विंडो को बढ़ाएं/घटाएं",
"scenario_description": "परिदृश्य विवरण",
"total_weight_kgs": "कुल वजन (किलोग्राम)",
"uploading_data_for_plant": "प्लांट के लिए डेटा अपलोड किया जा रहा है",
"general_settings": "सामान्य सेटिंग्स",
"Planning Configurations": "योजना कॉन्फ़िगरेशन",
"Planning Scenario": "योजना परिदृश्य",
"planning_optimization_settings": "योजना / अनुकूलन सेटिंग्स",
"vehicle_fill_rate_vfr_settings": "वाहन भरने की दर (VFR) सेटिंग्स",
"load_planning_settings": "लोड योजना सेटिंग्स",
"planning_configurations": "योजना कॉन्फ़िगरेशन",
"planning_scenario": "योजना परिदृश्य",
      add_transporter_details: "+ परिवहनकर्ता विवरण जोड़ें",
      "Please Select File": "कृपया फ़ाइल चुनें",
"Processing Plan. Please wait.....": "योजना संसाधित हो रही है। कृपया प्रतीक्षा करें.....",
"File is processing": "फ़ाइल संसाधित हो रही है",
"Enter Input Data To Continue": "जारी रखने के लिए इनपुट डेटा दर्ज करें",
"No Data": "कोई डेटा नहीं",
"please_select_date": "कृपया तारीख चुनें",
"data_uploaded": "डेटा अपलोड किया गया",
"Please Select No of Vehicles": "कृपया वाहनों की संख्या चुनें",
"Please Input Valid Data": "कृपया मान्य डेटा दर्ज करें",
      import_existing_vehicle_details: "मौजूदा वाहन विवरण आयात करें",
      add_vehicle_type: "वाहन प्रकार जोड़ें",
      "driver_break_time_to": "ड्राइवर ब्रेक समय तक",
"max_seating_capacity": "अधिकतम बैठने की क्षमता",
"min_seating_capacity": "न्यूनतम बैठने की क्षमता",
"vehicle_seating_capacity": "वाहन की बैठने की क्षमता",
"vehicle_capacity_cmt": "वाहन क्षमता (घन मीटर)",
      "change_password": "पासवर्ड बदलें",
  "new_password": "नया पासवर्ड",
  "confirm_new_password": "नया पासवर्ड पुष्टि करें",
  "old_password": "पुराना पासवर्ड",
  "enter_email": "अपना ईमेल दर्ज करें",
  "enter_new_password": "अपना नया पासवर्ड दर्ज करें",
  "confirm_new_password_entry": "अपना नया पासवर्ड पुष्टि करें",
      vehicle_availability_configurations: "वाहन उपलब्धता विन्यास",
      max_distance_per_plan_miles: "प्रति योजना अधिकतम दूरी (मील)",
    add_city_wise_cost: "शहर के अनुसार लागत जोड़ें",
    
      "loading....": "लोड हो रहा है....",
      "select_cluster": "क्लस्टर चुनें",
      "Overall Cluster Wise Vehicles": "कुल क्लस्टर वार वाहन",
      "Discard": "त्यागें",
      "obd": "ओबीडी",
      "Dispatch Planning is in progress..!!!": "डिस्पैच योजना जारी है..!!!",
      "Estimated Time": "अनुमानित समय",
      "GPS": "जीपीएस",
      "Spot Vehicles To Be Planned": "योजना के लिए स्पॉट वाहन",
      "No Of Spot Vehicles": "स्पॉट वाहनों की संख्या",
      "Availability DateTime": "उपलब्धता दिनांक और समय",
      "Notify Email Users After Process (comma seperated)": "प्रक्रिया के बाद ईमेल उपयोगकर्ताओं को सूचित करें (अल्पविराम द्वारा विभाजित)",
      "Max Time Limit(Hrs)": "अधिकतम समय सीमा (घंटे)",
      "vfr": "वीएफआर",
      "Disclaimer": "अस्वीकरण: ओटी ग्राहकों के लिए डीलर उपलब्ध विंडो इनपुट फ़ाइल से मानी जाएगी, जीटी ग्राहक समयों को पिकअप/ड्रॉप स्थानों से माना जाएगा।",
      "To Check for Blank Data": "रिक्त डेटा की जांच करने के लिए 1. 'खोजें और चुनें' पर क्लिक करें। 2. 'स्पेशल पर जाएं' पर क्लिक करें। 3. 'ब्लैंक्स' चुनें। 4. ठीक है पर क्लिक करें और फिर सभी रिक्त पंक्तियां/कोशिकाएं हाइलाइट हो जाएंगी। 5. होम टैब में कोशिकाओं के अनुभाग के तहत हटाएं चुनें। 6. 'शीट पंक्तियां हटाएं' पर क्लिक करें।",
      "Add New OBD": "नया ओबीडी जोड़ें",
      "All drop locations are invalid, Please check..!!": "सभी ड्रॉप स्थान अमान्य हैं, कृपया जांचें..!!",
      "Please upload file having extensions .csv or .xlsx only.": "कृपया केवल .csv या .xlsx एक्सटेंशन वाली फ़ाइल अपलोड करें।",
      "Dispatch Load Volume Summary (CFT)": "डिस्पैच लोड वॉल्यूम सारांश (सीएफटी)",
      "Total Loads Volume (CFT)": "कुल लोड वॉल्यूम (सीएफटी)",
      "Planned Loads Volume (CFT)": "योजनाबद्ध लोड वॉल्यूम (सीएफटी)",
      "Unplanned Loads Volume (CFT)": "अयोजित लोड वॉल्यूम (सीएफटी)",
      "Dropped Loads Volume (CFT)": "छोड़ा गया लोड वॉल्यूम (सीएफटी)",
      "Dispatch Load Weight Summary (Pounds)": "डिस्पैच लोड वजन सारांश (पाउंड)",
      "Total Loads Weight (Pounds)": "कुल लोड वजन (पाउंड)",
      "Planned Loads Weight (Pounds)": "योजनाबद्ध लोड वजन (पाउंड)",
      "Unplanned Loads Weight (Pounds)": "अयोजित लोड वजन (पाउंड)",
      "Dropped Loads Weight (Pounds)": "छोड़ा गया लोड वजन (पाउंड)",
      "Dispatch Load Quantity Summary": "डिस्पैच लोड मात्रा सारांश",
      "Total Quantity": "कुल मात्रा",
      "Planned Quantity": "योजनाबद्ध मात्रा",
      "Unplanned Quantity": "अयोजित मात्रा",
      "Dropped Quantity": "छोड़ी गई मात्रा",
      "Dispatch Load Volume Summary (CFT)": "डिस्पैच लोड वॉल्यूम सारांश (सीएफटी)",
      "Total Loads Volume (CFT)": "कुल लोड वॉल्यूम (सीएफटी)",
      "Planned Loads Volume (CFT)": "योजनाबद्ध लोड वॉल्यूम (सीएफटी)",
      "Unplanned Loads Volume (CFT)": "अयोजित लोड वॉल्यूम (सीएफटी)",
      "Dropped Loads Volume (CFT)": "छोड़ा गया लोड वॉल्यूम (सीएफटी)",
      "Dispatch Load Weight Summary (Pounds)": "डिस्पैच लोड वजन सारांश (पाउंड)",
      "Total Loads Weight (Pounds)": "कुल लोड वजन (पाउंड)",
      "Planned Loads Weight (Pounds)": "योजनाबद्ध लोड वजन (पाउंड)",
      "Unplanned Loads Weight (Pounds)": "अयोजित लोड वजन (पाउंड)",
      "Dropped Loads Weight (Pounds)": "छोड़ा गया लोड वजन (पाउंड)",
      "Dispatch Load Quantity Summary": "डिस्पैच लोड मात्रा सारांश",
      "Total Quantity": "कुल मात्रा",
      "Planned Quantity": "योजनाबद्ध मात्रा",
      "Unplanned Quantity": "अयोजित मात्रा",
      "Dropped Quantity": "छोड़ी गई मात्रा",
      "Dispatch Load Weight Summary (Pounds)": "डिस्पैच लोड वजन सारांश (पाउंड)",
      "Dispatch Load Volume Summary (CFT)": "डिस्पैच लोड वॉल्यूम सारांश (सीएफटी)",
      "Dropped Data": "छोड़ा गया डेटा",
      "ontime_delivery": "समय पर डिलीवरी",
      "dealer_Available_start_time_should_be_less_than_end_time": "डीलर की उपलब्धता का प्रारंभ समय समाप्ति समय से कम होना चाहिए",
      "Removed": "हटाया गया",
      "Unedited": "असंपादित",
      "Edited": "संपादित",    
    add_zone_wise_cost: "क्षेत्र के अनुसार लागत जोड़ें",
    scenarios: "परिदृश्य",
      plant: "प्लांट",
      standard_transit_time_days: "मानक पारगमन समय (दिन)",
    drop_to_time: "ड्रॉप करने का समय",
    drop_from_time: "ड्रॉप करने का प्रारंभ समय",
    pickup_to_time: "पिकअप करने का समय",
    pickup_from_time: "पिकअप करने का प्रारंभ समय",
    distance_from_plant_miles: "प्लांट से दूरी (मील)",
    vehicle_next_available_date: "अगली उपलब्ध वाहन तिथि",
    max_distance_per_plan_miles: "प्रति योजना अधिकतम दूरी (मील)",
    remaining_monthly_distance_miles: "शेष मासिक दूरी (मील)",
    assign_quality_checks: "गुणवत्ता जांच आवंटित करें",
    add_fixed_vehicle_cost: "नियत वाहन लागत जोड़ें",
      customer: "ग्राहक",
      "Pickup Locations (Drop)*": "पिकअप स्थान (ड्रॉप)*",
       "pickup_locations_drop*": "पिकअप स्थान (ड्रॉप)*",
      duplicate_obds_light_red: "डुप्लिकेट OBD हल्के लाल रंग में दिखाए गए हैं",
    new_obds_green: "नए जोड़े गए OBD हरे रंग में दिखाए गए हैं",
    pickup_locations_drop: "पिकअप स्थान (ड्रॉप)",
      invalid_orders_not_shown: "अवैध आदेश नहीं दिखाए जा रहे हैं",
    note: "नोट",
      percent_unplanned: "अयोजित प्रतिशत",
      please_select_scenario: "कृपया योजनाओं की तुलना करने के लिए परिदृश्य चुनें",
    avg_kms_travelled: "औसत किलोमीटर यात्रा की गई",
    total_cost: "कुल लागत",
    total_vehicle_fill_rate: "कुल वाहन भराव दर",
    percent_km_run_sob: "एसओबी के लिए चलाए गए किलोमीटर प्रतिशत",
    percent_ontime_dispatch: "समय पर भेजने का प्रतिशत",
    upcountry: "उपकंठ",
    percent_spot_vehicles_trips: "स्पॉट वाहनों की यात्राओं का प्रतिशत",
    round_trip_distance: "आवागमन दूरी",
    total_trip_distance: "कुल यात्रा दूरी",
    vehicle_type: "वाहन प्रकार",
    plant_code: "प्लांट कोड",
    vehicle_no: "वाहन संख्या",
    monthly_sob_km: "मासिक SOB (किलोमीटर)",
    distance_travelled_km: "यात्रा की गई दूरी (किलोमीटर)",
    pickup_location_source: "पिकअप स्थान (स्रोत)",
    drop_location_code_destination: "ड्रॉप स्थान कोड (गंतव्य)",
    drop_location_name_destination: "ड्रॉप स्थान नाम (गंतव्य)",
    order_number: "आदेश संख्या",
    invoicing_date: "बिलिंग की तारीख",
    quantity: "मात्रा",
    weight_kgs: "वजन (किलोग्राम)",
    demand_volume_m3: "मांग वॉल्यूम (M3)",
    dealer_available_from: "डीलर से उपलब्ध",
    dealer_available_to: "डीलर तक उपलब्ध",
    delivery_date: "वितरण तिथि",
    trip_no: "यात्रा संख्या",
    planned_day: "नियोजित दिन",
    drop_sequence: "ड्रॉप अनुक्रम",
    destination_location_city: "गंतव्य स्थान का शहर",
    destination_location_state: "गंतव्य स्थान का राज्य",
    volume_m3: "वॉल्यूम (M3)",
    distance_to_destination_kms: "गंतव्य तक की दूरी (किलोमीटर)",
    expected_delivery_date: "अनुमानित वितरण तिथि",
    time_to_destination: "गंतव्य तक समय",
    transit_time_hhmm: "ट्रांजिट समय (HH:MM)",
    loading_time_hhmm: "लोडिंग समय (HH:MM)",
    unloading_time_hhmm: "अनलोडिंग समय (HH:MM)",
    start_month: "प्रारंभ माह",
    end_month: "अंतिम माह",
    get_data: "डेटा प्राप्त करें",
    select_chart_type: "चार्ट प्रकार चुनें",
    fleet_analysis: "बेड़े का विश्लेषण",
    system_recommended: "सिस्टम द्वारा अनुशंसित",
    total_load: "कुल भार",
    serviceability: "सेवाक्षमता",
    in416: "IN416",
    in436: "IN436",
    user_recommended: "उपयोगकर्ता द्वारा अनुशंसित",
    threshold: "सीमा",
    number_of_trips: "यात्राओं की संख्या",
    total: "कुल",
    spot: "स्पॉट",
    planned_truck_load: "नियोजित ट्रक लोड",
    quantity_cases: "मात्रा (मामले)",
    weight_kgs: "वजन (किलोग्राम)",
    volume_cmt: "आयतन (CMT)",
    unplanned_load: "अनियोजित भार",
    average_distance_travelled_per_vehicle_km: "प्रति वाहन औसत यात्रा दूरी (KM)",
    operational_cost_rs: "संचालन लागत (रु.)",
    average_vehicle_fill_rate: "औसत वाहन भरण दर",
    unplanned_load_proportion: "अनियोजित भार का अनुपात",
    average_vehicle_utilization_as_per_sob: "SOB के अनुसार औसत वाहन उपयोग",
    ontime_dispatches: "समय पर भेजी गईं",
    local: "स्थानीय",
    upcountry: "उपकंठ",
    ontime_deliveries: "समय पर डिलीवरी",
    bar: "बार",
    load: "लोड",
    month: "माह",
    percentage: "%",






      //words for configurations screen

      "Consider Transporter SOB": "परिवहनकर्ता SOB पर विचार करें",
      forecast_summary: "पूर्वानुमान सारांश",
      compare: "तुलना करें",
    pickup_locations_drop: "पिकअप स्थान (ड्रॉप)",
      enable_disable: "सक्षम/अक्षम",
      "note_highlighted_rows_red": "नोट: लाल रंग में हाइलाइट किए गए पंक्तियों में GPS डेटा या एकीकरण की कमी है, वाहन की उपलब्धता को मैन्युअल रूप से चिह्नित करें।",
  "highlighted_rows_green": "2. हरे रंग में हाइलाइट की गई पंक्तियाँ योजना के लिए मानी जाती हैं",
  "enable_reconciliation": "समाधान सक्षम करें",
  "enable_dc_week_off_planning": "डीसी सप्ताह बंद योजना सक्षम करें",
      "Consider Dropping Distant Smaller Loads":
        "दूर के छोटे लोड को छोड़ने पर विचार करें",
      "Consider Realtime Vehicle Available Times":
        "वास्तविक समय में उपलब्ध वाहन के समय पर विचार करें",
      "Plant Wise Vehicle Type Configuration*":
        "प्लांट अनुसार वाहन प्रकार विन्यास*",
      "Consignees / Dealers Locations (Drop)*": "ग्राहक/डीलर स्थान (ड्रॉप)*",
      "Pickup Locations (Pickup)*": "पिकअप स्थान (पिकअप)*",
      "Optimization Type*": "अनुकूलन प्रकार*",
      "Plant Wise Planning History*": "प्लांट अनुसार योजना इतिहास*",
      "Plant Wise Plan Summary*": "प्लांट अनुसार योजना सारांश*",
      "Plant Wise New Plan Creation*": "प्लांट अनुसार नई योजना सृजन*",
      "Suppliers / Vendor Locations (Pickup)*":
        "आपूर्तिकर्ता/विक्रेता स्थान (पिकअप)*",
      "Plant Wise Vehicle Type Configuration*":
        "प्लांट अनुसार वाहन प्रकार विन्यास*",
      "Consignees / Dealers Locations (Drop)*": "ग्राहक/डीलर स्थान (ड्रॉप)*",
      "Pickup Locations (Pickup)*": "पिकअप स्थान (पिकअप)*",
      "Plant Wise Vehicle Type Configuration*":
        "प्लांट अनुसार वाहन प्रकार विन्यास*",
      "Suppliers / Vendor Locations (Pickup)*":
        "आपूर्तिकर्ता/विक्रेता स्थान (पिकअप)*",
      "Plant Wise Planning History*": "प्लांट अनुसार योजना इतिहास*",
      "Plant Wise Plan Summary*": "प्लांट अनुसार योजना सारांश*",
      "Plant Wise New Plan Creation*": "प्लांट अनुसार नई योजना सृजन*",
      "Enable/Disable Dispatch Planning Module":
        "डिस्पैच योजना मॉड्यूल सक्षम/अक्षम करें",
      Inbound: "आवक",
      Outbound: "निर्गम",
      "Suppliers / Vendor Locations (Pickup)":
        "आपूर्तिकर्ता / विक्रेता स्थान (पिकअप)",
      "Operational Hours": "संचालन घंटे",
      "Pickup Locations (Drop)": "पिकअप स्थान (ड्रॉप)",
      "Plant Wise Vehicle Type Configuration":
        "प्लांट अनुसार वाहन प्रकार विन्यास",
      "Max No. Of Vehicle Types": "वाहन प्रकारों की अधिकतम संख्या",
      "Vehicle Load Capacity Min / Max Configuration Preferences":
        "वाहन लोड क्षमता न्यूनतम/अधिकतम विन्यास प्राथमिकताएँ",
      "Vehicle Load Weight Min / Max Preferences":
        "वाहन लोड वजन न्यूनतम/अधिकतम प्राथमिकताएँ",
      "Distance preferences": "दूरी प्राथमिकताएँ",
      "Vehicle Priorities": "वाहन प्राथमिकताएँ",
      "Vehicle Operational Times": "वाहन संचालन समय",
      "Vehicle Availability": "वाहन उपलब्धता",
      "Plant Wise Freight Configuration": "प्लांट अनुसार माल ढुलाई विन्यास",
      "Plant Wise Transporter Configuration":
        "प्लांट अनुसार परिवहनकर्ता विन्यास",
      "Route Preferences": "मार्ग प्राथमिकताएँ",
      "Plant Wise New Plan Creation": "प्लांट अनुसार नई योजना सृजन",
      "Plant Wise Plan Summary": "प्लांट अनुसार योजना सारांश",
      "Plant Wise Planning History": "प्लांट अनुसार योजना इतिहास",
      "No of Vehicles Types": "वाहन प्रकारों की संख्या",
      "Generic Time Windows": "सामान्य समय विंडोज़",
      "Individual Vehicle Time Windows": "व्यक्तिगत वाहन समय विंडोज़",
      "Availability By Vehicle Type": "वाहन प्रकार द्वारा उपलब्धता",
      "Availability By Individual Vehicle": "व्यक्तिगत वाहन द्वारा उपलब्धता",
      "Monthly Minimum Planning Distance Preferences":
        "मासिक न्यूनतम योजना दूरी प्राथमिकताएँ",
      "GPS Based Availability": "GPS आधारित उपलब्धता",
      "Truck Location Visibility": "ट्रक स्थान दृश्यता",
      "Plant Vicinity Circle Radius": "प्लांट निकटवर्ती सर्कल त्रिज्या",
      "Fixed Vehicle Costs": "स्थिर वाहन लागत",
      "Variable Costs": "चर लागत",
      "Vehicle Type Level Variable Costs": "वाहन प्रकार स्तर की चर लागत",
      "City Level Vehicle Type Costs": "शहर स्तर वाहन प्रकार की लागत",
      "Per CFT Cost": "प्रति CFT लागत",
      "Per Mile Cost": "प्रति माइल लागत",
      "Zone Level Cost": "क्षेत्र स्तर लागत",
      "Transporter Share of Business": "परिवहनकर्ता का व्यवसाय में हिस्सा",
      "Sustainability Integration": "सस्टेनेबिलिटी एकीकरण",
      "Optimization Type": "अनुकूलन प्रकार",
      "Cost Optimization": "लागत अनुकूलन",
      "Weight based optimization": "वजन आधारित अनुकूलन",
      "Distance based optimization": "दूरी आधारित अनुकूलन",
      "Time based optimization": "समय आधारित अनुकूलन",
      "Volume based optimization": "आयतन आधारित अनुकूलन",
      "Prioritize Local Loads": "स्थानीय लोड को प्राथमिकता दें",
      "Radius Miles": "त्रिज्या माइल्स",
      "Number of Loading Docks": "लोडिंग डॉक की संख्या",
      "City Based Entry Restriction": "शहर आधारित प्रवेश प्रतिबंध",
      "Unloading time per case/max unloading time":
        "प्रति केस/अधिकतम लोड समय उतारने का समय",
      "Consider Vehicle Break Times (Night Driving)":
        "वाहन ब्रेक समय (रात्रि ड्राइविंग) पर विचार करें",
      "Consider Holiday Restrictions": "अवकाश प्रतिबंधों पर विचार करें",
      "Vehicle Restriction": "वाहन प्रतिबंध",
      "Plan Spot Vehicles": "स्पॉट वाहनों की योजना बनाएं",
      "Max No of Customers Per Vehicle": "प्रति वाहन अधिकतम ग्राहक संख्या",
      "Use Dynamic Planning Window": "डायनेमिक योजना विंडो का उपयोग करें",
      "Number of Days to Use Planning Window":
        "योजना विंडो का उपयोग करने के लिए दिन की संख्या",
      "Use Volume for Vehicle Fill Rate (VFR)":
        "वाहन भराव दर (VFR) के लिए आयतन का उपयोग करें",
      "Use Weight for Vehicle Fill Rate (VFR)":
        "वाहन भराव दर (VFR) के लिए वजन का उपयोग करें",
      "Min VFR Per Vehicle Type": "प्रति वाहन प्रकार न्यूनतम VFR",
      "Min VFR Per Spot Vehicle": "प्रति स्पॉट वाहन न्यूनतम VFR",
      "Prioritize Same Dealers First in a Vehicle":
        "वाहन में पहले एक ही डीलरों को प्राथमिकता दें",
      "Prioritize Multi City Dealers First in a Vehicle":
        "वाहन में पहले बहु-शहर डीलरों को प्राथमिकता दें",
      "Dispatch within Days": "दिनों के भीतर भेजें",
      "Allow Unplanned Loads": "अनियोजित लोड की अनुमति दें",
      "Minimize Number of Vehicles Used":
        "प्रयुक्त वाहनों की संख्या को कम करें",
      "Dealer Buffer Time": "डीलर बफर समय",
      "Buffer Time(Mins)": "बफर समय (मिनटों में)",
      "Month End Planning Start Day": "महीने के अंत योजना शुरू दिन",
      "Day of Every Month": "प्रत्येक महीने का दिन",
      "Max Local No of Customers Per Vehicle":
        "प्रति वाहन अधिकतम स्थानीय ग्राहकों की संख्या",
      "Max Upcountry No of Customers Per Vehicle":
        "प्रति वाहन अधिकतम उपदेशी ग्राहकों की संख्या",
      "Planning Type": "योजना प्रकार",
      "Local Prioritization": "स्थानीय प्राथमिकता",
      "Local Prioritization and Virtual Upcountry clubbing":
        "स्थानीय प्राथमिकता और वर्चुअल उपदेशी क्लबिंग",
      "General Plan (Local and Upcountry clubbing)":
        "सामान्य योजना (स्थानीय और उपदेशी क्लबिंग)",
      "Telescopic Deviation": "टेलीस्कोपिक विचलन",
      "MOQ for Planning": "योजना के लिए MOQ",
      Reconciliation: "सुलह",
      "DC Week Off planning": "DC सप्ताह अवकाश योजना",
      "Save Inbound Configurations": "आवक विन्यास सहेजें",
      "Save Outbound Configurations": "निर्गम विन्यास सहेजें",
      SAVE: "सहेजें",
      "Enable Reconciliation": "सुलह सक्षम करें",
      "Enable DC Week Off planning": "DC सप्ताह अवकाश योजना सक्षम करें",
      "Deviation Alert": "विचलन अलर्ट",
      "Overload Warning": "अधिभार चेतावनी",
      "Underload Alert": "अधीलोड चेतावनी",
      "Fuel Efficiency Report": "ईंधन दक्षता रिपोर्ट",
      "Vehicle Downtime": "वाहन डाउनटाइम",
      "Speed Violation Alert": "गति उल्लंघन चेतावनी",
      "Trip Delay Notification": "यात्रा विलंब सूचना",
      "Idle Time Monitoring": "निष्क्रिय समय निगरानी",
      "Driver Performance": "चालक प्रदर्शन",
      "Realtime GPS Tracking": "वास्तविक समय जीपीएस ट्रैकिंग",
      "Fleet Utilization": "बेड़ा उपयोग",
      "Trip Efficiency": "यात्रा दक्षता",
      "Dynamic Route Adjustment": "डायनेमिक मार्ग समायोजन",
      "Load Distribution Optimization": "लोड वितरण अनुकूलन",
      "Transporter Preferences": "परिवहनकर्ता प्राथमिकताएँ",
      "Trip Schedule": "यात्रा कार्यक्रम",
      "Driver Assignment": "चालक असाइनमेंट",
      "Optimize Vehicle Utilization": "वाहन उपयोग का अनुकूलन करें",
      "Time Based Planning": "समय आधारित योजना",
      "Plant Wise Route Planning": "प्लांट अनुसार मार्ग योजना",
      "Vehicle Rotation Configuration": "वाहन रोटेशन विन्यास",
      "Inbound Vehicle Preferences": "आवक वाहन प्राथमिकताएँ",
      "Outbound Vehicle Preferences": "निर्गम वाहन प्राथमिकताएँ",
      "Vehicle Fill Rate Optimization": "वाहन भराव दर अनुकूलन",
      "Dynamic Load Allocation": "डायनेमिक लोड आवंटन",
      "Start Date": "प्रारंभ तिथि",
      "Run Plan": "योजना चलाएँ",
      "Completion Date": "समाप्ति तिथि",
      "Transporter Name": "परिवहनकर्ता का नाम",
      "Driver Allocation": "चालक आवंटन",
      "Max Load Capacity": "अधिकतम लोड क्षमता",
      "Estimated Delivery Time": "अनुमानित डिलीवरी समय",
      "Use Real-Time Traffic Data": "वास्तविक समय ट्रैफिक डेटा का उपयोग करें",
      "Driver Break Times": "चालक ब्रेक समय",
      "Max Vehicle Load Percentage": "अधिकतम वाहन लोड प्रतिशत",
      "Telescopic Deviation Settings": "टेलीस्कोपिक विचलन सेटिंग्स",
      "Deviation Type": "विचलन प्रकार",
      "Spot Vehicle Usage": "स्पॉट वाहन का उपयोग",
      "Real-time Load Monitoring": "वास्तविक समय लोड निगरानी",
      "Driver Allocation Time": "चालक आवंटन समय",
      "Use Traffic Data": "ट्रैफिक डेटा का उपयोग करें",
      "Optimize Route": "मार्ग का अनुकूलन करें",
      "Optimize Fuel Efficiency": "ईंधन दक्षता का अनुकूलन करें",
      "Daily Vehicle Plan": "दैनिक वाहन योजना",
      "Transporter Allocation": "परिवहनकर्ता आवंटन",
      "Enable Real-time Tracking": "वास्तविक समय ट्रैकिंग सक्षम करें",
      "Vehicle Rotation Plan": "वाहन रोटेशन योजना",
      "Route Optimization Preferences": "मार्ग अनुकूलन प्राथमिकताएँ",
      "Consider Weather Conditions": "मौसम की परिस्थितियों पर विचार करें",
      "Max Loading Time": "अधिकतम लोडिंग समय",
      "Min Load Capacity": "न्यूनतम लोड क्षमता",
      "Driver Scheduling": "चालक अनुसूची",
      "Vehicle Capacity Monitoring": "वाहन क्षमता निगरानी",
      "Dynamic Load Balancing": "डायनेमिक लोड संतुलन",
      "Last Mile Optimization": "अंतिम मील अनुकूलन",
      "Unloading Time Management": "लोड उतारने का समय प्रबंधन",
      "Daily Trip Plan": "दैनिक यात्रा योजना",
      "Delivery Window Optimization": "डिलीवरी विंडो अनुकूलन",
      "Night Driving Restrictions": "रात्रि ड्राइविंग प्रतिबंध",
      "Weekend Planning": "सप्ताहांत योजना",

      drop_location_name_destination: "डिलीवरी स्थान का नाम (गंतव्य)",
      planned_day: "नियोजित दिन",
      drop_sequence: "ड्रॉप क्रम",
      planned_distance_miles: "नियोजित दूरी (मील)",
      restriction_hours: "प्रतिबंध घंटे",
      transporter_details: "परिवाहक विवरण",
      save_geofence: "जियोफेंस सहेजें",
      no_data: "कोई डेटा नहीं",
      redirecting_to_vehicle_checklist:
        "वाहन चेकलिस्ट की ओर पुनः निर्देशित कर रहा है",
      drop_location_code_destination: "ड्रॉप स्थान कोड (गंतव्य)",
      drop_location_name_destination: "ड्रॉप स्थान नाम (गंतव्य)",
      destination_location_city: "गंतव्य स्थान शहर",
      dealer_type: "डीलर प्रकार",
      customer_type: "ग्राहक प्रकार",
      week_off: "साप्ताहिक अवकाश",
      order_number: "आदेश संख्या",
      invoicing_date: "इनवॉइसिंग तिथि",
      quantity: "मात्रा",
      weight_pounds: "वजन (पाउंड)",
      volume_cft: "आयतन (CFT)",
      distance_to_destination_miles: "गंतव्य तक की दूरी (मील)",
      expected_delivery_date: "अपेक्षित डिलीवरी तिथि",
      time_to_destination: "गंतव्य तक का समय",
      transit_time_hh_mm: "ट्रांजिट समय (HH:MM)",
      unloading_time_hh_mm: "अनलोडिंग समय (HH:MM)",
      email: "ईमेल",
      details: "विवरण",
      view: "देखें",
      assign_vehicle: "वाहन आवंटित करें",
      revoke_assignment: "आवंटन रद्द करें",
      edit_plan: "योजना संपादित करें",
      delete: "हटाएं",
      plan_edited: "योजना संपादित की गई",
      yes: "हाँ",
      no: "नहीं",
      trip_no: "यात्रा संख्या",
      vehicle_no: "वाहन संख्या",
      vehicle_type: "वाहन प्रकार",
      cost: "लागत ($)",
      transit_type: "ट्रांजिट प्रकार",
      transporter_name: "परिवाहक नाम",
      no_of_orders: "आदेशों की संख्या",
      no_of_customers: "ग्राहकों की संख्या",
      total_quantity: "कुल मात्रा",
      vehicle_utilization_percent: "वाहन उपयोग (%)",
      vehicle_max_weight: "वाहन अधिकतम वजन (पाउंड)",
      total_planned_weight: "कुल नियोजित वजन (पाउंड)",
      onward_trip_distance_miles: "आगे की यात्रा की दूरी (मील)",
      round_trip_distance_miles: "राउंड ट्रिप दूरी (मील)",
      vehicle_max_volume_cft: "वाहन अधिकतम आयतन (CFT)",
      total_planned_volume_cft: "कुल नियोजित आयतन (CFT)",
      max_dispatch_time: "अधिकतम प्रेषण समय (HH:MM)",
      arrival_at_first_dealer: "प्रथम डीलर पर आगमन (प्रारंभ)",
      departure_at_last_dealer: "अंतिम डीलर से प्रस्थान (समाप्ति)",
      return_to_plant: "प्लांट में वापसी (HH:MM)",
      total_planned_trip_time: "कुल नियोजित यात्रा समय (घंटे)",
      total_round_trip_time: "कुल राउंड ट्रिप समय (घंटे)",
      total_transit_time: "कुल ट्रांजिट समय (HH:MM)",
      total_loading_time: "कुल लोडिंग समय (HH:MM)",
      total_unloading_time: "कुल अनलोडिंग समय (HH:MM)",
      remark: "टिप्पणी",
      "n/a": "उपलब्ध नहीं",
      revoke_again_to_edit: "संपादन के लिए पुनः रद्द करें",
      drop_location_code: "ड्रॉप स्थान कोड",
      drop_location_name: "ड्रॉप स्थान नाम",
      drop_location_city: "ड्रॉप स्थान शहर",
      distance_from_plant: "प्लांट से दूरी",
      demand_volume_cft: "मांग आयतन (CFT)",
      delivery_date: "डिलीवरी तिथि",
      actions: "क्रियाएँ",
      pickup_location_source: "पिकअप स्थान (स्रोत)",
      dealer_available_from: "डीलर उपलब्ध (से)",
      dealer_available_to: "डीलर उपलब्ध (तक)",
      loading_time_hh_mm: "लोडिंग समय (HH:MM)",
      dropped_reason: "ड्रॉप किया गया कारण",
      dealer_location_data_not_in_system: "डीलर स्थान डेटा सिस्टम में नहीं है",
      delivery_date_less_than_current_date:
        "डिलीवरी तिथि वर्तमान तिथि से कम है",
      kdd_customer: "KDD ग्राहक",
      insufficient_load_for_planning: "योजना के लिए अपर्याप्त लोड (<MOQ)",
      warehouse_name: "गोदाम का नाम",
      destination_city: "गंतव्य शहर",
      destination_state: "गंतव्य राज्य",
      sales_order_no: "बिक्री आदेश संख्या",
      no_of_tires: "टायरों की संख्या",
      order_date: "आदेश तिथि",
      vehicle_name: "वाहन नाम",
      vehicle_priority: "वाहन प्राथमिकता",
      vehicle_availability_from: "वाहन उपलब्धता (से)",
      vehicle_availability_to: "वाहन उपलब्धता (तक)",
      break_time_from: "विराम समय (से)",
      break_time_to: "विराम समय (तक)",
      min_volume_cft: "न्यूनतम आयतन (CFT)",
      max_volume_cft: "अधिकतम आयतन (CFT)",
      vehicle_dimensions_width_mm: "वाहन चौड़ाई (मिमी)",
      vehicle_dimensions_height_mm: "वाहन ऊँचाई (मिमी)",
      vehicle_dimensions_depth_mm: "वाहन गहराई (मिमी)",
      vehicle_min_weight: "वाहन न्यूनतम वजन (पाउंड)",
      max_no_of_nodes: "डिलीवरी की संख्या",
      min_load_per_delivery: "प्रति डिलीवरी न्यूनतम लोड",
      max_distance: "अधिकतम दूरी (मील)",
      material_code: "सामग्री कोड",
      material_name: "सामग्री नाम",
      material_type: "सामग्री प्रकार",
      material_weight: "सामग्री वजन",
      material_dimensions_depth: "सामग्री गहराई",
      material_dimensions_width: "सामग्री चौड़ाई",
      material_dimensions_height: "सामग्री ऊँचाई",
      form_vehicle_types: "वाहन प्रकार",
      dealer_form_state: "राज्य",
      dealer_form_city: "शहर",
      freight_fixed_vehicle_cost: "स्थिर वाहन लागत",
      freight_driver_cost: "चालक लागत",
      freight_maintenance_cost: "रखरखाव लागत",
      freight_per_cmt_cost: "प्रति CMT लागत",
      freight_per_km_cost: "प्रति किलोमीटर लागत",
      vehicle_date: "तारीख",
      vehicle_number: "वाहन संख्या",
      plant_code: "प्लांट कोड",
      plant_name: "प्लांट नाम",
      available_time: "उपलब्ध समय",
      gps_status: "GPS स्थिति",
      last_known_address: "अंतिम ज्ञात पता",
      last_gps_time: "अंतिम GPS समय",
      vehicle_location: "वाहन स्थान",
      assigned_to_ot: "OT को आवंटित",
      min_vfr: "न्यूनतम VFR",
      order_information: "ऑर्डर जानकारी",
      vehicle_type_configurations: "वाहन प्रकार कॉन्फ़िगरेशन",
      vehicle_routes: "वाहन मार्ग",
      total_customers: "कुल ग्राहक",
      planned: "योजनाबद्ध",
      unplanned: "अयोजित",
      total_round_trip_distance_miles: "कुल राउंड ट्रिप दूरी (मील)",
      planning: "योजना",
      vfr: "VFR",
      ontime_dispatch: "समय पर प्रेषण",
      avg_tat: "औसत TAT",
      view_locations: "स्थान देखें",
      view_routes: "मार्ग देखें",
      additional_vehicles: "अतिरिक्त वाहन",
      regular_vehicles: "नियमित वाहन",
      enter_vehicle_no: "वाहन नंबर दर्ज करें",
      vehicle_has_active_trip_shipment_id:
        "वाहन में सक्रिय ट्रिप शिपमेंट ID है",
      do_you_wish_to: "क्या आप करना चाहते हैं",
      force_close_assign: "फोर्स क्लोज़ और असाइन करें",
      force_close_reason: "फोर्स क्लोज़ कारण",
      gps_issue: "GPS समस्या",
      trip_closed: "यात्रा बंद",
      load_cancelled: "लोड रद्द",
      new_trip_allocated: "नई यात्रा आवंटित की गई",
      driver_changed: "ड्राइवर बदला गया",
      are_you_sure_you_want_to_update: "क्या आप वाकई अपडेट करना चाहते हैं",
      edit_plan_for_same: "उसी के लिए योजना संपादित करें",
      update_vehicle_plan: "वाहन योजना अपडेट करें",
      max_dispatch_time: "अधिकतम प्रेषण समय",
      new_plan: "नई योजना",
      reason_for_plan_change: "योजना परिवर्तन का कारण",
      enter_the_reason: "कारण दर्ज करें",
      reason_for_plan_deletion: "योजना हटाने का कारण",
      are_you_sure_delete_plan: "क्या आप वाकई योजना हटाना चाहते हैं",
      update_order_details: "ऑर्डर विवरण अपडेट करें",
      demand_volume: "मांग मात्रा",
      dealer_availability_start_time: "डीलर उपलब्धता प्रारंभ समय",
      dealer_availability_end_time: "डीलर उपलब्धता समाप्ति समय",
      download: "डाउनलोड",
      dealerTimeError:
        "डीलर की उपलब्ध प्रारंभिक समय समाप्ति समय से कम होना चाहिए",
      more: "अधिक",
      less: "कम",
      qualityChecks: "गुणवत्ता जांच",
      currentMonthPlanDistance: "वर्तमान माह योजना दूरी",
      email: "ई मेल",
      viewPlan: "योजना देखें",
      configurations: "कॉन्फ़िगरेशन्स",
      note_blank_rows_columns_not_allowed:
        "नोट: खाली पंक्तियों/कॉलम की अनुमति नहीं है",
      check_blank_data_instructions:
        "खाली डेटा की जांच करने के लिए: 1. खोजें और चुनें पर क्लिक करें। 2. विशेष पर जाएं पर क्लिक करें। 3. खाली चुनें। 4. ठीक पर क्लिक करें और सभी खाली पंक्तियाँ/कोशिकाएँ हाइलाइट हो जाएँगी। 5. होम टैब के सेल्स सेक्शन में हटाएं चुनें। 6. शीट पंक्तियों को हटाएं पर क्लिक करें।",
      disclaimer_dealer_window_ot_customers:
        "अस्वीकरण: ओटी ग्राहकों के लिए उपलब्ध विंडो इनपुट फ़ाइल से मानी जाएगी, जीटी ग्राहकों का समय पिकअप/ड्रॉप स्थानों से माना जाएगा",
      quantity: "मात्रा",
      specifications: "विशेष विवरण",
      please_select_plan_id_to_compare_plans:
        "कृपया योजनाओं की तुलना करने के लिए योजना आईडी चुनें",
      vehicle_forecast_summary_dashboard: "वाहन पूर्वानुमान सारांश डैशबोर्ड",
      pickup_point: "पिकअप पॉइंट",
      view_details: "विवरण देखें",
      compare_plans: "योजनाओं की तुलना करें",
      pickup_drop_points: "पिकअप/ड्रॉप पॉइंट्स",
      edit: "संपादित करें",
      pickup: "पिकअप",
      drop: "ड्रॉप",
      both: "दोनों",
      pickup_location_code: "पिकअप स्थान कोड",
      dealer_type: "डीलर प्रकार",
      select: "चुनें",
      local: "स्थानीय",
      upcountry: "देशी",
      vehicle_restriction_list: "वाहन प्रतिबंध सूची",
      week_off: "साप्ताहिक अवकाश",
      csp_day: "सीएसपी दिवस",
      is_customer_kdd: "क्या ग्राहक केडीडी है?",
      start_month: "प्रारंभ महीना",
      end_month: "समाप्ति महीना",
      plan_id: "योजना आईडी",
      fecha_creada: "बनाई गई तारीख",
      no_of_trucks_planned: "योजना किए गए ट्रकों की संख्या",
      total_planned_volume_cft: "कुल नियोजित मात्रा (सीएफटी)",
      total_planned_weight_pounds: "कुल नियोजित वजन (पाउंड)",
      plan_run_by: "योजना द्वारा चलाई गई",
      planned_vehicles: "योजना किए गए वाहन",
      trip_no: "यात्रा संख्या",
      total_planned_customers: "कुल नियोजित ग्राहक",
      total_round_trip_distance: "कुल राउंड ट्रिप दूरी",
      planning_percentage: "योजना का प्रतिशत",
      max_dispatch_time: "अधिकतम प्रेषण समय (HH:MM)",
      total_loading_time: "कुल लोडिंग समय (HH:MM)",
      total_unloading_time: "कुल अनलोडिंग समय (HH:MM)",
      planned_summary: "योजना का सारांश",
      invalid_orders_not_shown: "नोट: अवैध आदेश नहीं दिखाए गए हैं",
      no_of_customers: "ग्राहकों की संख्या",
      no_of_orders: "आदेशों की संख्या",
      total_demand_volume: "कुल मांग मात्रा (सीएफटी)",
      planned_summary: "योजना का सारांश",
      trips_removed: "यात्राओं को हटाया गया",
      removed_transaction_ids: "हटाए गए लेन-देन आईडी",
      vehicle_forecast_configurations: "वाहन पूर्वानुमान कॉन्फ़िगरेशन",
      pickup_point: "पिकअप पॉइंट",
      vehicle_type: "वाहन का प्रकार",
      vehicle_name: "वाहन का नाम",
      cost_per_miles: "प्रति मील लागत",
      driver_break_time: "ड्राइवर ब्रेक समय",
      view: "देखें",
      please_select_no_of_vehicles: "कृपया वाहनों की संख्या चुनें",
      no_of_vehicles: "वाहनों की संख्या",
      obd_analysis: "ओबीडी विश्लेषण",
      pickup_point: "पिकअप पॉइंट",
      select_month: "महीना चुनें",
      location_radius_miles: "स्थान त्रिज्या (मील)",
      submit: "प्रस्तुत करें",
      total_obd_details: "कुल ओबीडी विवरण",
      valid_dealers: "मान्य डीलर",
      invalid_dealers: "अमान्य डीलर",
      volume: "मात्रा",
      help: "मदद",
      change_password: "पासवर्ड बदलें",
      logout: "लॉगआउट",
      weight_pounds: "वजन (पाउंड)",
      quantity: "मात्रा",
      obd_details_within_radius: "त्रिज्या के भीतर ओबीडी विवरण",
      dealers: "डीलर",
      dealers_percent: "डीलरों का प्रतिशत",
      volume_percent: "मात्रा प्रतिशत",
      weight_pounds_percent: "वजन प्रतिशत (पाउंड)",
      quantity_percent: "मात्रा प्रतिशत",
      cost_per_miles: "प्रति मील लागत ($)",
      driver_break_time_from: "ड्राइवर ब्रेक समय (से)",
      monthly_min_planning_distance_miles: "मासिक न्यूनतम योजना दूरी (मील)",
      vehicle_type_count: "वाहन प्रकार की संख्या",
      driver_break_time_per_day: "प्रति दिन ड्राइवर ब्रेक समय (HH:MM)",
      cost_per_miles_2: "प्रति मील लागत ($)",
      set_vehicle_priority: "वाहन की प्राथमिकता सेट करें",
      vehicle_type_name: "वाहन प्रकार का नाम",
      min_volume_cft: "न्यूनतम मात्रा (सीएफटी)",
      max_volume_cft: "अधिकतम मात्रा (सीएफटी)",
      max_distance_per_trip_miles: "प्रति यात्रा अधिकतम दूरी (मील)",
      select_plan_id: "योजना आईडी चुनें",
      drop_details: "ड्रॉप विवरण",
      vehicles: "वाहन",
      total_trucks: "कुल ट्रक",
      active: "सक्रिय",
      cost_details: "लागत विवरण",
      fixed_vehicle_cost: "निश्चित वाहन लागत",
      variable_vehicle_cost: "परिवर्तनीय वाहन लागत",
      city_wise_vehicle_cost: "शहर अनुसार वाहन लागत",
      zone_wise_vehicle_cost: "क्षेत्र अनुसार वाहन लागत",
      pickup_point: "पिकअप पॉइंट",
      vehicle_type: "वाहन प्रकार",
      transporter_name: "परिवाहक का नाम",
      cost_per_mile_below_sob: "प्रति मील लागत (SOB से कम)",
      cost_per_mile_after_sob: "प्रति मील लागत (SOB के बाद)",
      validity_from: "से मान्य",
      validity_to: "तक मान्य",
      add_vehicle_cost: "+ वाहन लागत जोड़ें",
      freight_details: "भाड़ा विवरण",
      select_transporter: "परिवाहक चुनें",
      select_zone: "क्षेत्र चुनें",
      select_state: "राज्य चुनें",
      city: "शहर",
      cost: "लागत ($)",
      save: "सहेजें",
      cancel: "रद्द करें",
      actions: "क्रियाएँ",
      validity_from_label: "से मान्य",
      validity_to_label: "तक मान्य",
      get_data: "डेटा प्राप्त करें",
      state: "राज्य",
      zone: "क्षेत्र",
      fixed_vehicle_cost_label: "निश्चित वाहन लागत ($)",
      driver_cost: "चालक लागत ($)",
      maintenance_cost: "रखरखाव लागत ($)",
      city: "शहर",
      zone: "क्षेत्र",
      vehicle_cost: "वाहन लागत",
      freight_cost: "भाड़ा लागत",
      inactive: "निष्क्रिय",
      inside_dc: "DC के अंदर",
      in_transit_to_customer: "ग्राहक के लिए परिवहन में",
      near_customer: "ग्राहक के पास",
      return_to_dc: "DC में वापस",
      empty_outside_dc: "DC के बाहर खाली",
      zone_configurations: "क्षेत्र विन्यास",
      transporters_configurations: "परिवाहक विन्यास",
      truck_no: "ट्रक संख्या",
      facility_name: "सुविधा का नाम",
      zones: "क्षेत्र",
      actions: "क्रियाएँ",
      edit: "संपादित करें",
      delete: "हटाएँ",
      add_zone_mapping: "क्षेत्र मैपिंग जोड़ें",
      current_status: "वर्तमान स्थिति",
      next_availability: "अगली उपलब्धता",
      dc_location: "DC स्थान",
      add_route_restriction: "रूट प्रतिबंध जोड़ें",
      location_code: "स्थान कोड",
      location_name: "स्थान नाम",
      restricted_area: "प्रतिबंधित क्षेत्र",
      vehicle_list: "वाहनों की सूची",
      from_time: "समय से",
      to_time: "समय तक",
      customer_location: "ग्राहक स्थान",
      truck_no: "ट्रक संख्या",
      facility_name: "सुविधा का नाम",
      current_status: "वर्तमान स्थिति",
      inside_dc: "DC के अंदर",
      in_transit_to_customer: "ग्राहक के लिए परिवहन में",
      near_customer: "ग्राहक के पास",
      return_to_dc: "DC में वापस",
      empty_outside_dc: "DC के बाहर खाली",
      unplanned: "अयोजित",
      next_availability_date_at_dc: "DC पर अगली उपलब्धता की तारीख",
      transporter_name: "परिवाहक का नाम",
      gps_status: "GPS स्थिति",
      inactive: "निष्क्रिय",
      active: "सक्रिय",
      na: "लागू नहीं",
      actions: "क्रियाएँ",
      date_and_time: "तिथि और समय",
      security: "सुरक्षा",
      quality: "गुणवत्ता",
      assessed_by: "द्वारा मूल्यांकन",
      quality_conclusion: "गुणवत्ता निष्कर्ष",
      quality_comments: "गुणवत्ता टिप्पणियाँ",
      quality_approved: "गुणवत्ता अनुमोदित",
      quality_date_of_assessment: "गुणवत्ता मूल्यांकन की तिथि",
      zone_mapping: "क्षेत्र मानचित्रण",
      transporters: "परिवहनकर्ता",
      material_configurations: "सामग्री कॉन्फ़िगरेशन",
      freight_configurations: "मालभाड़ा कॉन्फ़िगरेशन",
      route_configurations: "मार्ग कॉन्फ़िगरेशन",
      auto_clustering: "स्वचालित क्लस्टरिंग",
      transporter_share_of_business: "परिवहनकर्ता का व्यापार हिस्सा",
      dispatch_plan_summary: "प्रेषण योजना सारांश",
      kpi_dashboard: "KPI डैशबोर्ड",
      planning_tools: "योजना उपकरण",
      forecast_tools: "पूर्वानुमान उपकरण",
      suppliers: "आपूर्तिकर्ता",
      customers: "ग्राहक",
      materials: "सामग्री",
      input_data: "इनपुट डेटा",
      forecast: "पूर्वानुमान",
      previous_plan: "पिछली योजना",
      trips: "यात्राएं",
      gps_quality: "GPS गुणवत्ता",
      truck_gps_data: "ट्रक GPS डेटा",
      unscheduled_stoppages: "अनियोजित रुकावटें",
      deviation_reports: "विचलन रिपोर्ट",
      fo_data: "FO डेटा",
      vehicle_checklist: "वाहन चेकलिस्ट",
      vehicle_status: "वाहन की स्थिति",
      route_restrictions: "मार्ग प्रतिबंध",
      consolidated_run_plan: "संवर्धित रन योजना",
      plans_overview: "योजनाओं का अवलोकन",
      input_plan_summary: "इनपुट योजना सारांश",
      load_plan_summary: "लोड योजना सारांश",
      plant_wise_configurations: "प्लांट-वार कॉन्फ़िगरेशन",
      vehicle_configuration: "वाहन कॉन्फ़िगरेशन",
      monthly_data: "मासिक डेटा",
      forecast_plan_history: "पूर्वानुमान योजना इतिहास",
      obd_analysis: "OBD विश्लेषण",
      actions: "क्रियाएँ",
      pickup_location_source: "पिकअप स्थान (स्रोत)",
      drop_location_destination: "ड्रॉप स्थान (गंतव्य)",
      order_number: "आदेश संख्या",
      invoicing_date: "चालान तिथि (M/D/YYYY)",
      quantity: "मात्रा",
      dealer_available_time_from: "डीलर उपलब्ध समय (00:00 से)",
      dealer_available_time_to: "डीलर उपलब्ध समय (23:59 तक)",
      volume_cft: "आयतन (CFT)",
      weight_pounds: "वजन (पाउंड)",
      delivery_date: "वितरण तिथि (M/D/YYYY)",
      no_rows_to_show: "दिखाने के लिए कोई पंक्तियाँ नहीं हैं",
      rows: "पंक्तियाँ",
      total_rows: "कुल पंक्तियाँ",
      add_dispatch: "प्रेषण जोड़ें",
      run_new_plan_by_file: "फ़ाइल द्वारा नई योजना चलाएं",
      submit_run_plan: "योजना प्रस्तुत करें और चलाएं",
      delete: "हटाएं",
      upload_input_file: "इनपुट फ़ाइल अपलोड करें",
      upload_file: "फ़ाइल अपलोड करें",
      download_sample_template: "नमूना टेम्पलेट डाउनलोड करें",
      save: "सहेजें",
      cancel: "रद्द करें",
      dealer_available_time_error: "प्रारंभ समय अंत समय से कम होना चाहिए",
      ok: "ठीक है",
      summary_dashboard: "सारांश डैशबोर्ड",
      pickup_point: "पिकअप पॉइंट",
      date_created: "निर्माण तिथि",
      get_data: "डेटा प्राप्त करें",
      plan_details: "योजना विवरण",
      plan_history: "योजना इतिहास",
      drop_details: "ड्रॉप विवरण",
      generate_plan: "योजना बनाएं",
      order_id: "आदेश आईडी",
      no_of_runs: "रनों की संख्या",
      created_on: "बनाया गया",
      plan_run_by: "योजना चलाई गई",
      discard_obd: "OBD छोड़ें",
      upload_obd_details: "OBD विवरण अपलोड करें",
      download_template: "टेम्पलेट डाउनलोड करें",
      choose_file: "फ़ाइल चुनें",
      submit: "प्रस्तुत करें",
      start_date: "प्रारंभ तिथि",
      end_date: "समाप्ति तिथि",
      get_data: "डेटा प्राप्त करें",
      upload_input_file: "इनपुट फ़ाइल अपलोड करें",
      discard_obd: "OBD छोड़ें",
      recent_plans: "हाल की योजनाएं",
      plan_history: "योजना इतिहास",
      view_input: "इनपुट देखें",
      consolidation_id: "समेकन आईडी",
      created_date: "निर्माण तिथि",
      run_by: "द्वारा चलाया गया",
      no_of_executions: "क्रियाओं की संख्या",
      execution_status: "क्रियान्वयन की स्थिति",
      download_output: "आउटपुट डाउनलोड करें",
      upload_obd_details: "OBD विवरण अपलोड करें",
      download_template: "टेम्पलेट डाउनलोड करें",
      choose_file: "फ़ाइल चुनें",
      submit: "प्रस्तुत करें",
      simulation_time: "सिमुलेशन समय",
      enable_dc_week_off_planning: "DC सप्ताह की छुट्टी योजना सक्षम करें",
      run_plan: "योजना चलाएं",
      cancel: "रद्द करें",
      plan_details: "योजना विवरण",
      pickup_point: "पिकअप पॉइंट",
      date_created: "निर्माण तिथि",
      order_id: "आदेश आईडी",
      no_of_runs: "चलने की संख्या",
      fix_and_reupload: "सुधारें और पुनः अपलोड करें",
      club_previous_order: "पिछले आदेश को मिलाएं",
      ignore: "नज़रअंदाज़ करें",
      dealer_code: "डीलर कोड",
      remarks: "टिप्पणियाँ",
      run_new_plan: "नई योजना चलाएं",
      consolidated_route_plan_screen: "समेकित मार्ग योजना स्क्रीन",
      plant_code: "प्लांट कोड",
      plant_name: "प्लांट नाम",
      total_trips: "कुल यात्राएं",
      trips_edited: "संपादित यात्राएं",
      edited_transaction_ids: "संपादित लेनदेन आईडी",
      vehicle_type: "वाहन प्रकार",
      vehicle_name: "वाहन नाम",
      weight_pounds: "वज़न (पाउंड)",
      drop_location_city: "ड्रॉप स्थान का शहर",
      quantity: "मात्रा",
      planned_vehicles: "योजनाबद्ध वाहन",
      trip_no: "यात्रा संख्या",
      total_planned_customers: "कुल योजनाबद्ध ग्राहक",
      total_round_trip_distance: "कुल राउंड ट्रिप दूरी",
      planning_percentage: "योजना प्रतिशत",
      max_dispatch_time: "अधिकतम डिस्पैच समय (घंटे:मिनट)",
      total_loading_time: "कुल लोडिंग समय (घंटे:मिनट)",
      total_unloading_time: "कुल अनलोडिंग समय (घंटे:मिनट)",
      planned_summary: "योजनाबद्ध सारांश",
      invalid_orders_not_shown: "नोट: अमान्य आदेश नहीं दिखाए गए",
      no_of_customers: "ग्राहकों की संख्या",
      no_of_orders: "आदेशों की संख्या",
      total_demand_volume: "कुल मांग वॉल्यूम (CFT)",
      planned_summary: "योजनाबद्ध सारांश",
      trips_removed: "हटाई गई यात्राएं",
      removed_transaction_ids: "हटाए गए लेन-देन आईडी",
      vehicle_forecast_configurations: "वाहन पूर्वानुमान विन्यास",
      pickup_point: "पिकअप पॉइंट",
      vehicle_type: "वाहन प्रकार",
      vehicle_name: "वाहन नाम",
      cost_per_miles: "प्रति मील लागत",
      driver_break_time: "चालक ब्रेक समय",
      view: "देखें",
      please_select_no_of_vehicles: "कृपया वाहन संख्या चुनें",
      no_of_vehicles: "वाहन संख्या",
      cost_per_miles: "प्रति मील लागत ($)",
      driver_break_time_from: "चालक ब्रेक समय से",
      monthly_min_planning_distance_miles: "मासिक न्यूनतम योजना दूरी (मील)",
      vehicle_type_count: "वाहन प्रकार संख्या",
      driver_break_time_per_day: "प्रति दिन चालक ब्रेक समय (घंटे:मिनट)",
      cost_per_miles_2: "प्रति मील लागत ($)",
      set_vehicle_priority: "वाहन प्राथमिकता सेट करें",
      vehicle_type_name: "वाहन प्रकार का नाम",
      min_volume_cft: "न्यूनतम वॉल्यूम (CFT)",
      max_volume_cft: "अधिकतम वॉल्यूम (CFT)",
      max_distance_per_trip_miles: "प्रति यात्रा अधिकतम दूरी (मील)",
      select_plan_id: "योजना आईडी चुनें",
      drop_details: "ड्रॉप विवरण",
      drop_location_code: "ड्रॉप स्थान कोड",
      drop_location_name: "ड्रॉप स्थान नाम",
      pickup_location: "पिकअप स्थान",
      dealer_available_from: "डीलर उपलब्ध (से)",
      dealer_available_to: "डीलर उपलब्ध (तक)",
      total_planned_weight: "कुल योजनाबद्ध वज़न",
      total_planned_volume: "कुल योजनाबद्ध वॉल्यूम",
      arrival_at_first_dealer: "पहले डीलर पर आगमन",
      departure_at_last_dealer: "अंतिम डीलर से प्रस्थान",
      return_to_plant: "प्लांट में वापसी",
      summary_dashboard: "सारांश डैशबोर्ड",
      dispatch_plan_details: "डिस्पैच योजना विवरण",
      invalid_orders_note: "नोट: अमान्य आदेश नहीं दिखाए गए",
      planned_data: "योजनाबद्ध डेटा",
      vfr_percentage: "वीएफआर प्रतिशत",
      cool_chain_adherence: "कूल चेन का पालन",
      transit_time: "परिवहन समय",
      loading_time: "लोडिंग समय",
      unloading_time: "अनलोडिंग समय",
      planned_summary: "योजनाबद्ध सारांश",
      invalid_drop_data: "अमान्य ड्रॉप डेटा",
      vehicle_utilization_km: "वाहन उपयोग (किमी)",
      distance_to_destination: "गंतव्य तक की दूरी",
      expected_delivery_date: "अपेक्षित डिलीवरी तिथि",
      planned_vehicles: "योजनाबद्ध वाहन",
      trip_no: "यात्रा संख्या",
      total_quantity: "कुल मात्रा",
      dealer_available_to: "डीलर उपलब्ध (तक)",
      drop_location_code_destination: "ड्रॉप स्थान कोड (गंतव्य)",
      order_number: "आदेश संख्या",
      demand_volume_cft: "मांग वॉल्यूम (CFT)",
      weight_pounds: "वज़न (पाउंड)",
      drop_location_name_destination: "डिलीवरी स्थान का नाम (गंतव्य)",
      note_blank_rows_columns_not_allowed:
        "नोट: खाली पंक्तियों/कॉलम की अनुमति नहीं है",
      check_blank_data_instructions:
        "खाली डेटा की जांच करने के लिए: 1. खोजें और चुनें पर क्लिक करें। 2. विशेष पर जाएं पर क्लिक करें। 3. खाली चुनें। 4. ठीक पर क्लिक करें और सभी खाली पंक्तियाँ/कोशिकाएँ हाइलाइट हो जाएँगी। 5. होम टैब के सेल्स सेक्शन में हटाएं चुनें। 6. शीट पंक्तियों को हटाएं पर क्लिक करें।",
      disclaimer_dealer_window_ot_customers:
        "अस्वीकरण: ओटी ग्राहकों के लिए उपलब्ध विंडो इनपुट फ़ाइल से मानी जाएगी, जीटी ग्राहकों का समय पिकअप/ड्रॉप स्थानों से माना जाएगा",
      quantity: "मात्रा",
      specifications: "विशेष विवरण",
      please_select_plan_id_to_compare_plans:
        "कृपया योजनाओं की तुलना करने के लिए योजना आईडी चुनें",
      vehicle_forecast_summary_dashboard: "वाहन पूर्वानुमान सारांश डैशबोर्ड",
      pickup_point: "पिकअप पॉइंट",
      view_details: "विवरण देखें",
      compare_plans: "योजनाओं की तुलना करें",
      pickup_drop_points: "पिकअप/ड्रॉप पॉइंट्स",
      edit: "संपादित करें",
      pickup: "पिकअप",
      drop: "ड्रॉप",
      both: "दोनों",
      pickup_location_code: "पिकअप स्थान कोड",
      dealer_type: "डीलर प्रकार",
      select: "चुनें",
      local: "स्थानीय",
      upcountry: "देशी",
      vehicle_restriction_list: "वाहन प्रतिबंध सूची",
      week_off: "साप्ताहिक अवकाश",
      csp_day: "सीएसपी दिवस",
      is_customer_kdd: "क्या ग्राहक केडीडी है?",
      start_month: "प्रारंभ महीना",
      end_month: "समाप्ति महीना",
      plan_id: "योजना आईडी",
      fecha_creada: "बनाई गई तारीख",
      no_of_trucks_planned: "योजना किए गए ट्रकों की संख्या",
      total_planned_volume_cft: "कुल नियोजित मात्रा (सीएफटी)",
      total_planned_weight_pounds: "कुल नियोजित वजन (पाउंड)",
      plan_run_by: "योजना द्वारा चलाई गई",
      planned_vehicles: "योजना किए गए वाहन",
      trip_no: "यात्रा संख्या",
      total_planned_customers: "कुल नियोजित ग्राहक",
      total_round_trip_distance: "कुल राउंड ट्रिप दूरी",
      planning_percentage: "योजना का प्रतिशत",
      max_dispatch_time: "अधिकतम प्रेषण समय (HH:MM)",
      total_loading_time: "कुल लोडिंग समय (HH:MM)",
      total_unloading_time: "कुल अनलोडिंग समय (HH:MM)",
      planned_summary: "योजना का सारांश",
      invalid_orders_not_shown: "नोट: अवैध आदेश नहीं दिखाए गए हैं",
      no_of_customers: "ग्राहकों की संख्या",
      no_of_orders: "आदेशों की संख्या",
      total_demand_volume: "कुल मांग मात्रा (सीएफटी)",
      planned_summary: "योजना का सारांश",
      trips_removed: "यात्राओं को हटाया गया",
      removed_transaction_ids: "हटाए गए लेन-देन आईडी",
      vehicle_forecast_configurations: "वाहन पूर्वानुमान कॉन्फ़िगरेशन",
      pickup_point: "पिकअप पॉइंट",
      vehicle_type: "वाहन का प्रकार",
      vehicle_name: "वाहन का नाम",
      cost_per_miles: "प्रति मील लागत",
      driver_break_time: "ड्राइवर ब्रेक समय",
      view: "देखें",
      please_select_no_of_vehicles: "कृपया वाहनों की संख्या चुनें",
      no_of_vehicles: "वाहनों की संख्या",
      obd_analysis: "ओबीडी विश्लेषण",
      pickup_point: "पिकअप पॉइंट",
      select_month: "महीना चुनें",
      location_radius_miles: "स्थान त्रिज्या (मील)",
      submit: "प्रस्तुत करें",
      total_obd_details: "कुल ओबीडी विवरण",
      valid_dealers: "मान्य डीलर",
      invalid_dealers: "अमान्य डीलर",
      volume: "मात्रा",
      help: "मदद",
      change_password: "पासवर्ड बदलें",
      logout: "लॉगआउट",
      weight_pounds: "वजन (पाउंड)",
      quantity: "मात्रा",
      obd_details_within_radius: "त्रिज्या के भीतर ओबीडी विवरण",
      dealers: "डीलर",
      dealers_percent: "डीलरों का प्रतिशत",
      volume_percent: "मात्रा प्रतिशत",
      weight_pounds_percent: "वजन प्रतिशत (पाउंड)",
      quantity_percent: "मात्रा प्रतिशत",
      cost_per_miles: "प्रति मील लागत ($)",
      driver_break_time_from: "ड्राइवर ब्रेक समय (से)",
      monthly_min_planning_distance_miles: "मासिक न्यूनतम योजना दूरी (मील)",
      vehicle_type_count: "वाहन प्रकार की संख्या",
      driver_break_time_per_day: "प्रति दिन ड्राइवर ब्रेक समय (HH:MM)",
      cost_per_miles_2: "प्रति मील लागत ($)",
      set_vehicle_priority: "वाहन की प्राथमिकता सेट करें",
      vehicle_type_name: "वाहन प्रकार का नाम",
      min_volume_cft: "न्यूनतम मात्रा (सीएफटी)",
      max_volume_cft: "अधिकतम मात्रा (सीएफटी)",
      max_distance_per_trip_miles: "प्रति यात्रा अधिकतम दूरी (मील)",
      select_plan_id: "योजना आईडी चुनें",
      drop_details: "ड्रॉप विवरण",
      select_vehicle_type: "वाहन प्रकार चुनें",
      select_transporter_name: "परिवहनकर्ता का नाम चुनें",
      zone: "क्षेत्र",
      state: "राज्य",
      driver_cost: "चालक की लागत ($)",
      maintenance_cost: "रखरखाव लागत ($)",
      fixed_vehicle_cost_label: "निश्चित वाहन लागत ($)",
      delete: "हटाएँ",
      state: "राज्य",
      city: "शहर",
      zone: "क्षेत्र",
      delta_warehouse: "डेल्टा गोदाम",
      zones: "क्षेत्र",
      zone_mapping: "क्षेत्र मैपिंग",
      city_not_mapped_to_state: "यह शहर किसी राज्य से मैप नहीं किया गया है",
      actions: "क्रियाएँ",
      add_zones: "+ क्षेत्र जोड़ें",
      transporter_master: "परिवहनकर्ता मास्टर",
      transporter_configurations: "परिवहनकर्ता कॉन्फ़िगरेशन",
      transporter_operations: "परिवहनकर्ता संचालन",
      transporter_code: "परिवहनकर्ता कोड",
      transporter_name: "परिवहनकर्ता का नाम",
      actions: "क्रियाएँ",
      edit: "संपादित करें",
      delete: "हटाएँ",
      zone_configuration: "क्षेत्र कॉन्फ़िगरेशन",
      vehicle_checklist_for_transportation_requirements:
        "परिवहन आवश्यकताओं के लिए वाहन चेकलिस्ट",
      transporters_configuration: "परिवहनकर्ताओं का कॉन्फ़िगरेशन",
      consolidated_route_plan_screen: "संहिताबद्ध मार्ग योजना स्क्रीन",
      plant_code: "संयंत्र कोड",
      select_plant_to_check_vehicle_availability:
        "वाहन की उपलब्धता की जाँच के लिए संयंत्र चुनें",
      vehicle_availability: "वाहन उपलब्धता",
      note_trucks_near_plant_vicinity: "नोट: संयंत्र के पास ट्रक की दूरी ",
      facility_name: "सुविधा का नाम",
      vehicle_number: "वाहन संख्या",
      transporter_code: "परिवहनकर्ता कोड",
      vehicle_status_dashboard: "वाहन स्थिति डैशबोर्ड",
      dc: "डिस्ट्रिब्यूशन सेंटर",
      drop_location_code: "ड्रॉप स्थान कोड",
      vehicle_utilization: "वाहन उपयोग",
      onward_trip_distance_miles: "आगे की यात्रा की दूरी (मील)",
      round_trip_miles: "आवागमन यात्रा (मील)",
      max_dispatch_time_hh_mm: "अधिकतम डिस्पैच समय (HH:MM)",
      arrival_at_first_dealer_start: "पहले डीलर पर आगमन (प्रारंभ)",
      departure_at_last_dealer_end: "अंतिम डीलर से प्रस्थान (समाप्ति)",
      return_to_plant_hh_mm: "संयंत्र में वापसी (HH:MM)",
      vehicle_number: "वाहन संख्या",
      vehicle_availability_from: "वाहन उपलब्धता (से)",
      vehicle_availability_to: "वाहन उपलब्धता (तक)",
      break_time_from: "ब्रेक समय (से)",
      break_time_to: "ब्रेक समय (तक)",
      no_of_vehicles: "वाहनों की संख्या",
      driver_break_time_from: "चालक ब्रेक समय (से)",
      max_distance_miles: "अधिकतम दूरी (मील)",
      monthly_min_planning_distance_miles: "मासिक न्यूनतम योजना दूरी (मील)",
      total_planned_trip_time_hrs: "कुल योजनाबद्ध यात्रा समय (घंटे)",
      total_round_trip_time_hrs: "कुल आवागमन यात्रा समय (घंटे)",
      drop_location_code: "ड्रॉप स्थान कोड",
      drop_location_name: "ड्रॉप स्थान का नाम",
      drop_location_city: "ड्रॉप स्थान का शहर",
      order_number: "आदेश संख्या",
      delivery_date: "डिलीवरी की तारीख (M/D/YYYY)",
      back: "वापस",
      pickup_location: "पिकअप स्थान",
      dealer_available_from: "डीलर उपलब्ध (से)",
      dealer_available_to: "डीलर उपलब्ध (तक)",
      total_planned_weight: "कुल योजनाबद्ध वजन",
      total_planned_volume: "कुल योजनाबद्ध आयतन",
      arrival_at_first_dealer: "पहले डीलर पर आगमन",
      departure_at_last_dealer: "अंतिम डीलर से प्रस्थान",
      return_to_plant: "संयंत्र में वापसी",
      summary_dashboard: "सारांश डैशबोर्ड",
      dispatch_plan_details: "डिस्पैच योजना विवरण",
      invalid_orders_note: "नोट: अमान्य आदेश नहीं दिखाए जा रहे हैं",
      planned_data: "योजनाबद्ध डेटा",
      vfr_percentage: "VFR प्रतिशत",
      cool_chain_adherence: "कूल चेन का अनुपालन",
      transit_time: "परिवहन समय",
      loading_time: "लोडिंग समय",
      unloading_time: "अनलोडिंग समय",
      planned_summary: "योजनाबद्ध सारांश",
      invalid_drop_data: "अमान्य ड्रॉप डेटा",
      vehicle_utilization_km: "वाहन उपयोग (KM)",
      distance_to_destination: "गंतव्य तक की दूरी",
      expected_delivery_date: "अनुमानित डिलीवरी की तारीख",
      planned_vehicles: "योजनाबद्ध वाहन",
      trip_no: "यात्रा संख्या",
      total_quantity: "कुल मात्रा",
      dealer_available_to: "डीलर उपलब्ध (तक)",
      drop_location_code_destination: "ड्रॉप स्थान कोड (गंतव्य)",
      order_number: "आदेश संख्या",
      demand_volume_cft: "मांग आयतन (CFT)",
      weight_pounds: "वजन (पाउंड)",
      quantity: "मात्रा",
      dropped_reason: "हटाए जाने का कारण",
      delivery_date: "डिलीवरी की तारीख",
      drop_location_name: "ड्रॉप स्थान का नाम",
      drop_location_city: "ड्रॉप स्थान का शहर",
      trip_no: "यात्रा संख्या",
      planned_day: "योजना का दिन",
      vehicle_no: "वाहन संख्या",
      vehicle_type: "वाहन प्रकार",
      drop_sequence: "ड्रॉप अनुक्रम",
      invoicing_date: "चालान की तारीख",
      pickup_location_source: "पिकअप स्थान (स्रोत)",
      destination_location_city: "गंतव्य का शहर",
      destination_location_state: "गंतव्य का राज्य",
      distance_to_destination_miles: "गंतव्य तक की दूरी (मील)",
      expected_delivery_date: "अनुमानित डिलीवरी की तारीख",
      time_to_destination: "गंतव्य तक का समय",
      transit_time_hh_mm: "परिवहन समय (HH:MM)",
      loading_time_hh_mm: "लोडिंग समय (HH:MM)",
      unloading_time_hh_mm: "अनलोडिंग समय (HH:MM)",
      total_planned_customers: "कुल योजनाबद्ध ग्राहक",
      total_round_trip_distance_km: "कुल आवागमन यात्रा दूरी (KM)",
      planning_percentage: "योजना प्रतिशत",
      cool_chain_adherence_percentage: "कूल चेन अनुपालन प्रतिशत",
      total_transit_time_hh_mm: "कुल परिवहन समय (HH:MM)",
      total_planned_weight_pounds: "कुल योजनाबद्ध वजन (पाउंड)",
      total_planned_volume_cft: "कुल योजनाबद्ध आयतन (CFT)",
      arrival_at_first_dealer_start: "पहले डीलर पर आगमन (प्रारंभ)",
      departure_at_last_dealer_end: "अंतिम डीलर से प्रस्थान (समाप्ति)",
      return_to_plant_hh_mm: "संयंत्र में वापसी (HH:MM)",
      total_planned_trip_time_hrs: "कुल योजनाबद्ध यात्रा समय (घंटे)",
      total_round_trip_time_hrs: "कुल आवागमन यात्रा समय (घंटे)",
      total_loading_time_hh_mm: "कुल लोडिंग समय (HH:MM)",
      total_unloading_time_hh_mm: "कुल अनलोडिंग समय (HH:MM)",
      vehicle_utilization_percentage: "वाहन उपयोग प्रतिशत (%)",
      view_order_information: "आदेश की जानकारी देखें",
      vehicle_configuration: "वाहन कॉन्फ़िगरेशन",
      summary: "सारांश",
      facility_name: "सुविधा का नाम",
      vehicle_number: "वाहन संख्या",
      vehicle_type: "वाहन प्रकार",
      transporter_code: "परिवाहक कोड",
      transporter_name: "परिवाहक का नाम",
      last_packet_time: "अंतिम पैकेट का समय",
      vehicle_checklist_for_transportation: "परिवहन के लिए वाहन चेकलिस्ट",
      assessed_by: "द्वारा मूल्यांकन",
      security_conclusion: "सुरक्षा निष्कर्ष",
      security_comments: "सुरक्षा टिप्पणियाँ",
      planned_summary: "योजना सारांश",
      planned_vehicles: "योजना अनुसार वाहन",
      unplanned_loads: "अयोजित लोड",
      invalid_drop_data: "अवैध ड्रॉप डेटा",
      vehicle_utilization_km: "वाहन उपयोग (किमी)",
      planned_data: "योजना डेटा",
      invalid_orders_note: "नोट: अवैध आदेश प्रदर्शित नहीं किए जा रहे हैं",
      set_vehicle_priority: "वाहन प्राथमिकता सेट करें",
      vehicle_min_weight_pounds: "न्यूनतम वाहन वजन (पाउंड)",
      vehicle_max_weight_pounds: "अधिकतम वाहन वजन (पाउंड)",
      vehicle_min_volume_cft: "न्यूनतम वाहन मात्रा (CFT)",
      vehicle_max_volume_cft: "अधिकतम वाहन मात्रा (CFT)",
      max_distance_miles: "अधिकतम दूरी (मील)",
      vehicle_priority: "वाहन प्राथमिकता",
      forecast_order_plan: "पूर्वानुमान आदेश योजना",
      drop_details: "ड्रॉप विवरण",
      month: "महीना",
      year: "साल",
      total_quantity: "कुल मात्रा",
      total_weight_pounds: "कुल वजन (पाउंड)",
      total_demand_volume_cft: "कुल मांग मात्रा (CFT)",
      user: "उपयोगकर्ता",
      uploaded_date: "अपलोड की गई तिथि",
      view_order_details: "आदेश विवरण देखें",
      pick_up_location_code: "पिकअप स्थान कोड",
      quantity: "मात्रा",
      drop_location: "ड्रॉप स्थान",
      drop_location_name: "ड्रॉप स्थान का नाम",
      weight: "वजन",
      volume: "मात्रा",
      delivery_date: "वितरण तिथि",
      invoice_date: "चालान तिथि",
      order_no: "आदेश संख्या",
      available_start_time: "उपलब्ध प्रारंभ समय",
      available_end_time: "उपलब्ध समाप्ति समय",
      upload_input_file: "इनपुट फ़ाइल अपलोड करें",
      download_sample_template: "नमूना टेम्पलेट डाउनलोड करें",
      select_month: "महीना चुनें",
      upload_file: "फ़ाइल अपलोड करें",
      save: "सहेजें",
      cancel: "रद्द करें",
      note_blank_rows_columns_not_allowed:
        "नोट: रिक्त पंक्तियाँ/स्तंभ अनुमत नहीं हैं",
      no_of_vehicles: "वाहनों की संख्या",
      run_plan: "योजना चलाएँ",
      vehicle_types: "वाहन प्रकार",
      consolidated_route_plan_screen: "समेकित मार्ग योजना स्क्रीन",
      start_date: "प्रारंभ तिथि",
      end_date: "समाप्ति तिथि",
      get_data: "डेटा प्राप्त करें",
      recent_plans: "हाल की योजनाएँ",
      plan_history: "योजना इतिहास",
      view_input: "इनपुट देखें",
      consolidation_id: "समेकन आईडी",
      created_date: "निर्माण तिथि",
      run_by: "द्वारा चलाया गया",
      no_of_executions: "निष्पादन की संख्या",
      execution_status: "निष्पादन की स्थिति",
      download_output: "परिणाम डाउनलोड करें",
      choose_file: "फ़ाइल चुनें",
      discard_obd: "OBD त्यागें",
      upload_obd_details: "OBD विवरण अपलोड करें",
      submit: "जमा करें",
      download_template: "टेम्पलेट डाउनलोड करें",
      bulk_upload_locations: "थोक में स्थान अपलोड करें",
      add_pickup_drop_points: "+पिकअप/ड्रॉप पॉइंट जोड़ें",
      details: "विवरण",
      date: "तिथि",
      total_trips: "कुल यात्राएँ",
      trips_edited: "संशोधित यात्राएँ",
      trips_removed: "हटाई गई यात्राएँ",
      trips_edit_percentage: "यात्रा संपादन प्रतिशत",
      plans_overview_dashboard: "योजनाओं का अवलोकन डैशबोर्ड",
      pickup_point: "पिकअप पॉइंट",
      location_type: "स्थान प्रकार",
      location_code: "स्थान कोड",
      location_name: "स्थान नाम",
      location_city: "स्थान का शहर",
      zone: "क्षेत्र",
      customer_type: "ग्राहक प्रकार",
      location_latitude: "स्थान अक्षांश",
      location_longitude: "स्थान देशांतर",
      standard_transit_time: "मानक पारगमन समय (दिन)",
      distance_from_plant: "कारखाने से दूरी (मील)",
      delta_warehouse: "डेल्टा गोदाम",
      home: "मुख्य पृष्ठ",
      run_new_plan: "नई योजना चलाएँ",
      stacking: "ढेर लगाना",
      production_planning: "उत्पादन योजना",
      forecasting: "पूर्वानुमान",
      resource_scheduling: "संसाधन अनुसूची",
      general: "सामान्य",
      pickup_drop_locations: "पिकअप/ड्रॉप स्थान",
      vehicle_configurations: "वाहन कॉन्फ़िगरेशन",
      vehicle_availability: "वाहन उपलब्धता",
      security_approved: "सुरक्षा स्वीकृत",
      security_date_of_assessment: "सुरक्षा मूल्यांकन तिथि",
      view: "देखें",
      location_code: "स्थान कोड",
      vehicle_no: "वाहन संख्या",
      stage: "चरण",
      quality_rejected: "गुणवत्ता अस्वीकृत",
      requirements_set: "आवश्यकताएँ सेट",
      security_rejected: "सुरक्षा अस्वीकृत",
      security_accepted: "सुरक्षा स्वीकृत",
      assessment_date: "मूल्यांकन तिथि",
      requirement: "आवश्यकता",
      satisfied: "संतुष्ट",
      yes: "हां",
      no: "नहीं",
      requirement_set_date: "आवश्यकता सेट तिथि",
      temperature: "तापमान",
      vehicle_operational_from: "वाहन संचालन से",
      vehicle_operational_to: "वाहन संचालन तक",
      break_time_from: "विराम समय (से)",
      break_time_to: "विराम समय (तक)",
      transporter_name: "परिवहनकर्ता का नाम",
      last_packet_time: "अंतिम पैकेट समय",
      temperature: "तापमान",
      view_vehicles: "वाहन देखें",
      transporter_name: "परिवहनकर्ता का नाम",
      max_distance_per_plan_miles: "प्रति योजना अधिकतम दूरी (मील)",
      remaining_monthly_distance_miles: "शेष मासिक दूरी (मील)",
      vehicle_next_available_date: "अगली उपलब्ध वाहन तिथि",
      owned: "स्वामित्व",
      contract: "अनुबंध",
      high: "उच्च",
      medium: "मध्यम",
      low: "निम्न",
      market: "बाजार",
      vehicles_for_today: "आज के वाहन",
      bulk_upload_master_vehicles: "बल्क अपलोड मास्टर वाहन",
      add_master_vehicle: "मास्टर वाहन जोड़ें",
      transporter_name: "परिवहनकर्ता का नाम",
      transit_type: "ट्रांजिट प्रकार",
      actions: "क्रियाएँ",
      facility_name: "सुविधा का नाम",
      vehicle_type: "वाहन प्रकार",
      vehicle_number: "वाहन संख्या",
      vehicle_priority: "वाहन प्राथमिकता",
      max_distance_per_plan_miles: "प्रति योजना अधिकतम दूरी (मील)",
      max_weight_pounds: "अधिकतम वजन (पाउंड)",
      max_volume_cft: "अधिकतम आयतन (CFT)",
      monthly_min_planning_distance_miles: "मासिक न्यूनतम योजना दूरी (मील)",
      remaining_monthly_planning_distance_miles: "शेष मासिक योजना दूरी (मील)",
      next_available_date_yyyy_mm_dd_hh_mm:
        "अगली उपलब्ध तिथि (YYYY-MM-DD HH:MM)",
      vehicle_break_down: "वाहन टूटना",
      drop_location_code: "ड्रॉप स्थान कोड",
      arrival_at_first_dealer: "पहले डीलर पर आगमन",
      departure_at_last_dealer: "अंतिम डीलर से प्रस्थान",
      average_payload: "औसत पेलोड (टन)",
      mileage: "माइलेज (मील/गैलन)",
      fuel_type: "ईंधन प्रकार",
      temperature_controlled: "तापमान नियंत्रित",
      load_characteristics: "लोड विशेषताएँ",
      glec_vehicle_type: "GLEC वाहन प्रकार",
      return_to_plant: "प्लांट पर लौटें",
      summary_dashboard: "सारांश डैशबोर्ड",
      dispatch_plan_details: "प्रेषण योजना विवरण",
      invalid_orders_note: "नोट: अमान्य आदेश नहीं दिखाए गए हैं",
      pickup_point: "पिकअप पॉइंट",
      vehicle_type_configuration: "वाहन प्रकार कॉन्फ़िगरेशन",
      vehicle_type: "वाहन प्रकार",
      vehicle_type_name: "वाहन प्रकार का नाम",
      min_volume: "न्यूनतम आयतन (CFT)",
      max_volume: "अधिकतम आयतन (CFT)",
      vehicle_priority: "वाहन प्राथमिकता",
      min_weight: "न्यूनतम वजन (पाउंड)",
      max_weight: "अधिकतम वजन (पाउंड)",
      max_distance_trip: "प्रति यात्रा अधिकतम दूरी (मील)",
      monthly_min_planning_distance: "मासिक न्यूनतम योजना दूरी",
      cancel: "रद्द करें",
      save: "सहेजें",
      facility_name: "सुविधा का नाम",
      vehicle_no: "वाहन संख्या",
      address: "पता",
      last_packet_time: "अंतिम पैकेट समय",
      latitude: "अक्षांश",
      longitude: "देशांतर",
      service_provider: "सेवा प्रदाता",
      temperature: "तापमान",
      on_trip: "यात्रा पर",
      requirement: "आवश्यकता",
      requirement_type: "आवश्यकता प्रकार",
      security: "सुरक्षा",
      assessed_by: "द्वारा मूल्यांकन किया गया",
      security_conclusion: "सुरक्षा निष्कर्ष",
      security_comments: "सुरक्षा टिप्पणियाँ",
      assessment_start_date: "मूल्यांकन प्रारंभ तिथि",
      assessment_end_date: "मूल्यांकन समाप्ति तिथि",
      get_data: "डेटा प्राप्त करें",
      add_requirements: "आवश्यकताएँ जोड़ें",
      add_vehicle_checklist: "वाहन चेकलिस्ट जोड़ें",
      actions: "क्रियाएँ",
      edit: "संपादित करें",
      delete: "हटाएँ",
      vehicle_configurations: "वाहन विन्यास",
      vehicle_availability_configurations: "वाहन उपलब्धता विन्यास",
      vehicle_location: "वाहन स्थान",
      vehicle_master: "वाहन मास्टर",
      vehicle_availability: "वाहन उपलब्धता",
      vehicle_details: "वाहन विवरण",
      monthly_min_distance: "मासिक न्यूनतम दूरी (मील)",
      max_weight_per_trip: "प्रति यात्रा अधिकतम वज़न (पाउंड)",
      max_distance_per_trip: "प्रति यात्रा अधिकतम दूरी (मील)",
      planned_data: "योजनाबद्ध डेटा",
      vfr_percentage: "वीएफआर प्रतिशत",
      cool_chain_adherence: "कूल चेन का पालन",
      transit_time: "परिवहन समय",
      loading_time: "लोडिंग समय",
      unloading_time: "अनलोडिंग समय",
      planned_summary: "योजनाबद्ध सारांश",
      invalid_drop_data: "अमान्य ड्रॉप डेटा",
      vehicle_utilization_km: "वाहन उपयोग (किमी)",
      distance_to_destination: "गंतव्य तक की दूरी",
      expected_delivery_date: "अपेक्षित डिलीवरी तिथि",
      trip_no: "यात्रा संख्या",
      total_quantity: "कुल मात्रा",
      demand_volume_cft: "मांग वॉल्यूम (CFT)",
      weight_pounds: "वज़न (पाउंड)",
      quantity: "मात्रा",
      dropped_reason: "छोड़ने का कारण",
      delivery_date: "डिलीवरी तिथि",
      drop_location_city: "ड्रॉप स्थान का शहर",
      planned_day: "योजनाबद्ध दिन",
      vehicle_no: "वाहन संख्या",
      drop_sequence: "ड्रॉप अनुक्रम",
      invoicing_date: "चालान तिथि",
      pickup_location_source: "पिकअप स्थान (स्रोत)",
      destination_location_city: "गंतव्य का शहर",
      destination_location_state: "गंतव्य राज्य",
      distance_to_destination_miles: "गंतव्य तक की दूरी (मील)",
      time_to_destination: "गंतव्य तक का समय",
      dealer_available_from: "डीलर उपलब्ध (से)",
      dealer_available_to: "डीलर उपलब्ध (तक)",
      total_planned_weight: "कुल योजनाबद्ध वज़न",
      total_planned_volume: "कुल योजनाबद्ध वॉल्यूम",
      arrival_at_first_dealer_start: "पहले डीलर पर आगमन (शुरुआत)",
      departure_at_last_dealer_end: "अंतिम डीलर से प्रस्थान (समाप्ति)",
      return_to_plant_hh_mm: "प्लांट में वापसी (घंटे:मिनट)",
      total_planned_trip_time_hrs: "कुल योजनाबद्ध यात्रा समय (घंटे)",
      total_round_trip_time_hrs: "कुल राउंड ट्रिप समय (घंटे)",
      total_loading_time_hh_mm: "कुल लोडिंग समय (घंटे:मिनट)",
      total_unloading_time_hh_mm: "कुल अनलोडिंग समय (घंटे:मिनट)",
      vehicle_utilization_percentage: "वाहन उपयोग प्रतिशत",
      view_order_information: "आदेश जानकारी देखें",
      vehicle_configuration: "वाहन विन्यास",
      summary: "सारांश",
      planned_summary: "योजनाबद्ध सारांश",
      planned_vehicles: "योजनाबद्ध वाहन",
      unplanned_loads: "अयोजित लोड",
      invalid_drop_data: "अमान्य ड्रॉप डेटा",
      set_vehicle_priority: "वाहन प्राथमिकता सेट करें",
      forecast_order_plan: "पूर्वानुमान आदेश योजना",
      volume_cft: "वॉल्यूम (CFT)",
      dealer_available_time_error: "प्रारंभ समय समाप्ति समय से कम होना चाहिए",
      ok: "ठीक है",
      get_data: "डेटा प्राप्त करें",
      plan_history: "योजना इतिहास",
      upload_file: "फ़ाइल अपलोड करें",
      save: "सहेजें",
      cancel: "रद्द करें",
      delete: "हटाएँ",
      plant_name: "प्लांट नाम",
      total_trips: "कुल यात्राएं",
      trips_edited: "संपादित यात्राएं",
      vfr_percentage: "वीएफआर प्रतिशत",
      total_transit_time_hh_mm: "कुल परिवहन समय (घंटे)",
      drop_location_name_destination: "ड्रॉप स्थान नाम (गंतव्य)",
      unloading_time_hh_mm: "अनलोडिंग समय (घंटे:मिनट)",
      edited_transaction_ids: "संपादित लेन-देन आईडी",
      vehicle_type: "वाहन प्रकार",
      vehicle_name: "वाहन नाम",
      weight_pounds: "वज़न (पाउंड)",
      plant_code: "प्लांट कोड",
      plant_name: "प्लांट नाम",
      total_trips: "कुल यात्राएं",
      trips_edited: "संपादित यात्राएं",
      last_30_days_trips_modification_details:
        "पिछले 30 दिनों की यात्रा संशोधन विवरण",
      last_7_days_trips_modification_details:
        "पिछले 7 दिनों की यात्रा संशोधन विवरण",
      planning_progress_past_7_weeks: "पिछले 7 सप्ताहों की योजना प्रगति",
      edited_transaction_ids: "संपादित लेन-देन आईडी",
      trips_removed: "हटाई गई यात्राएं",
      removed_transaction_ids: "हटाए गए लेन-देन आईडी",
      drop_location_city: "ड्रॉप स्थान का शहर",
      quantity: "मात्रा",
      dropped_reason: "छोड़ने का कारण",
      delivery_date: "डिलीवरी की तारीख",
      drop_location_name: "डिलीवरी स्थान का नाम",
      drop_location_city: "डिलीवरी स्थान का शहर",
      trip_no: "यात्रा संख्या",
      planned_day: "नियोजित दिन",
      vehicle_no: "वाहन संख्या",
      vehicle_type: "वाहन का प्रकार",
      drop_sequence: "ड्रॉप अनुक्रम",
      invoicing_date: "चालान की तारीख",
      pickup_location_source: "पिकअप स्थान (स्रोत)",
      destination_location_city: "गंतव्य स्थान का शहर",
      destination_location_state: "गंतव्य स्थान का राज्य",
      distance_to_destination_miles: "गंतव्य तक की दूरी (मील)",
      expected_delivery_date: "अनुमानित डिलीवरी की तारीख",
      time_to_destination: "गंतव्य तक का समय",
      invalid_material: "अमान्य सामग्री",
      drop_location_code_destination: "डिलीवरी स्थान कोड (गंतव्य)",
      demand_volume_cft: "मांग की मात्रा (सीएफटी)",
      dropped_reason: "छोड़ने का कारण",
      dealer_location_data_not_in_system: "डीलर स्थान डेटा सिस्टम में नहीं है",
      delivery_date_less_than_current:
        "डिलीवरी की तारीख वर्तमान तारीख से कम है",
      missing_dealer_information: "डीलर जानकारी गायब है",
      transit_time_hh_mm: "ट्रांजिट समय (HH:MM)",
      loading_time_hh_mm: "लोडिंग समय (HH:MM)",
      unloading_time_hh_mm: "अनलोडिंग समय (HH:MM)",
      actions: "क्रियाएँ",
      expected_delivery_date: "अनुमानित डिलीवरी की तारीख",
      max_dispatch_time: "अधिकतम प्रेषण समय (HH:MM)",
      unplanned_loads: "अयोजित लोड",
      monthly_data: "मासिक डेटा",
      forecast_plan_history: "पूर्वानुमान योजना इतिहास",
      obd_analysis: "ओबीडी विश्लेषण",
      dealer_available_time_error: "प्रारंभ समय समाप्ति समय से कम होना चाहिए",
      drop_location_name: "ड्रॉप स्थान का नाम",
      drop_location_city: "ड्रॉप स्थान का शहर",
      order_number: "आदेश संख्या",
      delivery_date: "डिलीवरी की तारीख (M/D/YYYY)",
      back: "वापस",
      arrival_at_first_dealer_start: "पहले डीलर पर आगमन (प्रारंभ)",
      departure_at_last_dealer_end: "अंतिम डीलर से प्रस्थान (समाप्ति)",
      return_to_plant_hh_mm: "संयंत्र में वापसी (HH:MM)",
      total_planned_trip_time_hrs: "कुल योजनाबद्ध यात्रा समय (घंटे)",
      total_round_trip_time_hrs: "कुल आवागमन यात्रा समय (घंटे)",
      dealer_available_to: "डीलर उपलब्ध (तक)",
      upload_input_file: "इनपुट फ़ाइल अपलोड करें",
      upload_file: "फ़ाइल अपलोड करें",
      bulk_upload_master_vehicles: "मास्टर वाहनों का बल्क अपलोड",
      add_master_vehicle: "मास्टर वाहन जोड़ें",
      dealer_available_time_error: "प्रारंभ समय अंत समय से कम होना चाहिए",
      plant_wise_configurations: "प्लांट-वार कॉन्फ़िगरेशन",
      monthly_min_planning_distance: "मासिक न्यूनतम योजना दूरी",
      pickup_point: "पिकअप पॉइंट",
      fix_and_reupload: "सुधारें और पुनः अपलोड करें",
      club_previous_order: "पिछले आदेश को मिलाएं",
      ignore: "नज़रअंदाज़ करें",
      dealer_code: "डीलर कोड",
      remarks: "टिप्पणियाँ",
      run_new_plan: "नई योजना चलाएं",
      consolidated_route_plan_screen: "समेकित मार्ग योजना स्क्रीन",
      actions: "क्रियाएँ",
    },
  },
  fr: {
    translation: {
      "route_type": "Type de Route",
      vehicles_recommended: "Véhicules Recommandés",
      "Number Of Trips": "Nombre de trajets",
  "Total": "Total",
  "Day-2": "Jour-2",
  "Day-3": "Jour-3",
  "Day-4": "Jour-4",
  "Day-5": "Jour-5",
  "Day-6": "Jour-6",
  "Distance / Time From Pickup Location": "Distance / Temps depuis le lieu de ramassage",
  "Distance / Time From Previous Drop Point": "Distance / Temps depuis le dernier point de dépôt",
  "Location City": "Ville de localisation",
  "Planned Day": "Jour prévu",
  "Quantity": "Quantité",
  "Demand Weight": "Poids de la demande",
  "Demand Volume": "Volume de la demande",
  "Location": "Emplacement",
  "Weight": "Poids",
  "View Onward Route": "Voir le trajet aller",
  "View Return Route": "Voir le trajet retour",
  "Quantity": "Quantité",
  "Weight": "Poids",
  "Spot": "Emplacement",
  "Local": "Local",
  "Upcountry": "Région rurale",
  "Volume": "Volume",
  "IN416": "IN416",
  "IN436": "IN436",
  "Spot": "Spot",
  "2,177": "2,177",
  "1,268": "1,268",
  "909": "909",
  "0": "0",
  "Planned Truck Load": "Charge de camion planifiée",
  "Quantity (cases)": "Quantité (cas)",
  "Weight (Kgs)": "Poids (Kgs)",
  "Volume (CMT)": "Volume (CMT)",
  "1,81,970": "1,81,970",
  "25,25,554": "25,25,554",
  "8,249": "8,249",
  "Unplanned Load": "Charge non planifiée",
  "26,324": "26,324",
  "3,74,549": "3,74,549",
  "1,234": "1,234",
  "Average Distance Travelled Per Vehicle (KM)": "Distance moyenne parcourue par véhicule (KM)",
  "35,426": "35,426",
  "37,624": "37,624",
  "28,111": "28,111",
  "Operational Cost (Rs.)": "Coût opérationnel (Rs.)",
  "1,62,57,137": "1,62,57,137",
  "1,31,64,341": "1,31,64,341",
  "30,92,797": "30,92,797",
  "Average Vehicle Fill Rate": "Taux de remplissage moyen du véhicule",
  "60 %": "60 %",
  "42 %": "42 %",
  "79 %": "79 %",
  "Unplanned Load Proportion": "Proportion de charge non planifiée",
  "12 %": "12 %",
  "Average Vehicle Utilization As Per SOB": "Utilisation moyenne des véhicules selon le SOB",
  "104 %": "104 %",
  "94 %": "94 %",
  "Ontime Dispatches": "Expéditions à l'heure",
  "Local": "Local",
  "frefe": "frefe",
  "94 %": "94 %",
  "92 %": "92 %",
  "Ontime Deliveries": "Livraisons à l'heure",
  "97 %": "97 %",
  "94 %": "94 %",
      last_30_days_trips_modification_details: "Détails de modification des trajets des 30 derniers jours",
last_7_days_trips_modification_details: "Détails de modification des trajets des 7 derniers jours",
planning_progress_past_7_weeks: "Progrès de la planification des 7 dernières semaines",
      "Please Select Scenario to Compare Plans": "Veuillez sélectionner un scénario pour comparer les plans",
"Percentage": "Pourcentage",
"Forecast Scenario": "Scénario de prévision",
      "Number of kms Travelled": "Nombre de kms parcourus",
"Cost": "Coût",
"Vehicle Fill Rate": "Taux de remplissage du véhicule",
"Vehicle KM Run %": "Pourcentage de kms parcourus par le véhicule",
      "Please Select File": "Veuillez sélectionner le fichier",
"Processing Plan. Please wait.....": "Plan en cours de traitement. Veuillez patienter.....",
"File is processing": "Le fichier est en cours de traitement",
"Enter Input Data To Continue": "Entrez les données d'entrée pour continuer",
"No Data": "Aucune donnée",
"please_select_date": "Veuillez sélectionner une date",
"data_uploaded": "Données téléchargées",
"Please Select No of Vehicles": "Veuillez sélectionner le nombre de véhicules",
"Please Input Valid Data": "Veuillez entrer des données valides",
      "note_trucks_near_plant_vicinity":"Remarque : Camions près de l'usine à une distance de",
      "loading....": "Chargement....",
      "driver_break_time_to": "Temps de pause du conducteur jusqu'à",
"max_seating_capacity": "Capacité maximale de sièges",
"min_seating_capacity": "Capacité minimale de sièges",
"vehicle_seating_capacity": "Capacité de sièges du véhicule",
"vehicle_capacity_cmt": "Capacité du véhicule (m³)",
      "change_password": "Changer le mot de passe",
  "new_password": "Nouveau mot de passe",
  "confirm_new_password": "Confirmer le nouveau mot de passe",
  "old_password": "Ancien mot de passe",
  "enter_email": "Entrez votre email",
  "enter_new_password": "Entrez votre nouveau mot de passe",
  "confirm_new_password_entry": "Confirmez votre nouveau mot de passe",
  "select_cluster": "Sélectionner un cluster",
  "Overall Cluster Wise Vehicles": "Véhicules par cluster général",
  "Discard": "Annuler",
  "obd": "OBD",
  "Dispatch Planning is in progress..!!!": "La planification de l'expédition est en cours..!!!",
  "Estimated Time": "Temps estimé",
  "GPS": "GPS",
  "Spot Vehicles To Be Planned": "Véhicules Spot à planifier",
  "No Of Spot Vehicles": "Nombre de véhicules Spot",
  "Availability DateTime": "Date et heure de disponibilité",
  "Notify Email Users After Process (comma seperated)": "Notifier les utilisateurs par e-mail après le processus (séparés par des virgules)",
  "Max Time Limit(Hrs)": "Durée limite maximale (heures)",
  "vfr": "VFR",
  "Disclaimer": "Avertissement : La fenêtre disponible pour les concessionnaires pour les clients OT sera considérée à partir du fichier d'entrée, les horaires des clients GT seront pris en compte à partir des lieux de ramassage/livraison.",
  "To Check for Blank Data": "Pour vérifier les données vides : 1. Cliquez sur 'Rechercher et sélectionner'. 2. Cliquez sur 'Aller à spécial'. 3. Choisissez 'Vides'. 4. Cliquez sur OK et toutes les lignes/cellules vides seront mises en surbrillance. 5. Choisissez 'Supprimer' dans la section Cellules de l'onglet Accueil. 6. Cliquez sur 'Supprimer les lignes de feuille'.",
  "Add New OBD": "Ajouter un nouveau OBD",
  "All drop locations are invalid, Please check..!!": "Toutes les adresses de dépôt sont invalides, veuillez vérifier..!!",
  "Please upload file having extensions .csv or .xlsx only.": "Veuillez télécharger un fichier avec des extensions .csv ou .xlsx uniquement.",
  "Dispatch Load Volume Summary (CFT)": "Résumé du volume des charges expédiées (CFT)",
  "Total Loads Volume (CFT)": "Volume total des charges (CFT)",
  "Planned Loads Volume (CFT)": "Volume des charges planifiées (CFT)",
  "Unplanned Loads Volume (CFT)": "Volume des charges non planifiées (CFT)",
  "Dropped Loads Volume (CFT)": "Volume des charges annulées (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "Résumé du poids des charges expédiées (livres)",
  "Total Loads Weight (Pounds)": "Poids total des charges (livres)",
  "Planned Loads Weight (Pounds)": "Poids des charges planifiées (livres)",
  "Unplanned Loads Weight (Pounds)": "Poids des charges non planifiées (livres)",
  "Dropped Loads Weight (Pounds)": "Poids des charges annulées (livres)",
  "Dispatch Load Quantity Summary": "Résumé de la quantité des charges expédiées",
  "Total Quantity": "Quantité totale",
  "Planned Quantity": "Quantité planifiée",
  "Unplanned Quantity": "Quantité non planifiée",
  "Dropped Quantity": "Quantité annulée",
  "Dispatch Load Volume Summary (CFT)": "Résumé du volume des charges expédiées (CFT)",
  "Total Loads Volume (CFT)": "Volume total des charges (CFT)",
  "Planned Loads Volume (CFT)": "Volume des charges planifiées (CFT)",
  "Unplanned Loads Volume (CFT)": "Volume des charges non planifiées (CFT)",
  "Dropped Loads Volume (CFT)": "Volume des charges annulées (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "Résumé du poids des charges expédiées (livres)",
  "Total Loads Weight (Pounds)": "Poids total des charges (livres)",
  "Planned Loads Weight (Pounds)": "Poids des charges planifiées (livres)",
  "Unplanned Loads Weight (Pounds)": "Poids des charges non planifiées (livres)",
  "Dropped Loads Weight (Pounds)": "Poids des charges annulées (livres)",
  "Dispatch Load Quantity Summary": "Résumé de la quantité des charges expédiées",
  "Total Quantity": "Quantité totale",
  "Planned Quantity": "Quantité planifiée",
  "Unplanned Quantity": "Quantité non planifiée",
  "Dropped Quantity": "Quantité annulée",
  "Dispatch Load Weight Summary (Pounds)": "Résumé du poids des charges expédiées (livres)",
  "Dispatch Load Volume Summary (CFT)": "Résumé du volume des charges expédiées (CFT)",
  "Dropped Data": "Données abandonnées",
  "ontime_delivery": "Livraison à temps",
  "dealer_Available_start_time_should_be_less_than_end_time": "L'heure de début de disponibilité du concessionnaire doit être inférieure à l'heure de fin",
  "Removed": "Supprimé",
  "Unedited": "Non édité",
  "Edited": "Édité",
      vehicle_availability_configurations: "Configurations de Disponibilité des Véhicules",
      "address": "Adresse",
      vehicle_status_dashboard: "Tableau de Bord de l'État des Véhicules",
      vehicles: "Véhicules",
      location: "Emplacement",
      transporter_configurations: "Configurations du Transporteur",
      transporter_operations: "Opérations du Transporteur",
      vehicle_master :"Maître de Véhicule",
      add_requirements: "Ajouter des Exigences",
      max_distance_per_plan_miles: "Distance Maximale par Plan (Miles)",
      add_city_wise_cost: "Ajouter un Coût par Ville",
      add_zone_wise_cost: "Ajouter un Coût par Zone",
      scenarios: "Scénarios",
      plant: "Usine",
      standard_transit_time_days: "Temps de Transit Standard (Jours)",
    drop_to_time: "Heure de Dépose",
    drop_from_time: "Heure de Départ",
    pickup_to_time: "Heure de Ramassage",
    pickup_from_time: "Heure de Début de Ramassage",
    distance_from_plant_miles: "Distance Depuis l'Usine (Miles)",
    vehicle_next_available_date: "Prochaine Date Disponible du Véhicule",
    max_distance_per_plan_miles: "Distance Maximale par Plan (Miles)",
    remaining_monthly_distance_miles: "Distance Mensuelle Restante (Miles)",
    assign_quality_checks: "Attribuer des Vérifications de Qualité",
    add_fixed_vehicle_cost: "Ajouter un Coût Fixe pour le Véhicule",
      customer: "Client",
      "Pickup Locations (Drop)*": "Lieux de Ramassage (Dépose)*",
      "pickup_locations_drop*": "Lieux de Ramassage (Dépose)*",
      percent_unplanned: "Pourcentage Non Planifié",
      duplicate_obds_light_red: "Les OBD dupliqués sont affichés en rouge clair",
    new_obds_green: "Les OBD nouvellement ajoutés sont affichés en vert",
    pickup_locations_drop: "Lieux de Ramassage (Dépose)",
      invalid_orders_not_shown: "Les commandes invalides ne sont pas affichées",
    note: "Remarque",
      please_select_scenario: "Veuillez sélectionner un scénario pour comparer les plans",
    avg_kms_travelled: "Km Moyens Parcourus",
    total_cost: "Coût Total",
    total_vehicle_fill_rate: "Taux de Remplissage Total des Véhicules",
    percent_km_run_sob: "Pourcentage de KM Parcourus SOB",
    percent_ontime_dispatch: "Pourcentage de Dispatchs à l'Heure",
    upcountry: "Campagne",
    percent_spot_vehicles_trips: "Pourcentage de Voyages de Véhicules Spot",
    round_trip_distance: "Distance Aller-Retour",
    total_trip_distance: "Distance Totale du Voyage",
    vehicle_type: "Type de Véhicule",
    plant_code: "Code de l'Usine",
    vehicle_no: "Numéro du Véhicule",
    monthly_sob_km: "SOB Mensuel (Km)",
    distance_travelled_km: "Distance Parcourue (Km)",
    pickup_location_source: "Lieu de Ramassage (Source)",
    drop_location_code_destination: "Code du Lieu de Dépose (Destination)",
    drop_location_name_destination: "Nom du Lieu de Dépose (Destination)",
    order_number: "Numéro de Commande",
    invoicing_date: "Date de Facturation",
    quantity: "Quantité",
    weight_kgs: "Poids (Kg)",
    demand_volume_m3: "Volume Demandé (M3)",
    dealer_available_from: "Disponible chez le Concessionnaire à partir de",
    dealer_available_to: "Disponible chez le Concessionnaire jusqu'à",
    delivery_date: "Date de Livraison",
    trip_no: "Numéro du Voyage",
    planned_day: "Jour Planifié",
    drop_sequence: "Séquence de Dépose",
    destination_location_city: "Ville de Destination",
    destination_location_state: "État de Destination",
    volume_m3: "Volume (M3)",
    distance_to_destination_kms: "Distance Jusqu'à la Destination (Km)",
    expected_delivery_date: "Date de Livraison Prévue",
    time_to_destination: "Temps jusqu'à la Destination",
    transit_time_hhmm: "Temps de Transit (HH:MM)",
    loading_time_hhmm: "Temps de Chargement (HH:MM)",
    unloading_time_hhmm: "Temps de Déchargement (HH:MM)",
    start_month: "Mois de Début",
    end_month: "Mois de Fin",
    get_data: "Obtenir des Données",
    select_chart_type: "Sélectionner le Type de Graphique",
    fleet_analysis: "Analyse de la Flotte",
    system_recommended: "Système Recommandé",
    total_load: "Charge Totale",
    serviceability: "Disponibilité",
    in416: "IN416",
    in436: "IN436",
    user_recommended: "Recommandé par l'Utilisateur",
    threshold: "Seuil",
    number_of_trips: "Nombre de Voyages",
    total: "Total",
    spot: "Spot",
    planned_truck_load: "Charge Camion Planifiée",
    quantity_cases: "Quantité (caisses)",
    weight_kgs: "Poids (Kgs)",
    volume_cmt: "Volume (CMT)",
    unplanned_load: "Charge Non Planifiée",
    average_distance_travelled_per_vehicle_km: "Distance Moyenne Parcourue par Véhicule (KM)",
    operational_cost_rs: "Coût Opérationnel (Rs.)",
    average_vehicle_fill_rate: "Taux de Remplissage Moyen des Véhicules",
    unplanned_load_proportion: "Proportion de Charge Non Planifiée",
    average_vehicle_utilization_as_per_sob: "Utilisation Moyenne des Véhicules Selon SOB",
    ontime_dispatches: "Envois à l'Heure",
    local: "Local",
    upcountry: "Province",
    ontime_deliveries: "Livraisons à l'Heure",
    bar: "Barre",
    load: "Charge",
    month: "Mois",
    percentage: "%",





      // code for configurations screens

      "Consider Transporter SOB": "Envisager le transporteur SOB",
      forecast_summary: "Résumé des Prévisions",
      compare: "Comparer",
    pickup_locations_drop: "Lieux de Ramassage (Dépose)",
      "note_highlighted_rows_red": "Note : 1. Les lignes en rouge manquent de données GPS ou d'intégration, marquez la disponibilité du véhicule manuellement.",
  "highlighted_rows_green": "2. Les lignes en vert sont considérées pour la planification",
  "enable_reconciliation": "Activer la réconciliation",
  "enable_dc_week_off_planning": "Activer la planification de la semaine de congé du centre de données",
      enable_disable: "Activer/Désactiver",
      "Consider Dropping Distant Smaller Loads":
        "Envisager de laisser tomber les petites charges éloignées",
      "Consider Realtime Vehicle Available Times":
        "Envisager les horaires disponibles des véhicules en temps réel",
      "Plant Wise Vehicle Type Configuration*":
        "Configuration du type de véhicule par usine*",
      "Consignees / Dealers Locations (Drop)*":
        "Emplacements des consignataires / concessionnaires (Déposer)*",
      "Pickup Locations (Pickup)*": "Emplacements de ramassage (Ramassage)*",
      "Optimization Type*": "Type d'optimisation*",
      "Plant Wise Planning History*": "Historique de planification par usine*",
      "Plant Wise Plan Summary*": "Résumé du plan par usine*",
      "Plant Wise New Plan Creation*": "Création de nouveau plan par usine*",
      "Suppliers / Vendor Locations (Pickup)*":
        "Emplacements des fournisseurs / vendeurs (Ramassage)*",
      "Plant Wise Vehicle Type Configuration*":
        "Configuration du type de véhicule par usine*",
      "Consignees / Dealers Locations (Drop)*":
        "Emplacements des consignataires / concessionnaires (Déposer)*",
      "Pickup Locations (Pickup)*": "Emplacements de ramassage (Ramassage)*",
      "Plant Wise Vehicle Type Configuration*":
        "Configuration du type de véhicule par usine*",
      "Suppliers / Vendor Locations (Pickup)*":
        "Emplacements des fournisseurs / vendeurs (Ramassage)*",
      "Plant Wise Planning History*": "Historique de planification par usine*",
      "Plant Wise Plan Summary*": "Résumé du plan par usine*",
      "Plant Wise New Plan Creation*": "Création de nouveau plan par usine*",
      "Enable/Disable Dispatch Planning Module":
        "Activer/Désactiver le module de planification des envois",
      Inbound: "Entrant",
      Outbound: "Sortant",
      "Suppliers / Vendor Locations (Pickup)":
        "Emplacements des fournisseurs / vendeurs (Ramassage)",
      "Operational Hours": "Heures d'opération",
      "Pickup Locations (Drop)": "Emplacements de ramassage (Déposer)",
      "Plant Wise Vehicle Type Configuration":
        "Configuration du type de véhicule par usine",
      "Max No. Of Vehicle Types": "Nombre maximal de types de véhicules",
      "Vehicle Load Capacity Min / Max Configuration Preferences":
        "Préférences de configuration de la capacité de charge des véhicules min / max",
      "Vehicle Load Weight Min / Max Preferences":
        "Préférences de poids de charge des véhicules min / max",
      "Distance preferences": "Préférences de distance",
      "Vehicle Priorities": "Priorités des véhicules",
      "Vehicle Operational Times": "Horaires opérationnels des véhicules",
      "Vehicle Availability": "Disponibilité des véhicules",
      "Plant Wise Freight Configuration": "Configuration des frets par usine",
      "Plant Wise Transporter Configuration":
        "Configuration des transporteurs par usine",
      "Route Preferences": "Préférences d'itinéraire",
      "Plant Wise New Plan Creation": "Création de nouveau plan par usine",
      "Plant Wise Plan Summary": "Résumé du plan par usine",
      "Plant Wise Planning History": "Historique de planification par usine",
      "No of Vehicles Types": "Nombre de types de véhicules",
      "Generic Time Windows": "Fenêtres temporelles génériques",
      "Individual Vehicle Time Windows":
        "Fenêtres temporelles individuelles des véhicules",
      "Availability By Vehicle Type": "Disponibilité par type de véhicule",
      "Availability By Individual Vehicle":
        "Disponibilité par véhicule individuel",
      "Monthly Minimum Planning Distance Preferences":
        "Préférences de distance minimale de planification mensuelle",
      "GPS Based Availability": "Disponibilité basée sur le GPS",
      "Truck Location Visibility": "Visibilité de l'emplacement du camion",
      "Plant Vicinity Circle Radius": "Rayon du cercle de proximité de l'usine",
      "Fixed Vehicle Costs": "Coûts fixes des véhicules",
      "Variable Costs": "Coûts variables",
      "Vehicle Type Level Variable Costs":
        "Coûts variables au niveau du type de véhicule",
      "City Level Vehicle Type Costs":
        "Coûts des types de véhicules au niveau de la ville",
      "Per CFT Cost": "Coût par CFT",
      "Per Mile Cost": "Coût par mile",
      "Zone Level Cost": "Coût au niveau de la zone",
      "Transporter Share of Business": "Part de l'entreprise du transporteur",
      "Sustainability Integration": "Intégration de la durabilité",
      "Optimization Type": "Type d'optimisation",
      "Cost Optimization": "Optimisation des coûts",
      "Weight based optimization": "Optimisation basée sur le poids",
      "Distance based optimization": "Optimisation basée sur la distance",
      "Time based optimization": "Optimisation basée sur le temps",
      "Volume based optimization": "Optimisation basée sur le volume",
      "Prioritize Local Loads": "Prioriser les charges locales",
      "Radius Miles": "Rayon en miles",
      "Number of Loading Docks": "Nombre de quais de chargement",
      "City Based Entry Restriction": "Restriction d'entrée basée sur la ville",
      "Unloading time per case/max unloading time":
        "Temps de déchargement par cas / temps de déchargement maximum",
      "Consider Vehicle Break Times (Night Driving)":
        "Prendre en compte les pauses des véhicules (conduite de nuit)",
      "Consider Holiday Restrictions":
        "Prendre en compte les restrictions de vacances",
      "Vehicle Restriction": "Restriction des véhicules",
      "Plan Spot Vehicles": "Planifier les véhicules de spot",
      "Max No of Customers Per Vehicle":
        "Nombre maximal de clients par véhicule",
      "Use Dynamic Planning Window":
        "Utiliser la fenêtre de planification dynamique",
      "Number of Days to Use Planning Window":
        "Nombre de jours pour utiliser la fenêtre de planification",
      "Use Volume for Vehicle Fill Rate (VFR)":
        "Utiliser le volume pour le taux de remplissage du véhicule (VFR)",
      "Use Weight for Vehicle Fill Rate (VFR)":
        "Utiliser le poids pour le taux de remplissage du véhicule (VFR)",
      "Min VFR Per Vehicle Type": "VFR minimum par type de véhicule",
      "Min VFR Per Spot Vehicle": "VFR minimum par véhicule de spot",
      "Prioritize Same Dealers First in a Vehicle":
        "Prioriser les mêmes concessionnaires en premier dans un véhicule",
      "Prioritize Multi City Dealers First in a Vehicle":
        "Prioriser les concessionnaires multi-villes en premier dans un véhicule",
      "Dispatch within Days": "Expédition sous quelques jours",
      "Allow Unplanned Loads": "Autoriser les charges non planifiées",
      "Minimize Number of Vehicles Used":
        "Minimiser le nombre de véhicules utilisés",
      "Dealer Buffer Time": "Temps tampon du concessionnaire",
      "Buffer Time(Mins)": "Temps tampon (minutes)",
      "Month End Planning Start Day":
        "Jour de début de la planification de fin de mois",
      "Day of Every Month": "Jour de chaque mois",
      "Max Local No of Customers Per Vehicle":
        "Nombre maximal de clients locaux par véhicule",
      "Max Upcountry No of Customers Per Vehicle":
        "Nombre maximal de clients ruraux par véhicule",
      "Planning Type": "Type de planification",
      "Local Prioritization": "Priorisation locale",
      "Local Prioritization and Virtual Upcountry clubbing":
        "Priorisation locale et regroupement rural virtuel",
      "General Plan (Local and Upcountry clubbing)":
        "Plan général (regroupement local et rural)",
      "Telescopic Deviation": "Déviation télescopique",
      "MOQ for Planning": "Quantité minimum de commande pour la planification",
      Reconciliation: "Réconciliation",
      "DC Week Off planning": "Planification de la semaine de repos DC",
      "Save Inbound Configurations": "Enregistrer les configurations entrantes",
      "Save Outbound Configurations":
        "Enregistrer les configurations sortantes",
      SAVE: "ENREGISTRER",
      "Enable Reconciliation": "Activer la réconciliation",
      "Enable DC Week Off planning":
        "Activer la planification de la semaine de repos DC",
      "Deviation Alert": "Alerte de déviation",
      "Overload Warning": "Avertissement de surcharge",
      "Underload Alert": "Alerte de sous-charge",
      "Fuel Efficiency Report": "Rapport d'efficacité énergétique",
      "Vehicle Downtime": "Temps d'arrêt des véhicules",
      "Speed Violation Alert": "Alerte de violation de vitesse",
      "Trip Delay Notification": "Notification de retard de voyage",
      "Idle Time Monitoring": "Surveillance du temps d'inactivité",
      "Driver Performance": "Performance du conducteur",
      "Realtime GPS Tracking": "Suivi GPS en temps réel",
      "Fleet Utilization": "Utilisation de la flotte",
      "Trip Efficiency": "Efficacité du voyage",
      "Dynamic Route Adjustment": "Ajustement dynamique de l'itinéraire",
      "Load Distribution Optimization":
        "Optimisation de la distribution des charges",
      "Transporter Preferences": "Préférences du transporteur",
      "Trip Schedule": "Planning du voyage",
      "Driver Assignment": "Affectation du conducteur",
      "Optimize Vehicle Utilization": "Optimiser l'utilisation des véhicules",
      "Time Based Planning": "Planification basée sur le temps",
      "Plant Wise Route Planning": "Planification des itinéraires par usine",
      "Vehicle Rotation Configuration":
        "Configuration de la rotation des véhicules",
      "Inbound Vehicle Preferences": "Préférences des véhicules entrants",
      "Outbound Vehicle Preferences": "Préférences des véhicules sortants",
      "Vehicle Fill Rate Optimization":
        "Optimisation du taux de remplissage des véhicules",
      "Dynamic Load Allocation": "Allocation dynamique des charges",
      "Start Date": "Date de début",
      "Run Plan": "Plan d'exécution",
      "Completion Date": "Date de fin",
      "Transporter Name": "Nom du transporteur",
      "Driver Allocation": "Allocation du conducteur",
      "Max Load Capacity": "Capacité maximale de charge",
      "Estimated Delivery Time": "Temps de livraison estimé",
      "Use Real-Time Traffic Data":
        "Utiliser les données de circulation en temps réel",
      "Driver Break Times": "Temps de pause du conducteur",
      "Max Vehicle Load Percentage":
        "Pourcentage maximal de charge du véhicule",
      "Telescopic Deviation Settings": "Paramètres de déviation télescopique",
      "Deviation Type": "Type de déviation",
      "Spot Vehicle Usage": "Utilisation des véhicules de spot",
      "Real-time Load Monitoring": "Surveillance de la charge en temps réel",
      "Driver Allocation Time": "Temps d'allocation du conducteur",
      "Use Traffic Data": "Utiliser les données de circulation",
      "Optimize Route": "Optimiser l'itinéraire",
      "Optimize Fuel Efficiency": "Optimiser l'efficacité énergétique",
      "Daily Vehicle Plan": "Plan quotidien des véhicules",
      "Transporter Allocation": "Allocation du transporteur",
      "Enable Real-time Tracking": "Activer le suivi en temps réel",
      "Vehicle Rotation Plan": "Plan de rotation des véhicules",
      "Route Optimization Preferences":
        "Préférences d'optimisation d'itinéraire",
      "Consider Weather Conditions":
        "Prendre en compte les conditions météorologiques",
      "Max Loading Time": "Temps de chargement maximal",
      "Min Load Capacity": "Capacité de charge minimale",
      "Driver Scheduling": "Planification des conducteurs",
      "Vehicle Capacity Monitoring":
        "Surveillance de la capacité des véhicules",
      "Dynamic Load Balancing": "Équilibrage dynamique des charges",
      "Last Mile Optimization": "Optimisation du dernier kilomètre",
      "Unloading Time Management": "Gestion du temps de déchargement",
      "Daily Trip Plan": "Plan quotidien des trajets",
      "Delivery Window Optimization": "Optimisation des fenêtres de livraison",
      "Night Driving Restrictions": "Restrictions de conduite de nuit",
      "Weekend Planning": "Planification du week-end",

      run_new_plan: "Exécuter un nouveau plan",
      min_vfr: "VFR Min",
      planned_day: "Jour Planifié",
      planned_distance_miles: "Distance Planifiée (Miles)",
      transporter_details: "Détails du transporteur",
      restriction_hours: "Heures De Restriction",
      save_geofence: "Enregistrer La Géofence",
      run_new_plan: "Lancer un nouveau plan",
      new_plan_details: "Détails du nouveau plan",
      order: "Commande",
      vehicle_configs: "Configurations du véhicule",
      order_information: "Informations sur la commande",
      plan_configs: "Configurations du plan",
      vehicle_availability: "Disponibilité du véhicule",
      vehicle_type: "Type de véhicule",
      vehicle_name: "Nom du véhicule",
      vehicle_min_weight: "Poids minimum du véhicule (livres)",
      vehicle_max_weight: "Poids maximum du véhicule (livres)",
      vehicle_min_volume: "Volume minimum du véhicule (pieds cubes)",
      vehicle_max_volume: "Volume maximum du véhicule (pieds cubes)",
      max_distance_per_trip: "Distance maximale par voyage (miles)",
      vehicle_priority: "Priorité du véhicule",
      enable_reconciliation: "Activer la réconciliation",
      enable_dc_week_off_planning:
        "Activer la planification des congés hebdomadaires du DC",
      order_number: "Numéro de commande",
      invoicing_date: "Date de facturation",
      quantity: "Quantité",
      demand_cft: "Demande (pieds cubes)",
      weight_pounds: "Poids (livres)",
      dealer_available_from: "Concessionnaire disponible à partir de",
      dealer_available_to: "Concessionnaire disponible jusqu'à",
      delivery_date: "Date de livraison",
      general_settings: "Paramètres généraux",
      prioritize_local_loads: "Donner la priorité aux charges locales",
      consider_dropping_distant_smaller_loads:
        "Envisager de supprimer les petites charges éloignées",
      plan_spot_vehicles: "Planifier les véhicules ponctuels",
      city_based_entry_restriction: "Restriction d'entrée basée sur la ville",
      consider_vehicle_break_times:
        "Prendre en compte les temps de pause du véhicule (conduite de nuit)",
      consider_holiday_restrictions:
        "Prendre en compte les restrictions de vacances",
      consider_realtime_vehicle_available_times:
        "Prendre en compte les heures de disponibilité en temps réel du véhicule",
      consider_transporter_sob: "Prendre en compte le SOB du transporteur",
      planning_optimization_settings:
        "Paramètres d'optimisation de la planification",
      use_dynamic_planning_window:
        "Utiliser une fenêtre de planification dynamique",
      number_of_days_to_use_planning_window:
        "Nombre de jours pour utiliser la fenêtre de planification",
      optimization_type: "Type d'optimisation",
      cost_optimization: "Optimisation des coûts",
      weight_based_optimization: "Optimisation basée sur le poids",
      distance_based_optimization: "Optimisation basée sur la distance",
      time_based_optimization: "Optimisation basée sur le temps",
      volume_based_optimization: "Optimisation basée sur le volume",
      vehicle_fill_rate_vfr_settings:
        "Paramètres du taux de remplissage du véhicule",
      use_volume_for_vfr:
        "Utiliser le volume pour le taux de remplissage du véhicule",
      use_weight_for_vfr:
        "Utiliser le poids pour le taux de remplissage du véhicule",
      min_vfr_per_vehicle_type:
        "Taux de remplissage minimum par type de véhicule",
      min_vfr_per_spot_vehicle:
        "Taux de remplissage minimum par véhicule ponctuel",
      moq_for_planning: "MOQ pour la planification",
      load_planning_settings: "Paramètres de planification des charges",
      number_of_loading_docks: "Nombre de quais de chargement",
      max_local_no_of_customers_per_vehicle:
        "Nombre maximal de clients locaux par véhicule",
      max_upcountry_no_of_customers_per_vehicle:
        "Nombre maximal de clients hors ville par véhicule",
      prioritize_same_dealers_first:
        "Donner la priorité aux mêmes concessionnaires dans un véhicule",
      prioritize_multi_city_dealers_first:
        "Donner la priorité aux concessionnaires multi-villes dans un véhicule",
      note_highlighted_rows_red:
        "Les lignes en rouge manquent de données GPS ou d'intégration, marquez manuellement la disponibilité du véhicule.",
      note_highlighted_rows_green:
        "Les lignes en vert sont considérées pour la planification",
      vehicle_number: "Numéro du véhicule",
      transit_type: "Type de transit",
      monthly_minimum_distance: "Distance minimale mensuelle",
      remaining_distance: "Distance restante",
      vehicle_available_date:
        "Date de disponibilité du véhicule (AAAA-MM-JJ HH:mm)",
      charlie_hub: "Centre Charlie",
      enable_recommendation: "Activer la recommandation",
      planned_day: "Jour planifié",
      drop_sequence: "Séquence de dépose",
      drop_location_code_destination: "Code du lieu de dépose (destination)",
      drop_location_name_destination: "Nom du lieu de dépose (destination)",
      destination_location_city: "Ville de destination",
      weight_kgs: "Poids (kg)",
      volume_m3: "Volume (m³)",
      distance_to_destination_kms: "Distance jusqu'à destination (km)",
      expected_delivery_date: "Date de livraison prévue",
      time_to_destination: "Temps jusqu'à destination",
      transit_time_hh_mm: "Temps de transit (HH:MM)",
      unloading_time_hh_mm: "Temps de déchargement (HH:MM)",
      plan_id: "ID du plan",
      generated_on: "Généré le",
      vehicle_availability_from: "Disponibilité du véhicule de",
      vehicle_availability_to: "Disponibilité du véhicule jusqu'à",
      break_time_from: "Temps de pause de",
      break_time_to: "Temps de pause jusqu'à",
      min_volume_m3: "Volume minimum (m³)",
      max_volume_m3: "Volume maximum (m³)",
      vehicle_width_mm: "Largeur du véhicule (mm)",
      vehicle_height_mm: "Hauteur du véhicule (mm)",
      vehicle_depth_mm: "Profondeur du véhicule (mm)",
      material_code: "Code du matériau",
      material_name: "Nom du matériau",
      material_type: "Type de matériau",
      material_weight: "Poids du matériau",
      material_dimensions_depth: "Dimensions du matériau - Profondeur",
      material_dimensions_width: "Dimensions du matériau - Largeur",
      material_dimensions_height: "Dimensions du matériau - Hauteur",
      state: "État",
      city: "Ville",
      fixed_vehicle_cost: "Coût fixe du véhicule",
      driver_cost: "Coût du conducteur",
      maintenance_cost: "Coût d'entretien",
      per_cmt_cost: "Coût par pied cube",
      per_km_cost: "Coût par km",
      date: "Date",
      transporter_name: "Nom du transporteur",
      no_of_vehicles: "Nombre de véhicules",
      driver_break_time_from: "Temps de pause du conducteur de",
      vehicle_max_weight_kgs: "Poids maximum du véhicule (kg)",
      vehicle_min_volume_m3: "Volume minimum du véhicule (m³)",
      vehicle_max_volume_m3: "Volume maximum du véhicule (m³)",
      max_distance_kms: "Distance maximale (km)",
      monthly_min_planning_distance_kms:
        "Distance minimale de planification mensuelle (km)",
      details: "Détails",
      trip_no: "Numéro de voyage",
      vehicle_no: "Numéro du véhicule",
      no_of_orders: "Nombre de commandes",
      no_of_customers: "Nombre de clients",
      total_quantity: "Quantité totale",
      vehicle_utilization_percent: "Utilisation du véhicule (%)",
      total_planned_weight_kgs: "Poids total prévu (kg)",
      onward_trip_distance_kms: "Distance du trajet aller (km)",
      round_trip_distance_kms: "Distance du trajet aller-retour (km)",
      total_planned_volume_m3: "Volume total prévu (m³)",
      max_dispatch_time_hh_mm: "Temps maximum d'expédition (HH:MM)",
      arrival_at_first_dealer_start:
        "Arrivée chez le premier concessionnaire (début)",
      departure_at_last_dealer_end: "Départ du dernier concessionnaire (fin)",
      return_to_plant_hh_mm: "Retour à l'usine (HH:MM)",
      total_planned_trip_time_hrs: "Temps total du voyage prévu (heures)",
      total_round_trip_time_hrs: "Temps total du trajet aller-retour (heures)",
      total_transit_time_hh_mm: "Temps de transit total (HH:MM)",
      total_loading_time_hh_mm: "Temps de chargement total (HH:MM)",
      total_unloading_time_hh_mm: "Temps de déchargement total (HH:MM)",
      drop_location_code: "Code du lieu de dépose",
      drop_location_name: "Nom du lieu de dépose",
      drop_location_city: "Ville du lieu de dépose",
      demand_volume_m3: "Volume de la demande (m³)",
      vehicle_min_weight_kgs: "Poids minimum du véhicule (kg)",
      no_of_deliveries: "Nombre de livraisons",
      min_load_per_delivery: "Charge minimale par livraison",
      status: "Statut",
      processing: "Traitement en cours",
      please_contact_admin: "Veuillez contacter l'administrateur",
      solver_complete: "Solution complète",
      solver_reco_processed: "Recommandation traitée",
      solver_sob_done: "SOB effectué",
      solver_invalid_input_dealer_location:
        "Localisation du concessionnaire invalide",
      solver_invalid_input_materials: "Matériaux invalides",
      solver_invalid_input_dealer_location_and_materials:
        "Localisation et matériaux invalides",
      solver_input_data_not_valid: "Données d'entrée non valides",
      solver_fix_dropped_dealers: "Corriger les concessionnaires supprimés",
      solver_vehicle_details_missing: "Détails du véhicule manquants",
      solver_input_file_data_error: "Erreur de données du fichier d'entrée",
      solver_invalid_file_template: "Modèle de fichier invalide",
      process_timed_out: "Temps d'exécution écoulé",
      solver_sob_fail: "Échec du SOB",
      solver_reco_failed: "Échec de la recommandation",
      solver_no_tool_config: "Pas de configuration d'outil",
      solver_invalid_tool_config: "Configuration d'outil invalide",
      transit_time_with_multiple_city_clubing:
        "Temps de transit avec regroupement de plusieurs villes",
      rdd_preference: "Préférence RDD",
      transit_time_with_single_city_clubing:
        "Temps de transit avec regroupement d'une seule ville",
      no_of_trucks_planned: "Nombre de camions planifiés",
      view_details: "Voir les détails",
      plan_run_by: "Plan exécuté par",
      compare_plans: "Comparer les plans",
      scenario_name: "Nom du scénario",
      pick_up_location_source: "Lieu de prise en charge (source)",
      pickup_location_source: "Lieu de prise en charge (source)",
      drop_location_destination: "Lieu de dépose (destination)",
      demand_m3: "Demande (m³)",
      location_radius: "Rayon de localisation (kms)",
      volume_cmt: "Volume (CMT)",
      obd_details_in_radius: "Détails OBD dans le rayon",
      planning_configurations: "Configurations de planification",
      planning_scenarios: "Scénarios de planification",
      what_if_demand_fluctuation: "Et si : Fluctuation de la demande",
      increase_decrease_demand_by: "Augmenter/Réduire la demande de",
      what_if_customer_priority_changes:
        "Et si : Changements de priorité client",
      prioritize_upcountry: "Donner la priorité aux régions éloignées",
      prioritize_local: "Donner la priorité aux régions locales",
      what_if_service_level_target_changes:
        "Et si : Changements d'objectif de niveau de service",
      set_service_level_target_to: "Définir l'objectif de niveau de service à",
      what_if_vehicle_fill_rate_changes:
        "Et si : Changements du taux de remplissage des véhicules",
      set_vehicle_fill_rate_to:
        "Définir le taux de remplissage des véhicules à",
      what_if_customer_specific_delivery_time:
        "Et si : Délai de livraison spécifique au client",
      increase_decrease_delivery_time_window_by:
        "Augmenter/Réduire la fenêtre de temps de livraison de",
      scenario_description: "Description du scénario",
      vehicle_fill_rate_settings:
        "Paramètres du taux de remplissage des véhicules",
      file_name: "Nom du fichier",
      total_weight_kgs: "Poids total (kg)",
      total_demand_volume_m3: "Volume total de la demande (m³)",
      processing_plan_please_wait: "Traitement du plan. Veuillez patienter...",
      forecast_input_information: "Informations sur les prévisions d'entrée",
      uploading_data_for_plant: "Téléchargement des données pour l'usine",
      vehicle_type_configuration: "Configuration du type de véhicule",
      min_volume_cft: "Volume minimum (pieds cubes)",
      max_volume_cft: "Volume maximum (pieds cubes)",
      cost_per_km: "Coût par km",
      note_duplicate_scenario: "Remarque : Scénario en double",
      select_unique_scenarios_to_compare:
        "Veuillez sélectionner des scénarios uniques pour comparer les plans",
      scenario_selection_limit_exceeded:
        "Limite de sélection de scénarios dépassée",
      note_scenario_selection_limit:
        "Remarque : Vous pouvez sélectionner jusqu'à trois scénarios",
      select_scenario_to_compare:
        "Veuillez sélectionner un scénario pour comparer les plans",
      forecast_scenarios: "Scénarios de prévision",
      select_planning_duration: "Sélectionner la durée de planification",
      notify_email_users_after_process:
        "Notifier les utilisateurs par e-mail après le traitement (séparés par des virgules)",
      optimize_by_time_details: "Optimiser par détails temporels",
      max_time_limit_hrs: "Limite de temps maximale (heures)",
      spot_vehicles_to_be_planned: "Véhicules ponctuels à planifier",
      auto_select: "Sélection automatique",
      no_data: "Pas De Données",
      redirecting_to_vehicle_checklist:
        "Redirection Vers La Liste De Contrôle Du Véhicule",
      drop_sequence: "Séquence de Livraison",
      drop_location_code_destination: "Code de Lieu de Livraison (Destination)",
      drop_location_name_destination: "Nom du Lieu de Livraison (Destination)",
      destination_location_city: "Ville de Destination",
      dealer_type: "Type de Revendeur",
      customer_type: "Type de Client",
      week_off: "Jour de Repos",
      order_number: "Numéro de Commande",
      invoicing_date: "Date de Facturation",
      quantity: "Quantité",
      weight_pounds: "Poids (Livres)",
      volume_cft: "Volume (CFT)",
      distance_to_destination_miles: "Distance jusqu'à la Destination (Miles)",
      expected_delivery_date: "Date de Livraison Prévue",
      time_to_destination: "Temps jusqu'à la Destination",
      transit_time_hh_mm: "Temps de Transit (HH:MM)",
      unloading_time_hh_mm: "Temps de Déchargement (HH:MM)",
      email: "Email",
      details: "Détails",
      view: "Voir",
      assign_vehicle: "Attribuer le Véhicule",
      revoke_assignment: "Révoquer l'Attribution",
      edit_plan: "Modifier le Plan",
      delete: "Supprimer",
      plan_edited: "Plan Modifié",
      yes: "Oui",
      no: "Non",
      trip_no: "Numéro de Voyage",
      vehicle_no: "Numéro du Véhicule",
      vehicle_type: "Type de Véhicule",
      cost: "Coût ($)",
      transit_type: "Type de Transit",
      transporter_name: "Nom du Transporteur",
      no_of_orders: "Nombre de Commandes",
      no_of_customers: "Nombre de Clients",
      total_quantity: "Quantité Totale",
      vehicle_utilization_percent: "Utilisation du Véhicule (%)",
      vehicle_max_weight: "Poids Maximal du Véhicule (Livres)",
      total_planned_weight: "Poids Total Planifié (Livres)",
      onward_trip_distance_miles: "Distance Aller (Miles)",
      round_trip_distance_miles: "Distance Aller-Retour (Miles)",
      vehicle_max_volume_cft: "Volume Maximal du Véhicule (CFT)",
      total_planned_volume_cft: "Volume Total Planifié (CFT)",
      max_dispatch_time: "Temps Maximal d'Expédition (HH:MM)",
      arrival_at_first_dealer: "Arrivée au Premier Revendeur (Début)",
      departure_at_last_dealer: "Départ du Dernier Revendeur (Fin)",
      return_to_plant: "Retour à l'Usine (HH:MM)",
      total_planned_trip_time: "Temps Total de Voyage Planifié (Heures)",
      total_round_trip_time: "Temps Total Aller-Retour (Heures)",
      total_transit_time: "Temps Total de Transit (HH:MM)",
      total_loading_time: "Temps Total de Chargement (HH:MM)",
      total_unloading_time: "Temps Total de Déchargement (HH:MM)",
      remark: "Remarque",
      "n/a": "Non Disponible",
      revoke_again_to_edit: "Révoquer à Nouveau pour Modifier",
      drop_location_code: "Code de Lieu de Livraison",
      drop_location_name: "Nom du Lieu de Livraison",
      drop_location_city: "Ville de Lieu de Livraison",
      distance_from_plant: "Distance depuis l'Usine",
      demand_volume_cft: "Volume Demandé (CFT)",
      delivery_date: "Date de Livraison",
      actions: "Actions",
      pickup_location_source: "Lieu de Ramassage (Source)",
      dealer_available_from: "Revendeur Disponible à Partir de",
      dealer_available_to: "Revendeur Disponible Jusqu'à",
      loading_time_hh_mm: "Temps de Chargement (HH:MM)",
      dropped_reason: "Raison du Rejet",
      dealer_location_data_not_in_system:
        "Données de Lieu du Revendeur Non dans le Système",
      delivery_date_less_than_current_date:
        "Date de Livraison Inférieure à la Date Actuelle",
      kdd_customer: "Client KDD",
      insufficient_load_for_planning:
        "Charge Insuffisante pour la Planification (<MOQ)",
      warehouse_name: "Nom de l'Entrepôt",
      destination_city: "Ville de Destination",
      destination_state: "État de Destination",
      sales_order_no: "Numéro de Commande de Vente",
      no_of_tires: "Nombre de Pneus",
      order_date: "Date de Commande",
      vehicle_name: "Nom du Véhicule",
      vehicle_priority: "Priorité du Véhicule",
      vehicle_availability_from: "Disponibilité du Véhicule (à partir de)",
      vehicle_availability_to: "Disponibilité du Véhicule (jusqu'à)",
      break_time_from: "Heure de Pause (à partir de)",
      break_time_to: "Heure de Pause (jusqu'à)",
      min_volume_cft: "Volume Minimum (CFT)",
      max_volume_cft: "Volume Maximum (CFT)",
      vehicle_dimensions_width_mm: "Largeur du Véhicule (mm)",
      vehicle_dimensions_height_mm: "Hauteur du Véhicule (mm)",
      vehicle_dimensions_depth_mm: "Profondeur du Véhicule (mm)",
      vehicle_min_weight: "Poids Minimum du Véhicule (Livres)",
      max_no_of_nodes: "Nombre Maximal de Livraisons",
      min_load_per_delivery: "Charge Minimum par Livraison",
      max_distance: "Distance Maximale (Miles)",
      material_code: "Code du Matériel",
      material_name: "Nom du Matériel",
      material_type: "Type de Matériel",
      material_weight: "Poids du Matériel",
      material_dimensions_depth: "Profondeur du Matériel",
      material_dimensions_width: "Largeur du Matériel",
      material_dimensions_height: "Hauteur du Matériel",
      form_vehicle_types: "Types de Véhicule",
      dealer_form_state: "État",
      dealer_form_city: "Ville",
      freight_fixed_vehicle_cost: "Coût Fixe du Véhicule",
      freight_driver_cost: "Coût du Conducteur",
      freight_maintenance_cost: "Coût d'Entretien",
      freight_per_cmt_cost: "Coût par CMT",
      freight_per_km_cost: "Coût par KM",
      vehicle_date: "Date",
      vehicle_number: "Numéro du Véhicule",
      plant_code: "Code de l'Usine",
      plant_name: "Nom de l'Usine",
      available_time: "Temps Disponible",
      gps_status: "Statut GPS",
      last_known_address: "Dernière Adresse Connue",
      last_gps_time: "Dernière Heure GPS",
      vehicle_location: "Localisation du Véhicule",
      assigned_to_ot: "Attribué à OT",
      order_information: "Informations de la Commande",
      vehicle_type_configurations: "Configurations du Type de Véhicule",
      vehicle_routes: "Itinéraires du Véhicule",
      total_customers: "Total des Clients",
      planned: "Planifié",
      unplanned: "Non Planifié",
      total_round_trip_distance_miles: "Distance Totale Aller-Retour (Miles)",
      planning: "Planification",
      vfr: "VFR",
      ontime_dispatch: "Expédition à Temps",
      avg_tat: "TAT Moyen",
      view_locations: "Voir les Lieux",
      view_routes: "Voir les Itinéraires",
      additional_vehicles: "Véhicules Supplémentaires",
      regular_vehicles: "Véhicules Réguliers",
      enter_vehicle_no: "Entrez le Numéro du Véhicule",
      vehicle_has_active_trip_shipment_id:
        "Le Véhicule Possède un ID de Chargement de Voyage Actif",
      do_you_wish_to: "Souhaitez-vous",
      force_close_assign: "Fermer de Force et Attribuer",
      force_close_reason: "Raison de la Fermeture Forcée",
      gps_issue: "Problème GPS",
      trip_closed: "Voyage Fermé",
      load_cancelled: "Charge Annulée",
      new_trip_allocated: "Nouveau Voyage Attribué",
      driver_changed: "Conducteur Changé",
      are_you_sure_you_want_to_update:
        "Êtes-vous sûr de vouloir mettre à jour?",
      edit_plan_for_same: "Modifier le Plan pour le Même",
      update_vehicle_plan: "Mettre à Jour le Plan du Véhicule",
      max_dispatch_time: "Temps Maximal d'Expédition",
      new_plan: "Nouveau Plan",
      reason_for_plan_change: "Raison du Changement de Plan",
      enter_the_reason: "Entrez la Raison",
      reason_for_plan_deletion: "Raison pour la Suppression du Plan",
      are_you_sure_delete_plan: "Êtes-vous sûr de vouloir supprimer le plan?",
      update_order_details: "Mettre à Jour les Détails de la Commande",
      demand_volume: "Volume de Demande",
      dealer_availability_start_time:
        "Heure de Début de Disponibilité du Concessionnaire",
      dealer_availability_end_time:
        "Heure de Fin de Disponibilité du Concessionnaire",
      configurations: "Configurations",
      download: "Télécharger",
      dealerTimeError:
        "L'heure de début disponible du concessionnaire doit être inférieure à l'heure de fin",
      more: "Plus",
      less: "Moins",
      qualityChecks: "Contrôles de qualité",
      currentMonthPlanDistance: "Distance du plan du mois en cours",
      email: "E-mail",
      viewPlan: "Voir le plan",
      blank_rows_columns_not_allowed:
        "Les lignes/colonnes vides ne sont pas autorisées.",
      import_existing_vehicle_details:
        "Importer les détails du véhicule existant",
      uploading_data_for_plant: "Téléchargement des données pour l'usine",
      check_and_remove_blank_data:
        "Pour vérifier et supprimer les données vides, utilisez Rechercher et sélectionner → Aller à spécial → Blancs, puis supprimez les lignes surlignées.",
      add_vehicle_type: "Ajouter un type de véhicule",
      vehicle_operational_hours: "Heures de fonctionnement du véhicule (HH:MM)",
      data_saved_successfully: "Données enregistrées avec succès",
      plant_wise_configurations: "Configurations par usine",
      save_plant_wise_configurations:
        "Enregistrer les configurations par usine",
      vehicle_break_window: "Fenêtre de pause du véhicule (HH:MM)",
      total_vehicles: "Nombre total de véhicules",
      zone_configurations: "Configurations de zone",
      pickup_point: "Point de collecte",
      actions: "Actions",
      edit: "Éditer",
      add_transporter_details: "+ Ajouter les détails du transporteur",
      cost_per_mile: "Coût par mile",
      freight_details: "Détails du fret",
      add_zone: "+ Ajouter une zone",
      fixed_vehicle_cost_title: "Coût fixe du véhicule ($)",
      variable_vehicle_cost_title: "Coût variable du véhicule",
      city_wise_cost: "Coût du véhicule par ville",
      zone_wise_cost: "Coût du véhicule par zone",
      vehicle_cost_configurations: "Configurations des coûts des véhicules",
      freight_cost_label: "Coût du fret",
      validity_to_label: "Valable jusqu'à",
      validity_from_label: "Valable depuis",
      actions_label: "Actions",
      edit_button: "Éditer",
      delete_button: "Supprimer",
      save_button: "Enregistrer",
      cancel_button: "Annuler",
      select_vehicle_type: "Sélectionner un type de véhicule",
      select_transporter_name: "Sélectionner un nom de transporteur",
      zone: "Zone",
      state: "État",
      driver_cost: "Coût du conducteur ($)",
      maintenance_cost: "Coût de maintenance ($)",
      enable_disable_dispatch_planning_module:
        "Activer/Désactiver le module de planification d'expédition",
      inbound: "Entrant",
      suppliers_vendor_locations_pickup:
        "Fournisseurs / Emplacements des vendeurs (Collecte)",
      operational_hours: "Heures d'ouverture",
      pickup_locations_drop: "Emplacements de collecte (Livraison)",
      plant_wise_vehicle_type_configuration:
        "Configuration du type de véhicule par usine",
      max_no_of_vehicle_types: "Nombre maximal de types de véhicules",
      vehicle_load_capacity_min_max_configuration_preferences:
        "Préférences de configuration de capacité de charge de véhicule Min / Max",
      vehicle_load_weight_min_max_preferences:
        "Préférences de poids de charge du véhicule Min / Max",
      distance_preferences: "Préférences de distance",
      vehicle_priorities: "Priorités du véhicule",
      vehicle_operational_times: "Temps de fonctionnement du véhicule",
      vehicle_availability: "Disponibilité du véhicule",
      generic_time_windows: "Fenêtres de temps génériques",
      individual_vehicle_time_windows:
        "Fenêtres de temps par véhicule individuel",
      availability_by_vehicle_type: "Disponibilité par type de véhicule",
      availability_by_individual_vehicle:
        "Disponibilité par véhicule individuel",
      monthly_minimum_planning_distance_preferences:
        "Préférences de distance minimale mensuelle de planification",
      gps_based_availability: "Disponibilité basée sur GPS",
      truck_location_visibility: "Visibilité de l'emplacement du camion",
      plant_vicinity_circle_radius: "Rayon du cercle de proximité de l'usine",
      plant_wise_freight_configuration: "Configuration du fret par usine",
      plant_wise_transporter_configuration:
        "Configuration du transporteur par usine",
      transporter_share_of_business: "Part de marché du transporteur",
      route_preferences: "Préférences d'itinéraire",
      plant_wise_new_plan_creation: "Création d'un nouveau plan par usine",
      plant_wise_plan_summary: "Résumé du plan par usine",
      plant_wise_planning_history: "Historique de planification par usine",
      sustainability_integration: "Intégration de la durabilité",
      save_inbound_configurations: "Enregistrer les configurations entrantes",
      save_outbound_configurations: "Enregistrer les configurations sortantes",
      outbound: "Sortant",
      consignees_dealers_locations_drop:
        "Emplacements des destinataires / concessionnaires (Livraison)",
      state: "État",
      city: "Ville",
      zone: "Zone",
      delta_warehouse: "Entrepôt Delta",
      zones: "Zones",
      transporter_master: "Maître du transporteur",
      transporter_code: "Code du transporteur",
      transporter_name: "Nom du transporteur",
      vehicle_checklist_for_transportation_requirements:
        "Liste de contrôle des véhicules pour les exigences de transport",
      consolidated_route_plan_screen: "Écran du plan de route consolidé",
      plant_code: "Code de l'usine",
      select_plant_to_check_vehicle_availability:
        "Sélectionnez l'usine pour vérifier la disponibilité du véhicule",
      vehicle_availability: "Disponibilité des véhicules",
      facility_name: "Nom de l'installation",
      vehicle_number: "Numéro du véhicule",
      total_trucks: "Nombre total de camions",
      cost_details: "Détails des coûts",
      fixed_vehicle_cost: "Coût fixe du véhicule",
      variable_vehicle_cost: "Coût variable du véhicule",
      city_wise_vehicle_cost: "Coût du véhicule par ville",
      zone_wise_vehicle_cost: "Coût du véhicule par zone",
      pickup_point: "Point de collecte",
      cost_per_mile_below_sob: "Coût par mile (en dessous de SOB)",
      cost_per_mile_after_sob: "Coût par mile (après SOB)",
      validity_from: "Valide à partir de",
      validity_to: "Valable jusqu'à",
      freight_details: "Détails du fret",
      select_transporter: "Sélectionnez le transporteur",
      add_vehicle_cost: "+ Ajouter le coût du véhicule",
      gps_status: "Statut GPS",
      inactive: "Inactif",
      inside_dc: "À l'intérieur de DC",
      in_transit_to_customer: "En transit vers le client",
      return_to_dc: "Retour à DC",
      empty_outside_dc: "Vide à l'extérieur de DC",
      add_zone_mapping: "Ajouter une carte de zone",
      current_status: "Statut actuel",
      next_availability: "Prochaine disponibilité",
      vehicle_list: "Liste des véhicules",
      from_time: "De l'heure",
      to_time: "À l'heure",
      customer_location: "Emplacement du client",
      quality: "Qualité",
      security: "Sécurité",
      view: "Voir",
      vehicle_operational_from: "Opérationnel du véhicule (depuis)",
      vehicle_operational_to: "Opérationnel du véhicule (jusqu'à)",
      temperature: "Température",
      average_payload: "Charge moyenne (tonnes)",
      mileage: "Kilométrage (Miles/galon)",
      fuel_type: "Type de carburant",
      load_characteristics: "Caractéristiques de charge",
      glec_vehicle_type: "Type de véhicule GLEC",
      return_to_plant: "Retour à l'usine",
      summary_dashboard: "Tableau de bord de résumé",
      vehicle_type_name: "Nom du type de véhicule",
      total_round_trip_distance: "Distance totale du voyage aller-retour",
      total_planned_weight: "Poids total planifié",
      total_loading_time_hh_mm: "Temps de chargement total (HH:MM)",
      drop_location_code: "Code de localisation de livraison",
      departure_at_last_dealer: "Départ du dernier concessionnaire",
      vehicle_utilization_percentage: "Pourcentage d'utilisation du véhicule",
      get_data: "Obtenir des données",
      cancel: "Annuler",
      save: "Enregistrer",
      delete: "Supprimer",
      max_distance_per_plan_miles: "Distance maximale par plan (miles)",
      max_weight_pounds: "Poids maximal (livres)",
      max_volume_cft: "Volume maximal (CFT)",
      monthly_min_planning_distance_miles:
        "Distance minimale mensuelle de planification (miles)",
      remaining_monthly_planning_distance_miles:
        "Distance mensuelle restante de planification (miles)",
      next_available_date_yyyy_mm_dd_hh_mm:
        "Prochaine date disponible (AAAA-MM-JJ HH:mm)",
      vehicle_break_down: "Panne de véhicule",
      arrival_at_first_dealer: "Arrivée au premier concessionnaire",
      drop_location_city: "Ville de la localisation de livraison",
      average_payload: "Charge moyenne (tonnes)",
      mileage: "Kilométrage (miles/galon)",
      fuel_type: "Type de carburant",
      temperature_controlled: "Contrôlé par la température",
      planned_summary: "Résumé planifié",
      forecast_order_plan: "Plan de commande de prévision",
      invalid_orders_note:
        "Remarque : Les commandes invalides ne sont pas affichées",
      drop_location_name: "Nom de la localisation de livraison",
      pickup_location_source: "Lieu de collecte (source)",
      total_planned_weight_pounds: "Poids total planifié (livres)",
      dealer_available_from: "Concessionnaire disponible à partir de",
      dealer_available_to: "Concessionnaire disponible jusqu'à",
      total_planned_volume_cft: "Volume total planifié (CFT)",
      total_loading_time_hh_mm: "Temps total de chargement (HH:MM)",
      total_unloading_time_hh_mm: "Temps total de déchargement (HH:MM)",
      delivery_date: "Date de livraison",
      time_to_destination: "Temps vers la destination",
      dealer_available_time_error:
        "L'heure de début doit être inférieure à l'heure de fin",
      max_distance_trip: "Distance maximale par voyage (miles)",
      set_vehicle_priority: "Définir la priorité du véhicule",
      vehicle_type_configuration: "Configuration du type de véhicule",
      demand_volume_cft: "Volume de la demande (CFT)",
      drop_location_city: "Ville de livraison",
      drop_sequence: "Séquence de livraison",
      plan_history: "Historique du plan",
      trip_no: "Numéro du voyage",
      save_plant_wise_configurations:
        "Enregistrer les configurations par usine",
      plan_run_by: "Plan exécuté par",
      invalid_drop_data: "Données de livraison invalides",
      planned_vehicles: "Véhicules planifiés",
      unplanned_loads: "Chargements non planifiés",
      plant_name: "Nom de l'usine",
      total_trips: "Nombre total de voyages",
      trips_edited: "Voyages édités",
      remaining_monthly_distance_miles: "Distance mensuelle restante (miles)",
      remaining_monthly_planning_distance_miles:
        "Distance mensuelle restante de planification (miles)",
      dealer_type: "Type de concessionnaire",
      compare_plans: "Comparer les plans",
      max_dispatch_time_hh_mm: "Temps maximum d'expédition (HH:MM)",
      total_round_trip_time_hrs: "Temps total de voyage aller-retour (heures)",
      total_planned_trip_time_hrs: "Temps total de voyage planifié (heures)",
      total_unloading_time_hh_mm: "Temps total de déchargement (HH:MM)",
      vehicle_utilization_percentage:
        "Pourcentage d'utilisation du véhicule (%)",
      view_order_information: "Voir les informations de commande",
      dealer_code: "Code du concessionnaire",
      remarks: "Remarques",
      dispatch_plan_details: "Détails du plan de répartition",
      summary: "Résumé",
      get_data: "Obtenir des données",
      pickup_drop_points: "Points de collecte/livraison",
      pickup_point: "Point de collecte",
      forecast_plan_summary_dashboard:
        "Tableau de bord de résumé du plan de prévision",
      transit_time_hh_mm: "Temps de transit (HH:MM)",
      editing_transaction_ids: "IDs des transactions éditées",
      trips_removed: "Voyages supprimés",
      note_blank_rows_columns_not_allowed:
        "Remarque : Les lignes/colonnes vides ne sont pas autorisées",
      editing_transaction_ids: "IDs des transactions modifiées",
      check_blank_data_instructions:
        "Pour vérifier les données vides : 1. Cliquez sur Rechercher et Sélectionner. 2. Cliquez sur Aller à Spécial. 3. Choisissez Vides. 4. Cliquez sur OK, et toutes les lignes/cellules vides seront mises en surbrillance. 5. Sélectionnez Supprimer dans la section Cellules sous l'onglet Accueil. 6. Cliquez sur Supprimer les lignes de la feuille.",
      disclaimer_dealer_window_ot_customers:
        "Avertissement : La fenêtre disponible pour les clients OT sera considérée à partir du fichier d'entrée, les horaires des clients GT seront pris en compte à partir des lieux de collecte/livraison.",
      quantity: "Quantité",
      specifications: "Spécifications",
      please_select_plan_id_to_compare_plans:
        "Veuillez sélectionner l'ID du plan pour comparer les plans",
      view_details: "Voir les détails",
      pickup: "Collecter",
      drop: "Livrer",
      both: "Les deux",
      pickup_location_code: "Code du lieu de collecte",
      vehicle_restriction_list: "Liste des restrictions de véhicules",
      week_off: "Jour de congé",
      csp_day: "Jour CSP",
      is_customer_kdd: "Le client est-il KDD ?",
      start_month: "Mois de début",
      end_month: "Mois de fin",
      plan_id: "ID du plan",
      fecha_creada: "Date de création",
      no_of_trucks_planned: "Nombre de camions planifiés",
      pickup_point: "Point de collecte",
      view_vehicles: "Voir les véhicules",
      pickup_location_source: "Source du lieu de collecte",
      plant_code: "Code de l'usine",
      no_of_runs: "Nombre d'exécutions",
      trips_edited: "Voyages édités",
      plant_wise_plan_summary: "Résumé du plan par usine",
      freight_details: "Détails du fret",
      upload_file: "Télécharger le fichier",
      max_weight_per_trip: "Poids maximum par voyage (livres)",
      max_distance_per_trip: "Distance maximum par voyage (miles)",
      vehicle_break_window: "Fenêtre de pause du véhicule (HH:MM)",
      trips_removed: "Voyages supprimés",
      fixed_vehicle_cost_label: "Coût fixe du véhicule ($)",
      next_available_date_at_dc: "Prochaine date disponible à DC",
      transportation_restriction: "Restriction de transport",
      route_preferences: "Préférences d'itinéraire",
      trips: "Voyages",
      consolidated_route_plan_screen: "Écran du plan d'itinéraire consolidé",
      service_provider: "Fournisseur de service",
      load_characteristics: "Caractéristiques de la charge",
      vehicle_utilization_km: "Utilisation des véhicules (KM)",
      cool_chain_adherence: "Respect de la chaîne du froid",
      return_to_dc: "Retour à DC",
      facility_name: "Nom de l'installation",
      add_vehicle_checklist: "Ajouter la liste de contrôle du véhicule",
      trip_no: "Numéro de voyage",
      remaining_monthly_distance_miles: "Distance mensuelle restante (miles)",
      next_availability_date: "Prochaine disponibilité",
      gps_status: "Statut GPS",
      transit_type: "Type de transit",
      fixed_vehicle_cost: "Coût fixe du véhicule",
      run_new_plan: "Exécuter un nouveau plan",
      empty_outside_dc: "Vide à l'extérieur de DC",
      plan_run_by: "Plan exécuté par",
      total_planned_customers: "Nombre total de clients planifiés",
      freight_cost: "Coût du fret",
      cost_per_mile: "Coût par mile",
      max_volume: "Volume maximum (CFT)",
      remaining_monthly_planning_distance_miles:
        "Distance mensuelle restante de planification (miles)",
      total_round_trip_distance: "Distance totale aller-retour",
      dealer_available_from: "Distributeur disponible à partir de",
      dealer_available_to: "Distributeur disponible jusqu'à",
      vehicle_priority: "Priorité du véhicule",
      drop_location_code: "Code du lieu de livraison",
      drop_location_city: "Ville du lieu de livraison",
      total_quantity: "Quantité totale",
      weight_pounds: "Poids (livres)",
      delivery_date: "Date de livraison",
      drop_sequence: "Séquence de livraison",
      invoicing_date: "Date de facturation",
      destination_location_city: "Ville de destination",
      destination_location_state: "État de destination",
      time_to_destination: "Temps jusqu'à la destination",
      dealer_available_time_error:
        "L'heure de début doit être inférieure à l'heure de fin",
      view_order_information: "Voir les informations de la commande",
      vfr_percentage: "Pourcentage VFR",
      temperature: "Température",
      vehicle_next_available_date: "Prochaine disponibilité du véhicule",
      driver_cost: "Coût du conducteur ($)",
      market: "Marché",
      mileage: "Kilométrage (miles/galon)",
      max_dispatch_time: "Temps maximum d'expédition (HH:MM)",
      return_to_plant: "Retour à l'usine",
      summary_dashboard: "Tableau de bord récapitulatif",
      arrival_at_first_dealer_start: "Arrivée au premier distributeur (début)",
      departure_at_last_dealer_end: "Départ du dernier distributeur (fin)",
      planned_day: "Jour planifié",
      pickup_point: "Point de collecte",
      add_transporter_details: "Ajouter les détails du transporteur",
      total_loading_time_hh_mm: "Temps total de chargement (HH:MM)",
      vehicle_utilization_percentage: "Pourcentage d'utilisation du véhicule",
      set_vehicle_priority: "Définir la priorité du véhicule",
      get_data: "Obtenir des données",
      save: "Sauvegarder",
      cancel: "Annuler",
      delete: "Supprimer",
      vehicle_details: "Détails du véhicule",
      maintenance_cost: "Coût d'entretien ($)",
      trip_no: "Numéro de voyage",
      plant_name: "Nom de l'usine",
      driver_break_window: "Fenêtre de pause du conducteur (HH:MM)",
      upload_file: "Téléverser un fichier",
      return_to_plant_hh_mm: "Retour à l'usine (HH:MM)",
      weight: "Poids",
      facility_name: "Nom de l'installation",
      distance_to_destination: "Distance à la destination",
      unloading_time_hh_mm: "Temps de déchargement (HH:MM)",
      loading_time: "Temps de chargement",
      quality_approved: "Approuvé par la qualité",
      assessed_by: "Évalué par",
      quality_comments: "Commentaires sur la qualité",
      actions: "Actions",
      vehicle_operational_hours: "Heures de fonctionnement du véhicule (HH:MM)",
      gps_status: "Statut GPS",
      active: "Actif",
      inactive: "Inactif",
      total_trips: "Nombre total de trajets",
      trips_edited: "Trajets modifiés",
      trips_removed: "Trajets supprimés",
      edited_transaction_ids: "IDs de transactions modifiées",
      removed_transaction_ids: "IDs de transactions supprimées",
      last_packet_time: "Dernière heure de paquet",
      truck_no: "Numéro de camion",
      vehicle_no: "Numéro de véhicule",
      total_planned_weight: "Poids total planifié",
      total_planned_volume: "Volume total planifié",
      max_weight: "Poids maximum (livres)",
      max_distance_trip: "Distance maximale par trajet (miles)",
      total_unloading_time_hh_mm: "Temps total de déchargement (HH:MM)",
      average_payload: "Charge moyenne (tonnes)",
      fuel_type: "Type de carburant",
      load_characteristics: "Caractéristiques de la charge",
      total_planned_customers: "Total des clients planifiés",
      freight_cost: "Coût du fret",
      total_round_trip_time_hrs: "Temps total aller-retour (heures)",
      cool_chain_adherence: "Respect de la chaîne du froid",
      vehicle_type: "Type de véhicule",
      driver_break_time: "Temps de pause du conducteur",
      on_trip: "En voyage",
      volume_cft: "Volume (CFT)",
      forecast_order_plan: "Plan de commande prévisionnel",
      planned_summary: "Résumé planifié",
      trip_no: "Numéro de voyage",
      no_of_trucks_planned: "Nombre de camions planifiés",
      planned_vehicles: "Véhicules planifiés",
      loading_time_hh_mm: "Temps de chargement (HH:MM)",
      plant_code: "Code de l'usine",
      transit_time: "Temps de transit",
      specifications: "Spécifications",
      max_weight_pounds: "Poids maximum (livres)",
      pick_up_location_code: "Code de lieu de collecte",
      bulk_upload_master_vehicles: "Téléversement massif de véhicules maîtres",
      add_master_vehicle: "Ajouter un véhicule maître",
      plan_history: "Historique du plan",
      driver_break_time_from: "Temps de pause du conducteur (à partir de)",
      zone_wise_vehicle_cost: "Coût du véhicule par zone",
      city_wise_vehicle_cost: "Coût du véhicule par ville",
      zone_mapping: "Cartographie des zones",
      cost_per_mile_below_sob: "Coût par mile (sous SOB)",
      cost_per_mile_after_sob: "Coût par mile (après SOB)",
      add_vehicle_cost: "Ajouter le coût du véhicule",
      vehicle_utilization_km: "Utilisation du véhicule (KM)",
      pickup_drop_points: "Points de collecte / livraison",
      facility_code: "Code de l'installation",
      destination_location_state: "État de la destination",
      driver_cost: "Coût du conducteur",
      next_availability_date_at_dc: "Prochaine date de disponibilité au DC",
      dealer_available_from: "Distributeur disponible à partir de",
      pickup_location_source: "Source du lieu de collecte",
      pickup_location_code: "Code de lieu de collecte",
      drop_location_code: "Code de lieu de livraison",
      drop_location_name: "Nom du lieu de livraison",
      drop_location_city: "Ville du lieu de livraison",
      drop_sequence: "Séquence de livraison",
      return_to_dc: "Retour au DC",
      near_customer: "Près du client",
      in_transit_to_customer: "En transit vers le client",
      empty_outside_dc: "Vide à l'extérieur du DC",
      unplanned: "Non planifié",
      vehicle_operational_hours: "Heures d'opération du véhicule (HH:MM)",
      plant_wise_configurations: "Configurations par usine",
      save_plant_wise_configurations:
        "Enregistrer les configurations par usine",
      plant_wise_vehicle_type_configuration:
        "Configuration du type de véhicule par usine",
      max_no_of_vehicle_types: "Nombre maximal de types de véhicules",
      max_distance_per_plan_miles: "Distance maximale par plan (miles)",
      max_distance_per_trip_miles: "Distance maximale par trajet (miles)",
      max_dispatch_time: "Temps maximal de dispatch (HH:MM)",
      validity_from: "Valide à partir de",
      validity_to: "Valide jusqu'à",
      next_available_date_yyyy_mm_dd_hh_mm:
        "Prochaine date disponible (AAAA-MM-JJ HH:MM)",
      vehicle_next_available_date: "Prochaine date disponible du véhicule",
      fixed_vehicle_cost: "Coût fixe du véhicule",
      variable_vehicle_cost: "Coût variable du véhicule",
      vehicle_operational_times: "Heures d'opération du véhicule",
      driver_break_time_per_day:
        "Temps de pause du conducteur par jour (HH:MM)",
      set_vehicle_priority: "Définir la priorité du véhicule",
      total_planned_trip_time_hrs: "Temps total de trajet planifié (heures)",
      total_loading_time_hh_mm: "Temps total de chargement (HH:MM)",
      total_round_trip_distance: "Distance totale aller-retour",
      arrival_at_first_dealer_start: "Arrivée au premier distributeur (début)",
      departure_at_last_dealer_end: "Départ du dernier distributeur (fin)",
      total_planned_weight_pounds: "Poids total planifié (livres)",
      total_planned_volume_cft: "Volume total planifié (CFT)",
      unplanned_loads: "Charges non planifiées",
      distance_to_destination_miles: "Distance jusqu'à la destination (miles)",
      expected_delivery_date: "Date de livraison prévue",
      total_unloading_time_hh_mm: "Temps total de déchargement (HH:MM)",
      vehicle_utilization_percentage: "Utilisation du véhicule (%)",
      arrival_at_first_dealer: "Arrivée au premier distributeur",
      departure_at_last_dealer: "Départ du dernier distributeur",
      fuel_type: "Type de carburant",
      glec_vehicle_type: "Type de véhicule GLEC",
      temperature_controlled: "Contrôle de la température",
      requirements_set: "Exigences définies",
      satisfied: "Satisfait",
      on_trip: "En voyage",
      requirement_set_date: "Date de définition des exigences",
      assessed_by: "Évalué par",
      security_conclusion: "Conclusion de la sécurité",
      security_comments: "Commentaires sur la sécurité",
      quality_conclusion: "Conclusion de la qualité",
      security: "Sécurité",
      return_to_plant: "Retour à l'usine",
      security_approved: "Approuvé par la sécurité",
      quality_approved: "Approuvé par la qualité",
      plant_name: "Nom de l'usine",
      facility_name: "Nom de l'installation",
      planning_percentage: "Pourcentage de planification",
      max_dispatch_time: "Temps maximum de répartition (HH:MM)",
      total_loading_time: "Temps total de chargement (HH:MM)",
      total_unloading_time: "Temps total de déchargement (HH:MM)",
      planned_summary: "Résumé planifié",
      invalid_orders_not_shown:
        "Remarque : Les commandes non valides ne sont pas affichées",
      no_of_customers: "Nombre de clients",
      no_of_orders: "Nombre de commandes",
      total_demand_volume: "Volume total de la demande (CFT)",
      planned_summary: "Résumé planifié",
      trips_removed: "Voyages supprimés",
      removed_transaction_ids: "Identifiants de transactions supprimés",
      vehicle_forecast_configurations:
        "Configurations de prévision des véhicules",
      pickup_point: "Point de ramassage",
      vehicle_type: "Type de véhicule",
      vehicle_name: "Nom du véhicule",
      cost_per_miles: "Coût par mille",
      driver_break_time: "Temps de pause du conducteur",
      view: "Voir",
      please_select_no_of_vehicles:
        "Veuillez sélectionner le nombre de véhicules",
      no_of_vehicles: "Nombre de véhicules",
      obd_analysis: "Analyse OBD",
      pickup_point: "Point de ramassage",
      select_month: "Sélectionnez le mois",
      location_radius_miles: "Rayon de la localisation (miles)",
      submit: "Soumettre",
      total_obd_details: "Détails totaux OBD",
      valid_dealers: "Distributeurs valides",
      invalid_dealers: "Distributeurs non valides",
      volume: "Volume",
      help: "Aide",
      change_password: "Changer le mot de passe",
      logout: "Déconnexion",
      weight_pounds: "Poids (livres)",
      quantity: "Quantité",
      obd_details_within_radius: "Détails OBD dans le rayon",
      dealers: "Distributeurs",
      dealers_percent: "Pourcentage de distributeurs",
      volume_percent: "Pourcentage de volume",
      weight_pounds_percent: "Pourcentage du poids (livres)",
      quantity_percent: "Pourcentage de la quantité",
      cost_per_miles: "Coût par mille ($)",
      driver_break_time_from: "Temps de pause du conducteur (de)",
      monthly_min_planning_distance_miles:
        "Distance minimale de planification mensuelle (miles)",
      vehicle_type_count: "Nombre de types de véhicules",
      driver_break_time_per_day:
        "Temps de pause du conducteur par jour (HH:MM)",
      cost_per_miles_2: "Coût par mille ($)",
      set_vehicle_priority: "Définir la priorité du véhicule",
      vehicle_type_name: "Nom du type de véhicule",
      min_volume_cft: "Volume minimum (CFT)",
      max_volume_cft: "Volume maximum (CFT)",
      max_distance_per_trip_miles: "Distance maximale par voyage (miles)",
      select_plan_id: "Sélectionner l'ID du plan",
      drop_details: "Détails de la livraison",
      drop_location_code: "Code de l'emplacement de livraison",
      vehicle_utilization: "Utilisation du véhicule",
      onward_trip_distance_miles: "Distance du voyage aller (miles)",
      round_trip_miles: "Voyage aller-retour (miles)",
      max_dispatch_time_hh_mm: "Temps maximum de répartition (HH:MM)",
      arrival_at_first_dealer_start:
        "Arrivée chez le premier distributeur (début)",
      departure_at_last_dealer_end: "Départ du dernier distributeur (fin)",
      return_to_plant_hh_mm: "Retour à l'usine (HH:MM)",
      vehicle_number: "Numéro du véhicule",
      vehicle_availability_from: "Disponibilité du véhicule (de)",
      vehicle_availability_to: "Disponibilité du véhicule (à)",
      break_time_from: "Temps de pause (de)",
      break_time_to: "Temps de pause (à)",
      no_of_vehicles: "Nombre de véhicules",
      driver_break_time_from: "Temps de pause du conducteur (de)",
      max_distance_miles: "Distance maximale (miles)",
      monthly_min_planning_distance_miles:
        "Distance minimale de planification mensuelle (miles)",
      total_planned_trip_time_hrs: "Temps total de voyage planifié (heures)",
      total_round_trip_time_hrs: "Temps total aller-retour (heures)",
      drop_location_code: "Code de l'emplacement de livraison",
      drop_location_name: "Nom de l'emplacement de livraison",
      drop_location_city: "Ville de l'emplacement de livraison",
      order_number: "Numéro de commande",
      delivery_date: "Date de livraison (M/J/AAAA)",
      back: "Retour",
      drop_location_name: "Nom de l'emplacement de livraison",
      pickup_location: "Emplacement de ramassage",
      dealer_available_from: "Distributeur disponible de",
      dealer_available_to: "Distributeur disponible jusqu'à",
      total_planned_weight: "Poids total planifié",
      total_planned_volume: "Volume total planifié",
      arrival_at_first_dealer: "Arrivée chez le premier distributeur",
      departure_at_last_dealer: "Départ du dernier distributeur",
      return_to_plant: "Retour à l'usine",
      summary_dashboard: "Tableau de bord récapitulatif",
      dispatch_plan_details: "Détails du plan de répartition",
      invalid_orders_note:
        "Remarque : Les commandes non valides ne sont pas affichées",
      planned_data: "Données planifiées",
      vfr_percentage: "Pourcentage VFR",
      cool_chain_adherence: "Adhérence à la chaîne du froid",
      transit_time: "Temps de transit",
      loading_time: "Temps de chargement",
      unloading_time: "Temps de déchargement",
      planned_summary: "Résumé planifié",
      invalid_drop_data: "Données de livraison non valides",
      vehicle_utilization_km: "Utilisation des véhicules (KM)",
      distance_to_destination: "Distance jusqu'à la destination",
      expected_delivery_date: "Date de livraison prévue",
      planned_vehicles: "Véhicules planifiés",
      trip_no: "Numéro de voyage",
      total_quantity: "Quantité totale",
      dealer_available_to: "Distributeur disponible jusqu'à",
      drop_location_code_destination:
        "Code de l'emplacement de livraison (destination)",
      order_number: "Numéro de commande",
      demand_volume_cft: "Volume de demande (CFT)",
      weight_pounds: "Poids (livres)",
      quantity: "Quantité",
      dropped_reason: "Raison de l'abandon",
      delivery_date: "Date de livraison",
      drop_location_name: "Nom de l'emplacement de livraison",
      drop_location_city: "Ville de l'emplacement de livraison",
      trip_no: "Numéro de voyage",
      planned_day: "Jour planifié",
      vehicle_no: "Numéro du véhicule",
      vehicle_type: "Type de véhicule",
      drop_sequence: "Séquence de livraison",
      invoicing_date: "Date de facturation",
      pickup_location_source: "Emplacement de ramassage (source)",
      destination_location_city: "Ville de destination",
      destination_location_state: "État de destination",
      distance_to_destination_miles: "Distance jusqu'à la destination (miles)",
      expected_delivery_date: "Date de livraison prévue",
      time_to_destination: "Temps jusqu'à la destination",
      transit_time_hh_mm: "Temps de transit (HH:MM)",
      loading_time_hh_mm: "Temps de chargement (HH:MM)",
      unloading_time_hh_mm: "Temps de déchargement (HH:MM)",
      total_planned_customers: "Nombre total de clients planifiés",
      total_round_trip_distance_km: "Distance totale aller-retour (KM)",
      planning_percentage: "Pourcentage de planification",
      cool_chain_adherence_percentage:
        "Pourcentage d'adhérence à la chaîne du froid",
      vfr_percentage: "Pourcentage VFR",
      total_transit_time_hh_mm: "Temps total de transit (HH:MM)",
      round_trip_miles: "Voyage aller-retour (miles)",
      total_planned_weight_pounds: "Poids total planifié (livres)",
      total_planned_volume_cft: "Volume total planifié (CFT)",
      arrival_at_first_dealer_start:
        "Arrivée chez le premier distributeur (début)",
      departure_at_last_dealer_end: "Départ du dernier distributeur (fin)",
      return_to_plant_hh_mm: "Retour à l'usine (HH:MM)",
      total_planned_trip_time_hrs: "Temps total de voyage planifié (heures)",
      total_round_trip_time_hrs: "Temps total aller-retour (heures)",
      total_loading_time_hh_mm: "Temps total de chargement (HH:MM)",
      total_unloading_time_hh_mm: "Temps total de déchargement (HH:MM)",
      vehicle_utilization_percentage: "Utilisation des véhicules (%)",
      view_order_information: "Voir les informations de commande",
      vehicle_configuration: "Configuration du véhicule",
      summary: "Résumé",
      planned_summary: "Résumé planifié",
      planned_vehicles: "Véhicules planifiés",
      unplanned_loads: "Chargements non planifiés",
      invalid_drop_data: "Données de livraison non valides",
      vehicle_utilization_km: "Utilisation des véhicules (KM)",
      planned_data: "Données planifiées",
      invalid_orders_note:
        "Remarque : Les commandes non valides ne sont pas affichées",
      set_vehicle_priority: "Définir la priorité du véhicule",
      vehicle_min_weight_pounds: "Poids minimum du véhicule (livres)",
      vehicle_max_weight_pounds: "Poids maximum du véhicule (livres)",
      vehicle_min_volume_cft: "Volume minimum du véhicule (CFT)",
      vehicle_max_volume_cft: "Volume maximum du véhicule (CFT)",
      max_distance_miles: "Distance maximale (miles)",
      vehicle_priority: "Priorité du véhicule",
      actions: "Actions",
      forecast_order_plan: "Plan de commande prévisionnel",
      drop_details: "Détails de la livraison",
      month: "Mois",
      year: "Année",
      total_quantity: "Quantité totale",
      total_weight_pounds: "Poids total (livres)",
      total_demand_volume_cft: "Volume total de la demande (CFT)",
      user: "Utilisateur",
      uploaded_date: "Date de téléchargement",
      view_order_details: "Voir les détails de la commande",
      pick_up_location_code: "Code de l'emplacement de ramassage",
      quantity: "Quantité",
      drop_location: "Emplacement de livraison",
      drop_location_name: "Nom de l'emplacement de livraison",
      weight: "Poids",
      volume: "Volume",
      delivery_date: "Date de livraison",
      invoice_date: "Date de facturation",
      order_no: "Numéro de commande",
      available_start_time: "Heure de début disponible",
      available_end_time: "Heure de fin disponible",
      upload_input_file: "Télécharger le fichier d'entrée",
      download_sample_template: "Télécharger le modèle d'échantillon",
      select_month: "Sélectionner le mois",
      upload_file: "Télécharger le fichier",
      save: "Sauvegarder",
      cancel: "Annuler",
      note_blank_rows_columns_not_allowed:
        "Remarque : Les lignes/colonnes vides ne sont pas autorisées",
      no_of_vehicles: "Nombre de véhicules",
      run_plan: "Exécuter le plan",
      vehicle_types: "Types de véhicules",
      upload_file: "Télécharger le fichier",
      pickup_point: "Point de ramassage",
      consolidated_route_plan_screen: "Écran de plan de route consolidé",
      start_date: "Date de début",
      end_date: "Date de fin",
      get_data: "Obtenir des données",
      recent_plans: "Plans récents",
      plan_history: "Historique du plan",
      view_input: "Voir l'entrée",
      consolidation_id: "ID de consolidation",
      created_date: "Date de création",
      run_by: "Exécuté par",
      no_of_executions: "Nombre d'exécutions",
      execution_status: "Statut de l'exécution",
      download_output: "Télécharger la sortie",
      upload_input_file: "Télécharger le fichier d'entrée",
      choose_file: "Choisir le fichier",
      discard_obd: "Écarter OBD",
      upload_obd_details: "Télécharger les détails OBD",
      upload_file: "Télécharger le fichier",
      submit: "Soumettre",
      download_template: "Télécharger le modèle",
      bulk_upload_locations: "Téléchargement en masse des emplacements",
      add_pickup_drop_points: "+Ajouter des points de ramassage/livraison",
      details: "Détails",
      date: "Date",
      total_trips: "Total des voyages",
      trips_edited: "Voyages édités",
      trips_removed: "Voyages supprimés",
      trips_edit_percentage: "Pourcentage de voyages édités",
      plans_overview_dashboard: "Tableau de bord de l'aperçu des plans",
      pickup_point: "Point de ramassage",
      location_type: "Type d'emplacement",
      location_code: "Code d'emplacement",
      location_name: "Nom de l'emplacement",
      location_city: "Ville de l'emplacement",
      zone: "Zone",
      customer_type: "Type de client",
      location_latitude: "Latitude de l'emplacement",
      location_longitude: "Longitude de l'emplacement",
      standard_transit_time: "Temps de transit standard (jours)",
      distance_from_plant: "Distance de l'usine (miles)",
      delta_warehouse: "Entrepôt Delta",
      home: "Accueil",
      run_new_plan: "Exécuter un nouveau plan",
      stacking: "Empilage",
      production_planning: "Planification de la production",
      forecasting: "Prévision",
      resource_scheduling: "Planification des ressources",
      general: "Général",
      pickup_drop_locations: "Emplacements de ramassage/livraison",
      vehicle_configurations: "Configurations des véhicules",
      vehicle_availability: "Disponibilité des véhicules",
      zone_mapping: "Cartographie des zones",
      transporters: "Transporteurs",
      material_configurations: "Configurations des matériaux",
      freight_configurations: "Configurations du fret",
      route_configurations: "Configurations des itinéraires",
      auto_clustering: "Regroupement automatique",
      transporter_share_of_business: "Part du transporteur dans les affaires",
      dispatch_plan_summary: "Résumé du plan de répartition",
      kpi_dashboard: "Tableau de bord KPI",
      planning_tools: "Outils de planification",
      forecast_tools: "Outils de prévision",
      suppliers: "Fournisseurs",
      customers: "Clients",
      materials: "Matériaux",
      input_data: "Données d'entrée",
      forecast: "Prévision",
      previous_plan: "Plan précédent",
      trips: "Voyages",
      gps_quality: "Qualité GPS",
      truck_gps_data: "Données GPS du camion",
      unscheduled_stoppages: "Arrêts non programmés",
      deviation_reports: "Rapports de déviation",
      fo_data: "Données FO",
      vehicle_checklist: "Liste de contrôle du véhicule",
      vehicle_status: "État du véhicule",
      route_restrictions: "Restrictions d'itinéraire",
      consolidated_run_plan: "Plan consolidé d'exécution",
      plans_overview: "Aperçu des plans",
      input_plan_summary: "Résumé du plan d'entrée",
      load_plan_summary: "Résumé du plan de chargement",
      plant_wise_configurations: "Configurations par usine",
      vehicle_configuration: "Configuration du véhicule",
      monthly_data: "Données mensuelles",
      forecast_plan_history: "Historique du plan de prévision",
      obd_analysis: "Analyse OBD",
      actions: "Actions",
      pickup_location_source: "Emplacement de ramassage (source)",
      drop_location_destination: "Emplacement de livraison (destination)",
      order_number: "Numéro de commande",
      invoicing_date: "Date de facturation (M/J/AAAA)",
      quantity: "Quantité",
      dealer_available_time_from:
        "Heure disponible du distributeur (à partir de 00:00)",
      dealer_available_time_to:
        "Heure disponible du distributeur (jusqu'à 23:59)",
      volume_cft: "Volume (CFT)",
      weight_pounds: "Poids (livres)",
      delivery_date: "Date de livraison (M/J/AAAA)",
      no_rows_to_show: "Aucune ligne à afficher",
      rows: "Lignes",
      total_rows: "Total des lignes",
      add_dispatch: "Ajouter une répartition",
      run_new_plan_by_file: "Exécuter un nouveau plan par fichier",
      submit_run_plan: "Soumettre et exécuter le plan",
      delete: "Supprimer",
      upload_input_file: "Télécharger le fichier d'entrée",
      upload_file: "Télécharger le fichier",
      download_sample_template: "Télécharger le modèle d'échantillon",
      save: "Sauvegarder",
      cancel: "Annuler",
      dealer_available_time_error:
        "L'heure de début doit être inférieure à l'heure de fin",
      ok: "Ok",
      summary_dashboard: "Tableau de bord récapitulatif",
      pickup_point: "Point de ramassage",
      date_created: "Date de création",
      get_data: "Obtenir des données",
      plan_details: "Détails du plan",
      plan_history: "Historique du plan",
      drop_details: "Détails de la livraison",
      generate_plan: "Générer un plan",
      order_id: "ID de la commande",
      no_of_runs: "Nombre d'exécutions",
      created_on: "Créé le",
      plan_run_by: "Plan exécuté par",
      discard_obd: "Écarter OBD",
      upload_obd_details: "Télécharger les détails OBD",
      download_template: "Télécharger le modèle",
      choose_file: "Choisir le fichier",
      submit: "Soumettre",
      start_date: "Date de début",
      end_date: "Date de fin",
      get_data: "Obtenir des données",
      upload_input_file: "Télécharger le fichier d'entrée",
      discard_obd: "Écarter OBD",
      recent_plans: "Plans récents",
      plan_history: "Historique du plan",
      view_input: "Voir l'entrée",
      consolidation_id: "ID de consolidation",
      created_date: "Date de création",
      run_by: "Exécuté par",
      no_of_executions: "Nombre d'exécutions",
      execution_status: "Statut de l'exécution",
      download_output: "Télécharger la sortie",
      upload_obd_details: "Télécharger les détails OBD",
      download_template: "Télécharger le modèle",
      choose_file: "Choisir le fichier",
      submit: "Soumettre",
      simulation_time: "Heure de simulation",
      enable_dc_week_off_planning:
        "Activer la planification des semaines de repos du DC",
      run_plan: "Exécuter le plan",
      cancel: "Annuler",
      plan_details: "Détails du plan",
      pickup_point: "Point de ramassage",
      date_created: "Date de création",
      order_id: "ID de la commande",
      no_of_runs: "Nombre d'exécutions",
      fix_and_reupload: "Corriger et télécharger à nouveau",
      club_previous_order: "Regrouper la commande précédente",
      ignore: "Ignorer",
      dealer_code: "Code du distributeur",
      remarks: "Remarques",
      run_new_plan: "Exécuter un nouveau plan",
      consolidated_route_plan_screen: "Écran du plan de route consolidé",
      plant_code: "Code de l'usine",
      plant_name: "Nom de l'usine",
      total_trips: "Nombre total de voyages",
      trips_edited: "Voyages édités",
      edited_transaction_ids: "Identifiants de transactions édités",
      vehicle_type: "Type de véhicule",
      vehicle_name: "Nom du véhicule",
      weight_pounds: "Poids (livres)",
      drop_location_city: "Ville de l'emplacement de livraison",
      quantity: "Quantité",
      planned_vehicles: "Véhicules planifiés",
      trip_no: "Numéro de voyage",
      total_planned_customers: "Nombre total de clients planifiés",
      total_round_trip_distance: "Distance totale aller-retour",
      planning_percentage: "Pourcentage de planification",
      max_dispatch_time: "Temps maximum de répartition (HH:MM)",
      total_loading_time: "Temps total de chargement (HH:MM)",
      total_unloading_time: "Temps total de déchargement (HH:MM)",
      planned_summary: "Résumé planifié",
      invalid_orders_not_shown:
        "Remarque : Les commandes non valides ne sont pas affichées",
      no_of_customers: "Nombre de clients",
      no_of_orders: "Nombre de commandes",
      total_demand_volume: "Volume total de la demande (CFT)",
      planned_summary: "Résumé planifié",
      trips_removed: "Voyages supprimés",
      removed_transaction_ids: "Identifiants de transactions supprimés",
      vehicle_forecast_configurations:
        "Configurations de prévision des véhicules",
      pickup_point: "Point de ramassage",
      vehicle_type: "Type de véhicule",
      vehicle_name: "Nom du véhicule",
      cost_per_miles: "Coût par mille",
      driver_break_time: "Temps de pause du conducteur",
      view: "Voir",
      please_select_no_of_vehicles:
        "Veuillez sélectionner le nombre de véhicules",
      no_of_vehicles: "Nombre de véhicules",
      cost_per_miles: "Coût par mille ($)",
      driver_break_time_from: "Temps de pause du conducteur (de)",
      monthly_min_planning_distance_miles:
        "Distance minimale de planification mensuelle (miles)",
      vehicle_type_count: "Nombre de types de véhicules",
      driver_break_time_per_day:
        "Temps de pause du conducteur par jour (HH:MM)",
      cost_per_miles_2: "Coût par mille ($)",
      set_vehicle_priority: "Définir la priorité du véhicule",
      vehicle_type_name: "Nom du type de véhicule",
      min_volume_cft: "Volume minimum (CFT)",
      max_volume_cft: "Volume maximum (CFT)",
      max_distance_per_trip_miles: "Distance maximale par voyage (miles)",
      select_plan_id: "Sélectionner l'ID du plan",
      drop_details: "Détails de la livraison",
      drop_location_code: "Code de l'emplacement de livraison",
      drop_location_name: "Nom de l'emplacement de livraison",
      pickup_location: "Emplacement de ramassage",
      dealer_available_from: "Distributeur disponible de",
      dealer_available_to: "Distributeur disponible jusqu'à",
      total_planned_weight: "Poids total planifié",
      total_planned_volume: "Volume total planifié",
      arrival_at_first_dealer: "Arrivée chez le premier distributeur",
      departure_at_last_dealer: "Départ du dernier distributeur",
      return_to_plant: "Retour à l'usine",
      summary_dashboard: "Tableau de bord récapitulatif",
      dispatch_plan_details: "Détails du plan de répartition",
      invalid_orders_note:
        "Remarque : Les commandes non valides ne sont pas affichées",
      planned_data: "Données planifiées",
      vfr_percentage: "Pourcentage VFR",
      cool_chain_adherence: "Adhérence à la chaîne du froid",
      transit_time: "Temps de transit",
      loading_time: "Temps de chargement",
      unloading_time: "Temps de déchargement",
      planned_summary: "Résumé planifié",
      invalid_drop_data: "Données de livraison non valides",
      vehicle_utilization_km: "Utilisation des véhicules (KM)",
      distance_to_destination: "Distance jusqu'à la destination",
      expected_delivery_date: "Date de livraison prévue",
      planned_vehicles: "Véhicules planifiés",
      trip_no: "Numéro de voyage",
      total_quantity: "Quantité totale",
      dealer_available_to: "Distributeur disponible jusqu'à",
      drop_location_code_destination:
        "Code de l'emplacement de livraison (destination)",
      order_number: "Numéro de commande",
      demand_volume_cft: "Volume de demande (CFT)",
      weight_pounds: "Poids (livres)",
      quantity: "Quantité",
      dropped_reason: "Raison de l'abandon",
      delivery_date: "Date de livraison",
      drop_location_name: "Nom de l'emplacement de livraison",
      drop_location_city: "Ville de l'emplacement de livraison",
      trip_no: "Numéro de voyage",
      planned_day: "Jour planifié",
      vehicle_no: "Numéro du véhicule",
      vehicle_type: "Type de véhicule",
      drop_sequence: "Séquence de livraison",
      invoicing_date: "Date de facturation",
      pickup_location_source: "Emplacement de ramassage (source)",
      destination_location_city: "Ville de destination",
      destination_location_state: "État de destination",
      distance_to_destination_miles: "Distance jusqu'à la destination (miles)",
      expected_delivery_date: "Date de livraison prévue",
      time_to_destination: "Temps jusqu'à la destination",
      invalid_material: "Matériau non valide",
      drop_location_code_destination:
        "Code de l'emplacement de livraison (destination)",
      demand_volume_cft: "Volume de demande (CFT)",
      dropped_reason: "Raison de l'abandon",
      dealer_location_data_not_in_system:
        "Données de localisation du distributeur non dans le système",
      delivery_date_less_than_current:
        "La date de livraison est inférieure à la date actuelle",
      missing_dealer_information: "Informations sur le distributeur manquantes",
      transit_time_hh_mm: "Temps de transit (HH:MM)",
      loading_time_hh_mm: "Temps de chargement (HH:MM)",
      unloading_time_hh_mm: "Temps de déchargement (HH:MM)",
      actions: "Actions",
      expected_delivery_date: "Date de livraison prévue",
      max_dispatch_time: "Temps maximum de répartition (HH:MM)",
      unplanned_loads: "Chargements non planifiés",
      monthly_data: "Données mensuelles",
      forecast_plan_history: "Historique du plan de prévision",
      obd_analysis: "Analyse OBD",
      dealer_available_time_error:
        "L'heure de début doit être inférieure à l'heure de fin",
    },
  },
  es: {
    translation: {
      "route_type": "Tipo de Ruta",
      vehicles_recommended: "Vehículos Recomendados",
      "Number Of Trips": "Número de Viajes",
  "Total": "Total",
  "Day-2": "Día-2",
  "Day-3": "Día-3",
  "Day-4": "Día-4",
  "Day-5": "Día-5",
  "Day-6": "Día-6",
  "Distance / Time From Pickup Location": "Distancia / Tiempo desde la ubicación de recogida",
  "Distance / Time From Previous Drop Point": "Distancia / Tiempo desde el punto de entrega anterior",
  "Location City": "Ciudad de ubicación",
  "Planned Day": "Día planificado",
  "Quantity": "Cantidad",
  "Demand Weight": "Peso de la demanda",
  "Demand Volume": "Volumen de demanda",
  "Location": "Ubicación",
  "Weight": "Peso",
  "View Onward Route": "Ver ruta de ida",
  "View Return Route": "Ver ruta de regreso",
  "Quantity": "Cantidad",
  "Weight": "Peso",
  "Spot": "Punto",
  "Local": "Local",
  "Upcountry": "Interior",
  "Volume": "Volumen",
  "IN416": "IN416",
  "IN436": "IN436",
  "Spot": "Spot",
  "2,177": "2,177",
  "1,268": "1,268",
  "909": "909",
  "0": "0",
  "Planned Truck Load": "Carga Planeada del Camión",
  "Quantity (cases)": "Cantidad (casos)",
  "Weight (Kgs)": "Peso (Kgs)",
  "Volume (CMT)": "Volumen (CMT)",
  "1,81,970": "1,81,970",
  "25,25,554": "25,25,554",
  "8,249": "8,249",
  "Unplanned Load": "Carga No Planeada",
  "26,324": "26,324",
  "3,74,549": "3,74,549",
  "1,234": "1,234",
  "Average Distance Travelled Per Vehicle (KM)": "Distancia Media Recorrida por Vehículo (KM)",
  "35,426": "35,426",
  "37,624": "37,624",
  "28,111": "28,111",
  "Operational Cost (Rs.)": "Costo Operacional (Rs.)",
  "1,62,57,137": "1,62,57,137",
  "1,31,64,341": "1,31,64,341",
  "30,92,797": "30,92,797",
  "Average Vehicle Fill Rate": "Tasa Promedio de Llenado del Vehículo",
  "60 %": "60 %",
  "42 %": "42 %",
  "79 %": "79 %",
  "Unplanned Load Proportion": "Proporción de Carga No Planeada",
  "12 %": "12 %",
  "Average Vehicle Utilization As Per SOB": "Utilización Promedio de Vehículos Según SOB",
  "104 %": "104 %",
  "94 %": "94 %",
  "Ontime Dispatches": "Despachos a Tiempo",
  "Local": "Local",
  "frefe": "frefe",
  "94 %": "94 %",
  "92 %": "92 %",
  "Ontime Deliveries": "Entregas a Tiempo",
  "97 %": "97 %",
  "94 %": "94 %",
      "Please Select Scenario to Compare Plans": "Por favor, seleccione el escenario para comparar los planes",
"Percentage": "Porcentaje",
"Forecast Scenario": "Escenario de previsión",
      "Number of kms Travelled": "Número de kms recorridos",
"Cost": "Costo",
"Vehicle Fill Rate": "Tasa de llenado del vehículo",
"Vehicle KM Run %": "Porcentaje de kms recorridos por el vehículo",
      "Please Select File": "Por favor seleccione el archivo",
"Processing Plan. Please wait.....": "Plan en proceso. Por favor espere.....",
"File is processing": "El archivo está siendo procesado",
"Enter Input Data To Continue": "Ingrese los datos de entrada para continuar",
"No Data": "Sin datos",
"please_select_date": "Por favor seleccione la fecha",
"data_uploaded": "Datos subidos",
"Please Select No of Vehicles": "Por favor seleccione el número de vehículos",
"Please Input Valid Data": "Por favor ingrese datos válidos",
      "loading....": "Cargando....",
      "change_password": "Cambiar la contraseña",
  "new_password": "Nueva contraseña",
  "confirm_new_password": "Confirmar nueva contraseña",
  "driver_break_time_to": "Tiempo de descanso del conductor hasta",
"max_seating_capacity": "Capacidad máxima de asientos",
"min_seating_capacity": "Capacidad mínima de asientos",
"vehicle_seating_capacity": "Capacidad de asientos del vehículo",
"vehicle_capacity_cmt": "Capacidad del vehículo (m³)",
  "old_password": "Contraseña antigua",
  "enter_email": "Ingresa tu correo electrónico",
  "enter_new_password": "Ingresa tu nueva contraseña",
  "confirm_new_password_entry": "Confirma tu nueva contraseña",
  "select_cluster": "Seleccionar Clúster",
  "Overall Cluster Wise Vehicles": "Vehículos por Clúster General",
  "Discard": "Descartar",
  "obd": "OBD",
  "Dispatch Planning is in progress..!!!": "La planificación de despacho está en progreso..!!!",
  "Estimated Time": "Tiempo Estimado",
  "GPS": "GPS",
  "Spot Vehicles To Be Planned": "Vehículos Spot a Planificar",
  "No Of Spot Vehicles": "Número de Vehículos Spot",
  "Availability DateTime": "Fecha y Hora de Disponibilidad",
  "Notify Email Users After Process (comma seperated)": "Notificar a los usuarios de correo electrónico después del proceso (separados por comas)",
  "Max Time Limit(Hrs)": "Límite Máximo de Tiempo (Horas)",
  "vfr": "VFR",
  "Disclaimer": "Descargo de responsabilidad: La ventana disponible para los concesionarios para clientes OT será considerada desde el archivo de entrada, los horarios de los clientes GT serán considerados desde las ubicaciones de recogida/entrega.",
  "To Check for Blank Data": "Para verificar los datos en blanco: 1. Haga clic en 'Buscar y seleccionar'. 2. Haga clic en 'Ir a Especial'. 3. Elija 'En blanco'. 4. Haga clic en 'Aceptar' y todas las filas/celdas en blanco se resaltarán. 5. Elija 'Eliminar' en la sección de celdas en la pestaña de Inicio. 6. Haga clic en 'Eliminar filas de la hoja'.",
  "Add New OBD": "Agregar Nuevo OBD",
  "All drop locations are invalid, Please check..!!": "Todas las ubicaciones de entrega son inválidas, ¡Por favor verifique..!!",
  "Please upload file having extensions .csv or .xlsx only.": "Por favor suba un archivo con extensiones .csv o .xlsx solamente.",
  "Dispatch Load Volume Summary (CFT)": "Resumen del Volumen de Carga Despachada (CFT)",
  "Total Loads Volume (CFT)": "Volumen Total de Cargas (CFT)",
  "Planned Loads Volume (CFT)": "Volumen de Cargas Planificadas (CFT)",
  "Unplanned Loads Volume (CFT)": "Volumen de Cargas No Planificadas (CFT)",
  "Dropped Loads Volume (CFT)": "Volumen de Cargas Caídas (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "Resumen del Peso de Carga Despachada (Libras)",
  "Total Loads Weight (Pounds)": "Peso Total de Cargas (Libras)",
  "Planned Loads Weight (Pounds)": "Peso de Cargas Planificadas (Libras)",
  "Unplanned Loads Weight (Pounds)": "Peso de Cargas No Planificadas (Libras)",
  "Dropped Loads Weight (Pounds)": "Peso de Cargas Caídas (Libras)",
  "Dispatch Load Quantity Summary": "Resumen de Cantidad de Carga Despachada",
  "Total Quantity": "Cantidad Total",
  "Planned Quantity": "Cantidad Planificada",
  "Unplanned Quantity": "Cantidad No Planificada",
  "Dropped Quantity": "Cantidad Caída",
  "Dispatch Load Volume Summary (CFT)": "Resumen del Volumen de Carga Despachada (CFT)",
  "Total Loads Volume (CFT)": "Volumen Total de Cargas (CFT)",
  "Planned Loads Volume (CFT)": "Volumen de Cargas Planificadas (CFT)",
  "Unplanned Loads Volume (CFT)": "Volumen de Cargas No Planificadas (CFT)",
  "Dropped Loads Volume (CFT)": "Volumen de Cargas Caídas (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "Resumen del Peso de Carga Despachada (Libras)",
  "Total Loads Weight (Pounds)": "Peso Total de Cargas (Libras)",
  "Planned Loads Weight (Pounds)": "Peso de Cargas Planificadas (Libras)",
  "Unplanned Loads Weight (Pounds)": "Peso de Cargas No Planificadas (Libras)",
  "Dropped Loads Weight (Pounds)": "Peso de Cargas Caídas (Libras)",
  "Dispatch Load Quantity Summary": "Resumen de Cantidad de Carga Despachada",
  "Total Quantity": "Cantidad Total",
  "Planned Quantity": "Cantidad Planificada",
  "Unplanned Quantity": "Cantidad No Planificada",
  "Dropped Quantity": "Cantidad Caída",
  "Dispatch Load Weight Summary (Pounds)": "Resumen del Peso de Carga Despachada (Libras)",
  "Dispatch Load Volume Summary (CFT)": "Resumen del Volumen de Carga Despachada (CFT)",
  "Dropped Data": "Datos Caídos",
  "ontime_delivery": "Entrega a Tiempo",
  "dealer_Available_start_time_should_be_less_than_end_time": "El tiempo de inicio de disponibilidad del concesionario debe ser menor que el tiempo de finalización",
  "Removed": "Eliminado",
  "Unedited": "Sin Editar",
  "Edited": "Editado",
      vehicle_availability_configurations: "Configuraciones de Disponibilidad de Vehículos",
      plant: "Planta",
      max_distance_per_plan_miles: "Distancia Máxima por Plan (Millas)",
    add_city_wise_cost: "Agregar Costo por Ciudad",
    add_zone_wise_cost: "Agregar Costo por Zona",
    scenarios: "Escenarios",
      standard_transit_time_days: "Tiempo de Tránsito Estándar (Días)",
    drop_to_time: "Hora de Entrega",
    drop_from_time: "Hora de Salida",
    pickup_to_time: "Hora de Recogida",
    pickup_from_time: "Hora de Inicio de Recogida",
    distance_from_plant_miles: "Distancia Desde la Planta (Millas)",
    vehicle_next_available_date: "Fecha de Próxima Disponibilidad del Vehículo",
    max_distance_per_plan_miles: "Distancia Máxima por Plan (Millas)",
    remaining_monthly_distance_miles: "Distancia Mensual Restante (Millas)",
    assign_quality_checks: "Asignar Controles de Calidad",
    add_fixed_vehicle_cost: "Agregar Costo Fijo del Vehículo",
      customer: "Cliente",
      "Pickup Locations (Drop)*": "Ubicaciones de Recogida (Entrega)*",
      "pickup_locations_drop*": "Ubicaciones de Recogida (Entrega)*",
      duplicate_obds_light_red: "Los OBD duplicados se muestran en color rojo claro",
    new_obds_green: "Los OBD recién agregados se muestran en verde",
    pickup_locations_drop: "Ubicaciones de Recogida (Entrega)",
      percent_unplanned: "Porcentaje No Planificado",
      invalid_orders_not_shown: "Los pedidos no válidos no se están mostrando",
    note: "Nota",
      please_select_scenario: "Por Favor Seleccione un Escenario para Comparar Planes",
    avg_kms_travelled: "Promedio de Kilómetros Recorridos",
    total_cost: "Costo Total",
    total_vehicle_fill_rate: "Tasa Total de Ocupación del Vehículo",
    percent_km_run_sob: "Porcentaje de KM Recorridos SOB",
    percent_ontime_dispatch: "Porcentaje de Despachos a Tiempo",
    upcountry: "Interior",
    percent_spot_vehicles_trips: "Porcentaje de Viajes de Vehículos Spot",
    round_trip_distance: "Distancia de Ida y Vuelta",
    total_trip_distance: "Distancia Total del Viaje",
    vehicle_type: "Tipo de Vehículo",
    plant_code: "Código de Planta",
    vehicle_no: "Número de Vehículo",
    monthly_sob_km: "SOB Mensual (Km)",
    distance_travelled_km: "Distancia Recorrida (Km)",
    pickup_location_source: "Ubicación de Recogida (Origen)",
    drop_location_code_destination: "Código de Ubicación de Entrega (Destino)",
    drop_location_name_destination: "Nombre de Ubicación de Entrega (Destino)",
    order_number: "Número de Pedido",
    invoicing_date: "Fecha de Facturación",
    quantity: "Cantidad",
    weight_kgs: "Peso (Kgs)",
    demand_volume_m3: "Volumen Demandado (M3)",
    dealer_available_from: "Concesionario Disponible Desde",
    dealer_available_to: "Concesionario Disponible Hasta",
    delivery_date: "Fecha de Entrega",
    trip_no: "Número de Viaje",
    planned_day: "Día Planificado",
    drop_sequence: "Secuencia de Entrega",
    destination_location_city: "Ciudad de Ubicación de Destino",
    destination_location_state: "Estado de Ubicación de Destino",
    volume_m3: "Volumen (M3)",
    distance_to_destination_kms: "Distancia al Destino (Kms)",
    expected_delivery_date: "Fecha de Entrega Esperada",
    time_to_destination: "Tiempo al Destino",
    transit_time_hhmm: "Tiempo de Tránsito (HH:MM)",
    loading_time_hhmm: "Tiempo de Carga (HH:MM)",
    unloading_time_hhmm: "Tiempo de Descarga (HH:MM)",
    start_month: "Mes de Inicio",
    end_month: "Mes de Fin",
    get_data: "Obtener Datos",
    select_chart_type: "Seleccionar Tipo de Gráfico",
    fleet_analysis: "Análisis de Flota",
    system_recommended: "Sistema Recomendado",
    total_load: "Carga Total",
    serviceability: "Operatividad",
    in416: "IN416",
    in436: "IN436",
    user_recommended: "Usuario Recomendado",
    threshold: "Umbral",
    number_of_trips: "Número de Viajes",
    total: "Total",
    spot: "Spot",
    planned_truck_load: "Carga Planificada del Camión",
    quantity_cases: "Cantidad (cajas)",
    weight_kgs: "Peso (Kgs)",
    volume_cmt: "Volumen (CMT)",
    unplanned_load: "Carga No Planificada",
    average_distance_travelled_per_vehicle_km: "Distancia Media Recorrida por Vehículo (KM)",
    operational_cost_rs: "Costo Operacional (Rs.)",
    average_vehicle_fill_rate: "Tasa Media de Ocupación del Vehículo",
    unplanned_load_proportion: "Proporción de Carga No Planificada",
    average_vehicle_utilization_as_per_sob: "Utilización Media del Vehículo Según SOB",
    ontime_dispatches: "Despachos a Tiempo",
    local: "Local",
    upcountry: "Interior",
    ontime_deliveries: "Entregas a Tiempo",
    bar: "Barra",
    load: "Carga",
    month: "Mes",
    percentage: "%",









      //code for configurations screen

      "Consider Transporter SOB": "Considerar transportista SOB",
      forecast_summary: "Resumen de Pronóstico",
      compare: "Comparar",
    pickup_locations_drop: "Ubicaciones de Recogida (Entrega)",
      "note_highlighted_rows_red": "Nota: 1. Las filas resaltadas en rojo carecen de datos GPS o integración, marque la disponibilidad del vehículo manualmente.",
  "highlighted_rows_green": "2. Las filas resaltadas en verde se consideran para la planificación",
  "enable_reconciliation": "Habilitar la reconciliación",
  "enable_dc_week_off_planning": "Habilitar la planificación de semana libre del centro de datos",
      enable_disable: "Habilitar/Deshabilitar",
      "Consider Dropping Distant Smaller Loads":
        "Considerar dejar cargas pequeñas y distantes",
      "Consider Realtime Vehicle Available Times":
        "Considerar los tiempos disponibles de los vehículos en tiempo real",
      "Plant Wise Vehicle Type Configuration*":
        "Configuración de tipo de vehículo por planta*",
      "Consignees / Dealers Locations (Drop)*":
        "Ubicaciones de consignatarios / distribuidores (Entrega)*",
      "Pickup Locations (Pickup)*": "Ubicaciones de recogida (Recoger)*",
      "Optimization Type*": "Tipo de optimización*",
      "Plant Wise Planning History*": "Historial de planificación por planta*",
      "Plant Wise Plan Summary*": "Resumen de plan por planta*",
      "Plant Wise New Plan Creation*": "Creación de nuevo plan por planta*",
      "Suppliers / Vendor Locations (Pickup)*":
        "Ubicaciones de proveedores / vendedores (Recoger)*",
      "Plant Wise Vehicle Type Configuration*":
        "Configuración de tipo de vehículo por planta*",
      "Consignees / Dealers Locations (Drop)*":
        "Ubicaciones de consignatarios / distribuidores (Entrega)*",
      "Pickup Locations (Pickup)*": "Ubicaciones de recogida (Recoger)*",
      "Plant Wise Vehicle Type Configuration*":
        "Configuración de tipo de vehículo por planta*",
      "Suppliers / Vendor Locations (Pickup)*":
        "Ubicaciones de proveedores / vendedores (Recoger)*",
      "Plant Wise Planning History*": "Historial de planificación por planta*",
      "Plant Wise Plan Summary*": "Resumen de plan por planta*",
      "Plant Wise New Plan Creation*": "Creación de nuevo plan por planta*",
      "Enable/Disable Dispatch Planning Module":
        "Habilitar/Deshabilitar módulo de planificación de envíos",
      Inbound: "Entrante",
      Outbound: "Saliente",
      "Suppliers / Vendor Locations (Pickup)":
        "Ubicaciones de proveedores / vendedores (Recoger)",
      "Operational Hours": "Horas operativas",
      "Pickup Locations (Drop)": "Ubicaciones de recogida (Entrega)",
      "Plant Wise Vehicle Type Configuration":
        "Configuración de tipo de vehículo por planta",
      "Max No. Of Vehicle Types": "Máximo número de tipos de vehículos",
      "Vehicle Load Capacity Min / Max Configuration Preferences":
        "Preferencias de configuración de capacidad de carga de vehículo mínima / máxima",
      "Vehicle Load Weight Min / Max Preferences":
        "Preferencias de peso de carga de vehículo mínima / máxima",
      "Distance preferences": "Preferencias de distancia",
      "Vehicle Priorities": "Prioridades de vehículos",
      "Vehicle Operational Times": "Tiempos operativos de vehículos",
      "Vehicle Availability": "Disponibilidad de vehículos",
      "Plant Wise Freight Configuration": "Configuración de flete por planta",
      "Plant Wise Transporter Configuration":
        "Configuración de transportista por planta",
      "Route Preferences": "Preferencias de ruta",
      "Plant Wise New Plan Creation": "Creación de nuevo plan por planta",
      "Plant Wise Plan Summary": "Resumen de plan por planta",
      "Plant Wise Planning History": "Historial de planificación por planta",
      "No of Vehicles Types": "Número de tipos de vehículos",
      "Generic Time Windows": "Ventanas de tiempo genéricas",
      "Individual Vehicle Time Windows":
        "Ventanas de tiempo de vehículo individual",
      "Availability By Vehicle Type": "Disponibilidad por tipo de vehículo",
      "Availability By Individual Vehicle":
        "Disponibilidad por vehículo individual",
      "Monthly Minimum Planning Distance Preferences":
        "Preferencias de distancia mínima de planificación mensual",
      "GPS Based Availability": "Disponibilidad basada en GPS",
      "Truck Location Visibility": "Visibilidad de ubicación del camión",
      "Plant Vicinity Circle Radius":
        "Radio de círculo de proximidad de la planta",
      "Fixed Vehicle Costs": "Costos fijos de vehículos",
      "Variable Costs": "Costos variables",
      "Vehicle Type Level Variable Costs":
        "Costos variables a nivel de tipo de vehículo",
      "City Level Vehicle Type Costs":
        "Costos por tipo de vehículo a nivel de ciudad",
      "Per CFT Cost": "Costo por CFT",
      "Per Mile Cost": "Costo por milla",
      "Zone Level Cost": "Costo a nivel de zona",
      "Transporter Share of Business": "Parte del negocio del transportista",
      "Sustainability Integration": "Integración de sostenibilidad",
      "Optimization Type": "Tipo de optimización",
      "Cost Optimization": "Optimización de costos",
      "Weight based optimization": "Optimización basada en peso",
      "Distance based optimization": "Optimización basada en distancia",
      "Time based optimization": "Optimización basada en tiempo",
      "Volume based optimization": "Optimización basada en volumen",
      "Prioritize Local Loads": "Priorizar cargas locales",
      "Radius Miles": "Radio en millas",
      "Number of Loading Docks": "Número de muelles de carga",
      "City Based Entry Restriction": "Restricción de entrada basada en ciudad",
      "Unloading time per case/max unloading time":
        "Tiempo de descarga por caso/tiempo máximo de descarga",
      "Consider Vehicle Break Times (Night Driving)":
        "Considerar tiempos de descanso del vehículo (Conducción nocturna)",
      "Consider Holiday Restrictions": "Considerar restricciones de vacaciones",
      "Vehicle Restriction": "Restricción de vehículos",
      "Plan Spot Vehicles": "Planificar vehículos spot",
      "Max No of Customers Per Vehicle":
        "Máximo número de clientes por vehículo",
      "Use Dynamic Planning Window": "Usar ventana de planificación dinámica",
      "Number of Days to Use Planning Window":
        "Número de días para usar la ventana de planificación",
      "Use Volume for Vehicle Fill Rate (VFR)":
        "Usar volumen para la tasa de llenado del vehículo (VFR)",
      "Use Weight for Vehicle Fill Rate (VFR)":
        "Usar peso para la tasa de llenado del vehículo (VFR)",
      "Min VFR Per Vehicle Type": "Mínimo VFR por tipo de vehículo",
      "Min VFR Per Spot Vehicle": "Mínimo VFR por vehículo spot",
      "Prioritize Same Dealers First in a Vehicle":
        "Priorizar a los mismos distribuidores primero en un vehículo",
      "Prioritize Multi City Dealers First in a Vehicle":
        "Priorizar a los distribuidores de varias ciudades primero en un vehículo",
      "Dispatch within Days": "Despacho en días",
      "Allow Unplanned Loads": "Permitir cargas no planificadas",
      "Minimize Number of Vehicles Used":
        "Minimizar el número de vehículos utilizados",
      "Dealer Buffer Time": "Tiempo de reserva del distribuidor",
      "Buffer Time(Mins)": "Tiempo de reserva (Minutos)",
      "Month End Planning Start Day":
        "Día de inicio de la planificación de fin de mes",
      "Day of Every Month": "Día de cada mes",
      "Max Local No of Customers Per Vehicle":
        "Máximo número de clientes locales por vehículo",
      "Max Upcountry No of Customers Per Vehicle":
        "Máximo número de clientes rurales por vehículo",
      "Planning Type": "Tipo de planificación",
      "Local Prioritization": "Priorización local",
      "Local Prioritization and Virtual Upcountry clubbing":
        "Priorización local y agrupación rural virtual",
      "General Plan (Local and Upcountry clubbing)":
        "Plan general (agrupación local y rural)",
      "Telescopic Deviation": "Desviación telescópica",
      "MOQ for Planning": "Cantidad mínima para planificación",
      Reconciliation: "Reconciliación",
      "DC Week Off planning": "Planificación de la semana libre DC",
      "Save Inbound Configurations": "Guardar configuraciones entrantes",
      "Save Outbound Configurations": "Guardar configuraciones salientes",
      SAVE: "GUARDAR",
      "Enable Reconciliation": "Habilitar reconciliación",
      "Enable DC Week Off planning":
        "Habilitar planificación de semana libre DC",
      "Deviation Alert": "Alerta de desviación",
      "Overload Warning": "Advertencia de sobrecarga",
      "Underload Alert": "Alerta de subcarga",
      "Fuel Efficiency Report": "Informe de eficiencia de combustible",
      "Vehicle Downtime": "Tiempo de inactividad del vehículo",
      "Speed Violation Alert": "Alerta de violación de velocidad",
      "Trip Delay Notification": "Notificación de retraso de viaje",
      "Idle Time Monitoring": "Monitoreo de tiempo inactivo",
      "Driver Performance": "Desempeño del conductor",
      "Realtime GPS Tracking": "Seguimiento GPS en tiempo real",
      "Fleet Utilization": "Utilización de la flota",
      "Trip Efficiency": "Eficiencia del viaje",
      "Dynamic Route Adjustment": "Ajuste dinámico de ruta",
      "Load Distribution Optimization": "Optimización de distribución de carga",
      "Transporter Preferences": "Preferencias del transportista",
      "Trip Schedule": "Horario de viaje",
      "Driver Assignment": "Asignación de conductor",
      "Optimize Vehicle Utilization": "Optimizar la utilización de vehículos",
      "Time Based Planning": "Planificación basada en tiempo",
      "Plant Wise Route Planning": "Planificación de ruta por planta",
      "Vehicle Rotation Configuration":
        "Configuración de rotación de vehículos",
      "Inbound Vehicle Preferences": "Preferencias de vehículos entrantes",
      "Outbound Vehicle Preferences": "Preferencias de vehículos salientes",
      "Vehicle Fill Rate Optimization":
        "Optimización de tasa de llenado de vehículos",
      "Dynamic Load Allocation": "Asignación dinámica de carga",
      "Start Date": "Fecha de inicio",
      "Run Plan": "Plan de ejecución",
      "Completion Date": "Fecha de finalización",
      "Transporter Name": "Nombre del transportista",
      "Driver Allocation": "Asignación de conductor",
      "Max Load Capacity": "Capacidad máxima de carga",
      "Estimated Delivery Time": "Tiempo estimado de entrega",
      "Use Real-Time Traffic Data": "Usar datos de tráfico en tiempo real",
      "Driver Break Times": "Tiempos de descanso del conductor",
      "Max Vehicle Load Percentage": "Porcentaje máximo de carga del vehículo",
      "Telescopic Deviation Settings":
        "Configuraciones de desviación telescópica",
      "Deviation Type": "Tipo de desviación",
      "Spot Vehicle Usage": "Uso de vehículos spot",
      "Real-time Load Monitoring": "Monitoreo de carga en tiempo real",
      "Driver Allocation Time": "Tiempo de asignación del conductor",
      "Use Traffic Data": "Usar datos de tráfico",
      "Optimize Route": "Optimizar ruta",
      "Optimize Fuel Efficiency": "Optimizar eficiencia de combustible",
      "Daily Vehicle Plan": "Plan diario de vehículos",
      "Transporter Allocation": "Asignación de transportista",
      "Enable Real-time Tracking": "Habilitar seguimiento en tiempo real",
      "Vehicle Rotation Plan": "Plan de rotación de vehículos",
      "Route Optimization Preferences": "Preferencias de optimización de ruta",
      "Consider Weather Conditions": "Considerar condiciones climáticas",
      "Max Loading Time": "Tiempo máximo de carga",
      "Min Load Capacity": "Capacidad mínima de carga",
      "Driver Scheduling": "Programación de conductores",
      "Vehicle Capacity Monitoring": "Monitoreo de capacidad del vehículo",
      "Dynamic Load Balancing": "Equilibrio dinámico de carga",
      "Last Mile Optimization": "Optimización de última milla",
      "Unloading Time Management": "Gestión del tiempo de descarga",
      "Daily Trip Plan": "Plan diario de viaje",
      "Delivery Window Optimization": "Optimización de ventana de entrega",
      "Night Driving Restrictions": "Restricciones de conducción nocturna",
      "Weekend Planning": "Planificación de fin de semana",

      run_new_plan: "Ejecutar nuevo plan",
      planned_distance_miles: "Distancia Planificada (Millas)",
      restriction_hours: "Horas De Restricción",
      transporter_details: "Detalles del transportista",
      save_geofence: "Guardar Geocerca",
      no_data: "Sin Datos",
      run_new_plan: "Ejecutar nuevo plan",
      new_plan_details: "Detalles del nuevo plan",
      order: "Pedido",
      vehicle_configs: "Configuraciones del vehículo",
      order_information: "Información del pedido",
      plan_configs: "Configuraciones del plan",
      vehicle_availability: "Disponibilidad del vehículo",
      vehicle_type: "Tipo de vehículo",
      vehicle_name: "Nombre del vehículo",
      vehicle_min_weight: "Peso mínimo del vehículo (libras)",
      vehicle_max_weight: "Peso máximo del vehículo (libras)",
      vehicle_min_volume: "Volumen mínimo del vehículo (pies cúbicos)",
      vehicle_max_volume: "Volumen máximo del vehículo (pies cúbicos)",
      max_distance_per_trip: "Distancia máxima por viaje (millas)",
      vehicle_priority: "Prioridad del vehículo",
      enable_reconciliation: "Habilitar reconciliación",
      enable_dc_week_off_planning:
        "Habilitar planificación de días libres en DC",
      order_number: "Número de pedido",
      invoicing_date: "Fecha de facturación",
      quantity: "Cantidad",
      demand_cft: "Demanda (pies cúbicos)",
      weight_pounds: "Peso (libras)",
      dealer_available_from: "Concesionario disponible desde",
      dealer_available_to: "Concesionario disponible hasta",
      delivery_date: "Fecha de entrega",
      general_settings: "Configuraciones generales",
      prioritize_local_loads: "Priorizar cargas locales",
      consider_dropping_distant_smaller_loads:
        "Considerar eliminar cargas pequeñas y distantes",
      plan_spot_vehicles: "Planificar vehículos puntuales",
      city_based_entry_restriction:
        "Restricción de entrada basada en la ciudad",
      consider_vehicle_break_times:
        "Considerar tiempos de descanso del vehículo (conducción nocturna)",
      consider_holiday_restrictions: "Considerar restricciones por vacaciones",
      consider_realtime_vehicle_available_times:
        "Considerar tiempos de disponibilidad de vehículos en tiempo real",
      consider_transporter_sob: "Considerar SOB del transportista",
      planning_optimization_settings:
        "Configuraciones de optimización de planificación",
      use_dynamic_planning_window: "Usar ventana de planificación dinámica",
      number_of_days_to_use_planning_window:
        "Número de días para usar la ventana de planificación",
      optimization_type: "Tipo de optimización",
      cost_optimization: "Optimización de costos",
      weight_based_optimization: "Optimización basada en peso",
      distance_based_optimization: "Optimización basada en distancia",
      time_based_optimization: "Optimización basada en tiempo",
      volume_based_optimization: "Optimización basada en volumen",
      vehicle_fill_rate_vfr_settings:
        "Configuraciones de tasa de llenado del vehículo (VFR)",
      use_volume_for_vfr: "Usar volumen para la tasa de llenado del vehículo",
      use_weight_for_vfr: "Usar peso para la tasa de llenado del vehículo",
      min_vfr_per_vehicle_type: "Tasa mínima de llenado por tipo de vehículo",
      min_vfr_per_spot_vehicle: "Tasa mínima de llenado por vehículo puntual",
      moq_for_planning: "MOQ para planificación",
      load_planning_settings: "Configuraciones de planificación de carga",
      number_of_loading_docks: "Número de muelles de carga",
      max_local_no_of_customers_per_vehicle:
        "Número máximo de clientes locales por vehículo",
      max_upcountry_no_of_customers_per_vehicle:
        "Número máximo de clientes fuera de la ciudad por vehículo",
      prioritize_same_dealers_first:
        "Priorizar los mismos concesionarios primero en un vehículo",
      prioritize_multi_city_dealers_first:
        "Priorizar concesionarios de múltiples ciudades primero en un vehículo",
      note_highlighted_rows_red:
        "Las filas resaltadas en rojo no tienen datos GPS o integración, marque manualmente la disponibilidad del vehículo.",
      note_highlighted_rows_green:
        "Las filas resaltadas en verde se consideran para la planificación",
      vehicle_number: "Número del vehículo",
      transit_type: "Tipo de tránsito",
      monthly_minimum_distance: "Distancia mínima mensual",
      remaining_distance: "Distancia restante",
      vehicle_available_date:
        "Fecha de disponibilidad del vehículo (AAAA-MM-DD HH:mm)",
      charlie_hub: "Hub Charlie",
      enable_recommendation: "Habilitar recomendación",
      planned_day: "Día planificado",
      drop_sequence: "Secuencia de entrega",
      drop_location_code_destination:
        "Código de ubicación de entrega (destino)",
      drop_location_name_destination:
        "Nombre de ubicación de entrega (destino)",
      destination_location_city: "Ciudad de destino",
      weight_kgs: "Peso (kgs)",
      volume_m3: "Volumen (m³)",
      distance_to_destination_kms: "Distancia al destino (kms)",
      expected_delivery_date: "Fecha de entrega esperada",
      time_to_destination: "Tiempo hasta el destino",
      transit_time_hh_mm: "Tiempo de tránsito (HH:MM)",
      unloading_time_hh_mm: "Tiempo de descarga (HH:MM)",
      plan_id: "ID del plan",
      generated_on: "Generado el",
      vehicle_availability_from: "Disponibilidad del vehículo desde",
      vehicle_availability_to: "Disponibilidad del vehículo hasta",
      break_time_from: "Hora de descanso desde",
      break_time_to: "Hora de descanso hasta",
      min_volume_m3: "Volumen mínimo (m³)",
      max_volume_m3: "Volumen máximo (m³)",
      vehicle_width_mm: "Ancho del vehículo (mm)",
      vehicle_height_mm: "Altura del vehículo (mm)",
      vehicle_depth_mm: "Profundidad del vehículo (mm)",
      material_code: "Código del material",
      material_name: "Nombre del material",
      material_type: "Tipo de material",
      material_weight: "Peso del material",
      material_dimensions_depth: "Dimensiones del material - Profundidad",
      material_dimensions_width: "Dimensiones del material - Ancho",
      material_dimensions_height: "Dimensiones del material - Altura",
      state: "Estado",
      city: "Ciudad",
      fixed_vehicle_cost: "Costo fijo del vehículo",
      driver_cost: "Costo del conductor",
      maintenance_cost: "Costo de mantenimiento",
      per_cmt_cost: "Costo por pie cúbico",
      per_km_cost: "Costo por km",
      date: "Fecha",
      transporter_name: "Nombre del transportista",
      no_of_vehicles: "Número de vehículos",
      driver_break_time_from: "Hora de descanso del conductor desde",
      vehicle_max_weight_kgs: "Peso máximo del vehículo (kgs)",
      vehicle_min_volume_m3: "Volumen mínimo del vehículo (m³)",
      vehicle_max_volume_m3: "Volumen máximo del vehículo (m³)",
      max_distance_kms: "Distancia máxima (kms)",
      monthly_min_planning_distance_kms:
        "Distancia mínima mensual de planificación (kms)",
      details: "Detalles",
      trip_no: "Número de viaje",
      vehicle_no: "Número del vehículo",
      no_of_orders: "Número de pedidos",
      no_of_customers: "Número de clientes",
      total_quantity: "Cantidad total",
      vehicle_utilization_percent: "Utilización del vehículo (%)",
      total_planned_weight_kgs: "Peso total planificado (kgs)",
      onward_trip_distance_kms: "Distancia del viaje de ida (kms)",
      round_trip_distance_kms: "Distancia del viaje de ida y vuelta (kms)",
      total_planned_volume_m3: "Volumen total planificado (m³)",
      max_dispatch_time_hh_mm: "Tiempo máximo de despacho (HH:MM)",
      arrival_at_first_dealer_start: "Llegada al primer concesionario (inicio)",
      departure_at_last_dealer_end: "Salida del último concesionario (fin)",
      return_to_plant_hh_mm: "Regreso a la planta (HH:MM)",
      total_planned_trip_time_hrs: "Tiempo total del viaje planificado (horas)",
      total_round_trip_time_hrs:
        "Tiempo total del viaje de ida y vuelta (horas)",
      total_transit_time_hh_mm: "Tiempo total de tránsito (HH:MM)",
      total_loading_time_hh_mm: "Tiempo total de carga (HH:MM)",
      total_unloading_time_hh_mm: "Tiempo total de descarga (HH:MM)",
      drop_location_code: "Código de ubicación de entrega",
      drop_location_name: "Nombre de ubicación de entrega",
      drop_location_city: "Ciudad de ubicación de entrega",
      demand_volume_m3: "Volumen de demanda (m³)",
      vehicle_min_weight_kgs: "Peso mínimo del vehículo (kgs)",
      no_of_deliveries: "Número de entregas",
      min_load_per_delivery: "Carga mínima por entrega",
      status: "Estado",
      processing: "Procesando",
      please_contact_admin: "Por favor contacte al administrador",
      solver_complete: "Solución completada",
      solver_reco_processed: "Recomendación procesada",
      solver_sob_done: "SOB completado",
      solver_invalid_input_dealer_location:
        "Ubicación del concesionario inválida",
      solver_invalid_input_materials: "Materiales inválidos",
      solver_invalid_input_dealer_location_and_materials:
        "Ubicación del concesionario y materiales inválidos",
      solver_input_data_not_valid: "Datos de entrada no válidos",
      solver_fix_dropped_dealers: "Corregir concesionarios eliminados",
      solver_vehicle_details_missing: "Detalles del vehículo faltantes",
      solver_input_file_data_error: "Error en los datos del archivo de entrada",
      solver_invalid_file_template: "Plantilla de archivo inválida",
      process_timed_out: "El proceso ha expirado",
      solver_sob_fail: "Fallo en SOB",
      solver_reco_failed: "Fallo en la recomendación",
      solver_no_tool_config: "No hay configuración de herramienta",
      solver_invalid_tool_config: "Configuración de herramienta inválida",
      transit_time_with_multiple_city_clubing:
        "Tiempo de tránsito con agrupación de varias ciudades",
      rdd_preference: "Preferencia de RDD",
      transit_time_with_single_city_clubing:
        "Tiempo de tránsito con agrupación de una sola ciudad",
      no_of_trucks_planned: "Número de camiones planificados",
      view_details: "Ver detalles",
      plan_run_by: "Plan ejecutado por",
      compare_plans: "Comparar planes",
      scenario_name: "Nombre del escenario",
      pick_up_location_source: "Ubicación de recogida (origen)",
      pickup_location_source: "Ubicación de recogida (origen)",
      drop_location_destination: "Ubicación de entrega (destino)",
      demand_m3: "Demanda (m³)",
      location_radius: "Radio de la ubicación (kms)",
      volume_cmt: "Volumen (CMT)",
      obd_details_in_radius: "Detalles de OBD dentro del radio",
      planning_configurations: "Configuraciones de planificación",
      planning_scenarios: "Escenarios de planificación",
      what_if_demand_fluctuation: "¿Qué pasaría si: Fluctuación de la demanda?",
      increase_decrease_demand_by: "Aumentar/disminuir la demanda en",
      what_if_customer_priority_changes:
        "¿Qué pasaría si: Cambios en la prioridad del cliente?",
      prioritize_upcountry: "Priorizar fuera de la ciudad",
      prioritize_local: "Priorizar local",
      what_if_service_level_target_changes:
        "¿Qué pasaría si: Cambios en la meta del nivel de servicio?",
      set_service_level_target_to:
        "Establecer la meta del nivel de servicio en",
      what_if_vehicle_fill_rate_changes:
        "¿Qué pasaría si: Cambios en la tasa de llenado del vehículo?",
      set_vehicle_fill_rate_to: "Establecer la tasa de llenado del vehículo en",
      what_if_customer_specific_delivery_time:
        "¿Qué pasaría si: Tiempo de entrega específico del cliente?",
      increase_decrease_delivery_time_window_by:
        "Aumentar/disminuir la ventana de tiempo de entrega en",
      scenario_description: "Descripción del escenario",
      vehicle_fill_rate_settings:
        "Configuraciones de tasa de llenado del vehículo",
      file_name: "Nombre del archivo",
      total_weight_kgs: "Peso total (kgs)",
      total_demand_volume_m3: "Volumen total de demanda (m³)",
      processing_plan_please_wait: "Procesando plan. Espere por favor...",
      forecast_input_information: "Información de entrada de previsión",
      uploading_data_for_plant: "Subiendo datos para la planta",
      vehicle_type_configuration: "Configuración del tipo de vehículo",
      min_volume_cft: "Volumen mínimo (pies cúbicos)",
      max_volume_cft: "Volumen máximo (pies cúbicos)",
      cost_per_km: "Costo por km",
      note_duplicate_scenario: "Nota: Escenario duplicado",
      select_unique_scenarios_to_compare:
        "Seleccione escenarios únicos para comparar planes",
      scenario_selection_limit_exceeded:
        "Límite de selección de escenarios excedido",
      note_scenario_selection_limit:
        "Nota: Puede seleccionar hasta tres escenarios",
      select_scenario_to_compare:
        "Seleccione un escenario para comparar planes",
      forecast_scenarios: "Escenarios de previsión",
      select_planning_duration: "Seleccionar duración de la planificación",
      notify_email_users_after_process:
        "Notificar a los usuarios por correo electrónico después del proceso (separados por comas)",
      optimize_by_time_details: "Optimizar por detalles de tiempo",
      max_time_limit_hrs: "Límite máximo de tiempo (horas)",
      spot_vehicles_to_be_planned: "Vehículos puntuales para ser planificados",
      auto_select: "Selección automática",
      redirecting_to_vehicle_checklist:
        "Redirigiendo A La Lista De Verificación Del Vehículo",
      planned_day: "Día Planificado",
      drop_sequence: "Secuencia de Entrega",
      drop_location_code_destination:
        "Código de Ubicación de Entrega (Destino)",
      drop_location_name_destination:
        "Nombre de Ubicación de Entrega (Destino)",
      destination_location_city: "Ciudad de Destino",
      dealer_type: "Tipo de Distribuidor",
      customer_type: "Tipo de Cliente",
      week_off: "Día de Descanso",
      order_number: "Número de Pedido",
      invoicing_date: "Fecha de Facturación",
      quantity: "Cantidad",
      weight_pounds: "Peso (Libras)",
      volume_cft: "Volumen (CFT)",
      distance_to_destination_miles: "Distancia al Destino (Millas)",
      expected_delivery_date: "Fecha de Entrega Esperada",
      time_to_destination: "Tiempo al Destino",
      transit_time_hh_mm: "Tiempo de Tránsito (HH:MM)",
      unloading_time_hh_mm: "Tiempo de Descarga (HH:MM)",
      email: "Correo Electrónico",
      details: "Detalles",
      view: "Ver",
      assign_vehicle: "Asignar Vehículo",
      revoke_assignment: "Revocar Asignación",
      edit_plan: "Editar Plan",
      delete: "Eliminar",
      plan_edited: "Plan Editado",
      yes: "Sí",
      no: "No",
      trip_no: "Número de Viaje",
      vehicle_no: "Número de Vehículo",
      vehicle_type: "Tipo de Vehículo",
      cost: "Costo ($)",
      transit_type: "Tipo de Tránsito",
      transporter_name: "Nombre del Transportista",
      no_of_orders: "Número de Pedidos",
      no_of_customers: "Número de Clientes",
      total_quantity: "Cantidad Total",
      vehicle_utilization_percent: "Utilización del Vehículo (%)",
      vehicle_max_weight: "Peso Máximo del Vehículo (Libras)",
      total_planned_weight: "Peso Total Planificado (Libras)",
      onward_trip_distance_miles: "Distancia del Viaje (Millas)",
      round_trip_distance_miles: "Distancia de Ida y Vuelta (Millas)",
      vehicle_max_volume_cft: "Volumen Máximo del Vehículo (CFT)",
      total_planned_volume_cft: "Volumen Total Planificado (CFT)",
      max_dispatch_time: "Tiempo Máximo de Envío (HH:MM)",
      arrival_at_first_dealer: "Llegada al Primer Distribuidor (Inicio)",
      departure_at_last_dealer: "Salida del Último Distribuidor (Fin)",
      return_to_plant: "Regreso a la Planta (HH:MM)",
      total_planned_trip_time: "Tiempo Total del Viaje Planificado (Horas)",
      total_round_trip_time: "Tiempo Total del Viaje Redondo (Horas)",
      total_transit_time: "Tiempo Total de Tránsito (HH:MM)",
      total_loading_time: "Tiempo Total de Carga (HH:MM)",
      total_unloading_time: "Tiempo Total de Descarga (HH:MM)",
      remark: "Comentario",
      "n/a": "No Disponible",
      revoke_again_to_edit: "Revocar de Nuevo para Editar",
      drop_location_code: "Código de Ubicación de Entrega",
      drop_location_name: "Nombre de Ubicación de Entrega",
      drop_location_city: "Ciudad de Ubicación de Entrega",
      distance_from_plant: "Distancia desde la Planta",
      demand_volume_cft: "Volumen Demandado (CFT)",
      delivery_date: "Fecha de Entrega",
      actions: "Acciones",
      pickup_location_source: "Ubicación de Recogida (Origen)",
      dealer_available_from: "Distribuidor Disponible Desde",
      dealer_available_to: "Distribuidor Disponible Hasta",
      loading_time_hh_mm: "Tiempo de Carga (HH:MM)",
      dropped_reason: "Razón de Rechazo",
      dealer_location_data_not_in_system:
        "Datos de Ubicación del Distribuidor No en el Sistema",
      delivery_date_less_than_current_date:
        "Fecha de Entrega Menor que la Fecha Actual",
      kdd_customer: "Cliente KDD",
      insufficient_load_for_planning:
        "Carga Insuficiente para Planificación (<MOQ)",
      warehouse_name: "Nombre del Almacén",
      destination_city: "Ciudad de Destino",
      destination_state: "Estado de Destino",
      sales_order_no: "Número de Orden de Venta",
      no_of_tires: "Número de Neumáticos",
      order_date: "Fecha de Pedido",
      vehicle_name: "Nombre del Vehículo",
      vehicle_priority: "Prioridad del Vehículo",
      vehicle_availability_from: "Disponibilidad del Vehículo (desde)",
      vehicle_availability_to: "Disponibilidad del Vehículo (hasta)",
      break_time_from: "Tiempo de Descanso (desde)",
      break_time_to: "Tiempo de Descanso (hasta)",
      min_volume_cft: "Volumen Mínimo (CFT)",
      max_volume_cft: "Volumen Máximo (CFT)",
      vehicle_dimensions_width_mm: "Ancho del Vehículo (mm)",
      vehicle_dimensions_height_mm: "Altura del Vehículo (mm)",
      vehicle_dimensions_depth_mm: "Profundidad del Vehículo (mm)",
      vehicle_min_weight: "Peso Mínimo del Vehículo (Libras)",
      max_no_of_nodes: "Número Máximo de Entregas",
      min_load_per_delivery: "Carga Mínima por Entrega",
      max_distance: "Distancia Máxima (Millas)",
      material_code: "Código de Material",
      material_name: "Nombre de Material",
      material_type: "Tipo de Material",
      material_weight: "Peso del Material",
      material_dimensions_depth: "Profundidad del Material",
      material_dimensions_width: "Ancho del Material",
      material_dimensions_height: "Altura del Material",
      form_vehicle_types: "Tipos de Vehículo",
      dealer_form_state: "Estado",
      dealer_form_city: "Ciudad",
      freight_fixed_vehicle_cost: "Costo Fijo del Vehículo",
      freight_driver_cost: "Costo del Conductor",
      freight_maintenance_cost: "Costo de Mantenimiento",
      freight_per_cmt_cost: "Costo por CMT",
      freight_per_km_cost: "Costo por KM",
      vehicle_date: "Fecha",
      vehicle_number: "Número de Vehículo",
      plant_code: "Código de Planta",
      plant_name: "Nombre de Planta",
      available_time: "Tiempo Disponible",
      gps_status: "Estado del GPS",
      last_known_address: "Última Dirección Conocida",
      last_gps_time: "Último Tiempo de GPS",
      vehicle_location: "Ubicación del Vehículo",
      assigned_to_ot: "Asignado a OT",
      min_vfr: "Mínimo VFR",
      order_information: "Información del Pedido",
      vehicle_type_configurations: "Configuraciones de Tipo de Vehículo",
      vehicle_routes: "Rutas de Vehículo",
      total_customers: "Total de Clientes",
      planned: "Planificado",
      unplanned: "No planificado",
      total_round_trip_distance_miles:
        "Distancia Total de Ida y Vuelta (Millas)",
      planning: "Planificación",
      vfr: "VFR",
      ontime_dispatch: "Despacho a Tiempo",
      avg_tat: "TAT Promedio",
      view_locations: "Ver Ubicaciones",
      view_routes: "Ver Rutas",
      additional_vehicles: "Vehículos Adicionales",
      regular_vehicles: "Vehículos Regulares",
      enter_vehicle_no: "Ingresar Número de Vehículo",
      vehicle_has_active_trip_shipment_id:
        "El Vehículo tiene un ID de Envío de Viaje Activo",
      do_you_wish_to: "Desea",
      force_close_assign: "Cierre Forzoso y Asignar",
      force_close_reason: "Razón del Cierre Forzoso",
      gps_issue: "Problema de GPS",
      trip_closed: "Viaje Cerrado",
      load_cancelled: "Carga Cancelada",
      new_trip_allocated: "Nuevo Viaje Asignado",
      driver_changed: "Conductor Cambiado",
      are_you_sure_you_want_to_update: "¿Está seguro que desea actualizar?",
      edit_plan_for_same: "Editar Plan para lo Mismo",
      update_vehicle_plan: "Actualizar Plan del Vehículo",
      max_dispatch_time: "Tiempo Máximo de Despacho",
      new_plan: "Nuevo Plan",
      reason_for_plan_change: "Razón del Cambio de Plan",
      enter_the_reason: "Ingresar la Razón",
      reason_for_plan_deletion: "Razón para la Eliminación del Plan",
      are_you_sure_delete_plan: "¿Está seguro que desea eliminar el plan?",
      update_order_details: "Actualizar Detalles del Pedido",
      demand_volume: "Volumen de Demanda",
      dealer_availability_start_time:
        "Hora de Inicio de Disponibilidad del Concesionario",
      dealer_availability_end_time:
        "Hora de Fin de Disponibilidad del Concesionario",
      configurations: "Configuraciones",
      download: "Descargar",
      dealerTimeError:
        "El tiempo de inicio disponible del distribuidor debe ser menor que el tiempo de finalización",
      more: "Más",
      less: "Menos",
      qualityChecks: "Controles de calidad",
      currentMonthPlanDistance: "Distancia del plan del mes actual",
      email: "Correo electrónico",
      viewPlan: "Ver plan",
      blank_rows_columns_not_allowed:
        "No se permiten filas/columnas en blanco.",
      import_existing_vehicle_details:
        "Importar detalles del vehículo existente",
      uploading_data_for_plant: "Subiendo Datos Para la Planta",
      check_and_remove_blank_data:
        "Para verificar y eliminar datos en blanco, use Buscar y seleccionar → Ir a especial → Blancos, y luego elimine las filas resaltadas.",
      add_vehicle_type: "Agregar Tipo de Vehículo",
      vehicle_operational_hours: "Horas Operacionales del Vehículo (HH:MM)",
      data_saved_successfully: "Datos Guardados Exitosamente",
      plant_wise_configurations: "Configuraciones por Planta",
      save_plant_wise_configurations: "Guardar Configuraciones por Planta",
      vehicle_break_window: "Ventana de Descanso del Vehículo (HH:MM)",
      total_vehicles: "Total de Vehículos",
      zone_configurations: "Configuraciones de Zonas",
      pickup_point: "Punto de Recogida",
      actions: "Acciones",
      edit: "Editar",
      add_transporter_details: "+ Añadir Detalles del Transportista",
      add_transporter_details: "+ Añadir Detalles del Transportista",
      cost_per_mile: "Costo Por Milla",
      freight_details: "Detalles del Flete",
      add_zone: "+ Añadir Zona",
      fixed_vehicle_cost_title: "Costo Fijo del Vehículo ($)",
      variable_vehicle_cost_title: "Costo Variable del Vehículo",
      city_wise_cost: "Costo del Vehículo por Ciudad",
      zone_wise_cost: "Costo del Vehículo por Zona",
      vehicle_cost_configurations: "Configuraciones de Costos del Vehículo",
      freight_cost_label: "Costo del Flete",
      validity_to_label: "Válido Hasta",
      validity_from_label: "Válido Desde",
      actions_label: "Acciones",
      edit_button: "Editar",
      delete_button: "Eliminar",
      save_button: "Guardar",
      cancel_button: "Cancelar",
      select_vehicle_type: "Seleccionar Tipo de Vehículo",
      select_transporter_name: "Seleccionar Nombre del Transportista",
      zone: "Zona",
      state: "Estado",
      driver_cost: "Costo del Conductor ($)",
      maintenance_cost: "Costo de Mantenimiento ($)",
      fixed_vehicle_cost_label: "Costo Fijo del Vehículo ($)",
      cost_per_mile: "Costo Por Milla",
      freight_details: "Detalles del Flete",
      add_zone: "+ Añadir Zona",
      fixed_vehicle_cost_title: "Costo Fijo del Vehículo ($)",
      variable_vehicle_cost_title: "Costo Variable del Vehículo",
      city_wise_cost: "Costo del Vehículo por Ciudad",
      zone_wise_cost: "Costo del Vehículo por Zona",
      vehicle_cost_configurations: "Configuraciones de Costos del Vehículo",
      freight_cost_label: "Costo del Flete",
      validity_to_label: "Válido Hasta",
      validity_from_label: "Válido Desde",
      actions_label: "Acciones",
      edit_button: "Editar",
      delete_button: "Eliminar",
      save_button: "Guardar",
      cancel_button: "Cancelar",
      enable_disable_dispatch_planning_module:
        "Habilitar/Deshabilitar el Módulo de Planificación de Despacho",
      inbound: "Entrante",
      suppliers_vendor_locations_pickup:
        "Proveedores / Ubicaciones de Vendedores (Recogida)",
      operational_hours: "Horas Operativas",
      pickup_locations_drop: "Ubicaciones de Recogida (Entrega)",
      plant_wise_vehicle_type_configuration:
        "Configuración del Tipo de Vehículo por Planta",
      max_no_of_vehicle_types: "Número Máximo de Tipos de Vehículos",
      vehicle_load_capacity_min_max_configuration_preferences:
        "Preferencias de Configuración de Capacidad de Carga del Vehículo Mín / Máx",
      vehicle_load_weight_min_max_preferences:
        "Preferencias de Peso de Carga del Vehículo Mín / Máx",
      distance_preferences: "Preferencias de Distancia",
      vehicle_priorities: "Prioridades del Vehículo",
      vehicle_operational_times: "Tiempos Operativos del Vehículo",
      vehicle_availability: "Disponibilidad del Vehículo",
      generic_time_windows: "Ventanas de Tiempo Genéricas",
      individual_vehicle_time_windows:
        "Ventanas de Tiempo por Vehículo Individual",
      availability_by_vehicle_type: "Disponibilidad por Tipo de Vehículo",
      availability_by_individual_vehicle:
        "Disponibilidad por Vehículo Individual",
      monthly_minimum_planning_distance_preferences:
        "Preferencias de Distancia Mínima Mensual de Planificación",
      gps_based_availability: "Disponibilidad Basada en GPS",
      truck_location_visibility: "Visibilidad de la Ubicación del Camión",
      plant_vicinity_circle_radius:
        "Radio del Círculo de Proximidad de la Planta",
      plant_wise_freight_configuration: "Configuración de Flete por Planta",
      plant_wise_transporter_configuration:
        "Configuración de Transportista por Planta",
      transporter_share_of_business:
        "Participación del Transportista en el Negocio",
      route_preferences: "Preferencias de Ruta",
      plant_wise_new_plan_creation: "Creación de Nuevo Plan por Planta",
      plant_wise_plan_summary: "Resumen del Plan por Planta",
      plant_wise_planning_history: "Historial de Planificación por Planta",
      sustainability_integration: "Integración de Sostenibilidad",
      save_inbound_configurations: "Guardar Configuraciones de Entrada",
      save_outbound_configurations: "Guardar Configuraciones de Salida",
      outbound: "Saliente",
      consignees_dealers_locations_drop:
        "Ubicaciones de Consignatarios / Distribuidores (Entrega)",
      select_vehicle_type: "Seleccionar Tipo de Vehículo",
      select_transporter_name: "Seleccionar Nombre del Transportista",
      zone: "Zona",
      state: "Estado",
      driver_cost: "Costo del Conductor ($)",
      maintenance_cost: "Costo de Mantenimiento ($)",
      fixed_vehicle_cost_label: "Costo Fijo del Vehículo ($)",
      delete: "Eliminar",
      state: "Estado",
      city: "Ciudad",
      zone: "Zona",
      delta_warehouse: "Almacén Delta",
      zones: "Zonas",
      zone_mapping: "Asignación de Zonas",
      city_not_mapped_to_state: "Esta ciudad no está asignada a ningún estado",
      actions: "Acciones",
      add_zones: "+ Agregar Zonas",
      transporter_master: "Maestro de Transportistas",
      transporter_configurations: "Configuraciones de Transportistas",
      transporter_operations: "Operaciones de Transportistas",
      transporter_code: "Código de Transportista",
      transporter_name: "Nombre del Transportista",
      actions: "Acciones",
      edit: "Editar",
      delete: "Eliminar",
      zone_configuration: "Configuración de Zonas",
      vehicle_checklist_for_transportation_requirements:
        "Lista de Verificación de Vehículos para Requisitos de Transporte",
      transporters_configuration: "Configuración de Transportistas",
      consolidated_route_plan_screen: "Pantalla de Plan Consolidado de Ruta",
      plant_code: "Código de Planta",
      select_plant_to_check_vehicle_availability:
        "Seleccione la planta para verificar la disponibilidad del vehículo",
      vehicle_availability: "Disponibilidad de Vehículos",
      note_trucks_near_plant_vicinity:
        "Nota: Camiones cerca de la planta a una distancia de ",
      facility_name: "Nombre de la Instalación",
      vehicle_number: "Número de Vehículo",
      transporter_code: "Código del Transportista",
      vehicle_status_dashboard: "Panel de Estado de Vehículos",
      dc: "DC",
      vehicles: "Vehículos",
      total_trucks: "Total de Camiones",
      active: "Activo",
      cost_details: "Detalles de costos",
      fixed_vehicle_cost: "Costo fijo del vehículo",
      variable_vehicle_cost: "Costo variable del vehículo",
      city_wise_vehicle_cost: "Costo del vehículo por ciudad",
      zone_wise_vehicle_cost: "Costo del vehículo por zona",
      pickup_point: "Punto de recogida",
      vehicle_type: "Tipo de vehículo",
      transporter_name: "Nombre del transportista",
      cost_per_mile_below_sob: "Costo por milla (debajo de SOB)",
      cost_per_mile_after_sob: "Costo por milla (después de SOB)",
      validity_from: "Válido desde",
      validity_to: "Válido hasta",
      add_vehicle_cost: "+ Agregar costo del vehículo",
      freight_details: "Detalles de flete",
      select_transporter: "Seleccionar transportista",
      select_zone: "Seleccionar zona",
      select_state: "Seleccionar estado",
      city: "Ciudad",
      cost: "Costo ($)",
      save: "Guardar",
      cancel: "Cancelar",
      actions: "Acciones",
      validity_from_label: "Válido desde",
      validity_to_label: "Válido hasta",
      get_data: "Obtener datos",
      state: "Estado",
      zone: "Zona",
      fixed_vehicle_cost_label: "Costo fijo del vehículo ($)",
      driver_cost: "Costo del conductor ($)",
      maintenance_cost: "Costo de mantenimiento ($)",
      city: "Ciudad",
      zone: "Zona",
      vehicle_cost: "Costo del vehículo",
      freight_cost: "Detalles del flete",
      inactive: "Inactivo",
      inside_dc: "Dentro de DC",
      in_transit_to_customer: "En Tránsito al Cliente",
      near_customer: "Cerca del Cliente",
      return_to_dc: "Retorno a DC",
      empty_outside_dc: "Vacío Fuera de DC",
      zone_configurations: "Configuraciones de Zonas",
      transporters_configurations: "Configuraciones de Transportistas",
      truck_no: "Número de Camión",
      facility_name: "Nombre de la Instalación",
      zones: "Zonas",
      actions: "Acciones",
      edit: "Editar",
      delete: "Eliminar",
      add_zone_mapping: "Agregar Asignación de Zonas",
      current_status: "Estado Actual",
      next_availability: "Próxima Disponibilidad",
      dc_location: "Ubicación de DC",
      add_route_restriction: "Agregar Restricción de Ruta",
      location_code: "Código de Ubicación",
      location_name: "Nombre de Ubicación",
      restricted_area: "Área Restringida",
      vehicle_list: "Lista de Vehículos",
      from_time: "Hora de Inicio",
      to_time: "Hora de Finalización",
      actions: "Acciones",
      edit: "Editar",
      customer_location: "Ubicación del Cliente",
      truck_no: "Número de Camión",
      facility_name: "Nombre de la Instalación",
      current_status: "Estado Actual",
      inside_dc: "Dentro de DC",
      in_transit_to_customer: "En Tránsito al Cliente",
      near_customer: "Cerca del Cliente",
      return_to_dc: "Retorno a DC",
      empty_outside_dc: "Vacío Fuera de DC",
      unplanned: "No Planificado",
      next_availability_date_at_dc: "Próxima Fecha de Disponibilidad en DC",
      transporter_name: "Nombre del Transportista",
      gps_status: "Estado GPS",
      inactive: "Inactivo",
      active: "Activo",
      na: "NA",
      actions: "Acciones",
      date_and_time: "Fecha y Hora",
      security: "Seguridad",
      quality: "Calidad",
      assessed_by: "Evaluado Por",
      quality_conclusion: "Conclusión de Calidad",
      quality_comments: "Comentarios de Calidad",
      quality_approved: "Aprobado por Calidad",
      quality_date_of_assessment: "Fecha de Evaluación de Calidad",
      facility_name: "Nombre de la Instalación",
      vehicle_number: "Número de Vehículo",
      vehicle_type: "Tipo de Vehículo",
      transporter_code: "Código del Transportista",
      transporter_name: "Nombre del Transportista",
      last_packet_time: "Hora del Último Paquete",
      vehicle_checklist_for_transportation:
        "Lista de Verificación de Vehículos para Transporte",
      assessed_by: "Evaluado Por",
      security_conclusion: "Conclusión de Seguridad",
      security_comments: "Comentarios de Seguridad",
      security_approved: "Aprobado por Seguridad",
      security_date_of_assessment: "Fecha de Evaluación de Seguridad",
      view: "Ver",
      location_code: "Código de Ubicación",
      vehicle_no: "Número de Vehículo",
      stage: "Etapa",
      quality_rejected: "Calidad Rechazada",
      requirements_set: "Requisitos Establecidos",
      security_rejected: "Seguridad Rechazada",
      security_accepted: "Seguridad Aceptada",
      assessment_date: "Fecha de Evaluación",
      requirement: "Requisito",
      satisfied: "Satisfecho",
      yes: "Sí",
      no: "No",
      requirement_set_date: "Fecha de Establecimiento del Requisito",
      temperature: "Temperatura",
      vehicle_operational_from: "Operacional del Vehículo (desde)",
      vehicle_operational_to: "Operacional del Vehículo (hasta)",
      break_time_from: "Hora de Descanso (desde)",
      break_time_to: "Hora de Descanso (hasta)",
      transporter_name: "Nombre del Transportista",
      last_packet_time: "Hora del Último Paquete",
      temperature: "Temperatura",
      view_vehicles: "Ver Vehículos",
      transporter_name: "Nombre del Transportista",
      max_distance_per_plan_miles: "Distancia Máxima por Plan (Millas)",
      remaining_monthly_distance_miles: "Distancia Mensual Restante (Millas)",
      vehicle_next_available_date: "Próxima Fecha Disponible del Vehículo",
      owned: "Propio",
      contract: "Contrato",
      high: "Alto",
      medium: "Medio",
      low: "Bajo",
      market: "Mercado",
      vehicles_for_today: "Vehículos para Hoy",
      bulk_upload_master_vehicles: "Subida Masiva de Vehículos Maestros",
      add_master_vehicle: "Agregar Vehículo Maestro",
      transporter_name: "Nombre del Transportista",
      transit_type: "Tipo de Tránsito",
      actions: "Acciones",
      facility_name: "Nombre de la Instalación",
      vehicle_type: "Tipo de Vehículo",
      vehicle_number: "Número de Vehículo",
      vehicle_priority: "Prioridad del Vehículo",
      max_distance_per_plan_miles: "Distancia Máxima por Plan (Millas)",
      max_weight_pounds: "Peso Máximo (Libras)",
      max_volume_cft: "Volumen Máximo (CFT)",
      monthly_min_planning_distance_miles:
        "Distancia Mínima Mensual de Planificación (Millas)",
      remaining_monthly_planning_distance_miles:
        "Distancia Mensual Restante de Planificación (Millas)",
      next_available_date_yyyy_mm_dd_hh_mm:
        "Próxima Fecha Disponible (AAAA-MM-DD HH:mm)",
      vehicle_break_down: "Avería del Vehículo",
      drop_location_code: "Código de ubicación de entrega", // Suggested translation based on others
      arrival_at_first_dealer: "Llegada al primer distribuidor",
      departure_at_last_dealer: "Salida del último distribuidor",
      average_payload: "Carga Promedio (Toneladas)",
      mileage: "Rendimiento (Millas/galón)",
      fuel_type: "Tipo de Combustible",
      temperature_controlled: "Control de Temperatura",
      load_characteristics: "Características de Carga",
      glec_vehicle_type: "Tipo de Vehículo GLEC",
      return_to_plant: "Regreso a la planta",
      summary_dashboard: "Tablero de resumen",
      dispatch_plan_details: "Detalles del plan de despacho",
      invalid_orders_note: "Nota: No se están mostrando pedidos inválidos",
      pickup_point: "Punto de Recogida",
      vehicle_type_configuration: "Configuración del Tipo de Vehículo",
      vehicle_type: "Tipo de Vehículo",
      vehicle_type_name: "Nombre del Tipo de Vehículo",
      min_volume: "Volumen Mínimo (CFT)",
      max_volume: "Volumen Máximo (CFT)",
      vehicle_priority: "Prioridad del Vehículo",
      min_weight: "Peso Mínimo del Vehículo (Libras)",
      max_weight: "Peso Máximo del Vehículo (Libras)",
      max_distance_trip: "Distancia Máxima por Viaje (Millas)",
      monthly_min_planning_distance:
        "Distancia Mínima Mensual de Planificación (Millas)",
      cancel: "Cancelar",
      save: "Guardar",
      facility_name: "Nombre de la Instalación",
      vehicle_no: "Nº de Vehículo",
      address: "Dirección",
      last_packet_time: "Hora del Último Paquete",
      latitude: "Latitud",
      longitude: "Longitud",
      service_provider: "Proveedor de Servicio",
      temperature: "Temperatura",
      on_trip: "En Viaje",
      requirement: "Requisito",
      requirement_type: "Tipo de Requisito",
      security: "Seguridad",
      assessed_by: "Evaluado Por",
      security_conclusion: "Conclusión de Seguridad",
      security_comments: "Comentarios de Seguridad",
      assessment_start_date: "Fecha de Inicio de Evaluación",
      assessment_end_date: "Fecha de Finalización de Evaluación",
      get_data: "Obtener Datos",
      add_requirements: "Agregar Requisitos",
      add_vehicle_checklist: "Agregar Lista de Verificación del Vehículo",
      actions: "Acciones",
      edit: "Editar",
      delete: "Eliminar",
      vehicle_configurations: "Configuraciones de Vehículos",
      vehicle_availability_configurations:
        "Configuraciones de Disponibilidad de Vehículos",
      vehicle_location: "Ubicación del Vehículo",
      vehicle_master: "Maestro del Vehículo",
      vehicle_availability: "Disponibilidad del Vehículo",
      vehicle_details: "Detalles del Vehículo",
      monthly_min_distance: "Distancia Mínima Mensual (Millas)",
      max_weight_per_trip: "Peso Máximo por Viaje (Libras)",
      max_distance_per_trip: "Distancia Máxima por Viaje (Millas)",
      planned_data: "Datos planificados",
      vfr_percentage: "Porcentaje VFR",
      cool_chain_adherence: "Adherencia a la cadena de frío",
      transit_time: "Tiempo de tránsito",
      loading_time: "Tiempo de carga",
      unloading_time: "Tiempo de descarga",
      planned_summary: "Resumen planificado",
      invalid_drop_data: "Datos de entrega no válidos",
      vehicle_utilization_km: "Utilización de vehículos (KM)",
      distance_to_destination: "Distancia al destino",
      expected_delivery_date: "Fecha de entrega esperada",
      trip_no: "Número de viaje",
      total_quantity: "Cantidad total",
      demand_volume_cft: "Volumen de demanda (CFT)",
      weight_pounds: "Peso (libras)",
      quantity: "Cantidad",
      dropped_reason: "Razón del descarte",
      delivery_date: "Fecha de entrega",
      drop_location_city: "Ciudad de ubicación de entrega",
      planned_day: "Día planeado",
      vehicle_no: "Número de vehículo",
      drop_sequence: "Secuencia de entrega",
      invoicing_date: "Fecha de facturación",
      pickup_location_source: "Ubicación de recogida (fuente)",
      destination_location_city: "Ciudad del destino",
      destination_location_state: "Estado del destino",
      distance_to_destination_miles: "Distancia al destino (millas)",
      time_to_destination: "Tiempo al destino",
      dealer_available_from: "Distribuidor disponible desde",
      dealer_available_to: "Distribuidor disponible hasta",
      total_planned_weight: "Peso total planificado",
      total_planned_volume: "Volumen total planificado",
      arrival_at_first_dealer_start: "Llegada al primer distribuidor (inicio)",
      departure_at_last_dealer_end: "Salida del último distribuidor (fin)",
      return_to_plant_hh_mm: "Regreso a la planta (HH:MM)",
      total_planned_trip_time_hrs: "Tiempo total de viaje planificado (Hrs)",
      total_round_trip_time_hrs: "Tiempo total de ida y vuelta (Hrs)",
      total_loading_time_hh_mm: "Tiempo total de carga (HH:MM)",
      total_unloading_time_hh_mm: "Tiempo total de descarga (HH:MM)",
      vehicle_utilization_percentage: "Utilización del vehículo (%)",
      view_order_information: "Ver información del pedido",
      vehicle_configuration: "Configuración del vehículo",
      summary: "Resumen",
      planned_summary: "Resumen planificado",
      planned_vehicles: "Vehículos planificados",
      unplanned_loads: "Cargas no planificadas",
      invalid_drop_data: "Datos de entrega no válidos",
      set_vehicle_priority: "Establecer prioridad del vehículo",
      forecast_order_plan: "Plan de Pedido de Pronóstico",
      volume_cft: "Volumen (CFT)",
      dealer_available_time_error:
        "La hora de inicio debe ser menor que la hora de finalización",
      ok: "Ok",
      get_data: "Obtener datos",
      plan_history: "Historial del plan",
      upload_file: "Subir archivo",
      save: "Guardar",
      cancel: "Cancelar",
      delete: "Eliminar",
      plant_name: "Nombre de la Planta",
      total_trips: "Total de Viajes",
      trips_edited: "Viajes Editados",
      vfr_percentage: "Porcentaje VFR",
      total_transit_time_hh_mm: "Tiempo total de tránsito (HH)",
      drop_location_name_destination:
        "Nombre de la ubicación de entrega (Destino)",
      unloading_time_hh_mm: "Tiempo de descarga (HH:MM)",
      edited_transaction_ids: "IDs de Transacciones Editadas",
      vehicle_type: "Tipo de vehículo",
      vehicle_name: "Nombre del vehículo",
      weight_pounds: "Peso (Libras)",
      plant_code: "Código de planta",
      plant_name: "Nombre de planta",
      total_trips: "Total de viajes",
      trips_edited: "Viajes editados",
      last_30_days_trips_modification_details:
        "Detalles de modificación de viajes de los últimos 30 días",
      last_7_days_trips_modification_details:
        "Detalles de modificación de viajes de los últimos 7 días",
      planning_progress_past_7_weeks:
        "Progreso de planificación en las últimas 7 semanas",
      edited_transaction_ids: "IDs de transacciones editadas",
      trips_removed: "Viajes eliminados",
      removed_transaction_ids: "IDs de transacciones eliminadas",
      drop_location_city: "Ciudad de ubicación de entrega",
      drop_location_name_destination:
        "Nombre de ubicación de entrega (Destino)",
      note_blank_rows_columns_not_allowed:
        "Nota: No se permiten filas/columnas en blanco",
      check_blank_data_instructions:
        "Para verificar datos en blanco: 1. Haga clic en Buscar y Seleccionar. 2. Haga clic en Ir a Especial. 3. Elija Blancos. 4. Haga clic en Aceptar y se resaltarán todas las filas/celdas en blanco. 5. Elija Eliminar en la sección Celdas en la pestaña Inicio. 6. Haga clic en Eliminar Filas de la Hoja.",
      disclaimer_dealer_window_ot_customers:
        "Descargo de responsabilidad: La ventana disponible para clientes OT se considerará desde el archivo de entrada, los horarios de los clientes GT se considerarán desde las ubicaciones de recogida/entrega",
      quantity: "Cantidad",
      specifications: "Especificaciones",
      please_select_plan_id_to_compare_plans:
        "Por favor seleccione el ID del plan para comparar planes",
      vehicle_forecast_summary_dashboard:
        "Tablero de resumen de pronóstico de vehículos",
      pickup_point: "Punto de recogida",
      view_details: "Ver detalles",
      compare_plans: "Comparar planes",
      pickup_drop_points: "Puntos de recogida/entrega",
      edit: "Editar",
      pickup: "RECOGER",
      drop: "ENTREGAR",
      both: "AMBOS",
      pickup_location_code: "Código de ubicación de recogida",
      dealer_type: "Tipo de distribuidor",
      select: "Seleccionar",
      local: "Local",
      upcountry: "Interior",
      vehicle_restriction_list: "Lista de restricciones de vehículos",
      week_off: "Día libre de la semana",
      csp_day: "Día CSP",
      is_customer_kdd: "¿Es el cliente KDD?",
      start_month: "Mes de inicio",
      end_month: "Mes de finalización",
      plan_id: "ID del plan",
      fecha_creada: "Fecha creada",
      no_of_trucks_planned: "Número de camiones planificados",
      total_planned_volume_cft: "Volumen total planificado (CFT)",
      total_planned_weight_pounds: "Peso total planificado (Libras)",
      plan_run_by: "Plan ejecutado por",
      planned_vehicles: "Vehículos planificados",
      trip_no: "Número de viaje",
      total_planned_customers: "Total de clientes planificados",
      total_round_trip_distance: "Distancia total de ida y vuelta",
      planning_percentage: "Porcentaje de planificación",
      max_dispatch_time: "Tiempo máximo de despacho (HH:MM)",
      total_loading_time: "Tiempo total de carga (HH:MM)",
      total_unloading_time: "Tiempo total de descarga (HH:MM)",
      planned_summary: "Resumen planificado",
      invalid_orders_not_shown: "Nota: No se muestran pedidos no válidos",
      no_of_customers: "Número de clientes",
      no_of_orders: "Número de pedidos",
      total_demand_volume: "Volumen total de demanda (CFT)",
      planned_summary: "Resumen planificado",
      trips_removed: "Viajes Eliminados",
      removed_transaction_ids: "IDs de Transacciones Eliminadas",
      vehicle_forecast_configurations:
        "Configuraciones de Pronóstico de Vehículos",
      pickup_point: "Punto de Recogida",
      vehicle_type: "Tipo de Vehículo",
      vehicle_name: "Nombre del Vehículo",
      cost_per_miles: "Costo por Milla",
      driver_break_time: "Tiempo de Descanso del Conductor",
      view: "Ver",
      please_select_no_of_vehicles:
        "Por favor seleccione el número de vehículos",
      no_of_vehicles: "Número de vehículos",
      obd_analysis: "Análisis OBD",
      pickup_point: "Punto de recogida",
      select_month: "Seleccionar mes",
      location_radius_miles: "Radio de ubicación (millas)",
      submit: "Enviar",
      total_obd_details: "Detalles totales de OBD",
      valid_dealers: "Distribuidores válidos",
      invalid_dealers: "Distribuidores inválidos",
      volume: "Volumen",
      help: "Ayuda",
      change_password: "Cambiar contraseña",
      logout: "Cerrar sesión",
      weight_pounds: "Peso (libras)",
      quantity: "Cantidad",
      obd_details_within_radius: "Detalles de OBD dentro del radio",
      dealers: "Distribuidores",
      dealers_percent: "Porcentaje de distribuidores",
      volume_percent: "Porcentaje de volumen",
      weight_pounds_percent: "Porcentaje de peso (libras)",
      quantity_percent: "Porcentaje de cantidad",
      cost_per_miles: "Costo por millas ($)",
      driver_break_time_from: "Tiempo de descanso del conductor (desde)",
      monthly_min_planning_distance_miles:
        "Distancia mínima de planificación mensual (millas)",
      vehicle_type_count: "Cantidad de tipos de vehículos",
      driver_break_time_per_day:
        "Tiempo de descanso del conductor por día (HH:MM)",
      cost_per_miles_2: "Costo por millas ($)",
      set_vehicle_priority: "Establecer prioridad del vehículo",
      vehicle_type_name: "Nombre del tipo de vehículo",
      min_volume_cft: "Volumen mínimo (CFT)",
      max_volume_cft: "Volumen máximo (CFT)",
      max_distance_per_trip_miles: "Distancia máxima por viaje (millas)",
      select_plan_id: "Seleccionar ID del plan",
      drop_details: "Detalles de la entrega",
      drop_location_code: "Código de ubicación de entrega",
      vehicle_utilization: "Utilización del vehículo",
      onward_trip_distance_miles: "Distancia del viaje de ida (Millas)",
      round_trip_miles: "Viaje redondo (Millas)",
      max_dispatch_time_hh_mm: "Tiempo máximo de despacho (HH:MM)",
      arrival_at_first_dealer_start: "Llegada al primer distribuidor (Inicio)",
      departure_at_last_dealer_end: "Salida del último distribuidor (Fin)",
      return_to_plant_hh_mm: "Regreso a la planta (HH:MM)",
      vehicle_number: "Número de vehículo",
      vehicle_availability_from: "Disponibilidad del vehículo (desde)",
      vehicle_availability_to: "Disponibilidad del vehículo (hasta)",
      break_time_from: "Tiempo de descanso (desde)",
      break_time_to: "Tiempo de descanso (hasta)",
      no_of_vehicles: "Número de vehículos",
      driver_break_time_from: "Tiempo de descanso del conductor (desde)",
      max_distance_miles: "Distancia máxima (millas)",
      monthly_min_planning_distance_miles:
        "Distancia mínima de planificación mensual (millas)",
      total_planned_trip_time_hrs: "Tiempo total de viaje planificado (Horas)",
      total_round_trip_time_hrs: "Tiempo total de ida y vuelta (Horas)",
      drop_location_code: "Código de ubicación de entrega",
      drop_location_name: "Nombre de ubicación de entrega",
      drop_location_city: "Ciudad de ubicación de entrega",
      order_number: "Número de orden",
      delivery_date: "Fecha de entrega (M/D/AAAA)",
      back: "Atrás",
      drop_location_name: "Nombre de ubicación de entrega",
      pickup_location: "Ubicación de recogida",
      dealer_available_from: "Distribuidor disponible desde",
      dealer_available_to: "Distribuidor disponible hasta",
      total_planned_weight: "Peso total planificado",
      total_planned_volume: "Volumen total planificado",
      arrival_at_first_dealer: "Llegada al primer distribuidor",
      departure_at_last_dealer: "Salida del último distribuidor",
      return_to_plant: "Regreso a la planta",
      summary_dashboard: "Tablero de resumen",
      dispatch_plan_details: "Detalles del plan de despacho",
      invalid_orders_note: "Nota: No se están mostrando pedidos inválidos",
      planned_data: "Datos planificados",
      vfr_percentage: "Porcentaje VFR",
      cool_chain_adherence: "Adherencia a la cadena de frío",
      transit_time: "Tiempo de tránsito",
      loading_time: "Tiempo de carga",
      unloading_time: "Tiempo de descarga",
      planned_summary: "Resumen planificado",
      invalid_drop_data: "Datos de entrega no válidos",
      vehicle_utilization_km: "Utilización de vehículos (KM)",
      distance_to_destination: "Distancia al destino",
      expected_delivery_date: "Fecha de entrega esperada",
      planned_vehicles: "Vehículos planificados",
      trip_no: "Número de viaje",
      total_quantity: "Cantidad total",
      dealer_available_to: "Distribuidor disponible hasta",
      drop_location_code_destination:
        "Código de ubicación de entrega (destino)",
      order_number: "Número de orden",
      demand_volume_cft: "Volumen de demanda (CFT)",
      weight_pounds: "Peso (libras)",
      quantity: "Cantidad",
      dropped_reason: "Razón del descarte",
      delivery_date: "Fecha de entrega",
      drop_location_name: "Nombre del lugar de entrega",
      drop_location_city: "Ciudad de ubicación de entrega",
      trip_no: "Número de viaje",
      planned_day: "Día planeado",
      vehicle_no: "Número de vehículo",
      vehicle_type: "Tipo de vehículo",
      drop_sequence: "Secuencia de entrega",
      invoicing_date: "Fecha de facturación",
      pickup_location_source: "Ubicación de recogida (fuente)",
      destination_location_city: "Ciudad del destino",
      destination_location_state: "Estado del destino",
      distance_to_destination_miles: "Distancia al destino (millas)",
      expected_delivery_date: "Fecha de entrega esperada",
      time_to_destination: "Tiempo al destino",
      transit_time_hh_mm: "Tiempo de tránsito (HH:MM)",
      loading_time_hh_mm: "Tiempo de carga (HH:MM)",
      unloading_time_hh_mm: "Tiempo de descarga (HH:MM)",
      total_planned_customers: "Total de clientes planificados",
      total_round_trip_distance_km: "Distancia total de ida y vuelta (KM)",
      planning_percentage: "Porcentaje de planificación",
      cool_chain_adherence_percentage:
        "Porcentaje de adherencia a la cadena de frío",
      vfr_percentage: "Porcentaje de VFR",
      total_transit_time_hh_mm: "Tiempo total de tránsito (HH:MM)",
      round_trip_miles: "Viaje redondo (millas)",
      total_planned_weight_pounds: "Peso total planificado (libras)",
      total_planned_volume_cft: "Volumen total planificado (CFT)",
      arrival_at_first_dealer_start: "Llegada al primer distribuidor (inicio)",
      departure_at_last_dealer_end: "Salida del último distribuidor (fin)",
      return_to_plant_hh_mm: "Regreso a la planta (HH:MM)",
      total_planned_trip_time_hrs: "Tiempo total de viaje planificado (Hrs)",
      total_round_trip_time_hrs: "Tiempo total de ida y vuelta (Hrs)",
      total_loading_time_hh_mm: "Tiempo total de carga (HH:MM)",
      total_unloading_time_hh_mm: "Tiempo total de descarga (HH:MM)",
      vehicle_utilization_percentage: "Utilización del vehículo (%)",
      view_order_information: "Ver información del pedido",
      vehicle_configuration: "Configuración del vehículo",
      summary: "Resumen",
      planned_summary: "Resumen planificado",
      planned_vehicles: "Vehículos planificados",
      unplanned_loads: "Cargas no planificadas",
      invalid_drop_data: "Datos de entrega inválidos",
      vehicle_utilization_km: "Utilización del vehículo (KM)",
      planned_data: "Datos planificados",
      invalid_orders_note: "Nota: No se están mostrando pedidos inválidos",
      set_vehicle_priority: "Establecer prioridad del vehículo",
      vehicle_min_weight_pounds: "Peso Mínimo del Vehículo (Libras)",
      vehicle_max_weight_pounds: "Peso Máximo del Vehículo (Libras)",
      vehicle_min_volume_cft: "Volumen Mínimo del Vehículo (CFT)",
      vehicle_max_volume_cft: "Volumen Máximo del Vehículo (CFT)",
      max_distance_miles: "Distancia Máxima (Millas)",
      vehicle_priority: "Prioridad del Vehículo",
      actions: "Acciones",
      forecast_order_plan: "Plan de Pedido de Pronóstico",
      drop_details: "Detalles de Entrega",
      month: "Mes",
      year: "Año",
      total_quantity: "Cantidad Total",
      total_weight_pounds: "Peso Total (Libras)",
      total_demand_volume_cft: "Volumen Total Demandado (CFT)",
      user: "Usuario",
      uploaded_date: "Fecha de Carga",
      view_order_details: "Ver Detalles del Pedido",
      pick_up_location_code: "Código de Ubicación de Recogida",
      quantity: "Cantidad",
      drop_location: "Ubicación de Entrega",
      drop_location_name: "Nombre de la Ubicación de Entrega",
      weight: "Peso",
      volume: "Volumen",
      delivery_date: "Fecha de Entrega",
      invoice_date: "Fecha de Factura",
      order_no: "Número de Pedido",
      available_start_time: "Hora de Inicio Disponible",
      available_end_time: "Hora de Fin Disponible",
      upload_input_file: "Subir Archivo de Entrada",
      download_sample_template: "Descargar Plantilla de Muestra",
      select_month: "Seleccionar Mes",
      upload_file: "Subir Archivo",
      save: "Guardar",
      cancel: "Cancelar",
      note_blank_rows_columns_not_allowed:
        "Nota: No se permiten filas/columnas en blanco",
      no_of_vehicles: "Número de Vehículos",
      run_plan: "Ejecutar Plan",
      vehicle_types: "Tipos de Vehículos",
      upload_file: "Subir Archivo",
      pickup_point: "Punto de recogida",
      consolidated_route_plan_screen: "Pantalla de Plan de Ruta Consolidada",
      start_date: "Fecha de Inicio",
      end_date: "Fecha de Fin",
      get_data: "Obtener Datos",
      recent_plans: "Planes Recientes",
      plan_history: "Historial del Plan",
      view_input: "Ver Entrada",
      consolidation_id: "ID de Consolidación",
      created_date: "Fecha de Creación",
      run_by: "Ejecutado Por",
      no_of_executions: "Número de Ejecuciones",
      execution_status: "Estado de Ejecución",
      download_output: "Descargar Resultado",
      upload_input_file: "Subir Archivo de Entrada",
      choose_file: "Elegir Archivo",
      discard_obd: "Descartar OBD",
      upload_obd_details: "Subir Detalles OBD",
      upload_file: "Subir Archivo",
      submit: "Enviar",
      download_template: "Descargar Plantilla",
      bulk_upload_locations: "Cargar Localizaciones Masivamente",
      add_pickup_drop_points: "+Añadir Puntos de Recogida/Entrega",
      details: "Detalles",
      date: "Fecha",
      total_trips: "Total de Viajes",
      trips_edited: "Viajes Editados",
      trips_removed: "Viajes Eliminados",
      trips_edit_percentage: "Porcentaje de Viajes Editados",
      plans_overview_dashboard: "Panel de Vista General de Planes",
      pickup_point: "Punto de Recogida",
      location_type: "Tipo de Ubicación",
      location_code: "Código de Ubicación",
      location_name: "Nombre de Ubicación",
      location_city: "Ciudad de Ubicación",
      zone: "Zona",
      customer_type: "Tipo de Cliente",
      location_latitude: "Latitud de Ubicación",
      location_longitude: "Longitud de Ubicación",
      standard_transit_time: "Tiempo de Tránsito Estándar (Días)",
      distance_from_plant: "Distancia Desde la Planta (Millas)",
      delta_warehouse: "Almacén Delta",
      home: "Inicio",
      run_new_plan: "Ejecutar nuevo plan",
      stacking: "Apilamiento",
      production_planning: "Planificación de producción",
      forecasting: "Pronóstico",
      resource_scheduling: "Programación de recursos",
      general: "General",
      pickup_drop_locations: "Puntos de recogida/entrega",
      vehicle_configurations: "Configuraciones de vehículos",
      vehicle_availability: "Disponibilidad de vehículos",
      zone_mapping: "Mapeo de zonas",
      transporters: "Transportistas",
      material_configurations: "Configuraciones de materiales",
      freight_configurations: "Configuraciones de flete",
      route_configurations: "Configuraciones de ruta",
      auto_clustering: "Agrupación automática",
      transporter_share_of_business: "Parte del negocio del transportista",
      dispatch_plan_summary: "Resumen del plan de despacho",
      kpi_dashboard: "Panel de control KPI",
      planning_tools: "Herramientas de planificación",
      forecast_tools: "Herramientas de pronóstico",
      suppliers: "Proveedores",
      customers: "Clientes",
      materials: "Materiales",
      input_data: "Datos de entrada",
      forecast: "Pronóstico",
      previous_plan: "Plan anterior",
      trips: "Viajes",
      gps_quality: "Calidad del GPS",
      truck_gps_data: "Datos GPS del camión",
      unscheduled_stoppages: "Paradas no programadas",
      deviation_reports: "Informes de desviación",
      fo_data: "Datos FO",
      vehicle_checklist: "Lista de verificación del vehículo",
      vehicle_status: "Estado del vehículo",
      route_restrictions: "Restricciones de ruta",
      consolidated_run_plan: "Plan de carrera consolidado",
      plans_overview: "Resumen de planes",
      input_plan_summary: "Resumen del plan de entrada",
      load_plan_summary: "Resumen del plan de carga",
      plant_wise_configurations: "Configuraciones por planta",
      vehicle_configuration: "Configuración del vehículo",
      monthly_data: "Datos mensuales",
      forecast_plan_history: "Historial del plan de pronóstico",
      obd_analysis: "Análisis de OBD",
      actions: "Acciones",
      pickup_location_source: "Ubicación de recogida (fuente)",
      drop_location_destination: "Ubicación de entrega (destino)",
      order_number: "Número de orden",
      invoicing_date: "Fecha de facturación (M/D/AAAA)",
      quantity: "Cantidad",
      dealer_available_time_from:
        "Hora disponible del distribuidor (desde 00:00)",
      dealer_available_time_to:
        "Hora disponible del distribuidor (hasta 23:59)",
      volume_cft: "Volumen (CFT)",
      weight_pounds: "Peso (libras)",
      delivery_date: "Fecha de entrega (M/D/AAAA)",
      no_rows_to_show: "No hay filas para mostrar",
      rows: "Filas",
      total_rows: "Total de filas",
      add_dispatch: "Agregar despacho",
      run_new_plan_by_file: "Ejecutar nuevo plan por archivo",
      submit_run_plan: "Enviar y ejecutar plan",
      delete: "Eliminar",
      upload_input_file: "Subir archivo de entrada",
      upload_file: "Subir archivo",
      download_sample_template: "Descargar plantilla de muestra",
      save: "GUARDAR",
      cancel: "CANCELAR",
      dealer_available_time_error:
        "La hora de inicio debe ser menor que la hora de finalización",
      ok: "Ok",
      summary_dashboard: "Tablero de resumen",
      pickup_point: "Punto de recogida",
      date_created: "Fecha creada",
      get_data: "Obtener datos",
      plan_details: "Detalles del plan",
      plan_history: "Historial del plan",
      drop_details: "Detalles de entrega",
      generate_plan: "Generar plan",
      order_id: "Identificación del pedido",
      no_of_runs: "Número de ejecuciones",
      created_on: "Creado el",
      plan_run_by: "Plan ejecutado por",
      discard_obd: "Descartar OBD",
      upload_obd_details: "Subir detalles de OBD",
      download_template: "Descargar plantilla",
      choose_file: "Elegir archivo",
      submit: "Enviar",
      start_date: "Fecha de inicio",
      end_date: "Fecha de fin",
      get_data: "Obtener datos",
      upload_input_file: "Subir archivo de entrada",
      discard_obd: "Descartar OBD",
      recent_plans: "Planes recientes",
      plan_history: "Historial del plan",
      view_input: "Ver entrada",
      consolidation_id: "ID de consolidación",
      created_date: "Fecha de creación",
      run_by: "Ejecutado por",
      no_of_executions: "Número de ejecuciones",
      execution_status: "Estado de la ejecución",
      download_output: "Descargar salida",
      upload_obd_details: "Subir detalles de OBD",
      download_template: "Descargar plantilla",
      choose_file: "Elegir archivo",
      submit: "Enviar",
      simulation_time: "Hora de simulación",
      enable_dc_week_off_planning:
        "Habilitar planificación de semana libre de DC",
      run_plan: "Ejecutar plan",
      cancel: "Cancelar",
      plan_details: "Detalles del plan",
      pickup_point: "Punto de recogida",
      date_created: "Fecha creada",
      order_id: "ID de pedido",
      no_of_runs: "Número de ejecuciones",
      fix_and_reupload: "Reparar y volver a cargar",
      club_previous_order: "Agrupar pedido anterior",
      ignore: "Ignorar",
      dealer_code: "Código del concesionario",
      remarks: "Observaciones",
      run_new_plan: "Ejecutar nuevo plan",
      consolidated_route_plan_screen: "Pantalla de Plan Consolidado de Ruta",
      plant_code: "Código de Planta",
      plant_name: "Nombre de la Planta",
      total_trips: "Total de Viajes",
      trips_edited: "Viajes Editados",
      edited_transaction_ids: "IDs de Transacciones Editadas",
      vehicle_type: "Tipo de vehículo",
      vehicle_name: "Nombre del vehículo",
      weight_pounds: "Peso (Libras)",
      drop_location_city: "Ciudad de ubicación de entrega",
      quantity: "Cantidad",
      planned_vehicles: "Vehículos planificados",
      trip_no: "Número de viaje",
      total_planned_customers: "Total de clientes planificados",
      total_round_trip_distance: "Distancia total de ida y vuelta",
      planning_percentage: "Porcentaje de planificación",
      max_dispatch_time: "Tiempo máximo de despacho (HH:MM)",
      total_loading_time: "Tiempo total de carga (HH:MM)",
      total_unloading_time: "Tiempo total de descarga (HH:MM)",
      planned_summary: "Resumen planificado",
      invalid_orders_not_shown: "Nota: No se muestran pedidos no válidos",
      no_of_customers: "Número de clientes",
      no_of_orders: "Número de pedidos",
      total_demand_volume: "Volumen total de demanda (CFT)",
      planned_summary: "Resumen planificado",
      trips_removed: "Viajes Eliminados",
      removed_transaction_ids: "IDs de Transacciones Eliminadas",
      vehicle_forecast_configurations:
        "Configuraciones de Pronóstico de Vehículos",
      pickup_point: "Punto de Recogida",
      vehicle_type: "Tipo de Vehículo",
      vehicle_name: "Nombre del Vehículo",
      cost_per_miles: "Costo por Milla",
      driver_break_time: "Tiempo de Descanso del Conductor",
      view: "Ver",
      please_select_no_of_vehicles:
        "Por favor seleccione el número de vehículos",
      no_of_vehicles: "Número de vehículos",
      cost_per_miles: "Costo por millas ($)",
      driver_break_time_from: "Tiempo de descanso del conductor (desde)",
      monthly_min_planning_distance_miles:
        "Distancia mínima de planificación mensual (millas)",
      vehicle_type_count: "Cantidad de tipos de vehículos",
      driver_break_time_per_day:
        "Tiempo de descanso del conductor por día (HH:MM)",
      cost_per_miles_2: "Costo por millas ($)",
      set_vehicle_priority: "Establecer prioridad del vehículo",
      vehicle_type_name: "Nombre del tipo de vehículo",
      min_volume_cft: "Volumen mínimo (CFT)",
      max_volume_cft: "Volumen máximo (CFT)",
      max_distance_per_trip_miles: "Distancia máxima por viaje (millas)",
      select_plan_id: "Seleccionar ID del plan",
      drop_details: "Detalles de la entrega",
      drop_location_code: "Código de ubicación de entrega",
      drop_location_name: "Nombre de ubicación de entrega",
      pickup_location: "Ubicación de recogida",
      dealer_available_from: "Distribuidor disponible desde",
      dealer_available_to: "Distribuidor disponible hasta",
      total_planned_weight: "Peso total planificado",
      total_planned_volume: "Volumen total planificado",
      arrival_at_first_dealer: "Llegada al primer distribuidor",
      departure_at_last_dealer: "Salida del último distribuidor",
      return_to_plant: "Regreso a la planta",
      summary_dashboard: "Tablero de resumen",
      dispatch_plan_details: "Detalles del plan de despacho",
      invalid_orders_note: "Nota: No se están mostrando pedidos inválidos",
      planned_data: "Datos planificados",
      vfr_percentage: "Porcentaje VFR",
      cool_chain_adherence: "Adherencia a la cadena de frío",
      transit_time: "Tiempo de tránsito",
      loading_time: "Tiempo de carga",
      unloading_time: "Tiempo de descarga",
      planned_summary: "Resumen planificado",
      invalid_drop_data: "Datos de entrega no válidos",
      vehicle_utilization_km: "Utilización de vehículos (KM)",
      distance_to_destination: "Distancia al destino",
      expected_delivery_date: "Fecha de entrega esperada",
      planned_vehicles: "Vehículos planificados",
      trip_no: "Número de viaje",
      total_quantity: "Cantidad total",
      dealer_available_to: "Distribuidor disponible hasta",
      drop_location_code_destination:
        "Código de ubicación de entrega (destino)",
      order_number: "Número de orden",
      demand_volume_cft: "Volumen de demanda (CFT)",
      weight_pounds: "Peso (libras)",
      quantity: "Cantidad",
      dropped_reason: "Razón del descarte",
      delivery_date: "Fecha de entrega",
      drop_location_name: "Nombre del lugar de entrega",
      drop_location_city: "Ciudad de ubicación de entrega",
      trip_no: "Número de viaje",
      planned_day: "Día planeado",
      vehicle_no: "Número de vehículo",
      vehicle_type: "Tipo de vehículo",
      drop_sequence: "Secuencia de entrega",
      invoicing_date: "Fecha de facturación",
      pickup_location_source: "Ubicación de recogida (fuente)",
      destination_location_city: "Ciudad del destino",
      destination_location_state: "Estado del destino",
      distance_to_destination_miles: "Distancia al destino (millas)",
      expected_delivery_date: "Fecha de entrega esperada",
      time_to_destination: "Tiempo al destino",
      invalid_material: "Material inválido",
      drop_location_code_destination:
        "Código de ubicación de entrega (destino)",
      demand_volume_cft: "Volumen de demanda (CFT)",
      dropped_reason: "Razón del descarte",
      dealer_location_data_not_in_system:
        "Datos de ubicación del distribuidor no están en el sistema",
      delivery_date_less_than_current:
        "La fecha de entrega es menor que la fecha actual",
      missing_dealer_information: "Información del distribuidor faltante",
      transit_time_hh_mm: "Tiempo de tránsito (HH:MM)",
      loading_time_hh_mm: "Tiempo de carga (HH:MM)",
      unloading_time_hh_mm: "Tiempo de descarga (HH:MM)",
      actions: "Acciones",
      expected_delivery_date: "Fecha de entrega esperada",
      max_dispatch_time: "Tiempo máximo de despacho (HH:MM)",
      unplanned_loads: "Cargas no planificadas",
      monthly_data: "Datos mensuales",
      forecast_plan_history: "Historial del plan de pronóstico",
      obd_analysis: "Análisis de OBD",
      dealer_available_time_error:
        "La hora de inicio debe ser menor que la hora de finalización",
    },
  },
  ja: {
    translation: {
      vehicles_recommended: "推奨される車両",
      "route_type": "ルートタイプ",
      "Number Of Trips": "旅行の数",
      "Day-2": "2日目",
  "Day-3": "3日目",
  "Day-4": "4日目",
  "Day-5": "5日目",
  "Day-6": "6日目",
  "Distance / Time From Pickup Location": "ピックアップ場所からの距離 / 時間",
  "Distance / Time From Previous Drop Point": "以前のドロップポイントからの距離 / 時間",
  "Location City": "位置都市",
  "Planned Day": "計画された日",
  "Quantity": "数量",
  "Demand Weight": "需要重量",
  "Demand Volume": "需要量",
  "Location": "場所",
  "Weight": "重さ",
  "View Onward Route": "行きのルートを見る",
  "View Return Route": "帰りのルートを見る",
      "Quantity": "数量",
  "Weight": "重量",
  "Spot": "スポット",
  "Local": "ローカル",
  "Upcountry": "地方",
  "Volume": "体積",
  "Total": "合計",
  "IN416": "IN416",
  "IN436": "IN436",
  "Spot": "スポット",
  "2,177": "2,177",
  "1,268": "1,268",
  "909": "909",
  "0": "0",
  "Planned Truck Load": "計画されたトラック積載量",
  "Quantity (cases)": "数量 (ケース)",
  "Weight (Kgs)": "重量 (キログラム)",
  "Volume (CMT)": "体積 (CMT)",
  "1,81,970": "1,81,970",
  "25,25,554": "25,25,554",
  "8,249": "8,249",
  "Unplanned Load": "計画外の荷物",
  "26,324": "26,324",
  "3,74,549": "3,74,549",
  "1,234": "1,234",
  "Average Distance Travelled Per Vehicle (KM)": "車両ごとの平均走行距離 (KM)",
  "35,426": "35,426",
  "37,624": "37,624",
  "28,111": "28,111",
  "Operational Cost (Rs.)": "運用コスト (Rs.)",
  "1,62,57,137": "1,62,57,137",
  "1,31,64,341": "1,31,64,341",
  "30,92,797": "30,92,797",
  "Average Vehicle Fill Rate": "平均車両充填率",
  "60 %": "60 %",
  "42 %": "42 %",
  "79 %": "79 %",
  "Unplanned Load Proportion": "計画外荷物の割合",
  "12 %": "12 %",
  "Average Vehicle Utilization As Per SOB": "SOB に基づく平均車両利用率",
  "104 %": "104 %",
  "94 %": "94 %",
  "Ontime Dispatches": "時間通りの発送",
  "Local": "ローカル",
  "frefe": "frefe",
  "94 %": "94 %",
  "92 %": "92 %",
  "Ontime Deliveries": "時間通りの配達",
  "97 %": "97 %",
  "94 %": "94 %",
      "Please Select Scenario to Compare Plans": "計画を比較するためのシナリオを選択してください",
"Percentage": "パーセンテージ",
"Forecast Scenario": "予測シナリオ",
      "Number of kms Travelled": "走行距離 (キロメートル)",
"Cost": "コスト",
"Vehicle Fill Rate": "車両の積載率",
"Vehicle KM Run %": "車両走行距離 %",
      "Please Select File": "ファイルを選択してください",
"Processing Plan. Please wait.....": "計画を処理中です。お待ちください.....",
"File is processing": "ファイルを処理中です",
"Enter Input Data To Continue": "続行するには入力データを入力してください",
"No Data": "データなし",
"please_select_date": "日付を選択してください",
"data_uploaded": "データがアップロードされました",
"Please Select No of Vehicles": "車両の数を選択してください",
"Please Input Valid Data": "有効なデータを入力してください",
      "loading....": "読み込み中....",
      "driver_break_time_to": "運転手の休憩時間まで",
"max_seating_capacity": "最大座席数",
"min_seating_capacity": "最小座席数",
"vehicle_seating_capacity": "車両の座席数",
"vehicle_capacity_cmt": "車両容量 (立方メートル)",
      "change_password": "パスワードを変更",
      "new_password": "新しいパスワード",
      "confirm_new_password": "新しいパスワードを確認",
      "old_password": "古いパスワード",
      "enter_email": "メールアドレスを入力してください",
      "enter_new_password": "新しいパスワードを入力してください",
      "confirm_new_password_entry": "新しいパスワードを確認してください",
  "select_cluster": "クラスターを選択",
  "Overall Cluster Wise Vehicles": "全体クラスターごとの車両",
  "Discard": "破棄",
  "obd": "OBD",
  "Dispatch Planning is in progress..!!!": "ディスパッチ計画が進行中です..!!!",
  "Estimated Time": "推定時間",
  "GPS": "GPS",
  "Spot Vehicles To Be Planned": "計画されるスポット車両",
  "No Of Spot Vehicles": "スポット車両の数",
  "Availability DateTime": "利用可能な日付と時間",
  "Notify Email Users After Process (comma seperated)": "処理後にメールユーザーに通知 (カンマ区切り)",
  "Max Time Limit(Hrs)": "最大時間制限 (時間)",
  "vfr": "VFR",
  "Disclaimer": "免責事項: OT顧客のディーラーの利用可能なウィンドウは入力ファイルから、GT顧客の時間はピックアップ/ドロップ場所から考慮されます。",
  "To Check for Blank Data": "空白データを確認するには: 1. 「検索と選択」をクリックします。 2. 「特別に移動」をクリックします。 3. 「空白」を選択します。 4. 「OK」をクリックすると、すべての空白行/セルが強調表示されます。 5. ホームタブのセルセクションで「削除」を選択します。 6. 「シート行を削除」をクリックします。",
  "Add New OBD": "新しいOBDを追加",
  "All drop locations are invalid, Please check..!!": "すべてのドロップ場所が無効です。確認してください..!!",
  "Please upload file having extensions .csv or .xlsx only.": ".csvまたは.xlsx拡張子のファイルのみをアップロードしてください。",
  "Dispatch Load Volume Summary (CFT)": "ディスパッチ負荷ボリュームの概要 (CFT)",
  "Total Loads Volume (CFT)": "総負荷ボリューム (CFT)",
  "Planned Loads Volume (CFT)": "計画された負荷ボリューム (CFT)",
  "Unplanned Loads Volume (CFT)": "未計画の負荷ボリューム (CFT)",
  "Dropped Loads Volume (CFT)": "ドロップされた負荷ボリューム (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "ディスパッチ負荷重量の概要 (ポンド)",
  "Total Loads Weight (Pounds)": "総負荷重量 (ポンド)",
  "Planned Loads Weight (Pounds)": "計画された負荷重量 (ポンド)",
  "Unplanned Loads Weight (Pounds)": "未計画の負荷重量 (ポンド)",
  "Dropped Loads Weight (Pounds)": "ドロップされた負荷重量 (ポンド)",
  "Dispatch Load Quantity Summary": "ディスパッチ負荷数量の概要",
  "Total Quantity": "総数量",
  "Planned Quantity": "計画数量",
  "Unplanned Quantity": "未計画数量",
  "Dropped Quantity": "ドロップされた数量",
  "Dispatch Load Volume Summary (CFT)": "ディスパッチ負荷ボリュームの概要 (CFT)",
  "Total Loads Volume (CFT)": "総負荷ボリューム (CFT)",
  "Planned Loads Volume (CFT)": "計画された負荷ボリューム (CFT)",
  "Unplanned Loads Volume (CFT)": "未計画の負荷ボリューム (CFT)",
  "Dropped Loads Volume (CFT)": "ドロップされた負荷ボリューム (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "ディスパッチ負荷重量の概要 (ポンド)",
  "Total Loads Weight (Pounds)": "総負荷重量 (ポンド)",
  "Planned Loads Weight (Pounds)": "計画された負荷重量 (ポンド)",
  "Unplanned Loads Weight (Pounds)": "未計画の負荷重量 (ポンド)",
  "Dropped Loads Weight (Pounds)": "ドロップされた負荷重量 (ポンド)",
  "Dispatch Load Quantity Summary": "ディスパッチ負荷数量の概要",
  "Total Quantity": "総数量",
  "Planned Quantity": "計画数量",
  "Unplanned Quantity": "未計画数量",
  "Dropped Quantity": "ドロップされた数量",
  "Dispatch Load Weight Summary (Pounds)": "ディスパッチ負荷重量の概要 (ポンド)",
  "Dispatch Load Volume Summary (CFT)": "ディスパッチ負荷ボリュームの概要 (CFT)",
  "Dropped Data": "ドロップされたデータ",
  "ontime_delivery": "時間通りの配達",
  "dealer_Available_start_time_should_be_less_than_end_time": "ディーラーの開始時間は終了時間よりも早い必要があります",
  "Removed": "削除されました",
  "Unedited": "未編集",
  "Edited": "編集済み",
      vehicle_availability_configurations: "車両の利用可能性の設定",
      max_distance_per_plan_miles: "プランごとの最大距離（マイル）",
    add_city_wise_cost: "市ごとのコストを追加",
    add_zone_wise_cost: "ゾーンごとのコストを追加",
    scenarios: "シナリオ",
      plant: "工場",
      customer: "顧客",
      "Pickup Locations (Drop)*": "ピックアップ場所（ドロップ）*",
      standard_transit_time_days: "標準輸送時間（日）",
    drop_to_time: "配達時間",
    drop_from_time: "出発時間",
    pickup_to_time: "受取時間",
    pickup_from_time: "受取開始時間",
    distance_from_plant_miles: "工場からの距離（マイル）",
    vehicle_next_available_date: "次に利用可能な車両の日時",
    max_distance_per_plan_miles: "プランごとの最大距離（マイル）",
    remaining_monthly_distance_miles: "残りの月間距離（マイル）",
    assign_quality_checks: "品質チェックを割り当てる",
    add_fixed_vehicle_cost: "固定車両コストを追加",
      "pickup_locations_drop*": "ピックアップ場所（ドロップ）*",
      duplicate_obds_light_red: "重複した OBD は薄い赤で表示されます",
    new_obds_green: "新しく追加された OBD は緑色で表示されます",
    pickup_locations_drop: "ピックアップ場所（ドロップ）",
       invalid_orders_not_shown: "無効な注文は表示されていません",
    note: "注意",
      percent_unplanned: "未計画の割合",
    start_month: "開始月",
    please_select_scenario: "シナリオを選択して計画を比較してください",
    avg_kms_travelled: "平均走行距離 (Km)",
    total_cost: "総費用",
    total_vehicle_fill_rate: "車両総充填率",
    percent_km_run_sob: "SOB 走行キロの割合",
    percent_ontime_dispatch: "定時出荷の割合",
    upcountry: "田舎",
    percent_spot_vehicles_trips: "スポット車両の割合",
    round_trip_distance: "往復距離",
    total_trip_distance: "総走行距離",
    vehicle_type: "車両の種類",
    plant_code: "プラントコード",
    vehicle_no: "車両番号",
    monthly_sob_km: "月間 SOB (Km)",
    distance_travelled_km: "走行距離 (Km)",
    pickup_location_source: "ピックアップ場所 (出発地)",
    drop_location_code_destination: "ドロップ場所コード (目的地)",
    drop_location_name_destination: "ドロップ場所名 (目的地)",
    order_number: "注文番号",
    invoicing_date: "請求日",
    quantity: "数量",
    weight_kgs: "重量 (Kgs)",
    demand_volume_m3: "需要体積 (M3)",
    dealer_available_from: "ディーラー利用可能開始日",
    dealer_available_to: "ディーラー利用可能終了日",
    delivery_date: "配送日",
    trip_no: "旅行番号",
    planned_day: "計画日",
    drop_sequence: "ドロップ順",
    destination_location_city: "目的地の場所の市",
    destination_location_state: "目的地の場所の州",
    volume_m3: "体積 (M3)",
    distance_to_destination_kms: "目的地までの距離 (Km)",
    expected_delivery_date: "予定配送日",
    time_to_destination: "目的地までの時間",
    transit_time_hhmm: "輸送時間 (時:分)",
    loading_time_hhmm: "積載時間 (時:分)",
    unloading_time_hhmm: "荷降ろし時間 (時:分)",
    end_month: "終了月",
    get_data: "データ取得",
    select_chart_type: "チャートタイプを選択",
    fleet_analysis: "フリート分析",
    system_recommended: "推奨システム",
    total_load: "総負荷",
    serviceability: "運用可能性",
    in416: "IN416",
    in436: "IN436",
    user_recommended: "推奨ユーザー",
    threshold: "しきい値",
    number_of_trips: "旅行の数",
    total: "合計",
    spot: "スポット",
    planned_truck_load: "計画されたトラック負荷",
    quantity_cases: "数量（ケース）",
    weight_kgs: "重量（Kg）",
    volume_cmt: "ボリューム（CMT）",
    unplanned_load: "未計画の負荷",
    average_distance_travelled_per_vehicle_km: "車両ごとの平均移動距離（KM）",
    operational_cost_rs: "運用コスト（Rs）",
    average_vehicle_fill_rate: "平均車両充填率",
    unplanned_load_proportion: "未計画の負荷の割合",
    average_vehicle_utilization_as_per_sob: "SOBに基づく平均車両利用率",
    ontime_dispatches: "定時発送",
    local: "ローカル",
    upcountry: "アップカントリー",
    ontime_deliveries: "定時配送",
    bar: "バー",
    load: "負荷",
    month: "月",
    percentage: "%",






      //code for configurations screen

      "Consider Transporter SOB": "トランスポーター SOB を考慮する",
      forecast_summary: "予報概要",
      compare: "比較する",
    pickup_locations_drop: "ピックアップ場所（ドロップ）",
      "note_highlighted_rows_red": "注意: 1. 赤でハイライトされた行はGPSデータや統合が不足しているため、車両の可用性を手動でマークしてください。",
      "highlighted_rows_green": "2. 緑でハイライトされた行は計画に考慮されます",
      "enable_reconciliation": "調整を有効にする",
      "enable_dc_week_off_planning": "データセンターの週休計画を有効にする",
      enable_disable: "有効/無効",
      "Consider Dropping Distant Smaller Loads":
        "遠距離の小さい荷物を無視することを考慮する",
      "Consider Realtime Vehicle Available Times":
        "リアルタイムの車両の利用可能時間を考慮する",
      "Plant Wise Vehicle Type Configuration*": "工場ごとの車両タイプ設定*",
      "Consignees / Dealers Locations (Drop)*":
        "受取人/ディーラーの場所（配達）*",
      "Pickup Locations (Pickup)*": "ピックアップ場所（引取）*",
      "Optimization Type*": "最適化タイプ*",
      "Plant Wise Planning History*": "工場ごとの計画履歴*",
      "Plant Wise Plan Summary*": "工場ごとの計画概要*",
      "Plant Wise New Plan Creation*": "工場ごとの新計画作成*",
      "Suppliers / Vendor Locations (Pickup)*":
        "サプライヤー/ベンダーの場所（引取）*",
      "Plant Wise Vehicle Type Configuration*": "工場ごとの車両タイプ設定*",
      "Consignees / Dealers Locations (Drop)*":
        "受取人/ディーラーの場所（配達）*",
      "Pickup Locations (Pickup)*": "ピックアップ場所（引取）*",
      "Plant Wise Vehicle Type Configuration*": "工場ごとの車両タイプ設定*",
      "Suppliers / Vendor Locations (Pickup)*":
        "サプライヤー/ベンダーの場所（引取）*",
      "Plant Wise Planning History*": "工場ごとの計画履歴*",
      "Plant Wise Plan Summary*": "工場ごとの計画概要*",
      "Plant Wise New Plan Creation*": "工場ごとの新計画作成*",
      "Enable/Disable Dispatch Planning Module":
        "ディスパッチ計画モジュールの有効/無効",
      Inbound: "インバウンド",
      Outbound: "アウトバウンド",
      "Suppliers / Vendor Locations (Pickup)":
        "サプライヤー / ベンダーの場所（引取）",
      "Operational Hours": "運用時間",
      "Pickup Locations (Drop)": "ピックアップ場所（配達）",
      "Plant Wise Vehicle Type Configuration": "工場ごとの車両タイプ設定",
      "Max No. Of Vehicle Types": "最大車両タイプ数",
      "Vehicle Load Capacity Min / Max Configuration Preferences":
        "車両積載容量の最小/最大設定の好み",
      "Vehicle Load Weight Min / Max Preferences":
        "車両積載重量の最小/最大の好み",
      "Distance preferences": "距離の好み",
      "Vehicle Priorities": "車両優先順位",
      "Vehicle Operational Times": "車両の運行時間",
      "Vehicle Availability": "車両の利用可能性",
      "Plant Wise Freight Configuration": "工場ごとの貨物設定",
      "Plant Wise Transporter Configuration": "工場ごとのトランスポーター設定",
      "Route Preferences": "ルートの好み",
      "Plant Wise New Plan Creation": "工場ごとの新計画作成",
      "Plant Wise Plan Summary": "工場ごとの計画概要",
      "Plant Wise Planning History": "工場ごとの計画履歴",
      "No of Vehicles Types": "車両タイプ数",
      "Generic Time Windows": "一般的な時間枠",
      "Individual Vehicle Time Windows": "個々の車両の時間枠",
      "Availability By Vehicle Type": "車両タイプごとの利用可能性",
      "Availability By Individual Vehicle": "個々の車両ごとの利用可能性",
      "Monthly Minimum Planning Distance Preferences": "月間最小計画距離の好み",
      "GPS Based Availability": "GPSに基づく利用可能性",
      "Truck Location Visibility": "トラックの位置の可視性",
      "Plant Vicinity Circle Radius": "工場周辺の円の半径",
      "Fixed Vehicle Costs": "固定車両費用",
      "Variable Costs": "変動費用",
      "Vehicle Type Level Variable Costs": "車両タイプレベルの変動費用",
      "City Level Vehicle Type Costs": "都市レベルの車両タイプ費用",
      "Per CFT Cost": "1CFTあたりの費用",
      "Per Mile Cost": "1マイルあたりの費用",
      "Zone Level Cost": "ゾーンレベルの費用",
      "Transporter Share of Business": "トランスポーターのビジネスシェア",
      "Sustainability Integration": "持続可能性統合",
      "Optimization Type": "最適化タイプ",
      "Cost Optimization": "コスト最適化",
      "Weight based optimization": "重量ベースの最適化",
      "Distance based optimization": "距離ベースの最適化",
      "Time based optimization": "時間ベースの最適化",
      "Volume based optimization": "ボリュームベースの最適化",
      "Prioritize Local Loads": "ローカル負荷を優先する",
      "Radius Miles": "半径マイル",
      "Number of Loading Docks": "積み込みドック数",
      "City Based Entry Restriction": "都市ベースのエントリー制限",
      "Unloading time per case/max unloading time":
        "1ケースあたりの荷降ろし時間/最大荷降ろし時間",
      "Consider Vehicle Break Times (Night Driving)":
        "車両の休憩時間（夜間運転）を考慮する",
      "Consider Holiday Restrictions": "休日制限を考慮する",
      "Vehicle Restriction": "車両制限",
      "Plan Spot Vehicles": "スポット車両を計画する",
      "Max No of Customers Per Vehicle": "1車両あたりの最大顧客数",
      "Use Dynamic Planning Window": "ダイナミック計画ウィンドウを使用する",
      "Number of Days to Use Planning Window": "計画ウィンドウを使用する日数",
      "Use Volume for Vehicle Fill Rate (VFR)":
        "車両充填率(VFR)に体積を使用する",
      "Use Weight for Vehicle Fill Rate (VFR)":
        "車両充填率(VFR)に重量を使用する",
      "Min VFR Per Vehicle Type": "車両タイプごとの最小VFR",
      "Min VFR Per Spot Vehicle": "スポット車両ごとの最小VFR",
      "Prioritize Same Dealers First in a Vehicle":
        "車両内で同じディーラーを優先する",
      "Prioritize Multi City Dealers First in a Vehicle":
        "車両内で複数都市のディーラーを優先する",
      "Dispatch within Days": "数日以内に発送",
      "Allow Unplanned Loads": "計画外の荷物を許可する",
      "Minimize Number of Vehicles Used": "使用車両数を最小限にする",
      "Dealer Buffer Time": "ディーラーのバッファ時間",
      "Buffer Time(Mins)": "バッファ時間（分）",
      "Month End Planning Start Day": "月末計画開始日",
      "Day of Every Month": "毎月の日",
      "Max Local No of Customers Per Vehicle":
        "1車両あたりの最大ローカル顧客数",
      "Max Upcountry No of Customers Per Vehicle":
        "1車両あたりの最大田舎顧客数",
      "Planning Type": "計画タイプ",
      "Local Prioritization": "ローカル優先順位",
      "Local Prioritization and Virtual Upcountry clubbing":
        "ローカル優先順位とバーチャル田舎クラブ化",
      "General Plan (Local and Upcountry clubbing)":
        "一般計画（ローカルおよび田舎のクラブ化）",
      "Telescopic Deviation": "テレスコピック偏差",
      "MOQ for Planning": "計画のためのMOQ",
      Reconciliation: "調整",
      "DC Week Off planning": "DC週休計画",
      "Save Inbound Configurations": "インバウンド設定を保存",
      "Save Outbound Configurations": "アウトバウンド設定を保存",
      SAVE: "保存",
      "Enable Reconciliation": "調整を有効にする",
      "Enable DC Week Off planning": "DC週休計画を有効にする",
      "Deviation Alert": "偏差アラート",
      "Overload Warning": "過負荷警告",
      "Underload Alert": "過小負荷アラート",
      "Fuel Efficiency Report": "燃料効率レポート",
      "Vehicle Downtime": "車両停止時間",
      "Speed Violation Alert": "速度違反アラート",
      "Trip Delay Notification": "旅行遅延通知",
      "Idle Time Monitoring": "アイドル時間の監視",
      "Driver Performance": "ドライバーのパフォーマンス",
      "Realtime GPS Tracking": "リアルタイムGPS追跡",
      "Fleet Utilization": "フリート利用",
      "Trip Efficiency": "旅行効率",
      "Dynamic Route Adjustment": "動的ルート調整",
      "Load Distribution Optimization": "荷物分配の最適化",
      "Transporter Preferences": "トランスポーターの好み",
      "Trip Schedule": "旅行スケジュール",
      "Driver Assignment": "ドライバー割り当て",
      "Optimize Vehicle Utilization": "車両利用の最適化",
      "Time Based Planning": "時間ベースの計画",
      "Plant Wise Route Planning": "工場ごとのルート計画",
      "Vehicle Rotation Configuration": "車両回転設定",
      "Inbound Vehicle Preferences": "インバウンド車両の好み",
      "Outbound Vehicle Preferences": "アウトバウンド車両の好み",
      "Vehicle Fill Rate Optimization": "車両充填率の最適化",
      "Dynamic Load Allocation": "動的荷物割り当て",
      "Start Date": "開始日",
      "Run Plan": "計画を実行する",
      "Completion Date": "完了日",
      "Transporter Name": "トランスポーター名",
      "Driver Allocation": "ドライバー割り当て",
      "Max Load Capacity": "最大積載容量",
      "Estimated Delivery Time": "推定配送時間",
      "Use Real-Time Traffic Data": "リアルタイム交通データを使用する",
      "Driver Break Times": "ドライバーの休憩時間",
      "Max Vehicle Load Percentage": "車両の最大積載率",
      "Telescopic Deviation Settings": "テレスコピック偏差設定",
      "Deviation Type": "偏差タイプ",
      "Spot Vehicle Usage": "スポット車両の使用",
      "Real-time Load Monitoring": "リアルタイム荷物監視",
      "Driver Allocation Time": "ドライバー割り当て時間",
      "Use Traffic Data": "交通データを使用する",
      "Optimize Route": "ルートを最適化する",
      "Optimize Fuel Efficiency": "燃料効率を最適化する",
      "Daily Vehicle Plan": "日次車両計画",
      "Transporter Allocation": "トランスポーターの割り当て",
      "Enable Real-time Tracking": "リアルタイム追跡を有効にする",
      "Vehicle Rotation Plan": "車両回転計画",
      "Route Optimization Preferences": "ルート最適化の好み",
      "Consider Weather Conditions": "天候条件を考慮する",
      "Max Loading Time": "最大積載時間",
      "Min Load Capacity": "最小積載容量",
      "Driver Scheduling": "ドライバーのスケジュール設定",
      "Vehicle Capacity Monitoring": "車両容量の監視",
      "Dynamic Load Balancing": "動的荷重分配",
      "Last Mile Optimization": "最終マイル最適化",
      "Unloading Time Management": "荷降ろし時間の管理",
      "Daily Trip Plan": "日次旅行計画",
      "Delivery Window Optimization": "配送ウィンドウの最適化",
      "Night Driving Restrictions": "夜間運転制限",
      "Weekend Planning": "週末の計画",

      run_new_plan: "新しいプランを実行",
      planned_distance_miles: "計画距離 (マイル)",
      restriction_hours: "制限時間",
      transporter_details: "輸送業者の詳細",
      save_geofence: "ジオフェンスを保存",
      run_new_plan: "新しい計画を実行",
      new_plan_details: "新しい計画の詳細",
      order: "注文",
      vehicle_configs: "車両構成",
      order_information: "注文情報",
      plan_configs: "計画構成",
      vehicle_availability: "車両の可用性",
      vehicle_type: "車両タイプ",
      vehicle_name: "車両名",
      vehicle_min_weight: "車両最小重量（ポンド）",
      vehicle_max_weight: "車両最大重量（ポンド）",
      vehicle_min_volume: "車両最小体積（立方フィート）",
      vehicle_max_volume: "車両最大体積（立方フィート）",
      max_distance_per_trip: "1回の走行での最大距離（マイル）",
      vehicle_priority: "車両の優先度",
      enable_reconciliation: "調整を有効にする",
      enable_dc_week_off_planning: "DC週休計画を有効にする",
      order_number: "注文番号",
      invoicing_date: "請求書発行日",
      quantity: "数量",
      demand_cft: "需要（立方フィート）",
      weight_pounds: "重量（ポンド）",
      dealer_available_from: "ディーラーの利用可能時間（開始）",
      dealer_available_to: "ディーラーの利用可能時間（終了）",
      delivery_date: "納品日",
      general_settings: "一般設定",
      prioritize_local_loads: "ローカルの積載を優先する",
      consider_dropping_distant_smaller_loads:
        "遠距離の小さい荷物を除外することを検討",
      plan_spot_vehicles: "スポット車両の計画",
      city_based_entry_restriction: "都市ベースの進入制限",
      consider_vehicle_break_times: "車両の休憩時間を考慮する（夜間運転）",
      consider_holiday_restrictions: "休日制限を考慮する",
      consider_realtime_vehicle_available_times:
        "リアルタイムの車両利用可能時間を考慮する",
      consider_transporter_sob: "輸送者のSOBを考慮する",
      planning_optimization_settings: "計画最適化設定",
      use_dynamic_planning_window: "動的な計画ウィンドウを使用",
      number_of_days_to_use_planning_window: "計画ウィンドウを使用する日数",
      optimization_type: "最適化タイプ",
      cost_optimization: "コスト最適化",
      weight_based_optimization: "重量ベースの最適化",
      distance_based_optimization: "距離ベースの最適化",
      time_based_optimization: "時間ベースの最適化",
      volume_based_optimization: "体積ベースの最適化",
      vehicle_fill_rate_vfr_settings: "車両充填率設定",
      use_volume_for_vfr: "車両充填率に体積を使用",
      use_weight_for_vfr: "車両充填率に重量を使用",
      min_vfr_per_vehicle_type: "車両タイプごとの最小充填率",
      min_vfr_per_spot_vehicle: "スポット車両ごとの最小充填率",
      moq_for_planning: "計画のMOQ",
      load_planning_settings: "荷物計画設定",
      number_of_loading_docks: "積み下ろしドックの数",
      max_local_no_of_customers_per_vehicle:
        "1台の車両あたりの最大ローカル顧客数",
      max_upcountry_no_of_customers_per_vehicle:
        "1台の車両あたりの最大郊外顧客数",
      prioritize_same_dealers_first: "同じディーラーを車両内で優先",
      prioritize_multi_city_dealers_first: "複数都市のディーラーを車両内で優先",
      note_highlighted_rows_red:
        "赤でハイライトされた行にはGPSデータまたは統合がありません。車両の可用性を手動でマークしてください。",
      note_highlighted_rows_green:
        "緑でハイライトされた行は計画に考慮されています",
      vehicle_number: "車両番号",
      transit_type: "輸送タイプ",
      monthly_minimum_distance: "月間最小距離",
      remaining_distance: "残りの距離",
      vehicle_available_date: "車両利用可能日（YYYY-MM-DD HH:mm）",
      charlie_hub: "チャーリーハブ",
      enable_recommendation: "推奨を有効にする",
      planned_day: "計画日",
      drop_sequence: "降車シーケンス",
      drop_location_code_destination: "降車場所コード（目的地）",
      drop_location_name_destination: "降車場所名（目的地）",
      destination_location_city: "目的地の都市",
      weight_kgs: "重量（キログラム）",
      volume_m3: "体積（立方メートル）",
      distance_to_destination_kms: "目的地までの距離（キロメートル）",
      expected_delivery_date: "予定納品日",
      time_to_destination: "目的地までの時間",
      transit_time_hh_mm: "輸送時間（時:分）",
      unloading_time_hh_mm: "荷降ろし時間（時:分）",
      plan_id: "計画ID",
      generated_on: "生成日",
      vehicle_availability_from: "車両利用可能時間（開始）",
      vehicle_availability_to: "車両利用可能時間（終了）",
      break_time_from: "休憩時間（開始）",
      break_time_to: "休憩時間（終了）",
      min_volume_m3: "最小体積（立方メートル）",
      max_volume_m3: "最大体積（立方メートル）",
      vehicle_width_mm: "車両幅（ミリメートル）",
      vehicle_height_mm: "車両高さ（ミリメートル）",
      vehicle_depth_mm: "車両奥行き（ミリメートル）",
      material_code: "材料コード",
      material_name: "材料名",
      material_type: "材料タイプ",
      material_weight: "材料重量",
      material_dimensions_depth: "材料の奥行き寸法",
      material_dimensions_width: "材料の幅寸法",
      material_dimensions_height: "材料の高さ寸法",
      state: "州",
      city: "都市",
      fixed_vehicle_cost: "固定車両費用",
      driver_cost: "ドライバーの費用",
      maintenance_cost: "メンテナンス費用",
      per_cmt_cost: "1立方フィートあたりのコスト",
      per_km_cost: "1キロメートルあたりのコスト",
      date: "日付",
      transporter_name: "輸送業者名",
      no_of_vehicles: "車両数",
      driver_break_time_from: "ドライバー休憩時間（開始）",
      vehicle_max_weight_kgs: "車両最大重量（キログラム）",
      vehicle_min_volume_m3: "車両最小体積（立方メートル）",
      vehicle_max_volume_m3: "車両最大体積（立方メートル）",
      max_distance_kms: "最大距離（キロメートル）",
      monthly_min_planning_distance_kms: "月間最小計画距離（キロメートル）",
      details: "詳細",
      trip_no: "旅行番号",
      vehicle_no: "車両番号",
      no_of_orders: "注文数",
      no_of_customers: "顧客数",
      total_quantity: "総数量",
      vehicle_utilization_percent: "車両利用率（％）",
      total_planned_weight_kgs: "計画総重量（キログラム）",
      onward_trip_distance_kms: "往路距離（キロメートル）",
      round_trip_distance_kms: "往復距離（キロメートル）",
      total_planned_volume_m3: "計画総体積（立方メートル）",
      max_dispatch_time_hh_mm: "最大出荷時間（時:分）",
      arrival_at_first_dealer_start: "最初のディーラーへの到着（開始）",
      departure_at_last_dealer_end: "最後のディーラーからの出発（終了）",
      return_to_plant_hh_mm: "工場への帰還時間（時:分）",
      total_planned_trip_time_hrs: "計画された総旅行時間（時間）",
      total_round_trip_time_hrs: "総往復時間（時間）",
      total_transit_time_hh_mm: "総輸送時間（時:分）",
      total_loading_time_hh_mm: "総積み込み時間（時:分）",
      total_unloading_time_hh_mm: "総荷降ろし時間（時:分）",
      drop_location_code: "降車場所コード",
      drop_location_name: "降車場所名",
      drop_location_city: "降車場所の都市",
      demand_volume_m3: "需要体積（立方メートル）",
      vehicle_min_weight_kgs: "車両最小重量（キログラム）",
      no_of_deliveries: "納品数",
      min_load_per_delivery: "納品ごとの最小負荷",
      status: "ステータス",
      processing: "処理中",
      please_contact_admin: "管理者に連絡してください",
      solver_complete: "解決完了",
      solver_reco_processed: "推奨処理完了",
      solver_sob_done: "SOB完了",
      solver_invalid_input_dealer_location: "無効なディーラー位置入力",
      solver_invalid_input_materials: "無効な材料入力",
      solver_invalid_input_dealer_location_and_materials:
        "無効なディーラー位置と材料入力",
      solver_input_data_not_valid: "無効な入力データ",
      solver_fix_dropped_dealers: "削除されたディーラーを修正",
      solver_vehicle_details_missing: "車両詳細が欠落しています",
      solver_input_file_data_error: "入力ファイルデータエラー",
      solver_invalid_file_template: "無効なファイルテンプレート",
      process_timed_out: "処理がタイムアウトしました",
      solver_sob_fail: "SOB失敗",
      solver_reco_failed: "推奨失敗",
      solver_no_tool_config: "ツール構成がありません",
      solver_invalid_tool_config: "無効なツール構成",
      transit_time_with_multiple_city_clubing: "複数都市の統合による輸送時間",
      rdd_preference: "RDDの優先度",
      transit_time_with_single_city_clubing: "単一都市の統合による輸送時間",
      no_of_trucks_planned: "計画されたトラックの数",
      view_details: "詳細を表示",
      plan_run_by: "計画実行者",
      compare_plans: "計画を比較",
      scenario_name: "シナリオ名",
      pick_up_location_source: "ピックアップ場所（発地）",
      pickup_location_source: "ピックアップ場所（発地）",
      drop_location_destination: "降車場所（目的地）",
      demand_m3: "需要（立方メートル）",
      location_radius: "場所半径（キロメートル）",
      volume_cmt: "体積（CMT）",
      obd_details_in_radius: "半径内のOBDの詳細",
      planning_configurations: "計画構成",
      planning_scenarios: "計画シナリオ",
      what_if_demand_fluctuation: "もし需要が変動したら",
      increase_decrease_demand_by: "需要を増加/減少させる",
      what_if_customer_priority_changes: "もし顧客の優先順位が変わったら",
      prioritize_upcountry: "地方を優先する",
      prioritize_local: "ローカルを優先する",
      what_if_service_level_target_changes:
        "もしサービスレベル目標が変わったら",
      set_service_level_target_to: "サービスレベル目標を設定する",
      what_if_vehicle_fill_rate_changes: "もし車両充填率が変わったら",
      set_vehicle_fill_rate_to: "車両充填率を設定する",
      what_if_customer_specific_delivery_time: "顧客特定の納品時間の場合",
      increase_decrease_delivery_time_window_by: "納品時間枠を増減する",
      scenario_description: "シナリオの説明",
      vehicle_fill_rate_settings: "車両充填率設定",
      file_name: "ファイル名",
      total_weight_kgs: "総重量（キログラム）",
      total_demand_volume_m3: "総需要体積（立方メートル）",
      processing_plan_please_wait: "計画を処理中です。お待ちください...",
      forecast_input_information: "予測入力情報",
      uploading_data_for_plant: "工場のためのデータをアップロード中",
      vehicle_type_configuration: "車両タイプ構成",
      min_volume_cft: "最小体積（立方フィート）",
      max_volume_cft: "最大体積（立方フィート）",
      cost_per_km: "1キロメートルあたりのコスト",
      note_duplicate_scenario: "注意：重複するシナリオ",
      select_unique_scenarios_to_compare:
        "比較するために一意のシナリオを選択してください",
      scenario_selection_limit_exceeded: "シナリオ選択制限を超えました",
      note_scenario_selection_limit: "注意：最大3つのシナリオを選択できます",
      select_scenario_to_compare: "比較するシナリオを選択してください",
      forecast_scenarios: "予測シナリオ",
      select_planning_duration: "計画期間を選択",
      notify_email_users_after_process:
        "処理後にメールユーザーに通知する（カンマ区切り）",
      optimize_by_time_details: "時間詳細で最適化",
      max_time_limit_hrs: "最大時間制限（時間）",
      spot_vehicles_to_be_planned: "計画されるスポット車両",
      auto_select: "自動選択",
      no_data: "データがありません",
      redirecting_to_vehicle_checklist: "車両チェックリストにリダイレクト中",
      planned_day: "計画日",
      drop_sequence: "ドロップ順",
      drop_location_code_destination: "ドロップ場所コード（目的地）",
      drop_location_name_destination: "ドロップ場所名（目的地）",
      destination_location_city: "目的地都市",
      dealer_type: "ディーラータイプ",
      customer_type: "顧客タイプ",
      week_off: "休み",
      order_number: "注文番号",
      invoicing_date: "請求日",
      quantity: "数量",
      weight_pounds: "重量 (ポンド)",
      volume_cft: "体積 (CFT)",
      distance_to_destination_miles: "目的地までの距離 (マイル)",
      expected_delivery_date: "予定配達日",
      time_to_destination: "目的地までの時間",
      transit_time_hh_mm: "輸送時間 (HH:MM)",
      unloading_time_hh_mm: "荷降ろし時間 (HH:MM)",
      email: "メール",
      details: "詳細",
      view: "表示",
      assign_vehicle: "車両割り当て",
      revoke_assignment: "割り当てを取り消す",
      edit_plan: "計画を編集",
      delete: "削除",
      plan_edited: "計画が編集されました",
      yes: "はい",
      no: "いいえ",
      trip_no: "旅行番号",
      vehicle_no: "車両番号",
      vehicle_type: "車両タイプ",
      cost: "コスト ($)",
      transit_type: "輸送タイプ",
      transporter_name: "運送会社名",
      no_of_orders: "注文数",
      no_of_customers: "顧客数",
      total_quantity: "合計数量",
      vehicle_utilization_percent: "車両利用率 (%)",
      vehicle_max_weight: "車両最大重量 (ポンド)",
      total_planned_weight: "合計計画重量 (ポンド)",
      onward_trip_distance_miles: "片道旅行距離 (マイル)",
      round_trip_distance_miles: "往復距離 (マイル)",
      vehicle_max_volume_cft: "車両最大体積 (CFT)",
      total_planned_volume_cft: "合計計画体積 (CFT)",
      max_dispatch_time: "最大出荷時間 (HH:MM)",
      arrival_at_first_dealer: "最初のディーラー到着 (開始)",
      departure_at_last_dealer: "最後のディーラー出発 (終了)",
      return_to_plant: "工場への帰還 (HH:MM)",
      total_planned_trip_time: "合計計画旅行時間 (時間)",
      total_round_trip_time: "合計往復時間 (時間)",
      total_transit_time: "合計輸送時間 (HH:MM)",
      total_loading_time: "合計積み込み時間 (HH:MM)",
      total_unloading_time: "合計荷降ろし時間 (HH:MM)",
      remark: "備考",
      "n/a": "該当なし",
      revoke_again_to_edit: "再度取り消して編集",
      drop_location_code: "ドロップ場所コード",
      drop_location_name: "ドロップ場所名",
      drop_location_city: "ドロップ場所都市",
      distance_from_plant: "工場からの距離",
      demand_volume_cft: "需要体積 (CFT)",
      delivery_date: "納品日",
      actions: "操作",
      pickup_location_source: "ピックアップ場所 (出所)",
      dealer_available_from: "ディーラーが利用可能（開始）",
      dealer_available_to: "ディーラーが利用可能（終了）",
      loading_time_hh_mm: "積み込み時間 (HH:MM)",
      dropped_reason: "ドロップ理由",
      dealer_location_data_not_in_system:
        "ディーラーの場所データがシステムにありません",
      delivery_date_less_than_current_date: "納品日が現在の日付より前です",
      kdd_customer: "KDD顧客",
      insufficient_load_for_planning: "計画のための負荷が不足しています (<MOQ)",
      warehouse_name: "倉庫名",
      destination_city: "目的地都市",
      destination_state: "目的地州",
      sales_order_no: "販売注文番号",
      no_of_tires: "タイヤ数",
      order_date: "注文日",
      vehicle_name: "車両名",
      vehicle_priority: "車両優先度",
      vehicle_availability_from: "車両の可用性 (開始)",
      vehicle_availability_to: "車両の可用性 (終了)",
      break_time_from: "休憩時間 (開始)",
      break_time_to: "休憩時間 (終了)",
      min_volume_cft: "最小体積 (CFT)",
      max_volume_cft: "最大体積 (CFT)",
      vehicle_dimensions_width_mm: "車両幅 (mm)",
      vehicle_dimensions_height_mm: "車両高さ (mm)",
      vehicle_dimensions_depth_mm: "車両深さ (mm)",
      vehicle_min_weight: "車両最小重量 (ポンド)",
      max_no_of_nodes: "配送ポイント数",
      min_load_per_delivery: "1回の配送あたりの最小負荷",
      max_distance: "最大距離 (マイル)",
      material_code: "材料コード",
      material_name: "材料名",
      material_type: "材料タイプ",
      material_weight: "材料重量",
      material_dimensions_depth: "材料の深さ",
      material_dimensions_width: "材料の幅",
      material_dimensions_height: "材料の高さ",
      form_vehicle_types: "車両タイプ",
      dealer_form_state: "州",
      dealer_form_city: "都市",
      freight_fixed_vehicle_cost: "固定車両費",
      freight_driver_cost: "運転手費",
      freight_maintenance_cost: "メンテナンス費",
      freight_per_cmt_cost: "1 CMTあたりの費用",
      freight_per_km_cost: "1キロあたりの費用",
      vehicle_date: "日付",
      vehicle_number: "車両番号",
      plant_code: "工場コード",
      plant_name: "工場名",
      available_time: "利用可能時間",
      gps_status: "GPSステータス",
      last_known_address: "最後に知られている住所",
      last_gps_time: "最後のGPS時間",
      vehicle_location: "車両位置",
      assigned_to_ot: "OTに割り当てられました",
      min_vfr: "最小 VFR",
      order_information: "注文情報",
      vehicle_type_configurations: "車両タイプの構成",
      vehicle_routes: "車両ルート",
      total_customers: "総顧客数",
      planned: "計画済み",
      unplanned: "計画外",
      total_round_trip_distance_miles: "総往復距離（マイル）",
      planning: "計画",
      vfr: "VFR",
      ontime_dispatch: "オンタイム発送",
      avg_tat: "平均TAT",
      view_locations: "場所を見る",
      view_routes: "ルートを見る",
      additional_vehicles: "追加の車両",
      regular_vehicles: "通常の車両",
      enter_vehicle_no: "車両番号を入力",
      vehicle_has_active_trip_shipment_id:
        "車両にアクティブなトリップ出荷IDがあります",
      do_you_wish_to: "したいですか",
      force_close_assign: "強制終了して割り当てる",
      force_close_reason: "強制終了の理由",
      gps_issue: "GPSの問題",
      trip_closed: "トリップ終了",
      load_cancelled: "ロードキャンセル",
      new_trip_allocated: "新しいトリップが割り当てられました",
      driver_changed: "ドライバーが変更されました",
      are_you_sure_you_want_to_update: "本当に更新しますか",
      edit_plan_for_same: "同じ計画を編集",
      update_vehicle_plan: "車両計画を更新",
      max_dispatch_time: "最大発送時間",
      new_plan: "新しい計画",
      reason_for_plan_change: "計画変更の理由",
      enter_the_reason: "理由を入力してください",
      reason_for_plan_deletion: "計画削除の理由",
      are_you_sure_delete_plan: "本当に計画を削除しますか",
      update_order_details: "注文の詳細を更新",
      demand_volume: "需要量",
      dealer_availability_start_time: "ディーラーの利用可能開始時間",
      dealer_availability_end_time: "ディーラーの利用可能終了時間",
      configurations: "設定",
      download: "ダウンロード",
      dealerTimeError:
        "ディーラーの開始時間は終了時間よりも短くする必要があります",
      more: "もっと",
      less: "少ない",
      qualityChecks: "品質チェック",
      currentMonthPlanDistance: "今月の計画距離",
      email: "Eメール",
      viewPlan: "計画を見る",
      blank_rows_columns_not_allowed: "空白の行/列は許可されていません。",
      import_existing_vehicle_details: "既存の車両詳細をインポート",
      uploading_data_for_plant: "工場のデータをアップロード中",
      check_and_remove_blank_data:
        "空白のデータを確認して削除するには、検索と選択 → 特殊条件に移動 → 空白、を使用し、強調表示された行を削除してください。",
      add_vehicle_type: "車両タイプを追加",
      vehicle_operational_hours: "車両の運行時間 (HH:MM)",
      data_saved_successfully: "データが正常に保存されました",
      plant_wise_configurations: "工場別構成",
      save_plant_wise_configurations: "工場別構成を保存",
      vehicle_break_window: "車両の休憩時間 (HH:MM)",
      total_vehicles: "車両の合計",
      zone_configurations: "ゾーン構成",
      pickup_point: "ピックアップポイント",
      actions: "アクション",
      edit: "編集",
      add_transporter_details: "+ 輸送業者の詳細を追加",
      cost_per_mile: "マイルあたりのコスト",
      freight_details: "貨物詳細",
      add_zone: "+ ゾーンを追加",
      fixed_vehicle_cost_title: "固定車両コスト ($)",
      variable_vehicle_cost_title: "可変車両コスト",
      city_wise_cost: "都市別車両コスト",
      zone_wise_cost: "ゾーン別車両コスト",
      vehicle_cost_configurations: "車両コスト構成",
      freight_cost_label: "貨物コスト",
      validity_to_label: "有効期限",
      validity_from_label: "有効開始日",
      actions_label: "アクション",
      edit_button: "編集",
      delete_button: "削除",
      save_button: "保存",
      cancel_button: "キャンセル",
      select_vehicle_type: "車両タイプを選択",
      select_transporter_name: "輸送業者名を選択",
      zone: "ゾーン",
      state: "州",
      driver_cost: "運転手のコスト ($)",
      maintenance_cost: "メンテナンスコスト ($)",
      fixed_vehicle_cost_label: "固定車両コスト ($)",
      enable_disable_dispatch_planning_module:
        "配送計画モジュールを有効化/無効化",
      inbound: "入荷",
      suppliers_vendor_locations_pickup:
        "サプライヤー/ベンダーの場所 (ピックアップ)",
      operational_hours: "運行時間",
      pickup_locations_drop: "ピックアップ場所 (ドロップ)",
      plant_wise_vehicle_type_configuration: "工場別車両タイプ構成",
      max_no_of_vehicle_types: "最大車両タイプ数",
      vehicle_load_capacity_min_max_configuration_preferences:
        "車両積載能力の最小/最大構成の好み",
      vehicle_load_weight_min_max_preferences: "車両積載重量の最小/最大の好み",
      distance_preferences: "距離の好み",
      vehicle_priorities: "車両の優先順位",
      vehicle_operational_times: "車両の運行時間",
      vehicle_availability: "車両の可用性",
      generic_time_windows: "一般的な時間枠",
      individual_vehicle_time_windows: "個別車両の時間枠",
      availability_by_vehicle_type: "車両タイプ別の可用性",
      availability_by_individual_vehicle: "個別車両の可用性",
      monthly_minimum_planning_distance_preferences: "月次最小計画距離の好み",
      gps_based_availability: "GPSに基づく可用性",
      truck_location_visibility: "トラックの位置の可視性",
      plant_vicinity_circle_radius: "工場の近隣範囲 (半径)",
      plant_wise_freight_configuration: "工場別貨物構成",
      plant_wise_transporter_configuration: "工場別輸送業者構成",
      transporter_share_of_business: "輸送業者の事業分担",
      route_preferences: "ルートの好み",
      plant_wise_new_plan_creation: "工場別新しいプランの作成",
      plant_wise_plan_summary: "工場別プランの概要",
      plant_wise_planning_history: "工場別計画履歴",
      sustainability_integration: "持続可能性の統合",
      save_inbound_configurations: "入荷構成を保存",
      save_outbound_configurations: "出荷構成を保存",
      outbound: "出荷",
      consignees_dealers_locations_drop: "荷受人/ディーラーの場所 (ドロップ)",
      vehicles: "車両",
      total_trucks: "トラック総数",
      active: "アクティブ",
      cost_details: "コストの詳細",
      fixed_vehicle_cost: "固定車両コスト",
      variable_vehicle_cost: "可変車両コスト",
      city_wise_vehicle_cost: "都市別車両コスト",
      zone_wise_vehicle_cost: "ゾーン別車両コスト",
      pickup_point: "ピックアップポイント",
      vehicle_type: "車両タイプ",
      transporter_name: "輸送業者名",
      cost_per_mile_below_sob: "1マイルあたりのコスト (SOB以下)",
      cost_per_mile_after_sob: "1マイルあたりのコスト (SOB後)",
      validity_from: "有効期限開始",
      validity_to: "有効期限終了",
      add_vehicle_cost: "+ 車両コストを追加",
      freight_details: "運賃の詳細",
      select_transporter: "輸送業者を選択",
      select_zone: "ゾーンを選択",
      select_state: "州を選択",
      city: "都市",
      cost: "コスト ($)",
      save: "保存",
      cancel: "キャンセル",
      actions: "アクション",
      validity_from_label: "有効期限開始",
      validity_to_label: "有効期限終了",
      get_data: "データを取得",
      state: "州",
      zone: "ゾーン",
      fixed_vehicle_cost_label: "固定車両コスト ($)",
      driver_cost: "ドライバーコスト ($)",
      maintenance_cost: "メンテナンスコスト ($)",
      city: "都市",
      zone: "ゾーン",
      vehicle_cost: "車両コスト",
      freight_cost: "運賃の詳細",
      inactive: "非アクティブ",
      inside_dc: "DC内",
      in_transit_to_customer: "顧客への輸送中",
      near_customer: "顧客の近く",
      return_to_dc: "DCに戻る",
      empty_outside_dc: "DC外の空車",
      zone_configurations: "ゾーン設定",
      transporters_configurations: "輸送業者設定",
      truck_no: "トラック番号",
      facility_name: "施設名",
      zones: "ゾーン",
      actions: "アクション",
      edit: "編集",
      delete: "削除",
      add_zone_mapping: "ゾーンマッピングを追加",
      current_status: "現在のステータス",
      next_availability: "次の可用性",
      dc_location: "DCの場所",
      add_route_restriction: "ルート制限を追加",
      location_code: "場所コード",
      location_name: "場所名",
      restricted_area: "制限区域",
      vehicle_list: "車両リスト",
      from_time: "開始時間",
      to_time: "終了時間",
      actions: "アクション",
      edit: "編集",
      customer_location: "顧客の場所",
      truck_no: "トラック番号",
      facility_name: "施設名",
      current_status: "現在のステータス",
      inside_dc: "DC内",
      in_transit_to_customer: "顧客への輸送中",
      near_customer: "顧客の近く",
      return_to_dc: "DCに戻る",
      empty_outside_dc: "DC外の空車",
      unplanned: "計画外",
      next_availability_date_at_dc: "DCでの次の利用可能日",
      transporter_name: "輸送業者名",
      gps_status: "GPSステータス",
      inactive: "非アクティブ",
      active: "アクティブ",
      na: "該当なし",
      actions: "アクション",
      date_and_time: "日付と時間",
      security: "セキュリティ",
      quality: "品質",
      assessed_by: "評価者",
      quality_conclusion: "品質結論",
      quality_comments: "品質コメント",
      quality_approved: "品質承認",
      quality_date_of_assessment: "品質評価日",
      facility_name: "施設名",
      vehicle_number: "車両番号",
      vehicle_type: "車両タイプ",
      transporter_code: "輸送業者コード",
      transporter_name: "輸送業者名",
      last_packet_time: "最後のパケット時間",
      vehicle_checklist_for_transportation: "輸送用車両チェックリスト",
      assessed_by: "評価者",
      security_conclusion: "セキュリティ結論",
      security_comments: "セキュリティコメント",
      zone_mapping: "ゾーンマッピング",
      transporters: "運送業者",
      material_configurations: "材料の設定",
      freight_configurations: "貨物の設定",
      route_configurations: "ルートの設定",
      auto_clustering: "自動クラスタリング",
      transporter_share_of_business: "運送業者の事業分担",
      dispatch_plan_summary: "配送計画の概要",
      kpi_dashboard: "KPIダッシュボード",
      planning_tools: "計画ツール",
      forecast_tools: "予測ツール",
      suppliers: "供給者",
      customers: "顧客",
      materials: "材料",
      input_data: "入力データ",
      forecast: "予測",
      previous_plan: "前の計画",
      trips: "旅行",
      gps_quality: "GPSの品質",
      truck_gps_data: "トラックGPSデータ",
      unscheduled_stoppages: "予定外の停車",
      deviation_reports: "逸脱レポート",
      fo_data: "FOデータ",
      vehicle_checklist: "車両チェックリスト",
      vehicle_status: "車両の状態",
      route_restrictions: "ルート制限",
      consolidated_run_plan: "統合ラン計画",
      plans_overview: "計画の概要",
      input_plan_summary: "入力計画の概要",
      load_plan_summary: "積載計画の概要",
      plant_wise_configurations: "プラント別設定",
      vehicle_configuration: "車両設定",
      monthly_data: "月次データ",
      forecast_plan_history: "予測計画履歴",
      obd_analysis: "OBD分析",
      actions: "アクション",
      pickup_location_source: "ピックアップ場所（出発地点）",
      drop_location_destination: "配送場所（目的地）",
      order_number: "注文番号",
      invoicing_date: "請求日（M/D/YYYY）",
      quantity: "数量",
      dealer_available_time_from: "ディーラー利用可能時間（00:00から）",
      dealer_available_time_to: "ディーラー利用可能時間（23:59まで）",
      volume_cft: "体積（CFT）",
      weight_pounds: "重量（ポンド）",
      delivery_date: "配達日（M/D/YYYY）",
      no_rows_to_show: "表示する行がありません",
      rows: "行",
      total_rows: "合計行数",
      add_dispatch: "配送を追加",
      run_new_plan_by_file: "ファイルで新しい計画を実行",
      submit_run_plan: "送信して計画を実行",
      delete: "削除",
      upload_input_file: "入力ファイルをアップロード",
      upload_file: "ファイルをアップロード",
      download_sample_template: "サンプルテンプレートをダウンロード",
      save: "保存",
      cancel: "キャンセル",
      dealer_available_time_error:
        "開始時間は終了時間より前でなければなりません",
      ok: "OK",
      summary_dashboard: "サマリーダッシュボード",
      pickup_point: "ピックアップポイント",
      date_created: "作成日",
      get_data: "データを取得",
      plan_details: "計画の詳細",
      plan_history: "計画履歴",
      drop_details: "配送詳細",
      generate_plan: "計画を生成",
      order_id: "注文ID",
      no_of_runs: "実行回数",
      created_on: "作成日",
      plan_run_by: "実行者による計画",
      discard_obd: "OBDを破棄",
      upload_obd_details: "OBDの詳細をアップロード",
      download_template: "テンプレートをダウンロード",
      choose_file: "ファイルを選択",
      submit: "送信",
      start_date: "開始日",
      end_date: "終了日",
      get_data: "データを取得",
      upload_input_file: "入力ファイルをアップロード",
      discard_obd: "OBDを破棄",
      recent_plans: "最近の計画",
      plan_history: "計画履歴",
      view_input: "入力を表示",
      consolidation_id: "統合ID",
      created_date: "作成日",
      run_by: "実行者",
      no_of_executions: "実行回数",
      execution_status: "実行ステータス",
      download_output: "出力をダウンロード",
      upload_obd_details: "OBDの詳細をアップロード",
      download_template: "テンプレートをダウンロード",
      choose_file: "ファイルを選択",
      submit: "送信",
      simulation_time: "シミュレーション時間",
      enable_dc_week_off_planning: "DCの週末休暇計画を有効化",
      run_plan: "計画を実行",
      cancel: "キャンセル",
      plan_details: "計画の詳細",
      pickup_point: "ピックアップ地点",
      date_created: "作成日",
      order_id: "注文ID",
      no_of_runs: "実行回数",
      fix_and_reupload: "修正して再アップロード",
      club_previous_order: "前の注文をまとめる",
      ignore: "無視",
      dealer_code: "ディーラーコード",
      remarks: "備考",
      run_new_plan: "新しい計画を実行",
      consolidated_route_plan_screen: "統合ルート計画画面",
      plant_code: "プラントコード",
      plant_name: "プラント名",
      total_trips: "総旅行数",
      trips_edited: "編集された旅行",
      edited_transaction_ids: "編集されたトランザクションID",
      vehicle_type: "車両タイプ",
      vehicle_name: "車両名",
      weight_pounds: "重量（ポンド）",
      drop_location_city: "配達場所の都市",
      quantity: "数量",
      planned_vehicles: "計画された車両",
      trip_no: "旅行番号",
      total_planned_customers: "計画された顧客総数",
      total_round_trip_distance: "往復総距離",
      planning_percentage: "計画の割合",
      max_dispatch_time: "最大配送時間（HH:MM）",
      total_loading_time: "総積載時間（HH:MM）",
      total_unloading_time: "総積み下ろし時間（HH:MM）",
      planned_summary: "計画概要",
      invalid_orders_not_shown: "注意: 無効な注文は表示されていません",
      no_of_customers: "顧客数",
      no_of_orders: "注文数",
      total_demand_volume: "総需要量（CFT）",
      planned_summary: "計画概要",
      trips_removed: "削除された旅行",
      removed_transaction_ids: "削除されたトランザクションID",
      vehicle_forecast_configurations: "車両予測構成",
      pickup_point: "ピックアップ地点",
      vehicle_type: "車両タイプ",
      vehicle_name: "車両名",
      cost_per_miles: "マイルあたりのコスト",
      driver_break_time: "ドライバーの休憩時間",
      view: "表示",
      please_select_no_of_vehicles: "車両の数を選択してください",
      no_of_vehicles: "車両数",
      cost_per_miles: "マイルあたりのコスト（$）",
      driver_break_time_from: "ドライバーの休憩時間（開始）",
      monthly_min_planning_distance_miles: "月間最小計画距離（マイル）",
      vehicle_type_count: "車両タイプの数",
      driver_break_time_per_day: "1日あたりのドライバー休憩時間（HH:MM）",
      cost_per_miles_2: "マイルあたりのコスト（$）",
      set_vehicle_priority: "車両の優先順位を設定",
      vehicle_type_name: "車両タイプ名",
      min_volume_cft: "最小ボリューム（CFT）",
      max_volume_cft: "最大ボリューム（CFT）",
      max_distance_per_trip_miles: "1回の旅行あたりの最大距離（マイル）",
      select_plan_id: "計画IDを選択",
      drop_details: "配達の詳細",
      drop_location_code: "配達場所コード",
      drop_location_name: "配達場所名",
      pickup_location: "ピックアップ場所",
      dealer_available_from: "ディーラー利用可能（開始）",
      dealer_available_to: "ディーラー利用可能（終了）",
      total_planned_weight: "計画された総重量",
      total_planned_volume: "計画された総ボリューム",
      arrival_at_first_dealer: "最初のディーラー到着",
      departure_at_last_dealer: "最後のディーラー出発",
      return_to_plant: "工場に戻る",
      summary_dashboard: "概要ダッシュボード",
      dispatch_plan_details: "配送計画の詳細",
      invalid_orders_note: "注意: 無効な注文は表示されていません",
      planned_data: "計画データ",
      vfr_percentage: "VFR割合",
      cool_chain_adherence: "コールドチェーン遵守",
      transit_time: "輸送時間",
      loading_time: "積載時間",
      unloading_time: "積み下ろし時間",
      planned_summary: "計画概要",
      invalid_drop_data: "無効な配達データ",
      vehicle_utilization_km: "車両利用率（KM）",
      distance_to_destination: "目的地までの距離",
      expected_delivery_date: "予想配達日",
      planned_vehicles: "計画された車両",
      trip_no: "旅行番号",
      total_quantity: "総数量",
      dealer_available_to: "ディーラー利用可能（終了）",
      drop_location_code_destination: "配達場所コード（目的地）",
      order_number: "注文番号",
      demand_volume_cft: "需要量（CFT）",
      weight_pounds: "重量（ポンド）",
      drop_location_name_destination: "配達場所名（目的地）",
      note_blank_rows_columns_not_allowed: "注: 空の行/列は許可されていません",
      check_blank_data_instructions:
        "空白のデータを確認するには: 1. [検索と選択] をクリックします。 2. [特別へ移動] をクリックします。 3. [空白] を選択します。 4. [OK] をクリックすると、すべての空の行/セルが強調表示されます。 5. [ホーム] タブの [セル] セクションで [削除] を選択します。 6. [シート行の削除] をクリックします。",
      disclaimer_dealer_window_ot_customers:
        "免責事項: OT顧客に対して利用可能なウィンドウは入力ファイルから考慮され、GT顧客の時間はピックアップ/ドロップ場所から考慮されます",
      quantity: "数量",
      specifications: "仕様",
      please_select_plan_id_to_compare_plans:
        "プランを比較するにはプランIDを選択してください",
      vehicle_forecast_summary_dashboard: "車両予測サマリーダッシュボード",
      pickup_point: "ピックアップポイント",
      view_details: "詳細を見る",
      compare_plans: "プランを比較",
      pickup_drop_points: "ピックアップ/ドロップポイント",
      edit: "編集",
      pickup: "ピックアップ",
      drop: "ドロップ",
      both: "両方",
      pickup_location_code: "ピックアップ場所コード",
      dealer_type: "ディーラータイプ",
      select: "選択",
      local: "ローカル",
      upcountry: "田舎",
      vehicle_restriction_list: "車両制限リスト",
      week_off: "週休",
      csp_day: "CSP日",
      is_customer_kdd: "顧客はKDDですか？",
      start_month: "開始月",
      end_month: "終了月",
      plan_id: "プランID",
      fecha_creada: "作成日",
      no_of_trucks_planned: "計画されたトラック数",
      total_planned_volume_cft: "計画された総容積（CFT）",
      total_planned_weight_pounds: "計画された総重量（ポンド）",
      plan_run_by: "プラン実行者",
      planned_vehicles: "計画された車両",
      trip_no: "旅行番号",
      total_planned_customers: "計画された顧客総数",
      total_round_trip_distance: "総往復距離",
      planning_percentage: "計画の割合",
      max_dispatch_time: "最大発送時間（HH:MM）",
      total_loading_time: "総積み込み時間（HH:MM）",
      total_unloading_time: "総荷降ろし時間（HH:MM）",
      planned_summary: "計画されたサマリー",
      invalid_orders_not_shown: "注: 無効な注文は表示されません",
      no_of_customers: "顧客数",
      no_of_orders: "注文数",
      total_demand_volume: "総需要量（CFT）",
      planned_summary: "計画されたサマリー",
      trips_removed: "削除された旅行",
      removed_transaction_ids: "削除された取引ID",
      vehicle_forecast_configurations: "車両予測設定",
      pickup_point: "ピックアップポイント",
      vehicle_type: "車両タイプ",
      vehicle_name: "車両名",
      cost_per_miles: "マイルあたりのコスト",
      driver_break_time: "ドライバーの休憩時間",
      view: "表示",
      please_select_no_of_vehicles: "車両数を選択してください",
      no_of_vehicles: "車両数",
      obd_analysis: "OBD分析",
      pickup_point: "ピックアップポイント",
      select_month: "月を選択",
      location_radius_miles: "場所の半径（マイル）",
      submit: "送信",
      total_obd_details: "総OBD詳細",
      valid_dealers: "有効なディーラー",
      invalid_dealers: "無効なディーラー",
      volume: "ボリューム",
      help: "ヘルプ",
      change_password: "パスワードを変更",
      logout: "ログアウト",
      weight_pounds: "重量（ポンド）",
      quantity: "数量",
      obd_details_within_radius: "半径内のOBD詳細",
      dealers: "ディーラー",
      dealers_percent: "ディーラーの割合",
      volume_percent: "ボリュームの割合",
      weight_pounds_percent: "重量の割合（ポンド）",
      quantity_percent: "数量の割合",
      cost_per_miles: "マイルあたりのコスト（$）",
      driver_break_time_from: "ドライバーの休憩時間（開始）",
      monthly_min_planning_distance_miles: "月間最小計画距離（マイル）",
      vehicle_type_count: "車両タイプの数",
      driver_break_time_per_day: "1日あたりのドライバーの休憩時間（HH:MM）",
      cost_per_miles_2: "マイルあたりのコスト（$）",
      set_vehicle_priority: "車両の優先順位を設定",
      vehicle_type_name: "車両タイプ名",
      min_volume_cft: "最小容積（CFT）",
      max_volume_cft: "最大容積（CFT）",
      max_distance_per_trip_miles: "1旅行あたりの最大距離（マイル）",
      select_plan_id: "プランIDを選択",
      drop_details: "配達の詳細",
      select_vehicle_type: "車両タイプを選択",
      select_transporter_name: "輸送業者名を選択",
      zone: "ゾーン",
      state: "州",
      driver_cost: "運転手のコスト ($)",
      maintenance_cost: "メンテナンスコスト ($)",
      fixed_vehicle_cost_label: "固定車両コスト ($)",
      delete: "削除",
      state: "州",
      city: "市",
      zone: "ゾーン",
      delta_warehouse: "デルタ倉庫",
      zones: "ゾーン",
      zone_mapping: "ゾーンマッピング",
      city_not_mapped_to_state: "この市は州にマッピングされていません",
      actions: "アクション",
      add_zones: "+ ゾーンを追加",
      transporter_master: "輸送業者マスター",
      transporter_configurations: "輸送業者構成",
      transporter_operations: "輸送業者の運行",
      transporter_code: "輸送業者コード",
      transporter_name: "輸送業者名",
      actions: "アクション",
      edit: "編集",
      delete: "削除",
      zone_configuration: "ゾーン構成",
      vehicle_checklist_for_transportation_requirements:
        "輸送要件のための車両チェックリスト",
      transporters_configuration: "輸送業者構成",
      consolidated_route_plan_screen: "統合されたルートプラン画面",
      plant_code: "工場コード",
      select_plant_to_check_vehicle_availability:
        "車両の可用性を確認する工場を選択",
      vehicle_availability: "車両の可用性",
      note_trucks_near_plant_vicinity: "注: 工場近くにあるトラックの距離 ",
      facility_name: "施設名",
      vehicle_number: "車両番号",
      transporter_code: "輸送業者コード",
      vehicle_status_dashboard: "車両ステータスダッシュボード",
      dc: "DC",
      drop_location_code: "配送場所コード",
      vehicle_utilization: "車両利用率",
      onward_trip_distance_miles: "片道距離 (マイル)",
      round_trip_miles: "往復距離 (マイル)",
      max_dispatch_time_hh_mm: "最大配送時間 (HH:MM)",
      arrival_at_first_dealer_start: "最初のディーラー到着 (開始)",
      departure_at_last_dealer_end: "最後のディーラー出発 (終了)",
      return_to_plant_hh_mm: "工場に戻る (HH:MM)",
      vehicle_number: "車両番号",
      vehicle_availability_from: "車両の可用性 (開始)",
      vehicle_availability_to: "車両の可用性 (終了)",
      break_time_from: "休憩時間 (開始)",
      break_time_to: "休憩時間 (終了)",
      no_of_vehicles: "車両の数",
      driver_break_time_from: "運転手の休憩時間 (開始)",
      max_distance_miles: "最大距離 (マイル)",
      monthly_min_planning_distance_miles: "月次最小計画距離 (マイル)",
      total_planned_trip_time_hrs: "総計画旅行時間 (時間)",
      total_round_trip_time_hrs: "総往復時間 (時間)",
      drop_location_code: "配送場所コード",
      drop_location_name: "配送場所名",
      drop_location_city: "配送場所市",
      order_number: "注文番号",
      delivery_date: "配送日 (M/D/YYYY)",
      back: "戻る",
      pickup_location: "ピックアップ場所",
      dealer_available_from: "ディーラーの利用可能時間 (開始)",
      dealer_available_to: "ディーラーの利用可能時間 (終了)",
      total_planned_weight: "総計画重量",
      total_planned_volume: "総計画体積",
      arrival_at_first_dealer: "最初のディーラー到着",
      departure_at_last_dealer: "最後のディーラー出発",
      return_to_plant: "工場に戻る",
      summary_dashboard: "概要ダッシュボード",
      dispatch_plan_details: "配送計画の詳細",
      invalid_orders_note: "注: 無効な注文は表示されていません",
      planned_data: "計画データ",
      vfr_percentage: "VFRパーセンテージ",
      cool_chain_adherence: "クールチェーンの順守",
      transit_time: "移動時間",
      loading_time: "積載時間",
      unloading_time: "積み下ろし時間",
      planned_summary: "計画概要",
      invalid_drop_data: "無効な配送データ",
      vehicle_utilization_km: "車両利用率 (KM)",
      distance_to_destination: "目的地までの距離",
      expected_delivery_date: "予想配送日",
      planned_vehicles: "計画された車両",
      trip_no: "旅行番号",
      total_quantity: "総量",
      dealer_available_to: "ディーラーの利用可能時間 (終了)",
      drop_location_code_destination: "配送場所コード (目的地)",
      order_number: "注文番号",
      demand_volume_cft: "需要体積 (CFT)",
      weight_pounds: "重量 (ポンド)",
      quantity: "数量",
      dropped_reason: "削除理由",
      delivery_date: "配送日",
      drop_location_name: "配送場所名",
      drop_location_city: "配送場所市",
      trip_no: "旅行番号",
      planned_day: "計画日",
      vehicle_no: "車両番号",
      vehicle_type: "車両タイプ",
      drop_sequence: "配送順序",
      invoicing_date: "請求日",
      pickup_location_source: "ピックアップ場所 (ソース)",
      destination_location_city: "目的地市",
      destination_location_state: "目的地州",
      distance_to_destination_miles: "目的地までの距離 (マイル)",
      expected_delivery_date: "予想配送日",
      time_to_destination: "目的地までの時間",
      transit_time_hh_mm: "移動時間 (HH:MM)",
      loading_time_hh_mm: "積載時間 (HH:MM)",
      unloading_time_hh_mm: "積み下ろし時間 (HH:MM)",
      total_planned_customers: "計画された顧客の総数",
      total_round_trip_distance_km: "総往復距離 (KM)",
      planning_percentage: "計画パーセンテージ",
      cool_chain_adherence_percentage: "クールチェーン順守率",
      total_transit_time_hh_mm: "総移動時間 (HH:MM)",
      total_planned_weight_pounds: "総計画重量 (ポンド)",
      total_planned_volume_cft: "総計画体積 (CFT)",
      arrival_at_first_dealer_start: "最初のディーラー到着 (開始)",
      departure_at_last_dealer_end: "最後のディーラー出発 (終了)",
      return_to_plant_hh_mm: "工場に戻る (HH:MM)",
      total_planned_trip_time_hrs: "総計画旅行時間 (時間)",
      total_round_trip_time_hrs: "総往復時間 (時間)",
      total_loading_time_hh_mm: "総積載時間 (HH:MM)",
      total_unloading_time_hh_mm: "総積み下ろし時間 (HH:MM)",
      vehicle_utilization_percentage: "車両利用率 (%)",
      view_order_information: "注文情報を表示",
      vehicle_configuration: "車両構成",
      summary: "概要",
      planned_summary: "計画された概要",
      planned_vehicles: "計画された車両",
      unplanned_loads: "計画外の荷物",
      invalid_drop_data: "無効なドロップデータ",
      vehicle_utilization_km: "車両利用率 (KM)",
      planned_data: "計画されたデータ",
      invalid_orders_note: "注: 無効な注文は表示されていません",
      set_vehicle_priority: "車両優先度を設定",
      vehicle_min_weight_pounds: "車両の最小重量 (ポンド)",
      vehicle_max_weight_pounds: "車両の最大重量 (ポンド)",
      vehicle_min_volume_cft: "車両の最小体積 (CFT)",
      vehicle_max_volume_cft: "車両の最大体積 (CFT)",
      max_distance_miles: "最大距離 (マイル)",
      vehicle_priority: "車両の優先度",
      actions: "アクション",
      forecast_order_plan: "予測注文計画",
      drop_details: "ドロップ詳細",
      month: "月",
      year: "年",
      total_quantity: "総数量",
      total_weight_pounds: "総重量 (ポンド)",
      total_demand_volume_cft: "総需要体積 (CFT)",
      user: "ユーザー",
      uploaded_date: "アップロード日",
      view_order_details: "注文詳細を見る",
      pick_up_location_code: "ピックアップ場所コード",
      quantity: "数量",
      drop_location: "ドロップ場所",
      drop_location_name: "ドロップ場所名",
      weight: "重量",
      volume: "体積",
      delivery_date: "納品日",
      invoice_date: "請求日",
      order_no: "注文番号",
      available_start_time: "利用可能開始時間",
      available_end_time: "利用可能終了時間",
      upload_input_file: "入力ファイルをアップロード",
      download_sample_template: "サンプルテンプレートをダウンロード",
      select_month: "月を選択",
      upload_file: "ファイルをアップロード",
      save: "保存",
      cancel: "キャンセル",
      note_blank_rows_columns_not_allowed:
        "注: 空白の行/列は許可されていません",
      no_of_vehicles: "車両数",
      run_plan: "計画を実行",
      vehicle_types: "車両タイプ",
      upload_file: "ファイルをアップロード",
      pickup_point: "ピックアップポイント",
      consolidated_route_plan_screen: "統合されたルート計画画面",
      start_date: "開始日",
      end_date: "終了日",
      get_data: "データを取得",
      recent_plans: "最近の計画",
      plan_history: "計画履歴",
      view_input: "入力を見る",
      consolidation_id: "統合ID",
      created_date: "作成日",
      run_by: "実行者",
      no_of_executions: "実行回数",
      execution_status: "実行ステータス",
      download_output: "出力をダウンロード",
      upload_input_file: "入力ファイルをアップロード",
      choose_file: "ファイルを選択",
      discard_obd: "OBDを破棄",
      upload_obd_details: "OBDの詳細をアップロード",
      upload_file: "ファイルをアップロード",
      submit: "送信",
      download_template: "テンプレートをダウンロード",
      bulk_upload_locations: "場所を一括アップロード",
      add_pickup_drop_points: "+ピックアップ/ドロップポイントを追加",
      details: "詳細",
      date: "日付",
      total_trips: "総トリップ数",
      trips_edited: "編集されたトリップ",
      trips_removed: "削除されたトリップ",
      trips_edit_percentage: "編集されたトリップの割合",
      plans_overview_dashboard: "計画概要ダッシュボード",
      pickup_point: "ピックアップポイント",
      location_type: "場所のタイプ",
      location_code: "場所コード",
      location_name: "場所名",
      location_city: "場所の都市",
      zone: "ゾーン",
      customer_type: "顧客タイプ",
      location_latitude: "場所の緯度",
      location_longitude: "場所の経度",
      standard_transit_time: "標準輸送時間 (日)",
      distance_from_plant: "工場からの距離 (マイル)",
      delta_warehouse: "デルタ倉庫",
      home: "ホーム",
      run_new_plan: "新しい計画を実行",
      stacking: "スタッキング",
      production_planning: "生産計画",
      forecasting: "予測",
      resource_scheduling: "資源スケジューリング",
      general: "一般",
      pickup_drop_locations: "ピックアップ/ドロップ場所",
      vehicle_configurations: "車両設定",
      vehicle_availability: "車両の可用性",
      security_approved: "セキュリティ承認済み",
      security_date_of_assessment: "セキュリティ評価日",
      view: "表示",
      location_code: "場所コード",
      vehicle_no: "車両番号",
      stage: "ステージ",
      quality_rejected: "品質が拒否されました",
      requirements_set: "要件が設定されました",
      security_rejected: "セキュリティ拒否",
      security_accepted: "セキュリティ承認",
      assessment_date: "評価日",
      requirement: "要件",
      satisfied: "満足",
      yes: "はい",
      no: "いいえ",
      requirement_set_date: "要件設定日",
      temperature: "温度",
      vehicle_operational_from: "車両の運用開始日",
      vehicle_operational_to: "車両の運用終了日",
      break_time_from: "休憩時間（開始）",
      break_time_to: "休憩時間（終了）",
      transporter_name: "運送業者の名前",
      last_packet_time: "最後のパケット時間",
      temperature: "温度",
      view_vehicles: "車両を見る",
      transporter_name: "運送業者の名前",
      max_distance_per_plan_miles: "計画あたりの最大距離（マイル）",
      remaining_monthly_distance_miles: "残りの月間距離（マイル）",
      vehicle_next_available_date: "次の利用可能な車両日",
      owned: "所有",
      contract: "契約",
      high: "高",
      medium: "中",
      low: "低",
      market: "市場",
      vehicles_for_today: "今日の車両",
      bulk_upload_master_vehicles: "マスタービークルの一括アップロード",
      add_master_vehicle: "マスタービークルを追加",
      transporter_name: "運送業者の名前",
      transit_type: "輸送タイプ",
      actions: "アクション",
      facility_name: "施設名",
      vehicle_type: "車両タイプ",
      vehicle_number: "車両番号",
      vehicle_priority: "車両の優先順位",
      max_distance_per_plan_miles: "計画あたりの最大距離（マイル）",
      max_weight_pounds: "最大重量（ポンド）",
      max_volume_cft: "最大容積（CFT）",
      monthly_min_planning_distance_miles: "月間最小計画距離（マイル）",
      remaining_monthly_planning_distance_miles: "残りの月間計画距離（マイル）",
      next_available_date_yyyy_mm_dd_hh_mm:
        "次の利用可能日（YYYY-MM-DD HH:MM）",
      vehicle_break_down: "車両の故障",
      drop_location_code: "配送場所コード",
      arrival_at_first_dealer: "最初のディーラー到着",
      departure_at_last_dealer: "最後のディーラー出発",
      average_payload: "平均積載量（トン）",
      mileage: "燃費（マイル/ガロン）",
      fuel_type: "燃料タイプ",
      temperature_controlled: "温度管理",
      load_characteristics: "荷物の特性",
      glec_vehicle_type: "GLEC車両タイプ",
      return_to_plant: "工場に戻る",
      summary_dashboard: "概要ダッシュボード",
      dispatch_plan_details: "配車計画の詳細",
      invalid_orders_note: "注: 無効な注文は表示されていません",
      pickup_point: "ピックアップポイント",
      vehicle_type_configuration: "車両タイプの設定",
      vehicle_type: "車両タイプ",
      vehicle_type_name: "車両タイプ名",
      min_volume: "最小容積（CFT）",
      max_volume: "最大容積（CFT）",
      vehicle_priority: "車両の優先順位",
      min_weight: "最小重量（ポンド）",
      max_weight: "最大重量（ポンド）",
      max_distance_trip: "最大距離（旅行あたり、マイル）",
      monthly_min_planning_distance: "月間最小計画距離（マイル）",
      cancel: "キャンセル",
      save: "保存",
      facility_name: "施設名",
      vehicle_no: "車両番号",
      address: "住所",
      last_packet_time: "最後のパケット時間",
      latitude: "緯度",
      longitude: "経度",
      service_provider: "サービスプロバイダー",
      temperature: "温度",
      on_trip: "旅行中",
      requirement: "要件",
      requirement_type: "要件のタイプ",
      security: "セキュリティ",
      assessed_by: "評価者",
      security_conclusion: "セキュリティの結論",
      security_comments: "セキュリティのコメント",
      assessment_start_date: "評価開始日",
      assessment_end_date: "評価終了日",
      get_data: "データを取得",
      add_requirements: "要件を追加",
      add_vehicle_checklist: "車両チェックリストを追加",
      actions: "アクション",
      edit: "編集",
      delete: "削除",
      vehicle_configurations: "車両構成",
      vehicle_availability_configurations: "車両の可用性構成",
      vehicle_location: "車両の場所",
      vehicle_master: "車両マスター",
      vehicle_availability: "車両の可用性",
      vehicle_details: "車両の詳細",
      monthly_min_distance: "月間最小距離（マイル）",
      max_weight_per_trip: "1回の旅行あたりの最大重量（ポンド）",
      max_distance_per_trip: "1回の旅行あたりの最大距離（マイル）",
      planned_data: "計画されたデータ",
      vfr_percentage: "VFR割合",
      cool_chain_adherence: "コールドチェーンの遵守",
      transit_time: "輸送時間",
      loading_time: "積載時間",
      unloading_time: "積み下ろし時間",
      planned_summary: "計画概要",
      invalid_drop_data: "無効な配達データ",
      vehicle_utilization_km: "車両利用率（KM）",
      distance_to_destination: "目的地までの距離",
      expected_delivery_date: "予想配達日",
      trip_no: "旅行番号",
      total_quantity: "総数量",
      demand_volume_cft: "需要量（CFT）",
      weight_pounds: "重量（ポンド）",
      quantity: "数量",
      dropped_reason: "廃棄理由",
      delivery_date: "配達日",
      drop_location_city: "配達場所の都市",
      planned_day: "計画日",
      vehicle_no: "車両番号",
      drop_sequence: "配達順序",
      invoicing_date: "請求日",
      pickup_location_source: "ピックアップ場所（出発地）",
      destination_location_city: "目的地の都市",
      destination_location_state: "目的地の州",
      distance_to_destination_miles: "目的地までの距離（マイル）",
      time_to_destination: "目的地までの時間",
      dealer_available_from: "ディーラー利用可能（開始）",
      dealer_available_to: "ディーラー利用可能（終了）",
      total_planned_weight: "計画された総重量",
      total_planned_volume: "計画された総ボリューム",
      arrival_at_first_dealer_start: "最初のディーラー到着（開始）",
      departure_at_last_dealer_end: "最後のディーラー出発（終了）",
      return_to_plant_hh_mm: "工場に戻る（HH:MM）",
      total_planned_trip_time_hrs: "計画された総旅行時間（時間）",
      total_round_trip_time_hrs: "総往復時間（時間）",
      total_loading_time_hh_mm: "総積載時間（HH:MM）",
      total_unloading_time_hh_mm: "総積み下ろし時間（HH:MM）",
      vehicle_utilization_percentage: "車両利用率（％）",
      view_order_information: "注文情報を表示",
      vehicle_configuration: "車両構成",
      summary: "概要",
      planned_summary: "計画概要",
      planned_vehicles: "計画された車両",
      unplanned_loads: "未計画の荷物",
      invalid_drop_data: "無効な配達データ",
      set_vehicle_priority: "車両の優先順位を設定",
      forecast_order_plan: "予測注文計画",
      volume_cft: "ボリューム（CFT）",
      dealer_available_time_error:
        "開始時間は終了時間よりも早くする必要があります",
      ok: "OK",
      get_data: "データを取得",
      plan_history: "計画履歴",
      upload_file: "ファイルをアップロード",
      save: "保存",
      cancel: "キャンセル",
      delete: "削除",
      plant_name: "プラント名",
      total_trips: "総旅行数",
      trips_edited: "編集された旅行",
      vfr_percentage: "VFR割合",
      total_transit_time_hh_mm: "総輸送時間（HH）",
      drop_location_name_destination: "配達場所名（目的地）",
      unloading_time_hh_mm: "積み下ろし時間（HH:MM）",
      edited_transaction_ids: "編集されたトランザクションID",
      vehicle_type: "車両タイプ",
      vehicle_name: "車両名",
      weight_pounds: "重量（ポンド）",
      plant_code: "プラントコード",
      plant_name: "プラント名",
      total_trips: "総旅行数",
      trips_edited: "編集された旅行",
      last_30_days_trips_modification_details: "過去30日間の旅行変更の詳細",
      last_7_days_trips_modification_details: "過去7日間の旅行変更の詳細",
      planning_progress_past_7_weeks: "過去7週間の計画進捗",
      edited_transaction_ids: "編集されたトランザクションID",
      trips_removed: "削除された旅行",
      removed_transaction_ids: "削除されたトランザクションID",
      drop_location_city: "配達場所の都市",
      quantity: "数量",
      dropped_reason: "廃棄理由",
      delivery_date: "配達日",
      drop_location_name: "配達場所名",
      drop_location_city: "配達場所の都市",
      trip_no: "旅行番号",
      planned_day: "計画日",
      vehicle_no: "車両番号",
      vehicle_type: "車両タイプ",
      drop_sequence: "配達順序",
      invoicing_date: "請求日",
      pickup_location_source: "ピックアップ場所（ソース）",
      destination_location_city: "目的地の都市",
      destination_location_state: "目的地の州",
      distance_to_destination_miles: "目的地までの距離（マイル）",
      expected_delivery_date: "予想配達日",
      time_to_destination: "目的地までの時間",
      invalid_material: "無効な材料",
      drop_location_code_destination: "配達場所コード（目的地）",
      demand_volume_cft: "需要容積（CFT）",
      dropped_reason: "廃棄理由",
      dealer_location_data_not_in_system:
        "ディーラーの場所データがシステムにありません",
      delivery_date_less_than_current: "配達日が現在の日付より前です",
      missing_dealer_information: "ディーラー情報が不足しています",
      transit_time_hh_mm: "輸送時間（HH:MM）",
      loading_time_hh_mm: "積み込み時間（HH:MM）",
      unloading_time_hh_mm: "荷降ろし時間（HH:MM）",
      actions: "アクション",
      expected_delivery_date: "予想配達日",
      max_dispatch_time: "最大配送時間（HH:MM）",
      unplanned_loads: "計画外の荷物",
      monthly_data: "月間データ",
      forecast_plan_history: "予測プラン履歴",
      obd_analysis: "OBD分析",
      dealer_available_time_error:
        "開始時刻は終了時刻より早くなければなりません",
    },
  },
  zh: {
    translation: {
      vehicles_recommended: "推荐车辆",
      "route_type": "路线类型",
      "Number Of Trips": "行程数量",
      "Day-2": "第2天",
  "Day-3": "第3天",
  "Day-4": "第4天",
  "Day-5": "第5天",
  "Day-6": "第6天",
  "Distance / Time From Pickup Location": "从取货地点的距离/时间",
  "Distance / Time From Previous Drop Point": "从上一个卸货点的距离/时间",
  "Location City": "位置城市",
  "Planned Day": "计划日",
  "Quantity": "数量",
  "Demand Weight": "需求重量",
  "Demand Volume": "需求量",
  "Location": "位置",
  "Weight": "重量",
  "View Onward Route": "查看前行路线",
  "View Return Route": "查看回程路线",
      "Quantity": "数量",
  "Weight": "重量",
  "Spot": "现场",
  "Local": "本地",
  "Upcountry": "上乡",
  "Volume": "体积",
  "Total": "总数",
  "IN416": "IN416",
  "IN436": "IN436",
  "Spot": "现场",
  "2,177": "2,177",
  "1,268": "1,268",
  "909": "909",
  "0": "0",
  "Planned Truck Load": "计划卡车载重",
  "Quantity (cases)": "数量 (箱)",
  "Weight (Kgs)": "重量 (千克)",
  "Volume (CMT)": "体积 (CMT)",
  "1,81,970": "1,81,970",
  "25,25,554": "25,25,554",
  "8,249": "8,249",
  "Unplanned Load": "未计划载重",
  "26,324": "26,324",
  "3,74,549": "3,74,549",
  "1,234": "1,234",
  "Average Distance Travelled Per Vehicle (KM)": "每辆车的平均行驶距离 (公里)",
  "35,426": "35,426",
  "37,624": "37,624",
  "28,111": "28,111",
  "Operational Cost (Rs.)": "运营成本 (卢比)",
  "1,62,57,137": "1,62,57,137",
  "1,31,64,341": "1,31,64,341",
  "30,92,797": "30,92,797",
  "Average Vehicle Fill Rate": "车辆平均填充率",
  "60 %": "60 %",
  "42 %": "42 %",
  "79 %": "79 %",
  "Unplanned Load Proportion": "未计划载重比例",
  "12 %": "12 %",
  "Average Vehicle Utilization As Per SOB": "根据 SOB 的平均车辆利用率",
  "104 %": "104 %",
  "94 %": "94 %",
  "Ontime Dispatches": "按时派遣",
  "Local": "本地",
  "frefe": "frefe",
  "94 %": "94 %",
  "92 %": "92 %",
  "Ontime Deliveries": "按时交付",
  "97 %": "97 %",
  "94 %": "94 %",
      "Number of kms Travelled": "行驶的公里数",
      "Please Select Scenario to Compare Plans": "请选择方案以比较计划",
"Percentage": "百分比",
"Forecast Scenario": "预测场景",
"Cost": "成本",
"Vehicle Fill Rate": "车辆装满率",
"Vehicle KM Run %": "车辆公里运行 %",
      "Please Select File": "请选择文件",
"Processing Plan. Please wait.....": "处理计划中，请稍候.....",
"File is processing": "文件正在处理中",
"Enter Input Data To Continue": "输入数据以继续",
"No Data": "无数据",
"please_select_date": "请选择日期",
"data_uploaded": "数据已上传",
"Please Select No of Vehicles": "请选择车辆数量",
"Please Input Valid Data": "请输入有效数据",
      "change_password": "更改密码",
      "driver_break_time_to": "司机休息时间到",
"max_seating_capacity": "最大座位容量",
"min_seating_capacity": "最小座位容量",
"vehicle_seating_capacity": "车辆座位容量",
"vehicle_capacity_cmt": "车辆容量 (立方米)",
  "new_password": "新密码",
  "confirm_new_password": "确认新密码",
  "old_password": "旧密码",
  "enter_email": "输入你的电子邮件",
  "enter_new_password": "输入你的新密码",
  "confirm_new_password_entry": "确认你的新密码",
      max_distance_per_plan_miles: "每计划最大距离（英里）",
      "loading....": "加载中....",
  "select_cluster": "选择集群",
  "Overall Cluster Wise Vehicles": "按集群分组的车辆",
  "Discard": "丢弃",
  "obd": "OBD",
  "Dispatch Planning is in progress..!!!": "调度规划正在进行中..!!!",
  "Estimated Time": "预计时间",
  "GPS": "GPS",
  "Spot Vehicles To Be Planned": "待规划的现用车辆",
  "No Of Spot Vehicles": "现用车辆数量",
  "Availability DateTime": "可用日期时间",
  "Notify Email Users After Process (comma seperated)": "处理后通知电子邮件用户（逗号分隔）",
  "Max Time Limit(Hrs)": "最大时间限制（小时）",
  "vfr": "VFR",
  "Disclaimer": "免责声明：OT客户的经销商可用时间窗口将从输入文件中考虑，GT客户的时间将从取货/交货地点考虑。",
  "To Check for Blank Data": "检查空数据步骤：1. 点击“查找和选择”。2. 点击“转到特殊”。3. 选择“空白”。4. 点击“确定”，所有空白行/单元格将被突出显示。5. 在主页选项卡的单元格部分选择“删除”。6. 点击“删除工作表行”。",
  "Add New OBD": "添加新OBD",
  "All drop locations are invalid, Please check..!!": "所有投递地点无效，请检查..!!",
  "Please upload file having extensions .csv or .xlsx only.": "请仅上传扩展名为.csv或.xlsx的文件。",
  "Dispatch Load Volume Summary (CFT)": "调度负载体积汇总（CFT）",
  "Total Loads Volume (CFT)": "总负载体积（CFT）",
  "Planned Loads Volume (CFT)": "计划负载体积（CFT）",
  "Unplanned Loads Volume (CFT)": "未计划负载体积（CFT）",
  "Dropped Loads Volume (CFT)": "丢弃负载体积（CFT）",
  "Dispatch Load Weight Summary (Pounds)": "调度负载重量汇总（磅）",
  "Total Loads Weight (Pounds)": "总负载重量（磅）",
  "Planned Loads Weight (Pounds)": "计划负载重量（磅）",
  "Unplanned Loads Weight (Pounds)": "未计划负载重量（磅）",
  "Dropped Loads Weight (Pounds)": "丢弃负载重量（磅）",
  "Dispatch Load Quantity Summary": "调度负载数量汇总",
  "Total Quantity": "总数量",
  "Planned Quantity": "计划数量",
  "Unplanned Quantity": "未计划数量",
  "Dropped Quantity": "丢弃数量",
  "Dispatch Load Volume Summary (CFT)": "调度负载体积汇总（CFT）",
  "Total Loads Volume (CFT)": "总负载体积（CFT）",
  "Planned Loads Volume (CFT)": "计划负载体积（CFT）",
  "Unplanned Loads Volume (CFT)": "未计划负载体积（CFT）",
  "Dropped Loads Volume (CFT)": "丢弃负载体积（CFT）",
  "Dispatch Load Weight Summary (Pounds)": "调度负载重量汇总（磅）",
  "Total Loads Weight (Pounds)": "总负载重量（磅）",
  "Planned Loads Weight (Pounds)": "计划负载重量（磅）",
  "Unplanned Loads Weight (Pounds)": "未计划负载重量（磅）",
  "Dropped Loads Weight (Pounds)": "丢弃负载重量（磅）",
  "Dispatch Load Quantity Summary": "调度负载数量汇总",
  "Total Quantity": "总数量",
  "Planned Quantity": "计划数量",
  "Unplanned Quantity": "未计划数量",
  "Dropped Quantity": "丢弃数量",
  "Dispatch Load Weight Summary (Pounds)": "调度负载重量汇总（磅）",
  "Dispatch Load Volume Summary (CFT)": "调度负载体积汇总（CFT）",
  "Dropped Data": "丢弃数据",
  "ontime_delivery": "准时交付",
  "dealer_Available_start_time_should_be_less_than_end_time": "经销商的开始时间应小于结束时间",
  "Removed": "已删除",
  "Unedited": "未编辑",
  "Edited": "已编辑",
      vehicle_availability_configurations: "车辆可用性配置",
    add_city_wise_cost: "添加按城市划分的成本",
    add_zone_wise_cost: "添加按区域划分的成本",
    scenarios: "场景",
      plant: "工厂",
      standard_transit_time_days: "标准运输时间（天）",
    drop_to_time: "送达时间",
    drop_from_time: "发车时间",
    pickup_to_time: "取货时间",
    pickup_from_time: "取货起始时间",
    distance_from_plant_miles: "距工厂距离（英里）",
    vehicle_next_available_date: "车辆下次可用日期",
    max_distance_per_plan_miles: "每计划最大距离（英里）",
    remaining_monthly_distance_miles: "每月剩余距离（英里）",
    assign_quality_checks: "分配质量检查",
    add_fixed_vehicle_cost: "添加固定车辆成本",
      customer: "客户",
      "Pickup Locations (Drop)*": "取货地点（下车）*",
      "pickup_locations_drop*": "取货地点（下车）*",
      duplicate_obds_light_red: "重复的 OBD 显示为浅红色",
      new_obds_green: "新添加的 OBD 显示为绿色",
      pickup_locations_drop: "取货地点（下车）",
      invalid_orders_not_shown: "无效订单未显示",
    note: "注意",
      percent_unplanned: "未计划百分比",
    start_month: "开始月份",
    please_select_scenario: "请选择情景以比较计划",
    avg_kms_travelled: "平均行驶公里数",
    total_cost: "总成本",
    total_vehicle_fill_rate: "车辆总填充率",
    percent_km_run_sob: "SOB 跑公里百分比",
    percent_ontime_dispatch: "准时发货百分比",
    upcountry: "内地",
    percent_spot_vehicles_trips: "临时车辆行程百分比",
    round_trip_distance: "往返距离",
    total_trip_distance: "总行程距离",
    vehicle_type: "车辆类型",
    plant_code: "工厂代码",
    vehicle_no: "车辆编号",
    monthly_sob_km: "月度 SOB (公里)",
    distance_travelled_km: "行驶距离 (公里)",
    pickup_location_source: "取货地点（来源）",
    drop_location_code_destination: "投放地点代码（目的地）",
    drop_location_name_destination: "投放地点名称（目的地）",
    order_number: "订单号",
    invoicing_date: "开票日期",
    quantity: "数量",
    weight_kgs: "重量 (公斤)",
    demand_volume_m3: "需求体积 (M3)",
    dealer_available_from: "经销商可用时间从",
    dealer_available_to: "经销商可用时间到",
    delivery_date: "交货日期",
    trip_no: "行程号",
    planned_day: "计划日",
    drop_sequence: "投放顺序",
    destination_location_city: "目的地城市",
    destination_location_state: "目的地州",
    volume_m3: "体积 (M3)",
    distance_to_destination_kms: "到目的地的距离 (公里)",
    expected_delivery_date: "预计交货日期",
    time_to_destination: "到达目的地的时间",
    transit_time_hhmm: "运输时间 (时:分)",
    loading_time_hhmm: "装载时间 (时:分)",
    unloading_time_hhmm: "卸载时间 (时:分)",
    end_month: "结束月份",
    get_data: "获取数据",
    select_chart_type: "选择图表类型",
    fleet_analysis: "车队分析",
    system_recommended: "推荐系统",
    total_load: "总负载",
    serviceability: "可用性",
    in416: "IN416",
    in436: "IN436",
    user_recommended: "推荐用户",
    threshold: "阈值",
    number_of_trips: "行程数量",
    total: "总计",
    spot: "点",
    planned_truck_load: "计划卡车负载",
    quantity_cases: "数量（箱）",
    weight_kgs: "重量（千克）",
    volume_cmt: "体积（CMT）",
    unplanned_load: "未计划负载",
    average_distance_travelled_per_vehicle_km: "每辆车的平均行驶距离（KM）",
    operational_cost_rs: "运营成本（Rs）",
    average_vehicle_fill_rate: "车辆平均填充率",
    unplanned_load_proportion: "未计划负载比例",
    average_vehicle_utilization_as_per_sob: "根据SOB的车辆平均利用率",
    ontime_dispatches: "准时派遣",
    local: "本地",
    upcountry: "上乡",
    ontime_deliveries: "准时交付",
    bar: "条",
    load: "负载",
    month: "月",
    percentage: "%",







      //code for configurations screen

      "Consider Transporter SOB": "考虑运输商 SOB",
      compare: "比较",
      forecast_summary: "预测摘要",
    pickup_locations_drop: "取货地点（下车）",
      "note_highlighted_rows_red": "注意：1. 红色高亮的行缺少 GPS 数据或集成，请手动标记车辆可用性。",
      "highlighted_rows_green": "2. 绿色高亮的行被视为计划中的车辆",
      "enable_reconciliation": "启用对账",
      "enable_dc_week_off_planning": "启用数据中心休息周规划",
      enable_disable: "启用/禁用",
      "Consider Dropping Distant Smaller Loads": "考虑放弃远距离小负载",
      "Consider Realtime Vehicle Available Times": "考虑车辆的实时可用时间",
      "Plant Wise Vehicle Type Configuration*": "按工厂配置的车辆类型*",
      "Consignees / Dealers Locations (Drop)*": "收货人/经销商位置（投放）*",
      "Pickup Locations (Pickup)*": "取货位置（取货）*",
      "Optimization Type*": "优化类型*",
      "Plant Wise Planning History*": "按工厂的计划历史*",
      "Plant Wise Plan Summary*": "按工厂的计划摘要*",
      "Plant Wise New Plan Creation*": "按工厂创建新计划*",
      "Suppliers / Vendor Locations (Pickup)*": "供应商/供应商位置（取货）*",
      "Plant Wise Vehicle Type Configuration*": "按工厂配置的车辆类型*",
      "Consignees / Dealers Locations (Drop)*": "收货人/经销商位置（投放）*",
      "Pickup Locations (Pickup)*": "取货位置（取货）*",
      "Plant Wise Vehicle Type Configuration*": "按工厂配置的车辆类型*",
      "Suppliers / Vendor Locations (Pickup)*": "供应商/供应商位置（取货）*",
      "Plant Wise Planning History*": "按工厂的计划历史*",
      "Plant Wise Plan Summary*": "按工厂的计划摘要*",
      "Plant Wise New Plan Creation*": "按工厂创建新计划*",
      "Enable/Disable Dispatch Planning Module": "启用/禁用派遣计划模块",
      Inbound: "入站",
      Outbound: "出站",
      "Suppliers / Vendor Locations (Pickup)": "供应商/供应商位置（取货）",
      "Operational Hours": "运营时间",
      "Pickup Locations (Drop)": "取货位置（投放）",
      "Plant Wise Vehicle Type Configuration": "按工厂配置的车辆类型",
      "Max No. Of Vehicle Types": "最大车辆类型数",
      "Vehicle Load Capacity Min / Max Configuration Preferences":
        "车辆载重量最小/最大配置偏好",
      "Vehicle Load Weight Min / Max Preferences": "车辆载重量最小/最大偏好",
      "Distance preferences": "距离偏好",
      "Vehicle Priorities": "车辆优先级",
      "Vehicle Operational Times": "车辆运行时间",
      "Vehicle Availability": "车辆可用性",
      "Plant Wise Freight Configuration": "按工厂配置的货运",
      "Plant Wise Transporter Configuration": "按工厂配置的运输商",
      "Route Preferences": "路线偏好",
      "Plant Wise New Plan Creation": "按工厂创建新计划",
      "Plant Wise Plan Summary": "按工厂的计划摘要",
      "Plant Wise Planning History": "按工厂的计划历史",
      "No of Vehicles Types": "车辆类型数",
      "Generic Time Windows": "通用时间窗口",
      "Individual Vehicle Time Windows": "单个车辆的时间窗口",
      "Availability By Vehicle Type": "按车辆类型的可用性",
      "Availability By Individual Vehicle": "按单个车辆的可用性",
      "Monthly Minimum Planning Distance Preferences": "每月最小规划距离偏好",
      "GPS Based Availability": "基于GPS的可用性",
      "Truck Location Visibility": "卡车位置可见性",
      "Plant Vicinity Circle Radius": "工厂附近的圆半径",
      "Fixed Vehicle Costs": "固定车辆成本",
      "Variable Costs": "可变成本",
      "Vehicle Type Level Variable Costs": "车辆类型级别的可变成本",
      "City Level Vehicle Type Costs": "城市级别的车辆类型成本",
      "Per CFT Cost": "每CFT成本",
      "Per Mile Cost": "每英里成本",
      "Zone Level Cost": "区域级别成本",
      "Transporter Share of Business": "运输商的业务份额",
      "Sustainability Integration": "可持续性集成",
      "Optimization Type": "优化类型",
      "Cost Optimization": "成本优化",
      "Weight based optimization": "基于重量的优化",
      "Distance based optimization": "基于距离的优化",
      "Time based optimization": "基于时间的优化",
      "Volume based optimization": "基于体积的优化",
      "Prioritize Local Loads": "优先本地负载",
      "Radius Miles": "半径（英里）",
      "Number of Loading Docks": "装卸码头数量",
      "City Based Entry Restriction": "基于城市的进入限制",
      "Unloading time per case/max unloading time":
        "每个箱子的卸货时间/最大卸货时间",
      "Consider Vehicle Break Times (Night Driving)":
        "考虑车辆的休息时间（夜间驾驶）",
      "Consider Holiday Restrictions": "考虑节假日限制",
      "Vehicle Restriction": "车辆限制",
      "Plan Spot Vehicles": "计划现场车辆",
      "Max No of Customers Per Vehicle": "每辆车的最大客户数",
      "Use Dynamic Planning Window": "使用动态计划窗口",
      "Number of Days to Use Planning Window": "使用计划窗口的天数",
      "Use Volume for Vehicle Fill Rate (VFR)": "使用体积计算车辆填充率(VFR)",
      "Use Weight for Vehicle Fill Rate (VFR)": "使用重量计算车辆填充率(VFR)",
      "Min VFR Per Vehicle Type": "每种车辆类型的最小VFR",
      "Min VFR Per Spot Vehicle": "每种现场车辆的最小VFR",
      "Prioritize Same Dealers First in a Vehicle":
        "优先在车辆中安置相同经销商",
      "Prioritize Multi City Dealers First in a Vehicle":
        "优先在车辆中安置多城市经销商",
      "Dispatch within Days": "在几天内调度",
      "Allow Unplanned Loads": "允许未计划的负载",
      "Minimize Number of Vehicles Used": "最小化使用的车辆数量",
      "Dealer Buffer Time": "经销商缓冲时间",
      "Buffer Time(Mins)": "缓冲时间（分钟）",
      "Month End Planning Start Day": "月底计划开始日",
      "Day of Every Month": "每个月的某一天",
      "Max Local No of Customers Per Vehicle": "每辆车的最大本地客户数",
      "Max Upcountry No of Customers Per Vehicle": "每辆车的最大农村客户数",
      "Planning Type": "计划类型",
      "Local Prioritization": "本地优先",
      "Local Prioritization and Virtual Upcountry clubbing":
        "本地优先和虚拟农村整合",
      "General Plan (Local and Upcountry clubbing)":
        "一般计划（本地和农村整合）",
      "Telescopic Deviation": "伸缩偏差",
      "MOQ for Planning": "计划的最小起订量",
      Reconciliation: "对账",
      "DC Week Off planning": "DC周计划休息",
      "Save Inbound Configurations": "保存入站配置",
      "Save Outbound Configurations": "保存出站配置",
      SAVE: "保存",
      "Enable Reconciliation": "启用对账",
      "Enable DC Week Off planning": "启用DC周休计划",
      "Deviation Alert": "偏差警报",
      "Overload Warning": "超载警告",
      "Underload Alert": "负载不足警报",
      "Fuel Efficiency Report": "燃料效率报告",
      "Vehicle Downtime": "车辆停机时间",
      "Speed Violation Alert": "超速警报",
      "Trip Delay Notification": "行程延误通知",
      "Idle Time Monitoring": "空转时间监控",
      "Driver Performance": "驾驶员表现",
      "Realtime GPS Tracking": "实时GPS跟踪",
      "Fleet Utilization": "车队利用率",
      "Trip Efficiency": "行程效率",
      "Dynamic Route Adjustment": "动态路线调整",
      "Load Distribution Optimization": "负载分配优化",
      "Transporter Preferences": "运输商偏好",
      "Trip Schedule": "行程计划",
      "Driver Assignment": "驾驶员分配",
      "Optimize Vehicle Utilization": "优化车辆利用率",
      "Time Based Planning": "基于时间的计划",
      "Plant Wise Route Planning": "按工厂的路线规划",
      "Vehicle Rotation Configuration": "车辆轮换配置",
      "Inbound Vehicle Preferences": "入站车辆偏好",
      "Outbound Vehicle Preferences": "出站车辆偏好",
      "Vehicle Fill Rate Optimization": "车辆填充率优化",
      "Dynamic Load Allocation": "动态负载分配",
      "Start Date": "开始日期",
      "Run Plan": "运行计划",
      "Completion Date": "完成日期",
      "Transporter Name": "运输商名称",
      "Driver Allocation": "驾驶员分配",
      "Max Load Capacity": "最大负载容量",
      "Estimated Delivery Time": "预计交货时间",
      "Use Real-Time Traffic Data": "使用实时交通数据",
      "Driver Break Times": "驾驶员休息时间",
      "Max Vehicle Load Percentage": "车辆的最大负载百分比",
      "Telescopic Deviation Settings": "伸缩偏差设置",
      "Deviation Type": "偏差类型",
      "Spot Vehicle Usage": "现场车辆使用",
      "Real-time Load Monitoring": "实时负载监控",
      "Driver Allocation Time": "驾驶员分配时间",
      "Use Traffic Data": "使用交通数据",
      "Optimize Route": "优化路线",
      "Optimize Fuel Efficiency": "优化燃油效率",
      "Daily Vehicle Plan": "每日车辆计划",
      "Transporter Allocation": "运输商分配",
      "Enable Real-time Tracking": "启用实时跟踪",
      "Vehicle Rotation Plan": "车辆轮换计划",
      "Route Optimization Preferences": "路线优化偏好",
      "Consider Weather Conditions": "考虑天气条件",
      "Max Loading Time": "最大装载时间",
      "Min Load Capacity": "最小负载容量",
      "Driver Scheduling": "驾驶员调度",
      "Vehicle Capacity Monitoring": "车辆容量监控",
      "Dynamic Load Balancing": "动态负载平衡",
      "Last Mile Optimization": "最后一英里优化",
      "Unloading Time Management": "卸货时间管理",
      "Daily Trip Plan": "每日行程计划",
      "Delivery Window Optimization": "交货窗口优化",
      "Night Driving Restrictions": "夜间驾驶限制",
      "Weekend Planning": "周末计划",

      run_new_plan: "执行新计划",
      run_new_plan: "运行新计划",
      new_plan_details: "新计划详情",
      order: "订单",
      transporter_details: "运输商详情",
      vehicle_configs: "车辆配置",
      order_information: "订单信息",
      plan_configs: "计划配置",
      vehicle_availability: "车辆可用性",
      vehicle_type: "车辆类型",
      vehicle_name: "车辆名称",
      vehicle_min_weight: "车辆最小重量（磅）",
      vehicle_max_weight: "车辆最大重量（磅）",
      vehicle_min_volume: "车辆最小体积（立方英尺）",
      vehicle_max_volume: "车辆最大体积（立方英尺）",
      max_distance_per_trip: "每次行程最大距离（英里）",
      vehicle_priority: "车辆优先级",
      enable_reconciliation: "启用对账",
      enable_dc_week_off_planning: "启用配送中心周休计划",
      order_number: "订单编号",
      invoicing_date: "开票日期",
      quantity: "数量",
      demand_cft: "需求（立方英尺）",
      weight_pounds: "重量（磅）",
      dealer_available_from: "经销商可用时间从",
      dealer_available_to: "经销商可用时间到",
      delivery_date: "交货日期",
      general_settings: "常规设置",
      prioritize_local_loads: "优先本地装载",
      consider_dropping_distant_smaller_loads: "考虑放弃远距离小装载",
      plan_spot_vehicles: "计划临时车辆",
      city_based_entry_restriction: "基于城市的进入限制",
      consider_vehicle_break_times: "考虑车辆休息时间（夜间驾驶）",
      consider_holiday_restrictions: "考虑节假日限制",
      consider_realtime_vehicle_available_times: "考虑车辆实时可用时间",
      consider_transporter_sob: "考虑承运商库存",
      planning_optimization_settings: "计划优化设置",
      use_dynamic_planning_window: "使用动态计划窗口",
      number_of_days_to_use_planning_window: "使用计划窗口的天数",
      optimization_type: "优化类型",
      cost_optimization: "成本优化",
      weight_based_optimization: "基于重量的优化",
      distance_based_optimization: "基于距离的优化",
      time_based_optimization: "基于时间的优化",
      volume_based_optimization: "基于体积的优化",
      vehicle_fill_rate_vfr_settings: "车辆填充率设置",
      use_volume_for_vfr: "使用体积进行车辆填充率",
      use_weight_for_vfr: "使用重量进行车辆填充率",
      min_vfr_per_vehicle_type: "每种车辆类型的最小填充率",
      min_vfr_per_spot_vehicle: "每辆临时车辆的最小填充率",
      moq_for_planning: "最小订单量规划",
      load_planning_settings: "装载计划设置",
      number_of_loading_docks: "装卸码头数量",
      max_local_no_of_customers_per_vehicle: "每辆车最大本地客户数量",
      max_upcountry_no_of_customers_per_vehicle: "每辆车最大异地客户数量",
      prioritize_same_dealers_first: "优先考虑相同经销商",
      prioritize_multi_city_dealers_first: "优先考虑多城市经销商",
      note_highlighted_rows_red:
        "红色高亮的行缺少GPS数据或集成，请手动标记车辆可用性。",
      note_highlighted_rows_green: "绿色高亮的行被视为可用于计划",
      vehicle_number: "车辆编号",
      transit_type: "运输类型",
      monthly_minimum_distance: "每月最小距离",
      remaining_distance: "剩余距离",
      vehicle_available_date: "车辆可用日期（YYYY-MM-DD HH:mm）",
      charlie_hub: "Charlie中心",
      enable_recommendation: "启用推荐",
      planned_day: "计划日",
      drop_sequence: "卸货顺序",
      drop_location_code_destination: "卸货地点代码（目的地）",
      drop_location_name_destination: "卸货地点名称（目的地）",
      destination_location_city: "目的地城市",
      weight_kgs: "重量（公斤）",
      volume_m3: "体积（立方米）",
      distance_to_destination_kms: "到目的地的距离（公里）",
      expected_delivery_date: "预计交货日期",
      time_to_destination: "到达目的地的时间",
      transit_time_hh_mm: "运输时间（小时:分钟）",
      unloading_time_hh_mm: "卸货时间（小时:分钟）",
      plan_id: "计划ID",
      generated_on: "生成日期",
      vehicle_availability_from: "车辆可用时间从",
      vehicle_availability_to: "车辆可用时间到",
      break_time_from: "休息时间从",
      break_time_to: "休息时间到",
      min_volume_m3: "最小体积（立方米）",
      max_volume_m3: "最大体积（立方米）",
      vehicle_width_mm: "车辆宽度（毫米）",
      vehicle_height_mm: "车辆高度（毫米）",
      vehicle_depth_mm: "车辆深度（毫米）",
      material_code: "材料代码",
      material_name: "材料名称",
      material_type: "材料类型",
      material_weight: "材料重量",
      material_dimensions_depth: "材料深度",
      material_dimensions_width: "材料宽度",
      material_dimensions_height: "材料高度",
      state: "州/省",
      city: "城市",
      fixed_vehicle_cost: "固定车辆成本",
      driver_cost: "司机成本",
      maintenance_cost: "维护成本",
      per_cmt_cost: "每立方英尺成本",
      per_km_cost: "每公里成本",
      date: "日期",
      transporter_name: "承运商名称",
      no_of_vehicles: "车辆数量",
      driver_break_time_from: "司机休息时间从",
      vehicle_max_weight_kgs: "车辆最大重量（公斤）",
      vehicle_min_volume_m3: "车辆最小体积（立方米）",
      vehicle_max_volume_m3: "车辆最大体积（立方米）",
      max_distance_kms: "最大距离（公里）",
      monthly_min_planning_distance_kms: "每月最小计划距离（公里）",
      details: "详情",
      trip_no: "行程编号",
      vehicle_no: "车辆编号",
      no_of_orders: "订单数量",
      no_of_customers: "客户数量",
      total_quantity: "总数量",
      vehicle_utilization_percent: "车辆利用率（百分比）",
      total_planned_weight_kgs: "总计划重量（公斤）",
      onward_trip_distance_kms: "去程距离（公里）",
      round_trip_distance_kms: "往返距离（公里）",
      total_planned_volume_m3: "总计划体积（立方米）",
      max_dispatch_time_hh_mm: "最大派送时间（小时:分钟）",
      arrival_at_first_dealer_start: "到达第一经销商（开始）",
      departure_at_last_dealer_end: "离开最后一个经销商（结束）",
      return_to_plant_hh_mm: "返回工厂时间（小时:分钟）",
      total_planned_trip_time_hrs: "总计划行程时间（小时）",
      total_round_trip_time_hrs: "总往返时间（小时）",
      total_transit_time_hh_mm: "总运输时间（小时:分钟）",
      total_loading_time_hh_mm: "总装货时间（小时:分钟）",
      total_unloading_time_hh_mm: "总卸货时间（小时:分钟）",
      drop_location_code: "卸货地点代码",
      drop_location_name: "卸货地点名称",
      drop_location_city: "卸货地点城市",
      demand_volume_m3: "需求体积（立方米）",
      vehicle_min_weight_kgs: "车辆最小重量（公斤）",
      no_of_deliveries: "交货数量",
      min_load_per_delivery: "每次交货的最小装载量",
      status: "状态",
      processing: "处理中",
      please_contact_admin: "请联系管理员",
      solver_complete: "解决完成",
      solver_reco_processed: "推荐已处理",
      solver_sob_done: "SOB已完成",
      solver_invalid_input_dealer_location: "无效的经销商位置输入",
      solver_invalid_input_materials: "无效的材料输入",
      solver_invalid_input_dealer_location_and_materials:
        "无效的经销商位置和材料输入",
      solver_input_data_not_valid: "输入数据无效",
      solver_fix_dropped_dealers: "修复丢弃的经销商",
      solver_vehicle_details_missing: "缺少车辆详情",
      solver_input_file_data_error: "输入文件数据错误",
      solver_invalid_file_template: "无效的文件模板",
      process_timed_out: "处理超时",
      solver_sob_fail: "SOB失败",
      solver_reco_failed: "推荐失败",
      solver_no_tool_config: "无工具配置",
      solver_invalid_tool_config: "无效的工具配置",
      transit_time_with_multiple_city_clubing: "多城市联合运输时间",
      rdd_preference: "RDD偏好",
      transit_time_with_single_city_clubing: "单城市联合运输时间",
      no_of_trucks_planned: "计划卡车数量",
      view_details: "查看详情",
      plan_run_by: "计划执行者",
      compare_plans: "比较计划",
      scenario_name: "场景名称",
      pick_up_location_source: "取货地点（来源）",
      pickup_location_source: "取货地点（来源）",
      drop_location_destination: "卸货地点（目的地）",
      demand_m3: "需求（立方米）",
      location_radius: "位置半径（公里）",
      volume_cmt: "体积（立方英尺）",
      obd_details_in_radius: "OBD详情（半径内）",
      planning_configurations: "计划配置",
      planning_scenarios: "计划场景",
      what_if_demand_fluctuation: "假如需求波动",
      increase_decrease_demand_by: "增加/减少需求",
      what_if_customer_priority_changes: "假如客户优先级改变",
      prioritize_upcountry: "优先考虑外地",
      prioritize_local: "优先考虑本地",
      what_if_service_level_target_changes: "假如服务水平目标改变",
      set_service_level_target_to: "设置服务水平目标为",
      what_if_vehicle_fill_rate_changes: "假如车辆填充率改变",
      set_vehicle_fill_rate_to: "设置车辆填充率为",
      what_if_customer_specific_delivery_time: "假如客户特定交货时间",
      increase_decrease_delivery_time_window_by: "增加/减少交货时间窗口",
      scenario_description: "场景描述",
      vehicle_fill_rate_settings: "车辆填充率设置",
      file_name: "文件名称",
      total_weight_kgs: "总重量（公斤）",
      total_demand_volume_m3: "总需求体积（立方米）",
      processing_plan_please_wait: "正在处理计划，请稍候...",
      forecast_input_information: "预测输入信息",
      uploading_data_for_plant: "正在为工厂上传数据",
      vehicle_type_configuration: "车辆类型配置",
      min_volume_cft: "最小体积（立方英尺）",
      max_volume_cft: "最大体积（立方英尺）",
      cost_per_km: "每公里成本",
      note_duplicate_scenario: "注意：重复的场景",
      select_unique_scenarios_to_compare: "请选择唯一的场景以比较计划",
      scenario_selection_limit_exceeded: "超出了场景选择限制",
      note_scenario_selection_limit: "注意：您最多可以选择三个场景",
      select_scenario_to_compare: "请选择场景进行比较",
      forecast_scenarios: "预测场景",
      select_planning_duration: "选择计划期限",
      notify_email_users_after_process:
        "处理完成后通知电子邮件用户（逗号分隔）",
      optimize_by_time_details: "按时间详情优化",
      max_time_limit_hrs: "最大时间限制（小时）",
      spot_vehicles_to_be_planned: "计划中的临时车辆",
      auto_select: "自动选择",
      planned_day: "计划日",
      drop_sequence: "卸货顺序",
      planned_distance_miles: "计划距离（英里）",
      restriction_hours: "限制时间",
      save_geofence: "保存地理围栏",
      no_data: "没有数据",
      redirecting_to_vehicle_checklist: "重定向到车辆检查清单",
      drop_location_code_destination: "卸货地点代码 (目的地)",
      drop_location_name_destination: "卸货地点名称 (目的地)",
      destination_location_city: "目的地城市",
      dealer_type: "经销商类型",
      customer_type: "客户类型",
      week_off: "休息日",
      order_number: "订单号",
      invoicing_date: "发票日期",
      quantity: "数量",
      weight_pounds: "重量 (磅)",
      volume_cft: "体积 (CFT)",
      distance_to_destination_miles: "到目的地的距离 (英里)",
      expected_delivery_date: "预期交货日期",
      time_to_destination: "到达目的地的时间",
      transit_time_hh_mm: "运输时间 (HH:MM)",
      unloading_time_hh_mm: "卸货时间 (HH:MM)",
      email: "电子邮件",
      details: "详细信息",
      view: "查看",
      assign_vehicle: "分配车辆",
      revoke_assignment: "撤销分配",
      edit_plan: "编辑计划",
      delete: "删除",
      plan_edited: "计划已编辑",
      yes: "是",
      no: "否",
      trip_no: "行程号",
      vehicle_no: "车辆号",
      vehicle_type: "车辆类型",
      cost: "费用 ($)",
      transit_type: "运输类型",
      transporter_name: "运输公司名称",
      no_of_orders: "订单数量",
      no_of_customers: "客户数量",
      total_quantity: "总数量",
      vehicle_utilization_percent: "车辆利用率 (%)",
      vehicle_max_weight: "车辆最大重量 (磅)",
      total_planned_weight: "计划总重量 (磅)",
      onward_trip_distance_miles: "去程距离 (英里)",
      round_trip_distance_miles: "往返距离 (英里)",
      vehicle_max_volume_cft: "车辆最大体积 (CFT)",
      total_planned_volume_cft: "计划总体积 (CFT)",
      max_dispatch_time: "最大发货时间 (HH:MM)",
      arrival_at_first_dealer: "到达首个经销商 (开始)",
      departure_at_last_dealer: "离开最后一个经销商 (结束)",
      return_to_plant: "返回工厂 (HH:MM)",
      total_planned_trip_time: "计划总行程时间 (小时)",
      total_round_trip_time: "往返总时间 (小时)",
      total_transit_time: "总运输时间 (HH:MM)",
      total_loading_time: "总装货时间 (HH:MM)",
      total_unloading_time: "总卸货时间 (HH:MM)",
      remark: "备注",
      "n/a": "不可用",
      revoke_again_to_edit: "撤销后再编辑",
      drop_location_code: "卸货地点代码",
      drop_location_name: "卸货地点名称",
      drop_location_city: "卸货地点城市",
      distance_from_plant: "距工厂距离",
      demand_volume_cft: "需求体积 (CFT)",
      delivery_date: "交货日期",
      actions: "操作",
      pickup_location_source: "提货地点 (来源)",
      dealer_available_from: "经销商可用时间 (从)",
      dealer_available_to: "经销商可用时间 (到)",
      loading_time_hh_mm: "装货时间 (HH:MM)",
      dropped_reason: "取消原因",
      dealer_location_data_not_in_system: "经销商地点数据不在系统中",
      delivery_date_less_than_current_date: "交货日期小于当前日期",
      kdd_customer: "KDD 客户",
      insufficient_load_for_planning: "规划载重不足 (<MOQ)",
      warehouse_name: "仓库名称",
      destination_city: "目的地城市",
      destination_state: "目的地州",
      sales_order_no: "销售订单号",
      no_of_tires: "轮胎数量",
      order_date: "订单日期",
      vehicle_name: "车辆名称",
      vehicle_priority: "车辆优先级",
      vehicle_availability_from: "车辆可用时间 (从)",
      vehicle_availability_to: "车辆可用时间 (到)",
      break_time_from: "休息时间 (从)",
      break_time_to: "休息时间 (到)",
      min_volume_cft: "最小体积 (CFT)",
      max_volume_cft: "最大体积 (CFT)",
      vehicle_dimensions_width_mm: "车辆宽度 (毫米)",
      vehicle_dimensions_height_mm: "车辆高度 (毫米)",
      vehicle_dimensions_depth_mm: "车辆深度 (毫米)",
      vehicle_min_weight: "车辆最小重量 (磅)",
      max_no_of_nodes: "最大交货点数量",
      min_load_per_delivery: "每次交货最小载荷",
      max_distance: "最大距离 (英里)",
      material_code: "材料代码",
      material_name: "材料名称",
      material_type: "材料类型",
      material_weight: "材料重量",
      material_dimensions_depth: "材料深度",
      material_dimensions_width: "材料宽度",
      material_dimensions_height: "材料高度",
      form_vehicle_types: "车辆类型",
      dealer_form_state: "州",
      dealer_form_city: "城市",
      freight_fixed_vehicle_cost: "固定车辆费用",
      freight_driver_cost: "司机费用",
      freight_maintenance_cost: "维护费用",
      freight_per_cmt_cost: "每 CMT 费用",
      freight_per_km_cost: "每公里费用",
      vehicle_date: "日期",
      vehicle_number: "车辆号",
      plant_code: "工厂代码",
      plant_name: "工厂名称",
      available_time: "可用时间",
      gps_status: "GPS 状态",
      last_known_address: "最后已知地址",
      last_gps_time: "最后 GPS 时间",
      vehicle_location: "车辆位置",
      assigned_to_ot: "已分配给 OT",
      min_vfr: "最小 VFR",
      order_information: "订单信息",
      vehicle_type_configurations: "车辆类型配置",
      vehicle_routes: "车辆路线",
      total_customers: "总客户数",
      planned: "计划",
      unplanned: "未计划",
      total_round_trip_distance_miles: "总往返距离（英里）",
      planning: "规划",
      vfr: "VFR",
      ontime_dispatch: "按时派遣",
      avg_tat: "平均TAT",
      view_locations: "查看位置",
      view_routes: "查看路线",
      additional_vehicles: "额外车辆",
      regular_vehicles: "常规车辆",
      enter_vehicle_no: "输入车辆号",
      vehicle_has_active_trip_shipment_id: "车辆有一个有效的行程运输ID",
      do_you_wish_to: "您想",
      force_close_assign: "强制关闭并分配",
      force_close_reason: "强制关闭原因",
      gps_issue: "GPS问题",
      trip_closed: "行程结束",
      load_cancelled: "负载已取消",
      new_trip_allocated: "已分配新行程",
      driver_changed: "司机已更换",
      are_you_sure_you_want_to_update: "您确定要更新吗",
      edit_plan_for_same: "编辑相同的计划",
      update_vehicle_plan: "更新车辆计划",
      max_dispatch_time: "最大派遣时间",
      new_plan: "新计划",
      reason_for_plan_change: "计划变更原因",
      enter_the_reason: "输入原因",
      reason_for_plan_deletion: "删除计划的原因",
      are_you_sure_delete_plan: "您确定要删除计划吗",
      update_order_details: "更新订单详情",
      demand_volume: "需求量",
      dealer_availability_start_time: "经销商可用开始时间",
      dealer_availability_end_time: "经销商可用结束时间",
      configurations: "配置",
      download: "下载",
      dealerTimeError: "经销商可用的开始时间应小于结束时间",
      more: "更多",
      less: "更少",
      qualityChecks: "质量检查",
      currentMonthPlanDistance: "本月计划距离",
      email: "电子邮件",
      viewPlan: "查看计划",
      blank_rows_columns_not_allowed: "不允许空白的行/列。",
      drop_location_name: "卸货地点名称",
      drop_location_city: "卸货地点城市",
      order_number: "订单号",
      delivery_date: "交货日期 (M/D/YYYY)",
      back: "返回",
      arrival_at_first_dealer_start: "首次到达经销商 (开始)",
      departure_at_last_dealer_end: "最后离开经销商 (结束)",
      return_to_plant_hh_mm: "返回工厂 (HH:MM)",
      total_planned_trip_time_hrs: "总计划行程时间 (小时)",
      total_round_trip_time_hrs: "总往返时间 (小时)",
      dealer_available_to: "经销商可用时间 (到)",
      bulk_upload_master_vehicles: "批量上传主车辆",
      add_master_vehicle: "添加主车辆",
      dealer_available_time_error: "开始时间必须早于结束时间",
      pickup_point: "取货点",
      plant_wise_configurations: "工厂配置",
      monthly_min_planning_distance: "每月最小计划距离",
      actions: "操作",
      import_existing_vehicle_details: "导入现有车辆详细信息",
      uploading_data_for_plant: "正在为工厂上传数据",
      check_and_remove_blank_data:
        "要检查并删除空白数据，请使用查找和选择 → 定位条件 → 空白，然后删除突出显示的行。",
      add_vehicle_type: "添加车辆类型",
      vehicle_operational_hours: "车辆运行时间 (HH:MM)",
      data_saved_successfully: "数据保存成功",
      plant_wise_configurations: "按工厂配置",
      save_plant_wise_configurations: "保存按工厂配置",
      vehicle_break_window: "车辆休息时间 (HH:MM)",
      total_vehicles: "车辆总数",
      zone_configurations: "区域配置",
      pickup_point: "取货点",
      actions: "操作",
      edit: "编辑",
      add_transporter_details: "+ 添加运输商详细信息",
      cost_per_mile: "每英里的成本",
      freight_details: "货运详情",
      add_zone: "+ 添加区域",
      fixed_vehicle_cost_title: "固定车辆成本 ($)",
      variable_vehicle_cost_title: "可变车辆成本",
      city_wise_cost: "按城市计算的车辆成本",
      zone_wise_cost: "按区域计算的车辆成本",
      vehicle_cost_configurations: "车辆成本配置",
      freight_cost_label: "货运成本",
      validity_to_label: "有效期至",
      validity_from_label: "有效期从",
      actions_label: "操作",
      edit_button: "编辑",
      delete_button: "删除",
      save_button: "保存",
      cancel_button: "取消",
      select_vehicle_type: "选择车辆类型",
      select_transporter_name: "选择运输商名称",
      zone: "区域",
      state: "州",
      driver_cost: "司机成本 ($)",
      maintenance_cost: "维护成本 ($)",
      fixed_vehicle_cost_label: "固定车辆成本 ($)",
      enable_disable_dispatch_planning_module: "启用/禁用调度计划模块",
      inbound: "入库",
      suppliers_vendor_locations_pickup: "供应商/供应商地点 (取货)",
      operational_hours: "运营时间",
      pickup_locations_drop: "取货地点 (卸货)",
      plant_wise_vehicle_type_configuration: "按工厂配置车辆类型",
      max_no_of_vehicle_types: "最大车辆类型数",
      vehicle_load_capacity_min_max_configuration_preferences:
        "车辆载重能力最小/最大配置偏好",
      vehicle_load_weight_min_max_preferences: "车辆载重量最小/最大偏好",
      distance_preferences: "距离偏好",
      vehicle_priorities: "车辆优先级",
      vehicle_operational_times: "车辆运行时间",
      vehicle_availability: "车辆可用性",
      generic_time_windows: "通用时间窗口",
      individual_vehicle_time_windows: "个别车辆时间窗口",
      availability_by_vehicle_type: "按车辆类型的可用性",
      availability_by_individual_vehicle: "按个别车辆的可用性",
      monthly_minimum_planning_distance_preferences: "月度最低计划距离偏好",
      gps_based_availability: "基于GPS的可用性",
      truck_location_visibility: "卡车位置可见性",
      plant_vicinity_circle_radius: "工厂周围范围半径",
      plant_wise_freight_configuration: "按工厂配置货运",
      plant_wise_transporter_configuration: "按工厂配置运输商",
      transporter_share_of_business: "运输商的业务份额",
      route_preferences: "路线偏好",
      plant_wise_new_plan_creation: "按工厂创建新计划",
      plant_wise_plan_summary: "按工厂总结计划",
      plant_wise_planning_history: "按工厂计划历史",
      sustainability_integration: "可持续性整合",
      save_inbound_configurations: "保存入库配置",
      save_outbound_configurations: "保存出库配置",
      outbound: "出库",
      consignees_dealers_locations_drop: "收货人/经销商地点 (卸货)",
      vehicles: "车辆",
      total_trucks: "卡车总数",
      active: "活跃",
      cost_details: "成本详情",
      fixed_vehicle_cost: "固定车辆成本",
      variable_vehicle_cost: "可变车辆成本",
      city_wise_vehicle_cost: "按城市划分的车辆成本",
      zone_wise_vehicle_cost: "按区域划分的车辆成本",
      pickup_point: "取货点",
      vehicle_type: "车辆类型",
      transporter_name: "运输商名称",
      cost_per_mile_below_sob: "每英里成本 (SOB以下)",
      cost_per_mile_after_sob: "每英里成本 (SOB之后)",
      validity_from: "有效期从",
      validity_to: "有效期至",
      add_vehicle_cost: "+ 添加车辆成本",
      freight_details: "运费详情",
      select_transporter: "选择运输商",
      select_zone: "选择区域",
      select_state: "选择州",
      city: "城市",
      cost: "成本 ($)",
      save: "保存",
      cancel: "取消",
      actions: "操作",
      validity_from_label: "有效期从",
      validity_to_label: "有效期至",
      get_data: "获取数据",
      state: "州",
      zone: "区域",
      fixed_vehicle_cost_label: "固定车辆成本 ($)",
      driver_cost: "司机成本 ($)",
      maintenance_cost: "维护成本 ($)",
      city: "城市",
      zone: "区域",
      vehicle_cost: "车辆成本",
      freight_cost: "运费详情",
      inactive: "不活跃",
      inside_dc: "在DC内",
      in_transit_to_customer: "运送至客户中",
      near_customer: "靠近客户",
      return_to_dc: "返回DC",
      empty_outside_dc: "DC外空车",
      zone_configurations: "区域配置",
      transporters_configurations: "运输商配置",
      truck_no: "卡车编号",
      facility_name: "设施名称",
      zones: "区域",
      actions: "操作",
      edit: "编辑",
      delete: "删除",
      add_zone_mapping: "添加区域映射",
      current_status: "当前状态",
      next_availability: "下次可用",
      dc_location: "DC位置",
      add_route_restriction: "添加路线限制",
      location_code: "位置代码",
      location_name: "位置名称",
      restricted_area: "限制区域",
      vehicle_list: "车辆列表",
      from_time: "开始时间",
      to_time: "结束时间",
      actions: "操作",
      edit: "编辑",
      customer_location: "客户位置",
      truck_no: "卡车编号",
      facility_name: "设施名称",
      current_status: "当前状态",
      inside_dc: "在DC内",
      in_transit_to_customer: "运送至客户中",
      near_customer: "靠近客户",
      return_to_dc: "返回DC",
      empty_outside_dc: "DC外空车",
      unplanned: "未计划",
      next_availability_date_at_dc: "DC的下次可用日期",
      transporter_name: "运输商名称",
      gps_status: "GPS状态",
      inactive: "不活跃",
      active: "活跃",
      na: "不适用",
      actions: "操作",
      date_and_time: "日期和时间",
      security: "安全",
      quality: "质量",
      assessed_by: "评估人",
      quality_conclusion: "质量结论",
      quality_comments: "质量评论",
      quality_approved: "质量批准",
      quality_date_of_assessment: "质量评估日期",
      facility_name: "设施名称",
      vehicle_number: "车辆编号",
      vehicle_type: "车辆类型",
      transporter_code: "运输商代码",
      transporter_name: "运输商名称",
      last_packet_time: "最后一个数据包时间",
      vehicle_checklist_for_transportation: "运输车辆清单",
      assessed_by: "评估人",
      security_conclusion: "安全结论",
      security_comments: "安全评论",
      zone_mapping: "区域映射",
      transporters: "运输商",
      material_configurations: "材料配置",
      freight_configurations: "货运配置",
      route_configurations: "路线配置",
      auto_clustering: "自动聚类",
      transporter_share_of_business: "运输商业务份额",
      dispatch_plan_summary: "调度计划概述",
      kpi_dashboard: "KPI仪表板",
      planning_tools: "规划工具",
      forecast_tools: "预测工具",
      suppliers: "供应商",
      customers: "客户",
      materials: "材料",
      input_data: "输入数据",
      forecast: "预测",
      previous_plan: "先前的计划",
      trips: "行程",
      gps_quality: "GPS质量",
      truck_gps_data: "卡车GPS数据",
      unscheduled_stoppages: "未计划的停顿",
      deviation_reports: "偏差报告",
      fo_data: "FO数据",
      vehicle_checklist: "车辆检查清单",
      vehicle_status: "车辆状态",
      route_restrictions: "路线限制",
      consolidated_run_plan: "合并运行计划",
      plans_overview: "计划概览",
      input_plan_summary: "输入计划概述",
      load_plan_summary: "装载计划概述",
      plant_wise_configurations: "工厂配置",
      vehicle_configuration: "车辆配置",
      monthly_data: "每月数据",
      forecast_plan_history: "预测计划历史",
      obd_analysis: "OBD分析",
      actions: "操作",
      pickup_location_source: "取货地点（来源）",
      drop_location_destination: "送货地点（目的地）",
      order_number: "订单编号",
      invoicing_date: "开票日期（M/D/YYYY）",
      quantity: "数量",
      dealer_available_time_from: "经销商可用时间（从 00:00）",
      dealer_available_time_to: "经销商可用时间（到 23:59）",
      volume_cft: "体积（立方英尺）",
      weight_pounds: "重量（磅）",
      delivery_date: "交货日期（M/D/YYYY）",
      no_rows_to_show: "没有要显示的行",
      rows: "行",
      total_rows: "总行数",
      add_dispatch: "添加调度",
      run_new_plan_by_file: "通过文件运行新计划",
      submit_run_plan: "提交并运行计划",
      delete: "删除",
      upload_input_file: "上传输入文件",
      upload_file: "上传文件",
      download_sample_template: "下载示例模板",
      save: "保存",
      cancel: "取消",
      dealer_available_time_error: "开始时间必须早于结束时间",
      ok: "好的",
      summary_dashboard: "摘要仪表板",
      pickup_point: "取货点",
      date_created: "创建日期",
      get_data: "获取数据",
      plan_details: "计划详情",
      plan_history: "计划历史",
      drop_details: "送货详情",
      generate_plan: "生成计划",
      order_id: "订单ID",
      no_of_runs: "运行次数",
      created_on: "创建于",
      plan_run_by: "计划执行者",
      discard_obd: "丢弃OBD",
      upload_obd_details: "上传OBD详情",
      download_template: "下载模板",
      choose_file: "选择文件",
      submit: "提交",
      start_date: "开始日期",
      end_date: "结束日期",
      get_data: "获取数据",
      upload_input_file: "上传输入文件",
      discard_obd: "丢弃OBD",
      recent_plans: "最近的计划",
      plan_history: "计划历史",
      view_input: "查看输入",
      consolidation_id: "合并ID",
      created_date: "创建日期",
      run_by: "执行者",
      no_of_executions: "执行次数",
      execution_status: "执行状态",
      download_output: "下载输出",
      upload_obd_details: "上传OBD详情",
      download_template: "下载模板",
      choose_file: "选择文件",
      submit: "提交",
      simulation_time: "模拟时间",
      enable_dc_week_off_planning: "启用DC周休计划",
      run_plan: "执行计划",
      cancel: "取消",
      plan_details: "计划详情",
      pickup_point: "取货点",
      date_created: "创建日期",
      order_id: "订单ID",
      no_of_runs: "执行次数",
      fix_and_reupload: "修复并重新上传",
      club_previous_order: "合并之前的订单",
      ignore: "忽略",
      dealer_code: "经销商代码",
      remarks: "备注",
      run_new_plan: "执行新计划",
      consolidated_route_plan_screen: "整合路线计划屏幕",
      plant_code: "工厂代码",
      plant_name: "工厂名称",
      total_trips: "总行程",
      trips_edited: "编辑后的行程",
      edited_transaction_ids: "编辑的交易ID",
      vehicle_type: "车辆类型",
      vehicle_name: "车辆名称",
      weight_pounds: "重量（磅）",
      drop_location_city: "交货地点城市",
      quantity: "数量",
      planned_vehicles: "计划的车辆",
      trip_no: "行程编号",
      total_planned_customers: "计划的客户总数",
      total_round_trip_distance: "总往返距离",
      planning_percentage: "计划百分比",
      max_dispatch_time: "最大派送时间（HH:MM）",
      total_loading_time: "总装载时间（HH:MM）",
      total_unloading_time: "总卸载时间（HH:MM）",
      planned_summary: "计划概要",
      invalid_orders_not_shown: "注意：无效订单未显示",
      no_of_customers: "客户数量",
      no_of_orders: "订单数量",
      total_demand_volume: "总需求量（CFT）",
      planned_summary: "计划概要",
      trips_removed: "删除的行程",
      removed_transaction_ids: "删除的交易ID",
      vehicle_forecast_configurations: "车辆预测配置",
      pickup_point: "取货点",
      vehicle_type: "车辆类型",
      vehicle_name: "车辆名称",
      cost_per_miles: "每英里的成本",
      driver_break_time: "司机休息时间",
      view: "查看",
      please_select_no_of_vehicles: "请选择车辆数量",
      no_of_vehicles: "车辆数量",
      cost_per_miles: "每英里的成本（$）",
      driver_break_time_from: "司机休息时间（从）",
      monthly_min_planning_distance_miles: "每月最低计划距离（英里）",
      vehicle_type_count: "车辆类型数量",
      driver_break_time_per_day: "每天的司机休息时间（HH:MM）",
      cost_per_miles_2: "每英里的成本（$）",
      set_vehicle_priority: "设置车辆优先级",
      vehicle_type_name: "车辆类型名称",
      min_volume_cft: "最小体积（CFT）",
      max_volume_cft: "最大体积（CFT）",
      max_distance_per_trip_miles: "每次行程的最大距离（英里）",
      select_plan_id: "选择计划ID",
      drop_details: "交货详情",
      drop_location_code: "交货地点代码",
      drop_location_name: "交货地点名称",
      pickup_location: "取货地点",
      dealer_available_from: "经销商可用时间（开始）",
      dealer_available_to: "经销商可用时间（结束）",
      total_planned_weight: "计划的总重量",
      total_planned_volume: "计划的总体积",
      arrival_at_first_dealer: "首次到达经销商",
      departure_at_last_dealer: "最后离开经销商",
      return_to_plant: "返回工厂",
      summary_dashboard: "概要仪表盘",
      dispatch_plan_details: "派送计划详情",
      invalid_orders_note: "注意：无效订单未显示",
      planned_data: "计划数据",
      vfr_percentage: "VFR百分比",
      cool_chain_adherence: "冷链遵守",
      transit_time: "运输时间",
      loading_time: "装载时间",
      unloading_time: "卸载时间",
      planned_summary: "计划概要",
      invalid_drop_data: "无效的交货数据",
      vehicle_utilization_km: "车辆利用率（KM）",
      distance_to_destination: "到目的地的距离",
      expected_delivery_date: "预计交货日期",
      planned_vehicles: "计划的车辆",
      trip_no: "行程编号",
      total_quantity: "总数量",
      dealer_available_to: "经销商可用时间（结束）",
      drop_location_code_destination: "交货地点代码（目的地）",
      order_number: "订单号",
      demand_volume_cft: "需求量（CFT）",
      weight_pounds: "重量（磅）",
      drop_location_name_destination: "交货地点名称（目的地）",
      note_blank_rows_columns_not_allowed: "注意: 不允许空行/列",
      check_blank_data_instructions:
        "检查空数据: 1. 单击查找并选择。 2. 单击转到特殊。 3. 选择空白。 4. 单击确定，所有空白行/单元格将被突出显示。 5. 在主页选项卡的单元格部分中选择删除。 6. 单击删除工作表行。",
      disclaimer_dealer_window_ot_customers:
        "免责声明: OT客户的可用时间窗口将从输入文件中考虑，GT客户的时间将从取货/交货地点考虑",
      quantity: "数量",
      specifications: "规格",
      please_select_plan_id_to_compare_plans: "请选择计划ID以比较计划",
      vehicle_forecast_summary_dashboard: "车辆预测汇总仪表板",
      pickup_point: "取货点",
      view_details: "查看详细信息",
      compare_plans: "比较计划",
      pickup_drop_points: "取货/交货点",
      edit: "编辑",
      pickup: "取货",
      drop: "交货",
      both: "两者",
      pickup_location_code: "取货地点代码",
      dealer_type: "经销商类型",
      select: "选择",
      local: "本地",
      upcountry: "农村",
      vehicle_restriction_list: "车辆限制清单",
      week_off: "周休",
      csp_day: "CSP日",
      is_customer_kdd: "客户是KDD吗？",
      start_month: "开始月份",
      end_month: "结束月份",
      plan_id: "计划ID",
      fecha_creada: "创建日期",
      no_of_trucks_planned: "计划卡车数量",
      total_planned_volume_cft: "计划总容量（CFT）",
      total_planned_weight_pounds: "计划总重量（磅）",
      plan_run_by: "计划执行者",
      planned_vehicles: "计划车辆",
      trip_no: "行程编号",
      total_planned_customers: "计划客户总数",
      total_round_trip_distance: "总往返距离",
      planning_percentage: "规划百分比",
      max_dispatch_time: "最大派送时间（HH:MM）",
      total_loading_time: "总装载时间（HH:MM）",
      total_unloading_time: "总卸载时间（HH:MM）",
      planned_summary: "计划摘要",
      invalid_orders_not_shown: "注意: 无效订单不显示",
      no_of_customers: "客户数量",
      no_of_orders: "订单数量",
      total_demand_volume: "总需求量（CFT）",
      planned_summary: "计划摘要",
      trips_removed: "已删除行程",
      removed_transaction_ids: "已删除的交易ID",
      vehicle_forecast_configurations: "车辆预测配置",
      pickup_point: "取货点",
      vehicle_type: "车辆类型",
      vehicle_name: "车辆名称",
      cost_per_miles: "每英里成本",
      driver_break_time: "驾驶员休息时间",
      view: "查看",
      please_select_no_of_vehicles: "请选择车辆数量",
      no_of_vehicles: "车辆数量",
      obd_analysis: "OBD分析",
      pickup_point: "取货点",
      select_month: "选择月份",
      location_radius_miles: "位置半径（英里）",
      submit: "提交",
      total_obd_details: "总OBD详情",
      valid_dealers: "有效经销商",
      invalid_dealers: "无效经销商",
      volume: "体积",
      help: "帮助",
      change_password: "更改密码",
      logout: "登出",
      weight_pounds: "重量（磅）",
      quantity: "数量",
      obd_details_within_radius: "半径内的OBD详情",
      dealers: "经销商",
      dealers_percent: "经销商百分比",
      volume_percent: "体积百分比",
      weight_pounds_percent: "重量百分比（磅）",
      quantity_percent: "数量百分比",
      cost_per_miles: "每英里成本（$）",
      driver_break_time_from: "驾驶员休息时间（从）",
      monthly_min_planning_distance_miles: "每月最小规划距离（英里）",
      vehicle_type_count: "车辆类型数量",
      driver_break_time_per_day: "每天驾驶员休息时间（HH:MM）",
      cost_per_miles_2: "每英里成本（$）",
      set_vehicle_priority: "设置车辆优先级",
      vehicle_type_name: "车辆类型名称",
      min_volume_cft: "最小体积（CFT）",
      max_volume_cft: "最大体积（CFT）",
      max_distance_per_trip_miles: "每次行程的最大距离（英里）",
      select_plan_id: "选择计划ID",
      drop_details: "交货详情",
      select_vehicle_type: "选择车辆类型",
      select_transporter_name: "选择运输商名称",
      zone: "区域",
      state: "州",
      driver_cost: "司机成本 ($)",
      maintenance_cost: "维护成本 ($)",
      fixed_vehicle_cost_label: "固定车辆成本 ($)",
      delete: "删除",
      state: "州",
      city: "城市",
      zone: "区域",
      delta_warehouse: "德尔塔仓库",
      zones: "区域",
      zone_mapping: "区域映射",
      city_not_mapped_to_state: "该城市未映射到任何州",
      actions: "操作",
      add_zones: "+ 添加区域",
      transporter_master: "运输商主数据",
      transporter_configurations: "运输商配置",
      transporter_operations: "运输商操作",
      transporter_code: "运输商代码",
      transporter_name: "运输商名称",
      actions: "操作",
      edit: "编辑",
      delete: "删除",
      zone_configuration: "区域配置",
      vehicle_checklist_for_transportation_requirements: "运输要求的车辆清单",
      transporters_configuration: "运输商配置",
      consolidated_route_plan_screen: "合并路线计划屏幕",
      plant_code: "工厂代码",
      select_plant_to_check_vehicle_availability: "选择工厂以检查车辆可用性",
      vehicle_availability: "车辆可用性",
      note_trucks_near_plant_vicinity: "注意: 工厂附近的卡车距离 ",
      facility_name: "设施名称",
      vehicle_number: "车辆编号",
      transporter_code: "运输商代码",
      vehicle_status_dashboard: "车辆状态仪表板",
      dc: "配送中心",
      drop_location_code: "卸货地点代码",
      vehicle_utilization: "车辆利用率",
      onward_trip_distance_miles: "单程距离 (英里)",
      round_trip_miles: "往返距离 (英里)",
      max_dispatch_time_hh_mm: "最大调度时间 (HH:MM)",
      arrival_at_first_dealer_start: "首次到达经销商 (开始)",
      departure_at_last_dealer_end: "最后离开经销商 (结束)",
      return_to_plant_hh_mm: "返回工厂 (HH:MM)",
      vehicle_number: "车辆编号",
      vehicle_availability_from: "车辆可用时间 (从)",
      vehicle_availability_to: "车辆可用时间 (到)",
      break_time_from: "休息时间 (从)",
      break_time_to: "休息时间 (到)",
      no_of_vehicles: "车辆数量",
      driver_break_time_from: "司机休息时间 (从)",
      max_distance_miles: "最大距离 (英里)",
      monthly_min_planning_distance_miles: "每月最低计划距离 (英里)",
      total_planned_trip_time_hrs: "总计划行程时间 (小时)",
      total_round_trip_time_hrs: "总往返时间 (小时)",
      drop_location_code: "卸货地点代码",
      drop_location_name: "卸货地点名称",
      drop_location_city: "卸货地点城市",
      order_number: "订单号",
      delivery_date: "交货日期 (M/D/YYYY)",
      back: "返回",
      pickup_location: "取货地点",
      dealer_available_from: "经销商可用时间 (从)",
      dealer_available_to: "经销商可用时间 (到)",
      total_planned_weight: "总计划重量",
      total_planned_volume: "总计划体积",
      arrival_at_first_dealer: "首次到达经销商",
      departure_at_last_dealer: "最后离开经销商",
      return_to_plant: "返回工厂",
      summary_dashboard: "总结仪表板",
      dispatch_plan_details: "调度计划详情",
      invalid_orders_note: "注: 无效订单未显示",
      planned_data: "计划数据",
      vfr_percentage: "VFR百分比",
      cool_chain_adherence: "冷链遵守",
      transit_time: "运输时间",
      loading_time: "装货时间",
      unloading_time: "卸货时间",
      planned_summary: "计划摘要",
      invalid_drop_data: "无效的卸货数据",
      vehicle_utilization_km: "车辆利用率 (KM)",
      distance_to_destination: "到目的地的距离",
      expected_delivery_date: "预计交货日期",
      planned_vehicles: "计划车辆",
      trip_no: "行程号",
      total_quantity: "总数量",
      dealer_available_to: "经销商可用时间 (到)",
      drop_location_code_destination: "卸货地点代码 (目的地)",
      order_number: "订单号",
      demand_volume_cft: "需求体积 (CFT)",
      weight_pounds: "重量 (磅)",
      quantity: "数量",
      dropped_reason: "删除原因",
      delivery_date: "交货日期",
      drop_location_name: "卸货地点名称",
      drop_location_city: "卸货地点城市",
      trip_no: "行程号",
      planned_day: "计划日",
      vehicle_no: "车辆编号",
      vehicle_type: "车辆类型",
      drop_sequence: "卸货顺序",
      invoicing_date: "发票日期",
      pickup_location_source: "取货地点 (来源)",
      destination_location_city: "目的地城市",
      destination_location_state: "目的地州",
      distance_to_destination_miles: "到目的地的距离 (英里)",
      expected_delivery_date: "预计交货日期",
      time_to_destination: "到目的地的时间",
      transit_time_hh_mm: "运输时间 (HH:MM)",
      loading_time_hh_mm: "装货时间 (HH:MM)",
      unloading_time_hh_mm: "卸货时间 (HH:MM)",
      total_planned_customers: "计划客户总数",
      total_round_trip_distance_km: "总往返距离 (KM)",
      planning_percentage: "计划百分比",
      cool_chain_adherence_percentage: "冷链遵守率",
      total_transit_time_hh_mm: "总运输时间 (HH:MM)",
      total_planned_weight_pounds: "总计划重量 (磅)",
      total_planned_volume_cft: "总计划体积 (CFT)",
      arrival_at_first_dealer_start: "首次到达经销商 (开始)",
      departure_at_last_dealer_end: "最后离开经销商 (结束)",
      return_to_plant_hh_mm: "返回工厂 (HH:MM)",
      total_planned_trip_time_hrs: "总计划行程时间 (小时)",
      total_round_trip_time_hrs: "总往返时间 (小时)",
      total_loading_time_hh_mm: "总装货时间 (HH:MM)",
      total_unloading_time_hh_mm: "总卸货时间 (HH:MM)",
      vehicle_utilization_percentage: "车辆利用率 (%)",
      view_order_information: "查看订单信息",
      vehicle_configuration: "车辆配置",
      summary: "摘要",
      planned_summary: "计划摘要",
      planned_vehicles: "计划车辆",
      unplanned_loads: "未计划负载",
      invalid_drop_data: "无效的卸货数据",
      vehicle_utilization_km: "车辆利用率 (公里)",
      planned_data: "计划数据",
      invalid_orders_note: "注意: 无效订单未显示",
      set_vehicle_priority: "设置车辆优先级",
      vehicle_min_weight_pounds: "车辆最小重量 (磅)",
      vehicle_max_weight_pounds: "车辆最大重量 (磅)",
      vehicle_min_volume_cft: "车辆最小体积 (立方英尺)",
      vehicle_max_volume_cft: "车辆最大体积 (立方英尺)",
      max_distance_miles: "最大距离 (英里)",
      vehicle_priority: "车辆优先级",
      actions: "操作",
      forecast_order_plan: "预测订单计划",
      drop_details: "卸货详情",
      month: "月",
      year: "年",
      total_quantity: "总数量",
      total_weight_pounds: "总重量 (磅)",
      total_demand_volume_cft: "总需求体积 (立方英尺)",
      user: "用户",
      uploaded_date: "上传日期",
      view_order_details: "查看订单详情",
      pick_up_location_code: "取货地点代码",
      quantity: "数量",
      drop_location: "卸货地点",
      drop_location_name: "卸货地点名称",
      weight: "重量",
      volume: "体积",
      delivery_date: "交货日期",
      invoice_date: "发票日期",
      order_no: "订单号",
      available_start_time: "可用开始时间",
      available_end_time: "可用结束时间",
      upload_input_file: "上传输入文件",
      download_sample_template: "下载示例模板",
      select_month: "选择月份",
      upload_file: "上传文件",
      save: "保存",
      cancel: "取消",
      note_blank_rows_columns_not_allowed: "注意: 不允许空行/空列",
      no_of_vehicles: "车辆数量",
      run_plan: "执行计划",
      vehicle_types: "车辆类型",
      upload_file: "上传文件",
      pickup_point: "取货点",
      consolidated_route_plan_screen: "合并路线计划屏幕",
      start_date: "开始日期",
      end_date: "结束日期",
      get_data: "获取数据",
      recent_plans: "最近的计划",
      plan_history: "计划历史",
      view_input: "查看输入",
      consolidation_id: "合并ID",
      created_date: "创建日期",
      run_by: "执行人",
      no_of_executions: "执行次数",
      execution_status: "执行状态",
      download_output: "下载输出",
      upload_input_file: "上传输入文件",
      choose_file: "选择文件",
      discard_obd: "丢弃OBD",
      upload_obd_details: "上传OBD详情",
      upload_file: "上传文件",
      submit: "提交",
      download_template: "下载模板",
      bulk_upload_locations: "批量上传地点",
      add_pickup_drop_points: "+添加取货/卸货点",
      details: "详情",
      date: "日期",
      total_trips: "总行程数",
      trips_edited: "编辑的行程",
      trips_removed: "移除的行程",
      trips_edit_percentage: "行程编辑百分比",
      plans_overview_dashboard: "计划概览仪表盘",
      pickup_point: "取货点",
      location_type: "地点类型",
      location_code: "地点代码",
      location_name: "地点名称",
      location_city: "地点城市",
      zone: "区域",
      customer_type: "客户类型",
      location_latitude: "地点纬度",
      location_longitude: "地点经度",
      standard_transit_time: "标准运输时间 (天)",
      distance_from_plant: "距离工厂的距离 (英里)",
      delta_warehouse: "德尔塔仓库",
      home: "首页",
      run_new_plan: "执行新计划",
      stacking: "堆叠",
      production_planning: "生产计划",
      forecasting: "预测",
      resource_scheduling: "资源调度",
      general: "一般",
      pickup_drop_locations: "取货/卸货地点",
      vehicle_configurations: "车辆配置",
      vehicle_availability: "车辆可用性",
      security_approved: "安全批准",
      security_date_of_assessment: "安全评估日期",
      view: "查看",
      location_code: "位置代码",
      vehicle_no: "车辆编号",
      stage: "阶段",
      quality_rejected: "质量被拒",
      requirements_set: "已设置要求",
      security_rejected: "安全拒绝",
      security_accepted: "安全接受",
      assessment_date: "评估日期",
      requirement: "要求",
      satisfied: "满意",
      yes: "是",
      no: "否",
      requirement_set_date: "要求设定日期",
      temperature: "温度",
      vehicle_operational_from: "车辆运营开始时间",
      vehicle_operational_to: "车辆运营结束时间",
      break_time_from: "休息时间从",
      break_time_to: "休息时间到",
      transporter_name: "运输商名称",
      last_packet_time: "最后的数据包时间",
      temperature: "温度",
      view_vehicles: "查看车辆",
      transporter_name: "运输商名称",
      max_distance_per_plan_miles: "每计划最大距离（英里）",
      remaining_monthly_distance_miles: "剩余的每月距离（英里）",
      vehicle_next_available_date: "车辆下次可用日期",
      owned: "自有",
      contract: "合同",
      high: "高",
      medium: "中",
      low: "低",
      market: "市场",
      vehicles_for_today: "今天的车辆",
      bulk_upload_master_vehicles: "批量上传主车辆",
      add_master_vehicle: "添加主车辆",
      transporter_name: "运输商名称",
      transit_type: "运输类型",
      actions: "操作",
      facility_name: "设施名称",
      vehicle_type: "车辆类型",
      vehicle_number: "车辆编号",
      vehicle_priority: "车辆优先级",
      max_distance_per_plan_miles: "每计划最大距离（英里）",
      max_weight_pounds: "最大重量（磅）",
      max_volume_cft: "最大体积（立方英尺）",
      monthly_min_planning_distance_miles: "每月最小计划距离（英里）",
      remaining_monthly_planning_distance_miles: "剩余的每月计划距离（英里）",
      next_available_date_yyyy_mm_dd_hh_mm: "下次可用日期（YYYY-MM-DD HH:MM）",
      vehicle_break_down: "车辆故障",
      drop_location_code: "送货地点代码",
      arrival_at_first_dealer: "到达第一经销商",
      departure_at_last_dealer: "离开最后的经销商",
      average_payload: "平均载荷（吨）",
      mileage: "行驶里程（英里/加仑）",
      fuel_type: "燃料类型",
      temperature_controlled: "温控",
      load_characteristics: "负载特性",
      glec_vehicle_type: "GLEC车辆类型",
      return_to_plant: "返回工厂",
      summary_dashboard: "摘要仪表板",
      dispatch_plan_details: "调度计划详情",
      invalid_orders_note: "注意：无效订单未显示",
      pickup_point: "取货点",
      vehicle_type_configuration: "车辆类型配置",
      vehicle_type: "车辆类型",
      vehicle_type_name: "车辆类型名称",
      min_volume: "最小体积（立方英尺）",
      max_volume: "最大体积（立方英尺）",
      vehicle_priority: "车辆优先级",
      min_weight: "最小重量（磅）",
      max_weight: "最大重量（磅）",
      max_distance_trip: "每次行程最大距离（英里）",
      monthly_min_planning_distance: "每月最小计划距离",
      cancel: "取消",
      save: "保存",
      facility_name: "设施名称",
      vehicle_no: "车辆编号",
      address: "地址",
      last_packet_time: "最后的数据包时间",
      latitude: "纬度",
      longitude: "经度",
      service_provider: "服务提供商",
      temperature: "温度",
      on_trip: "在行程中",
      requirement: "要求",
      requirement_type: "要求类型",
      security: "安全",
      assessed_by: "评估人",
      security_conclusion: "安全结论",
      security_comments: "安全评论",
      assessment_start_date: "评估开始日期",
      assessment_end_date: "评估结束日期",
      get_data: "获取数据",
      add_requirements: "添加要求",
      add_vehicle_checklist: "添加车辆检查表",
      actions: "操作",
      edit: "编辑",
      delete: "删除",
      vehicle_configurations: "车辆配置",
      vehicle_availability_configurations: "车辆可用性配置",
      vehicle_location: "车辆位置",
      vehicle_master: "车辆主数据",
      vehicle_availability: "车辆可用性",
      vehicle_details: "车辆详情",
      monthly_min_distance: "每月最小距离（英里）",
      max_weight_per_trip: "每次行程最大重量（磅）",
      max_distance_per_trip: "每次行程最大距离（英里）",
      planned_data: "计划数据",
      vfr_percentage: "VFR百分比",
      cool_chain_adherence: "冷链遵守",
      transit_time: "运输时间",
      loading_time: "装载时间",
      unloading_time: "卸载时间",
      planned_summary: "计划概要",
      invalid_drop_data: "无效的交货数据",
      vehicle_utilization_km: "车辆利用率（KM）",
      distance_to_destination: "到目的地的距离",
      expected_delivery_date: "预计交货日期",
      trip_no: "行程编号",
      total_quantity: "总数量",
      demand_volume_cft: "需求量（CFT）",
      weight_pounds: "重量（磅）",
      quantity: "数量",
      dropped_reason: "丢弃原因",
      delivery_date: "交货日期",
      drop_location_city: "交货地点城市",
      planned_day: "计划日",
      vehicle_no: "车辆编号",
      drop_sequence: "交货顺序",
      invoicing_date: "开票日期",
      pickup_location_source: "取货地点（来源）",
      destination_location_city: "目的地城市",
      destination_location_state: "目的地州",
      distance_to_destination_miles: "到目的地的距离（英里）",
      time_to_destination: "到目的地的时间",
      dealer_available_from: "经销商可用时间（开始）",
      dealer_available_to: "经销商可用时间（结束）",
      total_planned_weight: "计划的总重量",
      total_planned_volume: "计划的总体积",
      arrival_at_first_dealer_start: "首次到达经销商（开始）",
      departure_at_last_dealer_end: "最后离开经销商（结束）",
      return_to_plant_hh_mm: "返回工厂（HH:MM）",
      total_planned_trip_time_hrs: "计划的总行程时间（小时）",
      total_round_trip_time_hrs: "总往返时间（小时）",
      total_loading_time_hh_mm: "总装载时间（HH:MM）",
      total_unloading_time_hh_mm: "总卸载时间（HH:MM）",
      vehicle_utilization_percentage: "车辆利用率（％）",
      view_order_information: "查看订单信息",
      vehicle_configuration: "车辆配置",
      summary: "概要",
      planned_summary: "计划概要",
      planned_vehicles: "计划的车辆",
      unplanned_loads: "未计划的装载",
      invalid_drop_data: "无效的交货数据",
      set_vehicle_priority: "设置车辆优先级",
      forecast_order_plan: "预测订单计划",
      volume_cft: "体积（CFT）",
      dealer_available_time_error: "开始时间必须小于结束时间",
      ok: "确认",
      get_data: "获取数据",
      plan_history: "计划历史",
      upload_file: "上传文件",
      save: "保存",
      cancel: "取消",
      delete: "删除",
      plant_name: "工厂名称",
      total_trips: "总行程",
      trips_edited: "编辑后的行程",
      vfr_percentage: "VFR百分比",
      total_transit_time_hh_mm: "总运输时间（HH）",
      drop_location_name_destination: "交货地点名称（目的地）",
      unloading_time_hh_mm: "卸载时间（HH:MM）",
      edited_transaction_ids: "编辑的交易ID",
      vehicle_type: "车辆类型",
      vehicle_name: "车辆名称",
      weight_pounds: "重量（磅）",
      plant_code: "工厂代码",
      plant_name: "工厂名称",
      total_trips: "总行程",
      trips_edited: "编辑后的行程",
      last_30_days_trips_modification_details: "过去30天的行程修改详情",
      last_7_days_trips_modification_details: "过去7天的行程修改详情",
      planning_progress_past_7_weeks: "过去7周的计划进展",
      edited_transaction_ids: "编辑的交易ID",
      trips_removed: "删除的行程",
      removed_transaction_ids: "删除的交易ID",
      drop_location_city: "交货地点城市",
      quantity: "数量",
      dropped_reason: "放弃原因",
      delivery_date: "交货日期",
      drop_location_name: "交货地点名称",
      drop_location_city: "交货地点城市",
      trip_no: "行程编号",
      planned_day: "计划日",
      vehicle_no: "车辆编号",
      vehicle_type: "车辆类型",
      drop_sequence: "交货顺序",
      invoicing_date: "开票日期",
      pickup_location_source: "取货地点（来源）",
      destination_location_city: "目的地城市",
      destination_location_state: "目的地州",
      distance_to_destination_miles: "到目的地的距离（英里）",
      expected_delivery_date: "预计交货日期",
      time_to_destination: "到达目的地的时间",
      invalid_material: "无效材料",
      drop_location_code_destination: "交货地点代码（目的地）",
      demand_volume_cft: "需求体积（CFT）",
      dropped_reason: "放弃原因",
      dealer_location_data_not_in_system: "经销商位置信息不在系统中",
      delivery_date_less_than_current: "交货日期早于当前日期",
      missing_dealer_information: "缺少经销商信息",
      transit_time_hh_mm: "运输时间（HH:MM）",
      loading_time_hh_mm: "装载时间（HH:MM）",
      unloading_time_hh_mm: "卸载时间（HH:MM）",
      actions: "操作",
      expected_delivery_date: "预计交货日期",
      max_dispatch_time: "最大派送时间（HH:MM）",
      unplanned_loads: "未计划的装载",
      monthly_data: "月度数据",
      forecast_plan_history: "预测计划历史",
      obd_analysis: "OBD分析",
      dealer_available_time_error: "开始时间必须早于结束时间",
    },
  },
  ko: {
    translation: {
      vehicles_recommended: "추천 차량",
      "route_type": "경로 유형",
      "Number Of Trips": "여행 횟수",
      "Day-2": "2일차",
  "Day-3": "3일차",
  "Day-4": "4일차",
  "Day-5": "5일차",
  "Day-6": "6일차",
  "Distance / Time From Pickup Location": "픽업 위치로부터의 거리/시간",
  "Distance / Time From Previous Drop Point": "이전 하차 지점으로부터의 거리/시간",
  "Location City": "위치 도시",
  "Planned Day": "계획된 날",
  "Quantity": "수량",
  "Demand Weight": "수요 중량",
  "Demand Volume": "수요 볼륨",
  "Location": "위치",
  "Weight": "무게",
  "View Onward Route": "앞으로의 경로 보기",
  "View Return Route": "돌아오는 경로 보기",
  "Total": "총계",
  "Quantity": "수량",
  "Weight": "무게",
  "Spot": "스팟",
  "Local": "지역",
  "Upcountry": "지방",
  "Volume": "부피",
  "IN416": "IN416",
  "IN436": "IN436",
  "Spot": "스팟",
  "2,177": "2,177",
  "1,268": "1,268",
  "909": "909",
  "0": "0",
  "Planned Truck Load": "계획된 트럭 적재량",
  "Quantity (cases)": "수량 (건)",
  "Weight (Kgs)": "무게 (킬로그램)",
  "Volume (CMT)": "부피 (CMT)",
  "1,81,970": "1,81,970",
  "25,25,554": "25,25,554",
  "8,249": "8,249",
  "Unplanned Load": "계획되지 않은 적재량",
  "26,324": "26,324",
  "3,74,549": "3,74,549",
  "1,234": "1,234",
  "Average Distance Travelled Per Vehicle (KM)": "차량당 평균 주행 거리 (KM)",
  "35,426": "35,426",
  "37,624": "37,624",
  "28,111": "28,111",
  "Operational Cost (Rs.)": "운영 비용 (루피)",
  "1,62,57,137": "1,62,57,137",
  "1,31,64,341": "1,31,64,341",
  "30,92,797": "30,92,797",
  "Average Vehicle Fill Rate": "평균 차량 충전율",
  "60 %": "60 %",
  "42 %": "42 %",
  "79 %": "79 %",
  "Unplanned Load Proportion": "계획되지 않은 적재 비율",
  "12 %": "12 %",
  "Average Vehicle Utilization As Per SOB": "SOB 기준 차량 이용률",
  "104 %": "104 %",
  "94 %": "94 %",
  "Ontime Dispatches": "정시 발송",
  "Local": "로컬",
  "frefe": "frefe",
  "94 %": "94 %",
  "92 %": "92 %",
  "Ontime Deliveries": "정시 배송",
  "97 %": "97 %",
  "94 %": "94 %",
      "Please Select Scenario to Compare Plans": "계획을 비교할 시나리오를 선택하세요",
"Percentage": "백분율",
"Forecast Scenario": "예측 시나리오",
      "Number of kms Travelled": "이동한 거리 (킬로미터)",
"Cost": "비용",
"Vehicle Fill Rate": "차량 적재율",
"Vehicle KM Run %": "차량 주행 거리 %",
      "Please Select File": "파일을 선택하세요",
"Processing Plan. Please wait.....": "계획 처리 중입니다. 잠시만 기다려주세요.....",
"File is processing": "파일을 처리 중입니다",
"Enter Input Data To Continue": "계속하려면 입력 데이터를 입력하세요",
"No Data": "데이터 없음",
"please_select_date": "날짜를 선택하세요",
"data_uploaded": "데이터가 업로드되었습니다",
"Please Select No of Vehicles": "차량 수를 선택하세요",
"Please Input Valid Data": "유효한 데이터를 입력하세요",
      "change_password": "비밀번호 변경",
      "driver_break_time_to": "운전자 휴식 시간까지",
"max_seating_capacity": "최대 좌석 수용 인원",
"min_seating_capacity": "최소 좌석 수용 인원",
"vehicle_seating_capacity": "차량 좌석 수용 인원",
"vehicle_capacity_cmt": "차량 용량 (세제곱미터)",
  "new_password": "새 비밀번호",
  "confirm_new_password": "새 비밀번호 확인",
  "old_password": "기존 비밀번호",
  "enter_email": "이메일을 입력하세요",
  "enter_new_password": "새 비밀번호를 입력하세요",
  "confirm_new_password_entry": "새 비밀번호를 확인하세요",
      "loading....": "로딩 중....",
  "select_cluster": "클러스터 선택",
  "Overall Cluster Wise Vehicles": "전체 클러스터별 차량",
  "Discard": "취소",
  "obd": "OBD",
  "Dispatch Planning is in progress..!!!": "배차 계획이 진행 중입니다..!!!",
  "Estimated Time": "예상 시간",
  "GPS": "GPS",
  "Spot Vehicles To Be Planned": "계획할 스팟 차량",
  "No Of Spot Vehicles": "스팟 차량 수",
  "Availability DateTime": "이용 가능 날짜 및 시간",
  "Notify Email Users After Process (comma seperated)": "프로세스 후 이메일 사용자에게 알림 (쉼표로 구분)",
  "Max Time Limit(Hrs)": "최대 시간 제한 (시간)",
  "vfr": "VFR",
  "Disclaimer": "면책 조항: OT 고객의 경우 딜러의 이용 가능 시간은 입력 파일에서, GT 고객의 시간은 픽업/드롭 위치에서 고려됩니다.",
  "To Check for Blank Data": "빈 데이터를 확인하려면: 1. '찾기 및 선택'을 클릭합니다. 2. '특수로 이동'을 클릭합니다. 3. '빈 값'을 선택합니다. 4. '확인'을 클릭하면 모든 빈 행/셀들이 강조 표시됩니다. 5. 홈 탭에서 셀 섹션에서 '삭제'를 선택합니다. 6. '시트 행 삭제'를 클릭합니다.",
  "Add New OBD": "새 OBD 추가",
  "All drop locations are invalid, Please check..!!": "모든 드롭 위치가 유효하지 않습니다. 확인해 주세요..!!",
  "Please upload file having extensions .csv or .xlsx only.": ".csv 또는 .xlsx 확장자가 있는 파일만 업로드하십시오.",
  "Dispatch Load Volume Summary (CFT)": "배차 하중 볼륨 요약 (CFT)",
  "Total Loads Volume (CFT)": "총 하중 볼륨 (CFT)",
  "Planned Loads Volume (CFT)": "계획된 하중 볼륨 (CFT)",
  "Unplanned Loads Volume (CFT)": "미계획 하중 볼륨 (CFT)",
  "Dropped Loads Volume (CFT)": "드롭된 하중 볼륨 (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "배차 하중 무게 요약 (파운드)",
  "Total Loads Weight (Pounds)": "총 하중 무게 (파운드)",
  "Planned Loads Weight (Pounds)": "계획된 하중 무게 (파운드)",
  "Unplanned Loads Weight (Pounds)": "미계획 하중 무게 (파운드)",
  "Dropped Loads Weight (Pounds)": "드롭된 하중 무게 (파운드)",
  "Dispatch Load Quantity Summary": "배차 하중 수량 요약",
  "Total Quantity": "총 수량",
  "Planned Quantity": "계획된 수량",
  "Unplanned Quantity": "미계획 수량",
  "Dropped Quantity": "드롭된 수량",
  "Dispatch Load Volume Summary (CFT)": "배차 하중 볼륨 요약 (CFT)",
  "Total Loads Volume (CFT)": "총 하중 볼륨 (CFT)",
  "Planned Loads Volume (CFT)": "계획된 하중 볼륨 (CFT)",
  "Unplanned Loads Volume (CFT)": "미계획 하중 볼륨 (CFT)",
  "Dropped Loads Volume (CFT)": "드롭된 하중 볼륨 (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "배차 하중 무게 요약 (파운드)",
  "Total Loads Weight (Pounds)": "총 하중 무게 (파운드)",
  "Planned Loads Weight (Pounds)": "계획된 하중 무게 (파운드)",
  "Unplanned Loads Weight (Pounds)": "미계획 하중 무게 (파운드)",
  "Dropped Loads Weight (Pounds)": "드롭된 하중 무게 (파운드)",
  "Dispatch Load Quantity Summary": "배차 하중 수량 요약",
  "Total Quantity": "총 수량",
  "Planned Quantity": "계획된 수량",
  "Unplanned Quantity": "미계획 수량",
  "Dropped Quantity": "드롭된 수량",
  "Dispatch Load Weight Summary (Pounds)": "배차 하중 무게 요약 (파운드)",
  "Dispatch Load Volume Summary (CFT)": "배차 하중 볼륨 요약 (CFT)",
  "Dropped Data": "드롭된 데이터",
  "ontime_delivery": "정시 배송",
  "dealer_Available_start_time_should_be_less_than_end_time": "딜러의 시작 시간이 종료 시간보다 적어야 합니다",
  "Removed": "제거됨",
  "Unedited": "편집되지 않음",
  "Edited": "편집됨",
      vehicle_availability_configurations: "차량 가용성 구성",
      max_distance_per_plan_miles: "계획당 최대 거리 (마일)",
    add_city_wise_cost: "도시별 비용 추가",
    add_zone_wise_cost: "지역별 비용 추가",
    scenarios: "시나리오",
      plant: "공장",
      customer: "고객",
      standard_transit_time_days: "표준 운송 시간 (일)",
    drop_to_time: "하차 시간",
    drop_from_time: "출발 시간",
    pickup_to_time: "픽업 마감 시간",
    pickup_from_time: "픽업 시작 시간",
    distance_from_plant_miles: "공장으로부터의 거리 (마일)",
    vehicle_next_available_date: "다음 이용 가능 차량 날짜",
    max_distance_per_plan_miles: "계획당 최대 거리 (마일)",
    remaining_monthly_distance_miles: "남은 월간 거리 (마일)",
    assign_quality_checks: "품질 점검 할당",
    add_fixed_vehicle_cost: "고정 차량 비용 추가",
      "Pickup Locations (Drop)*": "픽업 위치 (드롭)*",
      percent_unplanned: "계획되지 않은 비율",
      duplicate_obds_light_red: "중복된 OBD는 연한 빨간색으로 표시됩니다",
    new_obds_green: "새로 추가된 OBD는 녹색으로 표시됩니다",
    pickup_locations_drop: "픽업 위치 (드롭)",
      invalid_orders_not_shown: "유효하지 않은 주문이 표시되지 않습니다",
    note: "참고",
      please_select_scenario: "계획을 비교할 시나리오를 선택하세요",
    avg_kms_travelled: "평균 이동 거리 (Km)",
    total_cost: "총 비용",
    total_vehicle_fill_rate: "총 차량 채우기 비율",
    percent_km_run_sob: "SOB에서 이동한 킬로미터 비율",
    percent_ontime_dispatch: "정시 배송 비율",
    upcountry: "지방",
    percent_spot_vehicles_trips: "스팟 차량 여행 비율",
    round_trip_distance: "왕복 거리",
    total_trip_distance: "총 여행 거리",
    vehicle_type: "차량 유형",
    plant_code: "공장 코드",
    vehicle_no: "차량 번호",
    monthly_sob_km: "월별 SOB (Km)",
    distance_travelled_km: "이동 거리 (Km)",
    pickup_location_source: "픽업 위치 (출발지)",
    drop_location_code_destination: "드롭 위치 코드 (목적지)",
    drop_location_name_destination: "드롭 위치 이름 (목적지)",
    order_number: "주문 번호",
    invoicing_date: "청구 날짜",
    quantity: "수량",
    weight_kgs: "무게 (Kg)",
    demand_volume_m3: "수요 부피 (M3)",
    dealer_available_from: "딜러 사용 가능 시간 시작",
    dealer_available_to: "딜러 사용 가능 시간 종료",
    delivery_date: "배송일",
    trip_no: "여행 번호",
    planned_day: "계획된 날",
    drop_sequence: "드롭 순서",
    destination_location_city: "목적지 도시",
    destination_location_state: "목적지 주",
    volume_m3: "부피 (M3)",
    distance_to_destination_kms: "목적지까지의 거리 (Km)",
    expected_delivery_date: "예상 배송일",
    time_to_destination: "목적지까지의 시간",
    transit_time_hhmm: "운송 시간 (시:분)",
    loading_time_hhmm: "적재 시간 (시:분)",
    unloading_time_hhmm: "하역 시간 (시:분)",
    start_month: "시작 월",
    end_month: "종료 월",
    get_data: "데이터 가져오기",
    select_chart_type: "차트 유형 선택",
    fleet_analysis: "차량 분석",
    system_recommended: "시스템 추천",
    total_load: "총 적재량",
    serviceability: "서비스 가능성",
    in416: "IN416",
    in436: "IN436",
    user_recommended: "사용자 추천",
    threshold: "임계값",
    number_of_trips: "여행 횟수",
    total: "총계",
    spot: "스팟",
    planned_truck_load: "계획된 트럭 적재량",
    quantity_cases: "수량 (상자)",
    weight_kgs: "무게 (Kg)",
    volume_cmt: "부피 (CMT)",
    unplanned_load: "계획되지 않은 적재량",
    average_distance_travelled_per_vehicle_km: "차량 당 평균 이동 거리 (KM)",
    operational_cost_rs: "운영 비용 (Rs)",
    average_vehicle_fill_rate: "평균 차량 적재율",
    unplanned_load_proportion: "계획되지 않은 적재 비율",
    average_vehicle_utilization_as_per_sob: "SOB에 따른 차량 평균 활용도",
    ontime_dispatches: "정시 파견",
    local: "로컬",
    upcountry: "업컨트리",
    ontime_deliveries: "정시 배송",
    bar: "바",
    load: "적재",
    month: "월",
    percentage: "%",






    
      //code for configurations screen

      "Consider Transporter SOB": "운송업체 SOB 고려하기",
      compare: "비교하다",
      forecast_summary: "예보 요약",
    pickup_locations_drop: "픽업 위치 (드롭)",
      "note_highlighted_rows_red": "참고: 1. 빨간색으로 강조된 행은 GPS 데이터 또는 통합이 부족하여 차량 가용성을 수동으로 표시해야 합니다.",
  "highlighted_rows_green": "2. 녹색으로 강조된 행은 계획에 고려됩니다",
  "enable_reconciliation": "조정을 활성화",
  "enable_dc_week_off_planning": "데이터 센터 주간 계획 비활성화를 활성화",
      enable_disable: "활성화/비활성화",
      "Consider Dropping Distant Smaller Loads":
        "먼 거리의 작은 화물 제외 고려",
      "Consider Realtime Vehicle Available Times":
        "실시간 차량 이용 가능 시간 고려",
      "Plant Wise Vehicle Type Configuration*": "공장별 차량 유형 구성*",
      "Consignees / Dealers Locations (Drop)*": "수하인 / 딜러 위치 (하차)*",
      "Pickup Locations (Pickup)*": "픽업 위치 (픽업)*",
      "Optimization Type*": "최적화 유형*",
      "Plant Wise Planning History*": "공장별 계획 이력*",
      "Plant Wise Plan Summary*": "공장별 계획 요약*",
      "Plant Wise New Plan Creation*": "공장별 새 계획 생성*",
      "Suppliers / Vendor Locations (Pickup)*":
        "공급업체 / 판매업체 위치 (픽업)*",
      "Plant Wise Vehicle Type Configuration*": "공장별 차량 유형 구성*",
      "Consignees / Dealers Locations (Drop)*": "수하인 / 딜러 위치 (하차)*",
      "Pickup Locations (Pickup)*": "픽업 위치 (픽업)*",
      "Plant Wise Vehicle Type Configuration*": "공장별 차량 유형 구성*",
      "Suppliers / Vendor Locations (Pickup)*":
        "공급업체 / 판매업체 위치 (픽업)*",
      "Plant Wise Planning History*": "공장별 계획 이력*",
      "Plant Wise Plan Summary*": "공장별 계획 요약*",
      "Plant Wise New Plan Creation*": "공장별 새 계획 생성*",
      "Enable/Disable Dispatch Planning Module":
        "출발 계획 모듈 활성화/비활성화",
      Inbound: "입고",
      Outbound: "출고",
      "Suppliers / Vendor Locations (Pickup)":
        "공급업체 / 판매업체 위치 (픽업)",
      "Operational Hours": "운영 시간",
      "Pickup Locations (Drop)": "픽업 위치 (하차)",
      "Plant Wise Vehicle Type Configuration": "공장별 차량 유형 구성",
      "Max No. Of Vehicle Types": "최대 차량 유형 수",
      "Vehicle Load Capacity Min / Max Configuration Preferences":
        "차량 적재 용량 최소/최대 구성 선호도",
      "Vehicle Load Weight Min / Max Preferences":
        "차량 적재 중량 최소/최대 선호도",
      "Distance preferences": "거리 선호도",
      "Vehicle Priorities": "차량 우선순위",
      "Vehicle Operational Times": "차량 운영 시간",
      "Vehicle Availability": "차량 가용성",
      "Plant Wise Freight Configuration": "공장별 화물 구성",
      "Plant Wise Transporter Configuration": "공장별 운송업체 구성",
      "Route Preferences": "경로 선호도",
      "Plant Wise New Plan Creation": "공장별 새 계획 생성",
      "Plant Wise Plan Summary": "공장별 계획 요약",
      "Plant Wise Planning History": "공장별 계획 이력",
      "No of Vehicles Types": "차량 유형 수",
      "Generic Time Windows": "일반 시간 창",
      "Individual Vehicle Time Windows": "개별 차량 시간 창",
      "Availability By Vehicle Type": "차량 유형별 가용성",
      "Availability By Individual Vehicle": "개별 차량별 가용성",
      "Monthly Minimum Planning Distance Preferences":
        "월간 최소 계획 거리 선호도",
      "GPS Based Availability": "GPS 기반 가용성",
      "Truck Location Visibility": "트럭 위치 가시성",
      "Plant Vicinity Circle Radius": "공장 근접 원 반경",
      "Fixed Vehicle Costs": "고정 차량 비용",
      "Variable Costs": "가변 비용",
      "Vehicle Type Level Variable Costs": "차량 유형별 가변 비용",
      "City Level Vehicle Type Costs": "도시별 차량 유형 비용",
      "Per CFT Cost": "CFT 당 비용",
      "Per Mile Cost": "마일 당 비용",
      "Zone Level Cost": "지역 수준 비용",
      "Transporter Share of Business": "운송업체의 비즈니스 비율",
      "Sustainability Integration": "지속 가능성 통합",
      "Optimization Type": "최적화 유형",
      "Cost Optimization": "비용 최적화",
      "Weight based optimization": "무게 기반 최적화",
      "Distance based optimization": "거리 기반 최적화",
      "Time based optimization": "시간 기반 최적화",
      "Volume based optimization": "부피 기반 최적화",
      "Prioritize Local Loads": "현지 화물 우선",
      "Radius Miles": "반경 (마일)",
      "Number of Loading Docks": "적재 도크 수",
      "City Based Entry Restriction": "도시 기반 진입 제한",
      "Unloading time per case/max unloading time": "케이스당/최대 하역 시간",
      "Consider Vehicle Break Times (Night Driving)":
        "차량 휴식 시간 (야간 운전) 고려",
      "Consider Holiday Restrictions": "휴일 제한 고려",
      "Vehicle Restriction": "차량 제한",
      "Plan Spot Vehicles": "현장 차량 계획",
      "Max No of Customers Per Vehicle": "차량당 최대 고객 수",
      "Use Dynamic Planning Window": "동적 계획 창 사용",
      "Number of Days to Use Planning Window": "계획 창 사용 일수",
      "Use Volume for Vehicle Fill Rate (VFR)": "차량 충전율 (VFR)에 부피 사용",
      "Use Weight for Vehicle Fill Rate (VFR)": "차량 충전율 (VFR)에 무게 사용",
      "Min VFR Per Vehicle Type": "차량 유형별 최소 VFR",
      "Min VFR Per Spot Vehicle": "현장 차량별 최소 VFR",
      "Prioritize Same Dealers First in a Vehicle": "차량 내 동일한 딜러 우선",
      "Prioritize Multi City Dealers First in a Vehicle":
        "차량 내 여러 도시 딜러 우선",
      "Dispatch within Days": "며칠 내에 발송",
      "Allow Unplanned Loads": "계획되지 않은 화물 허용",
      "Minimize Number of Vehicles Used": "사용 차량 수 최소화",
      "Dealer Buffer Time": "딜러 버퍼 시간",
      "Buffer Time(Mins)": "버퍼 시간(분)",
      "Month End Planning Start Day": "월말 계획 시작일",
      "Day of Every Month": "매월의 하루",
      "Max Local No of Customers Per Vehicle": "차량당 최대 현지 고객 수",
      "Max Upcountry No of Customers Per Vehicle": "차량당 최대 시외 고객 수",
      "Planning Type": "계획 유형",
      "Local Prioritization": "현지 우선",
      "Local Prioritization and Virtual Upcountry clubbing":
        "현지 우선 및 가상 시외 통합",
      "General Plan (Local and Upcountry clubbing)":
        "일반 계획 (현지 및 시외 통합)",
      "Telescopic Deviation": "망원 편차",
      "MOQ for Planning": "계획을 위한 MOQ",
      Reconciliation: "조정",
      "DC Week Off planning": "DC 주간 휴무 계획",
      "Save Inbound Configurations": "입고 구성 저장",
      "Save Outbound Configurations": "출고 구성 저장",
      SAVE: "저장",
      "Enable Reconciliation": "조정 활성화",
      "Enable DC Week Off planning": "DC 주간 휴무 계획 활성화",
      "Deviation Alert": "편차 경고",
      "Overload Warning": "과부하 경고",
      "Underload Alert": "적재 부족 경고",
      "Fuel Efficiency Report": "연료 효율 보고서",
      "Vehicle Downtime": "차량 가동 중단 시간",
      "Speed Violation Alert": "속도 위반 경고",
      "Trip Delay Notification": "여행 지연 알림",
      "Idle Time Monitoring": "유휴 시간 모니터링",
      "Driver Performance": "운전사 성과",
      "Realtime GPS Tracking": "실시간 GPS 추적",
      "Fleet Utilization": "차량단 이용",
      "Trip Efficiency": "여행 효율성",
      "Dynamic Route Adjustment": "동적 경로 조정",
      "Load Distribution Optimization": "화물 분배 최적화",
      "Transporter Preferences": "운송업체 선호도",
      "Trip Schedule": "여행 일정",
      "Driver Assignment": "운전사 할당",
      "Optimize Vehicle Utilization": "차량 이용 최적화",
      "Time Based Planning": "시간 기반 계획",
      "Plant Wise Route Planning": "공장별 경로 계획",
      "Vehicle Rotation Configuration": "차량 회전 구성",
      "Inbound Vehicle Preferences": "입고 차량 선호도",
      "Outbound Vehicle Preferences": "출고 차량 선호도",
      "Vehicle Fill Rate Optimization": "차량 충전율 최적화",
      "Dynamic Load Allocation": "동적 화물 할당",
      "Start Date": "시작일",
      "Run Plan": "계획 실행",
      "Completion Date": "완료일",
      "Transporter Name": "운송업체 이름",
      "Driver Allocation": "운전사 할당",
      "Max Load Capacity": "최대 적재 용량",
      "Estimated Delivery Time": "예상 배송 시간",
      "Use Real-Time Traffic Data": "실시간 교통 데이터 사용",
      "Driver Break Times": "운전사 휴식 시간",
      "Max Vehicle Load Percentage": "최대 차량 적재율",
      "Telescopic Deviation Settings": "망원 편차 설정",
      "Deviation Type": "편차 유형",
      "Spot Vehicle Usage": "현장 차량 사용",
      "Real-time Load Monitoring": "실시간 화물 모니터링",
      "Driver Allocation Time": "운전사 할당 시간",
      "Use Traffic Data": "교통 데이터 사용",
      "Optimize Route": "경로 최적화",
      "Optimize Fuel Efficiency": "연료 효율 최적화",
      "Daily Vehicle Plan": "일일 차량 계획",
      "Transporter Allocation": "운송업체 할당",
      "Enable Real-time Tracking": "실시간 추적 활성화",
      "Vehicle Rotation Plan": "차량 회전 계획",
      "Route Optimization Preferences": "경로 최적화 선호도",
      "Consider Weather Conditions": "날씨 조건 고려",
      "Max Loading Time": "최대 적재 시간",
      "Min Load Capacity": "최소 적재 용량",
      "Driver Scheduling": "운전사 일정 계획",
      "Vehicle Capacity Monitoring": "차량 용량 모니터링",
      "Dynamic Load Balancing": "동적 화물 균형 조정",
      "Last Mile Optimization": "마지막 마일 최적화",
      "Unloading Time Management": "하역 시간 관리",
      "Daily Trip Plan": "일일 여행 계획",
      "Delivery Window Optimization": "배송 창 최적화",
      "Night Driving Restrictions": "야간 운전 제한",
      "Weekend Planning": "주말 계획",

      run_new_plan: "새 계획 실행",
      planned_distance_miles: "계획된 거리 (마일)",
      restriction_hours: "제한 시간",
      transporter_details: "운송업체 세부 정보",
      save_geofence: "지오펜스 저장",
      no_data: "데이터 없음",
      run_new_plan: "새 계획 실행",
      new_plan_details: "새 계획 세부 사항",
      order: "주문",
      vehicle_configs: "차량 구성",
      order_information: "주문 정보",
      plan_configs: "계획 구성",
      vehicle_availability: "차량 가용성",
      vehicle_type: "차량 유형",
      vehicle_name: "차량 이름",
      vehicle_min_weight: "차량 최소 중량(파운드)",
      vehicle_max_weight: "차량 최대 중량(파운드)",
      vehicle_min_volume: "차량 최소 부피(입방 피트)",
      vehicle_max_volume: "차량 최대 부피(입방 피트)",
      max_distance_per_trip: "여행당 최대 거리(마일)",
      vehicle_priority: "차량 우선 순위",
      enable_reconciliation: "조정 활성화",
      enable_dc_week_off_planning: "DC 주간 휴무 계획 활성화",
      order_number: "주문 번호",
      invoicing_date: "청구서 발행일",
      quantity: "수량",
      demand_cft: "수요(입방 피트)",
      weight_pounds: "무게(파운드)",
      dealer_available_from: "딜러 사용 가능 시간(시작)",
      dealer_available_to: "딜러 사용 가능 시간(끝)",
      delivery_date: "배송 날짜",
      general_settings: "일반 설정",
      prioritize_local_loads: "지역 화물 우선 처리",
      consider_dropping_distant_smaller_loads: "먼 거리 소형 화물 제외 고려",
      plan_spot_vehicles: "임시 차량 계획",
      city_based_entry_restriction: "도시 기반 진입 제한",
      consider_vehicle_break_times: "차량 휴식 시간을 고려(야간 운전)",
      consider_holiday_restrictions: "휴일 제한 고려",
      consider_realtime_vehicle_available_times: "실시간 차량 가용 시간 고려",
      consider_transporter_sob: "운송업체 SOB 고려",
      planning_optimization_settings: "계획 최적화 설정",
      use_dynamic_planning_window: "동적 계획 창 사용",
      number_of_days_to_use_planning_window: "계획 창을 사용할 일수",
      optimization_type: "최적화 유형",
      cost_optimization: "비용 최적화",
      weight_based_optimization: "무게 기반 최적화",
      distance_based_optimization: "거리 기반 최적화",
      time_based_optimization: "시간 기반 최적화",
      volume_based_optimization: "부피 기반 최적화",
      vehicle_fill_rate_vfr_settings: "차량 충전율(VFR) 설정",
      use_volume_for_vfr: "차량 충전율에 부피 사용",
      use_weight_for_vfr: "차량 충전율에 무게 사용",
      min_vfr_per_vehicle_type: "차량 유형별 최소 충전율",
      min_vfr_per_spot_vehicle: "임시 차량별 최소 충전율",
      moq_for_planning: "계획에 대한 최소 주문 수량(MOQ)",
      load_planning_settings: "화물 계획 설정",
      number_of_loading_docks: "적재 도크 수",
      max_local_no_of_customers_per_vehicle: "차량당 최대 지역 고객 수",
      max_upcountry_no_of_customers_per_vehicle: "차량당 최대 지방 고객 수",
      prioritize_same_dealers_first: "같은 딜러를 차량 내에서 우선 처리",
      prioritize_multi_city_dealers_first:
        "여러 도시 딜러를 차량 내에서 우선 처리",
      note_highlighted_rows_red:
        "빨간색으로 강조된 행은 GPS 데이터 또는 통합이 부족합니다. 차량 가용성을 수동으로 표시하세요.",
      note_highlighted_rows_green:
        "초록색으로 강조된 행은 계획에 포함되었습니다",
      vehicle_number: "차량 번호",
      transit_type: "운송 유형",
      monthly_minimum_distance: "월별 최소 거리",
      remaining_distance: "남은 거리",
      vehicle_available_date: "차량 가용 날짜(YYYY-MM-DD HH:mm)",
      charlie_hub: "찰리 허브",
      enable_recommendation: "추천 활성화",
      planned_day: "계획된 날",
      drop_sequence: "하차 순서",
      drop_location_code_destination: "하차 위치 코드(목적지)",
      drop_location_name_destination: "하차 위치 이름(목적지)",
      destination_location_city: "목적지 도시",
      weight_kgs: "무게(kg)",
      volume_m3: "부피(입방 미터)",
      distance_to_destination_kms: "목적지까지의 거리(킬로미터)",
      expected_delivery_date: "예상 배송 날짜",
      time_to_destination: "목적지까지의 시간",
      transit_time_hh_mm: "운송 시간(시:분)",
      unloading_time_hh_mm: "하차 시간(시:분)",
      plan_id: "계획 ID",
      generated_on: "생성일",
      vehicle_availability_from: "차량 가용 시간(시작)",
      vehicle_availability_to: "차량 가용 시간(끝)",
      break_time_from: "휴식 시간(시작)",
      break_time_to: "휴식 시간(끝)",
      min_volume_m3: "최소 부피(입방 미터)",
      max_volume_m3: "최대 부피(입방 미터)",
      vehicle_width_mm: "차량 너비(mm)",
      vehicle_height_mm: "차량 높이(mm)",
      vehicle_depth_mm: "차량 깊이(mm)",
      material_code: "재료 코드",
      material_name: "재료 이름",
      material_type: "재료 유형",
      material_weight: "재료 무게",
      material_dimensions_depth: "재료 깊이 치수",
      material_dimensions_width: "재료 너비 치수",
      material_dimensions_height: "재료 높이 치수",
      state: "주/도",
      city: "도시",
      fixed_vehicle_cost: "고정 차량 비용",
      driver_cost: "운전자 비용",
      maintenance_cost: "유지 관리 비용",
      per_cmt_cost: "입방 피트당 비용",
      per_km_cost: "킬로미터당 비용",
      date: "날짜",
      transporter_name: "운송업체 이름",
      no_of_vehicles: "차량 수",
      driver_break_time_from: "운전자 휴식 시간(시작)",
      vehicle_max_weight_kgs: "차량 최대 무게(kg)",
      vehicle_min_volume_m3: "차량 최소 부피(입방 미터)",
      vehicle_max_volume_m3: "차량 최대 부피(입방 미터)",
      max_distance_kms: "최대 거리(킬로미터)",
      monthly_min_planning_distance_kms: "월간 최소 계획 거리(킬로미터)",
      details: "세부 사항",
      trip_no: "여행 번호",
      vehicle_no: "차량 번호",
      no_of_orders: "주문 수",
      no_of_customers: "고객 수",
      total_quantity: "총 수량",
      vehicle_utilization_percent: "차량 이용률(%)",
      total_planned_weight_kgs: "총 계획 무게(kg)",
      onward_trip_distance_kms: "가는 길 거리(킬로미터)",
      round_trip_distance_kms: "왕복 거리(킬로미터)",
      total_planned_volume_m3: "총 계획 부피(입방 미터)",
      max_dispatch_time_hh_mm: "최대 배송 시간(시:분)",
      arrival_at_first_dealer_start: "첫 번째 딜러 도착(시작)",
      departure_at_last_dealer_end: "마지막 딜러 출발(끝)",
      return_to_plant_hh_mm: "공장 복귀 시간(시:분)",
      total_planned_trip_time_hrs: "총 계획된 여행 시간(시간)",
      total_round_trip_time_hrs: "총 왕복 시간(시간)",
      total_transit_time_hh_mm: "총 운송 시간(시:분)",
      total_loading_time_hh_mm: "총 적재 시간(시:분)",
      total_unloading_time_hh_mm: "총 하차 시간(시:분)",
      drop_location_code: "하차 위치 코드",
      drop_location_name: "하차 위치 이름",
      drop_location_city: "하차 위치 도시",
      demand_volume_m3: "수요 부피(입방 미터)",
      vehicle_min_weight_kgs: "차량 최소 무게(kg)",
      no_of_deliveries: "배송 횟수",
      min_load_per_delivery: "배송당 최소 적재량",
      status: "상태",
      processing: "처리 중",
      please_contact_admin: "관리자에게 문의하세요",
      solver_complete: "솔버 완료",
      solver_reco_processed: "추천 처리 완료",
      solver_sob_done: "SOB 완료",
      solver_invalid_input_dealer_location: "유효하지 않은 딜러 위치 입력",
      solver_invalid_input_materials: "유효하지 않은 재료 입력",
      solver_invalid_input_dealer_location_and_materials:
        "유효하지 않은 딜러 위치 및 재료 입력",
      solver_input_data_not_valid: "유효하지 않은 입력 데이터",
      solver_fix_dropped_dealers: "제외된 딜러 수정",
      solver_vehicle_details_missing: "차량 세부 정보 누락",
      solver_input_file_data_error: "입력 파일 데이터 오류",
      solver_invalid_file_template: "유효하지 않은 파일 템플릿",
      process_timed_out: "처리 시간이 초과되었습니다",
      solver_sob_fail: "SOB 실패",
      solver_reco_failed: "추천 실패",
      solver_no_tool_config: "도구 설정 없음",
      solver_invalid_tool_config: "유효하지 않은 도구 설정",
      transit_time_with_multiple_city_clubing: "여러 도시 결합 운송 시간",
      rdd_preference: "RDD 우선 순위",
      transit_time_with_single_city_clubing: "단일 도시 결합 운송 시간",
      no_of_trucks_planned: "계획된 트럭 수",
      view_details: "세부 정보 보기",
      plan_run_by: "계획 실행자",
      compare_plans: "계획 비교",
      scenario_name: "시나리오 이름",
      pick_up_location_source: "픽업 위치(출발지)",
      pickup_location_source: "픽업 위치(출발지)",
      drop_location_destination: "하차 위치(목적지)",
      demand_m3: "수요(입방 미터)",
      location_radius: "위치 반경(킬로미터)",
      volume_cmt: "부피(CMT)",
      obd_details_in_radius: "반경 내 OBD 세부 정보",
      planning_configurations: "계획 구성",
      planning_scenarios: "계획 시나리오",
      what_if_demand_fluctuation: "수요 변동 시",
      increase_decrease_demand_by: "수요 증가/감소 비율",
      what_if_customer_priority_changes: "고객 우선 순위 변경 시",
      prioritize_upcountry: "지방 우선 처리",
      prioritize_local: "지역 우선 처리",
      what_if_service_level_target_changes: "서비스 수준 목표 변경 시",
      set_service_level_target_to: "서비스 수준 목표를 설정",
      what_if_vehicle_fill_rate_changes: "차량 충전율 변경 시",
      set_vehicle_fill_rate_to: "차량 충전율을 설정",
      what_if_customer_specific_delivery_time: "고객 맞춤형 배송 시간 설정 시",
      increase_decrease_delivery_time_window_by: "배송 시간 창 증가/감소 비율",
      scenario_description: "시나리오 설명",
      vehicle_fill_rate_settings: "차량 충전율 설정",
      file_name: "파일 이름",
      total_weight_kgs: "총 무게(kg)",
      total_demand_volume_m3: "총 수요 부피(입방 미터)",
      processing_plan_please_wait:
        "계획 처리 중입니다. 잠시만 기다려 주세요...",
      forecast_input_information: "예측 입력 정보",
      uploading_data_for_plant: "공장용 데이터 업로드 중",
      vehicle_type_configuration: "차량 유형 구성",
      min_volume_cft: "최소 부피(입방 피트)",
      max_volume_cft: "최대 부피(입방 피트)",
      cost_per_km: "킬로미터당 비용",
      note_duplicate_scenario: "참고: 중복된 시나리오",
      select_unique_scenarios_to_compare: "비교할 고유한 시나리오를 선택하세요",
      scenario_selection_limit_exceeded: "시나리오 선택 한도 초과",
      note_scenario_selection_limit:
        "참고: 최대 3개의 시나리오를 선택할 수 있습니다",
      select_scenario_to_compare: "비교할 시나리오를 선택하세요",
      forecast_scenarios: "예측 시나리오",
      select_planning_duration: "계획 기간 선택",
      notify_email_users_after_process:
        "처리 후 이메일 사용자에게 알림(쉼표로 구분)",
      optimize_by_time_details: "시간별 최적화",
      max_time_limit_hrs: "최대 시간 제한(시간)",
      spot_vehicles_to_be_planned: "계획될 임시 차량",
      auto_select: "자동 선택",
      redirecting_to_vehicle_checklist: "차량 체크리스트로 리디렉션 중",
      min_vfr: "최소 VFR",
      order_information: "주문 정보",
      vehicle_type_configurations: "차량 유형 구성",
      vehicle_routes: "차량 경로",
      total_customers: "총 고객",
      planned: "계획된",
      unplanned: "계획되지 않은",
      total_round_trip_distance_miles: "왕복 거리 총합 (마일)",
      planning: "계획",
      vfr: "VFR",
      ontime_dispatch: "정시 배차",
      avg_tat: "평균 TAT",
      view_locations: "위치 보기",
      view_routes: "경로 보기",
      additional_vehicles: "추가 차량",
      regular_vehicles: "정규 차량",
      enter_vehicle_no: "차량 번호 입력",
      vehicle_has_active_trip_shipment_id:
        "차량에 활성화된 여행 화물 ID가 있습니다",
      do_you_wish_to: "하시겠습니까",
      force_close_assign: "강제 종료 및 할당",
      force_close_reason: "강제 종료 이유",
      gps_issue: "GPS 문제",
      trip_closed: "여행 종료",
      load_cancelled: "로드 취소됨",
      new_trip_allocated: "새 여행 할당됨",
      driver_changed: "운전자가 변경됨",
      are_you_sure_you_want_to_update: "정말 업데이트 하시겠습니까",
      edit_plan_for_same: "같은 계획을 편집",
      update_vehicle_plan: "차량 계획 업데이트",
      max_dispatch_time: "최대 배차 시간",
      new_plan: "새 계획",
      reason_for_plan_change: "계획 변경 이유",
      enter_the_reason: "이유를 입력하세요",
      reason_for_plan_deletion: "계획 삭제 이유",
      are_you_sure_delete_plan: "정말 계획을 삭제하시겠습니까",
      update_order_details: "주문 세부 정보 업데이트",
      demand_volume: "수요량",
      dealer_availability_start_time: "딜러 이용 가능 시작 시간",
      dealer_availability_end_time: "딜러 이용 가능 종료 시간",
      configurations: "구성",
      download: "다운로드",
      dealerTimeError: "딜러의 시작 시간이 종료 시간보다 작아야 합니다",
      more: "더",
      less: "덜",
      qualityChecks: "품질 검사",
      currentMonthPlanDistance: "이번 달 계획 거리",
      email: "이메일",
      viewPlan: "계획 보기",
      blank_rows_columns_not_allowed: "빈 행/열은 허용되지 않습니다.",
      drop_location_name: "하차 위치 이름",
      drop_location_city: "하차 위치 도시",
      order_number: "주문 번호",
      delivery_date: "배송 날짜 (M/D/YYYY)",
      back: "뒤로",
      arrival_at_first_dealer_start: "첫 번째 딜러 도착 (시작)",
      departure_at_last_dealer_end: "마지막 딜러 출발 (종료)",
      return_to_plant_hh_mm: "공장 복귀 (HH:MM)",
      total_planned_trip_time_hrs: "총 계획된 여행 시간 (시간)",
      total_round_trip_time_hrs: "총 왕복 시간 (시간)",
      dealer_available_to: "딜러 이용 가능 시간 (종료)",
      driver_cost: "운전사 비용 ($)",
      in_transit_to_customer: "고객으로 이동 중",
      security_conclusion: "보안 결론",
      security_comments: "보안 의견",
      stacking: "적재",
      bulk_upload_master_vehicles: "마스터 차량 대량 업로드",
      add_master_vehicle: "마스터 차량 추가",
      dealer_available_time_error: "시작 시간은 종료 시간보다 빨라야 합니다",
      plant_wise_configurations: "공장별 설정",
      monthly_min_planning_distance: "월간 최소 계획 거리",
      pickup_point: "픽업 지점",
      fix_and_reupload: "수정하고 다시 업로드",
      club_previous_order: "이전 주문 병합",
      ignore: "무시",
      dealer_code: "딜러 코드",
      remarks: "비고",
      run_new_plan: "새 계획 실행",
      consolidated_route_plan_screen: "통합 경로 계획 화면",
      actions: "작업",
      import_existing_vehicle_details: "기존 차량 세부 정보 가져오기",
      uploading_data_for_plant: "공장 데이터를 업로드 중",
      check_and_remove_blank_data:
        "빈 데이터를 확인하고 제거하려면, 찾기 및 선택 → 특수로 이동 → 빈 셀을 사용하고 강조된 행을 삭제하십시오.",
      add_vehicle_type: "차량 유형 추가",
      vehicle_operational_hours: "차량 운영 시간 (HH:MM)",
      data_saved_successfully: "데이터가 성공적으로 저장되었습니다",
      plant_wise_configurations: "공장별 구성",
      save_plant_wise_configurations: "공장별 구성 저장",
      vehicle_break_window: "차량 휴식 시간 (HH:MM)",
      total_vehicles: "차량 총 수",
      zone_configurations: "지역 구성",
      pickup_point: "픽업 지점",
      actions: "작업",
      edit: "편집",
      add_transporter_details: "+ 운송업체 세부 정보 추가",
      cost_per_mile: "마일당 비용",
      freight_details: "화물 세부 정보",
      add_zone: "+ 지역 추가",
      fixed_vehicle_cost_title: "고정 차량 비용 ($)",
      variable_vehicle_cost_title: "가변 차량 비용",
      city_wise_cost: "도시별 차량 비용",
      zone_wise_cost: "지역별 차량 비용",
      vehicle_cost_configurations: "차량 비용 구성",
      freight_cost_label: "화물 비용",
      validity_to_label: "유효 기간",
      validity_from_label: "유효 시작일",
      actions_label: "작업",
      edit_button: "편집",
      delete_button: "삭제",
      save_button: "저장",
      cancel_button: "취소",
      select_vehicle_type: "차량 유형 선택",
      select_transporter_name: "운송업체 이름 선택",
      zone: "지역",
      state: "주",
      driver_cost: "운전자 비용 ($)",
      maintenance_cost: "유지보수 비용 ($)",
      fixed_vehicle_cost_label: "고정 차량 비용 ($)",
      enable_disable_dispatch_planning_module: "배차 계획 모듈 활성화/비활성화",
      inbound: "입고",
      suppliers_vendor_locations_pickup: "공급업체/판매자 위치 (픽업)",
      operational_hours: "운영 시간",
      pickup_locations_drop: "픽업 위치 (하차)",
      plant_wise_vehicle_type_configuration: "공장별 차량 유형 구성",
      max_no_of_vehicle_types: "최대 차량 유형 수",
      vehicle_load_capacity_min_max_configuration_preferences:
        "차량 적재 용량 최소/최대 구성 선호",
      vehicle_load_weight_min_max_preferences: "차량 적재 무게 최소/최대 선호",
      distance_preferences: "거리 선호",
      vehicle_priorities: "차량 우선 순위",
      vehicle_operational_times: "차량 운영 시간",
      vehicle_availability: "차량 가용성",
      generic_time_windows: "일반 시간 창",
      individual_vehicle_time_windows: "개별 차량 시간 창",
      availability_by_vehicle_type: "차량 유형별 가용성",
      availability_by_individual_vehicle: "개별 차량별 가용성",
      monthly_minimum_planning_distance_preferences: "월간 최소 계획 거리 선호",
      gps_based_availability: "GPS 기반 가용성",
      truck_location_visibility: "트럭 위치 가시성",
      plant_vicinity_circle_radius: "공장 인근 반경",
      plant_wise_freight_configuration: "공장별 화물 구성",
      plant_wise_transporter_configuration: "공장별 운송업체 구성",
      transporter_share_of_business: "운송업체의 사업 비율",
      route_preferences: "경로 선호",
      plant_wise_new_plan_creation: "공장별 새 계획 생성",
      plant_wise_plan_summary: "공장별 계획 요약",
      plant_wise_planning_history: "공장별 계획 기록",
      sustainability_integration: "지속 가능성 통합",
      save_inbound_configurations: "입고 구성 저장",
      save_outbound_configurations: "출고 구성 저장",
      outbound: "출고",
      consignees_dealers_locations_drop: "수하인/딜러 위치 (하차)",
      vehicles: "차량",
      total_trucks: "총 트럭 수",
      active: "활성",
      cost_details: "비용 세부사항",
      fixed_vehicle_cost: "고정 차량 비용",
      variable_vehicle_cost: "변동 차량 비용",
      city_wise_vehicle_cost: "도시별 차량 비용",
      zone_wise_vehicle_cost: "구역별 차량 비용",
      pickup_point: "픽업 지점",
      vehicle_type: "차량 유형",
      transporter_name: "운송업체 이름",
      cost_per_mile_below_sob: "마일당 비용 (SOB 이하)",
      cost_per_mile_after_sob: "마일당 비용 (SOB 이후)",
      validity_from: "유효 기간 시작",
      validity_to: "유효 기간 종료",
      add_vehicle_cost: "+ 차량 비용 추가",
      freight_details: "화물 세부사항",
      select_transporter: "운송업체 선택",
      select_zone: "구역 선택",
      select_state: "주 선택",
      city: "도시",
      cost: "비용 ($)",
      save: "저장",
      cancel: "취소",
      actions: "작업",
      validity_from_label: "유효 기간 시작",
      validity_to_label: "유효 기간 종료",
      get_data: "데이터 가져오기",
      state: "주",
      zone: "구역",
      fixed_vehicle_cost_label: "고정 차량 비용 ($)",
      driver_cost: "운전사 비용 ($)",
      maintenance_cost: "유지 비용 ($)",
      city: "도시",
      zone: "구역",
      vehicle_cost: "차량 비용",
      freight_cost: "화물 비용",
      inactive: "비활성",
      inside_dc: "DC 내",
      in_transit_to_customer: "고객으로 이동 중",
      near_customer: "고객 근처",
      return_to_dc: "DC로 복귀",
      empty_outside_dc: "DC 외부 비어 있음",
      zone_configurations: "구역 설정",
      transporters_configurations: "운송업체 설정",
      truck_no: "트럭 번호",
      facility_name: "시설 이름",
      zones: "구역",
      actions: "작업",
      edit: "편집",
      delete: "삭제",
      add_zone_mapping: "구역 매핑 추가",
      current_status: "현재 상태",
      next_availability: "다음 사용 가능 시간",
      dc_location: "DC 위치",
      add_route_restriction: "경로 제한 추가",
      location_code: "위치 코드",
      location_name: "위치 이름",
      restricted_area: "제한 구역",
      vehicle_list: "차량 목록",
      from_time: "시작 시간",
      to_time: "종료 시간",
      actions: "작업",
      edit: "편집",
      customer_location: "고객 위치",
      truck_no: "트럭 번호",
      facility_name: "시설 이름",
      current_status: "현재 상태",
      inside_dc: "DC 내",
      in_transit_to_customer: "고객으로 이동 중",
      near_customer: "고객 근처",
      return_to_dc: "DC로 복귀",
      empty_outside_dc: "DC 외부 비어 있음",
      unplanned: "계획되지 않음",
      next_availability_date_at_dc: "DC에서의 다음 사용 가능 날짜",
      transporter_name: "운송업체 이름",
      gps_status: "GPS 상태",
      inactive: "비활성",
      active: "활성",
      na: "해당 없음",
      actions: "작업",
      date_and_time: "날짜와 시간",
      security: "보안",
      quality: "품질",
      assessed_by: "평가자",
      quality_conclusion: "품질 결론",
      quality_comments: "품질 의견",
      quality_approved: "품질 승인",
      quality_date_of_assessment: "품질 평가 날짜",
      facility_name: "시설 이름",
      vehicle_number: "차량 번호",
      vehicle_type: "차량 유형",
      transporter_code: "운송업체 코드",
      transporter_name: "운송업체 이름",
      last_packet_time: "마지막 패킷 시간",
      vehicle_checklist_for_transportation: "운송용 차량 체크리스트",
      assessed_by: "평가자",
      security_conclusion: "보안 결론",
      security_comments: "보안 의견",
      zone_mapping: "존 매핑",
      transporters: "운송업체",
      material_configurations: "자재 설정",
      freight_configurations: "화물 설정",
      route_configurations: "경로 설정",
      auto_clustering: "자동 클러스터링",
      transporter_share_of_business: "운송업체 사업 비율",
      dispatch_plan_summary: "배차 계획 요약",
      kpi_dashboard: "KPI 대시보드",
      planning_tools: "계획 도구",
      forecast_tools: "예측 도구",
      suppliers: "공급업체",
      customers: "고객",
      materials: "자재",
      input_data: "입력 데이터",
      forecast: "예측",
      previous_plan: "이전 계획",
      trips: "여행",
      gps_quality: "GPS 품질",
      truck_gps_data: "트럭 GPS 데이터",
      unscheduled_stoppages: "예정되지 않은 정차",
      deviation_reports: "편차 보고서",
      fo_data: "FO 데이터",
      vehicle_checklist: "차량 점검표",
      vehicle_status: "차량 상태",
      route_restrictions: "경로 제한",
      consolidated_run_plan: "통합 실행 계획",
      plans_overview: "계획 개요",
      input_plan_summary: "입력 계획 요약",
      load_plan_summary: "적재 계획 요약",
      plant_wise_configurations: "공장별 설정",
      vehicle_configuration: "차량 설정",
      monthly_data: "월간 데이터",
      forecast_plan_history: "예측 계획 기록",
      obd_analysis: "OBD 분석",
      actions: "작업",
      pickup_location_source: "픽업 위치 (출발지)",
      drop_location_destination: "하차 위치 (목적지)",
      order_number: "주문 번호",
      invoicing_date: "청구일 (M/D/YYYY)",
      quantity: "수량",
      dealer_available_time_from: "딜러 이용 가능 시간 (00:00부터)",
      dealer_available_time_to: "딜러 이용 가능 시간 (23:59까지)",
      volume_cft: "부피 (CFT)",
      weight_pounds: "무게 (파운드)",
      delivery_date: "배송일 (M/D/YYYY)",
      no_rows_to_show: "표시할 행이 없음",
      rows: "행",
      total_rows: "총 행수",
      add_dispatch: "배차 추가",
      run_new_plan_by_file: "파일로 새 계획 실행",
      submit_run_plan: "계획 제출 및 실행",
      delete: "삭제",
      upload_input_file: "입력 파일 업로드",
      upload_file: "파일 업로드",
      download_sample_template: "샘플 템플릿 다운로드",
      save: "저장",
      cancel: "취소",
      dealer_available_time_error: "시작 시간은 종료 시간보다 빨라야 합니다",
      ok: "확인",
      summary_dashboard: "요약 대시보드",
      pickup_point: "픽업 지점",
      date_created: "생성일",
      get_data: "데이터 가져오기",
      plan_details: "계획 세부 정보",
      plan_history: "계획 기록",
      drop_details: "하차 세부 정보",
      generate_plan: "계획 생성",
      order_id: "주문 ID",
      no_of_runs: "실행 횟수",
      created_on: "생성일",
      plan_run_by: "계획 실행자",
      discard_obd: "OBD 폐기",
      upload_obd_details: "OBD 세부 정보 업로드",
      download_template: "템플릿 다운로드",
      choose_file: "파일 선택",
      submit: "제출",
      start_date: "시작일",
      end_date: "종료일",
      get_data: "데이터 가져오기",
      upload_input_file: "입력 파일 업로드",
      discard_obd: "OBD 폐기",
      recent_plans: "최근 계획",
      plan_history: "계획 기록",
      view_input: "입력 보기",
      consolidation_id: "통합 ID",
      created_date: "생성일",
      run_by: "실행자",
      no_of_executions: "실행 횟수",
      execution_status: "실행 상태",
      download_output: "출력 다운로드",
      upload_obd_details: "OBD 세부 정보 업로드",
      download_template: "템플릿 다운로드",
      choose_file: "파일 선택",
      submit: "제출",
      simulation_time: "시뮬레이션 시간",
      enable_dc_week_off_planning: "DC 주간 휴무 계획 활성화",
      run_plan: "계획 실행",
      cancel: "취소",
      plan_details: "계획 세부 사항",
      pickup_point: "픽업 지점",
      date_created: "생성 날짜",
      order_id: "주문 ID",
      no_of_runs: "실행 횟수",
      fix_and_reupload: "수정 후 다시 업로드",
      club_previous_order: "이전 주문 병합",
      ignore: "무시",
      dealer_code: "딜러 코드",
      remarks: "비고",
      run_new_plan: "새 계획 실행",
      consolidated_route_plan_screen: "통합 경로 계획 화면",
      plant_code: "공장 코드",
      plant_name: "공장 이름",
      total_trips: "총 여행 수",
      trips_edited: "수정된 여행",
      edited_transaction_ids: "수정된 거래 ID",
      vehicle_type: "차량 종류",
      vehicle_name: "차량 이름",
      weight_pounds: "무게 (파운드)",
      drop_location_city: "하차 위치 도시",
      quantity: "수량",
      planned_vehicles: "계획된 차량",
      trip_no: "여행 번호",
      total_planned_customers: "계획된 고객 수",
      total_round_trip_distance: "총 왕복 거리",
      planning_percentage: "계획 비율",
      max_dispatch_time: "최대 배차 시간 (HH:MM)",
      total_loading_time: "총 적재 시간 (HH:MM)",
      total_unloading_time: "총 하역 시간 (HH:MM)",
      planned_summary: "계획 요약",
      invalid_orders_not_shown: "참고: 잘못된 주문은 표시되지 않음",
      no_of_customers: "고객 수",
      no_of_orders: "주문 수",
      total_demand_volume: "총 수요량 (CFT)",
      planned_summary: "계획 요약",
      trips_removed: "제거된 여행",
      removed_transaction_ids: "제거된 거래 ID",
      vehicle_forecast_configurations: "차량 예측 설정",
      pickup_point: "픽업 지점",
      vehicle_type: "차량 종류",
      vehicle_name: "차량 이름",
      cost_per_miles: "마일당 비용",
      driver_break_time: "운전자 휴식 시간",
      view: "보기",
      please_select_no_of_vehicles: "차량 수를 선택하세요",
      no_of_vehicles: "차량 수",
      cost_per_miles: "마일당 비용 ($)",
      driver_break_time_from: "운전자 휴식 시간 (시작)",
      monthly_min_planning_distance_miles: "월 최소 계획 거리 (마일)",
      vehicle_type_count: "차량 종류 수",
      driver_break_time_per_day: "일일 운전자 휴식 시간 (HH:MM)",
      cost_per_miles_2: "마일당 비용 ($)",
      set_vehicle_priority: "차량 우선 순위 설정",
      vehicle_type_name: "차량 종류 이름",
      min_volume_cft: "최소 부피 (CFT)",
      max_volume_cft: "최대 부피 (CFT)",
      max_distance_per_trip_miles: "여행당 최대 거리 (마일)",
      select_plan_id: "계획 ID 선택",
      drop_details: "하차 세부 사항",
      drop_location_code: "하차 위치 코드",
      drop_location_name: "하차 위치 이름",
      pickup_location: "픽업 위치",
      dealer_available_from: "딜러 사용 가능 시간 (시작)",
      dealer_available_to: "딜러 사용 가능 시간 (종료)",
      total_planned_weight: "총 계획 무게",
      total_planned_volume: "총 계획 부피",
      arrival_at_first_dealer: "첫 번째 딜러 도착",
      departure_at_last_dealer: "마지막 딜러 출발",
      return_to_plant: "공장으로 복귀",
      summary_dashboard: "요약 대시보드",
      dispatch_plan_details: "배차 계획 세부 사항",
      invalid_orders_note: "참고: 잘못된 주문은 표시되지 않음",
      planned_data: "계획된 데이터",
      vfr_percentage: "VFR 비율",
      cool_chain_adherence: "냉동 체인 준수",
      transit_time: "운송 시간",
      loading_time: "적재 시간",
      unloading_time: "하역 시간",
      planned_summary: "계획 요약",
      invalid_drop_data: "잘못된 하차 데이터",
      vehicle_utilization_km: "차량 이용률 (KM)",
      distance_to_destination: "목적지까지 거리",
      expected_delivery_date: "예상 배송 날짜",
      planned_vehicles: "계획된 차량",
      trip_no: "여행 번호",
      total_quantity: "총 수량",
      dealer_available_to: "딜러 사용 가능 시간 (종료)",
      drop_location_code_destination: "하차 위치 코드 (목적지)",
      order_number: "주문 번호",
      demand_volume_cft: "수요량 (CFT)",
      weight_pounds: "무게 (파운드)",
      drop_location_name_destination: "배송지 이름 (목적지)",
      note_blank_rows_columns_not_allowed: "참고: 빈 행/열은 허용되지 않습니다",
      check_blank_data_instructions:
        "빈 데이터 확인 방법: 1. 찾기 및 선택을 클릭합니다. 2. 특수로 이동을 클릭합니다. 3. 빈 셀을 선택합니다. 4. 확인을 클릭하면 빈 행/셀 모두가 강조 표시됩니다. 5. 홈 탭의 셀 섹션에서 삭제를 선택합니다. 6. 시트 행 삭제를 클릭합니다.",
      disclaimer_dealer_window_ot_customers:
        "면책 조항: OT 고객에게 제공되는 시간 창은 입력 파일에서 고려되며, GT 고객의 시간은 픽업/배송 위치에서 고려됩니다",
      quantity: "수량",
      specifications: "사양",
      please_select_plan_id_to_compare_plans:
        "계획을 비교하려면 계획 ID를 선택하세요",
      vehicle_forecast_summary_dashboard: "차량 예측 요약 대시보드",
      pickup_point: "픽업 지점",
      view_details: "세부 정보 보기",
      compare_plans: "계획 비교",
      pickup_drop_points: "픽업/배송 지점",
      edit: "편집",
      pickup: "픽업",
      drop: "배송",
      both: "둘 다",
      pickup_location_code: "픽업 위치 코드",
      dealer_type: "딜러 유형",
      select: "선택",
      local: "로컬",
      upcountry: "지방",
      vehicle_restriction_list: "차량 제한 목록",
      week_off: "주간 휴무",
      csp_day: "CSP 일",
      is_customer_kdd: "고객이 KDD입니까?",
      start_month: "시작 월",
      end_month: "종료 월",
      plan_id: "계획 ID",
      fecha_creada: "생성 날짜",
      no_of_trucks_planned: "계획된 트럭 수",
      total_planned_volume_cft: "총 계획 부피 (CFT)",
      total_planned_weight_pounds: "총 계획 무게 (파운드)",
      plan_run_by: "계획 실행자",
      planned_vehicles: "계획된 차량",
      trip_no: "여행 번호",
      total_planned_customers: "총 계획 고객",
      total_round_trip_distance: "총 왕복 거리",
      planning_percentage: "계획 비율",
      max_dispatch_time: "최대 출발 시간 (HH:MM)",
      total_loading_time: "총 적재 시간 (HH:MM)",
      total_unloading_time: "총 하역 시간 (HH:MM)",
      planned_summary: "계획 요약",
      invalid_orders_not_shown: "참고: 유효하지 않은 주문은 표시되지 않습니다",
      no_of_customers: "고객 수",
      no_of_orders: "주문 수",
      total_demand_volume: "총 수요 부피 (CFT)",
      planned_summary: "계획 요약",
      trips_removed: "삭제된 여행",
      removed_transaction_ids: "삭제된 거래 ID",
      vehicle_forecast_configurations: "차량 예측 구성",
      pickup_point: "픽업 지점",
      vehicle_type: "차량 유형",
      vehicle_name: "차량 이름",
      cost_per_miles: "마일당 비용",
      driver_break_time: "운전자 휴식 시간",
      view: "보기",
      please_select_no_of_vehicles: "차량 수를 선택하세요",
      no_of_vehicles: "차량 수",
      obd_analysis: "OBD 분석",
      pickup_point: "픽업 지점",
      select_month: "월 선택",
      location_radius_miles: "위치 반경 (마일)",
      submit: "제출",
      total_obd_details: "총 OBD 세부 사항",
      valid_dealers: "유효한 딜러",
      invalid_dealers: "유효하지 않은 딜러",
      volume: "부피",
      help: "도움말",
      change_password: "비밀번호 변경",
      logout: "로그아웃",
      weight_pounds: "무게 (파운드)",
      quantity: "수량",
      obd_details_within_radius: "반경 내 OBD 세부 사항",
      dealers: "딜러",
      dealers_percent: "딜러 비율",
      volume_percent: "부피 비율",
      weight_pounds_percent: "무게 비율 (파운드)",
      quantity_percent: "수량 비율",
      cost_per_miles: "마일당 비용 ($)",
      driver_break_time_from: "운전자 휴식 시간 (시작)",
      monthly_min_planning_distance_miles: "월간 최소 계획 거리 (마일)",
      vehicle_type_count: "차량 유형 수",
      driver_break_time_per_day: "일일 운전자 휴식 시간 (HH:MM)",
      cost_per_miles_2: "마일당 비용 ($)",
      set_vehicle_priority: "차량 우선 순위 설정",
      vehicle_type_name: "차량 유형 이름",
      min_volume_cft: "최소 부피 (CFT)",
      max_volume_cft: "최대 부피 (CFT)",
      max_distance_per_trip_miles: "여행당 최대 거리 (마일)",
      select_plan_id: "계획 ID 선택",
      drop_details: "배송 세부 정보",
      select_vehicle_type: "차량 유형 선택",
      select_transporter_name: "운송업체 이름 선택",
      zone: "지역",
      state: "주",
      driver_cost: "운전자 비용 ($)",
      maintenance_cost: "유지보수 비용 ($)",
      fixed_vehicle_cost_label: "고정 차량 비용 ($)",
      delete: "삭제",
      state: "주",
      city: "도시",
      zone: "지역",
      delta_warehouse: "델타 창고",
      zones: "지역",
      zone_mapping: "지역 매핑",
      city_not_mapped_to_state: "이 도시는 주와 매핑되지 않았습니다",
      actions: "작업",
      add_zones: "+ 지역 추가",
      transporter_master: "운송업체 마스터",
      transporter_configurations: "운송업체 구성",
      transporter_operations: "운송업체 운영",
      transporter_code: "운송업체 코드",
      transporter_name: "운송업체 이름",
      actions: "작업",
      edit: "편집",
      delete: "삭제",
      zone_configuration: "지역 구성",
      vehicle_checklist_for_transportation_requirements:
        "운송 요구 사항을 위한 차량 체크리스트",
      transporters_configuration: "운송업체 구성",
      consolidated_route_plan_screen: "통합 경로 계획 화면",
      plant_code: "공장 코드",
      select_plant_to_check_vehicle_availability:
        "차량 가용성을 확인할 공장 선택",
      vehicle_availability: "차량 가용성",
      note_trucks_near_plant_vicinity: "참고: 공장 근처 트럭 거리 ",
      facility_name: "시설 이름",
      vehicle_number: "차량 번호",
      transporter_code: "운송업체 코드",
      vehicle_status_dashboard: "차량 상태 대시보드",
      dc: "물류 센터",
      drop_location_code: "하차 위치 코드",
      vehicle_utilization: "차량 이용률",
      onward_trip_distance_miles: "출발 거리 (마일)",
      round_trip_miles: "왕복 거리 (마일)",
      max_dispatch_time_hh_mm: "최대 배차 시간 (HH:MM)",
      arrival_at_first_dealer_start: "첫 번째 딜러 도착 (시작)",
      departure_at_last_dealer_end: "마지막 딜러 출발 (종료)",
      return_to_plant_hh_mm: "공장 복귀 (HH:MM)",
      vehicle_number: "차량 번호",
      vehicle_availability_from: "차량 가용 시간 (시작)",
      vehicle_availability_to: "차량 가용 시간 (종료)",
      break_time_from: "휴식 시간 (시작)",
      break_time_to: "휴식 시간 (종료)",
      no_of_vehicles: "차량 수",
      driver_break_time_from: "운전자 휴식 시간 (시작)",
      max_distance_miles: "최대 거리 (마일)",
      monthly_min_planning_distance_miles: "월간 최소 계획 거리 (마일)",
      total_planned_trip_time_hrs: "총 계획된 여행 시간 (시간)",
      total_round_trip_time_hrs: "총 왕복 시간 (시간)",
      drop_location_code: "하차 위치 코드",
      drop_location_name: "하차 위치 이름",
      drop_location_city: "하차 위치 도시",
      order_number: "주문 번호",
      delivery_date: "배송 날짜 (M/D/YYYY)",
      back: "뒤로",
      pickup_location: "픽업 위치",
      dealer_available_from: "딜러 이용 가능 시간 (시작)",
      dealer_available_to: "딜러 이용 가능 시간 (종료)",
      total_planned_weight: "총 계획된 무게",
      total_planned_volume: "총 계획된 부피",
      arrival_at_first_dealer: "첫 번째 딜러 도착",
      departure_at_last_dealer: "마지막 딜러 출발",
      return_to_plant: "공장 복귀",
      summary_dashboard: "요약 대시보드",
      dispatch_plan_details: "배차 계획 세부 정보",
      invalid_orders_note: "참고: 무효 주문은 표시되지 않습니다",
      planned_data: "계획 데이터",
      vfr_percentage: "VFR 백분율",
      cool_chain_adherence: "콜드 체인 준수",
      transit_time: "운송 시간",
      loading_time: "적재 시간",
      unloading_time: "하역 시간",
      planned_summary: "계획 요약",
      invalid_drop_data: "무효 하차 데이터",
      vehicle_utilization_km: "차량 이용률 (KM)",
      distance_to_destination: "목적지까지의 거리",
      expected_delivery_date: "예상 배송 날짜",
      planned_vehicles: "계획된 차량",
      trip_no: "여행 번호",
      total_quantity: "총 수량",
      dealer_available_to: "딜러 이용 가능 시간 (종료)",
      drop_location_code_destination: "하차 위치 코드 (목적지)",
      order_number: "주문 번호",
      demand_volume_cft: "수요 부피 (CFT)",
      weight_pounds: "무게 (파운드)",
      quantity: "수량",
      dropped_reason: "제외 이유",
      delivery_date: "배송 날짜",
      drop_location_name: "하차 위치 이름",
      drop_location_city: "하차 위치 도시",
      trip_no: "여행 번호",
      planned_day: "계획된 날",
      vehicle_no: "차량 번호",
      vehicle_type: "차량 유형",
      drop_sequence: "하차 순서",
      invoicing_date: "송장 날짜",
      pickup_location_source: "픽업 위치 (출처)",
      destination_location_city: "목적지 도시",
      destination_location_state: "목적지 주",
      distance_to_destination_miles: "목적지까지의 거리 (마일)",
      expected_delivery_date: "예상 배송 날짜",
      time_to_destination: "목적지까지의 시간",
      transit_time_hh_mm: "운송 시간 (HH:MM)",
      loading_time_hh_mm: "적재 시간 (HH:MM)",
      unloading_time_hh_mm: "하역 시간 (HH:MM)",
      total_planned_customers: "총 계획된 고객",
      total_round_trip_distance_km: "총 왕복 거리 (KM)",
      planning_percentage: "계획 백분율",
      cool_chain_adherence_percentage: "콜드 체인 준수 백분율",
      total_transit_time_hh_mm: "총 운송 시간 (HH:MM)",
      total_planned_weight_pounds: "총 계획된 무게 (파운드)",
      total_planned_volume_cft: "총 계획된 부피 (CFT)",
      arrival_at_first_dealer_start: "첫 번째 딜러 도착 (시작)",
      departure_at_last_dealer_end: "마지막 딜러 출발 (종료)",
      return_to_plant_hh_mm: "공장 복귀 (HH:MM)",
      total_planned_trip_time_hrs: "총 계획된 여행 시간 (시간)",
      total_round_trip_time_hrs: "총 왕복 시간 (시간)",
      total_loading_time_hh_mm: "총 적재 시간 (HH:MM)",
      total_unloading_time_hh_mm: "총 하역 시간 (HH:MM)",
      vehicle_utilization_percentage: "차량 이용률 (%)",
      view_order_information: "주문 정보 보기",
      vehicle_configuration: "차량 구성",
      summary: "요약",
      planned_summary: "계획 요약",
      planned_vehicles: "계획된 차량",
      unplanned_loads: "계획되지 않은 적재",
      invalid_drop_data: "잘못된 하차 데이터",
      vehicle_utilization_km: "차량 이용률 (KM)",
      planned_data: "계획된 데이터",
      invalid_orders_note: "참고: 잘못된 주문은 표시되지 않습니다",
      set_vehicle_priority: "차량 우선순위 설정",
      vehicle_min_weight_pounds: "차량 최소 중량 (파운드)",
      vehicle_max_weight_pounds: "차량 최대 중량 (파운드)",
      vehicle_min_volume_cft: "차량 최소 부피 (CFT)",
      vehicle_max_volume_cft: "차량 최대 부피 (CFT)",
      max_distance_miles: "최대 거리 (마일)",
      vehicle_priority: "차량 우선순위",
      actions: "작업",
      forecast_order_plan: "예상 주문 계획",
      drop_details: "하차 세부사항",
      month: "월",
      year: "년",
      total_quantity: "총 수량",
      total_weight_pounds: "총 중량 (파운드)",
      total_demand_volume_cft: "총 수요 부피 (CFT)",
      user: "사용자",
      uploaded_date: "업로드 날짜",
      view_order_details: "주문 세부사항 보기",
      pick_up_location_code: "픽업 위치 코드",
      quantity: "수량",
      drop_location: "하차 위치",
      drop_location_name: "하차 위치 이름",
      weight: "무게",
      volume: "부피",
      delivery_date: "배송일",
      invoice_date: "송장 날짜",
      order_no: "주문 번호",
      available_start_time: "사용 가능 시작 시간",
      available_end_time: "사용 가능 종료 시간",
      upload_input_file: "입력 파일 업로드",
      download_sample_template: "샘플 템플릿 다운로드",
      select_month: "월 선택",
      upload_file: "파일 업로드",
      save: "저장",
      cancel: "취소",
      note_blank_rows_columns_not_allowed: "참고: 빈 행/열은 허용되지 않습니다",
      no_of_vehicles: "차량 수",
      run_plan: "계획 실행",
      vehicle_types: "차량 유형",
      upload_file: "파일 업로드",
      pickup_point: "픽업 지점",
      consolidated_route_plan_screen: "통합 경로 계획 화면",
      start_date: "시작 날짜",
      end_date: "종료 날짜",
      get_data: "데이터 가져오기",
      recent_plans: "최근 계획",
      plan_history: "계획 내역",
      view_input: "입력 보기",
      consolidation_id: "통합 ID",
      created_date: "생성 날짜",
      run_by: "실행자",
      no_of_executions: "실행 횟수",
      execution_status: "실행 상태",
      download_output: "출력 다운로드",
      upload_input_file: "입력 파일 업로드",
      choose_file: "파일 선택",
      discard_obd: "OBD 폐기",
      upload_obd_details: "OBD 세부사항 업로드",
      upload_file: "파일 업로드",
      submit: "제출",
      download_template: "템플릿 다운로드",
      bulk_upload_locations: "위치 일괄 업로드",
      add_pickup_drop_points: "+픽업/하차 지점 추가",
      details: "세부사항",
      date: "날짜",
      total_trips: "총 운행 수",
      trips_edited: "편집된 운행",
      trips_removed: "제거된 운행",
      trips_edit_percentage: "운행 편집 비율",
      plans_overview_dashboard: "계획 개요 대시보드",
      pickup_point: "픽업 지점",
      location_type: "위치 유형",
      location_code: "위치 코드",
      location_name: "위치 이름",
      location_city: "위치 도시",
      zone: "구역",
      customer_type: "고객 유형",
      location_latitude: "위치 위도",
      location_longitude: "위치 경도",
      standard_transit_time: "표준 운송 시간 (일)",
      distance_from_plant: "공장에서의 거리 (마일)",
      delta_warehouse: "델타 창고",
      home: "홈",
      run_new_plan: "새 계획 실행",
      stacking: "적재",
      production_planning: "생산 계획",
      forecasting: "예측",
      resource_scheduling: "자원 일정 관리",
      general: "일반",
      pickup_drop_locations: "픽업/하차 위치",
      vehicle_configurations: "차량 설정",
      vehicle_availability: "차량 가용성",
      security_approved: "보안 승인됨",
      security_date_of_assessment: "보안 평가일",
      view: "보기",
      location_code: "위치 코드",
      vehicle_no: "차량 번호",
      stage: "단계",
      quality_rejected: "품질 거부됨",
      requirements_set: "요구사항 설정됨",
      security_rejected: "보안 거부됨",
      security_accepted: "보안 승인됨",
      assessment_date: "평가일",
      requirement: "요구사항",
      satisfied: "만족",
      yes: "예",
      no: "아니요",
      requirement_set_date: "요구사항 설정일",
      temperature: "온도",
      vehicle_operational_from: "차량 운행 시작일",
      vehicle_operational_to: "차량 운행 종료일",
      break_time_from: "휴식 시간 (시작)",
      break_time_to: "휴식 시간 (종료)",
      transporter_name: "운송업체 이름",
      last_packet_time: "마지막 패킷 시간",
      temperature: "온도",
      view_vehicles: "차량 보기",
      transporter_name: "운송업체 이름",
      max_distance_per_plan_miles: "계획당 최대 거리 (마일)",
      remaining_monthly_distance_miles: "남은 월간 거리 (마일)",
      vehicle_next_available_date: "다음 차량 사용 가능 날짜",
      owned: "소유",
      contract: "계약",
      high: "높음",
      medium: "중간",
      low: "낮음",
      market: "시장",
      vehicles_for_today: "오늘의 차량",
      bulk_upload_master_vehicles: "마스터 차량 대량 업로드",
      add_master_vehicle: "마스터 차량 추가",
      transporter_name: "운송업체 이름",
      transit_type: "통행 유형",
      actions: "작업",
      facility_name: "시설 이름",
      vehicle_type: "차량 유형",
      vehicle_number: "차량 번호",
      vehicle_priority: "차량 우선순위",
      max_distance_per_plan_miles: "계획당 최대 거리 (마일)",
      max_weight_pounds: "최대 무게 (파운드)",
      max_volume_cft: "최대 부피 (CFT)",
      monthly_min_planning_distance_miles: "월간 최소 계획 거리 (마일)",
      remaining_monthly_planning_distance_miles: "남은 월간 계획 거리 (마일)",
      next_available_date_yyyy_mm_dd_hh_mm:
        "다음 사용 가능 날짜 (YYYY-MM-DD HH:MM)",
      vehicle_break_down: "차량 고장",
      drop_location_code: "하차 위치 코드",
      arrival_at_first_dealer: "첫 번째 딜러 도착",
      departure_at_last_dealer: "마지막 딜러 출발",
      average_payload: "평균 적재량 (톤)",
      mileage: "연비 (마일/갤런)",
      fuel_type: "연료 유형",
      temperature_controlled: "온도 조절됨",
      load_characteristics: "적재 특성",
      glec_vehicle_type: "GLEC 차량 유형",
      return_to_plant: "공장으로 돌아감",
      summary_dashboard: "요약 대시보드",
      dispatch_plan_details: "배차 계획 세부 정보",
      invalid_orders_note: "참고: 잘못된 주문은 표시되지 않습니다",
      pickup_point: "픽업 지점",
      vehicle_type_configuration: "차량 유형 설정",
      vehicle_type: "차량 유형",
      vehicle_type_name: "차량 유형 이름",
      min_volume: "최소 부피 (CFT)",
      max_volume: "최대 부피 (CFT)",
      vehicle_priority: "차량 우선순위",
      min_weight: "최소 무게 (파운드)",
      max_weight: "최대 무게 (파운드)",
      max_distance_trip: "여행당 최대 거리 (마일)",
      monthly_min_planning_distance: "월간 최소 계획 거리",
      cancel: "취소",
      save: "저장",
      facility_name: "시설 이름",
      vehicle_no: "차량 번호",
      address: "주소",
      last_packet_time: "마지막 패킷 시간",
      latitude: "위도",
      longitude: "경도",
      service_provider: "서비스 제공업체",
      temperature: "온도",
      on_trip: "여행 중",
      requirement: "요구사항",
      requirement_type: "요구사항 유형",
      security: "보안",
      assessed_by: "평가자",
      security_conclusion: "보안 결론",
      security_comments: "보안 의견",
      assessment_start_date: "평가 시작일",
      assessment_end_date: "평가 종료일",
      get_data: "데이터 가져오기",
      add_requirements: "요구 사항 추가",
      add_vehicle_checklist: "차량 체크리스트 추가",
      actions: "작업",
      edit: "편집",
      delete: "삭제",
      vehicle_configurations: "차량 구성",
      vehicle_availability_configurations: "차량 가용성 구성",
      vehicle_location: "차량 위치",
      vehicle_master: "차량 마스터",
      vehicle_availability: "차량 가용성",
      vehicle_details: "차량 세부 사항",
      monthly_min_distance: "월 최소 거리 (마일)",
      max_weight_per_trip: "여행당 최대 무게 (파운드)",
      max_distance_per_trip: "여행당 최대 거리 (마일)",
      planned_data: "계획된 데이터",
      vfr_percentage: "VFR 비율",
      cool_chain_adherence: "냉동 체인 준수",
      transit_time: "운송 시간",
      loading_time: "적재 시간",
      unloading_time: "하역 시간",
      planned_summary: "계획 요약",
      invalid_drop_data: "잘못된 하차 데이터",
      vehicle_utilization_km: "차량 이용률 (KM)",
      distance_to_destination: "목적지까지 거리",
      expected_delivery_date: "예상 배송 날짜",
      trip_no: "여행 번호",
      total_quantity: "총 수량",
      demand_volume_cft: "수요량 (CFT)",
      weight_pounds: "무게 (파운드)",
      quantity: "수량",
      dropped_reason: "삭제된 이유",
      delivery_date: "배송 날짜",
      drop_location_city: "하차 위치 도시",
      planned_day: "계획된 날짜",
      vehicle_no: "차량 번호",
      drop_sequence: "하차 순서",
      invoicing_date: "청구서 발행일",
      pickup_location_source: "픽업 위치 (출처)",
      destination_location_city: "목적지 도시",
      destination_location_state: "목적지 주",
      distance_to_destination_miles: "목적지까지 거리 (마일)",
      time_to_destination: "목적지까지 시간",
      dealer_available_from: "딜러 사용 가능 시간 (시작)",
      dealer_available_to: "딜러 사용 가능 시간 (종료)",
      total_planned_weight: "계획된 총 무게",
      total_planned_volume: "계획된 총 부피",
      arrival_at_first_dealer_start: "첫 번째 딜러 도착 (시작)",
      departure_at_last_dealer_end: "마지막 딜러 출발 (종료)",
      return_to_plant_hh_mm: "공장 복귀 (HH:MM)",
      total_planned_trip_time_hrs: "계획된 총 여행 시간 (시간)",
      total_round_trip_time_hrs: "총 왕복 시간 (시간)",
      total_loading_time_hh_mm: "총 적재 시간 (HH:MM)",
      total_unloading_time_hh_mm: "총 하역 시간 (HH:MM)",
      vehicle_utilization_percentage: "차량 이용률 (%)",
      view_order_information: "주문 정보 보기",
      vehicle_configuration: "차량 구성",
      summary: "요약",
      planned_summary: "계획 요약",
      planned_vehicles: "계획된 차량",
      unplanned_loads: "계획되지 않은 하중",
      invalid_drop_data: "잘못된 하차 데이터",
      set_vehicle_priority: "차량 우선순위 설정",
      forecast_order_plan: "예상 주문 계획",
      volume_cft: "부피 (CFT)",
      dealer_available_time_error: "시작 시간은 종료 시간보다 빨라야 합니다",
      ok: "확인",
      get_data: "데이터 가져오기",
      plan_history: "계획 기록",
      upload_file: "파일 업로드",
      save: "저장",
      cancel: "취소",
      delete: "삭제",
      plant_name: "공장 이름",
      total_trips: "총 여행 수",
      trips_edited: "수정된 여행",
      vfr_percentage: "VFR 비율",
      total_transit_time_hh_mm: "총 운송 시간 (HH)",
      drop_location_name_destination: "하차 위치 이름 (목적지)",
      unloading_time_hh_mm: "하역 시간 (HH:MM)",
      edited_transaction_ids: "수정된 거래 ID",
      vehicle_type: "차량 종류",
      vehicle_name: "차량 이름",
      weight_pounds: "무게 (파운드)",
      plant_code: "공장 코드",
      plant_name: "공장 이름",
      total_trips: "총 여행 수",
      trips_edited: "수정된 여행",
      last_30_days_trips_modification_details:
        "지난 30일간의 여행 수정 세부 사항",
      last_7_days_trips_modification_details:
        "지난 7일간의 여행 수정 세부 사항",
      planning_progress_past_7_weeks: "지난 7주간의 계획 진행",
      edited_transaction_ids: "수정된 거래 ID",
      trips_removed: "제거된 여행",
      removed_transaction_ids: "제거된 거래 ID",
      drop_location_city: "하차 위치 도시",
      quantity: "수량",
      dropped_reason: "삭제된 이유",
      delivery_date: "배송 날짜",
      drop_location_name: "배송지 이름",
      drop_location_city: "배송지 도시",
      trip_no: "여행 번호",
      planned_day: "계획된 날",
      vehicle_no: "차량 번호",
      vehicle_type: "차량 유형",
      drop_sequence: "배송 순서",
      invoicing_date: "청구 날짜",
      pickup_location_source: "픽업 위치 (출처)",
      destination_location_city: "목적지 도시",
      destination_location_state: "목적지 주",
      distance_to_destination_miles: "목적지까지 거리 (마일)",
      expected_delivery_date: "예상 배송 날짜",
      time_to_destination: "목적지까지의 시간",
      invalid_material: "유효하지 않은 재료",
      drop_location_code_destination: "배송지 코드 (목적지)",
      demand_volume_cft: "수요 부피 (CFT)",
      dropped_reason: "삭제된 이유",
      dealer_location_data_not_in_system:
        "딜러 위치 데이터가 시스템에 없습니다",
      delivery_date_less_than_current: "배송 날짜가 현재 날짜보다 빠릅니다",
      missing_dealer_information: "딜러 정보가 누락되었습니다",
      transit_time_hh_mm: "운송 시간 (HH:MM)",
      loading_time_hh_mm: "적재 시간 (HH:MM)",
      unloading_time_hh_mm: "하역 시간 (HH:MM)",
      actions: "작업",
      expected_delivery_date: "예상 배송 날짜",
      max_dispatch_time: "최대 출발 시간 (HH:MM)",
      unplanned_loads: "계획되지 않은 적재",
      monthly_data: "월간 데이터",
      forecast_plan_history: "예측 계획 이력",
      obd_analysis: "OBD 분석",
      dealer_available_time_error: "시작 시간이 종료 시간보다 빨라야 합니다",
    },
  },
  pt: {
    translation: {
      vehicles_recommended: "Veículos Recomendados",
        "route_type": "Tipo de Rota",
      "Number Of Trips": "Número de Viagens",
  "Total": "Total",
  "Day-2": "Dia-2",
  "Day-3": "Dia-3",
  "Day-4": "Dia-4",
  "Day-5": "Dia-5",
  "Day-6": "Dia-6",
  "Distance / Time From Pickup Location": "Distância / Tempo da localização de coleta",
  "Distance / Time From Previous Drop Point": "Distância / Tempo do ponto de entrega anterior",
  "Location City": "Cidade de localização",
  "Planned Day": "Dia planejado",
  "Quantity": "Quantidade",
  "Demand Weight": "Peso da demanda",
  "Demand Volume": "Volume de demanda",
  "Location": "Localização",
  "Weight": "Peso",
  "View Onward Route": "Ver Rota de Ida",
  "View Return Route": "Ver Rota de Retorno",
  "Quantity": "Quantidade",
  "Weight": "Peso",
  "Spot": "Local",
  "Local": "Local",
  "Upcountry": "Interior",
  "Volume": "Volume",
  "IN416": "IN416",
  "IN436": "IN436",
  "Spot": "Spot",
  "2,177": "2,177",
  "1,268": "1,268",
  "909": "909",
  "0": "0",
  "Planned Truck Load": "Carga Planeada de Caminhão",
  "Quantity (cases)": "Quantidade (caixas)",
  "Weight (Kgs)": "Peso (Kgs)",
  "Volume (CMT)": "Volume (CMT)",
  "1,81,970": "1,81,970",
  "25,25,554": "25,25,554",
  "8,249": "8,249",
  "Unplanned Load": "Carga Não Planejada",
  "26,324": "26,324",
  "3,74,549": "3,74,549",
  "1,234": "1,234",
  "Average Distance Travelled Per Vehicle (KM)": "Distância Média Percorrida por Veículo (KM)",
  "35,426": "35,426",
  "37,624": "37,624",
  "28,111": "28,111",
  "Operational Cost (Rs.)": "Custo Operacional (Rs.)",
  "1,62,57,137": "1,62,57,137",
  "1,31,64,341": "1,31,64,341",
  "30,92,797": "30,92,797",
  "Average Vehicle Fill Rate": "Taxa Média de Preenchimento do Veículo",
  "60 %": "60 %",
  "42 %": "42 %",
  "79 %": "79 %",
  "Unplanned Load Proportion": "Proporção de Carga Não Planejada",
  "12 %": "12 %",
  "Average Vehicle Utilization As Per SOB": "Utilização Média de Veículos Conforme SOB",
  "104 %": "104 %",
  "94 %": "94 %",
  "Ontime Dispatches": "Despachos Pontuais",
  "Local": "Local",
  "frefe": "frefe",
  "94 %": "94 %",
  "92 %": "92 %",
  "Ontime Deliveries": "Entregas Pontuais",
  "97 %": "97 %",
  "94 %": "94 %",
      "Please Select Scenario to Compare Plans": "Por favor, selecione o cenário para comparar planos",
"Percentage": "Porcentagem",
"Forecast Scenario": "Cenário de Previsão",
      "Number of kms Travelled": "Número de kms percorridos",
"Cost": "Custo",
"Vehicle Fill Rate": "Taxa de preenchimento do veículo",
"Vehicle KM Run %": "Percentual de kms percorridos pelo veículo",
      "Please Select File": "Por favor, selecione o arquivo",
"Processing Plan. Please wait.....": "Processando plano. Por favor, aguarde.....",
"File is processing": "O arquivo está sendo processado",
"Enter Input Data To Continue": "Insira os dados de entrada para continuar",
"No Data": "Nenhum dado",
"please_select_date": "Por favor, selecione a data",
"data_uploaded": "Dados carregados",
"Please Select No of Vehicles": "Por favor, selecione o número de veículos",
"Please Input Valid Data": "Por favor, insira dados válidos",
      "loading....": "Carregando....",
      "driver_break_time_to": "Tempo de pausa do motorista até",
"max_seating_capacity": "Capacidade máxima de assentos",
"min_seating_capacity": "Capacidade mínima de assentos",
"vehicle_seating_capacity": "Capacidade de assentos do veículo",
"vehicle_capacity_cmt": "Capacidade do veículo (m³)",
      "change_password": "Mudar senha",
  "new_password": "Nova senha",
  "confirm_new_password": "Confirmar nova senha",
  "old_password": "Senha antiga",
  "enter_email": "Insira seu email",
  "enter_new_password": "Insira sua nova senha",
  "confirm_new_password_entry": "Confirme sua nova senha",
  "select_cluster": "Selecionar Cluster",
  "Overall Cluster Wise Vehicles": "Veículos por Cluster Geral",
  "Discard": "Descartar",
  "obd": "OBD",
  "Dispatch Planning is in progress..!!!": "Planejamento de despacho em andamento..!!!",
  "Estimated Time": "Tempo Estimado",
  "GPS": "GPS",
  "Spot Vehicles To Be Planned": "Veículos Spot a Planejar",
  "No Of Spot Vehicles": "Número de Veículos Spot",
  "Availability DateTime": "Data e Hora de Disponibilidade",
  "Notify Email Users After Process (comma seperated)": "Notificar usuários de e-mail após o processo (separados por vírgula)",
  "Max Time Limit(Hrs)": "Limite Máximo de Tempo (Horas)",
  "vfr": "VFR",
  "Disclaimer": "Isenção de responsabilidade: A janela disponível para os concessionários para clientes OT será considerada a partir do arquivo de entrada, os horários dos clientes GT serão considerados a partir dos locais de coleta/entrega.",
  "To Check for Blank Data": "Para verificar os dados em branco: 1. Clique em 'Localizar e Selecionar'. 2. Clique em 'Ir para Especial'. 3. Escolha 'Brancos'. 4. Clique em 'OK' e todas as linhas/células em branco serão destacadas. 5. Escolha 'Excluir' na seção Células na guia Início. 6. Clique em 'Excluir Linhas da Planilha'.",
  "Add New OBD": "Adicionar Novo OBD",
  "All drop locations are invalid, Please check..!!": "Todos os locais de entrega são inválidos, por favor, verifique..!!",
  "Please upload file having extensions .csv or .xlsx only.": "Por favor, envie um arquivo com extensões .csv ou .xlsx apenas.",
  "Dispatch Load Volume Summary (CFT)": "Resumo do Volume de Carga Despachada (CFT)",
  "Total Loads Volume (CFT)": "Volume Total de Cargas (CFT)",
  "Planned Loads Volume (CFT)": "Volume Planejado de Cargas (CFT)",
  "Unplanned Loads Volume (CFT)": "Volume de Cargas Não Planejado (CFT)",
  "Dropped Loads Volume (CFT)": "Volume de Cargas Descartadas (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "Resumo do Peso da Carga Despachada (Libras)",
  "Total Loads Weight (Pounds)": "Peso Total de Cargas (Libras)",
  "Planned Loads Weight (Pounds)": "Peso Planejado de Cargas (Libras)",
  "Unplanned Loads Weight (Pounds)": "Peso de Cargas Não Planejado (Libras)",
  "Dropped Loads Weight (Pounds)": "Peso de Cargas Descartadas (Libras)",
  "Dispatch Load Quantity Summary": "Resumo da Quantidade de Carga Despachada",
  "Total Quantity": "Quantidade Total",
  "Planned Quantity": "Quantidade Planejada",
  "Unplanned Quantity": "Quantidade Não Planejada",
  "Dropped Quantity": "Quantidade Descartada",
  "Dispatch Load Volume Summary (CFT)": "Resumo do Volume de Carga Despachada (CFT)",
  "Total Loads Volume (CFT)": "Volume Total de Cargas (CFT)",
  "Planned Loads Volume (CFT)": "Volume Planejado de Cargas (CFT)",
  "Unplanned Loads Volume (CFT)": "Volume de Cargas Não Planejado (CFT)",
  "Dropped Loads Volume (CFT)": "Volume de Cargas Descartadas (CFT)",
  "Dispatch Load Weight Summary (Pounds)": "Resumo do Peso da Carga Despachada (Libras)",
  "Total Loads Weight (Pounds)": "Peso Total de Cargas (Libras)",
  "Planned Loads Weight (Pounds)": "Peso Planejado de Cargas (Libras)",
  "Unplanned Loads Weight (Pounds)": "Peso de Cargas Não Planejado (Libras)",
  "Dropped Loads Weight (Pounds)": "Peso de Cargas Descartadas (Libras)",
  "Dispatch Load Quantity Summary": "Resumo da Quantidade de Carga Despachada",
  "Total Quantity": "Quantidade Total",
  "Planned Quantity": "Quantidade Planejada",
  "Unplanned Quantity": "Quantidade Não Planejada",
  "Dropped Quantity": "Quantidade Descartada",
  "Dispatch Load Weight Summary (Pounds)": "Resumo do Peso da Carga Despachada (Libras)",
  "Dispatch Load Volume Summary (CFT)": "Resumo do Volume de Carga Despachada (CFT)",
  "Dropped Data": "Dados Descartados",
  "ontime_delivery": "Entrega no Prazo",
  "dealer_Available_start_time_should_be_less_than_end_time": "O horário de início de disponibilidade do concessionário deve ser menor que o horário de término",
  "Removed": "Removido",
  "Unedited": "Não Editado",
  "Edited": "Editado",
      vehicle_availability_configurations: "Configurações de Disponibilidade de Veículos",
      max_distance_per_plan_miles: "Distância Máxima por Plano (Milhas)",
    add_city_wise_cost: "Adicionar Custo por Cidade",
    add_zone_wise_cost: "Adicionar Custo por Zona",
    scenarios: "Cenários",
      plant: "Planta",
      standard_transit_time_days: "Tempo de Trânsito Padrão (Dias)",
    drop_to_time: "Hora de Entrega",
    drop_from_time: "Hora de Saída",
    pickup_to_time: "Hora de Coleta",
    pickup_from_time: "Hora de Início de Coleta",
    distance_from_plant_miles: "Distância da Planta (Milhas)",
    vehicle_next_available_date: "Próxima Data Disponível do Veículo",
    max_distance_per_plan_miles: "Distância Máxima por Plano (Milhas)",
    remaining_monthly_distance_miles: "Distância Mensal Restante (Milhas)",
    assign_quality_checks: "Atribuir Verificações de Qualidade",
    add_fixed_vehicle_cost: "Adicionar Custo Fixo do Veículo",
      customer: "Cliente",
      "Pickup Locations (Drop)*": "Locais de Coleta (Entrega)*",
      "pickup_locations_drop*": "Locais de Coleta (Entrega)*",
      duplicate_obds_light_red: "OBDs duplicados são mostrados em vermelho claro",
    new_obds_green: "OBDs recém-adicionados são mostrados em verde",
    pickup_locations_drop: "Locais de Coleta (Entrega)",
      percent_unplanned: "Percentual Não Planejado",
      please_select_scenario: "Por Favor Selecione um Cenário para Comparar Planos",
    avg_kms_travelled: "Média de Km Percorridos",
    total_cost: "Custo Total",
    total_vehicle_fill_rate: "Taxa Total de Preenchimento de Veículos",
    percent_km_run_sob: "Percentual de KM Percorridos SOB",
    percent_ontime_dispatch: "Percentual de Despachos no Prazo",
    upcountry: "Interior",
    percent_spot_vehicles_trips: "Percentual de Viagens de Veículos Spot",
    round_trip_distance: "Distância de Ida e Volta",
    total_trip_distance: "Distância Total da Viagem",
    vehicle_type: "Tipo de Veículo",
    plant_code: "Código da Planta",
    vehicle_no: "Número do Veículo",
    monthly_sob_km: "SOB Mensal (Km)",
    distance_travelled_km: "Distância Percorrida (Km)",
    pickup_location_source: "Local de Retirada (Origem)",
    drop_location_code_destination: "Código do Local de Entrega (Destino)",
    drop_location_name_destination: "Nome do Local de Entrega (Destino)",
    order_number: "Número do Pedido",
    invoicing_date: "Data de Faturamento",
    quantity: "Quantidade",
    weight_kgs: "Peso (Kgs)",
    demand_volume_m3: "Volume de Demanda (M3)",
    dealer_available_from: "Concessionário Disponível a Partir de",
    dealer_available_to: "Concessionário Disponível Até",
    delivery_date: "Data de Entrega",
    trip_no: "Número da Viagem",
    planned_day: "Dia Planejado",
    drop_sequence: "Sequência de Entrega",
    destination_location_city: "Cidade do Local de Destino",
    destination_location_state: "Estado do Local de Destino",
    volume_m3: "Volume (M3)",
    distance_to_destination_kms: "Distância Até o Destino (Km)",
    expected_delivery_date: "Data de Entrega Esperada",
    time_to_destination: "Tempo Até o Destino",
    transit_time_hhmm: "Tempo de Trânsito (HH:MM)",
    loading_time_hhmm: "Tempo de Carregamento (HH:MM)",
    unloading_time_hhmm: "Tempo de Descarregamento (HH:MM)",
    start_month: "Mês de Início",
    end_month: "Mês de Fim",
    get_data: "Obter Dados",
    select_chart_type: "Selecionar Tipo de Gráfico",
    fleet_analysis: "Análise da Frota",
    system_recommended: "Sistema Recomendado",
    total_load: "Carga Total",
    serviceability: "Operacionalidade",
    in416: "IN416",
    in436: "IN436",
    user_recommended: "Usuário Recomendado",
    threshold: "Limite",
    number_of_trips: "Número de Viagens",
    total: "Total",
    spot: "Spot",
    planned_truck_load: "Carga Planejada do Caminhão",
    quantity_cases: "Quantidade (caixas)",
    weight_kgs: "Peso (Kgs)",
    volume_cmt: "Volume (CMT)",
    unplanned_load: "Carga Não Planejada",
    average_distance_travelled_per_vehicle_km: "Distância Média Percorrida por Veículo (KM)",
    operational_cost_rs: "Custo Operacional (Rs.)",
    average_vehicle_fill_rate: "Taxa Média de Ocupação do Veículo",
    unplanned_load_proportion: "Proporção de Carga Não Planejada",
    average_vehicle_utilization_as_per_sob: "Utilização Média do Veículo Conforme SOB",
    ontime_dispatches: "Envios no Prazo",
    local: "Local",
    upcountry: "Interior",
    ontime_deliveries: "Entregas no Prazo",
    bar: "Barra",
    load: "Carga",
    month: "Mês",
    percentage: "%",






      //code for configurations screen

      "Consider Transporter SOB": "Considerar Transportador SOB",
      compare: "Comparar",
      forecast_summary: "Resumo da Previsão",
    pickup_locations_drop: "Locais de Coleta (Entrega)",
      "note_highlighted_rows_red": "Nota: 1. As linhas destacadas em vermelho carecem de dados GPS ou integração, marque a disponibilidade do veículo manualmente.",
      "highlighted_rows_green": "2. As linhas destacadas em verde são consideradas para o planejamento",
      "enable_reconciliation": "Habilitar reconciliação",
      "enable_dc_week_off_planning": "Habilitar planejamento da semana de folga do DC",
      enable_disable: "Ativar/Desativar",
      "Consider Dropping Distant Smaller Loads":
        "Considerar eliminar cargas pequenas distantes",
      "Consider Realtime Vehicle Available Times":
        "Considerar tempos disponíveis dos veículos em tempo real",
      "Plant Wise Vehicle Type Configuration*":
        "Configuração do tipo de veículo por fábrica*",
      "Consignees / Dealers Locations (Drop)*":
        "Localizações de consignatários / distribuidores (Entrega)*",
      "Pickup Locations (Pickup)*": "Localizações de coleta (Coleta)*",
      "Optimization Type*": "Tipo de otimização*",
      "Plant Wise Planning History*": "Histórico de planejamento por fábrica*",
      "Plant Wise Plan Summary*": "Resumo do plano por fábrica*",
      "Plant Wise New Plan Creation*": "Criação de novo plano por fábrica*",
      "Suppliers / Vendor Locations (Pickup)*":
        "Localizações de fornecedores / vendedores (Coleta)*",
      "Plant Wise Vehicle Type Configuration*":
        "Configuração do tipo de veículo por fábrica*",
      "Consignees / Dealers Locations (Drop)*":
        "Localizações de consignatários / distribuidores (Entrega)*",
      "Pickup Locations (Pickup)*": "Localizações de coleta (Coleta)*",
      "Plant Wise Vehicle Type Configuration*":
        "Configuração do tipo de veículo por fábrica*",
      "Suppliers / Vendor Locations (Pickup)*":
        "Localizações de fornecedores / vendedores (Coleta)*",
      "Plant Wise Planning History*": "Histórico de planejamento por fábrica*",
      "Plant Wise Plan Summary*": "Resumo do plano por fábrica*",
      "Plant Wise New Plan Creation*": "Criação de novo plano por fábrica*",
      "Enable/Disable Dispatch Planning Module":
        "Ativar/Desativar Módulo de Planejamento de Despacho",
      Inbound: "Entrada",
      Outbound: "Saída",
      "Suppliers / Vendor Locations (Pickup)":
        "Localizações de fornecedores / vendedores (Coleta)",
      "Operational Hours": "Horário de operação",
      "Pickup Locations (Drop)": "Localizações de coleta (Entrega)",
      "Plant Wise Vehicle Type Configuration":
        "Configuração do tipo de veículo por fábrica",
      "Max No. Of Vehicle Types": "Máximo número de tipos de veículos",
      "Vehicle Load Capacity Min / Max Configuration Preferences":
        "Preferências de configuração da capacidade de carga do veículo mínima / máxima",
      "Vehicle Load Weight Min / Max Preferences":
        "Preferências de peso de carga do veículo mínima / máxima",
      "Distance preferences": "Preferências de distância",
      "Vehicle Priorities": "Prioridades de veículos",
      "Vehicle Operational Times": "Tempos operacionais do veículo",
      "Vehicle Availability": "Disponibilidade de veículos",
      "Plant Wise Freight Configuration": "Configuração de frete por fábrica",
      "Plant Wise Transporter Configuration":
        "Configuração de transportador por fábrica",
      "Route Preferences": "Preferências de rota",
      "Plant Wise New Plan Creation": "Criação de novo plano por fábrica",
      "Plant Wise Plan Summary": "Resumo do plano por fábrica",
      "Plant Wise Planning History": "Histórico de planejamento por fábrica",
      "No of Vehicles Types": "Número de tipos de veículos",
      "Generic Time Windows": "Janelas de tempo genéricas",
      "Individual Vehicle Time Windows":
        "Janelas de tempo de veículos individuais",
      "Availability By Vehicle Type": "Disponibilidade por tipo de veículo",
      "Availability By Individual Vehicle":
        "Disponibilidade por veículo individual",
      "Monthly Minimum Planning Distance Preferences":
        "Preferências de distância mínima de planejamento mensal",
      "GPS Based Availability": "Disponibilidade baseada em GPS",
      "Truck Location Visibility": "Visibilidade da localização do caminhão",
      "Plant Vicinity Circle Radius":
        "Raio do círculo de proximidade da fábrica",
      "Fixed Vehicle Costs": "Custos fixos do veículo",
      "Variable Costs": "Custos variáveis",
      "Vehicle Type Level Variable Costs":
        "Custos variáveis por tipo de veículo",
      "City Level Vehicle Type Costs":
        "Custos por tipo de veículo a nível de cidade",
      "Per CFT Cost": "Custo por CFT",
      "Per Mile Cost": "Custo por milha",
      "Zone Level Cost": "Custo a nível de zona",
      "Transporter Share of Business":
        "Participação do transportador no negócio",
      "Sustainability Integration": "Integração de sustentabilidade",
      "Optimization Type": "Tipo de otimização",
      "Cost Optimization": "Otimização de custos",
      "Weight based optimization": "Otimização baseada em peso",
      "Distance based optimization": "Otimização baseada em distância",
      "Time based optimization": "Otimização baseada em tempo",
      "Volume based optimization": "Otimização baseada em volume",
      "Prioritize Local Loads": "Priorizar cargas locais",
      "Radius Miles": "Raio em milhas",
      "Number of Loading Docks": "Número de docas de carga",
      "City Based Entry Restriction": "Restrição de entrada baseada em cidade",
      "Unloading time per case/max unloading time":
        "Tempo de descarga por caso/tempo máximo de descarga",
      "Consider Vehicle Break Times (Night Driving)":
        "Considerar tempos de descanso do veículo (Condução noturna)",
      "Consider Holiday Restrictions": "Considerar restrições de feriados",
      "Vehicle Restriction": "Restrição de veículo",
      "Plan Spot Vehicles": "Planejar veículos de ponto",
      "Max No of Customers Per Vehicle":
        "Máximo número de clientes por veículo",
      "Use Dynamic Planning Window": "Usar janela de planejamento dinâmica",
      "Number of Days to Use Planning Window":
        "Número de dias para usar a janela de planejamento",
      "Use Volume for Vehicle Fill Rate (VFR)":
        "Usar volume para taxa de preenchimento do veículo (VFR)",
      "Use Weight for Vehicle Fill Rate (VFR)":
        "Usar peso para taxa de preenchimento do veículo (VFR)",
      "Min VFR Per Vehicle Type": "VFR mínimo por tipo de veículo",
      "Min VFR Per Spot Vehicle": "VFR mínimo por veículo de ponto",
      "Prioritize Same Dealers First in a Vehicle":
        "Priorizar os mesmos distribuidores primeiro em um veículo",
      "Prioritize Multi City Dealers First in a Vehicle":
        "Priorizar distribuidores de várias cidades primeiro em um veículo",
      "Dispatch within Days": "Despacho em dias",
      "Allow Unplanned Loads": "Permitir cargas não planejadas",
      "Minimize Number of Vehicles Used":
        "Minimizar o número de veículos utilizados",
      "Dealer Buffer Time": "Tempo de buffer do distribuidor",
      "Buffer Time(Mins)": "Tempo de buffer (Minutos)",
      "Month End Planning Start Day":
        "Dia de início do planejamento de fim de mês",
      "Day of Every Month": "Dia de cada mês",
      "Max Local No of Customers Per Vehicle":
        "Máximo número de clientes locais por veículo",
      "Max Upcountry No of Customers Per Vehicle":
        "Máximo número de clientes em áreas rurais por veículo",
      "Planning Type": "Tipo de planejamento",
      "Local Prioritization": "Priorização local",
      "Local Prioritization and Virtual Upcountry clubbing":
        "Priorização local e agrupamento virtual de áreas rurais",
      "General Plan (Local and Upcountry clubbing)":
        "Plano geral (Agrupamento de áreas locais e rurais)",
      "Telescopic Deviation": "Desvio telescópico",
      "MOQ for Planning": "Quantidade mínima para planejamento",
      Reconciliation: "Reconciliação",
      "DC Week Off planning": "Planejamento de semana livre no DC",
      "Save Inbound Configurations": "Salvar configurações de entrada",
      "Save Outbound Configurations": "Salvar configurações de saída",
      SAVE: "SALVAR",
      "Enable Reconciliation": "Habilitar reconciliação",
      "Enable DC Week Off planning":
        "Habilitar planejamento de semana livre no DC",
      "Deviation Alert": "Alerta de desvio",
      "Overload Warning": "Aviso de sobrecarga",
      "Underload Alert": "Alerta de carga insuficiente",
      "Fuel Efficiency Report": "Relatório de eficiência de combustível",
      "Vehicle Downtime": "Tempo de inatividade do veículo",
      "Speed Violation Alert": "Alerta de violação de velocidade",
      "Trip Delay Notification": "Notificação de atraso na viagem",
      "Idle Time Monitoring": "Monitoramento de tempo ocioso",
      "Driver Performance": "Desempenho do motorista",
      "Realtime GPS Tracking": "Rastreamento GPS em tempo real",
      "Fleet Utilization": "Utilização da frota",
      "Trip Efficiency": "Eficiência da viagem",
      "Dynamic Route Adjustment": "Ajuste dinâmico de rota",
      "Load Distribution Optimization": "Otimização da distribuição de carga",
      "Transporter Preferences": "Preferências do transportador",
      "Trip Schedule": "Agenda de viagem",
      "Driver Assignment": "Atribuição de motorista",
      "Optimize Vehicle Utilization": "Otimizar a utilização de veículos",
      "Time Based Planning": "Planejamento baseado no tempo",
      "Plant Wise Route Planning": "Planejamento de rota por fábrica",
      "Vehicle Rotation Configuration": "Configuração de rotação de veículos",
      "Inbound Vehicle Preferences": "Preferências de veículos de entrada",
      "Outbound Vehicle Preferences": "Preferências de veículos de saída",
      "Vehicle Fill Rate Optimization":
        "Otimização da taxa de preenchimento do veículo",
      "Dynamic Load Allocation": "Alocação dinâmica de carga",
      "Start Date": "Data de início",
      "Run Plan": "Plano de execução",
      "Completion Date": "Data de conclusão",
      "Transporter Name": "Nome do transportador",
      "Driver Allocation": "Alocação de motorista",
      "Max Load Capacity": "Capacidade máxima de carga",
      "Estimated Delivery Time": "Tempo estimado de entrega",
      "Use Real-Time Traffic Data": "Usar dados de tráfego em tempo real",
      "Driver Break Times": "Tempos de pausa do motorista",
      "Max Vehicle Load Percentage": "Percentual máximo de carga do veículo",
      "Telescopic Deviation Settings": "Configurações de desvio telescópico",
      "Deviation Type": "Tipo de desvio",
      "Spot Vehicle Usage": "Uso de veículos de ponto",
      "Real-time Load Monitoring": "Monitoramento de carga em tempo real",
      "Driver Allocation Time": "Tempo de alocação de motorista",
      "Use Traffic Data": "Usar dados de tráfego",
      "Optimize Route": "Otimizar rota",
      "Optimize Fuel Efficiency": "Otimizar eficiência de combustível",
      "Daily Vehicle Plan": "Plano diário de veículos",
      "Transporter Allocation": "Alocação de transportador",
      "Enable Real-time Tracking": "Habilitar rastreamento em tempo real",
      "Vehicle Rotation Plan": "Plano de rotação de veículos",
      "Route Optimization Preferences": "Preferências de otimização de rota",
      "Consider Weather Conditions": "Considerar condições climáticas",
      "Max Loading Time": "Tempo máximo de carregamento",
      "Min Load Capacity": "Capacidade mínima de carga",
      "Driver Scheduling": "Programação de motoristas",
      "Vehicle Capacity Monitoring": "Monitoramento de capacidade do veículo",
      "Dynamic Load Balancing": "Balanceamento dinâmico de carga",
      "Last Mile Optimization": "Otimização da última milha",
      "Unloading Time Management": "Gestão do tempo de descarga",
      "Daily Trip Plan": "Plano diário de viagem",
      "Delivery Window Optimization": "Otimização da janela de entrega",
      "Night Driving Restrictions": "Restrições de condução noturna",
      "Weekend Planning": "Planejamento de fim de semana",

      run_new_plan: "Executar novo plano",
      planned_distance_miles: "Distância Planejada (Milhas)",
      transporter_details: "Detalhes do transportador",
      restriction_hours: "Horas De Restrição",
      save_geofence: "Salvar Geofence",
      run_new_plan: "Executar novo plano",
      new_plan_details: "Detalhes do novo plano",
      order: "Pedido",
      vehicle_configs: "Configurações do veículo",
      order_information: "Informações do pedido",
      plan_configs: "Configurações do plano",
      vehicle_availability: "Disponibilidade do veículo",
      vehicle_type: "Tipo de veículo",
      vehicle_name: "Nome do veículo",
      vehicle_min_weight: "Peso mínimo do veículo (libras)",
      vehicle_max_weight: "Peso máximo do veículo (libras)",
      vehicle_min_volume: "Volume mínimo do veículo (pés cúbicos)",
      vehicle_max_volume: "Volume máximo do veículo (pés cúbicos)",
      max_distance_per_trip: "Distância máxima por viagem (milhas)",
      vehicle_priority: "Prioridade do veículo",
      enable_reconciliation: "Ativar reconciliação",
      enable_dc_week_off_planning: "Ativar planejamento de folga semanal no DC",
      order_number: "Número do pedido",
      invoicing_date: "Data da fatura",
      quantity: "Quantidade",
      demand_cft: "Demanda (pés cúbicos)",
      weight_pounds: "Peso (libras)",
      dealer_available_from: "Concessionário disponível a partir de",
      dealer_available_to: "Concessionário disponível até",
      delivery_date: "Data de entrega",
      general_settings: "Configurações gerais",
      prioritize_local_loads: "Priorizar cargas locais",
      consider_dropping_distant_smaller_loads:
        "Considerar eliminar cargas menores distantes",
      plan_spot_vehicles: "Planejar veículos pontuais",
      city_based_entry_restriction: "Restrição de entrada com base na cidade",
      consider_vehicle_break_times:
        "Considerar tempos de pausa do veículo (condução noturna)",
      consider_holiday_restrictions: "Considerar restrições de feriados",
      consider_realtime_vehicle_available_times:
        "Considerar horários de disponibilidade em tempo real do veículo",
      consider_transporter_sob: "Considerar SOB do transportador",
      planning_optimization_settings:
        "Configurações de otimização de planejamento",
      use_dynamic_planning_window: "Usar janela de planejamento dinâmica",
      number_of_days_to_use_planning_window:
        "Número de dias para usar a janela de planejamento",
      optimization_type: "Tipo de otimização",
      cost_optimization: "Otimização de custos",
      weight_based_optimization: "Otimização baseada em peso",
      distance_based_optimization: "Otimização baseada em distância",
      time_based_optimization: "Otimização baseada em tempo",
      volume_based_optimization: "Otimização baseada em volume",
      vehicle_fill_rate_vfr_settings:
        "Configurações da taxa de preenchimento do veículo (VFR)",
      use_volume_for_vfr: "Usar volume para taxa de preenchimento do veículo",
      use_weight_for_vfr: "Usar peso para taxa de preenchimento do veículo",
      min_vfr_per_vehicle_type:
        "Taxa de preenchimento mínima por tipo de veículo",
      min_vfr_per_spot_vehicle:
        "Taxa de preenchimento mínima por veículo pontual",
      moq_for_planning: "MOQ para planejamento",
      load_planning_settings: "Configurações de planejamento de carga",
      number_of_loading_docks: "Número de docas de carga",
      max_local_no_of_customers_per_vehicle:
        "Máximo de clientes locais por veículo",
      max_upcountry_no_of_customers_per_vehicle:
        "Máximo de clientes fora da cidade por veículo",
      prioritize_same_dealers_first:
        "Priorizar os mesmos concessionários primeiro em um veículo",
      prioritize_multi_city_dealers_first:
        "Priorizar concessionários de várias cidades primeiro em um veículo",
      note_highlighted_rows_red:
        "Linhas destacadas em vermelho não possuem dados de GPS ou integração, marque manualmente a disponibilidade do veículo.",
      note_highlighted_rows_green:
        "Linhas destacadas em verde são consideradas para planejamento",
      vehicle_number: "Número do veículo",
      transit_type: "Tipo de trânsito",
      monthly_minimum_distance: "Distância mínima mensal",
      remaining_distance: "Distância restante",
      vehicle_available_date:
        "Data de disponibilidade do veículo (AAAA-MM-DD HH:mm)",
      charlie_hub: "Hub Charlie",
      enable_recommendation: "Ativar recomendação",
      planned_day: "Dia planejado",
      drop_sequence: "Sequência de entrega",
      drop_location_code_destination: "Código do local de entrega (destino)",
      drop_location_name_destination: "Nome do local de entrega (destino)",
      destination_location_city: "Cidade de destino",
      weight_kgs: "Peso (kg)",
      volume_m3: "Volume (m³)",
      distance_to_destination_kms: "Distância até o destino (km)",
      expected_delivery_date: "Data de entrega esperada",
      time_to_destination: "Tempo até o destino",
      transit_time_hh_mm: "Tempo de trânsito (HH:MM)",
      unloading_time_hh_mm: "Tempo de descarregamento (HH:MM)",
      plan_id: "ID do plano",
      generated_on: "Gerado em",
      vehicle_availability_from: "Disponibilidade do veículo de",
      vehicle_availability_to: "Disponibilidade do veículo até",
      break_time_from: "Horário de pausa de",
      break_time_to: "Horário de pausa até",
      min_volume_m3: "Volume mínimo (m³)",
      max_volume_m3: "Volume máximo (m³)",
      vehicle_width_mm: "Largura do veículo (mm)",
      vehicle_height_mm: "Altura do veículo (mm)",
      vehicle_depth_mm: "Profundidade do veículo (mm)",
      material_code: "Código do material",
      material_name: "Nome do material",
      material_type: "Tipo de material",
      material_weight: "Peso do material",
      material_dimensions_depth: "Dimensões do material - Profundidade",
      material_dimensions_width: "Dimensões do material - Largura",
      material_dimensions_height: "Dimensões do material - Altura",
      state: "Estado",
      city: "Cidade",
      fixed_vehicle_cost: "Custo fixo do veículo",
      driver_cost: "Custo do motorista",
      maintenance_cost: "Custo de manutenção",
      per_cmt_cost: "Custo por pé cúbico",
      per_km_cost: "Custo por km",
      date: "Data",
      transporter_name: "Nome do transportador",
      no_of_vehicles: "Número de veículos",
      driver_break_time_from: "Horário de pausa do motorista de",
      vehicle_max_weight_kgs: "Peso máximo do veículo (kg)",
      vehicle_min_volume_m3: "Volume mínimo do veículo (m³)",
      vehicle_max_volume_m3: "Volume máximo do veículo (m³)",
      max_distance_kms: "Distância máxima (km)",
      monthly_min_planning_distance_kms:
        "Distância mínima de planejamento mensal (km)",
      details: "Detalhes",
      trip_no: "Número da viagem",
      vehicle_no: "Número do veículo",
      no_of_orders: "Número de pedidos",
      no_of_customers: "Número de clientes",
      total_quantity: "Quantidade total",
      vehicle_utilization_percent: "Utilização do veículo (%)",
      total_planned_weight_kgs: "Peso total planejado (kg)",
      onward_trip_distance_kms: "Distância da viagem de ida (km)",
      round_trip_distance_kms: "Distância da viagem de ida e volta (km)",
      total_planned_volume_m3: "Volume total planejado (m³)",
      max_dispatch_time_hh_mm: "Tempo máximo de despacho (HH:MM)",
      arrival_at_first_dealer_start:
        "Chegada ao primeiro concessionário (início)",
      departure_at_last_dealer_end: "Partida do último concessionário (fim)",
      return_to_plant_hh_mm: "Retorno à fábrica (HH:MM)",
      total_planned_trip_time_hrs: "Tempo total da viagem planejada (horas)",
      total_round_trip_time_hrs: "Tempo total da viagem de ida e volta (horas)",
      total_transit_time_hh_mm: "Tempo total de trânsito (HH:MM)",
      total_loading_time_hh_mm: "Tempo total de carregamento (HH:MM)",
      total_unloading_time_hh_mm: "Tempo total de descarregamento (HH:MM)",
      drop_location_code: "Código do local de entrega",
      drop_location_name: "Nome do local de entrega",
      drop_location_city: "Cidade do local de entrega",
      demand_volume_m3: "Volume de demanda (m³)",
      vehicle_min_weight_kgs: "Peso mínimo do veículo (kg)",
      no_of_deliveries: "Número de entregas",
      min_load_per_delivery: "Carga mínima por entrega",
      status: "Status",
      processing: "Processando",
      please_contact_admin: "Por favor, contate o administrador",
      solver_complete: "Solução completa",
      solver_reco_processed: "Recomendação processada",
      solver_sob_done: "SOB concluído",
      solver_invalid_input_dealer_location:
        "Localização do concessionário inválida",
      solver_invalid_input_materials: "Materiais inválidos",
      solver_invalid_input_dealer_location_and_materials:
        "Localização do concessionário e materiais inválidos",
      solver_input_data_not_valid: "Dados de entrada inválidos",
      solver_fix_dropped_dealers: "Corrigir concessionários removidos",
      solver_vehicle_details_missing: "Detalhes do veículo ausentes",
      solver_input_file_data_error: "Erro nos dados do arquivo de entrada",
      solver_invalid_file_template: "Modelo de arquivo inválido",
      process_timed_out: "Processo expirado",
      solver_sob_fail: "Falha no SOB",
      solver_reco_failed: "Falha na recomendação",
      solver_no_tool_config: "Nenhuma configuração de ferramenta",
      solver_invalid_tool_config: "Configuração de ferramenta inválida",
      transit_time_with_multiple_city_clubing:
        "Tempo de trânsito com combinação de várias cidades",
      rdd_preference: "Preferência de RDD",
      transit_time_with_single_city_clubing:
        "Tempo de trânsito com combinação de uma única cidade",
      no_of_trucks_planned: "Número de caminhões planejados",
      view_details: "Ver detalhes",
      plan_run_by: "Plano executado por",
      compare_plans: "Comparar planos",
      scenario_name: "Nome do cenário",
      pick_up_location_source: "Local de coleta (origem)",
      pickup_location_source: "Local de coleta (origem)",
      drop_location_destination: "Local de entrega (destino)",
      demand_m3: "Demanda (m³)",
      location_radius: "Raio da localização (km)",
      volume_cmt: "Volume (CMT)",
      obd_details_in_radius: "Detalhes de OBD dentro do raio",
      planning_configurations: "Configurações de planejamento",
      planning_scenarios: "Cenários de planejamento",
      what_if_demand_fluctuation: "E se: Flutuação de demanda",
      increase_decrease_demand_by: "Aumentar/diminuir demanda em",
      what_if_customer_priority_changes:
        "E se: Mudanças de prioridade do cliente",
      prioritize_upcountry: "Priorizar interior",
      prioritize_local: "Priorizar local",
      what_if_service_level_target_changes:
        "E se: Mudanças na meta de nível de serviço",
      set_service_level_target_to: "Definir meta de nível de serviço para",
      what_if_vehicle_fill_rate_changes:
        "E se: Mudanças na taxa de preenchimento do veículo",
      set_vehicle_fill_rate_to: "Definir taxa de preenchimento do veículo para",
      what_if_customer_specific_delivery_time:
        "E se: Tempo de entrega específico do cliente",
      increase_decrease_delivery_time_window_by:
        "Aumentar/diminuir janela de tempo de entrega em",
      scenario_description: "Descrição do cenário",
      vehicle_fill_rate_settings:
        "Configurações de taxa de preenchimento do veículo",
      file_name: "Nome do arquivo",
      total_weight_kgs: "Peso total (kg)",
      total_demand_volume_m3: "Volume total de demanda (m³)",
      processing_plan_please_wait: "Processando plano. Aguarde...",
      forecast_input_information: "Informações de entrada de previsão",
      uploading_data_for_plant: "Enviando dados para a planta",
      vehicle_type_configuration: "Configuração do tipo de veículo",
      min_volume_cft: "Volume mínimo (pés cúbicos)",
      max_volume_cft: "Volume máximo (pés cúbicos)",
      cost_per_km: "Custo por km",
      note_duplicate_scenario: "Nota: Cenário duplicado",
      select_unique_scenarios_to_compare:
        "Selecione cenários únicos para comparar planos",
      scenario_selection_limit_exceeded:
        "Limite de seleção de cenários excedido",
      note_scenario_selection_limit:
        "Nota: Você pode selecionar até três cenários",
      select_scenario_to_compare: "Selecione um cenário para comparar planos",
      forecast_scenarios: "Cenários de previsão",
      select_planning_duration: "Selecionar duração do planejamento",
      notify_email_users_after_process:
        "Notificar usuários por e-mail após o processo (separados por vírgulas)",
      optimize_by_time_details: "Otimizar por detalhes de tempo",
      max_time_limit_hrs: "Limite máximo de tempo (horas)",
      spot_vehicles_to_be_planned: "Veículos pontuais a serem planejados",
      auto_select: "Seleção automática",
      no_data: "Sem Dados",
      redirecting_to_vehicle_checklist:
        "Redirecionando Para A Lista De Verificação Do Veículo",
      planned_day: "Dia Planejado",
      drop_sequence: "Sequência de Entrega",
      drop_location_code_destination: "Código do Local de Entrega (Destino)",
      drop_location_name_destination: "Nome do Local de Entrega (Destino)",
      destination_location_city: "Cidade de Destino",
      dealer_type: "Tipo de Revendedor",
      customer_type: "Tipo de Cliente",
      week_off: "Dia de Folga",
      order_number: "Número do Pedido",
      invoicing_date: "Data da Fatura",
      quantity: "Quantidade",
      weight_pounds: "Peso (Libras)",
      volume_cft: "Volume (CFT)",
      distance_to_destination_miles: "Distância até o Destino (Milhas)",
      expected_delivery_date: "Data de Entrega Prevista",
      time_to_destination: "Tempo até o Destino",
      transit_time_hh_mm: "Tempo de Trânsito (HH:MM)",
      unloading_time_hh_mm: "Tempo de Descarregamento (HH:MM)",
      email: "E-mail",
      details: "Detalhes",
      view: "Visualizar",
      assign_vehicle: "Atribuir Veículo",
      revoke_assignment: "Revogar Atribuição",
      edit_plan: "Editar Plano",
      delete: "Excluir",
      plan_edited: "Plano Editado",
      yes: "Sim",
      no: "Não",
      trip_no: "Número da Viagem",
      vehicle_no: "Número do Veículo",
      vehicle_type: "Tipo de Veículo",
      cost: "Custo ($)",
      transit_type: "Tipo de Trânsito",
      transporter_name: "Nome do Transportador",
      no_of_orders: "Número de Pedidos",
      no_of_customers: "Número de Clientes",
      total_quantity: "Quantidade Total",
      vehicle_utilization_percent: "Utilização do Veículo (%)",
      vehicle_max_weight: "Peso Máximo do Veículo (Libras)",
      total_planned_weight: "Peso Total Planejado (Libras)",
      onward_trip_distance_miles: "Distância da Viagem (Milhas)",
      round_trip_distance_miles: "Distância de Ida e Volta (Milhas)",
      vehicle_max_volume_cft: "Volume Máximo do Veículo (CFT)",
      total_planned_volume_cft: "Volume Total Planejado (CFT)",
      max_dispatch_time: "Tempo Máximo de Despacho (HH:MM)",
      arrival_at_first_dealer: "Chegada ao Primeiro Revendedor (Início)",
      departure_at_last_dealer: "Saída do Último Revendedor (Fim)",
      return_to_plant: "Retorno à Planta (HH:MM)",
      total_planned_trip_time: "Tempo Total Planejado da Viagem (Horas)",
      total_round_trip_time: "Tempo Total da Viagem de Ida e Volta (Horas)",
      total_transit_time: "Tempo Total de Trânsito (HH:MM)",
      total_loading_time: "Tempo Total de Carregamento (HH:MM)",
      total_unloading_time: "Tempo Total de Descarregamento (HH:MM)",
      remark: "Observação",
      "n/a": "Não Disponível",
      revoke_again_to_edit: "Revogar Novamente para Editar",
      drop_location_code: "Código do Local de Entrega",
      drop_location_name: "Nome do Local de Entrega",
      drop_location_city: "Cidade do Local de Entrega",
      distance_from_plant: "Distância da Planta",
      demand_volume_cft: "Volume de Demanda (CFT)",
      delivery_date: "Data de Entrega",
      actions: "Ações",
      pickup_location_source: "Local de Coleta (Origem)",
      dealer_available_from: "Revendedor Disponível a Partir de",
      dealer_available_to: "Revendedor Disponível Até",
      loading_time_hh_mm: "Tempo de Carregamento (HH:MM)",
      dropped_reason: "Motivo do Cancelamento",
      dealer_location_data_not_in_system:
        "Dados do Local do Revendedor Não no Sistema",
      delivery_date_less_than_current_date:
        "Data de Entrega Menor que a Data Atual",
      kdd_customer: "Cliente KDD",
      insufficient_load_for_planning:
        "Carga Insuficiente para Planejamento (<MOQ)",
      warehouse_name: "Nome do Armazém",
      destination_city: "Cidade de Destino",
      destination_state: "Estado de Destino",
      sales_order_no: "Número do Pedido de Venda",
      no_of_tires: "Número de Pneus",
      order_date: "Data do Pedido",
      vehicle_name: "Nome do Veículo",
      vehicle_priority: "Prioridade do Veículo",
      vehicle_availability_from: "Disponibilidade do Veículo (de)",
      vehicle_availability_to: "Disponibilidade do Veículo (até)",
      break_time_from: "Hora do Intervalo (de)",
      break_time_to: "Hora do Intervalo (até)",
      min_volume_cft: "Volume Mínimo (CFT)",
      max_volume_cft: "Volume Máximo (CFT)",
      vehicle_dimensions_width_mm: "Largura do Veículo (mm)",
      vehicle_dimensions_height_mm: "Altura do Veículo (mm)",
      vehicle_dimensions_depth_mm: "Profundidade do Veículo (mm)",
      vehicle_min_weight: "Peso Mínimo do Veículo (Libras)",
      max_no_of_nodes: "Número Máximo de Entregas",
      min_load_per_delivery: "Carga Mínima por Entrega",
      max_distance: "Distância Máxima (Milhas)",
      material_code: "Código do Material",
      material_name: "Nome do Material",
      material_type: "Tipo de Material",
      material_weight: "Peso do Material",
      material_dimensions_depth: "Profundidade do Material",
      material_dimensions_width: "Largura do Material",
      material_dimensions_height: "Altura do Material",
      form_vehicle_types: "Tipos de Veículo",
      dealer_form_state: "Estado",
      dealer_form_city: "Cidade",
      freight_fixed_vehicle_cost: "Custo Fixo do Veículo",
      freight_driver_cost: "Custo do Motorista",
      freight_maintenance_cost: "Custo de Manutenção",
      freight_per_cmt_cost: "Custo por CMT",
      freight_per_km_cost: "Custo por KM",
      vehicle_date: "Data",
      vehicle_number: "Número do Veículo",
      plant_code: "Código da Planta",
      plant_name: "Nome da Planta",
      available_time: "Hora Disponível",
      gps_status: "Status do GPS",
      last_known_address: "Último Endereço Conhecido",
      last_gps_time: "Última Hora de GPS",
      vehicle_location: "Localização do Veículo",
      assigned_to_ot: "Atribuído ao OT",
      min_vfr: "VFR Mínimo",
      order_information: "Informações do Pedido",
      vehicle_type_configurations: "Configurações do Tipo de Veículo",
      vehicle_routes: "Rotas do Veículo",
      total_customers: "Total de Clientes",
      planned: "Planejado",
      unplanned: "Não Planejado",
      total_round_trip_distance_miles:
        "Distância Total de Ida e Volta (Milhas)",
      planning: "Planejamento",
      vfr: "VFR",
      ontime_dispatch: "Despacho Pontual",
      avg_tat: "TAT Médio",
      view_locations: "Ver Localizações",
      view_routes: "Ver Rotas",
      additional_vehicles: "Veículos Adicionais",
      regular_vehicles: "Veículos Regulares",
      enter_vehicle_no: "Insira o Número do Veículo",
      vehicle_has_active_trip_shipment_id:
        "O Veículo Possui um ID de Remessa de Viagem Ativa",
      do_you_wish_to: "Deseja",
      force_close_assign: "Forçar Fechamento & Atribuir",
      force_close_reason: "Razão do Fechamento Forçado",
      gps_issue: "Problema de GPS",
      trip_closed: "Viagem Encerrada",
      load_cancelled: "Carga Cancelada",
      new_trip_allocated: "Nova Viagem Atribuída",
      driver_changed: "Motorista Alterado",
      are_you_sure_you_want_to_update: "Tem certeza de que deseja atualizar?",
      edit_plan_for_same: "Editar Plano para o Mesmo",
      update_vehicle_plan: "Atualizar Plano de Veículo",
      max_dispatch_time: "Tempo Máximo de Despacho",
      new_plan: "Novo Plano",
      reason_for_plan_change: "Razão da Mudança de Plano",
      enter_the_reason: "Insira o Motivo",
      reason_for_plan_deletion: "Razão para Exclusão do Plano",
      are_you_sure_delete_plan: "Tem certeza de que deseja excluir o plano?",
      update_order_details: "Atualizar Detalhes do Pedido",
      demand_volume: "Volume de Demanda",
      dealer_availability_start_time:
        "Hora de Início da Disponibilidade do Revendedor",
      dealer_availability_end_time:
        "Hora de Fim da Disponibilidade do Revendedor",
      download: "Baixar",
      dealerTimeError:
        "O horário de início disponível do revendedor deve ser menor que o horário de término",
      more: "Mais",
      less: "Menos",
      qualityChecks: "Verificações de qualidade",
      currentMonthPlanDistance: "Distância do plano do mês atual",
      email: "E-mail",
      viewPlan: "Ver plano",
      add_vehicle_type: "Adicionar Tipo de Veículo",
      configurations: "Configurações",
      uploading_data_for_plant: "Carregando Dados Para a Planta",
      import_existing_vehicle_details: "Importar detalhes do veículo existente",
      blank_rows_columns_not_allowed:
        "Linhas/colunas em branco não são permitidas.",
      check_and_remove_blank_data:
        "Para verificar e remover dados em branco, use Localizar e Selecionar → Ir para Especial → Brancos, e depois exclua as linhas destacadas.",
      vehicle_operational_hours: "Horas Operacionais do Veículo (HH:MM)",
      data_saved_successfully: "Dados Salvos com Sucesso",
      plant_wise_configurations: "Configurações por Planta",
      save_plant_wise_configurations: "Salvar Configurações por Planta",
      vehicle_break_window: "Janela de Descanso do Veículo (HH:MM)",
      total_vehicles: "Total de Veículos",
      zone_configurations: "Configurações de Zonas",
      pickup_point: "Ponto de Coleta",
      actions: "Ações",
      edit: "Editar",
      add_transporter_details: "+ Adicionar Detalhes do Transportador",
      cost_per_mile: "Custo por Milha",
      freight_details: "Detalhes do Frete",
      add_zone: "+ Adicionar Zona",
      fixed_vehicle_cost_title: "Custo Fixo do Veículo ($)",
      variable_vehicle_cost_title: "Custo Variável do Veículo",
      city_wise_cost: "Custo do Veículo por Cidade",
      zone_wise_cost: "Custo do Veículo por Zona",
      vehicle_cost_configurations: "Configurações de Custos do Veículo",
      freight_cost_label: "Custo do Frete",
      validity_to_label: "Válido Até",
      validity_from_label: "Válido Desde",
      actions_label: "Ações",
      edit_button: "Editar",
      delete_button: "Excluir",
      save_button: "Salvar",
      cancel_button: "Cancelar",
      select_vehicle_type: "Selecionar Tipo de Veículo",
      select_transporter_name: "Selecionar Nome do Transportador",
      zone: "Zona",
      state: "Estado",
      driver_cost: "Custo do Motorista ($)",
      maintenance_cost: "Custo de Manutenção ($)",
      fixed_vehicle_cost_label: "Custo Fixo do Veículo ($)",
      cost_per_mile: "Custo por Milha",
      freight_details: "Detalhes do Frete",
      add_zone: "+ Adicionar Zona",
      fixed_vehicle_cost_title: "Custo Fixo do Veículo ($)",
      variable_vehicle_cost_title: "Custo Variável do Veículo",
      city_wise_cost: "Custo do Veículo por Cidade",
      zone_wise_cost: "Custo do Veículo por Zona",
      vehicle_cost_configurations: "Configurações de Custos do Veículo",
      freight_cost_label: "Custo do Frete",
      validity_to_label: "Válido Até",
      validity_from_label: "Válido Desde",
      actions_label: "Ações",
      edit_button: "Editar",
      delete_button: "Excluir",
      save_button: "Salvar",
      cancel_button: "Cancelar",
      enable_disable_dispatch_planning_module:
        "Habilitar/Desabilitar o Módulo de Planejamento de Despacho",
      inbound: "Entrada",
      suppliers_vendor_locations_pickup:
        "Fornecedores / Localizações de Vendedores (Coleta)",
      operational_hours: "Horas Operacionais",
      pickup_locations_drop: "Localizações de Coleta (Entrega)",
      plant_wise_vehicle_type_configuration:
        "Configuração do Tipo de Veículo por Planta",
      max_no_of_vehicle_types: "Número Máximo de Tipos de Veículos",
      vehicle_load_capacity_min_max_configuration_preferences:
        "Preferências de Configuração de Capacidade de Carga do Veículo Mín/Máx",
      vehicle_load_weight_min_max_preferences:
        "Preferências de Peso de Carga do Veículo Mín/Máx",
      distance_preferences: "Preferências de Distância",
      vehicle_priorities: "Prioridades do Veículo",
      vehicle_operational_times: "Horários Operacionais do Veículo",
      vehicle_availability: "Disponibilidade do Veículo",
      generic_time_windows: "Janelas de Tempo Genéricas",
      individual_vehicle_time_windows:
        "Janelas de Tempo por Veículo Individual",
      availability_by_vehicle_type: "Disponibilidade por Tipo de Veículo",
      availability_by_individual_vehicle:
        "Disponibilidade por Veículo Individual",
      monthly_minimum_planning_distance_preferences:
        "Preferências de Distância Mínima Mensal de Planejamento",
      gps_based_availability: "Disponibilidade Baseada em GPS",
      truck_location_visibility: "Visibilidade da Localização do Caminhão",
      plant_vicinity_circle_radius: "Raio do Círculo de Proximidade da Planta",
      plant_wise_freight_configuration: "Configuração de Frete por Planta",
      plant_wise_transporter_configuration:
        "Configuração de Transportador por Planta",
      transporter_share_of_business: "Participação do Transportador no Negócio",
      route_preferences: "Preferências de Rota",
      plant_wise_new_plan_creation: "Criação de Novo Plano por Planta",
      plant_wise_plan_summary: "Resumo do Plano por Planta",
      plant_wise_planning_history: "Histórico de Planejamento por Planta",
      sustainability_integration: "Integração de Sustentabilidade",
      save_inbound_configurations: "Salvar Configurações de Entrada",
      save_outbound_configurations: "Salvar Configurações de Saída",
      outbound: "Saída",
      consignees_dealers_locations_drop:
        "Destinatários / Localizações de Distribuidores (Entrega)",
      select_vehicle_type: "Selecionar Tipo de Veículo",
      select_transporter_name: "Selecionar Nome do Transportador",
      zone: "Zona",
      state: "Estado",
      driver_cost: "Custo do Motorista ($)",
      maintenance_cost: "Custo de Manutenção ($)",
      fixed_vehicle_cost_label: "Custo Fixo do Veículo ($)",
      delete: "Excluir",
      state: "Estado",
      city: "Cidade",
      zone: "Zona",
      delta_warehouse: "Depósito Delta",
      zones: "Zonas",
      zone_mapping: "Mapeamento de Zonas",
      city_not_mapped_to_state:
        "Esta cidade não está mapeada para nenhum estado",
      actions: "Ações",
      add_zones: "+ Adicionar Zonas",
      transporter_master: "Mestre de Transportadores",
      transporter_configurations: "Configurações de Transportadores",
      transporter_operations: "Operações de Transportadores",
      transporter_code: "Código do Transportador",
      transporter_name: "Nome do Transportador",
      zone_configuration: "Configuração de Zonas",
      vehicle_checklist_for_transportation_requirements:
        "Lista de Verificação de Veículos para Requisitos de Transporte",
      transporters_configuration: "Configuração de Transportadores",
      consolidated_route_plan_screen: "Tela de Plano de Rota Consolidada",
      plant_code: "Código da Planta",
      select_plant_to_check_vehicle_availability:
        "Selecione a planta para verificar a disponibilidade do veículo",
      vehicle_availability: "Disponibilidade de Veículos",
      note_trucks_near_plant_vicinity:
        "Nota: Caminhões perto da planta a uma distância de ",
      facility_name: "Nome da Instalação",
      vehicle_number: "Número do Veículo",
      transporter_code: "Código do Transportador",
      vehicle_status_dashboard: "Painel de Status dos Veículos",
      dc: "DC",
      vehicles: "Veículos",
      total_trucks: "Total de Caminhões",
      active: "Ativo",
      cost_details: "Detalhes de Custos",
      fixed_vehicle_cost: "Custo Fixo do Veículo",
      variable_vehicle_cost: "Custo Variável do Veículo",
      city_wise_vehicle_cost: "Custo do Veículo por Cidade",
      zone_wise_vehicle_cost: "Custo do Veículo por Zona",
      pickup_point: "Ponto de Coleta",
      vehicle_type: "Tipo de Veículo",
      transporter_name: "Nome do Transportador",
      cost_per_mile_below_sob: "Custo por Milha (abaixo de SOB)",
      cost_per_mile_after_sob: "Custo por Milha (após SOB)",
      validity_from: "Válido Desde",
      validity_to: "Válido Até",
      add_vehicle_cost: "+ Adicionar Custo do Veículo",
      freight_details: "Detalhes do Frete",
      select_transporter: "Selecionar Transportador",
      select_zone: "Selecionar Zona",
      select_state: "Selecionar Estado",
      city: "Cidade",
      cost: "Custo ($)",
      save: "Salvar",
      cancel: "Cancelar",
      actions: "Ações",
      validity_from_label: "Válido Desde",
      validity_to_label: "Válido Até",
      get_data: "Obter Dados",
      state: "Estado",
      zone: "Zona",
      fixed_vehicle_cost_label: "Custo Fixo do Veículo ($)",
      driver_cost: "Custo do Motorista ($)",
      maintenance_cost: "Custo de Manutenção ($)",
      city: "Cidade",
      zone: "Zona",
      vehicle_cost: "Custo do Veículo",
      freight_cost: "Custo do Frete",
      inactive: "Inativo",
      inside_dc: "Dentro do DC",
      in_transit_to_customer: "Em Trânsito para o Cliente",
      near_customer: "Perto do Cliente",
      return_to_dc: "Retorno ao DC",
      empty_outside_dc: "Vazio Fora do DC",
      zone_configurations: "Configurações de Zonas",
      transporters_configurations: "Configurações de Transportadores",
      truck_no: "Número do Caminhão",
      facility_name: "Nome da Instalação",
      zones: "Zonas",
      actions: "Ações",
      edit: "Editar",
      delete: "Excluir",
      add_zone_mapping: "Adicionar Mapeamento de Zonas",
      current_status: "Status Atual",
      next_availability: "Próxima Disponibilidade",
      dc_location: "Localização do DC",
      add_route_restriction: "Adicionar Restrição de Rota",
      location_code: "Código de Localização",
      location_name: "Nome da Localização",
      restricted_area: "Área Restrita",
      vehicle_list: "Lista de Veículos",
      from_time: "Horário de Início",
      to_time: "Horário de Término",
      customer_location: "Localização do Cliente",
      truck_no: "Número do Caminhão",
      facility_name: "Nome da Instalação",
      current_status: "Status Atual",
      inside_dc: "Dentro do DC",
      in_transit_to_customer: "Em Trânsito para o Cliente",
      near_customer: "Perto do Cliente",
      return_to_dc: "Retorno ao DC",
      empty_outside_dc: "Vazio Fora do DC",
      unplanned: "Não Planejado",
      next_availability_date_at_dc: "Próxima Data de Disponibilidade no DC",
      transporter_name: "Nome do Transportador",
      gps_status: "Status do GPS",
      inactive: "Inativo",
      active: "Ativo",
      na: "NA",
      actions: "Ações",
      date_and_time: "Data e Hora",
      security: "Segurança",
      quality: "Qualidade",
      assessed_by: "Avaliado Por",
      quality_conclusion: "Conclusão da Qualidade",
      quality_comments: "Comentários de Qualidade",
      quality_approved: "Aprovado pela Qualidade",
      quality_date_of_assessment: "Data da Avaliação de Qualidade",
      facility_name: "Nome da Instalação",
      vehicle_number: "Número do Veículo",
      vehicle_type: "Tipo de Veículo",
      transporter_code: "Código do Transportador",
      transporter_name: "Nome do Transportador",
      last_packet_time: "Hora do Último Pacote",
      vehicle_checklist_for_transportation:
        "Lista de Verificação de Veículos para Transporte",
      assessed_by: "Avaliado Por",
      security_conclusion: "Conclusão da Segurança",
      security_comments: "Comentários de Segurança",
      security_approved: "Aprovado pela Segurança",
      security_date_of_assessment: "Data da Avaliação de Segurança",
      location_code: "Código de Localização",
      vehicle_no: "Nº do Veículo",
      stage: "Etapa",
      quality_rejected: "Qualidade Rejeitada",
      requirements_set: "Requisitos Definidos",
      security_rejected: "Segurança Rejeitada",
      security_accepted: "Segurança Aceita",
      assessment_date: "Data da Avaliação",
      requirement: "Requisito",
      satisfied: "Satisfeito",
      yes: "Sim",
      no: "Não",
      requirement_set_date: "Data de Definição do Requisito",
      temperature: "Temperatura",
      vehicle_operational_from: "Operacional do Veículo (desde)",
      vehicle_operational_to: "Operacional do Veículo (até)",
      break_time_from: "Horário de Descanso (desde)",
      break_time_to: "Horário de Descanso (até)",
      transporter_name: "Nome do Transportador",
      last_packet_time: "Hora do Último Pacote",
      temperature: "Temperatura",
      view_vehicles: "Ver Veículos",
      transporter_name: "Nome do Transportador",
      max_distance_per_plan_miles: "Distância Máxima por Plano (Milhas)",
      remaining_monthly_distance_miles: "Distância Mensal Restante (Milhas)",
      vehicle_next_available_date: "Próxima Data Disponível do Veículo",
      owned: "Próprio",
      contract: "Contrato",
      high: "Alto",
      medium: "Médio",
      low: "Baixo",
      market: "Mercado",
      vehicles_for_today: "Veículos para Hoje",
      bulk_upload_master_vehicles: "Carregamento em Massa de Veículos Mestres",
      add_master_vehicle: "Adicionar Veículo Mestre",
      transporter_name: "Nome do Transportador",
      transit_type: "Tipo de Trânsito",
      facility_name: "Nome da Instalação",
      vehicle_type: "Tipo de Veículo",
      vehicle_number: "Número do Veículo",
      vehicle_priority: "Prioridade do Veículo",
      max_distance_per_plan_miles: "Distância Máxima por Plano (Milhas)",
      max_weight_pounds: "Peso Máximo (Libras)",
      max_volume_cft: "Volume Máximo (CFT)",
      monthly_min_planning_distance_miles:
        "Distância Mínima Mensal de Planejamento (Milhas)",
      remaining_monthly_planning_distance_miles:
        "Distância Mensal Restante de Planejamento (Milhas)",
      next_available_date_yyyy_mm_dd_hh_mm:
        "Próxima Data Disponível (AAAA-MM-DD HH:mm)",
      vehicle_break_down: "Quebra de Veículo",
      drop_location_code: "Código da Localização de Entrega",
      arrival_at_first_dealer: "Chegada ao Primeiro Distribuidor",
      departure_at_last_dealer: "Partida do Último Distribuidor",
      average_payload: "Carga Média (Toneladas)",
      mileage: "Quilometragem (Milhas/galão)",
      fuel_type: "Tipo de Combustível",
      temperature_controlled: "Controle de Temperatura",
      load_characteristics: "Características da Carga",
      glec_vehicle_type: "Tipo de Veículo GLEC",
      return_to_plant: "Retorno à Planta",
      summary_dashboard: "Painel Resumo",
      dispatch_plan_details: "Detalhes do Plano de Despacho",
      invalid_orders_note: "Nota: Pedidos Inválidos Não Estão Sendo Exibidos",
      pickup_point: "Ponto de Coleta",
      vehicle_type_configuration: "Configuração do Tipo de Veículo",
      vehicle_type: "Tipo de Veículo",
      vehicle_type_name: "Nome do Tipo de Veículo",
      min_volume: "Volume Mínimo (CFT)",
      max_volume: "Volume Máximo (CFT)",
      vehicle_priority: "Prioridade do Veículo",
      min_weight: "Peso Mínimo do Veículo (Libras)",
      max_weight: "Peso Máximo do Veículo (Libras)",
      max_distance_trip: "Distância Máxima por Viagem (Milhas)",
      monthly_min_planning_distance:
        "Distância Mínima Mensal de Planejamento (Milhas)",
      cancel: "Cancelar",
      save: "Salvar",
      facility_name: "Nome da Instalação",
      vehicle_no: "Nº do Veículo",
      address: "Endereço",
      last_packet_time: "Hora do Último Pacote",
      latitude: "Latitude",
      longitude: "Longitude",
      service_provider: "Provedor de Serviço",
      temperature: "Temperatura",
      on_trip: "Em Viagem",
      requirement: "Requisito",
      requirement_type: "Tipo de Requisito",
      security: "Segurança",
      assessed_by: "Avaliado Por",
      security_conclusion: "Conclusão da Segurança",
      security_comments: "Comentários de Segurança",
      assessment_start_date: "Data de Início da Avaliação",
      assessment_end_date: "Data de Fim da Avaliação",
      get_data: "Obter Dados",
      add_requirements: "Adicionar Requisitos",
      add_vehicle_checklist: "Adicionar Lista de Verificação do Veículo",
      vehicle_configurations: "Configurações de Veículos",
      vehicle_availability_configurations:
        "Configurações de Disponibilidade de Veículos",
      vehicle_location: "Localização do Veículo",
      vehicle_master: "Mestre do Veículo",
      vehicle_availability: "Disponibilidade do Veículo",
      vehicle_details: "Detalhes do Veículo",
      monthly_min_distance: "Distância Mínima Mensal (Milhas)",
      max_weight_per_trip: "Peso Máximo por Viagem (Libras)",
      max_distance_per_trip: "Distância Máxima por Viagem (Milhas)",
      planned_data: "Dados Planejados",
      vfr_percentage: "Porcentagem VFR",
      cool_chain_adherence: "Adesão à Cadeia de Frio",
      transit_time: "Tempo de Trânsito",
      loading_time: "Tempo de Carregamento",
      unloading_time: "Tempo de Descarregamento",
      planned_summary: "Resumo Planejado",
      invalid_drop_data: "Dados de Entrega Inválidos",
      vehicle_utilization_km: "Utilização de Veículo (KM)",
      distance_to_destination: "Distância até o Destino",
      expected_delivery_date: "Data de Entrega Esperada",
      trip_no: "Número da Viagem",
      total_quantity: "Quantidade Total",
      demand_volume_cft: "Volume de Demanda (CFT)",
      weight_pounds: "Peso (Libras)",
      quantity: "Quantidade",
      dropped_reason: "Razão para Descarte",
      delivery_date: "Data de Entrega",
      drop_location_city: "Cidade de Entrega",
      planned_day: "Dia Planejado",
      vehicle_no: "Número do Veículo",
      drop_sequence: "Sequência de Entrega",
      invoicing_date: "Data de Faturamento",
      pickup_location_source: "Localização de Coleta (Fonte)",
      destination_location_city: "Cidade de Destino",
      destination_location_state: "Estado de Destino",
      distance_to_destination_miles: "Distância até o Destino (Milhas)",
      time_to_destination: "Tempo até o Destino",
      dealer_available_from: "Distribuidor Disponível Desde",
      dealer_available_to: "Distribuidor Disponível Até",
      total_planned_weight: "Peso Total Planejado",
      total_planned_volume: "Volume Total Planejado",
      arrival_at_first_dealer_start:
        "Chegada ao Primeiro Distribuidor (início)",
      departure_at_last_dealer_end: "Partida do Último Distribuidor (fim)",
      return_to_plant_hh_mm: "Retorno à Planta (HH:MM)",
      total_planned_trip_time_hrs: "Tempo Total de Viagem Planejado (Hrs)",
      total_round_trip_time_hrs: "Tempo Total de Viagem de Ida e Volta (Hrs)",
      total_loading_time_hh_mm: "Tempo Total de Carregamento (HH:MM)",
      total_unloading_time_hh_mm: "Tempo Total de Descarregamento (HH:MM)",
      vehicle_utilization_percentage: "Utilização do Veículo (%)",
      view_order_information: "Ver Informações do Pedido",
      vehicle_configuration: "Configuração do Veículo",
      summary: "Resumo",
      planned_summary: "Resumo Planejado",
      planned_vehicles: "Veículos Planejados",
      unplanned_loads: "Cargas Não Planejadas",
      invalid_drop_data: "Dados de Entrega Inválidos",
      set_vehicle_priority: "Definir Prioridade do Veículo",
      forecast_order_plan: "Plano de Pedido Previsto",
      volume_cft: "Volume (CFT)",
      dealer_available_time_error:
        "O horário de início deve ser menor que o horário de término",
      ok: "Ok",
      get_data: "Obter Dados",
      plan_history: "Histórico do Plano",
      upload_file: "Carregar Arquivo",
      save: "Salvar",
      cancel: "Cancelar",
      delete: "Excluir",
      plant_name: "Nome da Planta",
      total_trips: "Total de Viagens",
      trips_edited: "Viagens Editadas",
      vfr_percentage: "Porcentagem VFR",
      total_transit_time_hh_mm: "Tempo Total de Trânsito (HH:MM)",
      drop_location_name_destination:
        "Nome da Localização de Entrega (Destino)",
      unloading_time_hh_mm: "Tempo de Descarregamento (HH:MM)",
      edited_transaction_ids: "IDs de Transações Editadas",
      vehicle_type: "Tipo de Veículo",
      vehicle_name: "Nome do Veículo",
      weight_pounds: "Peso (Libras)",
      plant_code: "Código da Planta",
      plant_name: "Nome da Planta",
      total_trips: "Total de Viagens",
      trips_edited: "Viagens Editadas",
      last_30_days_trips_modification_details:
        "Detalhes de Modificação de Viagens dos Últimos 30 Dias",
      last_7_days_trips_modification_details:
        "Detalhes de Modificação de Viagens dos Últimos 7 Dias",
      planning_progress_past_7_weeks:
        "Progresso do Planejamento nas Últimas 7 Semanas",
      edited_transaction_ids: "IDs de Transações Editadas",
      trips_removed: "Viagens Removidas",
      removed_transaction_ids: "IDs de Transações Removidas",
      drop_location_city: "Cidade da Localização de Entrega",
      drop_location_name_destination:
        "Nome da Localização de Entrega (Destino)",
      note_blank_rows_columns_not_allowed:
        "Nota: Linhas/Colunas em Branco Não São Permitidas",
      check_blank_data_instructions:
        "Para verificar dados em branco: 1. Clique em Localizar e Selecionar. 2. Clique em Ir para Especial. 3. Escolha Brancos. 4. Clique em OK e todas as linhas/células em branco serão destacadas. 5. Escolha Excluir na seção Células na aba Início. 6. Clique em Excluir Linhas da Planilha.",
      disclaimer_dealer_window_ot_customers:
        "Aviso: A janela disponível para clientes OT será considerada a partir do arquivo de entrada, os horários dos clientes GT serão considerados das localizações de coleta/entrega",
      quantity: "Quantidade",
      specifications: "Especificações",
      please_select_plan_id_to_compare_plans:
        "Por favor, selecione o ID do plano para comparar planos",
      vehicle_forecast_summary_dashboard:
        "Painel de Resumo da Previsão de Veículos",
      pickup_point: "Ponto de Coleta",
      view_details: "Ver Detalhes",
      compare_plans: "Comparar Planos",
      pickup_drop_points: "Pontos de Coleta/Entrega",
      edit: "Editar",
      pickup: "COLETAR",
      drop: "ENTREGAR",
      both: "AMBOS",
      pickup_location_code: "Código da Localização de Coleta",
      dealer_type: "Tipo de Distribuidor",
      select: "Selecionar",
      local: "Local",
      upcountry: "Interior",
      vehicle_restriction_list: "Lista de Restrições de Veículos",
      week_off: "Folga Semanal",
      csp_day: "Dia CSP",
      is_customer_kdd: "O Cliente é KDD?",
      start_month: "Mês de Início",
      end_month: "Mês de Fim",
      plan_id: "ID do Plano",
      fecha_creada: "Data de Criação",
      no_of_trucks_planned: "Número de Caminhões Planejados",
      total_planned_volume_cft: "Volume Total Planejado (CFT)",
      total_planned_weight_pounds: "Peso Total Planejado (Libras)",
      plan_run_by: "Plano Executado Por",
      planned_vehicles: "Veículos Planejados",
      trip_no: "Número da Viagem",
      total_planned_customers: "Total de Clientes Planejados",
      total_round_trip_distance: "Distância Total de Ida e Volta",
      planning_percentage: "Porcentagem de Planejamento",
      max_dispatch_time: "Tempo Máximo de Despacho (HH:MM)",
      total_loading_time: "Tempo Total de Carregamento (HH:MM)",
      total_unloading_time: "Tempo Total de Descarregamento (HH:MM)",
      planned_summary: "Resumo Planejado",
      invalid_orders_not_shown:
        "Nota: Pedidos Inválidos Não Estão Sendo Exibidos",
      no_of_customers: "Número de Clientes",
      no_of_orders: "Número de pedidos",
      total_demand_volume: "Volume total de demanda (CFT)",
      planned_summary: "Resumo planejado",
      trips_removed: "Viagens Removidas",
      removed_transaction_ids: "IDs de Transações Removidas",
      vehicle_forecast_configurations: "Configurações de Previsão de Veículos",
      pickup_point: "Ponto de Coleta",
      vehicle_type: "Tipo de Veículo",
      vehicle_name: "Nome do Veículo",
      cost_per_miles: "Custo por Milha",
      driver_break_time: "Tempo de Descanso do Motorista",
      please_select_no_of_vehicles: "Por favor selecione o número de veículos",
      no_of_vehicles: "Número de veículos",
      obd_analysis: "Análise OBD",
      pickup_point: "Ponto de Coleta",
      select_month: "Selecionar mês",
      location_radius_miles: "Raio de localização (milhas)",
      submit: "Enviar",
      total_obd_details: "Detalhes totais de OBD",
      valid_dealers: "Distribuidores válidos",
      invalid_dealers: "Distribuidores inválidos",
      volume: "Volume",
      help: "Ajuda",
      change_password: "Alterar senha",
      logout: "Sair",
      weight_pounds: "Peso (libras)",
      quantity: "Quantidade",
      obd_details_within_radius: "Detalhes de OBD dentro do raio",
      dealers: "Distribuidores",
      dealers_percent: "Porcentagem de distribuidores",
      volume_percent: "Porcentagem de volume",
      weight_pounds_percent: "Porcentagem de peso (libras)",
      quantity_percent: "Porcentagem de quantidade",
      cost_per_miles: "Custo por milhas ($)",
      driver_break_time_from: "Tempo de descanso do motorista (desde)",
      monthly_min_planning_distance_miles:
        "Distância mínima de planejamento mensal (milhas)",
      vehicle_type_count: "Quantidade de tipos de veículos",
      driver_break_time_per_day:
        "Tempo de descanso do motorista por dia (HH:MM)",
      cost_per_miles_2: "Custo por milhas ($)",
      set_vehicle_priority: "Definir prioridade do veículo",
      vehicle_type_name: "Nome do tipo de veículo",
      min_volume_cft: "Volume mínimo (CFT)",
      max_volume_cft: "Volume máximo (CFT)",
      max_distance_per_trip_miles: "Distância máxima por viagem (milhas)",
      select_plan_id: "Selecionar ID do plano",
      drop_details: "Detalhes da entrega",
      drop_location_code: "Código de localização de entrega",
      vehicle_utilization: "Utilização do veículo",
      onward_trip_distance_miles: "Distância da viagem de ida (Milhas)",
      round_trip_miles: "Viagem de ida e volta (Milhas)",
      max_dispatch_time_hh_mm: "Tempo máximo de despacho (HH:MM)",
      arrival_at_first_dealer_start:
        "Chegada ao primeiro distribuidor (Início)",
      departure_at_last_dealer_end: "Partida do último distribuidor (Fim)",
      return_to_plant_hh_mm: "Retorno à planta (HH:MM)",
      vehicle_number: "Número do veículo",
      vehicle_availability_from: "Disponibilidade do veículo (desde)",
      vehicle_availability_to: "Disponibilidade do veículo (até)",
      break_time_from: "Tempo de descanso (desde)",
      break_time_to: "Tempo de descanso (até)",
      no_of_vehicles: "Número de veículos",
      driver_break_time_from: "Tempo de descanso do motorista (desde)",
      max_distance_miles: "Distância máxima (milhas)",
      monthly_min_planning_distance_miles:
        "Distância mínima de planejamento mensal (milhas)",
      total_planned_trip_time_hrs: "Tempo total de viagem planejada (Horas)",
      total_round_trip_time_hrs: "Tempo total de ida e volta (Horas)",
      drop_location_code: "Código de localização de entrega",
      drop_location_name: "Nome da localização de entrega",
      drop_location_city: "Cidade da localização de entrega",
      order_number: "Número de ordem",
      delivery_date: "Data de entrega (M/D/AAAA)",
      back: "Voltar",
      drop_location_name: "Nome da localização de entrega",
      pickup_location: "Local de coleta",
      dealer_available_from: "Distribuidor disponível desde",
      dealer_available_to: "Distribuidor disponível até",
      total_planned_weight: "Peso total planejado",
      total_planned_volume: "Volume total planejado",
      arrival_at_first_dealer: "Chegada ao primeiro distribuidor",
      departure_at_last_dealer: "Partida do último distribuidor",
      return_to_plant: "Retorno à planta",
      summary_dashboard: "Painel de resumo",
      dispatch_plan_details: "Detalhes do plano de despacho",
      invalid_orders_note: "Nota: Pedidos inválidos não estão sendo exibidos",
      planned_data: "Dados planejados",
      vfr_percentage: "Porcentagem VFR",
      cool_chain_adherence: "Adesão à cadeia de frio",
      transit_time: "Tempo de trânsito",
      loading_time: "Tempo de carregamento",
      unloading_time: "Tempo de descarregamento",
      planned_summary: "Resumo planejado",
      invalid_drop_data: "Dados de entrega inválidos",
      vehicle_utilization_km: "Utilização de veículos (KM)",
      distance_to_destination: "Distância ao destino",
      expected_delivery_date: "Data de entrega esperada",
      planned_vehicles: "Veículos planejados",
      trip_no: "Número da viagem",
      total_quantity: "Quantidade total",
      dealer_available_to: "Distribuidor disponível até",
      drop_location_code_destination:
        "Código de localização de entrega (destino)",
      order_number: "Número de ordem",
      demand_volume_cft: "Volume de demanda (CFT)",
      weight_pounds: "Peso (libras)",
      quantity: "Quantidade",
      dropped_reason: "Motivo do descarte",
      delivery_date: "Data de entrega",
      drop_location_name: "Nome do local de entrega",
      drop_location_city: "Cidade da localização de entrega",
      trip_no: "Número da viagem",
      planned_day: "Dia planejado",
      vehicle_no: "Número do veículo",
      vehicle_type: "Tipo de veículo",
      drop_sequence: "Sequência de entrega",
      invoicing_date: "Data de faturamento",
      pickup_location_source: "Local de coleta (fonte)",
      destination_location_city: "Cidade do destino",
      destination_location_state: "Estado do destino",
      distance_to_destination_miles: "Distância ao destino (milhas)",
      expected_delivery_date: "Data de entrega esperada",
      time_to_destination: "Tempo até o destino",
      transit_time_hh_mm: "Tempo de trânsito (HH:MM)",
      loading_time_hh_mm: "Tempo de carregamento (HH:MM)",
      unloading_time_hh_mm: "Tempo de descarregamento (HH:MM)",
      total_planned_customers: "Total de clientes planejados",
      total_round_trip_distance_km: "Distância total de ida e volta (KM)",
      planning_percentage: "Porcentagem de planejamento",
      cool_chain_adherence_percentage: "Porcentagem de adesão à cadeia de frio",
      vfr_percentage: "Porcentagem de VFR",
      total_transit_time_hh_mm: "Tempo total de trânsito (HH:MM)",
      round_trip_miles: "Viagem de ida e volta (milhas)",
      total_planned_weight_pounds: "Peso total planejado (libras)",
      total_planned_volume_cft: "Volume total planejado (CFT)",
      arrival_at_first_dealer_start:
        "Chegada ao primeiro distribuidor (início)",
      departure_at_last_dealer_end: "Partida do último distribuidor (fim)",
      return_to_plant_hh_mm: "Retorno à planta (HH:MM)",
      total_planned_trip_time_hrs: "Tempo total de viagem planejada (Hrs)",
      total_round_trip_time_hrs: "Tempo total de ida e volta (Hrs)",
      total_loading_time_hh_mm: "Tempo total de carregamento (HH:MM)",
      total_unloading_time_hh_mm: "Tempo total de descarregamento (HH:MM)",
      vehicle_utilization_percentage: "Utilização do veículo (%)",
      view_order_information: "Ver informações do pedido",
      vehicle_configuration: "Configuração do veículo",
      summary: "Resumo",
      planned_summary: "Resumo planejado",
      planned_vehicles: "Veículos planejados",
      unplanned_loads: "Cargas não planejadas",
      invalid_drop_data: "Dados de entrega inválidos",
      vehicle_utilization_km: "Utilização de veículos (KM)",
      planned_data: "Dados planejados",
      invalid_orders_note: "Nota: Pedidos inválidos não estão sendo exibidos",
      set_vehicle_priority: "Definir prioridade do veículo",
      vehicle_min_weight_pounds: "Peso Mínimo do Veículo (Libras)",
      vehicle_max_weight_pounds: "Peso Máximo do Veículo (Libras)",
      vehicle_min_volume_cft: "Volume Mínimo do Veículo (CFT)",
      vehicle_max_volume_cft: "Volume Máximo do Veículo (CFT)",
      max_distance_miles: "Distância Máxima (Milhas)",
      vehicle_priority: "Prioridade do Veículo",
      actions: "Ações",
      forecast_order_plan: "Plano de Pedido de Previsão",
      drop_details: "Detalhes da Entrega",
      month: "Mês",
      year: "Ano",
      total_quantity: "Quantidade Total",
      total_weight_pounds: "Peso Total (Libras)",
      total_demand_volume_cft: "Volume Total Demandado (CFT)",
      user: "Usuário",
      uploaded_date: "Data de Upload",
      view_order_details: "Ver Detalhes do Pedido",
      pick_up_location_code: "Código de Local de Coleta",
      quantity: "Quantidade",
      drop_location: "Local de Entrega",
      drop_location_name: "Nome da Localização de Entrega",
      weight: "Peso",
      volume: "Volume",
      delivery_date: "Data de Entrega",
      invoice_date: "Data da Fatura",
      order_no: "Número do Pedido",
      available_start_time: "Hora de Início Disponível",
      available_end_time: "Hora de Fim Disponível",
      upload_input_file: "Carregar Arquivo de Entrada",
      download_sample_template: "Baixar Modelo de Amostra",
      select_month: "Selecionar Mês",
      upload_file: "Carregar Arquivo",
      save: "Salvar",
      cancel: "Cancelar",
      note_blank_rows_columns_not_allowed:
        "Nota: Linhas/colunas em branco não são permitidas",
      no_of_vehicles: "Número de Veículos",
      run_plan: "Executar Plano",
      vehicle_types: "Tipos de Veículos",
      upload_file: "Carregar Arquivo",
      pickup_point: "Ponto de Coleta",
      consolidated_route_plan_screen: "Tela de Plano de Rota Consolidado",
      start_date: "Data de Início",
      end_date: "Data de Fim",
      get_data: "Obter Dados",
      recent_plans: "Planos Recentes",
      plan_history: "Histórico do Plano",
      view_input: "Ver Entrada",
      consolidation_id: "ID de Consolidação",
      created_date: "Data de Criação",
      run_by: "Executado por",
      no_of_executions: "Número de Execuções",
      execution_status: "Status de Execução",
      download_output: "Baixar Resultado",
      upload_input_file: "Carregar Arquivo de Entrada",
      choose_file: "Escolher Arquivo",
      discard_obd: "Descartar OBD",
      upload_obd_details: "Carregar Detalhes OBD",
      upload_file: "Carregar Arquivo",
      submit: "Enviar",
      download_template: "Baixar Modelo",
      bulk_upload_locations: "Carregar Localizações em Massa",
      add_pickup_drop_points: "+Adicionar Pontos de Coleta/Entrega",
      details: "Detalhes",
      date: "Data",
      total_trips: "Total de Viagens",
      trips_edited: "Viagens Editadas",
      trips_removed: "Viagens Removidas",
      trips_edit_percentage: "Porcentagem de Viagens Editadas",
      plans_overview_dashboard: "Painel de Visão Geral de Planos",
      pickup_point: "Ponto de Coleta",
      location_type: "Tipo de Localização",
      location_code: "Código de Localização",
      location_name: "Nome da Localização",
      location_city: "Cidade da Localização",
      zone: "Zona",
      customer_type: "Tipo de Cliente",
      location_latitude: "Latitude da Localização",
      location_longitude: "Longitude da Localização",
      standard_transit_time: "Tempo Padrão de Trânsito (Dias)",
      distance_from_plant: "Distância da Planta (Milhas)",
      delta_warehouse: "Armazém Delta",
      home: "Início",
      run_new_plan: "Executar Novo Plano",
      stacking: "Empilhamento",
      production_planning: "Planejamento de Produção",
      forecasting: "Previsão",
      resource_scheduling: "Programação de Recursos",
      general: "Geral",
      pickup_drop_locations: "Pontos de Coleta/Entrega",
      vehicle_configurations: "Configurações de Veículos",
      vehicle_availability: "Disponibilidade de Veículos",
      zone_mapping: "Mapeamento de Zonas",
      transporters: "Transportadores",
      material_configurations: "Configurações de Materiais",
      freight_configurations: "Configurações de Frete",
      route_configurations: "Configurações de Rota",
      auto_clustering: "Agrupamento Automático",
      transporter_share_of_business: "Parte do Negócio do Transportador",
      dispatch_plan_summary: "Resumo do Plano de Despacho",
      kpi_dashboard: "Painel de Controle de KPI",
      planning_tools: "Ferramentas de Planejamento",
      forecast_tools: "Ferramentas de Previsão",
      suppliers: "Fornecedores",
      customers: "Clientes",
      materials: "Materiais",
      input_data: "Dados de Entrada",
      forecast: "Previsão",
      previous_plan: "Plano Anterior",
      trips: "Viagens",
      gps_quality: "Qualidade do GPS",
      truck_gps_data: "Dados de GPS do Caminhão",
      unscheduled_stoppages: "Paradas Não Programadas",
      deviation_reports: "Relatórios de Desvio",
      fo_data: "Dados FO",
      vehicle_checklist: "Lista de Verificação do Veículo",
      vehicle_status: "Status do Veículo",
      route_restrictions: "Restrições de Rota",
      consolidated_run_plan: "Plano de Execução Consolidado",
      plans_overview: "Visão Geral dos Planos",
      input_plan_summary: "Resumo do Plano de Entrada",
      load_plan_summary: "Resumo do Plano de Carga",
      plant_wise_configurations: "Configurações por Planta",
      vehicle_configuration: "Configuração do Veículo",
      monthly_data: "Dados Mensais",
      forecast_plan_history: "Histórico do Plano de Previsão",
      obd_analysis: "Análise OBD",
      actions: "Ações",
      pickup_location_source: "Local de Coleta (fonte)",
      drop_location_destination: "Local de Entrega (destino)",
      order_number: "Número de Ordem",
      invoicing_date: "Data de Faturamento (M/D/AAAA)",
      quantity: "Quantidade",
      dealer_available_time_from:
        "Hora disponível do distribuidor (desde 00:00)",
      dealer_available_time_to: "Hora disponível do distribuidor (até 23:59)",
      volume_cft: "Volume (CFT)",
      weight_pounds: "Peso (libras)",
      delivery_date: "Data de Entrega (M/D/AAAA)",
      no_rows_to_show: "Sem linhas para mostrar",
      rows: "Linhas",
      total_rows: "Total de linhas",
      add_dispatch: "Adicionar Despacho",
      run_new_plan_by_file: "Executar novo plano por arquivo",
      submit_run_plan: "Enviar e Executar Plano",
      delete: "Excluir",
      upload_input_file: "Carregar Arquivo de Entrada",
      upload_file: "Carregar Arquivo",
      download_sample_template: "Baixar Modelo de Amostra",
      save: "SALVAR",
      cancel: "CANCELAR",
      dealer_available_time_error:
        "O horário de início deve ser menor que o horário de término",
      ok: "Ok",
      summary_dashboard: "Painel de Resumo",
      pickup_point: "Ponto de Coleta",
      date_created: "Data de Criação",
      get_data: "Obter Dados",
      plan_details: "Detalhes do Plano",
      plan_history: "Histórico do Plano",
      drop_details: "Detalhes de Entrega",
      generate_plan: "Gerar Plano",
      order_id: "ID de Ordem",
      no_of_runs: "Número de Execuções",
      created_on: "Criado em",
      plan_run_by: "Plano Executado por",
      discard_obd: "Descartar OBD",
      upload_obd_details: "Carregar Detalhes OBD",
      download_template: "Baixar Modelo",
      choose_file: "Escolher Arquivo",
      submit: "Enviar",
      simulation_time: "Hora de Simulação",
      enable_dc_week_off_planning:
        "Habilitar Planejamento de Semana de Folga de DC",
      run_plan: "Executar Plano",
      cancel: "Cancelar",
      plan_details: "Detalhes do Plano",
      pickup_point: "Ponto de Coleta",
      date_created: "Data de Criação",
      order_id: "ID de Ordem",
      no_of_runs: "Número de Execuções",
      fix_and_reupload: "Corrigir e Recarregar",
      club_previous_order: "Agrupar Pedido Anterior",
      ignore: "Ignorar",
      dealer_code: "Código do Distribuidor",
      remarks: "Observações",
      run_new_plan: "Executar Novo Plano",
      consolidated_route_plan_screen: "Tela de Plano de Rota Consolidado",
      plant_code: "Código da Planta",
      plant_name: "Nome da Planta",
      total_trips: "Total de Viagens",
      trips_edited: "Viagens Editadas",
      edited_transaction_ids: "IDs de Transações Editadas",
      vehicle_type: "Tipo de veículo",
      vehicle_name: "Nome do veículo",
      weight_pounds: "Peso (Libras)",
      drop_location_city: "Cidade de localização de entrega",
      quantity: "Quantidade",
      planned_vehicles: "Veículos planejados",
      trip_no: "Número da viagem",
      total_planned_customers: "Total de clientes planejados",
      total_round_trip_distance: "Distância total de ida e volta",
      planning_percentage: "Porcentagem de planejamento",
      max_dispatch_time: "Tempo máximo de despacho (HH:MM)",
      total_loading_time: "Tempo total de carregamento (HH:MM)",
      total_unloading_time: "Tempo total de descarregamento (HH:MM)",
      planned_summary: "Resumo planejado",
      invalid_orders_not_shown:
        "Nota: Pedidos inválidos não estão sendo exibidos",
      no_of_customers: "Número de clientes",
      invalid_material: "Material inválido",
      drop_location_code_destination:
        "Código de localização de entrega (destino)",
      demand_volume_cft: "Volume de demanda (CFT)",
      dropped_reason: "Motivo do descarte",
      dealer_location_data_not_in_system:
        "Dados da localização do distribuidor não estão no sistema",
      delivery_date_less_than_current:
        "A data de entrega é menor que a data atual",
      missing_dealer_information: "Informações do distribuidor ausentes",
      transit_time_hh_mm: "Tempo de trânsito (HH:MM)",
      loading_time_hh_mm: "Tempo de carregamento (HH:MM)",
      unloading_time_hh_mm: "Tempo de descarregamento (HH:MM)",
      actions: "Ações",
      expected_delivery_date: "Data de entrega esperada",
      max_dispatch_time: "Tempo máximo de despacho (HH:MM)",
      unplanned_loads: "Cargas não planejadas",
      monthly_data: "Dados mensais",
      forecast_plan_history: "Histórico do plano de previsão",
      obd_analysis: "Análise de OBD",
      dealer_available_time_error:
        "O horário de início deve ser menor que o horário de término",
    },
  },
};

// const I18nInitializer = () => {

//   const [language, setLanguage] = useState(null); 

//   useEffect(() => {
//     const fetchUserLanguage = async () => {
//       try {
//         const params = {
//           unique_id: localStorage.getItem("unique_id"),
//           organization: localStorage.getItem("organization")
//         };

//         const response = await redirectURL.post('stacking/getUserLanguage', params);

//         if (response.data.status === 'success') {
//           console.log(response, 'response9483');
//           setLanguage(response.data.language.value); // Set the language value directly
//         } else {
//           console.log('Failed to fetch language');
//         }
//       } catch (e) {
//         console.error('Error fetching user language:', e);
//       }
//     };

//     fetchUserLanguage(); // Call the fetch function on mount
//   }, []); // Runs once when the component mounts

//   useEffect(() => {
//     if (language) {
//       i18n
//         .use(initReactI18next)
//         .init({
//           resources,
//           lng: language, // Use the fetched language
//           fallbackLng: "en",
//           interpolation: {
//             escapeValue: false // React already escapes values
//           }
//         });
//     }
//   }, [language]); // Runs when language state changes

//   return null; // No UI to render
// };

// var language_code = JSON.parse(localStorage.getItem("language"))?.value
// console.log("language_code", language_code);

const language = localStorage.getItem("language") 
// console.log("language", language)
var language_code = language !== undefined && language !== "undefined" && language !== null && language !== "null" 
    ? JSON.parse(language)?.value || "en" 
    : "en";
// var language_code = localStorage.getItem("language") 
//     ? JSON.parse(localStorage.getItem("language"))?.value 
//         ? JSON.parse(localStorage.getItem("language")).value 
//         : "en"
//     : "en";



i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: language_code, // Use the fetched language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // React already escapes values
    }
  });

// export default I18nInitializer;
export default i18n;