import React from "react";

const CompareGridBtn = (props) => {
  const handleOnClick = () => {
    props.colDef.params.onClickFunction(props, true);
  };
  var iconName = "fa fa-edit";
  if (
    props.colDef.params.iconName != undefined &&
    props.colDef.params.iconName != ""
  ) {
    iconName = props.colDef.params.iconName;
  }
  var gButton = "btn btn-warning";
  if (
    props.colDef.params.buttonCls != undefined &&
    props.colDef.params.buttonCls != ""
  ) {
    gButton = props.colDef.params.buttonCls;
  }
  var isDisable = true;

  if (props.data.comparePlan === true) {
    isDisable = false;
  } else {
    isDisable = true;
  }
  return (
    <div>
      <button
        className={gButton + " grid-btn"}
        disabled={isDisable}
        onClick={handleOnClick}
        style={{ border: "0px", position: "relative" }}
      >
        <i className={iconName}></i> {props.colDef.params.buttonName}
      </button>
    </div>
  );
};

export default CompareGridBtn;
