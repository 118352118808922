import React, { Component } from "react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
// import Constant from "../common/constants";
import Modal from "react-responsive-modal";
// import CountUp from "react-countup";
import "react-responsive-modal/styles.css";
import redirectURL from "../redirectURL";
import Select from "react-select";
// import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
// import getToolConfiguration from "../optirun-admin/commonFunctions";
import SweetAlert from "react-bootstrap-sweetalert";
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import { subDays } from "date-fns";
// import GridButton from "./gridButton";
import GridButton from "../optirun-admin/gridButton";
// import GridCheckbox from "./gridCheckbox";
import GridCheckbox from "../optirun-admin/gridCheckbox";
// import CSVFileValidator from "csv-file-validator";
// import { getDDMMYYYYHHMMSS } from "../common/utils";
// import { logFormChanges } from "../optirun-admin/userActivityPickupDrop";
// import $ from "jquery";
// var moment = require("moment");

export default class RouteDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
        GridCheckbox: GridCheckbox,
      },
      rowData: [],
      tabActive: "all",
      currentDateVehicles: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 100,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      openMasterProductModal: false,
      data_type: "",
      // location: this.props.plantLocation,
      location :{
                "value": "LAP-291647",
                "label": "Charlie Hub"
            },
      vehicle_type: "",
      vehicleTypeList: [],
      transporter_name: "",
      transporterList: [],
      vehicle_no: "",
      show: false,
      basicTitle: "",
      basicType: "default",
      row_id: "",
      vehicle_priority: "Owned",
      overlayBlock: "show-n",
      showSlideBlock: "",
      csvfiledata: [],
      vehicle_priority_order_owned: 1,
      vehicle_priority_order_contract: 2,
      vehicle_priority_order_market: 3,
      monthly_min_planning_distance: 0,
      remaining_distance: "",
      vehicle_next_available_date: "",
      loadshow: "show-m",
      vehicle_breakdown: false,
      clickonEdit: false,
      oldDate: "",
      newDate: "",
      transit_type: "",
      vehiclesData: [],
      previousData: "",
      totalMasterData: [],
      routeData: [],
      product_type: "",
      product_code: "",
      locationCode: "",
      locationNames: [],
      dropLocations: [],
      dropLocation: {
        "value": "LAP-291647",
        "label": "Charlie Hub"
    },
      route_id: "",
    };
  }
  componentDidMount() {
    // localStorage.setItem("")
    this.locationChanged(
      {
            "value": "LAP-291647",
            "label": "Charlie Hub"
        }
    )
    this.getRouteDetails();
    this.boundTypeLocations({ data_type: 2 });
    this.getDropLocationsData();
    this.getRouteOptions();

  //   this.droplocationChanged({
  //     "value": "LAP-291647",
  //     "label": "Charlie Hub"
  // })
    
  }
  getRouteOptions = () => {
    var routeOptions = [];
    for (var i = 1; i <= 100; i++) {
      routeOptions.push({ label: "Route " + i, value: "Route " + i });
    }
    this.setState({ routeOptions });
    // console.log("first", routeOptions);
  };
  getRouteDetails = () => {
    redirectURL
      .post("master/getRouteDetails")
      .then(async (response) => {
          console.log("routedatares", response.data);
        await this.setState({ routeData: response.data });
      });
  };
  onClickAddProduct = () => {
    // console.log("first", this.state.product_type);
    this.setState({
      openMasterProductModal: true,
    });
    // this.updateProductDetails()
  };

  updateProductDetails = async (rowData) => {
    // console.log("hit here first", rowData.data);
    var { route, drop_location, route_id } = rowData.data;
    var row_id = rowData.data._id;
    await this.setState({
      openMasterProductModal: true,
      //   product_code,
      //   product_type,
      dropLocation: { value: drop_location, label: drop_location },
      route_id,
      route: { value: route, label: route },
      row_id,
      // add_status
    });
  };

  getDropLocationsData = async () => {
    // drop_corresponding_pickup_location_code
    var dropLocationCodes = await redirectURL.post(
      "master/getDropLocationsData"
    );
    console.log("first drops", dropLocationCodes.data);
    await this.setState({ dropLocations: dropLocationCodes.data });
  };

  handleProductMasterModal = () => {
    this.setState({
      openMasterProductModal: false,
      row_id: "",
      dropLocation: "",
      route: "",
    });
    // console.log("first on handleProductMasterModal");
  };

  // updateMasterProductData = (params) => {
  //   redirectURL
  //     .post("/master/updateMasterProductData", params)
  //     .then(async (response) => {
  //       // console.log("first res upda", response.data);
  //       if (response.data.status === "success") {
  //         await this.setState({
  //           product_code: "",
  //           product_type: "",
  //           show: true,
  //           basicTitle: "Data Modified Successfully",
  //           basicType: "success",
  //           row_id: "",
  //         });
  //       } else {
  //         await this.setState({
  //           product_code: "",
  //           product_type: "",
  //           show: true,
  //           basicTitle: "Product Code Already Exists",
  //           basicType: "danger",
  //           row_id: "",
  //         });
  //       }
  //       await this.getProductMasterDetails();
  //     });
  //   // this.setState({ product_code: "", product_type: "" });
  // };

  saveRouteDetails = async (params) => {
    redirectURL.post("/master/saveRouteData", params).then(async (response) => {
      //   console.log("first res save", response.data);
      if (response.data === "success") {
        await this.setState({
          dropLocation: "",
          route: "",
          show: true,
          basicTitle: "Data Added Successfully",
          basicType: "success",
          row_id: "",
        });
      } else {
        await this.setState({
          dropLocation: "",
          route: "",
          show: true,
          basicTitle: "Failed",
          basicType: "danger",
          row_id: "",
        });
      }
      await this.getRouteDetails();
    });
  };

  handleSubmitProductMaster = (event) => {
    event.preventDefault();
    this.setState({ openMasterProductModal: false });
    var { dropLocation, route, location, row_id } = this.state;

    var location_name =  dropLocation.label.split("-")[0].toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    var params = {
      drop_location: dropLocation.value,
      drop_location_name  : location_name,
      route: route.value,
      plant_location_code: location.value,
    };
    // console.log("first row", params);
    // if (this.state.row_id !== "") {
    //   params.row_id = row_id;
    //   this.updateMasterProductData(params);
    // } else {
    // console.log("first hit save");
    this.saveRouteDetails(params);
    // }
  };

  onChangeProductFields = async (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "vehicle_next_available_date") {
      //   this.setState({ newDate: value });
    }
    if (name === "vehicle_breakdown") {
      // console.log(value)
      //   if (value === "false") {
      //     await this.setState({ [name]: true });
      //   } else {
      //     await this.setState({ [name]: false });
      //   }
    } else {
      this.setState({ [name]: value });
    }
  };
  onChangeProductCode = (event) => {
    // console.log("first", event.target.name, event.target.value);
    var name = event.target.name,
      value = event.target.value;
    if (value !== " ") {
      this.setState({ [name]: value });
    }
  };
  closeAlert = async () => {
    await this.setState({
      show: false,
    });
  };

  locationChanged = async (location) => {
    console.log( "location changed to", location)
    this.setState({
      location: location,
    });
  };

  droplocationChanged = async (location) => {
    // console.log(location, "location changed to")
    this.setState({
      dropLocation: location,
    });
  };

  routeChanged = async (route) => {
    // console.log(location, "location changed to")
    this.setState({
      route: route,
    });
  };

  boundTypeLocations = async (param) => {
    let location_access = JSON.parse(localStorage.getItem("location_access"));
    console.log("location_access", location_access)

    let locationNames = this.state.locationNames;
    console.log("stateLocations", locationNames)
    var plantCodes = [];
    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status === "success") {
          let boundLocations = response.data.boundLocations;
            console.log("boundLocations", response.data, boundLocations);
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                if (location_access.includes(item.location_code)) {
                  locationNames.push({
                    value: item.location_code,
                    label: item.location_name,
                  });
                }
              } else {
                locationNames.push({
                  value: item.location_code,
                  label: item.location_name,
                });
              }
              plantCodes.push(item.location_code);
              return true;
            });
            // console.log("first loca", locationNames);
            if (locationNames.length > 0) {
              await this.setState({
                locationNames: locationNames,
                // location: locationNames[1],
                boundLocations: boundLocations,
                plantCodes,
              });
            } else {
              //   console.log("first loca3", locationNames);
              await this.setState({
                locationNames: [{ label: "Please Add Data", value: "no_data" }],
                location: [{ label: "Please Add Data", value: "no_data" }],
                plantCodes,
              });
            }
            param.location_code = locationNames[0].value;
            this.getLatestDispatchOrderId(param);
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getBoundTypeLocations",
          screen: "Run New Plan",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };
  render() {
    // var routeData = this.state.productData;
    // var location = this.state.location;
    var routeData = this.state.routeData;
    var location = this.state.location;
    console.log("current_location", this.state.location)
    // console.log("routeData", routeData)
    // console.log("location", location)
    var dropLocations = [];
    if (location) {
        // console.log("first dropLocations", this.state.dropLocations);
        routeData = routeData.filter(
            (item) => item.plant_location_code === location.value
        );

        // console.log("came here", this.state.dropLocations)
      this.state.dropLocations.map((item) => {
        var locate_value =  location.value
        if (item.drop_corresponding_pickup_location_code === locate_value){
          dropLocations.push({
            label: item.location_code,
            value: item.location_code,
          });
        }
        return true;
      });
    } else {
      routeData = [];
      location = "";
    }
    console.log("dropLocations", dropLocations)

    var colsDefs = [
      {
        headerName: "Actions",
        field: "cluster",
        width: "100",
        pinned: "left",
        filter: false,
        sortable: false,
        params: {
          buttonName: "Edit",
          onClickFunction: this.updateProductDetails,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      //   {
      //     headerName: "Product Name",
      //     field: "product_code",
      //     width: "200",
      //   },
      {
        headerName: "Plant Location Code",
        field: "plant_location_code",
        width: "180",
      },
      {
        headerName: "Drop Location Code",
        field: "drop_location",
        width: "200",
      },
      {
        headerName: "Drop Location Name",
        field: "drop_location_name",
        width: "200",
      },
      {
        headerName: "Route",
        field: "route",
        width: "200",
      },
      // {
      //   headerName: "Route ID",
      //   field: "route_id",
      //   width: "200",
      // },
    ];
    return (
      <>
        <div class="container-fluid">
          <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
          ></SweetAlert>
          <div className="col-sm-12">
            <div className="row mb-3p">
              <div
                className="col-md-12 col-sm-12"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h5 className="fbold d-flex justify-content-between">
                  <span>Milkrun Routes</span>
                </h5>
              </div>
              <div className="col-md-2 col-sm-12">
                <div className="form-group ">
                  <label className="col-form-label f12">Drop Location</label>
                  <Select
                    placeholder={"Select"}
                    onChange={this.locationChanged}
                    name="location"
                    value={this.state.location}
                    options={this.state.locationNames}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex flex-row-reverse col-sm-12 control-padding-right">
                  <button
                    onClick={this.onClickAddProduct}
                    className="f12 btn btn-default float-right themefont fbold mr-10p mb-10p"
                  >
                    +Add Route
                  </button>
                </div>
              </div>
              <div
                id="myGrid"
                style={{ width: "100%", height: "80vh" }}
                className="col-sm-12 ag-theme-balham"
              >
                <AgGridReact
                  rowData={routeData}
                  columnDefs={colsDefs}
                  gridOptions={{ context: { componentParent: this } }}
                  defaultColDef={this.state.defaultColDef}
                  frameworkComponents={this.state.frameworkComponents}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                  masterDetail={true}
                  detailCellRendererParams={this.state.detailCellRendererParams}
                  enableRangeSelection={true}
                  enableCellContextMenu={true}
                  statusBar={this.state.statusBar}
                  sideBar={this.state.sideBar}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12"></div>

          <Modal
            closeOnOverlayClick={false}
            open={this.state.openMasterProductModal}
            onClose={this.handleProductMasterModal}
            classNames={{ modal: "productmaster-modelClass30vh"}}
          >
            <div className="row">
              <div className="col-12">
                <h4>Milk Run Routes</h4>
              </div>

              <form
                className="theme-form col-12 row mt-3"
                onSubmit={this.handleSubmitProductMaster}
              >
                <div className="row col-12">
                  <div className="col-md-4">
                      <label className="col-form-label f12">
                        Plant Location <span className="redColor fbold">*</span>
                      </label>
                      <Select
                        required
                        placeholder={"Select"}
                        onChange={this.transporterChanged}
                        name="locationCode"
                        value={this.state.location}
                        options={this.state.transporterList}
                        isDisabled={true}
                      />
                  </div>

                  <div className="col-md-4">
                      <label className="col-form-label f12">
                        Drop Location <span className="redColor fbold">*</span>
                      </label>
                      <Select
                        required
                        placeholder={"Select"}
                        onChange={this.droplocationChanged}
                        name="dropLocation"
                        value={this.state.dropLocation}
                        options={dropLocations}
                        maxMenuHeight={"170px"}
                      />
                  </div>

                  <div className="col-md-4">
                      <label className="col-form-label f12">
                        Route <span className="redColor fbold">*</span>
                      </label>
                      <Select
                        required
                        placeholder={"Select"}
                        onChange={this.routeChanged}
                        name="routes"
                        value={this.state.route}
                        options={this.state.routeOptions}
                        maxMenuHeight={"170px"}
                      />
                  </div>
                </div>

                <div className="form-grou col-12" style={{marginTop:"50px"}}>
                  <button
                    type="submit"
                    className="btn tbtn float-right save-btn"
                    id="saveGeo"
                    style={{padding:"0px 5px", height:"34px"}}
                  >
                    SAVE
                  </button>
                  <button
                    type="button"
                    className="btn tbtn float-right cancel-btn"
                    style={{padding:"0px 5px", height:"34px"}}
                    onClick={this.handleProductMasterModal}
                  >
                    CANCEL
                  </button>
                </div>

              </form>
            </div>
          </Modal>
          <div className="col-sm-12"></div>
        </div>
      </>
    );
  }
}
